import { useRouteQuery } from '@/hooks/useRouteQuery';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APKFileListView } from './@components/APKFileListView';
import { DeployApkFile } from './apply';

const locations = [
  {
    url: '/administration/apk-files/list',
    key: 'development',
    label: 'Development',
    breadcrumb: 'Apk Files',
    component: <APKFileListView />,
  },
  {
    url: '/administration/apk-files/deployment',
    key: 'deployment',
    label: 'Deployment',
    breadcrumb: 'Apk Files Deployment',
    component: <DeployApkFile />,
  },
];

export function ApkFileLandingPage() {
  const { setBreadcrumbTitle } = useContext(MainContext) || {};
  const navigate = useNavigate();
  const { query, updateQuery } = useRouteQuery();

  const [selectedParentKey, setSelectedParentKey] = useState(query?.type || 'development');

  const handleChange = (event, newValue) => {
    setSelectedParentKey(newValue);
    updateQuery({ type: newValue });
  };

  const handleCreate = () => {
    navigate('/administration/apk-files/create');
  };

  useEffect(() => {
    const item = locations?.find((i) => i?.key === selectedParentKey);
    if (item) {
      setBreadcrumbTitle(item.breadcrumb);
    }
  }, [setBreadcrumbTitle, selectedParentKey]);

  useEffect(() => {
    if (query?.type) {
      setSelectedParentKey(query.type);
    }
  }, [query?.type]);

  return (
    <Box mx={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        sx={{
          '& .MuiButtonBase-root': {
            textTransform: 'none',
          },
        }}
      >
        <Tabs
          value={selectedParentKey}
          onChange={handleChange}
          aria-label="APK Tabs"
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
          sx={{
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-selected': {
              color: '#4177BF !important',
            },
            '.MuiTab-root.MuiTab-textColorPrimary.Mui-disabled': {
              color: '#A6AFBA !important',
            },
            '.MuiTabs-indicator': {
              backgroundColor: '#4177BF !important',
            },
            '.MuiTabs-scroller': {
              height: '40px',
            },
          }}
        >
          {locations?.map((item, index) => (
            <Tab key={index} value={item.key} label={item.label} sx={{ fontSize: '1rem' }} />
          ))}
        </Tabs>

        {selectedParentKey === 'development' && (
          <Button
            variant="contained"
            startIcon={<AddOutlined />}
            size="small"
            onClick={handleCreate}
          >
            <Typography fontSize={'.875rem'}>APK File</Typography>
          </Button>
        )}
      </Box>

      {/* Render the selected component */}
      <Box mt={1}>
        {locations
          .filter((item) => item.key === selectedParentKey)
          .map((item) => (
            <Box key={item.key}>{item.component}</Box>
          ))}
      </Box>
    </Box>
  );
}
