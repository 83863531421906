import { FROM_TYPES } from '@/assets/schemes/constants';
import {
  VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
  audioBitrate,
  audioCodecs,
  frameRateOptions,
  streamingCodecs,
  videoResolution,
} from '@/assets/schemes/options';

export const imagerStreamingVideoSettings = (imager) => {
  const inputCode = imager?.inputCode;
  const postText = `_${inputCode}_streaming_`;
  return {
    label: imager.inputName,
    key: `streamingVideoPipeline${inputCode}`,
    underline: false,
    secondaryUnderline: true,
    isDisabled: (watch) => !watch(`enableImagerState_${inputCode}`),
    children: [
      {
        label: 'Enable Streaming Pipeline',
        key: `enableVideoPipeline${postText}`,
        type: FROM_TYPES.SWITCH,
        isDisabled: (watch) => {
          let otherImagerState =
            inputCode === 'vid_1'
              ? watch('enableVideoPipeline_vid_2_streaming_')
              : inputCode === 'vid_2'
                ? watch('enableVideoPipeline_vid_1_streaming_')
                : true;
          return !watch(`enableImagerState_${inputCode}`) || !otherImagerState;
        },
      },
      {
        label: 'Override Configuration from Deployed AI Models',
        key: `shouldTakeSettingsFromModel${postText}`,
        type: FROM_TYPES.SWITCH,
        isDisabled: (watch) => !watch(`enableVideoPipeline${postText}`),
      },
      {
        label: 'Resolution',
        key: `resolution${postText}`,
        type: FROM_TYPES.SELECT,
        options: videoResolution,
        originalOptions: VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },
      {
        label: 'Video Codec',
        key: `videoCodec${postText}`,
        type: FROM_TYPES.SELECT,
        options: streamingCodecs,
        isDisabled: (watch) =>
          watch(`videoCodec${postText}`) === 'H264' ||
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },
      {
        label: 'Adaptive Frame Rate',
        key: `isAbtOnFramerateEnabled${postText}`,
        type: FROM_TYPES.SWITCH,
        isHidden: (watch) => !watch('streamingQualityProfile'),
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },
      {
        label: 'Adaptive Bitrate',
        key: `isAbtOnBitrateEnabled${postText}`,
        type: FROM_TYPES.SWITCH,
        isHidden: (watch) => !watch('streamingQualityProfile'),
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },

      {
        label: 'Frame Rate',
        key: `frameRateValue${postText}`,
        type: FROM_TYPES.SELECT,
        options: frameRateOptions,
        postText: 'fps',
        isHidden: (watch) =>
          watch('streamingQualityProfile') && watch(`isAbtOnFramerateEnabled${postText}`),
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },
      {
        label: 'Frame Rate',
        key: `streamingFrameRateGroup${postText}`,
        isHidden: (watch) =>
          !watch('streamingQualityProfile') || !watch(`isAbtOnFramerateEnabled${postText}`),
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        children: [
          {
            label: 'Minimum Frame Rate',
            key: `frameRateMin${postText}`,
            type: FROM_TYPES.SELECT,
            options: frameRateOptions,
            postText: 'fps',
          },
          {
            label: 'Maximum Frame Rate',
            key: `frameRateMax${postText}`,
            postText: 'fps',
            type: FROM_TYPES.SELECT,
            options: frameRateOptions,
          },
        ],
      },
      {
        label: 'Bit Rate',
        key: `bitrateValue${postText}`,
        type: FROM_TYPES.INPUT,
        postText: 'kbps',
        min: 100,
        max: 100000,
        inputType: 'number',
        isHidden: (watch) =>
          watch('streamingQualityProfile') && watch(`isAbtOnBitrateEnabled${postText}`),
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },
      {
        label: 'Bit Rate',
        key: `bitRateGroup${postText}`,
        isHidden: (watch) =>
          !watch('streamingQualityProfile') || !watch(`isAbtOnBitrateEnabled${postText}`),
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        children: [
          {
            label: 'Minimum Bit Rate',
            key: `bitrateMin${postText}`,
            type: FROM_TYPES.INPUT,
            postText: 'kbps',
            min: 100,
            max: 100000,
            inputType: 'number',
          },
          {
            label: 'Maximum Bit Rate',
            key: `bitrateMax${postText}`,
            type: FROM_TYPES.INPUT,
            postText: 'kbps',
            min: 100,
            max: 100000,
            inputType: 'number',
          },
          {},
        ],
      },
    ],
  };
};

export const imagerStreamingAudioSettings = (imager) => {
  const inputCode = imager?.inputCode;
  const postText = `_${inputCode}_streaming_`;
  return {
    label: imager.inputName,
    key: `streamingAudioPipeline${inputCode}`,
    underline: false,
    isDisabled: (watch) => !watch(`enableImagerState_${inputCode}`),
    children: [
      {
        label: 'Enable Streaming Pipeline',
        key: `enableAudioPipeline${postText}`,
        type: FROM_TYPES.SWITCH,
        isDisabled: (watch) => !watch(`enableImagerState_${inputCode}`),
      },
      {},
      {
        label: 'Audio Codec',
        key: `audioCodec${postText}`,
        type: FROM_TYPES.SELECT,
        options: audioCodecs,
        isDisabled: (watch) => !watch(`enableAudioPipeline${postText}`),
      },
      {
        label: 'Bit Rate',
        key: `audioBitrate${postText}`,
        type: FROM_TYPES.SELECT,
        options: audioBitrate,
        postText: 'kbps',
        isDisabled: (watch) => !watch(`enableAudioPipeline${postText}`),
      },
    ],
  };
};
