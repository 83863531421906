import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/dbc/actions';
import { DBC_TABLE_COLUMNS } from '@/web/administration/dbc/columns';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

export function DBCListView() {
  const secretToken = useSelector(selectSecretToken);

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'DBC Files'} actions={ACTION_ITEMS} />
        <OffsetPagination
          key={secretToken}
          itemsPerPage={50}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            const request = api.ac.v5.dbc.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
            });
            await request.process();
            return {
              data: request.result.data,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<DbcFileDto>} */ results) => (
            <ItemsResponsiveView
              results={results}
              columns={DBC_TABLE_COLUMNS}
              defaultSortKey="name"
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
