import { BOX_DISCONTINUE, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<UserRole>>} */
export const ACTION_ITEMS = [
  {
    label: 'Remove',
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    confirmationMessage: 'Are you sure you want to remove the selected users from this Camera.',
    hidden: (selected) => !selected.length,
  },
  {
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    type: 'MODIFY',
    useCustomDialog: true,
    hidden: (selected) => !selected.length || selected.length > 1,
  },
  {
    label: 'Search',
    placeholder: 'Search Cameras',
    component: 'SEARCH',
  },
  {
    label: 'Add User',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    useCustomDialog: true,
    type: 'ADD_USER',
  },
];
