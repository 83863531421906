import { BOX_DISCONTINUE } from '@/assets/constants/images';

/** @type {Array<TableHeaderAction<FeedbackDto>>} */
export const ACTION_ITEMS = [
  {
    label: 'Delete',
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    confirmationMessage: 'Are you sure you want to delete the selected Feedbacks?',
  },
];
