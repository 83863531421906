import { useFullProductList } from '@/hooks/useFullProductList';
import { CustomSelect } from '@/web/@components/CustomForm';

/**
 * @typedef {object} AiContainerProductSelectProps
 * @property {boolean} [disabled]
 * @property {import('@/types').ProductProperties} [product]
 */

/** @param {AiContainerProductSelectProps} props */
export function AiContainerProductSelect(props) {
  const { disabled } = props;
  const { result: products, loading } = useFullProductList();
  return (
    <CustomSelect
      name="product"
      label="Product *"
      loading={loading}
      disabled={disabled}
      options={products}
      getKey="productId"
      getLabel="productName"
      placeholder="Select a product"
      rules={{ required: 'Product is required' }}
    />
  );
}
