module.exports = {
  'ai.containers.create.button': 'এআই ধারক তৈরি করুন',
  'ai.containers.page.card.browse': 'এআই ধারক ব্রাউজ করুন',
  'ai.containers.page.card.create': 'একটি নতুন এআই ধারক তৈরি করুন',
  'ai.containers.page.card.title': 'এআই ধারক',
  'ai.containers.page.title': 'এআই ধারক',
  'ai.containers.table.button.add': 'এআই কনটেইনার',
  'ai.containers.table.columns.name': 'নাম',
  'ai.containers.table.columns.publication.status': 'স্থিতি',
  'ai.containers.table.columns.release.version': 'রিলিজ সংস্করণ',
  'ai.containers.table.columns.version': 'সংস্করণ',
  'ai.containers.table.search.placeholder': 'এআই ধারক অনুসন্ধান করুন',
  'ai.models.create.button': 'এআই মডেল তৈরি করুন',
  'ai.models.name': 'এআই মডেল',
  'ai.models.page.card.browseItems': 'এআই মডেলগুলি ব্রাউজ করুন',
  'ai.models.page.card.createNew': 'একটি নতুন এআই মডেল তৈরি করুন',
  'ai.models.page.card.name': 'এআই মডেল',
  'ai.models.table.button.add': 'আপনার একটি মডেল আছে',
  'ai.models.table.columns.name': 'নাম',
  'ai.models.table.columns.publication.status': 'স্থিতি',
  'ai.models.table.columns.release.version': 'রিলিজ সংস্করণ',
  'ai.models.table.columns.vendor': 'বিক্রেতা',
  'ai.models.table.search.placeholder': 'এআই মডেলগুলি অনুসন্ধান করুন',
  'annotations.fetching.data.message': 'টীকা বিভাগ আনতে',
  'annotations.no.categories.message': 'কোন টীকা বিভাগ নেই।',
  'annotations.page.card.browseItems': 'টীকা ব্রাউজ করুন',
  'annotations.page.card.createNew': 'টীকা তৈরি করুন',
  'annotations.page.card.name': 'টীকা বিভাগ',
  'annotations.page.title': 'টীকা',
  'annotations.search.placeholder': 'সম্পাদনা এবং মুছতে টীকা অনুসন্ধান করুন',
  'button.agree': 'সম্মত',
  'button.cancel': 'বাতিল',
  'button.details': 'বিশদ',
  'button.disagree': 'একমত',
  'button.discontinue': 'বন্ধ করুন',
  'button.edit': 'সম্পাদনা',
  'button.modify': 'সংশোধন করুন',
  'button.release': 'মুক্তি',
  'button.retry': 'পুনরায় চেষ্টা করা',
  'button.save': 'সংরক্ষণ',
  'button.update': 'হালনাগাদ',
  'cameras.no.data.message': 'কোনও ক্যামেরা পাওয়া যায়নি!',
  'cameras.page.title': 'ক্যামেরা',
  'cameras.search.placeholder':
    'ক্যামেরার নাম, ক্যামেরা আইডি, অবস্থান এবং ভিন দ্বারা অনুসন্ধান করুন',
  'change.password.title': 'পাসওয়ার্ড পরিবর্তন করুন',
  'confirmations.discontinue': 'আপনি কি নিশ্চিত যে আপনি এই আইটেমগুলি বন্ধ করতে চান?',
  'confirmations.release': 'আপনি কি নিশ্চিত যে আপনি এই আইটেমগুলি প্রকাশ করতে চান?',
  'data-residency.page.card.configurations': 'কনফিগারেশন',
  'data-residency.page.card.name': 'ডেটা রেসিডেন্সি',
  'data-retention.page.card.configurations': 'কনফিগারেশন',
  'data-retention.page.card.name': 'তথ্য ধারণ',
  'device-batches.page.card.browseItems': 'ডিভাইস ব্যাচ ব্রাউজ করুন',
  'device-batches.page.card.createNew': 'ডিভাইস ব্যাচ তৈরি করুন',
  'device-batches.page.card.name': 'ডিভাইস ব্যাচ',
  'device-group.columns.description': 'বর্ণনা',
  'device-group.columns.name': 'নাম',
  'device-group.columns.product': 'পণ্য',
  'device-group.create.button': 'ডিভাইস গ্রুপ',
  'device-group.create.failed': 'ডিভাইস গ্রুপ আপডেট করতে ব্যর্থ',
  'device-group.create.page.title': 'ক্যামেরা গ্রুপ তৈরি করুন',
  'device-group.create.retry': 'গ্রুপ যোগ করতে পারেনি।কিছুক্ষণ পরে আবার চেষ্টা করুন',
  'device-group.create.success': 'গ্রুপ {{groupName}} সফলভাবে যুক্ত করা হয়েছে।',
  'device-group.delete.failed': '{{groupName}} বন্ধ করতে ব্যর্থ}',
  'device-group.delete.retry':
    '{{groupName}} বন্ধ করতে পারেনি}অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন.',
  'device-group.delete.success': 'ডিভাইস গ্রুপ {{groupName}} সফলভাবে বন্ধ।',
  'device-group.edit.failed': 'ডিভাইস গ্রুপ আপডেট করতে ব্যর্থ',
  'device-group.edit.success': 'গ্রুপ {{groupName}} সফলভাবে আপডেট করা হয়েছে।',
  'device-group.edit.title': 'আপডেট ক্যামেরা গ্রুপ',
  'device-group.form.description.placeholder': 'গ্রুপ বিবরণ প্রবেশ করান',
  'device-group.form.group.name.hint': 'দয়া করে একটি গ্রুপের নাম লিখুন',
  'device-group.form.group.name.label': 'দলের নাম',
  'device-group.form.group.name.placeholder': 'গ্রুপের নাম লিখুন',
  'device-group.form.select.product.hint': 'একটি পণ্য নির্বাচন করুন',
  'device-group.form.select.product.label': 'পণ্য',
  'device-group.form.select.product.placeholder': 'একটি পণ্য নির্বাচন করুন',
  'device-group.placeholder.search': 'অনুসন্ধান ডিভাইস গ্রুপ',
  'device-group.retry': 'গ্রুপ আপডেট করতে পারেনি।কিছুক্ষণ পরে আবার চেষ্টা করুন',
  'device-group.title': 'ডিভাইস গ্রুপ',
  'device.groups.page.card.browseItems': 'ডিভাইস গ্রুপগুলি ব্রাউজ করুন',
  'device.groups.page.card.createNew': 'ডিভাইস গ্রুপ তৈরি করুন',
  'device.groups.page.card.name': 'ডিভাইস গ্রুপ',
  'device.pools.page.card.browseItems': 'ডিভাইস পুল ব্রাউজ করুন',
  'device.pools.page.card.createNew': 'ডিভাইস পুল তৈরি করুন',
  'device.pools.page.card.name': 'ডিভাইস পুল',
  'events.no.data.message': 'কোনও ঘটনা পাওয়া যায় নি!',
  'events.page.title': 'ঘটনা',
  'events.search.placeholder': 'অনুসন্ধান',
  'firmwares.page.card.browseItems': 'ফার্মওয়্যার ব্রাউজ করুন',
  'firmwares.page.card.name': 'ফার্মওয়্যারস',
  'firmwares.page.card.upload': 'ফার্মওয়্যার আপলোড করুন',
  'geofences.page.card.browseItems': 'জিওফেন্সগুলি ব্রাউজ করুন',
  'geofences.page.card.createNew': 'জিওফেন্স তৈরি করুন',
  'geofences.page.card.name': 'জিওফেন্সস',
  'login.alternate.text': 'বা আপনি সঙ্গে করতে পারেন',
  'login.banner': 'বিশ্বস্ত ডেটা এবং সিদ্ধান্তের জন্য যথার্থ এআই',
  'login.button.google': 'গুগল',
  'login.button.microsoft': 'মাইক্রোসফ্ট',
  'login.button': 'প্রবেশ করুন',
  'login.forget.password': 'পাসওয়ার্ড ভুলে গেছেন?',
  'login.label.email': 'ইমেল',
  'login.label.password': 'পাসওয়ার্ড',
  'login.label.remember.me': 'আমাকে মনে কর',
  'login.title': 'আপনার অ্যাকাউন্টে লগ ইন করুন',
  'navigation.ai.container': 'এআই ধারক',
  'navigation.ai.models': 'এআই মডেল',
  'navigation.ai': 'এআই',
  'navigation.annotations': 'টীকা',
  'navigation.cameras': 'ক্যামেরা',
  'navigation.configurations': 'কনফিগারেশন',
  'navigation.data.residency': 'ডেটা রেসিডেন্সি',
  'navigation.data.retention': 'তথ্য ধারণ',
  'navigation.device.batches': 'ডিভাইস ব্যাচ',
  'navigation.device.groups': 'ডিভাইস গ্রুপ',
  'navigation.device.pools': 'ডিভাইস পুল',
  'navigation.events': 'ঘটনা',
  'navigation.firmwares': 'ফার্মওয়্যারস',
  'navigation.gallery': 'গ্যালারী',
  'navigation.operations.geofences': 'জিওফেন্সস',
  'navigation.operations': 'অপারেশন',
  'navigation.administration': 'অর্কেস্টেশন',
  'navigation.privacy.and.compliance': 'গোপনীয়তা এবং সম্মতি',
  'navigation.privacy.policies': 'গোপনীয়তা নীতি',
  'navigation.products': 'পণ্য',
  'navigation.reports': 'রিপোর্ট',
  'navigation.smarter.ai': 'স্মার্ট এআই',
  'navigation.tenants': 'ভাড়াটে',
  'navigation.triggers': 'ট্রিগার',
  'navigation.users': 'ব্যবহারকারীরা',
  'navigation.vin.groups': 'ভিন গ্রুপ',
  'navigation.waste.management': 'বর্জ্য ব্যবস্থাপনা',
  'navigation.winter.operations': 'শীতকালীন অপারেশন',
  'no.data.found': 'কোনও ডেটা পাওয়া যায় নি!',
  'pages.administration.deviceGroups.form.description.name': 'বর্ণনা',
  'privacy-policies.page.card.biometric': 'বায়োমেট্রিক',
  'privacy-policies.page.card.consent': 'সম্মতি',
  'privacy-policies.page.card.name': 'গোপনীয়তা নীতি',
  'privacy-policies.page.card.tools': 'সরঞ্জাম',
  'products.page.card.manage-accelerator': 'এআই এক্সিলারেটর পরিচালনা করুন',
  'products.page.card.manageHardwareProducts': 'হার্ডওয়্যার পণ্য পরিচালনা করুন',
  'products.page.card.name': 'পণ্য',
  'reset.password.title': 'পাসওয়ার্ড রিসেট করুন',
  'table.per.page': 'প্রতি পৃষ্ঠা {{item}}',
  'tenants.label.select': 'ভাড়াটে নির্বাচন করুন',
  'tenants.page.card.browseItems': 'ভাড়াটে ব্রাউজ করুন',
  'tenants.page.card.createNew': 'ভাড়াটে তৈরি করুন',
  'tenants.page.card.name': 'ভাড়াটে',
  'tenants.placeholder.search': 'ভাড়াটে অনুসন্ধান করুন',
  'tenants.title': 'ভাড়াটে',
  'triggers.create.button': 'ট্রিগার তৈরি করুন',
  'triggers.page.card.browseItems': 'ট্রিগার ব্রাউজ করুন',
  'triggers.page.card.createNew': 'ট্রিগার তৈরি করুন',
  'triggers.page.card.manageLabels': 'লেবেল পরিচালনা',
  'triggers.page.card.name': 'ট্রিগার',
  'triggers.page.title': 'ট্রিগার',
  'triggers.table.button.add': 'ট্রিগার',
  'triggers.table.columns.name': 'নাম',
  'triggers.table.columns.publication.status': 'স্থিতি',
  'triggers.table.columns.type': 'প্রকার',
  'triggers.table.search.placeholder': 'ট্রিগার অনুসন্ধান',
  'users.page.card.browseItems': 'ব্যবহারকারীদের ব্রাউজ করুন',
  'users.page.card.createNew': 'ব্যবহারকারী তৈরি করুন',
  'users.page.card.name': 'ব্যবহারকারীরা',
  'vin.groups.page.card.browseItems': 'ভিন গ্রুপগুলি ব্রাউজ করুন',
  'vin.groups.page.card.createNew': 'ভিন গ্রুপ তৈরি করুন',
  'vin.groups.page.card.name': 'ভিন গ্রুপ',
  'waste-management.configurations.page.card.customer': 'গ্রাহকরা',
  'waste-management.configurations.page.card.name': 'কনফিগারেশন',
  'waste-management.configurations.page.card.softwareConf': 'সফ্টওয়্যার কনফিগারেশন',
  'waste-management.operations.page.card.name': 'অপারেশন',
  'waste-management.reports.page.card.browseItems': 'ব্রাউজ রিপোর্ট',
  'waste-management.reports.page.card.createNew': 'প্রতিবেদন তৈরি করুন',
  'waste-management.reports.page.card.name': 'রিপোর্ট',
  'winter-operations.configurations.page.card.customer': 'গ্রাহকরা',
  'winter-operations.configurations.page.card.name': 'কনফিগারেশন',
  'winter-operations.configurations.page.card.softwareConf': 'সফ্টওয়্যার কনফিগারেশন',
  'winter-operations.gallery.page.card.name': 'গ্যালারী',
  'winter-operations.reports.page.card.browseItems': 'ব্রাউজ রিপোর্ট',
  'winter-operations.reports.page.card.createNew': 'প্রতিবেদন তৈরি করুন',
  'winter-operations.reports.page.card.name': 'রিপোর্ট',
};
