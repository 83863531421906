import { formatTimestamp } from '@/utils/datetime';
import { prettyBytes } from '@/utils/pretty-bytes';

/** @type {Array<TableColumn<CameraTypeRecord>>} */
export const GROUP_CAMERA_CELLULAR_USAGE_TABLE_COLUMNS = [
  {
    id: 'reportingTime',
    label: 'Time',
    align: 'left',
    searchable: true,
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'tx_bytes',
    label: 'Uploaded',
    align: 'center',
    searchable: true,
    format: (value) => prettyBytes(value),
  },
  {
    id: 'rx_bytes',
    label: 'Downloaded',
    align: 'center',
    searchable: true,
    format: (value) => prettyBytes(value),
  },
];
