import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {object} TSelectFieldPropsBase
 * @property {string} value
 * @property {(value: string) => any} onChange
 * @property {Array<import('@/types').LabelValuePair<any>>} options
 */

/**
 * @typedef {TSelectFieldPropsBase & Partial<Omit<Omit<import('@mui/material').SelectProps, 'options'>, 'onChange'>>} TSelectFieldProps
 */

/** @param {TSelectFieldProps} props */
export function SelectField(props) {
  const { label, options, value, onChange, ...extra } = props;
  const { t } = useTranslation();

  if (!options?.length) {
    return null;
  }

  return (
    <Select
      size="small"
      {...extra}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{
        'fontSize': '.875rem',
        'fontWeight': '500',
        'color': '#596A81',
        'borderRadius': '6px',
        'textAlign': 'center',
        '& .MuiOutlinedInput-root': {
          border: '1px solid #abb4c0',
        },
        '& .Mui-focused': {
          border: '1px solid #abb4c0',
        },
        ...extra.sx,
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {t(label)}
        </MenuItem>
      ))}
    </Select>
  );
}
