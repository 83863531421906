import { T } from '@/assets/locales';
import { useFetchAIAccelerators } from '@/hooks/useFetchAIAccelerators';
import { trimString } from '@/utils/formatting';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AIModelInputSourceForm } from '../AIModelInputSourceForm';
import { AIModelOutputSourceForm } from '../AIModelOutputSourceForm';
import { AiModelAdditionalParamForm } from '../AiModelAdditionalParamForm';
import { AIModelFileUpload } from '../AiModelFileUpload';

/**
 * @typedef {object} AiModelFormFields
 * @property {File} modelFile
 * @property {string} modelId
 * @property {string} name
 * @property {string} version
 * @property {string} vendor
 * @property {string} modelType
 * @property {string} inputLayer
 * @property {string} outputLayer
 * @property {AIAccelerator} aiAccelerator
 * @property {string} storedFileNamePrev
 * @property {string} description
 * @property {Array<any>} modelParams
 * @property {Array<{[key: string]: any}>} inputSources
 * @property {Array<OutputSource>} outputSources
 */

/**
 * @typedef {object} AIModelFormProps
 * @property {boolean} disabled
 * @property {AIModelV2WithAccelerator} [aiModel]
 * @property {(data: AiModelFormFields) => any} onSubmit
 */

/**
 * @param {AIModelFormProps} props
 */
export function AIModelForm(props) {
  const { onSubmit, disabled, aiModel } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { result: aiAccelerators = [], loading: acceleratorLoading } = useFetchAIAccelerators();

  /** @type {import('react-hook-form').UseFormReturn<AiModelFormFields>} */
  const form = useForm({
    mode: 'all',
    defaultValues: {
      modelId: uuidv4(),
      modelFile: null,
      name: '',
      version: '',
      vendor: '',
      modelType: '',
      inputLayer: '',
      outputLayer: '',
      aiAccelerator: null,
      storedFileNamePrev: '',
      description: '',
      inputSources: [],
      outputSources: [],
      modelParams: [],
    },
  });

  useEffect(() => {
    if (!aiModel) return;
    form.setValue('modelId', aiModel.modelId);
    form.setValue('name', aiModel.name);
    form.setValue('version', aiModel.version);
    form.setValue('inputLayer', aiModel.inputLayer);
    form.setValue('outputLayer', aiModel.outputLayer);
    form.setValue('description', aiModel.description);
    form.setValue('storedFileNamePrev', aiModel.storedFileName);
    form.setValue('aiAccelerator', aiModel.aiAcceleratorList[0]);
    form.setValue('vendor', aiModel.properties['vendor']);
    form.setValue('modelType', aiModel.properties['modelType']);
    form.setValue('modelParams', JSON.parse(aiModel.properties['modelParams'] || '[]'));
    form.setValue('outputSources', aiModel.outputSources);
    const inputSources = aiModel.inputSources?.map((item, i) => ({
      id: i + 1 + '',
      ...item.inputDescription,
    }));
    form.setValue('inputSources', inputSources);
  }, [aiModel, form]);

  const handleCancel = () => {
    navigate('/administration/ai/ai-models');
  };

  /** @param {File} file */
  const handleModelFileUpload = async (file) => {
    form.setValue('modelFile', file);
  };

  /** @param {AiModelFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmit) return;
    data.name = trimString(data.name);
    data.version = trimString(data.version);
    data.vendor = trimString(data.vendor);
    data.modelType = trimString(data.modelType);
    data.inputLayer = trimString(data.inputLayer);
    data.outputLayer = trimString(data.outputLayer);
    data.description = trimString(data.description);
    data.outputSources.forEach((item) => {
      item.outputName = trimString(item.outputName);
      item.outputCode = trimString(item.outputCode);
    });
    data.inputSources.forEach((item) => {
      for (const key in item) {
        if (typeof item[key] === 'string') {
          item[key] = trimString(item[key]);
        }
      }
    });
    onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
        {t(T['ai.model.form.text.secondary'])}
      </Typography>
      <Divider sx={{ mb: 1 }} />

      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="name"
              label={t(T['ai.model.form.name.label'])}
              placeholder={t(T['ai.model.form.name.placeholder'])}
              rules={{ required: t(T['ai.model.form.name.required']) }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="version"
              label={t(T['ai.model.form.version.label'])}
              placeholder={t(T['ai.model.form.version.placeholder'])}
              rules={{ required: t(T['ai.model.form.version.required']) }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="vendor"
              label={t(T['ai.model.form.vendor.label'])}
              placeholder={t(T['ai.model.form.vendor.placeholder'])}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="modelType"
              label={t(T['ai.model.form.modelType.label'])}
              placeholder={t(T['ai.model.form.modelType.placeholder'])}
              rules={{ required: t(T['ai.model.form.modelType.required']) }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="inputLayer"
              label={t(T['ai.model.form.inputLayer.label'])}
              placeholder={t(T['ai.model.form.inputLayer.placeholder'])}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="outputLayer"
              label={t(T['ai.model.form.outputLayer.label'])}
              placeholder={t(T['ai.model.form.outputLayer.placeholder'])}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSelect
              name="aiAccelerator"
              label={t(T['ai.model.form.aiAccelerator.label'])}
              placeholder={t(T['ai.model.form.aiAccelerator.placeholder'])}
              options={aiAccelerators || []}
              loading={acceleratorLoading}
              getLabel={(item) => item?.name || ''}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomInput
              name="description"
              label={t(T['ai.model.form.description.label'])}
              placeholder={t(T['ai.model.form.description.placeholder'])}
              minRows={3}
            />
          </Grid>
        </Grid>

        <AIModelFileUpload
          name="modelFile"
          modelId={aiModel?.modelId}
          onDropFile={handleModelFileUpload}
          label={t(T['ai.model.form.text.upload'])}
          rules={{ required: 'This is required' }}
        />

        <AIModelInputSourceForm />
        <AIModelOutputSourceForm />
        <AiModelAdditionalParamForm />

        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} py={10}>
          <Button variant="text" onClick={handleCancel}>
            {t(T['button.cancel'])}
          </Button>
          <Button disabled={disabled} variant="contained" type="submit">
            {disabled && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {aiModel ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
      </form>
      <Box height="30px" />
    </FormProvider>
  );
}
