import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { TenantForm } from '@/web/administration/tenants/@components/TenantForm';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function TenantUpdatePage() {
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const updateTenant = async (data) => {
    const { tenantName, tenantId } = data;

    try {
      setLoading(true);
      const req = api.ac.v5.tenant.$tenantId(tenantId).$patch({
        headers: {
          Authorization: secretToken,
        },
        data: {
          tenantName,
        },
      });
      await req.process();
      toastSuccess('Success', t(T['tenant.update.success'], { tenantName }));
      navigate('/administration/tenants');
    } catch (err) {
      if (err.response.data.code === 401) {
        toastWarning('Error', 'You are not authorized to change the tenant name');
        return;
      }
      toastWarning('Error', t(T['tenant.update.retry']));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['tenant.update.page.title'])}
      </Typography>
      <TenantForm onSubmitData={updateTenant} isLoading={loading} />
    </Box>
  );
}
