import { formatResolution } from '@/utils/cameras';
import { formatBitrate } from '@/utils/formatting';

/**
 * @typedef {object} InputSourceData
 * @property {string} [inputSource]
 * @property {string} [videoCodec]
 * @property {string} [resolution]
 * @property {string} [fps]
 * @property {string} [bitrate]
 * @property {string} [status]
 * @property {number} [inputName]
 */

/** @type {Array<TableColumn<InputSourceData>>} */
export const CAMERA_VIDEO_IMAGER_INPUT_TABLE_COLUMNS = [
  {
    id: 'inputName',
    label: 'Name',
    align: 'center',
    isPrimary: true,
  },
  {
    id: 'inputSource',
    label: 'Input Source',
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    format: (value) => (value === 'SUCCESS' ? 'Available' : 'Unavailable'),
  },
  {
    id: 'videoCodec',
    label: 'Video Codec',
    align: 'center',
    format: (value, row) => (row.status === 'SUCCESS' ? value : '...'),
  },
  {
    id: 'fps',
    label: 'FPS',
    align: 'center',
    format: (value, row) => (row.status === 'SUCCESS' ? value : '...'),
  },
  {
    id: 'bitrate',
    label: 'Bitrate',
    align: 'center',
    format: (value, row) => (row.status === 'SUCCESS' ? formatBitrate(Number(value)) : '...'),
  },
  {
    id: 'resolution',
    label: 'Resolution',
    align: 'center',
    format: (value, row) => (row.status === 'SUCCESS' ? formatResolution(value) : '...'),
  },
];
