import { BOX_DISCONTINUE, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<APNInfo>>} */
export const ACTION_ITEMS = [
  {
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    confirmationMessage: T['apn.confirmations.discontinue'],
  },
  {
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    link: (selectedItem) =>
      `/administration/device-groups/${selectedItem.groupId}/apns/${selectedItem.apnInfoId}/edit`,
  },
  {
    label: 'Search',
    placeholder: T['apn.actions.search.placeholder'],
    component: 'SEARCH',
  },
  {
    label: T['apn.actions.create'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: `./create`,
  },
];
