import { toastWarning } from '@/utils/toaster';
import { Box, Button } from '@mui/material';
import qs from 'qs';
import { useState } from 'react';
import { CameraSelectionPage } from '../CameraSelectionPage';

/**
 * @typedef {object} DriversFaceIdentityProps
 * @property {Driver} [driver]
 */

/**
 * @param {DriversFaceIdentityProps} props
 */
export function DriversFaceIdentity(props) {
  const { driver } = props;
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const handleAddFaceId = () => {
    setOpenCameraModal(true);
  };

  const onModalClose = () => {
    setOpenCameraModal(false);
  };

  /** @param {EndpointInfoAggregated} selectedCameraItem */
  const onHandleSelect = (selectedCameraItem) => {
    if (!selectedCameraItem) {
      toastWarning('Please select a device');
      return;
    }
    window.open(
      `/fleets/drivers/registration?${qs.stringify({
        id: selectedCameraItem.endpointId,
        driverId: driver.driverId,
        nickName: driver.firstName,
      })}`,
      '_self'
    );
  };

  return (
    <>
      {openCameraModal && (
        <CameraSelectionPage
          isOpen={true}
          onModalClose={onModalClose}
          onHandleSelect={onHandleSelect}
        />
      )}
      <Box display="flex" justifyContent="flex-end">
        <Button size="medium" variant="contained" onClick={handleAddFaceId}>
          Add Face Id
        </Button>
      </Box>
    </>
  );
}
