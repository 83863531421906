import api from '@/api';
import { store } from '@/store';
import { selectSecretToken } from '@/store/auth';
import axios from 'axios';
import { KnownUserError } from '../errors';

/**
 * @typedef {object} UploadToGCPParams
 * @property {any} id
 * @property {File} file
 * @property {string} [version]
 * @property {V5FileUploadUrlGetParams['type']} type
 * @property {AbortSignal} [signal]
 * @property {(v: number) => any} [onProgress]
 */

/**
 * @typedef {object} UploadToGCPResult
 * @property {string} fileName
 * @property {string} contentType
 * @property {() => Promise<string>} getDownloadUrl
 */

/**
 * @param {UploadToGCPParams} props
 * @returns {Promise<UploadToGCPResult>} stored file name
 */
export async function uploadToGCP(props) {
  const { id, version, file, type, onProgress = () => {}, signal } = props || {};

  const state = store.getState();
  const actualFileName = file.name;
  const contentType = file.type || 'application/octet-stream';
  const storedFileName = `${id}-${version}-${actualFileName}`.replace(/[^\w\d]+/g, '-');

  // get presigned url
  const request = api.ac.v5.file['upload-url'].$get({
    headers: {
      Authorization: selectSecretToken(state),
    },
    params: {
      id,
      type,
      contentType,
      fileName: storedFileName,
      cloudService: /*type === 'DBC' ? 'AZURE' : */ 'GCP',
    },
  });
  const result = await request.process();
  const upload = result?.result;
  if (signal?.aborted) return;
  if (!upload?.url) {
    throw new KnownUserError('Failed to generate upload URL');
  }

  try {
    // upload file to AZURE if its DBC file otherwise GCP
    // if (type === 'DBC') {
    //   const aborter = Aborter.none;
    //   signal.addEventListener('abort', aborter.abort, { once: true });
    //   const url = await uploadFile(file, storedFileName, upload.url, onProgress, aborter);
    //   onProgress(100);
    //   return {
    //     contentType,
    //     fileName: storedFileName,
    //     getDownloadUrl: () => Promise.resolve(url),
    //   };
    // }

    // upload file to GCP
    await axios.put(upload.url, file, {
      signal,
      headers: {
        'Content-Type': contentType,
      },
      onUploadProgress: (e) => {
        onProgress((e.loaded / e.total) * 100);
      },
    });
    onProgress(100);

    return {
      contentType,
      fileName: storedFileName,
      getDownloadUrl: async () => {
        const request = api.ac.v5.file['download-url'].$get({
          headers: {
            Authorization: selectSecretToken(state),
          },
          params: {
            id,
            type,
            contentType,
            fileName: storedFileName,
          },
        });
        const result = await request.process();
        console.log(result);
        return result.result.url;
      },
    };
  } catch (err) {
    console.error(err);
    throw new KnownUserError('Failed to upload file');
  }
}
