import { T } from '@/assets/locales';
import { store } from '@/store';
import { PageView, selectSidebarMode } from '@/store/page-view';
import { CenterBox } from '@/web/@components/CenterBox';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollFixedListView } from '@/web/@components/InfiniteScrollView/InfiniteScrollFixedListView';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from '@/web/@layouts/MainLayout/config';
import { useTheme } from '@mui/material';
import { throttle } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EventGridListItem } from '../EventGridListItem';
import { EventListContext } from '../EventListContextProvider';

/** @param {{fullHeight?: boolean}} props */
export function EventInfiniteList(props) {
  const { fullHeight } = props;
  const { t } = useTranslation();
  const { fetcher, tableKey } = useContext(EventListContext);

  useEffect(() => {
    const tid = setTimeout(() => {
      store.dispatch(PageView.setFullWidthLayout(true));
    }, 100);
    return () => clearTimeout(tid);
  }, []);

  return (
    <HubConnectionProvider>
      <InfiniteScrollView
        key={tableKey}
        fetcher={fetcher}
        itemsPerPage={100}
        renderEmpty={() => (
          <CenterBox fullView>
            <IconMessageBox
              size="256px"
              src="/images/empty/no-events.svg"
              message={t(T['events.no.data.message'])}
            />
          </CenterBox>
        )}
        renderList={(state) => <EventFixedListView state={state} fullHeight={fullHeight} />}
      />
    </HubConnectionProvider>
  );
}

/** @param {{fullHeight?: boolean, state: InfiniteListDataFetcherState<EventV5ResponseModel>}} props */
function EventFixedListView(props) {
  const { state, fullHeight } = props;

  const theme = useTheme();
  const isSidebarCollapsed = useSelector(selectSidebarMode);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { md: mediumScreen, lg: largeScreen } = useMemo(() => theme.breakpoints.values, [theme]);

  const drawerWidth = useMemo(
    () => (isSidebarCollapsed ? COLLAPSED_DRAWER_WIDTH : DRAWER_WIDTH),
    [isSidebarCollapsed]
  );
  const listWidth = useMemo(
    () => windowWidth - (windowWidth >= largeScreen ? drawerWidth + 30 : 30),
    [windowWidth, largeScreen, drawerWidth]
  );
  const listHeight = useMemo(
    () => windowHeight - (fullHeight ? 0 : windowWidth >= mediumScreen ? 165 : 200),
    [windowHeight, windowWidth, fullHeight, mediumScreen]
  );
  const columnCount = useMemo(
    () => Math.round((listWidth - 10) / 400), //
    [listWidth]
  );
  const columnWidth = useMemo(
    () => (listWidth - 10) / columnCount, //
    [listWidth, columnCount]
  );
  const rowHeight = useMemo(
    () => 85 + (9 * columnWidth) / 16, //
    [columnWidth]
  );

  useEffect(() => {
    const tid = setTimeout(() => {
      store.dispatch(PageView.setFullWidthLayout(true));
    }, 100);
    return () => clearTimeout(tid);
  }, []);

  useEffect(() => {
    const aborter = new AbortController();
    const updateSize = throttle(() => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }, 100);
    window.addEventListener('resize', updateSize, {
      passive: true,
      signal: aborter.signal,
    });
    return () => {
      aborter.abort();
      updateSize.cancel();
    };
  }, []);

  /** @type {InfiniteScrollFixedListViewProps<EventV5ResponseModel>['renderItem']} */
  const renderItem = useCallback(({ data, style }) => {
    return <EventGridListItem item={data} style={style} p="5px" />;
  }, []);

  return (
    <InfiniteScrollFixedListView
      px={1.5}
      state={state}
      itemKey={(item) => item.id}
      variant={'grid'}
      renderItem={renderItem}
      FixedGridProps={{
        width: listWidth,
        height: listHeight,
        rowHeight,
        columnCount,
        columnWidth,
        overscanRowCount: 1,
      }}
    />
  );
}
