import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ApkFileForm } from '../@components/ApkForm';
import { uploadFirmwareFile } from '../utils';

export function APKFileCreatePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<AbortController>} */
  const [loading, setLoading] = useState();
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);

  /** @type {import('../@components/ApkForm').ApkFileFormProps['onSubmit']} */
  const createAPKFile = async (fields) => {
    if (!fields.apkFile) {
      setProgressModalOpen(false);
      toastWarning('Please select a APK file');
      return;
    }

    const aborter = new AbortController();
    setLoading((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      setProgressModalOpen(true);

      /** @type {CreateFirmwareRequest} */
      const body = {};
      body.firmwareId = uuidv4();
      body.name = fields.name;
      body.version = fields.version;
      body.description = fields.description;
      body.uploadNotes = fields.notes;
      body.firmwareTypeId = 7; //SUPPORTING_APPLICATION
      body.productIdsForSchemesCreationAndAssociation = fields?.apkProducts?.map((i) => i.id);

      await uploadFirmwareFile(fields.apkFile, body, setPercentage, aborter.signal);

      const request = api.ac.v5.firmware.$post({
        data: body,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', 'APK File created');
      navigate('/administration/apk-files');
    } catch (err) {
      if (!aborter.signal.aborted) {
        if (err.response.data?.status === 'CONFLICT') {
          toastWarning('Error', 'This APK File already exists.');
        } else {
          toastWarning('Error', 'Could not create APK File.');
        }
      }
    } finally {
      setLoading(undefined);
      setProgressModalOpen(false);
    }
  };

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Add a New APK File"
          progressText={'Uploading file...'}
          onClose={() => {
            loading?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['apk.flag.create.page.title'])}
      </Typography>
      <ApkFileForm onSubmit={createAPKFile} disabled={Boolean(loading)} />
    </Box>
  );
}
