/**
 * Underscore To Camel case
 * @param {string} string
 * @returns
 */

import { CAR_NAMES } from '@/assets/schemes/carNames';

export const underscoreConstantToCamelCase = (string) => {
  if (string === '') return;
  const str = string.replace(/_/g, ' ');
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');
};

/**
 * Parking Mode Car Name Mapper
 */

export const carNames = CAR_NAMES.map((item) => ({
  label: underscoreConstantToCamelCase(item),
  value: item,
}));

/**
 * Equal check between two items
 * @param {*} a
 * @param {*} b
 * @returns
 */

export const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

/**
 * intersection between two items
 * @param {*} a
 * @param {*} b
 * @returns
 */

export const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

/**
 * Union two items
 * @param {*} a
 * @param {*} b
 * @returns
 */

export const union = (a, b) => {
  return [...a, ...not(b, a)];
};
