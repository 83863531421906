export const SERVER_ENVIRONMENTS = [
  {
    label: 'Production',
    value: 'https://api.smarterai.com',
  },
  {
    label: 'Staging',
    value: 'https://stage-api.smarterai.com',
  },
  {
    label: 'Development',
    value: 'https://dev-api.smarterai.com',
  },
];
