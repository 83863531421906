import { Box, Typography } from '@mui/material';
import { VideoMinutesTenantTable } from './@components/TenantTreeItem';
import { VideoMinutesFilteringArea } from './@components/VideoMinutesFilteringArea';
import { VideoMinutesFilterContextProvider } from './@context/VideoMinutesFilterContext';

export function VideoMinutes() {
  return (
    <Box mt={2} mb={4} mx={2.5}>
      <Typography my={2} variant="body2" fontWeight={500}>
        Video Minutes
      </Typography>
      <VideoMinutesFilterContextProvider>
        <VideoMinutesFilteringArea />
        <VideoMinutesTenantTable />
      </VideoMinutesFilterContextProvider>
    </Box>
  );
}
