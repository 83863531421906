import { SchemeFormLayout } from '@/web/@layouts/SchemeLayout';
import { SchemeListView } from '.';
import { SchemeDetails } from '@/web/smarter-ai/scheme-management/schemes/_id';
import { ApplySchemes } from './apply';
import { SchemesApplied } from './schemes-applied';
import { SchemeLandingPage } from './landing';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <SchemeLandingPage />,
  },
  {
    path: 'list',
    element: <SchemeListView />,
  },
  {
    path: ':id',
    breadcrumb: 'Loading...',
    element: <SchemeFormLayout />,
    children: [
      {
        path: '',
        element: <SchemeDetails />,
        breadcrumb: 'Details',
      },
      {
        path: 'schemes-applied',
        element: <SchemesApplied />,
        breadcrumb: 'Schemes Applied',
      },
    ],
  },
  {
    path: 'apply',
    element: <ApplySchemes />,
    breadcrumb: 'Apply Scheme',
  },
];

export default routes;
