export const deployedAPKsArraysDifferent = (deployedItems, updatedItem, availableSchemes) => {
  const availableDeployedItems = availableSchemes.filter((availableScheme) =>
    deployedItems.some((deployedItem) => deployedItem?.schemeId === availableScheme?.id)
  );

  if (!availableDeployedItems?.length && !updatedItem?.length) return false;
  if (availableDeployedItems?.length !== updatedItem?.length) return true;

  const schemeIds1 = (availableDeployedItems || []).map((obj) => obj?.id || obj?.schemeId);
  const schemeIds2 = (updatedItem || []).map((obj) => obj?.id || obj?.schemeId);

  const difference = schemeIds1
    .filter((id) => !schemeIds2.includes(id))
    .concat(schemeIds2.filter((id) => !schemeIds1.includes(id)));

  return !!difference?.length;
};
