import { GO_DEVICE_OFF, GO_DEVICE_ON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { formatTimestamp } from '@/utils/datetime';

const NETWORK_COLORS = {
  BACKGROUND_INACTIVE: '#F8FAFF',
  BACKGROUND_ACTIVE: '#487CC1',
  BACKGROUND_ACTIVE_HOVER_ACTIVE: '#487DD1',
  ITEM_INACTIVE: '#C8CFE5',
  ITEM_ACTIVE: '#FFFFFF',
};

const getStatusText = (camera) => {
  let status = camera?.pluggedDeviceStatus;
  switch (status) {
    case 'GO_CONNECTING':
    case 'GO_SUBSCRIBING':
    case 'GO_PUBSUB_ERROR':
      return 'Connected to Power';
    case 'GO_PUBSUB_OK':
      return 'Connected to CAN, Power';
    case 'NO_GO_DEVICE':
      return 'Not Connected';
    case 'GO_CONNECTION_FAILED':
    default:
      return camera?.lastPluggedDeviceOkTimestamp
        ? `Disconnected, last connection time ${formatTimestamp(
            camera.lastPluggedDeviceOkTimestamp
          )}`
        : 'Not Connected';
  }
};

export function GoDeviceInfo() {
  const { camera } = useContext(CameraDetailsContext);

  const isGoDevice = useMemo(
    () => camera?.pluggedDevice !== null && camera?.pluggedDevice !== '<NONE>',
    [camera]
  );

  const isConnectedForPower = useMemo(() => {
    const status = camera?.pluggedDeviceStatus;
    return (
      status === 'GO_CONNECTING' || status === 'GO_SUBSCRIBING' || status === 'GO_PUBSUB_ERROR'
    );
  }, [camera]);

  const isConnectedForCANandPower = useMemo(() => {
    return camera?.pluggedDeviceStatus === 'GO_PUBSUB_OK';
  }, [camera]);

  const ICON_NETWORK_COLORS = useMemo(() => {
    if (isConnectedForPower) {
      return {
        BACKGROUND_ACTIVE: '#5E6A7F', // Color for Power connected
        BACKGROUND_ACTIVE_HOVER_ACTIVE: '#5E6A7F',
        ITEM_ACTIVE: '#FFFFFF',
      };
    } else if (isConnectedForCANandPower) {
      return {
        BACKGROUND_ACTIVE: '#487CC1', // Color for CAN and Power connected
        BACKGROUND_ACTIVE_HOVER_ACTIVE: '#487DD1',
        ITEM_ACTIVE: '#FFFFFF',
      };
    } else {
      return {
        BACKGROUND_INACTIVE: '#F8FAFF', // Color for not connected
        BACKGROUND_ACTIVE_HOVER_ACTIVE: '#F8FAFF',
        ITEM_INACTIVE: '#C8CFE5',
      };
    }
  }, [isConnectedForPower, isConnectedForCANandPower]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(false);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(false);
  };

  if (!isGoDevice) return null;

  return (
    <>
      <IconButton
        disableRipple
        sx={{
          'backgroundColor':
            isGoDevice && camera?.isOnline
              ? ICON_NETWORK_COLORS.BACKGROUND_ACTIVE
              : NETWORK_COLORS.BACKGROUND_INACTIVE,
          'height': '40px',
          'width': '40px',
          ':hover': {
            'backgroundColor':
              isGoDevice && camera?.isOnline
                ? ICON_NETWORK_COLORS.BACKGROUND_ACTIVE_HOVER_ACTIVE
                : NETWORK_COLORS.BACKGROUND_INACTIVE,
            '& .MuiSvgIcon-root': {
              color: NETWORK_COLORS.ITEM_INACTIVE,
            },
          },
        }}
        onMouseEnter={(e) => {
          setAnchorEl(e.currentTarget);
          setOpenedPopoverId(true);
        }}
        onMouseLeave={handlePopoverClose}
      >
        <BoxImage
          src={isGoDevice && camera?.isOnline ? GO_DEVICE_ON : GO_DEVICE_OFF}
          height="16px"
          width="17px"
        />
      </IconButton>

      <Popover
        sx={{
          'pointerEvents': 'none',
          'boxShadow': 'none',
          '& .MuiPopover-paper': {
            boxShadow: '1px',
          },
        }}
        open={openedPopoverId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box display="row" p={1}>
          <Typography variant="subtitle2" fontWeight={500} fontSize="12px">
            {getStatusText(camera)}
          </Typography>
          <Typography variant="subtitle2" fontWeight={500} fontSize="12px">
            SN: {camera?.pluggedDevice}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
