import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AddHealthTagButton } from './AddTagButton';
import { CameraHealthTagList } from './CameraTagList';
import { fetchEndpointTags } from './fetchers';

export function HealthTagListBox() {
  const { cameraId } = useContext(CameraDetailsContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<Array<AssignedHealthTagResponse>>} */
  const [cameraTags, setCameraTags] = useState(null);
  /** @type {StateVariable<boolean>} */

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);
    fetchEndpointTags(cameraId, tenantId, secretToken, aborter.signal)
      .then(setCameraTags)
      .catch(console.error)
      .finally(() => setLoading(false));
    return () => aborter.abort();
  }, [cameraId, secretToken, tenantId]);

  /** @param {number} tagId */
  const handleRemove = async (tagId) => {
    try {
      const promises = [];
      for (const tag of cameraTags.filter((x) => x.tagId === tagId)) {
        const request = api.ac.v5.health.tenant
          .$tenantId(tenantId)
          .endpoint.$endpointId(cameraId)
          ['assigned-health-tag'].$id(tag.id)
          .$delete({
            headers: {
              Authorization: secretToken,
            },
          });
        promises.push(request.process());
      }
      await Promise.all(promises);
      const tags = await fetchEndpointTags(cameraId, tenantId, secretToken);
      setCameraTags(tags);
    } catch (err) {
      console.error(err);
      toastWarning('Oops', 'Could not remove the Tag');
    }
  };

  /** @param {number} tagId */
  const handleAdd = async (tagId) => {
    try {
      const request = api.ac.v5.health.tag
        .$tagId(tagId)
        .endpoint.$endpointId(cameraId)
        .$post({
          params: {
            tenantId: tenantId,
          },
          data: {
            resolution: 'NULL',
            healthTagReportType: 'CAMERA',
            resourceId: cameraId + '',
            healthTagReportSubtype: '',
            comment: '',
          },
          headers: {
            Authorization: secretToken,
          },
        });
      await request.process();
      const tags = await fetchEndpointTags(cameraId, tenantId, secretToken);
      setCameraTags(tags);
    } catch (err) {
      console.error(err);
      toastWarning('Oops', 'Could not add the Tag');
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="#F8FAFF"
      overflow="hidden"
      style={{
        maxHeight: 40,
        minWidth: 40,
        borderRadius: 40,
      }}
    >
      {loading ? (
        <Box width="40px" height="40px" padding="8px">
          <CircularProgress size="24px" />
        </Box>
      ) : (
        <>
          <CameraHealthTagList cameraTags={cameraTags} onRemove={handleRemove} />
          <AddHealthTagButton cameraTags={cameraTags} onAdd={handleAdd} onRemove={handleRemove} />
        </>
      )}
    </Box>
  );
}
