import { TRIGGER_ON_CAMERA_ICON, TRIGGER_ON_CLOUD_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';

export function RecordingInfo(props) {
  const { recodingType = 'SD_CARD', defaultRecodingType = 'SD_CARD' } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      display={'inline-flex'}
      gap={'5px'}
      fontSize={{ sx: '1rem', md: '0.875rem' }}
      alignItems="center"
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <BoxImage
        src={recodingType !== 'SD_CARD' ? TRIGGER_ON_CLOUD_ICON : TRIGGER_ON_CAMERA_ICON}
        size="18px"
      />
      <Typography fontSize={{ sx: '1rem', md: '0.875rem' }}>
        {recodingType !== 'SD_CARD' ? 'Cloud' : 'Camera'}
      </Typography>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption" sx={{ fontWeight: 600 }}>
            Default
          </Typography>
          <Box display="flex" gap="5px" alignItems="center">
            <BoxImage
              src={
                defaultRecodingType !== 'SD_CARD' ? TRIGGER_ON_CLOUD_ICON : TRIGGER_ON_CAMERA_ICON
              }
              size="18px"
            />
            <Typography variant="caption">
              {defaultRecodingType !== 'SD_CARD' ? 'Cloud' : 'Camera'}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
