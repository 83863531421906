import { createContext, useEffect, useState } from 'react';
import { FilterContextProvider } from '../FilterContext';

/**
 * @template T
 * @typedef {object} PaginatedTableContextBase<T>
 * @property {TableHeaderAction<T>['type']} selectedType
 * @property {boolean} reloadStatus
 * @property {Array<T>} selectedItems
 * @property {Array<T>} disabledItems
 * @property {number} filteredDataCount
 * @property {StateAction<TableHeaderAction<T>['type']>} setSelectedType
 * @property {StateAction<Array<T>>} setSelectedItems
 * @property {StateAction<Array<T>>} setDisabledItems
 * @property {StateAction<boolean>} setTableReload
 * @property {StateAction<number>} setFilteredDataCount
 */

/**
 * @template T
 * @type {import('react').Context<PaginatedTableContextBase<any>>}
 */
export const PaginatedTableContext = createContext(null);

/** @param {{children: import('react').ReactNode}} props */
function PaginatedTableContextProviderInternal(props) {
  const { children } = props;

  /** @type {StateVariable<TableHeaderAction<T>['type']>} */
  const [selectedType, setSelectedType] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [reloadStatus, setTableReload] = useState(false);
  const [disabledItems, setDisabledItems] = useState([]);
  const [filteredDataCount, setFilteredDataCount] = useState(null);

  useEffect(() => {
    setSelectedItems([]);
    setTableReload(false);
  }, [reloadStatus]);

  return (
    <PaginatedTableContext.Provider
      value={{
        selectedType,
        selectedItems,
        reloadStatus,
        filteredDataCount,
        disabledItems,
        setSelectedItems,
        setSelectedType,
        setTableReload,
        setFilteredDataCount,
        setDisabledItems,
      }}
    >
      {children}
    </PaginatedTableContext.Provider>
  );
}

/** @param {{children: import('react').ReactNode}} props */
export function PaginatedTableContextProvider(props) {
  const { children } = props;
  return (
    <FilterContextProvider>
      <PaginatedTableContextProviderInternal>{children}</PaginatedTableContextProviderInternal>
    </FilterContextProvider>
  );
}
