import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { selectSessionId } from '@/store/hub-connection';
import { HubConnectionContext } from '@/web/@components/HubConnectionContext';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Terminal from 'terminal-in-react';
import { MESSAGE_TYPE } from '@/assets/signalr/config';

/**
 * @typedef {object} CameraTerminalProps
 * @property {EndpointDetailsResponse} camera
 * @property {number} cameraId
 */

/**
 * @param {CameraTerminalProps} props
 */
export function CameraTerminal(props) {
  const { camera, cameraId } = props;
  const secretToken = useSelector(selectSecretToken);
  const sessionId = useSelector(selectSessionId);
  const connection = useContext(HubConnectionContext);

  useEffect(() => {
    if (!connection) return;
    const handler = (/** @type {any} */ message) => {
      if (message?.type === MESSAGE_TYPE.SHELL_EXECUTE) {
        console.log('..........................................................');
        console.log(message?.text);
      }
    };
    connection.on('newMessage', handler);
    return () => connection?.off('newMessage', handler);
  }, [connection]);

  const sendCommandToDevice = async (cmd) => {
    try {
      const request = api.ac.v2.endpoint.shell.execute.$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          endpointId: cameraId + '',
          senderId: sessionId + '',
          shellData: cmd.join(' '),
        },
      });
      await request.process();
      if (request.response.status === 200) {
        return request;
      } else {
        return false;
      }
    } catch (e) {
      console.error('Shell command failed to execute', e);
      return false;
    }
  };

  const terminalMessage = useMemo(
    () =>
      `Camera: ${camera.endpointName || 'Smarter AI Dashcam'}, deviceKey: ${
        camera.deviceSerialNumber
      }, cameraId: ${cameraId}`,
    [camera, cameraId]
  );

  return (
    <Terminal
      watchConsoleLogging
      color="green"
      backgroundColor="black"
      barColor="black"
      startState="maximised"
      style={{ fontWeight: 'bold', fontSize: '1rem', height: '630px' }}
      showActions={false}
      commandPassThrough={(cmd, print) => {
        // @ts-ignore //TODO:: library issue, but need to check
        print(`sending (${cmd?.join(' ')}) command to device.....`);
        sendCommandToDevice(cmd).then(
          (value) => {
            if (value) {
              // @ts-ignore
              print('Message sent successfully, waiting for response...');
            }
          },
          (reason) => {
            // @ts-ignore
            print('Something went wrong');
            // @ts-ignore
            print(reason.toString());
          }
        );
      }}
      msg={terminalMessage}
    />
  );
}
