import { APN_SETTINGS_ICON, CAMERA_N_ON_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { PageLink } from '@/web/@components/PageLink';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

/** @type {Array<DeviceGroupPageCardItem>} */
const navItems = [
  {
    name: 'Cameras',
    icon: CAMERA_N_ON_ICON,
    bgcolor: '#F0F7EF',
    link: 'cameras',
  },
  {
    name: 'APN Settings',
    icon: APN_SETTINGS_ICON,
    bgcolor: '#F2F2ED',
    link: 'apns',
  },
];

export function DeviceGroupCardItems() {
  return (
    <Grid container columnSpacing={3} rowSpacing={2} py={1}>
      {navItems
        .filter((i) => !i?.hidden)
        .map((item, index) => (
          <Grid
            key={item.name + index}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            component={PageLink}
            underline="none"
            to={item.link}
          >
            <Box
              bgcolor={item.bgcolor}
              sx={{ height: '110px', borderRadius: '20px' }}
              px={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize="22px" fontWeight="500" variant="body2">
                {item.name}
              </Typography>
              <BoxImage src={item.icon} height="50px" width="50px" />
            </Box>
          </Grid>
        ))}
    </Grid>
  );
}
