import api from '@/api';
import { NO_SEARCH_RESULT_IMG, PERSONA_ICON } from '@/assets/constants/images';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectTenantId } from '@/store/auth';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Box, Button, CircularProgress } from '@mui/material';
import { sortBy } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ScoringFilterSection } from '../../@components/ScoringFilterSection';
import { ScoringFilterProvider } from '../../@context/ScoringFilterContext';

/**
 * @typedef {object} SafetyScoreLayoutContextData
 * @property {Array<PersonaDto>} personaList
 * @property {Array<Driver>} [drivers]
 * @property {Array<EndpointInfoAggregated>} [cameras]
 */

/** @type {import('react').Context<SafetyScoreLayoutContextData>} */
export const SafetyScoreLayoutContext = createContext(null);

export function SafetyScoreLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = useSelector(selectTenantId);

  const [error] = useState(null);
  const [initialTenantId] = useState(tenantId);

  const { result: personaList, loading: personaLoading } = useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.v5.persona.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      return result.list?.map((item) => {
        if (item?.id === 1) {
          item['name'] = 'Overall Safety';
        }
        return item;
      });
    },
    [],
    { cache: false }
  );

  const { result: drivers = [], loading: driverLoading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      let offset = 0;
      const limit = 500;
      /** @type {Array<Driver>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.driver.list.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
            tenantId,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result.drivers;
        results.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      return results;
    },
    [],
    { cache: false }
  );

  const { result: cameras = [], loading: cameraLoading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      let offset = 0;
      const limit = 500;
      /** @type {Array<EndpointInfoAggregated>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.endpoint.list.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            type: 'DEVICE',
            status: 'ACTIVE',
            limit,
            offset,
            tenantId,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result.endpointInfoList;
        results.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      return sortBy(results, [(item) => item?.deviceLabel?.toLowerCase()]);
    },
    [],
    { cache: false }
  );

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  if (personaLoading || driverLoading || cameraLoading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error) {
    return (
      <CenterBox>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          size="256px"
          message="Failed to Load Safety Score!"
        />
      </CenterBox>
    );
  }

  return (
    <SafetyScoreLayoutContext.Provider
      value={{
        personaList,
        drivers,
        cameras,
      }}
    >
      <ScoringFilterProvider>
        <ErrorBoundary key={location.pathname}>
          <Box
            mx={2.5}
            mt={1}
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              paddingTop: '15px',
              paddingBottom: '1px',
              zIndex: 1000,
            }}
          >
            {!location.pathname?.includes('/safety/scoring/persona') ? (
              <>
                {location.pathname === '/safety/scoring' && (
                  <Box display="flex" justifyContent="flex-end" pb={0.5}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => navigate('/safety/scoring/persona')}
                    >
                      <Box display="flex" gap={0.6} alignItems="center">
                        <BoxImage src={PERSONA_ICON} size="15px" /> Persona
                      </Box>
                    </Button>
                  </Box>
                )}
                <ScoringFilterSection />
              </>
            ) : (
              ''
            )}
          </Box>
          <Outlet />
        </ErrorBoundary>
      </ScoringFilterProvider>
    </SafetyScoreLayoutContext.Provider>
  );
}
