import { Box } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MemoizedEffectiveSchemePropertyFormItem } from './SchemePropertyFormItem';

export function SchemeEffectivePropertiesForm(props) {
  const { productInputs, triggers, settings } = props;

  const { control } = useFormContext();

  const { fields } = useFieldArray({ control, name: 'properties' });

  return (
    <Box mb="15px" mt="10px">
      {fields?.map((itemField, index) => {
        return (
          <Box display="flex" justifyContent="space-between" key={itemField.id + index}>
            <MemoizedEffectiveSchemePropertyFormItem
              fieldIndex={index}
              triggers={triggers}
              settings={settings}
              productInputs={productInputs}
              // @ts-ignore
              effective={itemField?.effective}
            />
          </Box>
        );
      })}
    </Box>
  );
}
