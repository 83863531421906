import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';

export function useFetchDeviceBatches() {
  return useFutureLoader(
    async ({ signal, secretToken }) => {
      const limit = 25;
      let offset = 0;
      /** @type {Array<DeviceBatchResponse>} */
      const results = [];
      while (!signal.aborted) {
        const request = api.ac.v5['device-batch'].$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result;
        results.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      return results;
    },
    [],
    { cache: true }
  );
}
