import { BOX_DISCONTINUE, BOX_MODIFY_ICON, BOX_RELEASE_ICON } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';
import { T } from '@/assets/locales';

/** @type {Array<TableHeaderAction<AIModelV2WithAccelerator>>} */
export const ACTION_ITEMS = [
  {
    label: T['button.release'],
    iconImage: BOX_RELEASE_ICON,
    component: 'BUTTON',
    type: 'RELEASE',
    variant: 'outlined',
    confirmationMessage: T['ai.model.confirmations.release'],
    hidden: (selected) =>
      !selected.length || selected.length > 1 || selected[0]?.publicationStatus === 'RELEASED',
  },
  {
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    confirmationMessage: T['ai.model.confirmations.discontinue'],
    hidden: (selected) => !selected.length,
  },
  {
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selectedItem) => `/administration/ai/ai-models/${selectedItem.modelId}/edit`,
    hidden: (selected) =>
      !selected.length || selected.length > 1 || selected[0]?.publicationStatus === 'RELEASED',
  },
  {
    label: 'Search',
    placeholder: T['ai.models.table.search.placeholder'],
    component: 'SEARCH',
  },
  {
    label: T['ai.models.table.button.add'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/ai/ai-models/create',
  },
];
