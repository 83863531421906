import { AI_LABEL_NAME } from '../ai-labels';

export class BoundingBoxV4Renderer {
  /**
   * @param {import('.').BoundingBoxRenderer} parent
   */
  constructor(parent) {
    this.parent = parent;
    this.ctx = parent.ctx;
    this.canvas = parent.canvas;
    this.options = parent.options;
    this.labelRenderer = parent.labelRenderer;
  }

  /**
   * Draw v4 Bounding box
   * @param {VideoMetaData} item
   */
  render(item) {
    const { ts, dimen, figures } = item;
    const { imagerWidth, imagerHeight } = this.options;
    this.scaleX = (dimen[1] || imagerWidth) / this.canvas.width;
    this.scaleY = (dimen[0] || imagerHeight) / this.canvas.height;
    for (const figure of figures) {
      if (figure.type === 'rect') {
        this.drawRect(ts, figure);
      } else if (figure.type === 'point') {
        this.drawPoint(ts, figure);
      } else if (figure.type === 'line') {
        this.drawQuadraticCurve(ts, figure);
      }
    }
  }

  /**
   * Draw Rectangle Bounding box
   * @param {number} ts
   * @param {BoundingBoxFigure} figure
   */
  drawRect(ts, figure) {
    const { data, detections } = figure;
    const { scale, triggerLabels, startTime, endTime } = this.options;
    let { lineWidth, lineDash, meshGap } = this.options;

    // hide small bounding boxes at top-left
    if ((data[0] < 5 || data[1] < 5) && (data[2] - data[0]) * (data[3] - data[1]) <= 10) {
      return;
    }

    let x = data[0] / this.scaleX;
    let y = data[1] / this.scaleY;
    let width = (data[2] - data[0]) / this.scaleX;
    let height = (data[3] - data[1]) / this.scaleY;
    if (width === 0 && height === 0) {
      x = 0;
      y = 2 * scale;
    }
    if (width < 0 || height < 0) {
      return;
    }

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.lineWidth = lineWidth;
    this.ctx.globalAlpha = 0.03;
    this.ctx.strokeStyle = '#888';
    for (let i = 0; i < height; i += meshGap) {
      this.ctx.moveTo(x, y + i);
      this.ctx.lineTo(x + width, y + i);
      this.ctx.stroke();
    }
    for (let i = 0; i < width; i += meshGap) {
      this.ctx.moveTo(x + i, y);
      this.ctx.lineTo(x + i, y + height);
      this.ctx.stroke();
    }
    this.ctx.closePath();
    this.ctx.restore();

    let boxColor = this.options.boxColor;
    if (ts >= startTime && ts <= endTime) {
      if (detections.find((x) => triggerLabels.includes(AI_LABEL_NAME[x[0]]))) {
        boxColor = '#B32134';
      }
    }

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.lineWidth = 2 * lineWidth;
    this.ctx.strokeStyle = boxColor;
    this.ctx.setLineDash([lineDash]);
    this.ctx.strokeRect(x, y, width, height);
    this.ctx.strokeRect(x, y, width, height);
    this.ctx.closePath();
    this.ctx.restore();

    this.labelRenderer.render(detections, x, y, width, height);
  }

  /**
   * Draw Point Bounding box
   * @param {number} ts
   * @param {BoundingBoxFigure} figure
   */
  drawPoint(ts, figure) {
    const { data, detections } = figure;
    const { scale, triggerLabels, startTime, endTime } = this.options;
    let { lineWidth, lineDash, meshGap } = this.options;

    lineWidth *= scale;
    lineDash *= scale;
    meshGap *= scale;
    let x = data[0] / this.scaleX;
    let y = data[1] / this.scaleY;
    let width = 0;
    let height = 0;
    if (width === 0 && height === 0) {
      x = 0;
      y = 2 * scale;
    }
    if (width < 0 || height < 0) {
      return;
    }

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.lineWidth = lineWidth;
    this.ctx.globalAlpha = 0.03;
    this.ctx.strokeStyle = '#888';
    for (let i = 0; i < height; i += meshGap) {
      this.ctx.moveTo(x, y + i);
      this.ctx.lineTo(x + width, y + i);
      this.ctx.stroke();
    }
    for (let i = 0; i < width; i += meshGap) {
      this.ctx.moveTo(x + i, y);
      this.ctx.lineTo(x + i, y + height);
      this.ctx.stroke();
    }
    this.ctx.closePath();
    this.ctx.restore();

    let boxColor = this.options.boxColor || '#B32134';
    if (ts >= startTime && ts <= endTime) {
      if (detections.find((x) => triggerLabels.includes(AI_LABEL_NAME[x[0]]))) {
        boxColor = '#B32134';
      }
    }

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.lineWidth = 2 * lineWidth;
    this.ctx.strokeStyle = boxColor;
    this.ctx.setLineDash([lineDash]);
    this.ctx.strokeRect(x, y, width, height);
    this.ctx.strokeRect(x, y, width, height);
    this.ctx.closePath();
    this.ctx.restore();

    this.labelRenderer.render(detections, x, y, width, height);
  }

  /**
   * For Line Draw by QuadraticCurve
   * @param {number} ts
   * @param {BoundingBoxFigure} figure
   */
  drawQuadraticCurve(ts, figure) {
    const { data, detections, style } = figure;
    if (!data.length) return;

    const points = data;
    const pointHalfLength = Math.round(points.length / 2);

    const middlePoint = data[pointHalfLength];
    if (!middlePoint) return;

    let x = middlePoint[0] / this.scaleX;
    let y = middlePoint[1] / this.scaleY;
    let width = 0;
    let height = 0;

    // Set initial and final line widths
    const initialWidth = 40;
    const finalWidth = 10;

    // Set the color of the curve
    const curveColor = style?.color || '#36AAFF';

    this.ctx.beginPath();
    this.ctx.moveTo(points[0][0] / this.scaleX, points[0][1] / this.scaleY);

    for (let i = 1; i < points.length - 1; i++) {
      const xVal = points[i][0] / this.scaleX;
      const yVal = points[i][1] / this.scaleY;

      const xNextVal = points[i + 1][0] / this.scaleX;
      const yNextVal = points[i + 1][1] / this.scaleY;

      let x = (xVal + xNextVal) / 2;
      let y = (yVal + yNextVal) / 2;

      if (this.canvas.width > 1000) {
        const lineWidth = initialWidth + (finalWidth - initialWidth) * (i / (points.length - 1));
        this.ctx.lineWidth = lineWidth;
      } else {
        this.ctx.lineWidth = 5;
      }

      this.ctx.strokeStyle = curveColor;
      this.ctx.quadraticCurveTo(xVal, yVal, x, y);
    }

    this.ctx.lineTo(
      points[points.length - 1][0] / this.scaleX,
      points[points.length - 1][1] / this.scaleY
    );
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();

    this.labelRenderer.render(detections, x, y, width, height);
  }
}
