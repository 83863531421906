import { Box, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { TRIGGER_COLOR } from '../../assets';

export function CoolDownTimeBar(props) {
  const {
    isEnable,
    defaultCoolDownEnable = false,
    coolDownTimer = 0,
    defaultCoolDownTimer = 0,
    min = 0,
    max = 100,
    isHovered,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  // Normalize the value to be between 0 and 100
  const normalizedValue = Math.min(max, Math.max(min, coolDownTimer));
  const defaultPercentage = (defaultCoolDownTimer / coolDownTimer) * 100;

  const lineStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '90px',
    height: '4.6px',
    backgroundColor:
      defaultCoolDownTimer !== coolDownTimer
        ? TRIGGER_COLOR.SHALLOW_RED
        : isHovered
          ? '#fff'
          : '#ECF0F4',
    position: 'relative',
    borderRadius: '50px !important',
  };

  const circleStyle = {
    width: '11px',
    height: '11px',
    borderRadius: '70%',
    border: '2px solid white',
    backgroundColor:
      coolDownTimer === defaultCoolDownTimer ? TRIGGER_COLOR.BLUE : TRIGGER_COLOR.RED,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '-10px',
  };

  const redLineStyle = {
    flexGrow: 1,
    height: '100%',
    background:
      coolDownTimer === defaultCoolDownTimer
        ? TRIGGER_COLOR.BLUE
        : `linear-gradient(to right, red ${defaultPercentage}%, #FF8A8A ${
            100 - defaultPercentage
          }%)`,
    margin: `0 ${100 - normalizedValue}% 0 0`,
    borderRadius: '50px !important',
    width: '100%',
  };

  return (
    <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      {!Boolean(isEnable) ? (
        <Box
          fontSize="0.875rem"
          alignItems="center"
          aria-owns={open ? 'mouse-over-popover' : undefined}
        >
          <Typography fontSize="0.875rem" color="#b6c4d5">
            Turned Off
          </Typography>
        </Box>
      ) : (
        <Box
          height="15px"
          minWidth="100px"
          width="100px"
          margin="auto"
          sx={{ ...lineStyle, mt: -1 }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
        >
          <Box sx={redLineStyle}></Box>
          <Box sx={{ ...circleStyle, left: `${normalizedValue}%` }}>
            <Box pt={0.4}></Box>
            <Typography
              variant="caption"
              sx={{ whiteSpace: 'nowrap', ml: !coolDownTimer ? 0 : '-11px' }}
            >
              {coolDownTimer} sec
            </Typography>
          </Box>
        </Box>
      )}

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption" fontSize={'12px'} fontWeight={600}>
            Default
          </Typography>

          <Typography variant="caption">
            Cool Down Status: {defaultCoolDownEnable ? 'Enabled' : 'Disabled'}
          </Typography>

          <Typography variant="caption">Cool Down: {defaultCoolDownTimer} sec</Typography>
        </Box>
      </Popover>
    </Box>
  );
}
