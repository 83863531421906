import { useCameraList } from '@/hooks/useCameraList';
import { trimString } from '@/utils/formatting';
import qs from 'qs';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {object} FilterParams
 * @property {string} [driverName]
 * @property {number} [scoreFrom]
 * @property {number} [scoreTo]
 * @property {string} [deviceName]
 * @property {string} [deviceSerialNumber]
 * @property {string} [triggerName]
 * @property {string} [annotationName]
 * @property {Array<EndpointInfoAggregated>} [endpoints]
 */

/** @type {FilterParams} */
const defaultValues = {
  driverName: '',
  scoreFrom: 0,
  scoreTo: 100,
  deviceName: '',
  deviceSerialNumber: '',
  triggerName: '',
  annotationName: '',
};

const paramAlias = {
  driverName: 'driver',
  scoreFrom: 'scoreFrom',
  scoreTo: 'scoreTo',
  deviceName: 'camera',
  deviceSerialNumber: 'serial',
  triggerName: 'trigger',
  annotationName: 'annotation',
};

/** @type {import("react").Context<FilterParams>} */
export const CoachingFilterContext = createContext(null);

/** @type {import("react").Context<(params: FilterParams, replace?: boolean) => any>} */
export const SetCoachingFilterContext = createContext(null);

/** @param {{children: import("react").ReactNode}} props */
function SetCoachingFilterContextProvider(props) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  /**
   * @type {(data: {[key: string]: any}, replace?: boolean) => any}
   */
  const setCoachingFilter = useCallback(
    (data, replace = false) => {
      let params = {};
      for (const key of Object.keys(data)) {
        if (paramAlias.hasOwnProperty(key)) {
          params[paramAlias[key]] = data[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          params[key] = data[key];
        }
      }

      const query = qs.parse(location.search.substring(1));
      if (!replace) {
        params = { ...query, ...params };
      }

      for (const key of Object.keys(params)) {
        if (key in params) {
          if (!params[key]) delete params[key];
          else params[key] = trimString(params[key]);
        }
      }

      if (
        JSON.stringify(Object.entries(query).sort()) ===
        JSON.stringify(Object.entries(params).sort())
      ) {
        return;
      }

      navigate(
        {
          ...location,
          search: qs.stringify(params),
        },
        { replace: false }
      );
    },
    [location, navigate]
  );

  return (
    <SetCoachingFilterContext.Provider value={setCoachingFilter}>
      {children}
    </SetCoachingFilterContext.Provider>
  );
}

/** @param {{children: import("react").ReactNode, defaults?: FilterParams}} props */
export function CoachingFilterContextProvider(props) {
  const { children, defaults = defaultValues } = props;

  const location = useLocation();

  const { result: endpoints } = useCameraList(false);

  /** @type {StateVariable<FilterParams>} */
  const [value, setValue] = useState(defaults);

  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  /** @type {(value: any, defaultValue: string) => string} */
  const toString = (value, defaultValue) => (value || defaultValue || '') + '';

  /** @type {(value: any, defaultValue: number) => number} */
  const toNumber = (value, defaultValue) => Number(value) || defaultValue || 0;

  useEffect(() => {
    const driverName = toString(query.driver, defaults.driverName);
    setValue((v) => (v.driverName === driverName ? v : { ...v, driverName }));
  }, [query.driver, defaults]);

  useEffect(() => {
    const scoreFrom = toNumber(query.scoreFrom, defaults.scoreFrom);
    setValue((v) => (v.scoreFrom === scoreFrom ? v : { ...v, scoreFrom }));
  }, [query.scoreFrom, defaults]);

  useEffect(() => {
    const scoreTo = toNumber(query.scoreTo, defaults.scoreTo);
    setValue((v) => (v.scoreFrom === scoreTo ? v : { ...v, scoreTo }));
  }, [query.scoreTo, defaults]);

  useEffect(() => {
    const deviceName = toString(query.camera, defaults.deviceName);
    setValue((v) => (v.deviceName === deviceName ? v : { ...v, deviceName }));
  }, [query.camera, defaults]);

  useEffect(() => {
    const deviceSerialNumber = toString(query.serial, defaults.deviceSerialNumber);
    setValue((v) =>
      v.deviceSerialNumber === deviceSerialNumber ? v : { ...v, deviceSerialNumber }
    );
  }, [query.serial, defaults]);

  useEffect(() => {
    const triggerName = toString(query.trigger, defaults.triggerName);
    setValue((v) => (v.triggerName === triggerName ? v : { ...v, triggerName }));
  }, [query.trigger, defaults]);

  useEffect(() => {
    const annotationName = toString(query.annotation, defaults.annotationName);
    setValue((v) => (v.annotationName === annotationName ? v : { ...v, annotationName }));
  }, [query.annotation, defaults]);

  return (
    <SetCoachingFilterContextProvider>
      <CoachingFilterContext.Provider value={{ ...value, endpoints }}>
        {children}
      </CoachingFilterContext.Provider>
    </SetCoachingFilterContextProvider>
  );
}
