import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';

export function useTriggerVariables() {
  return useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.v5.trigger.variable.definition.all.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      const results = await request.process();
      return results;
    },
    [],
    { cache: false }
  );
}
