import { T } from '@/assets/locales';
import { TriggerVariableListView } from '.';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <TriggerVariableListView />,
    breadcrumb: T['device-batch.title'],
  },
];

export default routes;
