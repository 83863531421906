import { formatDuration, formatTimestamp } from '@/utils/datetime';
import { differenceInMilliseconds } from 'date-fns';

/** @type {Array<TableColumn<PresenceHistoryDTO>>} */
export const CAMERA_PRESENCE_TABLE_COLUMNS = [
  {
    id: 'onlineAt',
    label: 'Online At',
    align: 'left',
    searchable: true,
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'offlineAt',
    label: 'Offline At',
    align: 'left',
    searchable: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: '',
    label: 'Online Duration',
    align: 'left',
    searchable: true,
    format: (value, row) => {
      let diff = 0;
      const onlineTime = new Date(row.onlineAt);
      if (row?.offlineAt) {
        diff = differenceInMilliseconds(new Date(row.offlineAt), onlineTime);
      } else {
        diff = differenceInMilliseconds(new Date(), onlineTime);
      }
      return formatDuration(diff, { short: false });
    },
  },
];
