import { formatTimestamp } from '@/utils/datetime';
import { formatFirmwareName } from '@/utils/firmwares';

/** @type {Array<TableColumn<Endpoint>>} */
export const POOL_DEPLOYABLE_FIRMWARE_TABLE_COLUMNS = [
  {
    id: 'firmwareId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    searchable: true,
    type: 'CHECKBOX',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    sort: true,
    searchable: true,
  },
  {
    id: 'version',
    label: 'Version',
    sort: true,
    searchable: true,
  },
  {
    id: 'type',
    label: 'Package Type',
    align: 'left',
    sort: true,
    searchable: true,
    format: (value) => formatFirmwareName(value),
  },
  {
    id: 'createTime',
    label: 'Created At',
    align: 'center',
    sort: true,
    searchable: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    sort: true,
    searchable: true,
  },
];
