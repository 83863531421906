import { CustomLogger } from '@/utils/logger';
import { useCallback, useEffect, useState } from 'react';

const logger = new CustomLogger('Fullscreen');

const available = Boolean(
  document.fullscreenEnabled ||
    document.body.requestFullscreen || // @ts-ignore
    document.body.webkitRequestFullscreen || // @ts-ignore
    document.body.mozRequestFullScreen || // @ts-ignore
    document.body.msRequestFullscreen ||
    false
);

export function isInFullscreen() {
  return Boolean(
    document.fullscreenElement || // @ts-ignore
      document.webkitFullscreenElement || // @ts-ignore
      document.mozFullScreenElement || // @ts-ignore
      document.msFullscreenElement
  );
}

/**
 * @typedef {object} FullscreenControlParams
 * @property {boolean} available
 * @property {boolean} fullscreen
 * @property {() => Promise<boolean>} exitFullscreen
 * @property {(elem?: HTMLElement) => Promise<boolean>} openFullscreen
 */

/**
 * @returns {FullscreenControlParams}
 */
export function useFullscreenControl() {
  const [fullscreen, setFullscreen] = useState(isInFullscreen());

  useEffect(() => {
    const handler = () => setFullscreen(isInFullscreen());
    document.addEventListener('fullscreenchange', handler, { passive: true });
    document.addEventListener('mozfullscreenchange', handler, { passive: true });
    document.addEventListener('MSFullscreenChange', handler, { passive: true });
    document.addEventListener('webkitfullscreenchange', handler, { passive: true });
    return () => {
      document.removeEventListener('fullscreenchange', handler);
      document.removeEventListener('mozfullscreenchange', handler);
      document.removeEventListener('MSFullscreenChange', handler);
      document.removeEventListener('webkitfullscreenchange', handler);
    };
  }, []);

  const openFullscreen = useCallback(async (/** @type {HTMLElement} */ elem = document.body) => {
    if (!elem) return;
    try {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } // @ts-ignore
      else if (elem.webkitRequestFullscreen) {
        // @ts-ignore
        await elem.webkitRequestFullscreen();
      } // @ts-ignore
      else if (elem.mozRequestFullScreen) {
        // @ts-ignore
        await elem.mozRequestFullScreen();
      } // @ts-ignore
      else if (elem.msRequestFullscreen) {
        // @ts-ignore
        await elem.msRequestFullscreen();
      } else {
        logger.log('Fullscreen is not available');
        return false;
      }
      return true;
    } catch (err) {
      logger.error('Failed to open full screen', err);
    }
  }, []);

  const exitFullscreen = useCallback(async () => {
    try {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } // @ts-ignore
      else if (document.webkitExitFullscreen) {
        // @ts-ignore
        await document.webkitExitFullscreen();
      } // @ts-ignore
      else if (document.mozExitFullScreen) {
        // @ts-ignore
        await document.mozExitFullScreen();
      } // @ts-ignore
      else if (document.msExitFullscreen) {
        // @ts-ignore
        await document.msExitFullscreen();
      } else {
        logger.log('Fullscreen is not available');
        return false;
      }
      return true;
    } catch (err) {
      logger.error('Failed to exit fullscreen', err);
    }
  }, []);

  return {
    available,
    fullscreen,
    openFullscreen,
    exitFullscreen,
  };
}
