import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DeviceUsagesContext } from '../../../../@contexts/DeviceUsagesContext';
import { API_CALL_ITEM_PER_PAGE, USAGES_TAB_HEIGHT } from '../../@assets/usages';
import { parseObject } from '../../@utils';
import { GROUP_CAMERA_NETWORK_CHANGE_TABLE_COLUMNS_PUBSUB } from './columnNewSource';
import { GROUP_CAMERA_NETWORK_CHANGE_TABLE_COLUMNS } from './columns';

export function CameraNetworkChangesRecords() {
  const { cameraId } = useContext(CameraDetailsContext);
  const { isNewDataSource } = useContext(DeviceUsagesContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const fetchDeviceNetworkChangeDataFromOldSource = async (signal, offset, limit) => {
    try {
      const request = api.ac.v3.report.device.records.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          secretToken,
          pageSize: limit,
          endpointId: cameraId,
          subtype: 'network-change',
          continuationToken: offset,
        },
      });
      await request.process();
      const result = request.result.data;
      const mappedData = result.map((item, index) => {
        const properties = parseObject(item.properties) || {};
        return {
          ...item,
          ...properties,
        };
      });
      const token = request.result.continuationToken;
      return { result: mappedData, token };
    } catch (ex) {
      return { result: [], token: null };
    }
  };

  const fetchDeviceNetworkChangeDataFromNewSource = async (signal, offset, limit) => {
    try {
      const request = api.ac.v5.device.records['network-info'].search.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          pageSize: limit,
          endpoint: cameraId + '',
          pageNumber: offset,
        },
      });
      await request.process();
      const result = request.result.deviceRecords;
      const token = offset + 1;
      return { result: result, token };
    } catch (ex) {
      return { result: [], token: null };
    }
  };

  const fetchDeviceNetworkChangeData = async (signal, offset, limit) => {
    if (isNewDataSource) {
      return await fetchDeviceNetworkChangeDataFromNewSource(signal, offset, limit);
    } else {
      return await fetchDeviceNetworkChangeDataFromOldSource(signal, offset, limit);
    }
  };

  return (
    <Box mt={2} mb={5} height="100%">
      <PaginatedTableContextProvider>
        <InfiniteScrollView
          key={generateUniqueKey([tenantId, cameraId])}
          initialToken={null}
          fullView={false}
          itemsPerPage={API_CALL_ITEM_PER_PAGE}
          // @ts-ignore
          fetcher={async ({ signal, limit, token: offset }) => {
            return await fetchDeviceNetworkChangeData(signal, offset, limit);
          }}
          renderEmpty={() => (
            <IconMessageBox
              size="256px"
              src="/images/player/no-data-icon.svg"
              message="No Data Found"
            />
          )}
          renderList={(state) => (
            <InfiniteScrollTableView
              state={state}
              // @ts-ignore
              columns={
                isNewDataSource
                  ? GROUP_CAMERA_NETWORK_CHANGE_TABLE_COLUMNS_PUBSUB
                  : GROUP_CAMERA_NETWORK_CHANGE_TABLE_COLUMNS
              }
              sx={{ height: USAGES_TAB_HEIGHT }}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
