import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { sortedUniq } from 'lodash';
import { useContext, useMemo } from 'react';
import { MONTHS_ARRAY } from '../../@assets';
import {
  SetVideoMinutesFilterContext,
  VideoMinutesFilterContext,
} from '../../@context/VideoMinutesFilterContext';

export function VideoMinutesFilteringArea() {
  const setVideoMinutesFilter = useContext(SetVideoMinutesFilterContext);
  const { month, searchText } = useContext(VideoMinutesFilterContext);

  const monthNames = useMemo(() => sortedUniq((MONTHS_ARRAY || []).map((x) => x.label)), []);

  return (
    <PageFilteringArea
      value={{ month, searchText }}
      onChange={(data) => {
        setVideoMinutesFilter({
          searchText: data.searchText,
          month: data.month,
        });
      }}
      filters={[
        {
          type: 'TEXT',
          key: 'searchText',
          title: 'Entity',
        },
        {
          type: 'CHOICE',
          key: 'month',
          title: 'Month',
          submenu: monthNames,
          disableClose: true,
        },
      ]}
    />
  );
}
