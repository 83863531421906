import { Box } from '@mui/system';
import { DeviceGroupCardItems } from './@components/DeviceGroupCardItems';
import { DeviceGroupInfo } from './@components/DeviceGroupInfo';

export function DeviceGroupDetails() {
  return (
    <Box px={3} py={1}>
      <DeviceGroupInfo />
      <DeviceGroupCardItems />
    </Box>
  );
}
