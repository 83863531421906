import { IMAGE_UPLOAD_LIMIT, PICTURE_UPLOAD_ACCEPTED_EXTENSIONS } from '@/assets/file';
import { toastWarning } from '@/utils/toaster';
import { FileUpload } from '@mui/icons-material';
import { Box, Button, FormLabel, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { get, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} DriverFileUploadProps
 * @property {string | number} [driverId]
 * @property {string} label
 * @property {string} [path]
 * @property {string} name
 * @property {(file: File) => any} onDropFile
 * @property {import('react-hook-form').ControllerProps['rules']} [rules]
 */

/** @param {DriverFileUploadProps} props */
export function DriverFileUpload(props) {
  const { name, path, label, onDropFile } = props;
  const theme = useTheme();
  const { watch, formState } = useFormContext();

  const error = get(formState.errors, name);

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      onDropFile && onDropFile(acceptedFiles[0]);
    },
    [onDropFile]
  );

  const onDropRejected = () => {
    toastWarning('Maximum file upload size is 5MB');
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    maxFiles: 1,
    minSize: 0,
    maxSize: IMAGE_UPLOAD_LIMIT, //bytes
    accept: {
      'image/*': PICTURE_UPLOAD_ACCEPTED_EXTENSIONS,
    },
  });

  const file = watch(name);

  const uploadedFile = useMemo(
    () =>
      file
        ? Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        : null,
    [file]
  );

  return (
    <Box mb={2}>
      <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
        {label}
      </FormLabel>
      <Button
        {...getRootProps()}
        fullWidth
        variant="text"
        style={{
          padding: '20px',
          textAlign: 'center',
          borderRadius: '4px',
          border: '1px dashed #C5D9F0',
          fontWeight: 'normal',
          fontSize: '0.875rem',
          color: error
            ? theme.palette.error.main
            : ''
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
        }}
      >
        {!uploadedFile && !path ? (
          <>
            <input {...getInputProps()} />
            <Box>{"Drag 'n' drop the file here, or click to select image"}</Box>
            <Box flex={1} />
            <FileUpload htmlColor="#596A82" />
          </>
        ) : uploadedFile ? (
          <Box key={uploadedFile?.name} height="200px">
            <img
              style={{
                height: '100%',
                width: '100%',
              }}
              src={uploadedFile?.preview}
              onLoad={() => {
                URL.revokeObjectURL(uploadedFile?.preview);
              }}
              alt=""
            />
          </Box>
        ) : (
          <Box height="200px">
            <img
              style={{
                height: '100%',
                width: '100%',
              }}
              src={path}
              alt=""
            />
          </Box>
        )}
      </Button>
    </Box>
  );
}
