import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { intersection, not, union } from '@/utils/group-settings';
import { CenterBox } from '@/web/@components/CenterBox';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { unionBy, uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export function SchemeSelection(props) {
  const { property, submitCount, camera, group, tenant } = props;

  const { setValue } = useFormContext();

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [checked, setChecked] = useState([]);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);

  const [appliedSchemeFetchLoading, setAppliedSchemeFetchLoading] = useState(false);
  const [deployedItems, setDeployedItems] = useState([]);
  const [isSubItemSelected, setIsSubItemSelected] = useState(false);

  const leftChecked = intersection(checked, leftItems);
  const rightChecked = intersection(checked, rightItems);

  const { result: availableSchemes = [], loading: availableSchemesLoading } = useFutureLoader(
    async ({ signal, secretToken }) => {
      let offset = 0;
      const limit = 100;
      /** @type {Array<SchemeInfo>} */
      const results = [];
      while (!signal.aborted) {
        const request = api.ac.v5.scheme.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
            tenantId: tenant?.tenantId || tenantId,
          },
        });
        await request.process();
        // @ts-ignore TODO:: API type fix
        const result = request.result.list;
        results.push(...result);
        offset += result?.length;
        if (result?.length < limit) break;
      }
      return unionBy(results, 'id');
    },
    [tenant],
    { cache: false }
  );

  const getEndpointSchemes = useCallback(() => {
    if (!camera?.endpointId) return;
    setAppliedSchemeFetchLoading(true);
    const request = api.ac.v5.scheme.deploy.endpoint.$endpointId(camera?.endpointId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then((res) => {
        const deployedItems =
          uniqBy(
            res?.values?.filter((i) => i.targetLevel === 'ENDPOINT'),
            'schemeId'
          ) || [];
        setValue('deployedItems', deployedItems);
        setDeployedItems(deployedItems);
      })
      .finally(() => {
        setAppliedSchemeFetchLoading(false);
      });
  }, [secretToken, setValue, camera?.endpointId]);

  const getGroupSchemes = useCallback(() => {
    if (!group?.id) return;
    setAppliedSchemeFetchLoading(true);
    const request = api.ac.v5.scheme.deploy.group.$groupId(group?.id).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then((res) => {
        const deployedItems =
          uniqBy(
            res?.values?.filter((i) => i.targetLevel === 'GROUP'),
            'schemeId'
          ) || [];
        setValue('deployedItems', deployedItems);
        setDeployedItems(deployedItems);
      })
      .finally(() => {
        setAppliedSchemeFetchLoading(false);
      });
  }, [secretToken, setValue, group?.id]);

  const getTenantSchemes = useCallback(() => {
    if (!tenant?.tenantId) return;
    setAppliedSchemeFetchLoading(true);
    const request = api.ac.v5.scheme.deploy.tenant.$tenantId(tenant?.tenantId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then((res) => {
        const deployedItems =
          uniqBy(
            res?.values?.filter((i) => i.targetLevel === 'TENANT'),
            'schemeId'
          ) || [];
        setValue('deployedItems', deployedItems);
        setDeployedItems(deployedItems);
      })
      .finally(() => {
        setAppliedSchemeFetchLoading(false);
      });
  }, [secretToken, setValue, tenant?.tenantId]);

  useEffect(() => {
    setValue('deployedItems', []);
    setDeployedItems([]);
    setRightItems([]);
    getEndpointSchemes();
    getTenantSchemes();
    getGroupSchemes();
    if (!camera && !tenant && !group) return;
    setIsSubItemSelected(true);
  }, [
    camera,
    tenant,
    group,
    submitCount,
    getEndpointSchemes,
    getTenantSchemes,
    getGroupSchemes,
    setDeployedItems,
    setValue,
  ]);

  const handleToggle = (value) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => {
    return intersection(checked, items)?.length;
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRightItems(rightItems.concat(leftChecked));
    setLeftItems(not(leftItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftItems(leftItems.concat(rightChecked));
    setRightItems(not(rightItems, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    if (availableSchemesLoading && !deployedItems?.length) return;
    const filteredArray = availableSchemes?.filter((item) =>
      deployedItems.some((dep) => item.id === Number(dep.schemeId))
    );
    setRightItems(filteredArray);
  }, [availableSchemes, availableSchemesLoading, deployedItems]);

  useEffect(() => {
    let filteredArray = availableSchemes?.filter((x) => !rightItems.includes(x));
    setLeftItems(filteredArray);
  }, [rightItems, availableSchemes]);

  useEffect(() => {
    setValue(property, rightItems);
  }, [rightItems, setValue, property]);

  useEffect(() => {
    setValue('availableSchemes', availableSchemes);
  }, [availableSchemes, setValue]);

  const customList = (title, items, notFoundMsg, loading) => {
    const itemsLength = items?.length;
    const checkedItemsLength = numberOfChecked(items);
    return (
      <Card sx={{ width: '100%', border: '1px solid #C5D9F0', borderRadius: '15px' }} elevation={0}>
        <CardHeader
          sx={{
            'px': 2,
            'py': 0.5,
            'color': '#0B2547',
            'fontSize': '1rem',
            '& .MuiCardHeader-title': {
              fontWeight: 500,
              opacity: 0.75,
            },
            '& .MuiCardHeader-avatar': {
              mr: 1,
            },
          }}
          avatar={
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  color:
                    itemsLength === 0
                      ? '#d3e0f2'
                      : itemsLength === checkedItemsLength
                        ? '#596a81'
                        : checkedItemsLength > 0
                          ? 'rgba(89, 106, 129, 0.7)'
                          : '#d3e0f2',
                  opacity: 1,
                },
              }}
              disabled={itemsLength === 0}
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === itemsLength && itemsLength !== 0}
              indeterminate={numberOfChecked(items) !== itemsLength && numberOfChecked(items) !== 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
        />
        <Divider />
        <List
          sx={{
            width: '100%',
            height: '363px',
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
        >
          {loading ? (
            <CenterBox children={<CircularProgress />} />
          ) : (
            <>
              {(items || [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, index) => {
                  const schemeId = `transfer-list-all-item-${item.id}-scheme`;
                  return (
                    <ListItem
                      key={item.name + index}
                      role="listitem"
                      button
                      onClick={handleToggle(item)}
                      sx={{ py: 0 }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(item) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': schemeId,
                          }}
                          sx={{ color: '#D3E0F2' }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          'fontWeight': 700,
                          'fontSize': '0.75rem',
                          'color': '#5a6c83',
                          'opacity': 1,
                          '& .MuiTypography-body1': {
                            fontWeight: checked.indexOf(item) !== -1 ? 500 : 400,
                          },
                        }}
                        id={schemeId}
                        primary={item.name}
                      />
                    </ListItem>
                  );
                })}
            </>
          )}

          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Box
      py={3}
      gap={'20px'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{ pointerEvents: 'auto' }}
    >
      <Box width="100%">
        {customList(
          'Available Schemes',
          leftItems,
          'No available schemes!',
          availableSchemesLoading
        )}
      </Box>
      <Box>
        <Box
          display={'flex'}
          flexDirection="column"
          gap="10px"
          alignItems="center"
          sx={{
            '& .MuiButton-root': {
              minWidth: '40px',
              px: '3px',
            },
          }}
        >
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked?.length === 0 || !isSubItemSelected}
            aria-label="move selected rightItems"
          >
            <ArrowRight fontSize="large" />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked?.length === 0 || !isSubItemSelected}
            aria-label="move selected leftItems"
          >
            <ArrowLeft fontSize="large" />
          </Button>
        </Box>
      </Box>
      <Box width="100%">
        {customList(
          'Applied Schemes',
          rightItems,
          'No applied schemes found!',
          appliedSchemeFetchLoading
        )}
      </Box>
    </Box>
  );
}
