import { formatCameraName, isCameraInParkingMode } from '@/utils/cameras';
import { BoxImage } from '@/web/@components/BoxImage';
import { LiveStreaming } from '@/web/@components/LiveStreaming';
import { PageLink } from '@/web/@components/PageLink';
import { ImageNotAvailable } from '@/web/@components/SnapshotImageSlider/ImageNotAvailable';
import { Box, CircularProgress } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CameraThumbnailContext } from '../CameraThumbnailProvider';
import { CameraStatusTag } from './CameraStatusTag';

const defaultSource = 'vid_1';

/**
 * @typedef {object} CameraGridListItemExpandedProps
 * @property {EndpointInfoAggregated} item
 */

/** @param {CameraGridListItemExpandedProps & import('@mui/material').BoxProps} props */
export function CameraGridListItem(props) {
  const { item, ...boxProps } = props;

  const navigate = useNavigate();
  const { thumbnails, updateThumbnail } = useContext(CameraThumbnailContext);

  /** @type {StateVariable<boolean>} */
  const [hovered, setHovered] = useState(false);

  const endpointId = useMemo(() => Number(item.endpointId), [item.endpointId]);
  const thumbnail = useMemo(() => thumbnails[item?.endpointId], [thumbnails, item?.endpointId]);
  const loading = useMemo(() => !thumbnail || (!thumbnail.src && !thumbnail.error), [thumbnail]);

  const endpointAlive = useMemo(
    () => item.deviceOnlineStatus && !isCameraInParkingMode(item.parkingStatus),
    [item]
  );

  const showLiveStream = useMemo(() => endpointAlive && hovered, [endpointAlive, hovered]);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const handleClick = (e) => {
    setHovered(false);
    const url = `/cameras/live?id=${item.endpointId}`;
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank').focus();
    } else {
      navigate(url);
    }
  };

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const handlePointerMove = (e) => {
    const box = e.currentTarget.getBoundingClientRect();
    const x = e.pageX;
    const y = e.pageY;
    if (x < box.left + 10 || y < box.top + 10 || x > box.right - 10 || y > box.bottom - 10) {
      setHovered(false);
    } else {
      setHovered(true);
    }
  };

  const handlePointerLeave = () => {
    setHovered(false);
  };

  const handleSnapshotUpdate = useCallback(
    /** @param {string} src */
    (src) => {
      updateThumbnail(endpointId + '', {
        src,
        ts: Date.now(),
        source: defaultSource,
      });
    },
    [endpointId, updateThumbnail]
  );

  if (!item) {
    return null;
  }

  return (
    <Box {...boxProps} display="flex" flexDirection="column">
      <Box
        onClick={endpointAlive ? handleClick : undefined}
        onPointerMove={endpointAlive ? handlePointerMove : undefined}
        onPointerLeave={endpointAlive ? handlePointerLeave : undefined}
        style={{
          width: '100%',
          aspectRatio: 16 / 9,
          overflow: 'hidden',
          position: 'relative',
          cursor: endpointAlive ? 'pointer' : undefined,
          boxShadow: hovered ? '0 0 10px rgba(0, 0, 0, 0.15)' : undefined,
          transition: hovered ? '0.2s ease-in' : undefined,
        }}
      >
        <BoxImage
          src={thumbnail?.src}
          objectFit="cover"
          objectPosition="center"
          style={{
            width: '100%',
            aspectRatio: 16 / 9,
          }}
          fallback={
            <ImageNotAvailable
              children={
                loading ? <CircularProgress color="secondary" size="32px" /> : hovered ? '' : null
              }
            />
          }
        />

        {showLiveStream && (
          <LiveStreaming
            endpointId={endpointId}
            source={defaultSource}
            onSnapshot={handleSnapshotUpdate}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              aspectRatio: 16 / 9,
              overflow: 'hidden',
            }}
          />
        )}

        <CameraStatusTag item={item} />
      </Box>

      <Box
        pt={'4px'}
        style={{
          width: '100%',
          display: 'table',
          tableLayout: 'fixed',
        }}
      >
        <PageLink
          to={`/cameras/${item.endpointId}`}
          fontSize="0.875rem"
          fontWeight={600}
          display="table-cell"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {formatCameraName(item.deviceLabel, item.deviceSerialNo)}
        </PageLink>
      </Box>
    </Box>
  );
}
