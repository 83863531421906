import { KnownUserError } from '@/utils/errors';
import { calculateMd5sum } from '@/utils/file-utils';
import { toastWarning } from '@/utils/toaster';
import { uploadToGCP } from '@/utils/uploader/gcpUpload';
/**
 * @param {File} file
 * @param {FirmwareV2 & CreateFirmwareRequest} body
 * @param {(v: number) => any} onProgress
 * @param {AbortSignal} [signal]
 */
export async function uploadFirmwareFile(file, body, onProgress, signal) {
  const id = body.firmwareId;
  const actualFileName = file.name;
  try {
    const result = await uploadToGCP({
      id,
      file,
      signal,
      onProgress,
      type: 'FIRMWARE',
    });
    body.actualFileName = actualFileName;
    body.storedFileName = result.fileName;
    body.md5sum = await calculateMd5sum(file);
    if (body.properties) {
      body.properties.ActualFileName = actualFileName;
    }
  } catch (err) {
    if (err instanceof KnownUserError) {
      toastWarning(err.message);
    } else {
      console.error(err);
    }
  }
}

/**
 * @param {File} file
 * @param {DbcFileCreateRequest} body
 * @param {string} uuid
 * @param {(v: number) => any} onProgress
 * @param {AbortSignal} [signal]
 */
export async function uploadDBCFile(file, body, uuid, onProgress, signal) {
  try {
    const result = await uploadToGCP({
      id: uuid,
      file,
      signal,
      onProgress,
      type: 'DBC',
    });
    body.fileName = result.fileName;
  } catch (err) {
    if (err instanceof KnownUserError) {
      toastWarning(err.message);
    } else {
      console.error(err);
    }
  }
}
