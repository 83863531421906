import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';
import {
  VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
  frameRateOptions,
  periodicSnapshotFreqOptions,
  recordingCodecs,
  sensorOptionsOne,
  videoResolution,
} from '../options';

/** @type {Array<GroupSettingsItems>} */
export const recordingSettings = [
  {
    title: 'Recording',
    key: GROUP_SETTINGS_CATEGORIES.RECORDING,
    children: [
      {
        label: 'Continuous Recording at the Edge',
        key: 'continuousRecordingAtEdge',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Continuous Recording at Cloud',
        key: 'continuousRecording',
        type: FROM_TYPES.SWITCH,
        isDisabled: (watch) => watch('continuousRecordingAtEdge') === true,
      },
      {
        label: 'Continuous Recording Unit Length',
        key: 'continuousRecordingUnitLength',
        type: FROM_TYPES.SLIDER,
        postText: 'sec',
        min: 2,
        max: 120,
      },
      {
        label: 'Maximum Storage Usage Limit',
        key: 'maximumStorageUsageLimit',
        type: FROM_TYPES.SLIDER,
        postText: '%',
        min: 50,
        max: 85,
      },
      {
        label: 'Periodic Snapshot Frequency',
        key: 'periodicSnapshotFreq',
        type: FROM_TYPES.SELECT,
        options: periodicSnapshotFreqOptions,
      },
      {
        label: 'Upload Snapshots Automatically',
        key: 'uploadPeriodicSnapshotAutomatically',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Limit upload over LTE',
        key: 'limitUploadsOverLTE',
        type: FROM_TYPES.SWITCH,
        bold: true,
      },
      {
        label: 'Event Recording',
        key: 'recording',
        children: [
          {
            label: 'Event Recording',
            key: 'eventRecording',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Enable Event Media Upload to Cloud',
            key: 'enableEventMediaUploadtoCloud',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Pre Buffer',
            key: 'preBufferEvent',
            type: FROM_TYPES.INPUT,
            postText: 'sec',
            min: 1,
            max: 60,
            inputType: 'number',
            isDisabled: (watch) => watch('continuousRecording') || !watch('eventRecording'),
          },
          {
            label: 'Post Buffer',
            key: 'postBufferEvent',
            type: FROM_TYPES.INPUT,
            postText: 'sec',
            min: 1,
            max: 60,
            inputType: 'number',
            isDisabled: (watch) => watch('continuousRecording') || !watch('eventRecording'),
          },
        ],
      },
      {
        label: 'Event.Magnitude.AI',
        key: 'uploadEventMagnitudeAIData',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Event.Magnitude.Sensor.CAN',
        key: 'uploadEventMagnitudeSensorCANData',
        type: FROM_TYPES.SWITCH,
      },
      {},
      {
        label: 'Recording Video Pipeline',
        key: 'recordingVideoQuality',
        underline: true,
        children: [],
      },
      {
        label: 'Recording Audio Pipeline',
        key: 'recordingAudioQuality',
        underline: true,
        children: [],
      },
      {
        label: 'Legacy Video Quality',
        key: 'video',
        underline: true,
        children: [
          {
            label: 'Override Configuration from Deployed AI Models',
            key: 'shouldTakeRecordingSettingsFromModel',
            type: FROM_TYPES.SWITCH,
          },
          {},
          {
            label: 'Resolution',
            key: 'recordingResolution',
            type: FROM_TYPES.SELECT,
            options: videoResolution,
            originalOptions: VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
            isDisabled: (watch) => watch('allRecordingDisabled'),
          },
          {
            label: 'Video Codec', //value has a condition
            key: 'recordingVideoCodec',
            type: FROM_TYPES.SELECT,
            options: recordingCodecs,
            isDisabled: (watch) => watch('recordingVideoCodec') === 'H264',
          },
          {
            label: 'Frame Rate', //depending on recordingFrameRate
            key: 'recordingFrameRateValue',
            type: FROM_TYPES.SELECT,
            options: frameRateOptions,
            postText: 'fps',
            isDisabled: (watch) => watch('allRecordingDisabled'),
            isHidden: (watch) => watch('recordingFrameRate') !== 'fixed',
          },
          {
            label: 'Frame Rate (Min)',
            key: 'recordingFrameRateMin',
            type: FROM_TYPES.SELECT,
            options: frameRateOptions,
            postText: 'fps',
            isDisabled: (watch) => watch('allRecordingDisabled'),
            isHidden: (watch) => watch('recordingFrameRate') !== 'dynamic',
          },
          {
            label: 'Frame Rate (Max)',
            key: 'recordingFrameRateMax',
            type: FROM_TYPES.SELECT,
            options: frameRateOptions,
            postText: 'fps',
            isDisabled: (watch) => watch('allRecordingDisabled'),
            isHidden: (watch) => watch('recordingFrameRate') !== 'dynamic',
          },
          {
            label: 'Bit Rate', //depending on recordingBitrate
            key: 'recordingBitrateValue',
            type: FROM_TYPES.INPUT,
            postText: 'kbps',
            min: 100,
            max: 100000,
            inputType: 'number',
            isDisabled: (watch) => watch('allRecordingDisabled'),
            isHidden: (watch) => watch('recordingBitrate') !== 'fixed',
          },
          {
            label: 'Bit Rate (Min)',
            key: 'recordingBitrateMin',
            type: FROM_TYPES.INPUT,
            postText: 'kbps',
            min: 100,
            max: 100000,
            inputType: 'number',
            isDisabled: (watch) => watch('allRecordingDisabled'),
            isHidden: (watch) => watch('recordingBitrate') !== 'dynamic',
          },
          {
            label: 'Bit Rate (Max)',
            key: 'recordingBitrateMax',
            type: FROM_TYPES.INPUT,
            postText: 'kbps',
            min: 100,
            max: 100000,
            inputType: 'number',
            isDisabled: (watch) => watch('allRecordingDisabled'),
            isHidden: (watch) => watch('recordingBitrate') !== 'dynamic',
          },
        ],
      },
      {
        label: 'CAN Bus Upload',
        key: 'canbus',
        underline: true,
        children: [
          {
            label: 'Upload CAN Bus Data',
            key: 'canDataUploadEnabled',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Transmission Frequency',
            key: 'canDataTransmissionFrequency',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsOne,
          },
        ],
      },
    ],
  },
];
