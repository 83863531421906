import {
  BOX_LIST_ICON,
  SCHEME_LIST_ICON_ON,
  SCHEME_VIEW_MODE_ICON_ON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const SCHEME_ITEMS = [
  {
    name: T['scheme.page.card.view'],
    image: SCHEME_LIST_ICON_ON,
    color: '#EFF3F7',
    countHide: true,
    link: '/administration/schemes/list',
    components: [
      {
        name: T['scheme.page.card.browse'],
        image: BOX_LIST_ICON,
        link: '/administration/schemes/list',
      },
    ],
  },
  {
    name: T['scheme.page.card.apply'],
    image: SCHEME_VIEW_MODE_ICON_ON,
    color: '#F7F5EF',
    countHide: true,
    link: '/administration/schemes/apply',
    components: [
      {
        name: T['scheme.page.card.apply.subtext'],
        image: BOX_LIST_ICON,
        link: '/administration/schemes/apply',
      },
    ],
  },
];
