import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastWarning } from '@/utils/toaster';
import { T } from '@/assets/locales';
import { useTranslation } from 'react-i18next';

export function ResetDefaultModal(props) {
  const { onHide, onComplete } = props;
  const secretToken = useSelector(selectSecretToken);
  const params = useParams();
  const { t } = useTranslation();

  const groupId = useMemo(() => Number(params.id), [params]);

  const handleResetDefault = async () => {
    try {
      const req = api.ac.endpoint.group.restore.default_settings.$post({
        params: {
          groupId,
          secretToken,
        },
      });
      await req.process();
      if (req.response.status === 200) {
        onComplete(true);
      } else {
        onComplete(false);
      }
    } catch (err) {
      onComplete(false);
      toastWarning('Failed to reset');
    }
  };

  return (
    <Dialog open={true} onClose={onHide}>
      <DialogTitle>Reset to default</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(T['group.settings.reset.confirmation'])}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onHide}>{t(T['button.disagree'])}</Button>
        <Button onClick={handleResetDefault} autoFocus variant="contained">
          {t(T['button.agree'])}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
