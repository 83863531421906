import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { PoolFirmwareListActions } from '../@components/PoolFirmwareListActions';
import { PoolFirmwareListView } from '../@components/PoolFirmwareListView';

export function DevicePoolFirmwares() {
  return (
    <Box m={2.5}>
      <PaginatedTableContextProvider>
        <PoolFirmwareListActions />
        <PoolFirmwareListView />
      </PaginatedTableContextProvider>
    </Box>
  );
}
