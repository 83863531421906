import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { toString } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import md5 from 'spark-md5';

const cache = new SmartCache('FutureLoader', 30 * 1000);

/**
 * @typedef {object} FutureLoaderSettings
 * @property {boolean} [cache]
 */

/**
 * @typedef {object} FutureLoaderParams
 * @property {AbortSignal} signal
 * @property {string} secretToken
 * @property {string} tenantId
 */

/**
 * @template R
 * @typedef {object} FutureLoaderResponse<R>
 * @property {R} result
 * @property {Error} error
 * @property {boolean} loading
 * @property {() => any} retry
 * @property {() => any} clear
 */

/**
 * A hook to resolve a promise and getting the result.
 * The promise is resolved inside `useEffect` hook.
 *
 * Returns a stateful result, loading, and error.
 *
 * Settings:
 * - If [cache] is set, it will retrieve the cached result and continue loading
 * the new result. After promise is resolved, the old cache will  be replaced
 * by the new value.
 * @template T
 * @param {(params: FutureLoaderParams) => Promise<T>} [future] A builder for future to resolve.
 * @param {import('react').DependencyList} [deps] If present, effect will only activate if the values in the list change. If absent, effect will only activate once per component lifetime.
 * @param {FutureLoaderSettings} [settings]
 * @returns {FutureLoaderResponse<T>}
 */
export function useFutureLoader(future, deps = [], settings = {}) {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);

  const cacheKey = useMemo(
    () => (settings?.cache ? md5.hash([tenantId, future, ...deps].map(toString).join('/')) : null),
    [future, settings?.cache, tenantId, deps]
  );

  useEffect(
    () => {
      setError(null);
      const aborter = new AbortController();
      (async () => {
        try {
          let result;
          if (cacheKey) {
            result = await cache.getItem(cacheKey);
          }
          if (!result) {
            result = await future({
              tenantId,
              secretToken,
              signal: aborter.signal,
            });
          }
          if (cacheKey) {
            cache.setItem(cacheKey, result);
          }
          setResult(result);
        } catch (err) {
          setError(err);
        }
      })();
      return () => aborter.abort();
    },
    // eslint-disable-next-line
    [...deps, cacheKey, tenantId, secretToken, retry]
  );

  const handleClear = useCallback(() => {
    setResult(null);
    setError(null);
  }, []);

  const handleRetry = useCallback(() => {
    setRetry((v) => v + 1);
  }, []);

  return {
    loading: !result && !error,
    result,
    error,
    clear: handleClear,
    retry: handleRetry,
  };
}
