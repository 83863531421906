import { T } from '@/assets/locales';
import { ItemStatus } from '@/web/@components/ItemStatus';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<AIModelV2WithAccelerator>>} */
export const AI_MODELS_TABLE_COLUMNS = [
  {
    id: 'modelId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'modelId'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: T['ai.models.table.columns.name'],
    align: 'left',
    isPrimary: true,
    sort: true,
    searchable: true,
  },
  {
    id: 'version',
    label: T['ai.models.table.columns.version'],
    align: 'left',
    sort: true,
    searchable: true,
  },
  {
    id: 'releaseVersion',
    label: T['ai.models.table.columns.release.version'],
    align: 'left',
    sort: true,
    searchable: true,
  },
  {
    id: 'vendor',
    label: T['ai.models.table.columns.vendor'],
    align: 'left',
    sort: true,
    searchable: true,
    format: (value, row) => row?.properties?.vendor || '...',
  },
  {
    id: 'publicationStatus',
    label: T['ai.models.table.columns.publication.status'],
    align: 'left',
    sort: true,
    searchable: true,
    format: (value) => <ItemStatus status={value} />,
  },
];
