import { useFetchDeviceBatches } from '@/hooks/useFetchDeviceBatches';
import { useFullProductList } from '@/hooks/useFullProductList';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateDeviceInformation } from '../CreateDeviceInformation';
import { DevicePoolDetailsContext } from '../DevicePoolDetailsLayout';

/**
 * @typedef {object} CreateDeviceDialogFields
 * @property {object} product
 * @property {object} batch
 * @property {string} skuNumber
 * @property {Array} devices
 */

/**
 * @typedef {object} CreateDeviceDialogProps
 * @property {boolean} [open]
 * @property {boolean} [disabled]
 * @property {VinDetails} [item]
 * @property {() => any} [onCancel]
 * @property {import('react-hook-form').SubmitHandler<CreateDeviceDialogFields>} onSubmit
 */

/** @param {CreateDeviceDialogProps} props */
export function CreateDeviceDialog(props) {
  const { open, onCancel, onSubmit, disabled } = props;

  const { devicePool } = useContext(DevicePoolDetailsContext);

  const { result: products, loading: productLoading } = useFullProductList();

  const { result: batches, loading: batchLoading } = useFetchDeviceBatches();

  /** @type {import('react-hook-form').UseFormReturn<CreateDeviceDialogFields>} */
  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      product: null,
      batch: null,
      skuNumber: '',
      devices: [{ batch: null, deviceId: '', imei: '', skuNumber: '' }],
    },
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (!products?.length) return;
    setValue('product', products?.at(0));
    setValue('batch', batches?.at(0));
  }, [setValue, products, batches]);

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="lg">
      <DialogTitle>Add New Devices to Device Pool</DialogTitle>
      <Divider />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
          <DialogContent>
            <Box
              display="grid"
              gridTemplateColumns={{ md: '1fr 1fr', sm: '1fr' }}
              gap="10px"
              px="10px"
            >
              <CustomSelect
                name="product"
                label="Product *"
                loading={productLoading}
                disabled={disabled}
                options={products}
                getKey="productId"
                getLabel="productName"
                placeholder="Select a product"
                rules={{ required: 'Product is required' }}
              />

              <CustomInput
                name="devicePool"
                label="Device Pool"
                InputProps={{ value: devicePool.name, disabled: true }}
              />

              <CustomSelect
                name={`batch`}
                label="Select Batch *"
                loading={batchLoading}
                disabled={disabled}
                options={batches}
                getKey="id"
                getLabel="description"
                placeholder="Select a batch"
                rules={{ required: 'Batch is required' }}
              />

              <CustomInput
                label="SKU Number *"
                placeholder="Enter SKU Number"
                name={'skuNumber'}
                rules={{ required: 'Please provide a valid SKU Number' }}
              />
            </Box>
            <CreateDeviceInformation disabled={disabled} />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="reset" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" autoFocus variant="contained" disabled={disabled}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
