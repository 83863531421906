import { MESSAGE_TYPE } from '@/assets/signalr/config';

/**
 * Get OTA Type and Message by trigger policy ota settings
 * @param {string} onUserTriggerPolicy
 * @returns
 */

export function getOTAType(onUserTriggerPolicy = 'DISABLED') {
  let type = null;
  let message = '';
  switch (onUserTriggerPolicy) {
    case 'INSTANTLY':
      type = MESSAGE_TYPE.FIRMWARE_UPDATE;
      message = 'OTA will be starting right away';
      break;
    case 'ASAP':
      type = MESSAGE_TYPE.FIRMWARE_UPDATE;
      message = 'OTA will be started when the firmware update settings conditions are met';
      break;
    case 'DISABLED':
      message = 'Firmware update settings does not allow to trigger an OTA update on demand';
      break;
    default:
      message = 'Could not send Firmware Update signal';
      break;
  }
  return { type, message };
}
