import api from '@/api';
import { T } from '@/assets/locales';
import { EMAIL_VALIDATION_REGEX } from '@/assets/regex';
import { reportEvent, SentryEvents } from '@/utils/sentry';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CustomInput } from '@/web/@components/CustomForm';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButtonContainer } from '../@components/BackButtonContainer';

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      email: '',
    },
  });

  /** @param {{email: string}} data */
  const handleSubmit = async (data) => {
    const request = api.ac.v5.auth.account['password-reset-request'].$post({
      data: {
        email: data.email,
      },
    });
    try {
      await request.process();
      navigate('/');
      toastSuccess('A password reset link has been sent to your email.');
    } catch (err) {
      console.error(err);
      toastWarning('Failed to send reset instruction');
      reportEvent(SentryEvents.RESET_LINK_SEND_FAILED, '', {
        err,
        email: data.email,
        tags: { email: data.email },
      });
    }
  };

  const handleBackButton = () => {
    navigate('/');
  };

  return (
    <BackButtonContainer onClick={handleBackButton}>
      <Typography fontWeight={600} fontSize="1.25rem">
        Reset Password
      </Typography>
      <Typography fontSize="0.875rem" my="10px">
        Please enter the email address you'd like your password reset information sent to
      </Typography>
      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={form.handleSubmit(handleSubmit)}
          sx={{
            '& label': {
              fontSize: '1rem',
              color: '#5a6c83',
              lineHeight: '28px',
              fontWeight: 500,
              mb: 0,
            },
          }}
        >
          <CustomInput
            name="email"
            label={t(T['reset.password.email.label'])}
            placeholder={t(T['reset.password.email.placeholder'])}
            InputProps={{
              type: 'email',
              autoComplete: 'email',
            }}
            rules={{
              required: t(T['reset.password.email.required']),
              pattern: {
                value: EMAIL_VALIDATION_REGEX,
                message: t(T['reset.password.email.rules.regex']),
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={!form.formState.isValid}
          >
            Send Reset Instruction
          </Button>
        </Box>
      </FormProvider>
    </BackButtonContainer>
  );
}
