import { DataPrivacyPage } from '@/web/data-privacy/index';
import { UnderDevelop } from '@/web/@components/UnderDevelop';
import { T } from '@/assets/locales';
import { DataResidencyPage } from './data-residency';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DataPrivacyPage />,
    breadcrumb: 'Privacy & Compliance',
  },
  {
    path: 'data-residency',
    element: <DataResidencyPage />,
    breadcrumb: T['navigation.data.residency'],
  },
  {
    path: 'data-retention',
    element: <UnderDevelop />,
    breadcrumb: T['navigation.data.retention'],
  },
  {
    path: 'privacy-policies',
    element: <UnderDevelop />,
    breadcrumb: T['navigation.privacy.policies'],
  },
];

export default routes;
