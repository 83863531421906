import api from '@/api';
import { useFullProductList } from '@/hooks/useFullProductList';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/smarter-ai/products/actions';
import { PRODUCT_TABLE_COLUMNS } from '@/web/smarter-ai/products/columns';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ProductAssignTenantDialog } from './@components/AssignTenantDialog';

export function ProductListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { result, loading, error, retry: reload } = useFullProductList();

  /** @type {StateVariable<import('@/types').ProductProperties>} */
  const [assignDialog, setAssignDialog] = useState(null);

  useEffect(() => {
    setAssignDialog(null);
  }, [result]);

  /** @param {Array<import('@/types').ProductProperties>} products */
  const deleteProducts = async (products) => {
    const success = [];
    const failed = [];
    await Promise.all(
      products.map(async (item) => {
        try {
          const request = api.ac.v1.tenant.product.$delete({
            params: {
              productId: item.productId,
              secretToken,
              tenantId,
            },
          });
          await request.process();
          success.push(item.productName);
        } catch (err) {
          failed.push(item.productName);
        }
      })
    );
    if (failed.length) {
      toastWarning(
        'Error',
        failed.length === 1
          ? `Failed to delete <b>${failed[0]}</b>`
          : `Failed to delete ${failed.length} products`
      );
    }
    if (success.length) {
      reload();
    }
  };

  /** @type {import('./@components/AssignTenantDialog').ProductAssignTenantDialogProps['onDone']} */
  const handleAssign = async (selectedTenants) => {
    const product = assignDialog;
    try {
      const requests = selectedTenants.map((tenantId) => {
        return api.ac.v5.product
          .$productId(product?.productId)
          .tenant.$tenantId(tenantId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          })
          .process();
      });

      const results = await Promise.allSettled(requests);
      const successfulUpdates = results.filter((result) => result?.status === 'fulfilled')?.length;

      if (successfulUpdates > 0) {
        toastSuccess(
          'Success',
          `Updated the assigned tenant${successfulUpdates > 1 ? 's' : ''} of <b>${
            product?.productName
          }</b>`
        );
        reload();
      } else {
        throw new Error('No successful updates');
      }
    } catch (err) {
      console.error('Tenant assign failed', err);
      toastWarning(
        'Sorry!',
        `Could not update the assigned tenants of <b>${product?.productName}</b>`
      );
    } finally {
      setAssignDialog(null);
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<import('@/types').ProductProperties>['onAction']} */
  const onActionHandle = (type, selectedItems, setTableReload) => {
    switch (type) {
      case 'DISCONTINUE':
        deleteProducts(selectedItems);
        break;
      case 'ASSIGN':
        setAssignDialog(selectedItems[0]);
        break;
      default:
        break;
    }
  };

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Product list');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'Products'} actions={ACTION_ITEMS} onAction={onActionHandle} />
        <ContinuationTokenTable
          results={result}
          loading={loading}
          columns={PRODUCT_TABLE_COLUMNS}
          dataSortKey={'productName'}
        />
      </PaginatedTableContextProvider>
      <ProductAssignTenantDialog
        onDone={handleAssign}
        product={assignDialog}
        onCancel={() => setAssignDialog(null)}
      />
    </Box>
  );
}
