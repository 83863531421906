import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { DeviceGroupForm } from '@/web/administration/device-groups/@components/DeviceGroupForm';
import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DeviceGroupDetailsContext } from '../@components/DeviceGroupDetailsLayout';

export function UpdateDeviceGroup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const secretToken = useSelector(selectSecretToken);
  const { group, groupId, loading: groupLoading } = useContext(DeviceGroupDetailsContext);

  const [loading, setLoading] = useState(false);

  /** @type {import('../../@components/DeviceGroupForm').DeviceGroupFormProps['onSubmitData']} */
  const updateGroup = async (data) => {
    const { groupName, groupDescription, product } = data;
    try {
      setLoading(true);
      const req = api.ac.endpoint.group.update.$put({
        params: {
          secretToken,
          groupNewName: groupName,
          groupDescription,
          associatedType: 'PRODUCT',
          associatedTypeId: product?.productId,
          groupId,
        },
      });
      await req.process();
      toastSuccess('Success', t(T['device-group.edit.success'], { groupName }));
      navigate('/administration/device-groups');
    } catch (err) {
      toastWarning('Error', t(T['device-group.retry']));
    } finally {
      setLoading(false);
    }
    return;
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['device-group.edit.title'])}
      </Typography>
      <DeviceGroupForm group={group} onSubmitData={updateGroup} loading={loading || groupLoading} />
    </Box>
  );
}
