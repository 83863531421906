import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<Driver>>} */
export const ACTION_ITEMS = [
  // {
  //   label: 'Discontinue',
  //   iconImage: BOX_DISCONTINUE,
  //   component: 'BUTTON',
  //   type: 'DISCONTINUE',
  //   variant: 'outlined',
  //   hidden: (selected) => !selected.length,
  //   confirmationMessage: T['drivers.confirmations.discontinue'],
  // },
  // {
  //   label: 'Modify',
  //   iconImage: BOX_MODIFY_ICON,
  //   component: 'BUTTON',
  //   variant: 'outlined',
  //   hidden: (selected) => selected.length !== 1,
  //   link: (row) => `/fleets/drivers/${row?.driverId}/edit`,
  // },
  {
    label: 'Driver',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/fleets/drivers/create',
  },
];
