import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { uploadFileToAzureBlob } from '@/utils/uploader/azureBlobUploader';
import { CenterBox } from '@/web/@components/CenterBox';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Aborter } from '@azure/storage-file';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DriverForm } from '../../@components/DriverForm/index';

export function DriverEditPage() {
  const navigate = useNavigate();
  const { id: editingDriverId } = useParams();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [progressText, setProgressText] = useState('');
  /** @type {StateVariable<Driver>} */
  const [driver, setDriver] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const fileAborter = useMemo(() => Aborter.none, []);

  useEffect(() => {
    if (!editingDriverId) return;
    const request = api.ac.v5.driver.$driverId(Number(editingDriverId)).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    setLoading(true);
    request
      .process()
      .then((driver) => {
        setDriver(driver.result);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [secretToken, editingDriverId, tenantId]);

  useEffect(() => {
    if (!driver) return;
    const driverCombinedName = `${driver.firstName} ${driver.lastName}`;
    setBreadcrumbTitle(driverCombinedName);
  }, [driver, setBreadcrumbTitle]);

  useEffect(() => {
    return () => navigate('/fleets/drivers');
  }, [tenantId, navigate]);

  const onProgress = (percentage) => {
    const progress = parseInt(percentage);
    setPercentage(progress);
  };

  const deleteDirectories = async (fileCredentials) => {
    fileCredentials?.forEach(async (item) => {
      //await deleteContainer(item?.container, item?.sas);
    });
  };

  /** @type {import('../../@components/DriverForm').DriverFormProps['onSubmit']} */
  const handleUpdateDriver = async (fields) => {
    let success = false;
    let fileCredentials = [];

    try {
      setSubmitting(true);
      setProgressModalOpen(true);

      const driverId = driver?.driverId;

      /** @type {DriverUpdateRequest} */
      const formBody = {};

      formBody.firstName = fields.firstName;
      formBody.lastName = fields.lastName;
      formBody.email = fields.email;
      formBody.phone = fields.phone;
      formBody.licenseNo = fields.licenseNo;
      formBody.tags = fields.tags?.map((item) => item.id);
      formBody.licenseIssuanceDate = new Date(
        startOfTheDay(fields.licenseIssuanceDate)
      ).toISOString();
      formBody.licenseExpirationDate = new Date(
        endOfTheDay(fields.licenseExpirationDate)
      ).toISOString();

      const { driverPhotoFile, driverLicenseFrontFile, driverLicenseBackFile } = fields;
      const uploadedFiles = [];
      if (driverPhotoFile) uploadedFiles.push('driverPhotoFile');
      if (driverLicenseFrontFile) uploadedFiles.push('driverLicenseFrontFile');
      if (driverLicenseBackFile) uploadedFiles.push('driverLicenseBackFile');

      if (uploadedFiles.length > 0) {
        setProgressText('Generating SAS token.');
        const request = api.ac.v5.driver['file-credential'].$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            count: uploadedFiles.length,
            tenantId,
          },
        });
        await request.process();
        fileCredentials = request.result.list;

        if (fileCredentials?.length === 0) {
          toastWarning('Could not upload Driver files [Error: Failed to obtain File Credentials]');
          return;
        }

        const promises = uploadedFiles?.map(async (item, index) => {
          if (item === 'driverPhotoFile') {
            setProgressText('Uploading Driver Photo.');
            const actualFileName = fields.driverPhotoFile.name;
            const storedFileName = `${driverId}-${actualFileName}`.replace(/[^\w\d]+/g, '-');
            await uploadFileToAzureBlob(
              driverPhotoFile,
              storedFileName,
              fileCredentials[index]?.url,
              onProgress
            );
            formBody.pictureContainer = fileCredentials[index]?.container;
            formBody.picturePath = fileCredentials[index]?.path;
            if (fileAborter.aborted) return;
          } else if (item === 'driverLicenseFrontFile') {
            setProgressText('Uploading Driver License Front Image.');
            const actualFileName = fields.driverLicenseFrontFile.name;
            const storedFileName = `${driverId}-${actualFileName}`.replace(/[^\w\d]+/g, '-');
            await uploadFileToAzureBlob(
              driverLicenseFrontFile,
              storedFileName,
              fileCredentials[index]?.url,
              onProgress
            );
            formBody.licenseImageFrontContainer = fileCredentials[index]?.container;
            formBody.licenseImageFrontPath = fileCredentials[index]?.path;
            if (fileAborter.aborted) return;
          } else if (item === 'driverLicenseBackFile') {
            setProgressText('Uploading Driver License Back Image.');
            const actualFileName = fields.driverLicenseBackFile.name;
            const storedFileName = `${driverId}-${actualFileName}`.replace(/[^\w\d]+/g, '-');
            await uploadFileToAzureBlob(
              driverLicenseBackFile,
              storedFileName,
              fileCredentials[index]?.url,
              onProgress
            );
            formBody.licenseImageBackContainer = fileCredentials[index]?.container;
            formBody.licenseImageBackPath = fileCredentials[index]?.path;
            if (fileAborter.aborted) return;
          }
        });

        await Promise.all(promises);
      }

      const driverRequest = api.ac.v5.driver.$driverId(driverId).$patch({
        headers: {
          Authorization: secretToken,
        },
        data: formBody,
      });

      await driverRequest.process();
      // @ts-ignore
      // TODO:: fix apy type
      if (driverRequest.result.status !== 'OK') {
        await deleteDirectories(fileCredentials);
        setLoading(false);
        return;
      }
      toastSuccess('Success', 'Driver updated successfully');
      setProgressModalOpen(false);
      setSubmitting(false);
      success = true;
    } catch (err) {
      setSubmitting(false);
      await deleteDirectories(fileCredentials);
      toastWarning('Error', 'Failed to modify Driver');
    } finally {
      setLoading(false);
      setProgressText('');
      setPercentage(0);
      setProgressModalOpen(false);
      if (success) {
        navigate('/fleets/drivers');
      }
    }
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Update Driver"
          progressText={progressText}
          onClose={() => setProgressModalOpen(false)}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Update Driver
      </Typography>
      <DriverForm driver={driver} onSubmit={handleUpdateDriver} disabled={submitting} />
    </Box>
  );
}
