import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { toastWarning } from '@/utils/toaster';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  IconButton,
  TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

/**
 * @typedef {object} ProductAssignTenantDialogProps
 * @property {import('@/types').ProductProperties} product
 * @property {(selected: Array<string>) => any} onDone
 * @property {() => any} onCancel
 */

/** @param {ProductAssignTenantDialogProps} props */
export function ProductAssignTenantDialog(props) {
  const { product, onDone, onCancel } = props;

  const [selected, setSelected] = useState(
    (product?.assignedTenants || '')
      .split(',')
      .map((s) => s.trim())
      .filter(Boolean)
  );

  const {
    result = [],
    loading,
    error,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    /** @type {Array<TenantInfo>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
        signal: signal,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      if (!request.result) break;
      const result = request.result;
      results.push(...result);
      break;
    }
    return results;
  }, []);

  const tenantIdToNameMap = useMemo(() => {
    const id2name = {};
    for (const tenant of result || []) {
      id2name[tenant.tenantId] = tenant.tenantName;
    }
    return id2name;
  }, [result]);

  useEffect(() => {
    if (!error) return;
    toastWarning('Failure', 'Could not fetch child tenant list');
    onCancel();
  }, [onCancel, error]);

  if (!product) {
    return null;
  }

  return (
    <Dialog open onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>Assign Tenants</Box>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormLabel sx={{ fontSize: '0.875rem' }}>
          Select which tenants can access <b>{product.productName}</b>:
        </FormLabel>
        <Autocomplete
          multiple
          disableClearable
          value={selected}
          loading={loading}
          onChange={(e, value) => setSelected(value)}
          options={Object.keys(tenantIdToNameMap)}
          getOptionLabel={(option) => tenantIdToNameMap[option]}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={loading}
              placeholder={selected?.length ? null : loading ? 'Loading...' : 'Select tenants'}
            />
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onDone(selected)} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
