import api from '@/api';
import { selectSecretToken, selectUserId, selectUserRole } from '@/store/auth';
import { toastSuccess } from '@/utils/toaster';
import { USER_LEVELS } from '@/utils/user-access';
import { ConfirmationDialog } from '@/web/@components/ConfirmationDialog';
import { UserDetailsContext } from '@/web/@layouts/UserDetailsLayout';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { useContext, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toastWarning } from '../../../../../utils/toaster';
import { getCountryFlagIconByCountryName } from '../../../../../utils/country-flags';

export function UserDetailsArea() {
  const { user, userId, refreshUser } = useContext(UserDetailsContext);
  const secretToken = useSelector(selectSecretToken);
  const loggedUserId = useSelector(selectUserId);
  const loggedUserRole = useSelector(selectUserRole);

  /** @type {StateVariable<boolean>} */
  const [openModal, setOpenModal] = useState(false);

  const hasDeactivateAccess = useMemo(
    () => !(loggedUserId === user?.userId || USER_LEVELS[user?.role] > USER_LEVELS[loggedUserRole]),
    [loggedUserId, user, loggedUserRole]
  );

  const handleStatusChange = () => {
    if (user.active) {
      handleUserDeactivate();
      return;
    }
    handleUSerActivate();
  };

  const handleUserDeactivate = () => {
    const request = api.ac.v5.user.$userId(userId).deactivate.$post({
      headers: {
        Authorization: secretToken,
      },
    });

    request
      .process()
      .then((resp) => {
        toastSuccess('Success', `${user?.name} deactivated successfully`);
      })
      .catch((error) => {
        toastWarning('Error!', `Failed to deactivate ${user?.name}`);
      })
      .finally(() => {
        setOpenModal(false);
        refreshUser();
      });
  };

  const handleUSerActivate = () => {
    const request = api.ac.v5.user.$userId(userId).activate.$post({
      headers: {
        Authorization: secretToken,
      },
    });

    request
      .process()
      .then((resp) => {
        toastSuccess('Success', `${user?.name} activated successfully`);
      })
      .catch((error) => {
        toastWarning('Error!', `Failed to activate ${user?.name}`);
      })
      .finally(() => {
        setOpenModal(false);
        refreshUser();
      });
  };

  return (
    <>
      <ConfirmationDialog
        open={openModal}
        title={`User ${user.active ? 'Deactivate' : 'Activate'}`}
        message={`Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${
          user.name
        }?`}
        onCancel={() => setOpenModal(false)}
        onApply={handleStatusChange}
      />
      <Box
        display={{ xs: 'grid', md: 'flex' }}
        alignItems="center"
        borderRadius="20px"
        minHeight="114px"
        gap={3}
      >
        <Avatar
          alt={user?.name}
          src={
            // @ts-ignore
            user?.pictureUrl || ''
          }
          sx={{ width: 50, height: 50 }}
        />

        <Grid container textAlign="left" spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" lineHeight="18px">
              Name
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {user?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" lineHeight="18px">
              Email
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {user?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" lineHeight="18px">
              Location
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {user?.country || '...'}{' '}
              <img
                src={getCountryFlagIconByCountryName(user?.country)}
                alt=""
                height="14px"
                style={{ border: '1px solid #f5f9ff' }}
              ></img>
            </Typography>
          </Grid>
        </Grid>

        {hasDeactivateAccess ? (
          <Button
            variant="outlined"
            color={user?.active ? 'warning' : 'success'}
            onClick={() => setOpenModal(true)}
          >
            {user?.active ? 'Deactivate' : 'Activate'}
          </Button>
        ) : (
          ''
        )}
      </Box>
    </>
  );
}
