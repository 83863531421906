export function PrivacyPolicyPage() {
  return (
    <div
      style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto', paddingBottom: '25px' }}
    >
      <h1 style={{ textAlign: 'center' }}>
        Privacy Policy
        <br />
        <div style={{ fontWeight: 500, textAlign: 'center', color: 'grey', fontSize: '0.75rem' }}>
          Effective Date: January 1, 2019.
        </div>
      </h1>
      <p>
        Smarter AI Corporation and its subsidiaries and affiliates (collectively, &ldquo;Smarter
        AI&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;) provide connected camera services and
        software which enable access, connections, streaming, and analytics between cameras and
        applications (our &ldquo;Services&rdquo;). We enable developers to build and deploy
        innovative products that leverage our Services (&ldquo;Products&rdquo;).
      </p>
      <p>
        This privacy policy (&ldquo;Privacy Policy&rdquo;) governs our collection, processing, and
        storage of your Product Data. By using a Product, you agree to be bound by this Privacy
        Policy.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <strong>1. Product Data</strong>
        </span>
      </p>
      <p>Your &ldquo;Product Data&rdquo; includes all data:</p>
      <ul>
        <li>About your Product, such as its serial number and software versions,</li>
        <li>
          That we collect during setup of your Product, such as your name, email address, and
          location, and
        </li>
        <li>
          That we collect during the operation of your Product, such as network data, location,
          audio/video signals, sensor data, and authorization, access, and connection data.
        </li>
        <li style={{ listStyleType: 'none' }}></li>
      </ul>
      <p>
        You grant Smarter AI and our suppliers a perpetual, irrevocable, assignable, worldwide,
        royalty-free license to collect, process, and store your Product Data, including your
        personal data, to operate, support, and improve our Services, and to develop new products
        and services.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <strong>2. Data Protection, Privacy, and Data Transfers</strong>
        </span>
      </p>
      <p>Data protection and privacy laws in your country:</p>
      <ul>
        <li>May govern the use of your Product,</li>
        <li>May differ from similar laws in Singapore, and</li>
        <li>
          May grant authorities different rights to access your Product Data, including your
          personal data, compared to similar laws in Singapore.
        </li>
        <li>
          You are solely responsible for complying with all applicable laws when using your Product.
        </li>
      </ul>
      <p>
        You consent to the transfer of your Product Data, including your personal data, to Singapore
        and to other countries where Smarter AI and our suppliers operate.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <strong>3. Invited Users</strong>
        </span>
      </p>
      <p>
        Your Product may enable you to invite other users to access it. Invited users may view your
        Product Data and may invite other users.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <strong>4. Data Security</strong>
        </span>
      </p>
      <p>
        Smarter AI uses data security tools and methods to protect your Product Data from
        unauthorized access. In addition, Smarter AI has policies and barriers to protect your
        audio/video Product Data from unauthorized employee access.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <strong>5. Personal Data</strong>
        </span>
      </p>
      <p>Smarter AI will not share your personal data, other than:</p>
      <ul>
        <li>With your consent, for example, if you invite another user to access your Product,</li>
        <li>With the developer of your Product,</li>
        <li>
          In response to any applicable law, regulation, legal process, or enforceable governmental
          request,
        </li>
        <li>
          To protect against harm to the property, rights, or safety of Smarter AI, our developers
          and users, and the public as permitted or required by law, or
        </li>
        <li>As part of the sale or transfer of Smarter AI or substantially all of its assets.</li>
        <li>
          We may share your Product Data excluding your personal data, for example aggregated or
          anonymized data, publicly.
        </li>
      </ul>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <strong>6. Changes to this Privacy Policy</strong>
        </span>
      </p>
      <p>
        We may revise our Privacy Policy from time to time. We will provide notice of any revisions
        by posting our revised Privacy Policy at https://smarterai.camera. Each revised Privacy
        Policy will supersede preceding versions as of the Effective Date written at the top. Your
        continued use of your Product will constitute your acceptance of each revised Privacy
        Policy.
      </p>
      <p>
        <span style={{ textDecoration: 'underline' }}>
          <strong>7. Contacting Smarter AI</strong>
        </span>
      </p>
      <p>
        If you have any questions about this Privacy Policy, please contact Smarter AI at{' '}
        <a href="mailto:privacy@smarterai.camera" target="_blank" rel="noreferrer">
          privacy@smarterai.camera
        </a>
        .
      </p>
    </div>
  );
}
