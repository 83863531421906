import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';
import { sdCardEncryptionOptions } from '../options';

/** @type {Array<GroupSettingsItems>} */
export const blurringSettings = [
  {
    title: 'Privacy Mode',
    key: GROUP_SETTINGS_CATEGORIES.PRIVACY,
    children: [
      {
        label: 'SD Card Encryption',
        key: 'sdCardEncryptionOption',
        type: FROM_TYPES.SELECT,
        options: sdCardEncryptionOptions,
      },
      {},
      {
        label: 'Object Blurring',
        key: 'isBlurEnabled',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Labels For Selected AI Models',
        key: 'blurredLabels',
        type: FROM_TYPES.AI_MODEL_LEVELS,
        isDisabled: (watch) => !watch('isBlurEnabled'),
        isHidden: (watch) => watch('isBlurEnabled') === false,
      },
    ],
  },
];
