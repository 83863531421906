import { CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';
import { SafetyScoreLayoutContext } from '../../@layouts/SafetyScoreLayout';
import { SELECTION_TYPE } from '../../assets';
import { filterItemsByProperties } from '../../utils';

/**
 * @typedef {object} ScoringMultiSelectFilterProps
 * @property {() => any} [onApply]
 * @property {() => any} [onCancel]
 * @property {number} [selectionNo]
 */

/**
 * @param {ScoringMultiSelectFilterProps} props
 */
export function ScoringMultiSelectFilter(props) {
  const { onApply, onCancel, selectionNo } = props;

  const { personaList, drivers, cameras } = useContext(SafetyScoreLayoutContext);

  const {
    selectedCameras,
    selectedDrivers,
    selectedPersonaList,
    setSelectedCameras,
    setSelectedDrivers,
    setSelectedPersonaList,
  } = useContext(ScoringFilterContext);

  const [searchTerm, setSearchTerm] = useState('');

  const [tempState, setTempState] = useState([]);

  const onClickApply = () => {
    if (selectionNo === SELECTION_TYPE.CAMERA) {
      setSelectedCameras(tempState);
    } else if (selectionNo === SELECTION_TYPE.DRIVER) {
      setSelectedDrivers(tempState);
    } else if (selectionNo === SELECTION_TYPE.PERSONA) {
      setSelectedPersonaList(tempState);
    }
    onApply();
  };

  const onClickCancel = () => {
    if (selectionNo === SELECTION_TYPE.CAMERA) {
      setTempState(selectedCameras);
    } else if (selectionNo === SELECTION_TYPE.DRIVER) {
      setTempState(selectedDrivers);
    } else if (selectionNo === SELECTION_TYPE.PERSONA) {
      setTempState(selectedPersonaList);
    }
    onCancel();
  };

  useEffect(() => {
    if (selectionNo === SELECTION_TYPE.CAMERA) {
      setTempState(selectedCameras);
    } else if (selectionNo === SELECTION_TYPE.DRIVER) {
      setTempState(selectedDrivers);
    } else if (selectionNo === SELECTION_TYPE.PERSONA) {
      setTempState(selectedPersonaList);
    }
  }, [selectionNo, selectedDrivers, selectedCameras, selectedPersonaList]);

  const getSubmenuItems = () => {
    const mapCheckedStatus = (item, idKey) => ({
      ...item,
      checked: tempState?.some((i) => i[idKey] === item?.[idKey]),
    });

    switch (selectionNo) {
      case SELECTION_TYPE.CAMERA:
        return cameras?.map((item) => mapCheckedStatus(item, 'endpointId'));
      case SELECTION_TYPE.DRIVER:
        return drivers?.map((item) => mapCheckedStatus(item, 'driverId'));
      case SELECTION_TYPE.PERSONA:
        return personaList?.map((item) => mapCheckedStatus(item, 'id'));
      default:
        return [];
    }
  };

  /**
   * Searches and filters the list of items for multi-select based on the selection type.
   * @returns {Array} - The filtered items based on the selection type and search term.
   */
  const searchListForMultiSelect = () => {
    const items = getSubmenuItems();
    const searchTermLowerCase = searchTerm?.toLowerCase();

    switch (selectionNo) {
      case SELECTION_TYPE.CAMERA:
        return filterItemsByProperties(items, ['deviceLabel'], searchTermLowerCase);
      case SELECTION_TYPE.DRIVER:
        return filterItemsByProperties(items, ['firstName', 'lastName'], searchTermLowerCase);
      case SELECTION_TYPE.PERSONA:
        return filterItemsByProperties(items, ['name'], searchTermLowerCase);
      default:
        return [];
    }
  };

  const getMultiSelectionItemLabel = (item) => {
    switch (selectionNo) {
      case SELECTION_TYPE.CAMERA:
        return item?.deviceLabel;
      case SELECTION_TYPE.DRIVER:
        return `${item?.firstName} ${item?.lastName}`;
      case SELECTION_TYPE.PERSONA:
        return item?.name;
      default:
        return '';
    }
  };

  /**
   * Handle Check toggle for Multi Selection value
   * @param {*} item
   */
  const handleItemToggle = (item) => {
    const idKey = (() => {
      switch (selectionNo) {
        case SELECTION_TYPE.CAMERA:
          return 'endpointId';
        case SELECTION_TYPE.DRIVER:
          return 'driverId';
        case SELECTION_TYPE.PERSONA:
          return 'id';
        default:
          return '';
      }
    })();

    const toggleSelectedItems = (prev = []) => {
      const isItemInList = prev.some((selectedItem) => selectedItem[idKey] === item[idKey]);
      if (isItemInList) {
        return prev.filter((selectedItem) => selectedItem[idKey] !== item[idKey]);
      } else {
        return [...prev, item];
      }
    };

    switch (selectionNo) {
      case SELECTION_TYPE.CAMERA:
        setTempState((prev) => toggleSelectedItems(prev));
        break;
      case SELECTION_TYPE.DRIVER:
        setTempState((prev) => toggleSelectedItems(prev));
        break;
      case SELECTION_TYPE.PERSONA:
        setTempState((prev) => toggleSelectedItems(prev));
        break;
      default:
        break;
    }
  };

  return (
    <Box
      px={2}
      pb={2}
      sx={{ maxHeight: '350px', minWidth: '400px', overflowY: 'auto', marginTop: '2px' }}
    >
      <Box sx={{ maxHeight: '270px', minWidth: '400px', overflowY: 'auto', marginTop: '2px' }}>
        <TextField
          size="small"
          fullWidth
          placeholder="Search Item"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mt: '8px' }}
        />
        {searchListForMultiSelect()?.map((item, index) => (
          <Box sx={{ overflowY: 'auto', marginTop: '2px' }} key={item + index}>
            <MenuItem onClick={() => handleItemToggle(item)} sx={{ paddingLeft: 0 }}>
              {item?.checked ? (
                <CheckBoxIcon htmlColor="#4EA5F1" />
              ) : (
                <CheckBoxIcon htmlColor="#EBEBEB" />
              )}
              <Typography fontSize="0.875rem" pl={1}>
                {getMultiSelectionItemLabel(item)}
              </Typography>
            </MenuItem>
          </Box>
        ))}
      </Box>
      <Box
        sx={{ p: 1, display: 'flex', justifyContent: 'flex-end', mt: 1 }}
        onClick={onClickCancel}
      >
        <Button size="small">Cancel</Button>
        <Button onClick={() => onClickApply()} variant="contained" size="small">
          Apply
        </Button>
      </Box>
    </Box>
  );
}
