import { CheckCircle } from '@mui/icons-material';
import { Button, Card, Dialog, Typography } from '@mui/material';

export function CoachingAccessPermissionModal(props) {
  const { handleClose } = props;

  return (
    <Dialog
      open={true}
      maxWidth={'md'}
      PaperProps={{
        sx: {
          borderRadius: '25px',
        },
      }}
    >
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '350px',
          p: 4,
        }}
      >
        <CheckCircle sx={{ fontSize: '70px', color: '#5A906B' }} />

        <Typography fontWeight={500} py={2} fontSize={'1rem'} color="#596A82">
          Thank you for your Interest!
        </Typography>

        <Typography fontWeight={400} pb={5} fontSize={'.80rem'} color="#0B2547">
          We have received your request and will be reaching out to you soon.
        </Typography>

        <Button variant="contained" size="small" onClick={handleClose}>
          Close
        </Button>
      </Card>
    </Dialog>
  );
}
