import { EventFilterContext, SetEventFilterContext } from '@/web/@components/EventFilterContext';
import { SearchField } from '@/web/@components/SearchField';
import { Box } from '@mui/material';
import { useContext } from 'react';

export function EventItemSearch(props) {
  const { cameraId } = props;
  const setEventFilter = useContext(SetEventFilterContext);

  const { deviceName, deviceSerialNumber, triggerName, annotationName } =
    useContext(EventFilterContext);

  /**
   *
   *  @param {string} [text]
   *  @param {string} [key]
   */
  const handleSearchTextChange = (text, key) => {
    setEventFilter({
      [key]: text?.trim(),
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      columnGap={0.8}
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
    >
      {!cameraId && (
        <>
          <SearchField
            clearable
            searchOnEnter
            value={deviceName}
            hideSearchIcon={true}
            onSearch={(text) => handleSearchTextChange(text, 'deviceName')}
            placeholder="Camera Name"
            InputProps={{ fullWidth: false }}
            sx={{ width: { xs: '48%', md: 160, xl: 230 } }}
          />
          <SearchField
            clearable
            searchOnEnter
            value={deviceSerialNumber}
            hideSearchIcon={true}
            onSearch={(text) => handleSearchTextChange(text, 'deviceSerialNumber')}
            placeholder="Camera Serial"
            InputProps={{ fullWidth: true }}
            sx={{ width: { xs: '48%', md: 160, xl: 230 } }}
          />
        </>
      )}
      <SearchField
        clearable
        searchOnEnter
        value={triggerName}
        hideSearchIcon={true}
        onSearch={(text) => handleSearchTextChange(text, 'triggerName')}
        placeholder="Trigger"
        InputProps={{ fullWidth: true }}
        sx={{ width: { xs: '48%', md: 160, xl: 200 } }}
      />
      <SearchField
        clearable
        searchOnEnter
        value={annotationName}
        hideSearchIcon={true}
        onSearch={(text) => handleSearchTextChange(text, 'annotationName')}
        placeholder="Annotation"
        InputProps={{ fullWidth: true }}
        sx={{ width: { xs: '48%', md: 160, xl: 200 } }}
      />
    </Box>
  );
}
