import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<Endpoint>>} */
export const ACTION_ITEMS = [
  {
    type: 'TRANSFER',
    label: 'Change Group',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    useCustomDialog: true,
    hidden: (selected) => selected.length !== 1,
  },
  {
    label: 'Search',
    placeholder: 'Search Camera',
    component: 'SEARCH',
  },
  {
    type: 'CREATE',
    label: 'Add Camera',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    useCustomDialog: true,
  },
];
