import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { parseObject } from '@/web/administration/device-groups/_id/cameras/manage-cameras/@utils';
import { SignalCellularAltSharp, WifiSharp } from '@mui/icons-material';
import { Box, Icon, IconButton, Popover, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';

/**
 * Network colors configuration.
 * @type {{[key: string]: string}}
 * @constant
 */
const NETWORK_COLORS = {
  BACKGROUND_INACTIVE: '#F8FAFF',
  BACKGROUND_ACTIVE: '#487CC1',
  BACKGROUND_ACTIVE_HOVER_ACTIVE: '#487DD1',
  ITEM_INACTIVE: '#C8CFE5',
  ITEM_ACTIVE: '#FFFFFF',
};

/**
 * An array containing different connection types.
 * @constant {Array<Object>} CONNECTION_TYPES
 * @property {string} type
 * @property {string} name
 * @property {Icon} icon
 */
const CONNECTION_TYPES = [
  {
    type: 'WIFI',
    name: 'Wifi',
    icon: WifiSharp,
  },
  {
    type: 'CELLULAR',
    name: 'Cellular',
    icon: SignalCellularAltSharp,
  },
];

export function CameraISPInformation() {
  const { cameraId, camera } = useContext(CameraDetailsContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [networkInfo, setNetworkInfo] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handlePopoverOpen = (event, popoverId) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(popoverId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const fetchISPRecords = useCallback(async () => {
    const request = api.ac.v3.report.device.records.$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
        secretToken,
        pageSize: 1,
        endpointId: cameraId,
        subtype: 'network-change',
      },
    });
    await request.process();
    const result = request.result.data;

    /** @type {Array<CameraTypeRecord>} */
    const mappedData = result.map((item, index) => {
      const properties = parseObject(item.properties) || {};
      return properties;
    });
    setNetworkInfo(mappedData?.at(0));
  }, [cameraId, secretToken, tenantId]);

  useEffect(() => {
    fetchISPRecords();
  }, [cameraId, fetchISPRecords]);

  const isCellularAvailable = useMemo(
    () => networkInfo?.cellular_available === 'yes',
    [networkInfo]
  );

  const isWifiAvailable = useMemo(() => networkInfo?.wifi_available === 'yes', [networkInfo]);

  if (!isCellularAvailable && !isWifiAvailable) {
    return null;
  }

  return (
    <>
      {CONNECTION_TYPES.map((item) => {
        const isAvailable =
          camera.isOnline && (item.type === 'WIFI' ? isWifiAvailable : isCellularAvailable);
        return (
          <Fragment key={item.type}>
            <IconButton
              disableRipple
              sx={{
                'backgroundColor': isAvailable
                  ? NETWORK_COLORS.BACKGROUND_ACTIVE
                  : NETWORK_COLORS.BACKGROUND_INACTIVE,
                'height': '40px',
                'width': '40px',
                ':hover': {
                  'backgroundColor': isAvailable
                    ? NETWORK_COLORS.BACKGROUND_ACTIVE_HOVER_ACTIVE
                    : NETWORK_COLORS.BACKGROUND_INACTIVE,
                  '& .MuiSvgIcon-root': {
                    color: NETWORK_COLORS.ITEM_INACTIVE,
                  },
                },
              }}
              onMouseEnter={(e) => handlePopoverOpen(e, item.type)}
              onMouseLeave={handlePopoverClose}
            >
              <Icon
                component={item.icon}
                fontSize="small"
                sx={{
                  'color': isAvailable ? NETWORK_COLORS.ITEM_ACTIVE : NETWORK_COLORS.ITEM_INACTIVE,
                  ':hover': {
                    color: NETWORK_COLORS.ITEM_INACTIVE,
                  },
                }}
              ></Icon>
            </IconButton>
            <Popover
              sx={{
                'pointerEvents': 'none',
                'boxShadow': 'none',
                '& .MuiPopover-paper': {
                  boxShadow: '1px',
                },
              }}
              open={openedPopoverId === item.type}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {item.type === 'WIFI' ? (
                <Box display="row" p={1}>
                  <Typography variant="subtitle1" fontWeight={500} fontSize="12px">
                    {camera.isOnline && networkInfo?.wifi_available === 'yes'
                      ? networkInfo?.wifi_ssid
                      : 'Wi-Fi Not Connected'}
                  </Typography>
                </Box>
              ) : (
                <Box display="row" gap={1} p={1}>
                  {camera.isOnline && networkInfo?.cellular_available === 'yes' ? (
                    <>
                      <Typography variant="subtitle1" fontWeight={500} fontSize="12px">
                        {networkInfo?.cellular_network_operator_name ||
                          networkInfo?.cellular_simoperator ||
                          networkInfo?.cellular_operator}
                      </Typography>
                      <Typography variant="subtitle2" fontWeight={400} fontSize="10px">
                        {networkInfo?.cellular_operator}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="subtitle1" fontWeight={500} fontSize="12px">
                      Cellular Not Connected
                    </Typography>
                  )}
                </Box>
              )}
            </Popover>
          </Fragment>
        );
      })}
    </>
  );
}
