import { T } from '@/assets/locales';
import { Box, CircularProgress, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EventListContext } from '../EventListContextProvider';

export function EventCountHeader() {
  const { t } = useTranslation();
  const { totalEvents } = useContext(EventListContext);
  return (
    <Box>
      {!isNumber(totalEvents) ? (
        <CircularProgress size="16px" />
      ) : (
        <Box component={'span'} fontWeight={'medium'}>
          {totalEvents}
        </Box>
      )}
      <Typography component={'span'} ml="5px" variant="subtitle2">
        {t(T['events.page.title'])}
      </Typography>
    </Box>
  );
}
