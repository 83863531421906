import { useRouteQuery } from '@/hooks/useRouteQuery';
import { Download } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { isObject } from 'lodash';
import { useContext } from 'react';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';

const csvVariables = [
  { key: 'name', header: 'Trigger Name' },
  { key: 'cooldown', header: 'Cooldown' },
  { key: 'cooldownTimer', header: 'Cooldown Timer (s)' },
  { key: 'mediaType', header: 'Media Type' },
  { key: 'uploadStrategy', header: 'Upload Strategy' },
  { key: 'chime', header: 'Chime Sound' },
  { key: 'preBuffer', header: 'Pre-Buffer (s)' },
  { key: 'postBuffer', header: 'Post-Buffer (s)' },
  { key: 'mediaSource', header: 'Media Source' },
  { key: 'primarySnapshotImager', header: 'Primary Snapshot Imager' },
  { key: 'enableTts', header: 'Text-to-Speech' },
  { key: 'ttsText', header: 'Text-to-Speech Text' },
  { key: 'triggerCategoryDescription', header: 'Trigger Description' },
];

export function TriggersCSVDownload() {
  const { triggers, selectionType } = useContext(TriggerConfigurationContext);

  const { query } = useRouteQuery();

  const downloadCSV = () => {
    // Create custom headers row
    const headersRow = csvVariables.map((variable) => variable.header).join(',');

    const updateValue = (value, key) => {
      if (key === 'cooldownTimer') return value / 1000;
      return value;
    };

    //Filter and map the triggers array to get only the desired values
    const dataRows = triggers.map((item) =>
      csvVariables
        .map((variable) =>
          JSON.stringify(
            isObject(item[variable.key])
              ? updateValue(item[variable.key]?.value, variable.key) || ''
              : item[variable.key] || ''
          )
        )
        .join(',')
    );

    // Combine headers and data into CSV content
    const csvContent = [headersRow, ...dataRows].join('\n');

    // Create a blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger download
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const filename = `${selectionType?.name?.replace(/\s+/g, '_')}_${selectionType?.value}`;
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (!query?.export) return null;

  return (
    <Box
      onClick={downloadCSV}
      display="flex"
      flexDirection="row"
      sx={{ cursor: 'pointer' }}
      gap={0.5}
      alignItems="center"
    >
      <Download sx={{ color: '#7792b4', fontSize: '20px' }} />
      <Typography variant="body2" fontWeight={600} color="#4177BF" fontSize={'0.78rem'}>
        Export
      </Typography>
    </Box>
  );
}
