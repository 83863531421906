import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { toastWarning } from '@/utils/toaster';
import { CustomSelect } from '@/web/@components/CustomForm';
import { CustomDateSelect } from '@/web/@components/CustomForm/CustomDate';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} NeedCoachingFormProps
 * @property {EndpointDetailsResponse & {endpointId: number}} [endpoint]
 * @property  {() => any} [onHide]
 */

/**
 * @param {NeedCoachingFormProps} props
 */
export function NeedCoachingForm(props) {
  const { onHide } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [users, setUsers] = useState([]);

  const defaultValues = useMemo(
    () => ({
      coach: '',
      schemdule: '',
    }),
    []
  );

  const methods = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  useEffect(() => {
    const request = api.ac.v5.tenant.$tenantId(tenantId).user.$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        limit: 1000,
        offset: 0,
      },
    });
    request
      .process()
      .then((r) => setUsers(r?.users))
      .catch(console.error);
  }, [secretToken, tenantId]);

  const { handleSubmit } = methods;

  /** @param {typeof defaultValues} data */
  const handleAdd = async (data) => {
    try {
      // const request = api.ac.v5['driver-tag'].$post({
      //   headers: {
      //     Authorization: `Bearer ${secretToken}`,
      //   },
      //   data: {
      //     tenantId,
      //     ...data,
      //   },
      // });
      // await request.process();
      toastWarning('Failed', 'API not provided!!');
      //toastSuccess('Success', 'Coaching recomended');
      //onHide && onHide();
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Unable to recomend coaching');
    }
  };

  /** @param {typeof defaultValues} data */
  const handelOnSubmit = (data) => {
    data.coach = trimString(data.coach);
    data.schemdule = trimString(data.schemdule);
    handleAdd(data);
  };

  return (
    <Dialog
      open={true}
      onClose={onHide}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handelOnSubmit)} autoComplete="off" noValidate>
          <DialogTitle id="alert-dialog-title">Schedule Coaching</DialogTitle>
          <Divider />
          <DialogContent>
            <CustomSelect
              name="coach"
              label="Coach *"
              options={users}
              placeholder="Please enter a name"
              rules={{ required: 'This is required' }}
            />
            <CustomDateSelect
              name="schemdule"
              label="Schedule"
              rules={{ required: 'This is required' }}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={onHide}>Cancel</Button>
            <Button variant="contained" type="submit">
              Apply
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
