import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/vin-groups/actions';
import { VIN_GROUP_TABLE_COLUMNS } from '@/web/administration/vin-groups/columns';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

export function VinGroupListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<VinGroupInfoAggregated>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteVinGroups(selectedItems);
    }
    setTableReload(true);
  };

  const deleteVinGroup = async (group) => {
    try {
      const request = api.ac.v5['vin-groups'].$id(group.vinGroupId).$delete({
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
      });
      await request.process();
      toastSuccess('Success', `${group.vinGroupName} deleted successfully`);
    } catch (e) {
      toastWarning('Error', `Failed to delete ${group.vinGroupName}`);
    }
  };

  const deleteVinGroups = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteVinGroup));
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'VIN Groups'} actions={ACTION_ITEMS} onAction={onActionHandle} />
        <OffsetPagination
          key={generateUniqueKey([secretToken, 'vin_group'])}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            const request = api.ac.v5['vin-groups'].list.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit: limit,
                offset,
                tenantId,
                ...(searchText !== '' && searchType && { [searchType]: searchText }),
              },
            });
            await request.process();
            return {
              data: request.result,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<VinGroupInfoAggregated>} */ results) => (
            <ItemsResponsiveView results={results} columns={VIN_GROUP_TABLE_COLUMNS} />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
