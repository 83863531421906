import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { CircularProgress } from '@mui/material';
import { sortBy } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VinGroupDetailsContext } from '../VinGroupDetailsLayout';
import { VIN_TABLE_COLUMNS } from './columns';

export function VinListView() {
  const navigate = useNavigate();

  const tenantId = useSelector(selectTenantId);

  const { searchText } = useContext(FilterContext);
  const { groupId } = useContext(VinGroupDetailsContext);
  const { reloadStatus, setTableReload, setSelectedItems } = useContext(PaginatedTableContext);

  const {
    result: vins = [],
    loading,
    error,
    retry,
  } = useFutureLoader(
    async ({ signal, secretToken }) => {
      /** @type {Array<VinDetails>} */
      const result = [];
      const pageSize = 20; // API max limit: 20
      let continuationToken;
      while (!signal.aborted) {
        const request = api.ac.v5['vin-groups'].$id(groupId).vins.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            pageSize,
            continuationToken,
          },
        });
        await request.process();
        result.push(...request.result.vins);
        continuationToken = request.result.continuationToken;
        if (!continuationToken || request.result.vins.length < pageSize) break;
      }
      return sortBy(result, 'vin');
    },
    [groupId]
  );

  useEffect(() => {
    if (!reloadStatus) return;
    setTableReload(false);
    retry();
  }, [reloadStatus, setTableReload, retry]);

  const filteredVins = useMemo(() => {
    const q = trimString(searchText).toLowerCase();
    if (!q) return vins;
    return (vins || []).filter(
      (item) =>
        item.vin?.toLowerCase()?.startsWith(q) || item.deviceName?.toLowerCase()?.startsWith(q)
    );
  }, [searchText, vins]);

  useEffect(() => {
    setSelectedItems([]);
  }, [filteredVins, setSelectedItems]);

  // Navigate vin groups list page while tenant change
  useEffect(() => {
    return () => navigate('/administration/vin-groups/');
  }, [tenantId, navigate]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error || !filteredVins?.length) {
    return (
      <CenterBox fullView>
        <IconMessageBox
          size="256px"
          src="/images/empty/no-search-results.svg"
          message={'No VINs available'}
        />
      </CenterBox>
    );
  }

  return <ItemsResponsiveView results={filteredVins} columns={VIN_TABLE_COLUMNS} />;
}
