import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DeviceGroupDetailsContext } from '../@components/DeviceGroupDetailsLayout/index';
import { ACTION_ITEMS } from './actions';
import { APN_TABLE_COLUMNS } from './columns';

export function DeviceGroupApnSettings() {
  const secretToken = useSelector(selectSecretToken);
  const { groupId } = useContext(DeviceGroupDetailsContext);

  const {
    result = [],
    loading,
    retry: fetchApnSettings,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    const request = api.ac.v2.endpoint.apn.group.list.$groupId(groupId).$get({
      signal,
      headers: {
        Authorization: secretToken,
      },
    });
    await request.process();
    return request.result.apnInfoList;
  }, []);

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<APNInfo>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    if (type === 'DISCONTINUE') {
      await deleteApnSettings(selectedItems);
    }
    fetchApnSettings();
  };

  /** @param {APNInfo} apn  */
  const deleteApnSetting = async (apn) => {
    try {
      const request = api.ac.v2.endpoint.apn.group.remove.$delete({
        headers: {
          Authorization: secretToken,
        },
        data: {
          apnInfoId: apn.apnInfoId,
          groupId,
        },
      });
      await request.process();
      toastSuccess('Success', `APN settings remove successfully from group.`);
    } catch (e) {
      toastWarning('Error', 'Failed to remove APN Settings');
    }
  };

  /** @param {Array<APNInfo>} selectedItems  */
  const deleteApnSettings = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteApnSetting));
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title="APN Settings" actions={ACTION_ITEMS} onAction={onActionHandle} />
        <ContinuationTokenTable
          results={result}
          loading={loading}
          columns={APN_TABLE_COLUMNS}
          onReload={() => fetchApnSettings()}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
