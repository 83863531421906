import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box } from '@mui/material';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { CameraAdminAddModal } from '../../@components/CameraAdminAddModal';
import { CameraUserPermissionModal } from '../../@components/CameraUserPermissionModal';
import { ACTION_ITEMS } from './actions';
import { CAMERA_USER_TABLE_COLUMNS } from './columns';

export function CameraUsersPage() {
  const { cameraId } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);

  const [selected, setSelected] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);

  const {
    result: users,
    loading,
    retry: fetchCameraUsers,
  } = useFutureLoader(async ({ secretToken }) => {
    const request = api.ac.endpoint.acl.associatedusers.$get({
      params: {
        endpointId: cameraId,
        secretToken,
      },
    });
    const result = await request.process();
    return result.userRoles;
  }, []);

  /** @param {UserRole} user  */
  const removeUserFromCamera = async (user) => {
    try {
      const request = api.ac.endpoint.acl.remove.user.$delete({
        params: {
          secretToken,
          fromEndpointId: cameraId,
          toUserId: user.userId,
        },
      });
      await request.process();
    } catch (e) {
      toastWarning('Error', `Failed to remove ${user.name}`);
    }
  };

  /** @param {Array<UserRole>} selectedItems */
  const removeUsersFromCamera = async (selectedItems) => {
    await Promise.all(selectedItems.map(removeUserFromCamera));
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<Device>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    if (type === 'ADD_USER') {
      setShowAddModal(true);
    } else if (type === 'DISCONTINUE') {
      await removeUsersFromCamera(selectedItems);
      fetchCameraUsers();
    } else if (type === 'MODIFY') {
      setShowModifyModal(true);
      setSelected(selectedItems[0]);
    }
  };

  return (
    <Box mx={2.5} mt={2} mb={5}>
      <PaginatedTableContextProvider>
        {showModifyModal && (
          <CameraUserPermissionModal
            open={true}
            user={selected}
            onClose={() => {
              setShowModifyModal(false);
              setSelected(null);
            }}
            onSuccess={() => {
              setShowModifyModal(false);
              fetchCameraUsers();
            }}
          />
        )}
        {showAddModal ? (
          <CameraAdminAddModal
            open={true}
            admins={users}
            onClose={() => setShowAddModal(false)}
            onSuccess={() => {
              setShowAddModal(false);
              fetchCameraUsers();
            }}
          />
        ) : null}
        <ItemsListHeader title="Users" actions={ACTION_ITEMS} onAction={onActionHandle} />
        <ContinuationTokenTable
          results={users}
          loading={loading}
          columns={CAMERA_USER_TABLE_COLUMNS}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
