import { CameraDetailsLayout } from '@/web/@layouts/CameraDetailsLayout';
import { DeviceGroupCameras } from '..';
import { CameraSettings } from './@components/CameraSettings';
import { ManageCameraDetailsPage } from './_id';
import { CameraAdbLogs } from './_id/adb-logs';
import { CameraServerConfigurationPage } from './_id/controls';
import { CameraDiagnosticsLogs } from './_id/diagnostics-logs';
import { CameraOTALogs } from './_id/ota-logs';
import { CameraPresencePage } from './_id/presence';
import { CameraSchemes } from './_id/schemes';
import { CameraSensorPage } from './_id/sensors';
import { CameraSoftwarePage } from './_id/softwares';
import { CameraUsages } from './_id/usages';
import { CameraUsersPage } from './_id/users';
import { EffectiveSchemes } from './_id/schemes/effective';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DeviceGroupCameras />,
  },
  {
    path: ':cameraId',
    element: <CameraDetailsLayout />,
    breadcrumb: 'Camera Details',
    children: [
      {
        path: '',
        element: <ManageCameraDetailsPage />,
      },
      {
        path: 'users',
        element: <CameraUsersPage />,
      },
      {
        path: 'softwares',
        element: <CameraSoftwarePage />,
      },
      {
        path: 'settings',
        element: <CameraSettings />,
      },
      {
        path: 'diagnostics-logs',
        element: <CameraDiagnosticsLogs />,
      },
      {
        path: 'ota-logs',
        breadcrumb: 'OTA Logs',
        element: <CameraOTALogs />,
      },
      {
        breadcrumb: 'ADB Logs',
        path: 'adb-logs',
        element: <CameraAdbLogs />,
      },
      {
        path: 'presence',
        element: <CameraPresencePage />,
      },
      {
        path: 'schemes',
        breadcrumb: 'Schemes',
        children: [
          {
            path: '',
            element: <CameraSchemes />,
          },
          {
            path: 'effective',
            breadcrumb: 'View Effective Scheme',
            element: <EffectiveSchemes />,
          },
        ],
      },
      {
        path: 'usages',
        element: <CameraUsages />,
      },
      {
        path: 'controls',
        element: <CameraServerConfigurationPage />,
      },
      {
        path: 'sensors',
        element: <CameraSensorPage />,
      },
    ],
  },
];

export default routes;
