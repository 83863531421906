import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { isValidEmail } from '@/utils/input';
import { toastWarning } from '@/utils/toaster';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const filter = createFilterOptions();

/**
 * @typedef {object} CameraAdminAddModalProps
 * @property {boolean} [open]
 * @property {Array<UserRole>} [admins]
 * @property {() => any} onClose
 * @property {() => any} onSuccess
 * @property {number} [defaultGroupId]
 */

/** @param {CameraAdminAddModalProps} props */
export function CameraAdminAddModal(props) {
  const { open, onClose, onSuccess, admins = [] } = props;
  const { cameraId } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [userEmail, setUserEmail] = useState(null);
  const [role, setRole] = useState('ADMIN');

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const { result, loading } = useFutureLoader(async ({ signal, secretToken }) => {
    let limit = 100;
    let offset = 0;
    /** @type {Array<UserInfo>} */
    const results = [];
    while (true) {
      const request = api.ac.auth.user.list.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          secretToken,
          domain: 'TENANT',
          offset,
          limit,
        },
      });
      await request.process();
      const users = request.result.userInfoList;
      results.push(...users);
      offset += limit;
      if (users.length < limit) break;
    }
    return results?.filter((obj) => !admins?.map((item) => item.userId)?.includes(obj.userId));
  }, []);

  if (!open) {
    return null;
  }

  const handleUserAdd = async () => {
    if (!isValidEmail(userEmail)) {
      toastWarning('Please enter a valid email address');
      return;
    }
    try {
      const request = api.ac.v5.endpoint.$endpointId(cameraId).share.$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          userEmail,
          userRole: role,
        },
      });
      await request.process();
      onSuccess();
    } catch (err) {
      console.error(err);
      toastWarning('Error', err?.response?.data?.message || 'Failed to add user');
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Add User to Camera</DialogTitle>
      <Divider />
      <DialogContent>
        <Box component="form" noValidate autoComplete="off">
          <FormControl sx={{ width: '100%', mb: '10px' }}>
            <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
              User Email
            </FormLabel>
            <Autocomplete
              size="small"
              loading={loading}
              defaultValue={''}
              value={userEmail || ''}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setUserEmail(newValue);
                } else if (newValue && newValue?.inputValue) {
                  setUserEmail(newValue?.inputValue);
                } else {
                  setUserEmail(newValue?.email);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter ? filter(options, params) : [];
                const { inputValue } = params;
                const isExisting = options.some((option) => option.email?.startsWith(inputValue));
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    email: `Add "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={result || []}
              getOptionLabel={(option) => {
                if (typeof option === 'string') return option;
                if (option.inputValue) return option?.inputValue;
                return option.email;
              }}
              renderOption={(props, option) => <li {...props}>{option.email}</li>}
              freeSolo
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
              User Permission
            </FormLabel>
            <Select size="small" value={role} onChange={handleChange}>
              <MenuItem value={'ADMIN'}>Admin</MenuItem>
              <MenuItem value={'VIEWER'}>Viewer</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" disabled={!userEmail} onClick={handleUserAdd}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
