import { Box, CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { InfiniteScrollViewContext } from '.';
import { IconMessageBox } from '../IconMessageBox';

/**
 * @param {import('@mui/material').BoxProps} props
 * @returns {any}
 */
export function InfiniteScrollLoadNext(props) {
  const { children, ...boxProps } = props;
  const state = useContext(InfiniteScrollViewContext);

  const { ref, inView } = useInView({
    delay: 50,
    threshold: 0.1,
    initialInView: false,
  });

  if (!state) {
    return null;
  }

  const { finished, loading, error, results, loadNext } = state;

  if (finished) {
    if (!results?.length) {
      return (
        children ?? (
          <IconMessageBox
            size="256px"
            src="/images/player/no-data-icon.svg"
            message="No data Found"
          />
        )
      );
    }
    return null;
  }

  if (loading) {
    return children ?? <CircularProgress size="24px" />;
  }

  if (error) {
    return (
      children ?? (
        <IconMessageBox
          size={42}
          icon="warning"
          onRetry={loadNext}
          message="Failed to fetch data"
        />
      )
    );
  }

  return (
    <Box textAlign="center" height={'50px'} {...boxProps} ref={ref}>
      {inView && <img src="#" onError={loadNext} alt="load next" style={{ opacity: 0 }} />}
      <CircularProgress size="24px" />
    </Box>
  );
}
