import { downloadVideoFile } from './file-utils';

/**
 * @param {HTMLVideoElement} video
 * @param {string} [type] Image type. Default: image/jpg
 * @param {any} [quality] Image quality. Default: 0.85
 */
export function captureVideoFrame(video, type = 'image/jpg', quality = 0.85) {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0);
    return canvas.toDataURL(type, quality);
  } catch (err) {
    console.warn('Failed to capture video frame', err);
    return null;
  }
}

/**
 * Returns a video component
 * @param {string|MediaStream} src
 * @param {AbortSignal} [signal] A signal to abort the operation
 * @param {{stream?: boolean, timeout?: number}} [options]
 * @returns {Promise<HTMLVideoElement>}
 */
export async function makeVideo(src, signal, options = {}) {
  const { timeout, stream = true } = options || {};

  if (!src) throw new Error('Invalid video url');

  const aborter = new AbortController();
  let tid = setTimeout(() => aborter.abort('timeout'), timeout || 60000);
  signal?.addEventListener('abort', () => aborter.abort('signal'));

  signal = aborter.signal;
  signal.addEventListener('abort', () => clearTimeout(tid));

  if (!stream && typeof src === 'string' && src.startsWith('http')) {
    src = await downloadVideoFile(src, aborter.signal).catch(() => src);
  }

  return await Promise.race([
    new Promise((resolve, reject) => {
      aborter.signal.addEventListener('abort', (e) =>
        reject(new Error('makeVideo aborted: ' + aborter.signal.reason))
      );
    }),
    new Promise(async (resolve, reject) => {
      // create the video element
      const video = document.createElement('video');
      video.style.width = '100%';
      video.style.height = '100%';
      video.style.objectFit = 'contain';
      video.style.objectPosition = 'center';
      video.crossOrigin = 'anonymous';
      video.preload = 'none';
      video.playsInline = true;
      video.muted = true;
      video.autoplay = false;

      const onLoad = () => {
        video.pause();
        resolve(video);
        video.removeEventListener('load', onLoad);
        video.removeEventListener('error', onLoad);
        video.removeEventListener('loadeddata', onLoad);
        video.removeEventListener('loadedmetadata', onLoad);
      };
      video.addEventListener('load', onLoad, { signal });
      video.addEventListener('error', onLoad, { signal });
      video.addEventListener('loadeddata', onLoad, { signal });
      video.addEventListener('loadedmetadata', onLoad, { signal });

      if (typeof src === 'string') {
        video.src = src;
      } else if (src.id) {
        video.srcObject = src;
      }
      video.load();
    }),
  ]).finally(() => clearTimeout(tid));
}
