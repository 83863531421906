import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DBCFileForm } from '../../@components/DBCForm/index';
import { uploadDBCFile } from '../../utils';
import { DbcVersionHistory } from '../../@components/VersionHistory';

export function DbcFileEditPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: dbcId } = useParams();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  /** @type {StateVariable<DbcFileDto>} */
  const [dbcDetails, setDbcDetails] = useState(null);
  /** @type {StateVariable<AbortController>} */
  const [submitting, setSubmitting] = useState();
  /** @type {StateVariable<boolean>} */
  const [showVersion, setShowVersion] = useState(true);

  useEffect(() => {
    if (!dbcId) {
      navigate('/administration/dbc');
      return;
    }
    const request = api.ac.v5.dbc.$id(dbcId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    setLoading(true);
    request
      .process()
      .then(setDbcDetails)
      .catch(() => navigate('/administration/dbc'))
      .finally(() => setLoading(false));
  }, [secretToken, dbcId, tenantId, navigate]);

  useEffect(() => {
    if (!dbcDetails?.name) return;
    setBreadcrumbTitle(dbcDetails?.name);
  }, [dbcDetails?.name, setBreadcrumbTitle]);

  /** @type {import('../../@components/DBCForm').DBCFileFormProps['onSubmit']} */
  const updateDbcFiles = async (fields) => {
    const aborter = new AbortController();
    setSubmitting((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      fields.dbcFile && setProgressModalOpen(true);

      /** @type {DbcFileDto} */
      const body = {};
      body.name = dbcDetails?.name;
      body.description = fields.description;

      if (fields.dbcFile) {
        await uploadDBCFile(fields.dbcFile, body, dbcId, setPercentage, aborter.signal);
      } else {
        body.fileName = dbcDetails?.fileName;
      }

      const request = api.ac.v5.dbc.$id(dbcId).$put({
        data: body,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', 'Bbc file updated successfully');
      navigate('/administration/dbc');
    } catch (err) {
      if (!aborter.signal.aborted) {
        if (
          err?.response?.data?.length &&
          err.response.data[0]?.message === 'ALREADY_EXISTS_VERSION'
        ) {
          toastWarning('Error', 'This DBC File version already exists.');
        } else {
          toastWarning('Error', 'Could not update DBC File.');
        }
      }
    } finally {
      setSubmitting(null);
      setProgressModalOpen(false);
    }
    return null;
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Update DBC File"
          progressText={'Uploading file...'}
          onClose={() => {
            submitting?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['dbc.update.page.title'])}
      </Typography>

      <DBCFileForm
        dbcId={dbcId}
        dbcDetails={dbcDetails}
        onSubmit={updateDbcFiles}
        disabled={Boolean(submitting)}
      />

      {
        // @ts-ignore
        dbcDetails?.versions?.length > 0 && (
          <Box>
            <Typography
              color={'#7a9fd2'}
              mt={'-25px'}
              onClick={() => {
                setShowVersion((prev) => !prev);
              }}
              sx={{ fontSize: '0.875rem', color: '#4177BF', cursor: 'pointer' }}
            >
              Version History
            </Typography>

            {showVersion && (
              <DbcVersionHistory
                versions={
                  // @ts-ignore
                  dbcDetails?.versions
                }
              />
            )}
          </Box>
        )
      }
    </Box>
  );
}
