module.exports = {
  'ai.containers.create.button': 'Создайте контейнер ИИ',
  'ai.containers.page.card.browse': 'Просмотрите контейнер ИИ',
  'ai.containers.page.card.create': 'Создайте новый контейнер для ИИ',
  'ai.containers.page.card.title': 'Контейнеры ИИ',
  'ai.containers.page.title': 'Контейнеры ИИ',
  'ai.containers.table.button.add': 'Контейнер ИИ',
  'ai.containers.table.columns.name': 'Имя',
  'ai.containers.table.columns.publication.status': 'Статус',
  'ai.containers.table.columns.release.version': 'Выпуск версии',
  'ai.containers.table.columns.version': 'Версия',
  'ai.containers.table.search.placeholder': 'Поиск контейнера ИИ',
  'ai.models.create.button': 'Создайте модель ИИ',
  'ai.models.name': 'ИИ модели',
  'ai.models.page.card.browseItems': 'Просмотрите модели ИИ',
  'ai.models.page.card.createNew': 'Создайте новую модель ИИ',
  'ai.models.page.card.name': 'ИИ модели',
  'ai.models.table.button.add': 'У вас есть модель',
  'ai.models.table.columns.name': 'Имя',
  'ai.models.table.columns.publication.status': 'Статус',
  'ai.models.table.columns.release.version': 'Выпуск версии',
  'ai.models.table.columns.vendor': 'Продавец',
  'ai.models.table.search.placeholder': 'Поиск моделей ИИ',
  'annotations.fetching.data.message': 'Привлечение категорий аннотаций',
  'annotations.no.categories.message': 'Нет категорий аннотаций.',
  'annotations.page.card.browseItems': 'Просмотр аннотации',
  'annotations.page.card.createNew': 'Создать аннотацию',
  'annotations.page.card.name': 'Категории аннотации',
  'annotations.page.title': 'Аннотации',
  'annotations.search.placeholder': 'Поиск аннотации для редактирования и удаления',
  'button.agree': 'Соглашаться',
  'button.cancel': 'Отмена',
  'button.details': 'Подробности',
  'button.disagree': 'Не согласен',
  'button.discontinue': 'Прекратить',
  'button.edit': 'Редактировать',
  'button.modify': 'Модифицировать',
  'button.release': 'Выпускать',
  'button.retry': 'Повторно',
  'button.save': 'Сохранять',
  'button.update': 'Обновлять',
  'cameras.no.data.message': 'Камеры не найдено!',
  'cameras.page.title': 'Камеры',
  'cameras.search.placeholder': 'Поиск по имени камеры, идентификатор камеры, местоположение и VIN',
  'change.password.title': 'Изменить пароль',
  'confirmations.discontinue': 'Вы уверены, что хотите прекратить эти предметы?',
  'confirmations.release': 'Вы уверены, что хотите выпустить эти предметы?',
  'data-residency.page.card.configurations': 'Конфигурации',
  'data-residency.page.card.name': 'Данные резидентуры',
  'data-retention.page.card.configurations': 'Конфигурации',
  'data-retention.page.card.name': 'Хранение данных',
  'device-batches.page.card.browseItems': 'Просмотр партии устройств',
  'device-batches.page.card.createNew': 'Создать партию устройства',
  'device-batches.page.card.name': 'Партии устройств',
  'device-group.columns.description': 'Описание',
  'device-group.columns.name': 'Имя',
  'device-group.columns.product': 'Товар',
  'device-group.create.button': 'Группа устройств',
  'device-group.create.failed': 'Не удалось обновить группу устройств',
  'device-group.create.page.title': 'Создать группу камеры',
  'device-group.create.retry':
    'Не мог добавить группу.Пожалуйста, попробуйте еще раз через некоторое время',
  'device-group.create.success': 'Группа {{groupName}} была успешно добавлена.',
  'device-group.delete.failed': 'Не удалось прекратить {{groupName}}.',
  'device-group.delete.retry': 'Не удалось прекратить {{groupName}}.Пожалуйста, попробуйте позже.',
  'device-group.delete.success': 'Группа устройства {{groupName}} успешно прекратилась.',
  'device-group.edit.failed': 'Не удалось обновить группу устройств',
  'device-group.edit.success': 'Группа {{groupName}} была успешно обновлена.',
  'device-group.edit.title': 'Обновить группу камеры',
  'device-group.form.description.placeholder': 'Введите описание группы',
  'device-group.form.group.name.hint': 'Пожалуйста, введите имя группы',
  'device-group.form.group.name.label': 'Название группы',
  'device-group.form.group.name.placeholder': 'Введите имя группы',
  'device-group.form.select.product.hint': 'Пожалуйста, выберите продукт',
  'device-group.form.select.product.label': 'Товар',
  'device-group.form.select.product.placeholder': 'Выберите продукт',
  'device-group.placeholder.search': 'Поиск группы устройств',
  'device-group.retry':
    'Не мог обновить группу.Пожалуйста, попробуйте еще раз через некоторое время',
  'device-group.title': 'Группы устройств',
  'device.groups.page.card.browseItems': 'Обзор группы устройств',
  'device.groups.page.card.createNew': 'Создать группу устройств',
  'device.groups.page.card.name': 'Группы устройств',
  'device.pools.page.card.browseItems': 'Просмотрите бассейны устройств',
  'device.pools.page.card.createNew': 'Создать пул устройств',
  'device.pools.page.card.name': 'Бассейны устройств',
  'events.no.data.message': 'События не найдено!',
  'events.page.title': 'События',
  'events.search.placeholder': 'Поиск',
  'firmwares.page.card.browseItems': 'Просмотр прошивки',
  'firmwares.page.card.name': 'Фермана',
  'firmwares.page.card.upload': 'Загрузите прошивку',
  'geofences.page.card.browseItems': 'Просмотр геозисов',
  'geofences.page.card.createNew': 'Создать геозону',
  'geofences.page.card.name': 'Геоза',
  'login.alternate.text': 'Или вы можете с',
  'login.banner': 'Точность ИИ для доверенных данных и решений',
  'login.button.google': 'google',
  'login.button.microsoft': 'microsoft',
  'login.button': 'Авторизоваться',
  'login.forget.password': 'Забыл пароль?',
  'login.label.email': 'Эл. адрес',
  'login.label.password': 'Пароль',
  'login.label.remember.me': 'Запомните меня',
  'login.title': 'Войдите в свою учетную запись',
  'navigation.ai.container': 'Контейнеры ИИ',
  'navigation.ai.models': 'ИИ модели',
  'navigation.ai': 'Ай',
  'navigation.annotations': 'Аннотации',
  'navigation.cameras': 'Камеры',
  'navigation.configurations': 'Конфигурации',
  'navigation.data.residency': 'Данные резидентуры',
  'navigation.data.retention': 'Хранение данных',
  'navigation.device.batches': 'Партии устройств',
  'navigation.device.groups': 'Группы устройств',
  'navigation.device.pools': 'Бассейны устройств',
  'navigation.events': 'События',
  'navigation.firmwares': 'Фермана',
  'navigation.gallery': 'Галерея',
  'navigation.operations.geofences': 'Геоза',
  'navigation.operations': 'Операции',
  'navigation.administration': 'Оркестровка',
  'navigation.privacy.and.compliance': 'Конфиденциальность и соответствие',
  'navigation.privacy.policies': 'Политика конфиденциальности',
  'navigation.products': 'Товары',
  'navigation.reports': 'Отчеты',
  'navigation.smarter.ai': 'Умнее ай',
  'navigation.tenants': 'Арендаторы',
  'navigation.triggers': 'Триггеры',
  'navigation.users': 'Пользователи',
  'navigation.vin.groups': 'vinGroups',
  'navigation.waste.management': 'Управление отходами',
  'navigation.winter.operations': 'Зимние операции',
  'no.data.found': 'Данные не найдены!',
  'pages.administration.deviceGroups.form.description.name': 'Описание',
  'privacy-policies.page.card.biometric': 'Биометрический',
  'privacy-policies.page.card.consent': 'Согласие',
  'privacy-policies.page.card.name': 'Политика конфиденциальности',
  'privacy-policies.page.card.tools': 'Инструменты',
  'products.page.card.manage-accelerator': 'Управление акселератором ИИ',
  'products.page.card.manageHardwareProducts': 'Управление аппаратными продуктами',
  'products.page.card.name': 'Товары',
  'reset.password.title': 'Сброс пароля',
  'table.per.page': '{{item}} на страницу',
  'tenants.label.select': 'Выберите арендатора',
  'tenants.page.card.browseItems': 'Просматривать арендаторов',
  'tenants.page.card.createNew': 'Создать арендатора',
  'tenants.page.card.name': 'Арендаторы',
  'tenants.placeholder.search': 'Поиск арендатора',
  'tenants.title': 'Арендаторы',
  'triggers.create.button': 'Создать триггер',
  'triggers.page.card.browseItems': 'Просмотр триггеров',
  'triggers.page.card.createNew': 'Создать триггер',
  'triggers.page.card.manageLabels': 'Управление ярлыками',
  'triggers.page.card.name': 'Триггеры',
  'triggers.page.title': 'Триггеры',
  'triggers.table.button.add': 'Курок',
  'triggers.table.columns.name': 'Имя',
  'triggers.table.columns.publication.status': 'Статус',
  'triggers.table.columns.type': 'Тип',
  'triggers.table.search.placeholder': 'Поиск триггера',
  'users.page.card.browseItems': 'Просмотрите пользователей',
  'users.page.card.createNew': 'Создать пользователя',
  'users.page.card.name': 'Пользователи',
  'vin.groups.page.card.browseItems': 'Просмотрите группы VIN',
  'vin.groups.page.card.createNew': 'Создать VIN Group',
  'vin.groups.page.card.name': 'vinGroups',
  'waste-management.configurations.page.card.customer': 'Клиенты',
  'waste-management.configurations.page.card.name': 'Конфигурации',
  'waste-management.configurations.page.card.softwareConf': 'Конфигурация программного обеспечения',
  'waste-management.operations.page.card.name': 'Операции',
  'waste-management.reports.page.card.browseItems': 'Обзор отчетов',
  'waste-management.reports.page.card.createNew': 'Создать отчет',
  'waste-management.reports.page.card.name': 'Отчеты',
  'winter-operations.configurations.page.card.customer': 'Клиенты',
  'winter-operations.configurations.page.card.name': 'Конфигурации',
  'winter-operations.configurations.page.card.softwareConf':
    'Конфигурация программного обеспечения',
  'winter-operations.gallery.page.card.name': 'Галерея',
  'winter-operations.reports.page.card.browseItems': 'Обзор отчетов',
  'winter-operations.reports.page.card.createNew': 'Создать отчет',
  'winter-operations.reports.page.card.name': 'Отчеты',
};
