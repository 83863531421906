import { Box, Button, CircularProgress, Popover, Typography } from '@mui/material';
import { useState } from 'react';

export function TriggerResetButton({ loading, actionType, onReset }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleOnClick = () => {
    onReset();
  };

  return (
    <Box
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Button
        autoFocus
        variant="outlined"
        color="error"
        size="small"
        type="submit"
        disabled={loading}
        onClick={handleOnClick}
        startIcon={loading && actionType === 'RESET' && <CircularProgress size={20} />}
      >
        Reset
      </Button>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption">Reset to default settings.</Typography>
        </Box>
      </Popover>
    </Box>
  );
}
