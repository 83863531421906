import {
  AI_CONTAINER_ADD_PLUS_ICON,
  AI_CONTAINER_AI_MODEL_ICON,
  AI_CONTAINER_PRODUCT_ICON,
} from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Button, IconButton, Typography } from '@mui/material';

/**
 * @typedef {object} ComponentNodeProps
 * @property {boolean} [adding]
 * @property {boolean} [selected]
 * @property {string} [label]
 * @property {'product'|'model'} [icon]
 * @property {(addMode: boolean) => any} [onSelect]
 * @property {import('@mui/material').BoxProps['sx']} [sx]
 */

/** @param {ComponentNodeProps} props  */
export function ComponentNode(props) {
  const { adding, selected, label, icon = 'model', onSelect, sx } = props;

  /** @type {import('react').MouseEventHandler<HTMLButtonElement>} */
  const handlePlusClick = (e) => {
    e.stopPropagation();
    onSelect && onSelect(true);
  };

  /** @type {import('react').MouseEventHandler<HTMLButtonElement>} */
  const handleTextClick = (e) => {
    e.stopPropagation();
    onSelect && onSelect(false);
  };

  return (
    <Box sx={sx} gap="5px" height="40px" display="inline-flex" alignItems="center">
      <Button
        variant="text"
        onClick={handleTextClick}
        sx={{
          p: '5px',
          px: '10px',
          gap: '10px',
          bgcolor: '#fff',
          borderRadius: 0,
          border: selected ? '1px solid #608AC3;' : null,
        }}
      >
        <BoxImage
          src={icon === 'model' ? AI_CONTAINER_AI_MODEL_ICON : AI_CONTAINER_PRODUCT_ICON}
          size={'24px'}
          alt={icon}
        />
        <Typography variant="subtitle1" fontWeight={600} whiteSpace="nowrap">
          {label}
        </Typography>
      </Button>
      <IconButton
        size="small"
        onClick={handlePlusClick}
        sx={adding ? { bgcolor: '#fff', border: '1px solid #608AC3' } : null}
      >
        <BoxImage src={AI_CONTAINER_ADD_PLUS_ICON} size={'18px'} borderRadius="50%" />
      </IconButton>
    </Box>
  );
}
