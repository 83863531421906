import { formatCameraName } from '@/utils/cameras';
import { LocationWithIcon } from '@/web/@components/LocationWithIcon';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<Endpoint>>} */
export const GROUP_CAMERA_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'label',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    sort: true,
    searchable: true,
    link: (row) => `./${row.id}`,
    format: (value, row) => formatCameraName(row.label, row.deviceKey),
  },
  {
    id: 'country',
    label: 'Location',
    sort: true,
    searchable: true,
    format: (value, row) => <LocationWithIcon location={`${row.city}, ${row?.country}`} />,
  },
  {
    id: 'deviceKey',
    label: 'Serial No',
    align: 'left',
    sort: true,
    searchable: true,
  },
  {
    id: 'packageVersion',
    label: 'Version',
    align: 'left',
    sort: true,
    searchable: true,
  },
];
