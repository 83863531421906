import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { DevicePoolDetailsContext } from '../DevicePoolDetailsLayout';
import { CAMERA_TABLE_COLUMNS } from './columns';

export function DevicePoolDeviceList() {
  const { searchText } = useContext(FilterContext);
  const { poolId } = useContext(DevicePoolDetailsContext);
  const { reloadStatus, setTableReload, setSelectedItems } = useContext(PaginatedTableContext);

  const {
    result: devices = [],
    loading,
    error,
    retry,
  } = useFutureLoader(
    async ({ signal, secretToken }) => {
      /** @type {Array<ZeroTouchDeviceModel>} */
      const results = [];
      const pageSize = 25;
      let afterId;
      while (!signal.aborted) {
        const request = api.ac.v5['device-pool'].$poolId(poolId).device.$get({
          signal,
          headers: {
            Authorization: `Bearer ${secretToken}`,
          },
          params: {
            limit: pageSize,
            afterId,
          },
        });
        await request.process();
        const data = request.result.deviceList;
        results.push(...data);
        afterId = request.result.deviceList[data.length - 1]?.id;
        if (!afterId || data.length < pageSize) break;
      }
      return results;
    },
    [poolId]
  );

  const filteredDevices = useMemo(() => {
    const q = trimString(searchText).toLowerCase();
    if (!q) return devices || [];
    return (devices || []).filter((item) => item.id.toLowerCase().includes(q));
  }, [searchText, devices]);

  useEffect(() => {
    if (!reloadStatus) return;
    setTableReload(false);
    retry();
  }, [reloadStatus, setTableReload, retry]);

  useEffect(() => {
    setSelectedItems([]);
  }, [filteredDevices, setSelectedItems]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error || !filteredDevices?.length) {
    return (
      <CenterBox fullView>
        <IconMessageBox
          size="256px"
          src="/images/empty/no-search-results.svg"
          message={'No Device Available'}
        />
      </CenterBox>
    );
  }

  return <ItemsResponsiveView results={filteredDevices} columns={CAMERA_TABLE_COLUMNS} />;
}
