/** @type {Array<TableColumn<EndpointRole & Endpoint>>} */
import { CAMERA_OFF_ICON, CAMERA_ON_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { capitalize } from 'lodash';
export const USER_HARDWARE_DEVICE_TABLE_COLUMNS = [
  {
    id: 'imageUrl',
    label: '',
    align: 'left',
    width: '10px',
    isPrimary: true,
    format: (value, row) => (
      <BoxImage
        alt="Camera"
        display="block"
        className="icon-image"
        sx={{
          width: { xs: '20px', md: '28px' },
        }}
        src={row.isOnline ? CAMERA_ON_ICON : CAMERA_OFF_ICON}
      />
    ),
  },
  {
    id: 'label',
    label: 'Name',
    align: 'left',
    searchable: true,
    isPrimary: true,
    link: (row) => `/cameras/${row.endpointId}`,
  },
  {
    id: 'country',
    label: 'Location',
    align: 'left',
  },
  {
    id: 'packageVersion',
    label: 'Version',
    align: 'left',
  },
  {
    id: 'role',
    label: 'Permission',
    align: 'left',
    format: (value) => capitalize(value),
  },
];
