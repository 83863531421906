import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';

import { DialogTable } from '@/web/@components/DialogTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import { FLEET_CAMERA_TABLE_COLUMNS } from './columns';

/**
 * @typedef {object} CameraSelectionPageProps
 * @property {boolean} [isOpen]
 * @property {boolean} [cameraAddLoading]
 * @property {() => any} [onModalClose]
 * @property {(val: EndpointInfoAggregated) => any} [onHandleSelect]
 */

/**
 * @param {CameraSelectionPageProps} props
 */

export function CameraSelectionPage(props) {
  const { isOpen, cameraAddLoading, onModalClose, onHandleSelect } = props;

  /** @type {StateVariable<Array<EndpointInfoAggregated>>} */
  const [selectedCameraItems, setSelectedCameraItems] = useState([]);

  const {
    result = [],
    loading: deviceLoading,
    error: deviceError,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    let limit = 100;
    let offset = 0;
    /** @type {Array<EndpointInfoAggregated>} */
    const results = [];
    while (!signal.aborted) {
      const request = api.ac.v5.endpoint.list.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          limit: limit,
          offset,
          tenantId,
          type: 'DEVICE',
          status: 'ACTIVE',
        },
      });
      await request.process();
      const endpoints = request.result.endpointInfoList;
      const filteredEndpoint = endpoints.filter((item) => item.deviceOnlineStatus);
      results.push(...filteredEndpoint);
      offset += endpoints.length;
      if (endpoints.length < limit) break;
    }
    return results;
  }, []);

  const handleAddDevice = () => {
    onHandleSelect(selectedCameraItems[0]);
  };

  return (
    <Dialog open={isOpen} onClose={onModalClose} maxWidth={'md'} fullWidth={true}>
      <DialogTitle>Select Camera</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogTable
          title="Cameras"
          disableMultiSelect
          loading={deviceLoading}
          error={deviceError}
          results={result}
          columns={FLEET_CAMERA_TABLE_COLUMNS}
          dataSortKey="deviceLabel"
          onSelectItem={setSelectedCameraItems}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onModalClose}>Cancel</Button>
        <Button onClick={handleAddDevice} autoFocus variant="contained" disabled={cameraAddLoading}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
