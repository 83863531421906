import { T } from '@/assets/locales';
import { ChildTenantList } from '@/web/@components/ChildTenantList';
import { AiContainerListView } from '@/web/administration/ai/ai-container/index';
import { Navigate } from 'react-router-dom';
import { AiContainerDetailsLayout } from './@components/AiContainerDetailsLayout';
import { AiContainerCreatePage } from './create';
import { AssignedChildAiContainerList } from './tenant-deployment';
import { AiContainerEditPage } from './_id/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <AiContainerListView />,
  },
  {
    path: 'create',
    element: <AiContainerCreatePage />,
    breadcrumb: T['ai.containers.create.button'],
  },
  {
    path: 'tenant-deployment',
    breadcrumb: 'Tenant Deployment',
    children: [
      {
        path: '',
        element: <ChildTenantList type="AI_CONTAINER" />,
        breadcrumb: 'Child Tenants',
      },
      {
        path: ':tenantId/ai-containers/:tenantName',
        element: <AssignedChildAiContainerList />,
        breadcrumb: 'AI Containers',
      },
    ],
  },
  {
    path: ':id',
    element: <AiContainerDetailsLayout />,
    children: [
      {
        path: '',
        element: <Navigate to=".." />,
      },
      {
        path: 'edit',
        element: <AiContainerEditPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
