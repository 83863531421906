import { AI_LABEL_DATA } from '../ai-labels';
import { makeDisplayText } from '../sensors/extractor';

export class BoundingBoxLabelRenderer {
  /**
   * @param {import('.').BoundingBoxRenderer} parent
   */
  constructor(parent) {
    this.parent = parent;
    this.canvas = parent.canvas;
    this.ctx = parent.ctx;
    this.options = parent.options;
  }

  /**
   * Braw Bounding Box Labels
   * @param {Array} detections
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @param {string} [backColor]
   */
  render(detections, x, y, width, height, backColor) {
    let {
      imperial,
      lineWidth,
      labelGap,
      textPaddingX,
      textPaddingY,
      connectorLength,
      fontWeight,
      fontSize,
      fontFamily,
      disabledLabels,
      decimalPlaces,
      boxColor,
    } = this.options;

    backColor ||= boxColor;

    const labelFont = `${fontWeight} ${fontSize}px ${fontFamily}`;
    this.ctx.font = labelFont;
    const measure = this.ctx.measureText('M'); // https://stackoverflow.com/a/13318387/1583052
    const textHeight = measure.actualBoundingBoxAscent
      ? measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent
      : measure.fontBoundingBoxAscent
        ? measure.fontBoundingBoxAscent + measure.fontBoundingBoxDescent
        : measure.width;
    const paddedTextHeight = textHeight + 2 * textPaddingY;

    let leftCounter = 0;
    let rightCounter = 0;
    for (const item of detections) {
      // if (isNanCheck) if (!Number.isNaN(Number(item[0]))) continue;
      if (disabledLabels && disabledLabels.indexOf(item[0]) >= 0) continue;
      if (!/[a-zA-Z]/.test(item[0])) continue;
      const aiLabel = AI_LABEL_DATA[item[0]];
      if (!aiLabel) continue;

      /** @type {TelematicsTemplate} */
      const temp = {
        name: item[0],
        value: item[1],
        type: 'number',
        source: 'bbox',
        min: aiLabel.min,
        max: aiLabel.max,
        unit: aiLabel.unit,
      };

      const text = `${aiLabel.displayName} → ${makeDisplayText(temp, decimalPlaces, imperial)}`;
      const { width: textWidth } = this.ctx.measureText(text);
      const paddedTextWidth = textWidth + 2 * textPaddingX;

      // calculate x for left and the right side to decide where to draw
      const leftStart = x - connectorLength - paddedTextWidth - lineWidth;
      const rightEnd = x + width + lineWidth + connectorLength + paddedTextWidth;
      if (leftStart <= 0 && rightEnd >= this.canvas.height) {
        continue; // overlaps on both side. hide it!
      }

      let labelX, labelY;
      this.ctx.save();
      this.ctx.beginPath();
      this.ctx.lineWidth = 2 * lineWidth;
      this.ctx.strokeStyle = backColor;

      if (leftStart > 0 && (rightEnd >= this.canvas.height || leftCounter < rightCounter)) {
        // draw on the left side of bounding box
        labelX = x - connectorLength - paddedTextWidth - lineWidth;
        labelY = y + leftCounter * (paddedTextHeight + labelGap) + 0.5 * paddedTextHeight;

        // draw connector line
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.lineWidth = 2 * lineWidth;
        this.ctx.strokeStyle = backColor;
        this.ctx.moveTo(x, labelY);
        this.ctx.lineTo(x - connectorLength, labelY);
        this.ctx.stroke();

        // draw follow up line
        if (labelY > y + height) {
          this.ctx.moveTo(x, labelY);
          this.ctx.lineTo(x + width / 2, y + height);
          this.ctx.stroke();
        }

        leftCounter++;
      } else {
        // draw on the right side of bounding box
        labelX = x + width + connectorLength - lineWidth;
        labelY = y + rightCounter * (paddedTextHeight + labelGap) + 0.5 * paddedTextHeight;

        // draw connector line
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.lineWidth = 2 * lineWidth;
        this.ctx.strokeStyle = backColor;
        this.ctx.moveTo(x + width, labelY);
        this.ctx.lineTo(labelX, labelY);
        this.ctx.stroke();

        // draw follow up line
        if (labelY > y + height) {
          this.ctx.moveTo(x + width, labelY);
          this.ctx.lineTo(x + width / 2, y + height);
          this.ctx.stroke();
        }

        rightCounter++;
      }

      this.ctx.closePath();
      this.ctx.restore();

      // draw background for label
      this.ctx.save();
      this.ctx.beginPath();
      this.ctx.fillStyle = backColor;
      // this.ctx.globalAlpha = 0.75;
      this.ctx.fillRect(labelX, labelY - 0.5 * paddedTextHeight, paddedTextWidth, paddedTextHeight);
      this.ctx.closePath();
      this.ctx.restore();

      // draw the label text
      this.ctx.save();
      this.ctx.beginPath();
      this.ctx.fillStyle = boxColor !== backColor ? '#fff' : '#000';
      this.ctx.fillText(text, labelX + textPaddingX, labelY + textPaddingY);
      this.ctx.closePath();
      this.ctx.restore();
    }
  }
}
