import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AIModelInputSourceFormItem } from './AIModelInputSourceFormItem';

export function AIModelInputSourceForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, remove, prepend } = useFieldArray({ control, name: 'inputSources' });

  return (
    <Box mb="15px">
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="body2" fontWeight="500">
          {t(T['ai.model.form.text.input.source'])}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => prepend({ inputType: 'VIDEO', bitrate: 1300, codec: 'RAW' })}
          startIcon={<AddOutlined />}
        >
          {t(T['ai.model.form.text.input.source.add'])}
        </Button>
      </Box>

      <Divider sx={{ mt: '5px', mb: '10px' }} />

      {fields.map((itemField, fieldIndex) => (
        <AIModelInputSourceFormItem key={itemField.id} fieldIndex={fieldIndex} remove={remove} />
      ))}
    </Box>
  );
}
