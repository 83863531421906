import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

/**
 * @typedef {object} DevicePoolDetailsContextParams
 * @property {string} poolId
 * @property {DevicePoolInfoForTenant} devicePool
 */

/** @type {import('react').Context<DevicePoolDetailsContextParams>} */
export const DevicePoolDetailsContext = createContext(null);

export function DevicePoolLayout() {
  const params = useParams();
  const navigate = useNavigate();

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const { setBreadcrumbTitle } = useContext(MainContext);

  const [initialTenantId] = useState(tenantId);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [devicePool, setDevicePool] = useState(null);

  const poolId = useMemo(() => '' + params.id, [params]);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  useEffect(() => {
    setError(null);
    setLoading(true);
    const request = api.ac.v5['device-pool'].$poolId(poolId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then(setDevicePool)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [poolId, secretToken]);

  useEffect(() => {
    if (!devicePool) return;
    setBreadcrumbTitle(devicePool.name, (path) => path.endsWith(poolId));
  }, [setBreadcrumbTitle, poolId, devicePool]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error || !devicePool) {
    throw new KnownUserError('Failed to get Device Pool details');
  }

  return (
    <DevicePoolDetailsContext.Provider value={{ poolId, devicePool }}>
      <Outlet />
    </DevicePoolDetailsContext.Provider>
  );
}
