import { isCameraInParkingMode } from '@/utils/cameras';
import { Box } from '@mui/material';
import { useMemo } from 'react';

/** @param {{item: EndpointInfoAggregated, hideOffline?: boolean } & import('@mui/material').BoxProps} props */
export function CameraStatusTag(props) {
  const { item, sx, hideOffline, ...boxProps } = props;

  const isParkingMode = useMemo(
    () => isCameraInParkingMode(item?.parkingStatus),
    [item?.parkingStatus]
  );

  if (!isParkingMode && !item.deviceOnlineStatus && hideOffline) {
    return null;
  }

  return (
    <Box
      p="1px 5px"
      fontWeight="600"
      borderRadius="2px"
      fontSize="0.625rem"
      position="absolute"
      top="6px"
      left="8px"
      color="#fff"
      bgcolor={item.deviceOnlineStatus ? (isParkingMode ? '#CBA305' : '#E32929') : '#8491A3'}
      {...boxProps}
      sx={{
        textShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
        ...(item.deviceOnlineStatus && !isParkingMode
          ? {
              'animation': 'pulse-animation 2s infinite',
              '@keyframes pulse-animation': {
                '0%': {
                  boxShadow: '0 0 0 0px rgba(0, 0, 0, 0.2)',
                },
                '100%': {
                  boxShadow: `0 0 0 10px rgba(0, 0, 0, 0)`,
                },
              },
            }
          : null),
        ...sx,
      }}
    >
      {isParkingMode ? 'PARKED' : item.deviceOnlineStatus ? 'LIVE' : 'OFFLINE'}
    </Box>
  );
}
