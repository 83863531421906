import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} CameraUserPermissionModalProps
 * @property {boolean} [open]
 * @property {UserRole} [user]
 * @property {() => any} onClose
 * @property {() => any} onSuccess
 */

/** @param {CameraUserPermissionModalProps} props */
export function CameraUserPermissionModal(props) {
  const { open, user, onClose, onSuccess } = props;

  const { cameraId } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);

  const [role, setRole] = useState(user.role);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleChangeRole = async () => {
    try {
      const request = api.ac.endpoint.acl.change.userrole.$put({
        params: {
          endpointId: cameraId,
          secretToken,
          toUserId: user.userId,
          role: role,
        },
      });
      await request.process();
      onSuccess();
    } catch (error) {
      toastWarning('Failed to change user permission');
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Change User Permission</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container sx={{ mb: 1 }}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
              User Permission
            </FormLabel>
            <Select size="small" value={role} onChange={handleChange}>
              <MenuItem value={'ADMIN'}>Admin</MenuItem>
              <MenuItem value={'VIEWER'}>Viewer</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleChangeRole}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
