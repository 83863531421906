import { COACH_NOTE_DELETE_ICON, USER_FALLBACK_ICON } from '@/assets/constants/images';
import { formatDateTimeWeek } from '@/utils/datetime';
import { BoxImage } from '@/web/@components/BoxImage';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useState } from 'react';

/**
 * @typedef {object} UserNoteBoxProps
 * @property {string} avatar
 * @property {string} username
 * @property {string} note
 * @property {any} time
 * @property {boolean} enableAction
 * @property {boolean} loading
 * @property {() => any} onDelete
 */

/** @param {UserNoteBoxProps} props */
export function UserNoteBox(props) {
  const { avatar, username, note, time, enableAction = false, onDelete, loading } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [arrowRef, setArrowRef] = useState(null);

  const handleDeleteButtonClick = () => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleAgreeDelete = () => {
    onDelete();
    setOpen(false);
  };

  return (
    <>
      <Popper
        sx={{ zIndex: 1200, background: '10px solid red' }}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal={true}
        transition
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper sx={{ maxWidth: 400, overflow: 'auto' }}>
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  marginTop: '-0.4em',
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '0 0.5em 0.5em 0.5em',
                  borderColor: `transparent transparent white transparent`,
                }}
                ref={setArrowRef}
              />
              <DialogTitle>{'Confirmation'}</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure you want to delete this note?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button size="small" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button variant="contained" size="small" onClick={handleAgreeDelete} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Box
        p={2}
        borderRadius={2}
        border="1px solid #C5D9F0"
        sx={{ pointerEvents: loading ? 'none' : 'auto' }}
        display="flex"
        alignItems="center"
        gap={1.5}
        position="relative"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => !open && setShowActions(false)}
      >
        <Box>
          <BoxImage
            size="48px"
            height="100%"
            borderRadius="50%"
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
            src={avatar}
            fallbackSrc={USER_FALLBACK_ICON}
          />
        </Box>
        <Box flex={1}>
          <Typography variant="body1" fontWeight={500}>
            {username}
          </Typography>
          <Typography variant="subtitle2">{note?.replace(/"/g, '')}</Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            position="absolute"
            top={'5px'}
            right={'15px'}
            fontSize={'0.80rem'}
            fontWeight={500}
          >
            {formatDateTimeWeek(time)}
          </Typography>

          <Box display="flex" pt="25px" gap={0.7}>
            {showActions && enableAction && (
              <>
                <IconButton
                  onClick={handleDeleteButtonClick()}
                  color="error"
                  sx={{
                    p: '5px',
                    borderRadius: '50%',
                  }}
                >
                  <BoxImage src={COACH_NOTE_DELETE_ICON} size="17px" sx={{ fill: 'green' }} />
                </IconButton>
                {/* <IconButton
                  //onClick={() => remove(fieldIndex)}
                  color="error"
                  sx={{
                    p: '5px',
                    borderRadius: '50%',
                  }}
                >
                  <BoxImage src={TRIGGER_EDIT_ICON} size="17px" />
                </IconButton> */}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
