import { Avatar, Badge, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

/**
 * @typedef {object} AvatarWithPresenceProps
 * @property {string} src
 * @property {string} [alt]
 * @property {boolean} [isActive]
 */

/** @param {AvatarWithPresenceProps } props */
export default function AvatarWithPresence(props) {
  const { src, alt, isActive } = props;
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        '& .MuiBadge-badge': isActive
          ? { backgroundColor: '#44b700', color: '#44b700' }
          : { backgroundColor: '#bdbdbd', color: '#bdbdbd' },
      }}
    >
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar alt={alt || ''} src={src} />
      </StyledBadge>
    </Stack>
  );
}
