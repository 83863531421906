import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccessEntitySelection } from './@components/EntitySelection';
import { FEATURES_FLAG_TABLE_COLUMNS } from './columns';

export function FeatureAccessView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { t } = useTranslation();
  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));

  const form = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const {
    formState: { dirtyFields },
    setValue,
    reset,
  } = form;

  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(tenantId);

  const fetchFeatureFlags = useCallback(
    async (signal) => {
      if (!selectedTenant || !secretToken) return;
      setError(null);
      try {
        const request = api.ac.v5.tenant.access.features.$tenantId(selectedTenant + '').$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
        });
        const data = await request?.process();

        // @ts-ignore
        data?.features?.forEach((item) => {
          // @ts-ignore
          setValue(`platformFeature-${item.platformFeature}`, item.platformFeature);
          // @ts-ignore
          setValue(`enabled-${item.platformFeature}`, item?.enabled);
          // @ts-ignore
          setValue(`childTenantInclusive-${item.platformFeature}`, item?.childTenantInclusive);
        });

        // @ts-ignore
        await setFeatures(data?.features);
        // @ts-ignore
        if (data?.features?.length) {
          setLoading(false);
        }
      } catch (ex) {
        setError(null);
        setLoading(false);
      }
    },
    [secretToken, selectedTenant, setValue]
  );

  const formatFormData = (data) => {
    const groupedResult = {};
    Object.keys(data).forEach((key) => {
      const [beforeDash, afterDash] = key.split('-');
      if (!groupedResult[afterDash]) {
        groupedResult[afterDash] = {};
      }
      groupedResult[afterDash][beforeDash] = data[key];
    });
    return groupedResult;
  };

  const getSubmittedUniqueKeys = (data) => {
    const uniqueKeys = new Set(Object.keys(data).map((key) => key.split('-')[1]));
    return Array.from(uniqueKeys);
  };

  const prepareAndSubmit = async (data) => {
    setFormSubmitting(true);
    try {
      const dirtyFeatureKeys = getSubmittedUniqueKeys(dirtyFields);
      const formattedData = formatFormData(data);
      const filteredData = Object.fromEntries(
        Object.entries(formattedData).filter(([key]) => dirtyFeatureKeys.includes(key))
      );

      const requests = Object.keys(filteredData).map(async (key) => {
        const body = {
          ...filteredData[key],
          platformFeature: key,
        };

        // Perform the API request for each key
        return api.ac.v5.tenant.access.features
          .$tenantId(selectedTenant)
          .$post({
            headers: {
              Authorization: secretToken,
            },
            data: body,
          })
          .process();
      });

      // Await all requests
      await Promise.all(requests);
      reset(undefined, { keepValues: true });
      toastSuccess('Feature access updated successfully.');
    } catch (error) {
      console.error('Error during submission:', error);
      toastWarning('Failed to update feature access.');
    } finally {
      setFormSubmitting(false); // Always reset the form submitting state
    }
  };

  const handleReset = () => {
    reset();
  };

  useEffect(() => {
    if (!secretToken) return;
    setLoading(true);
    const aborter = new AbortController();
    fetchFeatureFlags(aborter.signal);
    return () => aborter.abort();
  }, [secretToken, fetchFeatureFlags]);

  useEffect(() => {
    setSelectedTenant(tenantId);
    reset();
  }, [tenantId, reset]);

  useEffect(() => {
    reset();
  }, [selectedTenant, reset]);

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch features');
  }

  return (
    <Box mx={2.5} mt={2}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
          <Box width={{ xs: '100%', md: '600px' }}>
            <Typography color="#596A82" fontWeight={500} pb={1}>
              Select Tenant
            </Typography>
            <AccessEntitySelection onSelectTenant={setSelectedTenant} />
          </Box>

          {selectedTenant ? (
            <PaginatedTableContextProvider>
              <ContinuationTokenTable
                results={features}
                loading={loading}
                dataSortKey={'platformFeature'}
                fullView={true}
                hideHeaderBorder={true}
                height={mdAndUp ? (features?.length > 25 ? '80vh' : '100%') : '100%'}
                // @ts-ignore
                columns={FEATURES_FLAG_TABLE_COLUMNS}
              />
            </PaginatedTableContextProvider>
          ) : (
            <CenterBox fullView>
              <IconMessageBox
                size="256px"
                src={NO_SEARCH_RESULT_IMG}
                message={t(T['no.data.found'])}
              />
            </CenterBox>
          )}

          {!loading && Object.keys(dirtyFields)?.length > 0 && (
            <Box display="flex" justifyContent={'flex-end'} gap="5px" py={mdAndUp ? 5 : 0}>
              <Button type="button" variant="text" onClick={() => handleReset()} size="small">
                <Typography fontSize={'0.875rem'}>Cancel</Typography>
              </Button>
              <Button
                type="submit"
                size="small"
                variant="contained"
                sx={{ gap: '5px' }}
                disabled={formSubmitting || Object.keys(dirtyFields)?.length === 0}
              >
                {formSubmitting && <CircularProgress color="inherit" size={16} />}
                <Typography fontSize={'0.875rem'}>Save</Typography>
              </Button>
            </Box>
          )}
        </form>
      </FormProvider>
    </Box>
  );
}
