import { TextField } from '@mui/material';

/**
 * @typedef {object} TimeSelectionProps
 * @property {string} time
 * @property {function} setTime
 * @property {boolean} [isDisabled]
 */

/**
 * @param {TimeSelectionProps} props
 */

export function GroupSettingsTimeSelection({ time, setTime, isDisabled = false }) {
  return (
    <TextField
      disabled={isDisabled}
      fullWidth
      onChange={(e) => {
        setTime(e.target.value);
      }}
      className="form-control"
      type="time"
      size="medium"
      value={time}
    />
  );
}
