export const CLOCK_TICK_INTERVAL = 990;

export const PAUSE_TIMEOUT = 1000;
export const REPLY_TIMEOUT = 60 * 1000;
export const STOP_TIMEOUT = 15 * 60 * 1000;
export const STREAMING_PREVIEW_TIMEOUT = 60 * 1000;
export const KEEPALIVE_AFTER_MAX_DURATION = 30 * 1000;

export const TICK_WIDTH = 8;
export const EVENT_TICK_DURATION = 100;
export const RECORDING_TICK_DURATION = 60 * 1000;
export const RECORDING_CACHE_DURATION = 60 * 1000;
export const RECORDING_ERROR_TIMEOUT = 4 * 60 * 1000;
export const UPCOMING_FETCH_INTERVAL = 20 * 60 * 1000;

// time in milliseconds for the minimum selectable time
export const MIN_RECORDING_SELECT_TIME = 90 * 24 * 3600 * 1000;

// frequency of stream report
export const STREAM_REPORT_INTERVAL = 5 * 1000;

// sensor and bounding box data timestamp is usually behind the current time
// this milliseconds considers how much back we should look for a data point
export const LIVE_METADATA_MAX_DELAY = 10 * 1000;

// time in milliseconds of how much data to be displayed on the sensor data
export const SENSOR_DATA_BUFFER_SIZE = 30 * 1000;

export const MAX_BOUNDING_BOX_WIDTH = 3840; //4k
export const MAX_BOUNDING_BOX_HEIGHT = 2160; //4k

export const VIDEO_PLAYING_SPEED = [
  {
    value: 4,
    label: '4x',
  },
  {
    value: 2,
    label: '2x',
  },
  {
    value: 1,
    label: '1x',
  },
  {
    value: 0.5,
    label: '0.5x',
  },
  {
    value: 0.1,
    label: '0.1x',
  },
  {
    value: 0,
    label: 'Step',
  },
];

/** @type {{[key: string]: RTCIceCandidateType}} */
export const RTC_CANDIDATE_TYPES = {
  host: 'host',
  srflx: 'srflx',
  prflx: 'prflx',
  relay: 'relay',
};

export const SENSOR_REPORT_TABS = [
  {
    id: 'widgets',
    title: 'Widgets',
    src: '/images/player/cirlce-graph-icon.svg',
  },
  {
    id: 'lines',
    title: 'Line Chart',
    src: '/images/player/info-graph-icon.svg',
  },
];
