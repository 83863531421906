import { Box, Button, Grid, Typography } from '@mui/material';
import { TRIGGER_SENSITIVITY_MEDIA_TYPES } from '../../assets';
import { BoxImage } from '@/web/@components/BoxImage';
import { AI_CONTAINER_CAMERA_VIDEO_ICON, TRIGGER_SNAPSHOT_ICON } from '@/assets/constants/images';

export function MediaSettingsForm(props) {
  const { mediaTypeValues, setMediaTypeValues, inheritance } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle2">Media</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box display="flex" gap="10px" height="48px">
          {TRIGGER_SENSITIVITY_MEDIA_TYPES?.map((item, index) => (
            <Button
              key={index + item?.value}
              variant={mediaTypeValues?.includes(item?.value) ? 'contained' : 'outlined'}
              sx={{
                width: '50%',
                borderRadius: '10px',
                backgroundColor: !mediaTypeValues?.includes(item?.value)
                  ? '#EFF4FA'
                  : '-moz-initial',
              }}
              onClick={() => {
                if (mediaTypeValues.includes(item?.value)) {
                  setMediaTypeValues((prevValues) => prevValues.filter((i) => i !== item?.value));
                } else {
                  setMediaTypeValues((prevValues) => [...prevValues, item?.value]);
                }
              }}
            >
              <Box display={'inline-flex'} gap={'5px'} fontSize="0.80rem" alignItems="center">
                <BoxImage
                  src={
                    item?.value === 'VIDEO' ? AI_CONTAINER_CAMERA_VIDEO_ICON : TRIGGER_SNAPSHOT_ICON
                  }
                  size="16px"
                  sx={{
                    filter: mediaTypeValues?.includes(item?.value)
                      ? 'brightness(0) invert(1)'
                      : 'white',
                  }}
                />
                {item?.label}
              </Box>
            </Button>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        {inheritance('mediaType')}
      </Grid>
    </Grid>
  );
}
