import { isArray } from 'lodash';
import { trimString } from './formatting';

export class KnownUserError extends Error {
  name = 'KnownUserError';
}

export class CustomLoggerError extends Error {
  name = 'CustomLoggerError';
}

/**
 * @param {any} err
 * @param {string} [fallback]
 */
export function readErrorMessage(err, fallback = 'Something went wrong!') {
  const process = () => {
    if (err.response) {
      if (err.response.data) {
        if (err.response.data.message) {
          return err.response.data.message;
        }
        if (err.response.data.error) {
          if (isArray(err.response.data.error)) {
            return err.response.data.error.map(String).join('\n');
          }
          return err.response.data.error;
        }
        return err.response.data;
      }
    }
    if (err.message) {
      return err.message;
    }
    return err || '';
  };
  return trimString(process()) || fallback;
}
