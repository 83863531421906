import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {object} InitialState
 * @property {boolean} sidebarVisible
 * @property {boolean} fullWidthLayout
 * @property {boolean} hideToolbar
 * @property {boolean} sidebarCollapsed
 * @property {any} hoveredItem
 * @property {boolean} tabVisible
 * @property {boolean} simplifyCurve
 * @property {boolean} toolbarMenuOpen
 */

/** @type {InitialState} */
const initialState = {
  hideToolbar: false,
  sidebarVisible: false,
  fullWidthLayout: false,
  hoveredItem: null,
  toolbarMenuOpen: false,
  sidebarCollapsed: false,
  simplifyCurve: false,
  tabVisible: document.visibilityState === 'visible',
};

const reducers = {
  /**
   * @type {SliceReducer<InitialState, boolean>}
   */
  setFullWidthLayout(state, action) {
    if (state.fullWidthLayout === action.payload) return;
    state.fullWidthLayout = action.payload;
  },

  /**
   * @type {SliceReducer<InitialState, boolean>}
   */
  setHideToolbar(state, action) {
    if (state.hideToolbar === action.payload) return;
    state.hideToolbar = action.payload;
  },

  /**
   * @type {SliceReducer<InitialState, boolean>}
   */
  setToolbarMenuOpen(state, action) {
    if (state.toolbarMenuOpen === action.payload) return;
    state.toolbarMenuOpen = action.payload;
  },

  /**
   * @type {SliceReducer<InitialState, void>}
   */
  toggleSidebarVisibility(state, action) {
    state.sidebarVisible = !state.sidebarVisible;
  },

  /**
   * @type {SliceReducer<InitialState, boolean>}
   */
  toggleSidebarMode(state, action) {
    if (state.sidebarCollapsed === action.payload) return;
    state.sidebarCollapsed = action.payload;
  },

  /**
   * @type {SliceReducer<InitialState, any>}
   */
  setHoveredItem(state, action) {
    if (state.hoveredItem === action.payload) return;
    state.hoveredItem = action.payload;
  },
  /**
   * @type {SliceReducer<InitialState, boolean>}
   */
  setTabVisible: (state, action) => {
    if (state.tabVisible === action.payload) return;
    state.tabVisible = action.payload;
  },
  /**
   * @type {SliceReducer<InitialState, boolean>}
   */
  setSimplifyCurve: (state, action) => {
    if (state.simplifyCurve === action.payload) return;
    state.simplifyCurve = action.payload;
  },
  /**
   * @type {SliceReducer<InitialState, void>}
   */
  toggleSimplifyCurve: (state) => {
    state.simplifyCurve = !state.simplifyCurve;
  },
};

export const PageViewSlice = createSlice({
  name: 'page-view',
  initialState,
  reducers,
});
