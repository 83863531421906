import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

/** @param {import("@mui/material").BoxProps} props */
export function EllipsisTextViewContainer(props) {
  /** @type {import('react').Ref<HTMLElement>} */
  const container = useRef();

  /** @type {StateVariable<import('@mui/material').SxProps>} */
  const [marqueeSX, setMarqueeSX] = useState({});

  useEffect(() => {
    const iid = setInterval(() => {
      const el = container.current?.querySelector('.marquee');
      if (!el) return;
      clearInterval(iid);
      if (el.clientWidth === el.scrollWidth) return;
      const delta = el.clientWidth - el.scrollWidth - 10;
      setMarqueeSX({
        'animation': 'scroll-left 5s ease-in infinite',
        '@keyframes scroll-left': {
          '0%': { transform: 'translateX(0)' },
          '70%, 90%': { transform: `translateX(${delta}px)` },
        },
      });
    }, 1000);
    return () => clearInterval(iid);
  }, []);

  return (
    <Box
      ref={container}
      {...props}
      sx={{
        'width': '100%',
        'display': 'table',
        'overflow': 'hidden',
        'tableLayout': 'fixed',
        '& > *': {
          'display': 'table-cell',
          'overflow': 'scroll',
          'whiteSpace': 'nowrap',
          'textOverflow': 'unset',
          'scrollbarWidth': 'none',
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },
        },
        ...props.sx,
        '&:hover > .marquee': {
          overflow: 'visible',
          textOverflow: 'unset',
          ...marqueeSX,
        },
      }}
    />
  );
}
