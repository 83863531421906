/** @type {import('../index').TRANSLATION} */
module.exports = {
  'ai.containers.create.button': 'إنشاء حاوية الذكاء الاصطناعي',
  'ai.containers.page.card.browse': 'تصفح حاوية الذكاء الاصطناعي',
  'ai.containers.page.card.create': 'إنشاء حاوية AI جديدة',
  'ai.containers.page.card.title': 'حاويات الذكاء الاصطناعي',
  'ai.containers.page.title': 'حاويات الذكاء الاصطناعي',
  'ai.containers.table.button.add': 'حاوية الذكاء الاصطناعي',
  'ai.containers.table.columns.name': 'اسم',
  'ai.containers.table.columns.publication.status': 'حالة',
  'ai.containers.table.columns.release.version': 'الإصدار',
  'ai.containers.table.columns.version': 'إصدار',
  'ai.containers.table.search.placeholder': 'ابحث في حاوية الذكاء الاصطناعي',
  'ai.models.create.button': 'إنشاء نموذج الذكاء الاصطناعي',
  'ai.models.name': 'نماذج الذكاء الاصطناعي',
  'ai.models.page.card.browseItems': 'تصفح نماذج الذكاء الاصطناعي',
  'ai.models.page.card.createNew': 'قم بإنشاء نموذج جديد من الذكاء الاصطناعي',
  'ai.models.page.card.name': 'نماذج الذكاء الاصطناعي',
  'ai.models.table.button.add': 'لديك نموذج',
  'ai.models.table.columns.name': 'اسم',
  'ai.models.table.columns.publication.status': 'حالة',
  'ai.models.table.columns.release.version': 'الإصدار',
  'ai.models.table.columns.vendor': 'بائع',
  'ai.models.table.search.placeholder': 'ابحث في نماذج الذكاء الاصطناعي',
  'annotations.fetching.data.message': 'جلب فئات التعليقات التوضيحية',
  'annotations.no.categories.message': 'لا فئات التعليقات التوضيحية.',
  'annotations.page.card.browseItems': 'تصفح التعليقات التوضيحية',
  'annotations.page.card.createNew': 'إنشاء التعليقات التوضيحية',
  'annotations.page.card.name': 'فئات التعليقات التوضيحية',
  'annotations.page.title': 'التعليقات التوضيحية',
  'annotations.search.placeholder': 'البحث عن التعليقات التوضيحية لتحرير وحذف',
  'button.agree': 'يوافق على',
  'button.cancel': 'يلغي',
  'button.details': 'تفاصيل',
  'button.disagree': 'تعارض',
  'button.discontinue': 'توقف',
  'button.edit': 'يحرر',
  'button.modify': 'تعديل',
  'button.release': 'إطلاق سراح',
  'button.retry': 'إعادة المحاولة',
  'button.save': 'يحفظ',
  'button.update': 'تحديث',
  'cameras.no.data.message': 'لم يتم العثور على كاميرات!',
  'cameras.page.title': 'كاميرات',
  'cameras.search.placeholder': 'ابحث باسم الكاميرا ومعرف الكاميرا والموقع و VIN',
  'change.password.title': 'غير كلمة السر',
  'confirmations.discontinue': 'هل أنت متأكد من أنك تريد توقف هذه العناصر؟',
  'confirmations.release': 'هل أنت متأكد من أنك تريد إصدار هذه العناصر؟',
  'data-residency.page.card.configurations': 'التكوينات',
  'data-residency.page.card.name': 'إقامة البيانات',
  'data-retention.page.card.configurations': 'التكوينات',
  'data-retention.page.card.name': 'الاحتفاظ بالبيانات',
  'device-batches.page.card.browse.items': 'تصفح دفعات الجهاز',
  'device-batches.page.card.create.new': 'إنشاء دفعة الجهاز',
  'device-batches.page.card.name': 'دفعات الجهاز',
  'device-group.columns.description': 'وصف',
  'device-group.columns.name': 'اسم',
  'device-group.columns.product': 'منتج',
  'device-group.create.button': 'مجموعة الأجهزة',
  'device-group.create.failed': 'فشل في تحديث مجموعة الأجهزة',
  'device-group.create.page.title': 'إنشاء مجموعة الكاميرا',
  'device-group.create.retry': 'لا يمكن إضافة مجموعة.من فضلك حاول مرة أخرى بعد بعض من الوقت',
  'device-group.create.success': 'تمت إضافة المجموعة {{groupName}} بنجاح.',
  'device-group.delete.failed': 'فشل في وقف {{groupName}}.',
  'device-group.delete.retry': 'لا يمكن إيقاف {{groupName}}.الرجاء معاودة المحاولة في وقت لاحق.',
  'device-group.delete.success': 'مجموعة الأجهزة {{groupName}} تم إيقافها بنجاح.',
  'device-group.edit.failed': 'فشل في تحديث مجموعة الأجهزة',
  'device-group.edit.success': 'تم تحديث المجموعة {{groupName}} بنجاح.',
  'device-group.edit.title': 'تحديث مجموعة الكاميرا',
  'device-group.form.description.placeholder': 'أدخل وصف المجموعة',
  'device-group.form.group.name.hint': 'الرجاء إدخال اسم المجموعة',
  'device-group.form.group.name.label': 'أسم المجموعة',
  'device-group.form.group.name.placeholder': 'أدخل اسم المجموعة',
  'device-group.form.select.product.hint': 'الرجاء اختيار منتج',
  'device-group.form.select.product.label': 'منتج',
  'device-group.form.select.product.placeholder': 'اختر المنتج',
  'device-group.placeholder.search': 'مجموعة أجهزة البحث',
  'device-group.retry': 'لا يمكن تحديث المجموعة.من فضلك حاول مرة أخرى بعد بعض من الوقت',
  'device-group.title': 'مجموعات الأجهزة',
  'device.groups.page.card.browseItems': 'تصفح مجموعات الأجهزة',
  'device.groups.page.card.createNew': 'إنشاء مجموعة الأجهزة',
  'device.groups.page.card.name': 'مجموعات الأجهزة',
  'device.pools.page.card.browseItems': 'تصفح تجمعات الأجهزة',
  'device.pools.page.card.createNew': 'إنشاء تجمع الأجهزة',
  'device.pools.page.card.name': 'تجمعات الأجهزة',
  'events.no.data.message': 'لم يتم العثور على أحداث!',
  'events.page.title': 'الأحداث',
  'events.search.placeholder': 'يبحث',
  'firmwares.page.card.browse.items': 'تصفح الشركات',
  'firmwares.page.card.name': 'firmwares',
  'firmwares.page.card.upload': 'تحميل البرامج الثابتة',
  'geofences.page.card.browseItems': 'تصفح الجيش',
  'geofences.page.card.createNew': 'خلق الجيولوجي',
  'geofences.page.card.name': 'الجيولوجية',
  'login.alternate.text': 'أو يمكنك مع',
  'login.banner': 'Precision AI للبيانات والقرارات الموثوقة',
  'login.button.google': 'جوجل',
  'login.button.microsoft': 'مايكروسوفت',
  'login.button': 'تسجيل الدخول',
  'login.forget.password': 'هل نسيت كلمة السر؟',
  'login.label.email.label': 'البريد الإلكتروني',
  'login.label.password.label': 'كلمة المرور',
  'login.label.remember.me': 'تذكرنى',
  'login.title': 'تسجيل الدخول إلى حسابك',
  'navigation.ai.container': 'حاويات الذكاء الاصطناعي',
  'navigation.ai.models': 'نماذج الذكاء الاصطناعي',
  'navigation.ai': 'منظمة العفو الدولية',
  'navigation.annotations': 'التعليقات التوضيحية',
  'navigation.cameras': 'كاميرات',
  'navigation.configurations': 'التكوينات',
  'navigation.data.residency': 'إقامة البيانات',
  'navigation.data.retention': 'الاحتفاظ بالبيانات',
  'navigation.device.batches': 'دفعات الجهاز',
  'navigation.device.groups': 'مجموعات الأجهزة',
  'navigation.device.pools': 'تجمعات الأجهزة',
  'navigation.events': 'الأحداث',
  'navigation.firmwares': 'firmwares',
  'navigation.gallery': 'صالة عرض',
  'navigation.operations.geofences': 'الجيولوجية',
  'navigation.operations': 'عمليات',
  'navigation.administration': 'تزامن',
  'navigation.privacy.and.compliance': 'الخصوصية والامتثال',
  'navigation.privacy.policies': 'سياسات الخصوصية',
  'navigation.products': 'منتجات',
  'navigation.reports': 'تقارير',
  'navigation.smarter.ai': 'أذكى الذكاء الاصطناعي',
  'navigation.tenants': 'المستأجرين',
  'navigation.triggers': 'محفزات',
  'navigation.users': 'المستخدمون',
  'navigation.vin.groups': 'مجموعات فين',
  'navigation.waste.management': 'إدارة المخلفات',
  'navigation.winter.operations': 'العمليات الشتوية',
  'no.data.found': 'لاتوجد بيانات!',
  'pages.administration.deviceGroups.form.description.name': 'وصف',
  'privacy-policies.page.card.biometric': 'بصمات',
  'privacy-policies.page.card.consent': 'موافقة',
  'privacy-policies.page.card.name': 'سياسات الخصوصية',
  'privacy-policies.page.card.tools': 'أدوات',
  'products.page.card.manage-accelerator': 'إدارة AI Accelerator',
  'products.page.card.manage.hardware.products': 'إدارة منتجات الأجهزة',
  'products.page.card.name': 'منتجات',
  'reset.password.title': 'إعادة تعيين كلمة المرور',
  'table.per.page': '{{item}} لكل صفحة',
  'tenants.label.select': 'حدد المستأجر',
  'tenants.page.card.browse.items': 'تصفح المستأجرين',
  'tenants.page.card.create.new': 'إنشاء المستأجر',
  'tenants.page.card.name': 'المستأجرين',
  'tenants.placeholder.search': 'البحث المستأجر',
  'tenants.title': 'المستأجرين',
  'triggers.create.button': 'إنشاء الزناد',
  'triggers.page.card.browseItems': 'تصفح المشغلات',
  'triggers.page.card.createNew': 'إنشاء الزناد',
  'triggers.page.card.manageLabels': 'إدارة التصنيفات',
  'triggers.page.card.name': 'محفزات',
  'triggers.page.title': 'محفزات',
  'triggers.table.button.add': 'اثار',
  'triggers.table.columns.name': 'اسم',
  'triggers.table.columns.publication.status': 'حالة',
  'triggers.table.columns.type': 'يكتب',
  'triggers.table.search.placeholder': 'مشغل البحث',
  'users.page.card.browseItems': 'تصفح المستخدمين',
  'users.page.card.createNew': 'إنشاء المستخدم',
  'users.page.card.name': 'المستخدمون',
  'vin.groups.page.card.browseItems': 'تصفح مجموعات فين',
  'vin.groups.page.card.createNew': 'إنشاء مجموعة فين',
  'vin.groups.page.card.name': 'مجموعات فين',
  'waste-management.configurations.page.card.customer': 'عملاء',
  'waste-management.configurations.page.card.name': 'التكوينات',
  'waste-management.configurations.page.card.softwareConf': 'تكوين البرنامج',
  'waste-management.operations.page.card.name': 'عمليات',
  'waste-management.reports.page.card.browseItems': 'تصفح التقارير',
  'waste-management.reports.page.card.createNew': 'إنشاء تقرير',
  'waste-management.reports.page.card.name': 'تقارير',
  'winter-operations.configurations.page.card.customer': 'عملاء',
  'winter-operations.configurations.page.card.name': 'التكوينات',
  'winter-operations.configurations.page.card.softwareConf': 'تكوين البرنامج',
  'winter-operations.gallery.page.card.name': 'صالة عرض',
  'winter-operations.reports.page.card.browseItems': 'تصفح التقارير',
  'winter-operations.reports.page.card.createNew': 'إنشاء تقرير',
  'winter-operations.reports.page.card.name': 'تقارير',
};
