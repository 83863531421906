import { BOX_DISCONTINUE, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<DeviceGroupInfo>>} */
export const ACTION_ITEMS = [
  {
    type: 'DISCONTINUE',
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    confirmationMessage: T['device-group.confirmations.delete'],
  },
  {
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    link: (selectedItem) => `/administration/device-groups/${selectedItem.id}/edit`,
  },
  {
    component: 'SEARCH',
    label: 'Search',
    placeholder: T['device-group.placeholder.search'],
    options: [
      { value: 'groupName', label: 'Name' },
      { value: 'groupDescription', label: 'Description' },
    ],
  },
  {
    label: T['device-group.create.button'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/device-groups/create',
  },
];
