import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * @typedef {object} ApnDetailsData
 * @property {String} apnInfoId
 * @property {() => any} refreshDetails
 * @property {APNInfo} apnInfo
 */

/** @type {import('react').Context<ApnDetailsData>} */
export const ApnDetailsContext = createContext(null);

export function ApnDetailsLayout() {
  const { apnInfoId } = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [initialTenantId] = useState(tenantId);
  /** @type {StateVariable<Error>} */
  const [error, setError] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<APNInfo>} */
  const [apnInfo, setApnInfo] = useState(null);
  /** @type {StateVariable<number>} */
  const [refresh, setRefresh] = useState(0);

  const refreshDetails = useCallback(() => {
    setRefresh((v) => v + 1);
  }, []);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  useEffect(() => {
    if (!apnInfoId) return;
    setError(null);
    setLoading(true);
    const request = api.ac.v5.endpoint.apn.$apnId(apnInfoId).$get({
      headers: {
        Authorization: secretToken,
        apnId: Number(apnInfoId),
      },
    });
    request
      .process()
      // @ts-ignore TODO: fix type
      .then(setApnInfo)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [apnInfoId, secretToken, tenantId, refresh]);

  useEffect(() => {
    setBreadcrumbTitle(setApnInfo?.name || apnInfoId || 'Loading...', (path) =>
      path.endsWith(apnInfoId)
    );
  }, [apnInfoId, setApnInfo?.name, setBreadcrumbTitle]);

  if (loading || !setApnInfo) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error) {
    return (
      <CenterBox>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          size="256px"
          message="Could not get APN details"
        />
      </CenterBox>
    );
  }

  return (
    <ApnDetailsContext.Provider
      value={{
        apnInfoId,
        apnInfo,
        refreshDetails,
      }}
    >
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </ApnDetailsContext.Provider>
  );
}
