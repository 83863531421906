import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ACTION_ITEMS } from './actions';

export function SchemeListView() {
  const { t } = useTranslation();
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const navigate = useNavigate();

  return (
    <Box mx={2.5} mt={2} mb={8}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={t(T['scheme.title'])} actions={ACTION_ITEMS} />
        <OffsetPagination
          key={secretToken}
          fetcher={async ({ signal, limit, offset, searchText }) => {
            const request = api.ac.v5.scheme.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                tenantId,
                limit,
                offset,
                ...(searchText !== '' && { name: searchText }),
              },
            });
            await request.process();
            return {
              // @ts-ignore
              data: request.result.list,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<SchemeInfo>} */ items) => (
            <Box
              sx={{
                height: 'calc(100vh - 220px)',
              }}
            >
              <Box
                sx={{
                  height: 'calc(100vh - 220px)',
                  overflow: 'auto',
                  background: '#fff',
                }}
              >
                {items
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item, i) => (
                    <Paper
                      key={i}
                      elevation={0}
                      sx={{
                        p: 1,
                        my: 1,
                        height: {
                          xs: 'fit-content',
                          md: '48px',
                        },
                        border: '1px solid #E0ECFC',
                        borderRadius: '6px',
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{
                          flexDirection: {
                            xs: 'column',
                            md: 'row',
                          },
                        }}
                        alignItems="center"
                        px={2}
                      >
                        <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '500px' }}>
                          {item.name}
                        </Typography>
                        <Typography display="flex" gap="10px">
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ color: '#4177BF', borderColor: '#C5D9F0' }}
                            onClick={() => {
                              navigate(`/administration/schemes/${item.id}`);
                            }}
                          >
                            View Scheme
                          </Button>

                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ color: '#4177BF', borderColor: '#C5D9F0' }}
                            onClick={() => {
                              navigate(`/administration/schemes/${item.id}/schemes-applied`);
                            }}
                          >
                            Applied On
                          </Button>
                        </Typography>
                      </Box>
                    </Paper>
                  ))}
              </Box>
            </Box>
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
