import { Box, Button, Grid, Typography } from '@mui/material';
import { TRIGGER_SENSITIVITY_CHIME_OPTIONS } from '../../assets';
import { BoxImage } from '@/web/@components/BoxImage';
import { TRIGGER_CHIME_ICON } from '@/assets/constants/images';

export function ChimeSettingsForm(props) {
  const { chimeValue, setChimeValue, inheritance } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle2">Chime</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box display="flex" gap="10px" height="48px">
          {TRIGGER_SENSITIVITY_CHIME_OPTIONS?.map((item, index) => (
            <Button
              key={index + item?.value}
              variant={chimeValue === item?.value ? 'contained' : 'outlined'}
              sx={{
                width: '50%',
                borderRadius: '10px',
                backgroundColor: item?.value !== chimeValue ? '#EFF4FA' : '-moz-initial',
              }}
              onClick={() => {
                setChimeValue(chimeValue === item?.value ? 'NONE' : item?.value);
              }}
            >
              <Box display={'inline-flex'} gap={'5px'} fontSize="0.80rem" alignItems="center">
                <BoxImage
                  src={TRIGGER_CHIME_ICON}
                  size="16px"
                  sx={{
                    filter: chimeValue === item?.value ? 'brightness(0) invert(1)' : 'white',
                  }}
                />
                {item?.label}
              </Box>
            </Button>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        {inheritance('chime')}
      </Grid>
    </Grid>
  );
}
