import api from '@/api';
import { STATUS_LIST } from '@/assets/constants/table';
import { T } from '@/assets/locales';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/ai/ai-container/actions';
import { AI_CONTAINER_TABLE_COLUMNS } from '@/web/administration/ai/ai-container/columns';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export function AiContainerListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<AiContainerResponse>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    if (type === 'DISCONTINUE') {
      await deleteAIContainers(selectedItems);
    }
    if (type === 'RELEASE') {
      await releaseAIContainer(selectedItems[0]);
    }
    fetchAIContainers();
  };

  const deleteAIContainers = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteAIContainer));
  };

  /** @param {AiContainerResponse} aiContainer  */
  const deleteAIContainer = async (aiContainer) => {
    try {
      const request = api.ac.v2.repository.aicontainer.discontinue.$post({
        params: {
          secretToken,
          tenantId,
          containerId: aiContainer.id,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully discontinued ${aiContainer.name}`);
    } catch (e) {
      toastWarning('Error', `Failed to discontinue ${aiContainer.name}`);
    }
  };

  /** @param {AiContainerResponse} aiContainer  */
  const releaseAIContainer = async (aiContainer) => {
    try {
      const request = api.ac.v2.repository.aicontainer.release.$post({
        params: {
          secretToken,
          tenantId,
          containerId: aiContainer.id,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully Released ${aiContainer.name}`);
    } catch (e) {
      toastWarning('Error', `Failed to release ${aiContainer.name}`);
    }
  };

  const {
    result = [],
    loading,
    error,
    clear: clearResult,
    retry: fetchAIContainers,
  } = useFutureLoader(async ({ tenantId, secretToken }) => {
    let conToken = '';
    const limit = 100;
    /** @type {Array<AiContainerResponse>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.aicontainer.$get({
        headers: {
          Authorization: secretToken,
        },
        params: {
          size: limit,
          continuationToken: conToken,
          tenantId,
        },
      });
      await request.process();
      const result = request.result.aiContainers;
      conToken = request.result.continuationToken;
      results.push(
        ...result.filter((x) =>
          [STATUS_LIST.RELEASED, STATUS_LIST.DRAFT].includes(x.publicationStatus)
        )
      );
      if (result.length < limit) break;
    }
    return results.sort((a, b) => {
      return (
        -a.publicationStatus.localeCompare(b.publicationStatus) ||
        a.name.localeCompare(b.name) ||
        -a.versionNumber.localeCompare(b.versionNumber)
      );
    });
  }, []);

  useEffect(() => {
    clearResult();
  }, [tenantId, clearResult]);

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch AI Containers');
  }

  return (
    <Box mx={2.5} mt={2} mb={5}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={T['ai.containers.page.title']}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <ContinuationTokenTable
          results={result || []}
          columns={AI_CONTAINER_TABLE_COLUMNS}
          loading={loading}
          onReload={() => fetchAIContainers()}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
