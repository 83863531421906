import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

export const SHADOW_DISTANCE = 25;

/** @param {import('@mui/material').BoxProps & {ContainerProps?: import('@mui/material').BoxProps}} props */
export function ScrollShadowBox(props) {
  const { ContainerProps, children, ...parentProps } = props;

  /** @type {import('react').Ref<HTMLDivElement>} */
  const containerRef = useRef();

  /** @type {import('react').Ref<HTMLDivElement>} */
  const bottomRef = useRef();

  const handleScroll = useMemo(
    () =>
      debounce(() => {
        const parent = containerRef.current;
        if (!parent) return;
        if (parent?.scrollTop < parent?.scrollHeight - parent?.clientHeight - SHADOW_DISTANCE) {
          bottomRef.current.style.display = 'block';
        } else {
          bottomRef.current.style.display = 'none';
        }
      }, 100),
    []
  );

  useEffect(() => {
    const id = setInterval(handleScroll, 500);
    return () => clearInterval(id);
  }, [handleScroll]);

  return (
    <Box position="relative" {...parentProps}>
      <Box
        ref={containerRef}
        height="100%"
        overflow="hidden auto"
        {...ContainerProps}
        children={children}
        onScroll={handleScroll}
        sx={{
          '&:not(:hover)': {
            paddingRight: '8px',
          },
          '&:not(:hover)::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      />
      <Box ref={bottomRef} className="scrollable-bottom-shadow" />
    </Box>
  );
}
