import api from '@/api';
import { store } from '@/store';
import { selectMainAccessToken, selectMainTenantId } from '@/store/auth';
import { SmartCache } from '../caching/smart-cache';

const triggerCategoryCache = new SmartCache(`trigger-categories`, 3600 * 1000);

/** @type {{[key: string]: string}} */
export const TRIGGER_NAMES = {};

/**
 * @param {AbortSignal} [signal]
 * @returns {Promise<Array<TriggerCategoryDetails>>}
 */
export async function fetchTriggerCategories(signal) {
  const state = store.getState();
  const tenantId = selectMainTenantId(state);
  const secretToken = selectMainAccessToken(state);

  /** @type {TriggerCategoryDetails[]} */
  const cached = await triggerCategoryCache.getItem(tenantId);
  if (cached) return cached;

  const request = api.ac.v5.trigger.category.all.$get({
    signal,
    headers: {
      Authorization: secretToken,
    },
  });
  const results = await request.process();
  triggerCategoryCache.setItem(tenantId, results);
  // @ts-ignore TODO: fix swagger response
  return results;
}

/**
 * @param {Array<TriggerCategoryDetails>} categories
 */
export function buildTriggerMapping(categories) {
  for (const { id, name } of categories) {
    TRIGGER_NAMES[id] = name;
  }
}
