import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { DevicePoolDetailsContext } from '../DevicePoolDetailsLayout';
import { POOL_FIRMWARE_TABLE_COLUMNS } from './columns';

export function PoolFirmwareListView() {
  const { searchText } = useContext(FilterContext);
  const { poolId } = useContext(DevicePoolDetailsContext);
  const { reloadStatus, setTableReload, setSelectedItems } = useContext(PaginatedTableContext);

  const {
    result: firmwareList = [],
    loading,
    error,
    retry,
  } = useFutureLoader(
    async ({ signal, secretToken }) => {
      /** @type {Array<FirmwareV2>} */
      let result = [];
      const limit = 25;
      let offset = 0;
      while (!signal.aborted) {
        const request = api.ac.v2.endpoint.devicepools.$poolId(poolId).firmwares.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
          },
        });
        await request.process();
        result = [...result, ...request.result.deployments.map((item) => item.firmware)];
        offset += limit;
        if (request.result.deployments.length < limit) break;
      }
      return result;
    },
    [poolId]
  );

  useEffect(() => {
    if (!reloadStatus) return;
    setTableReload(false);
    retry();
  }, [reloadStatus, setTableReload, retry]);

  const filteredDevices = useMemo(() => {
    const q = trimString(searchText).toLowerCase();
    if (!q) return firmwareList;
    return (firmwareList || []).filter(
      (item) =>
        item.name?.toLowerCase()?.startsWith(q) ||
        item.version?.toLowerCase()?.startsWith(q) ||
        item.type?.toLowerCase()?.startsWith(q)
    );
  }, [searchText, firmwareList]);

  useEffect(() => {
    setSelectedItems([]);
  }, [filteredDevices, setSelectedItems]);

  if (loading) {
    return (
      <CenterBox minHeight="calc(100vh - 300px)">
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error || !filteredDevices?.length) {
    return (
      <CenterBox minHeight="calc(100vh - 300px)">
        <IconMessageBox
          size="256px"
          src="/images/empty/no-search-results.svg"
          message={'No Device Available'}
        />
      </CenterBox>
    );
  }

  return <ItemsResponsiveView results={filteredDevices} columns={POOL_FIRMWARE_TABLE_COLUMNS} />;
}
