import { TRIGGER_EDIT_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function ApkEditButton(props) {
  const { isVisible, firmwareId } = props;

  const navigate = useNavigate();

  const handleEditButtonClick = () => {
    navigate(`/administration/apk-files/${firmwareId}/edit`);
  };

  return (
    <Button size="small" onClick={handleEditButtonClick}>
      <Box
        sx={{ display: isVisible ? 'grid' : 'none' }}
        display="flex"
        flexDirection="column"
        justifyItems="center"
        gap={0.4}
        fontSize={'0.875rem'}
        alignItems="center"
      >
        <BoxImage src={TRIGGER_EDIT_ICON} size="14px" />
        <Typography fontSize={'0.75rem'}>Edit</Typography>
      </Box>
    </Button>
  );
}
