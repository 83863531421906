import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { uploadFileToAzureBlob } from '@/utils/uploader/azureBlobUploader';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { Aborter } from '@azure/storage-file';
import { Box, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DriverForm } from '../@components/DriverForm';

export function DriverCreatePage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [progressText, setProgressText] = useState('');

  const onProgress = (percentage) => {
    const progress = parseInt(percentage) * 100;
    setPercentage(progress);
  };

  const fileAborter = useMemo(() => Aborter.none, []);

  const deleteDirectories = async (fileCredentials) => {
    fileCredentials?.forEach(async (item) => {
      //await deleteContainer(item?.container, item?.sas);
    });
  };

  /** @type {import('../@components/DriverForm').DriverFormProps['onSubmit']} */
  const createDriver = async (fields) => {
    let fileCredentials;
    let success = false;

    try {
      setLoading(true);
      setProgressModalOpen(true);

      const driverId = uuidv4();

      /** @type {DriverAddRequest} */
      const formBody = {};
      formBody.firstName = fields.firstName;
      formBody.lastName = fields.lastName;
      formBody.email = fields.email;
      formBody.phone = fields.phone;
      formBody.licenseNo = fields.licenseNo;
      formBody.tenantId = fields.tenantId;
      formBody.tags = fields.tags?.map((item) => item.id);
      formBody.licenseIssuanceDate = new Date(
        startOfTheDay(fields.licenseIssuanceDate)
      ).toISOString();
      formBody.licenseExpirationDate = new Date(
        endOfTheDay(fields.licenseExpirationDate)
      ).toISOString();

      const uploadedFiles = [];
      const { driverPhotoFile, driverLicenseFrontFile, driverLicenseBackFile } = fields;
      if (driverPhotoFile) uploadedFiles.push('driverPhotoFile');
      if (driverLicenseFrontFile) uploadedFiles.push('driverLicenseFrontFile');
      if (driverLicenseBackFile) uploadedFiles.push('driverLicenseBackFile');

      if (uploadedFiles.length > 0) {
        setProgressText('Generating SAS token.');
        const request = api.ac.v5.driver['file-credential'].$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            count: uploadedFiles.length,
            tenantId,
          },
        });
        await request.process();
        fileCredentials = request.result.list;

        if (fileCredentials?.length === 0) {
          toastWarning('Could not upload Driver files [Error: Failed to obtain File Credentials]');
          return;
        }

        const promises = uploadedFiles?.map(async (item, index) => {
          if (item === 'driverPhotoFile') {
            setProgressText('Uploading Driver Photo.');
            const actualFileName = fields.driverPhotoFile.name;
            const storedFileName = `${driverId}-${actualFileName}`.replace(/[^\w\d]+/g, '-');
            await uploadFileToAzureBlob(
              driverPhotoFile,
              storedFileName,
              fileCredentials[index]?.url,
              onProgress
            );
            formBody.pictureContainer = fileCredentials[index]?.container;
            formBody.picturePath = fileCredentials[index]?.path;
            if (fileAborter.aborted) return;
          } else if (item === 'driverLicenseFrontFile') {
            setProgressText('Uploading Driver License Front Image.');
            const actualFileName = fields.driverLicenseFrontFile.name;
            const storedFileName = `${driverId}-${actualFileName}`.replace(/[^\w\d]+/g, '-');
            await uploadFileToAzureBlob(
              driverLicenseFrontFile,
              storedFileName,
              fileCredentials[index]?.url,
              onProgress
            );
            formBody.licenseImageFrontContainer = fileCredentials[index]?.container;
            formBody.licenseImageFrontPath = fileCredentials[index]?.path;
            if (fileAborter.aborted) return;
          } else if (item === 'driverLicenseBackFile') {
            setProgressText('Uploading Driver License Back Image.');
            const actualFileName = fields.driverLicenseBackFile.name;
            const storedFileName = `${driverId}-${actualFileName}`.replace(/[^\w\d]+/g, '-');
            await uploadFileToAzureBlob(
              driverLicenseBackFile,
              storedFileName,
              fileCredentials[index]?.url,
              onProgress
            );
            formBody.licenseImageBackContainer = fileCredentials[index]?.container;
            formBody.licenseImageBackPath = fileCredentials[index]?.path;
            if (fileAborter.aborted) return;
          }
        });

        await Promise.all(promises);
      }

      const driverRequest = api.ac.v5.driver.$post({
        data: formBody,
        headers: {
          Authorization: secretToken,
        },
      });
      await driverRequest.process();
      // @ts-ignore
      //TODO:: fix apy type
      if (driverRequest.result.status !== 'OK') {
        await deleteDirectories(fileCredentials);
        setLoading(false);
        return;
      }
      toastSuccess('Success', 'Driver Added successfully');
      success = true;
    } catch (err) {
      await deleteDirectories(fileCredentials);
      toastWarning('Error', 'Failed to create new Driver');
    } finally {
      setLoading(false);
      setProgressText('');
      setPercentage(0);
      setProgressModalOpen(false);
      if (success) {
        navigate('/fleets/drivers');
      }
    }
  };

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Create New Driver"
          progressText={progressText}
          onClose={() => setProgressModalOpen(false)}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['driver.create.page.title'])}
      </Typography>
      <DriverForm onSubmit={createDriver} disabled={loading} />
    </Box>
  );
}
