export const DEFAULT_GROUP_SETTINGS = {
  shouldTakeStreamingSettingsFromModel: false,
  parkingDelay: 1,
  recordingBitrateMin: 100,
  recordingFrameRateMax: 15,
  wifiSSID: 'Wifi 1',
  streamingVideoCodec: 'H264',
  downloadRetryPolicy: 'ASAP',
  networkProfilingFrequency: 60,
  postBufferEvent: 2,
  parkingMode: true,
  streamingFrameRateValue: 15,
  gpsSamplingRateTrigger: 2,
  onUserTriggerPolicy: 'INSTANTLY',
  allocatedLogSpace: 2048,
  tripTriggersBy: 'PARKING',
  cameraApp: 'warning',
  gpsSamplingRateVision: 25,
  periodicCheckPolicy: 'INTERVAL',
  recordingIFrameInterval: 9,
  limitUploadsOverLTE: false,
  continuousRecordingUnitLength: 10,
  aicInterval: 60,
  continuousRecording: false,
  app_release_timeout: 0,
  recordingResolution: 'WH_1280x720',
  access: 'warning',
  eventRecording: true,
  pairingAfterOnboard: true,
  usbMode: 'CHARGING',
  usbAdb: 'ON',
  pairingOnConnectivityLost: true,
  logLevel: 'error',
  stream: 'warning',
  installStartPolicy: 'ASAP',
  connect: 'warning',
  streamingResolution: 'WH_1280x720',
  metadataThreshold: 0,
  canBusProfilingFrequency: 120,
  variableFps: false,
  continuousRecordingAtEdge: true,
  profileNetworkOnActiveAdapterChange: true,
  aiEdge: true,
  streamingIFrameInterval: 9,
  downloadStartPolicy: 'ASAP',
  cpuProfilingFrequency: 120,
  ttsEnabled: true,
  maxRetryOnInstallationFailure: 3,
  periodicCheckInterval: 1, //1*60
  recordingFrameRateMin: 1,
  onDeploymentPolicy: 'DISABLED',
  streamingBitrateMin: 1000,
  carName: 'obd2',
  memoryProfilingFrequency: 120,
  isAbtOnResolutionEnabled: false,
  manualRecording: true,
  pluginLibs: 'warning',
  usbFileTransfer: true,
  wakeUpRecordingLength: 10,
  maxRetryOnDownloadFailure: 5,
  recordingVideoCodec: 'H264',
  deviceVolume: 100,
  recordingBitrateValue: 1300,
  chimeEnabled: true,
  wakeUpThroughAccelerometer: 0.5,
  maxPackageSizeForLTEDownload: 1024,
  streamingBitrateMax: 1300,
  aiCloud: false,
  blurredLabels: '',
  streamingFrameRateMin: 1,
  maximumStreamingDuration: 30,
  maxConcurrentStreamingLimit: 3,
  sensorProfilingFrequency: 30,
  usbHostMode: 'OFF',
  diskProfilingFrequency: 2,
  streamingBitrateValue: 1300,
  vision: 'warning',
  update_interval: 60,
  onboardType: 'manual',
  networkToDownload: 'ANY',
  streamingFrameRateMax: 15,
  isAbtOnFramerateEnabled: false,
  updateOnGroupChange: false,
  wifiPassword: 123345,
  maximumStorageUsageLimit: 80,
  preBufferEvent: 5,
  ttsVoiceOption: 'tts1',
  isAbtOnBitrateEnabled: false,
  streamingFrameRate: 'fixed',
  shouldTakeRecordingSettingsFromModel: false,
  connectivityTimeout: 1,
  recordingFrameRate: 'fixed',
  ota_reboot_timeout: 0,
  updateOnFirmwareDeployment: false,
  recordingBitrate: 'fixed',
  gpuProfilingFrequency: 120,
  preBufferManual: 5,
  streamingBitrate: 'fixed',
  installationRetryPolicy: 'ASAP',
  recordingFrameRateValue: 15,
  streamingQualityProfile: false,
  recordingBitrateMax: 100000,
  endToEndEncryption: false,
  postBufferManual: 2,
  gpsSamplingRateRecording: 1,
  downloadRetrySchedule: null,
  downloadStartSchedule: null,
  periodicCheckTime: null,
  installationStartSchedule: null,
  installationRetrySchedule: null,
  isBlurEnabled: false,
  accTri: 20,
  accLog: 0,
  accRec: 100,
  accStr: 1,
  accVis: 20,
  accIPC: 100,
  accTypeVis: 'BOTH',
  accTypeRec: 'LINEAR',
  accTypeTri: 'LINEAR',
  accTypeLog: 'LINEAR',
  accTypeIPC: 'LINEAR',
  accTypeStr: 'LINEAR',
  gyroTri: 20,
  gyroLog: 0,
  gyroRec: 100,
  gyroStr: 1,
  gyroVis: 20,
  gyroIPC: 1,
  gpsTri: 1,
  gpsLog: 1,
  gpsRec: 1,
  gpsStr: 1,
  gpsVis: 1,
  gpsIPC: 1,
  loggedObd2: 'Signal.OBD.Speed',
  enableModelValidation: false,
  parkingAtBatteryVolt12VSystem: 11.5,
  parkingAtBatteryVolt24VSystem: 23.2,
  parkingBatteryVoltSensitivity: 3000,
  capture_voltage_hz: 0,
  powerOffAtBatteryVolt12VSystem: 8.1,
  powerOffAtBatteryVolt24VSystem: 18.6,
  enableADR: false,
  parkingOnVehicleMovement: true,
  parkingDelayOnNoMovement: 5,
  parkingSensitivityOnNoMovement: 0.5,
  gpsSensitivityOnNoMovement: 5,
  exitParkingOnVehicleImpact: true,
  exitParkingWithWakeUpSignal: true,
  // parkingModeOnLowBattery: true,
  // parkingDelayOnLowBattery: 1,
  accOverrideRecordingPipeWithTriggerPipe: false,
  accOverrideVisionPipeWithTriggerPipe: false,
  gyroOverrideRecordingPipeWithTriggerPipe: false,
  gyroOverrideVisionPipeWithTriggerPipe: false,
  gpsOverrideRecordingPipeWithTriggerPipe: false,
  gpsOverrideVisionPipeWithTriggerPipe: false,
  sdCardFormatOption: 'NEVER',
  periodicSnapshotFreq: 60000, // 1min
  uploadPeriodicSnapshotAutomatically: true,
  periodicAwakeningFromParkingModeFreq: 86400000, //24 hours
  //digital io
  enableCoverDetect: true,
  enableGPIO0: false,
  enableGPIO0ChangeDetectOnFallingEdge: false,
  enableGPIO1: false,
  enableGPIO1ChangeDetectOnFallingEdge: false,
  enableGPIO2: false,
  enableGPIO2ChangeDetectOnFallingEdge: false,
  enableGPIO3: false,
  enableGPIO3ChangeDetectOnFallingEdge: false,
  setGPIO3: false,
  audioBitRate: 16,
  audioCodec: 'OPUS',
  enableAudioPipeline: false,
  imagerState: '[]',
  videoPipeline: '[]',
  audioPipeline: '[]',
  wifiNetworks: '[]',
  firmwareBlacklistingEnabled: true,
  inboxPollingInterval: 300, //sec, 5 min
  maxDownloadDuration: 6, //hour, 6 hours
  dlProgressReportingInterval: 5, //sec, 5 sec
  isMandatoryDriverLogin: true,
  loginPromptFrequency: 30000, //milli
  loginPromptMaxDuration: 0,
  trafficFeatureEnabled: true,
  mapDownloadSize: 10000, //meter
  highways: '["motorway", "primary", "secondary"]',
  speedOffsetUnit: 'FIXED',
  speedOffset: 0,
  isFaceLoginEnabled: false,
  faceLoginTimeout: 180000,
  enableEventMediaUploadtoCloud: true,
  canDataUploadEnabled: true,
  canDataTransmissionFrequency: 20,
  sdCardEncryptionOption: 'NONE',
  enableDataConsumptionReporting: true,
  reportingThreshold: 100, //MB
  lowBandwidthThreshold: 25, //25GB
  blackoutThreshold: 50, //50GB
  billingCycleDate: 1,
  periodReportCycle: 1,
  resetPeriodicCountingAt: null,
  maximumConcurrentRecordingRetrieval: 3,
  PostProcessingFrameRate: 15,
  EnablePostProcessing: false,
  deepSleepMode: false,
  deepSleepWakeningInterval: 720, //12 hours in mins
  supportingAppID: '',
  enableRebootOnInstallFreeze: true,
  canDataSamplingRate: 1,
  driverSide: 'LEFT',
  triggersPluginsTestMode: false,
  uploadEventMagnitudeAIData: true,
  uploadEventMagnitudeSensorCANData: true,
  adbLogUploadInterval: 12,
  adbLogMaxSize: 100,
  adbLogSeverity: 'debug',
};
