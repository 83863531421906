import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { DevicePoolDeviceActions } from '../@components/DeviceListActions';
import { DevicePoolDeviceList } from '../@components/DeviceListView';

export function DevicePoolDevices() {
  return (
    <Box m={2.5}>
      <PaginatedTableContextProvider>
        <DevicePoolDeviceActions />
        <DevicePoolDeviceList />
      </PaginatedTableContextProvider>
    </Box>
  );
}
