import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { ConfirmationDialog } from '@/web/@components/ConfirmationDialog';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { AddOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { format, subHours } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function CreateLogRequestModal() {
  const { camera, refreshCamera, isOnline } = useContext(CameraDetailsContext);

  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<boolean>} */
  const [open, setOpen] = useState(false);
  /** @type {StateVariable<LogRequestCreateModel['logType']>} */
  /** @type {StateVariable<string>} */
  const [startTime, setStartTime] = useState('');
  /** @type {StateVariable<string>} */
  const [endTime, setEndTime] = useState('');

  const onLogRequestButtonClick = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const handleNewLogRequest = async () => {
    if (!isOnline) {
      toastWarning('Device is not online');
      return;
    }
    try {
      const fromTime = new Date(startTime).getTime();
      const toTime = new Date(endTime).getTime();
      const request = api.ac.v3.report.device
        .$deviceId(camera.deviceSerialNumber)
        ['log-request'].$post({
          params: {
            secretToken,
          },
          data: {
            fromTime,
            toTime,
            logType: 'ADB',
          },
        });
      await request.process();
      refreshCamera();
      setOpen(false);
    } catch (ex) {
      toastWarning('Failed to create log request');
    }
  };

  useEffect(() => {
    const startTime = format(subHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm");
    const endTime = format(new Date(), "yyyy-MM-dd'T'HH:mm");
    setStartTime(startTime);
    setEndTime(endTime);
  }, []);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={onLogRequestButtonClick}
        startIcon={<AddOutlined />}
      >
        Log Request
      </Button>

      <ConfirmationDialog
        open={open}
        title="Log Request"
        message={'Are you sure you want to request log from this camera?'}
        onCancel={onCancel}
        onApply={handleNewLogRequest}
      />
    </>
  );
}
