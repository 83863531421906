import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AccessCardForm } from './@components/AccessCardForm';
import { AccessCardTransferModal } from './@components/AccessCardTransferModal';
import { ACTION_ITEMS } from './actions';
import { ACCESS_CARD_TABLE_COLUMNS } from './columns';

export function DriversAccessCardListView() {
  const { id: driverId } = useParams();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [openModalType, setOpenModalType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [refreshCount, setRefreshCount] = useState(0);

  /** @param {NfcCardModel} nfcCard */
  const deleteAccessCard = async (nfcCard) => {
    try {
      const request = api.ac.v5['nfc-card'].$nfcId(nfcCard.id).$delete({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully deleted ${nfcCard.id}`);
    } catch (e) {
      toastWarning('Error', `Failed to delete ${nfcCard.id}`);
    }
  };

  /** @param {Array<NfcCardModel>} selectedItems */
  const deleteAccessCards = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteAccessCard));
  };

  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteAccessCards(selectedItems);
      setTableReload(true);
      setRefreshCount((value) => value + 1);
    } else if (type === 'TRANSFER') {
      setOpenModalType(type);
      setSelectedItem(selectedItems[0]);
    } else if (type === 'CREATE') {
      setOpenModalType(type);
      setModalTitle('Create New Access Card');
    }
  };

  const handleModalClose = () => {
    setOpenModalType(null);
    setSelectedItem(null);
    setModalTitle('');
  };

  const handleFormSubmit = async (data) => {
    try {
      const request = api.ac.v5['nfc-card'].$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          driverIds: [Number(driverId)],
          nfcIds: [data.id],
          tenantId,
        },
      });
      await request.process();
      toastSuccess('Success', 'Access Card added successfully');
      handleModalClose();
      setRefreshCount((value) => value + 1);
    } catch (error) {
      toastWarning('Success', 'Failed to create Access Card');
    }
  };

  const handleAccessCardUserChange = async (data) => {
    try {
      const request = api.ac.v5['nfc-card'].$nfcId(selectedItem.id).user.$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          userId: data.driverId,
        },
      });
      await request.process();
      toastSuccess('Success', 'Access Card transferred successfully');
      handleModalClose();
      setRefreshCount((value) => value + 1);
    } catch (error) {
      toastWarning('Success', 'Failed to transfer Access Card');
    }
  };

  return (
    <>
      {openModalType === 'CREATE' && (
        <AccessCardForm
          title={modalTitle}
          onCancel={handleModalClose}
          onSubmit={handleFormSubmit}
        />
      )}
      {openModalType === 'TRANSFER' && (
        <AccessCardTransferModal
          onCancel={handleModalClose}
          onSubmit={handleAccessCardUserChange}
        />
      )}
      <Box mx={2.5} mt={2} mb={5}>
        <PaginatedTableContextProvider>
          <ItemsListHeader
            title={'Access Cards'}
            actions={ACTION_ITEMS}
            onAction={onActionHandle}
          />
          <InfiniteScrollView
            fullView={false}
            key={generateUniqueKey([tenantId, refreshCount])}
            initialToken={null}
            itemsPerPage={20}
            fetcher={async ({ signal, limit, token: offset }) => {
              const request = api.ac.v5['nfc-card'].$get({
                signal,
                headers: {
                  Authorization: secretToken,
                },
                params: {
                  size: limit || 50,
                  page: offset || 0,
                  tenantId,
                  userId: driverId,
                },
              });
              await request.process();
              return {
                result: request.result.result.list,
                token: offset + 1,
              };
            }}
            renderEmpty={() => (
              <IconMessageBox
                size="150px"
                src="/images/player/no-data-icon.svg"
                message="No Access Card Found"
              />
            )}
            renderList={(state) => (
              <InfiniteScrollTableView
                state={state}
                columns={ACCESS_CARD_TABLE_COLUMNS}
                sx={{ height: '100%' }}
              />
            )}
          />
        </PaginatedTableContextProvider>
      </Box>
    </>
  );
}
