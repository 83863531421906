import { camelCase, startCase } from 'lodash';
/**
 * @typedef {object} CustomTenantInfo
 * @property {boolean} [dataResidencyEnabled]
 * @property {string} [tenantCode] Tenant Code
 * @property {string} [tenantId] Tenant ID
 * @property {string} [tenantName] Tenant Name
 * @property {string} [tenantRegion] Tenant Region
 * @property {string} [tenantStatus] Tenant Code
 * @property {string} [type] action type
 */

/** @type {Array<TableColumn<CustomTenantInfo>>} */
export const TENANT_TABLE_COLUMNS = [
  {
    id: 'tenantName',
    label: 'Name',
    align: 'left',
    width: '40%',
    isPrimary: true,
    searchable: true,
    link: (row) => {
      const actionType = row?.type;
      if (actionType === 'FIRMWARE') {
        return `/smarter-ai/firmwares/tenant-deployment/${row.tenantId}/firmwares/${row.tenantName}`;
      } else if (actionType === 'TRIGGER') {
        return `/smarter-ai/triggers/tenant-deployment/${row.tenantId}/triggers/${row.tenantName}`;
      } else if (actionType === 'AI_CONTAINER') {
        return `/administration/ai/ai-containers/tenant-deployment/${row.tenantId}/ai-containers/${row.tenantName}`;
      } else if (actionType === 'SCHEME') {
        return `/smarter-ai/scheme-management/tenant-deployment/${row.tenantId}/schemes/${row.tenantName}`;
      } else {
        return '';
      }
    },
  },
  {
    id: 'tenantCode',
    label: 'Code',
    align: 'left',
    width: '30%',
    searchable: true,
    format: (value) => value,
  },
  {
    id: 'tenantStatus',
    label: 'Status',
    align: 'left',
    width: '20%',
    searchable: true,
    format: (value) => startCase(camelCase(value)),
  },
];
