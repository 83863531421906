import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Fetch list of all camera groups
 */
export function useCameraGroupList() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      let offset = 0;
      const limit = 200;
      /** @type {Array<GroupV2>} */
      const results = [];
      while (!signal.aborted) {
        const request = api.ac.endpoint.group.list.$get({
          signal,
          params: {
            limit,
            offset,
            tenantId,
            secretToken,
            groupResourceType: 'DEVICE',
          },
        });
        const result = await request.process();
        if (!result) break;
        results.push(...result.groups);
        offset += limit;
        if (result.groups.length < limit) break;
      }
      return results;
    },
    [],
    { cache: true }
  );
}
