import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { APK_TABLE_COLUMNS } from '../../columns';

export function APKFileListView() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  return (
    <Box mx={2}>
      <PaginatedTableContextProvider>
        <Typography variant="body2" fontSize="1rem" fontWeight={600} mb={2}>
          APK Files
        </Typography>
        <OffsetPagination
          key={secretToken}
          itemsPerPage={25}
          fetcher={async ({ signal, limit, offset }) => {
            const request = api.ac.v5.firmware.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit,
                offset,
                firmwareTypeId: 7,
                tenantId,
                ownerTenantId: tenantId,
              },
            });
            await request.process();
            return {
              data: request.result.firmwares,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<FirmwareDto>} */ results) => (
            <ItemsResponsiveView
              results={results}
              sx={{ height: { md: '68vh', xl: '80vh' } }}
              columns={APK_TABLE_COLUMNS}
              defaultSortKey="name"
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
