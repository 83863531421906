import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

/**
 * @typedef {object} TextFilterContentProps
 * @property {import('.').FilterMenuItem} item
 * @property {string} [initialValue]
 * @property {() => any} onCancel
 * @property {(value: string) => any} onSubmit
 */

/** @param {TextFilterContentProps} props */
export const TextFilterContent = (props) => {
  const { item, initialValue, onCancel, onSubmit } = props;

  const [text, setText] = useState(initialValue || '');

  useEffect(() => {
    setText(initialValue || '');
  }, [initialValue]);

  return (
    <>
      <Box
        sx={{
          minWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
        p={2}
      >
        <Typography fontWeight="bold" variant="h3" fontSize="0.85rem">
          {item.title}
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={text}
          placeholder={`Enter ${item.title}`}
          onChange={(e) => setText(e.currentTarget.value)}
          sx={{ mt: '8px' }}
        />
      </Box>

      <Divider />

      <Box p={1} display="flex" justifyContent="flex-end" gap="10px">
        <Button onClick={() => onCancel()} size="small">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(text)} variant="contained" size="small">
          Apply
        </Button>
      </Box>
    </>
  );
};
