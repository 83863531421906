import { store } from '@/store';
import { refreshToken } from '@/store/auth/actions/refresh-token';
import axios, { AxiosError } from 'axios';
import { isFunction } from 'lodash';
import qs from 'qs';
import { ApiRequestProgress } from './progress';
import api from '..';

/**
 * @typedef LoadingState
 * @type {'pending'|'loading'|'success'|'failure'|'cancelled'}
 */

/** @template T */
export class ApiRequest {
  /** @type {import('axios').AxiosRequestConfig} */
  static defaultOptions = {
    method: 'GET',
    params: {},
  };

  #aborter = new AbortController();
  #upload = new ApiRequestProgress();
  #download = new ApiRequestProgress();

  /** @type {LoadingState} */
  #status = 'pending';

  /** @type {import('axios').AxiosRequestConfig<T>} */
  #request = {};

  /** @type {import('axios').AxiosResponse<T>?} */
  #response;

  /** @type {Error | null} */
  #error;

  /** @type {Promise<T>} */
  #processor = null;

  /** @type {Array<((request: ApiRequest<T>) => any)>} */
  #callbacks = [];

  /**
   * @param {import('axios').AxiosRequestConfig<T>} config
   */
  constructor(config) {
    Object.assign(this.#request, ApiRequest.defaultOptions, config);
  }

  get status() {
    return this.#status;
  }

  get loading() {
    return this.#status === 'pending' || this.#status === 'loading';
  }

  get done() {
    return this.#status === 'failure' || this.#status === 'success';
  }

  get request() {
    return this.#request;
  }

  get response() {
    return this.#response || null;
  }

  get error() {
    return this.#error;
  }

  /** @type {T?} */
  get result() {
    return this.response?.data;
  }

  get upload() {
    return this.#upload;
  }

  get download() {
    return this.#download;
  }

  /** @param {any} [reason] */
  abort(reason) {
    this.#aborter?.abort(reason);
  }

  /** @param {(request: ApiRequest<T>) => any} callback */
  listen(callback) {
    if (!isFunction(callback)) return;
    this.#callbacks.push(callback);
    if (this.done) callback(this);
  }

  reset() {
    this.abort();
    this.#error = null;
    this.#response = null;
    this.#status = 'pending';
    this.#aborter = new AbortController();
    this.#upload = new ApiRequestProgress();
    this.#download = new ApiRequestProgress();
    this.#processor = null;
  }

  /**
   * Process the request and produces response
   * @param {number} [retry] A value between 0 and 10. [Default: 3]
   * @returns {Promise<T>}
   */
  process(retry = 2) {
    if (this.#processor) {
      return this.#processor;
    }

    const _notify = () => {
      for (const callback of this.#callbacks) {
        setTimeout(() => callback(this), 1);
      }
    };

    retry = Number(retry) || 0;
    retry = Math.max(Math.min(retry, 10), 0);

    this.#processor = (async () => {
      const startTime = Date.now();

      this.#status = 'loading';
      _notify();

      if (!this.#request.signal) {
        this.#request.signal = this.#aborter.signal;
      }

      this.#request.onUploadProgress = (event) => {
        //console.debug('upload', event.loaded, event.total);
        this.#upload = new ApiRequestProgress(event.loaded, event.total, startTime);
        _notify();
      };

      this.#request.onDownloadProgress = (event) => {
        //console.debug('download', event.loaded, event.total);
        this.#download = new ApiRequestProgress(event.loaded, event.total, startTime);
        _notify();
      };

      this.#request.paramsSerializer = (params) => {
        return qs.stringify(params, {
          skipNulls: true,
          arrayFormat: 'repeat',
        });
      };

      while (retry >= 0) {
        try {
          this.#response = await axios.request(this.#request);
          this.#status = 'success';
          return this.result;
        } catch (err) {
          this.#response = err?.response;
          if (
            err?.response?.status === '403' &&
            this.#request?.method?.toLocaleLowerCase() === 'get' &&
            this.#request?.url?.startsWith(api.ac?.$baseURL)
          ) {
            store.dispatch(refreshToken()).catch(console.error);
          }
          if (!err || err?.code === AxiosError.ERR_CANCELED) {
            console.debug('Request cancelled, ignoring', err);
            // ignore the cancelled requests
            this.#status = 'cancelled';
            return null;
          } else if (err.code === AxiosError.ERR_NETWORK) {
            console.debug('Network error', err);
            // retry on network failure
            this.#status = 'failure';
            this.#error = err;
            if (retry >= 0) {
              await new Promise((resolve) => setTimeout(resolve, 1000)); // wait 1s before retrying
              retry -= 1;
              continue;
            }
            throw this.#error; // retry limit exceeded
          } else {
            this.#status = 'failure';
            this.#error = err;
            // reportAxiosError(err, this.#request, this.#response); // TODO: to reduce consumption
            throw this.#error;
          }
        } finally {
          _notify();
        }
      }
    })();

    return this.#processor;
  }
}
