import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { STATUS_LIST } from '@/assets/constants/table';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * @typedef {object} SchemeContextData
 * @property {Number} schemeId
 * @property {GroupV2} scheme
 * @property {boolean} layoutLoading
 * @property {Array<FirmwareDto>} firmwares
 * @property {Array<DbcFileDto>} dbcFiles
 * @property {Array<CompositeTriggerDto>} triggers
 * @property {Array<AiContainerResponse>} aiContainers
 * @property {Array<FirmwareInfo>} deployedFirmwares
 * @property {Array<CompositeTriggerDto>} deployedTriggers
 * @property {AiContainerDto} deployedAiContainer
 * @property {Array<any>} groupSettings
 * @property {Array<DbcFileDto>} deployedDBCFiles
 */

/** @type {import('react').Context<SchemeContextData>} */
export const SchemeFormContext = createContext(null);
export function SchemeFormLayout() {
  const params = useParams();
  const schemeId = useMemo(() => Number(params.id), [params]);

  const { setBreadcrumbTitle } = useContext(MainContext);

  const navigate = useNavigate();
  const location = useLocation();
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const isAppliedPage = useMemo(() => location.pathname.includes('/schemes-applied'), [location]);

  /** @type {StateVariable<GroupV2>} */
  const [scheme, setScheme] = useState(null);
  /** @type {StateVariable<Array<DbcFileDto>>} */
  const [dbcFiles, setDBCFiles] = useState([]);
  /** @type {StateVariable<Array<FirmwareDto>>} */
  const [firmwares, setFirmwares] = useState([]);
  /** @type {StateVariable<Array<CompositeTriggerDto>>} */
  const [triggers, setTriggers] = useState([]);
  /** @type {StateVariable<Array<AiContainerResponse>>} */
  const [aiContainers, setAiContainers] = useState([]);

  // deployed
  const [groupSettings, setGroupSettings] = useState([]);
  /** @type {StateVariable<Array<FirmwareInfo>>} */
  const [deployedFirmwares, setDeployedFirmwares] = useState([]);
  /** @type {StateVariable<Array<CompositeTriggerDto>>} */
  const [deployedTriggers, setDeployedTriggers] = useState([]);
  /** @type {StateVariable<AiContainerDto>} */
  const [deployedAiContainer, setDeployedAiContainer] = useState(null);
  /** @type {StateVariable<Array<DbcFileSchemeAssignmentDto>>} */
  const [deployedDBCFiles, setDeployedDBCFiles] = useState([]);

  const [error] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialTenantId] = useState(tenantId);

  /*************************************
   *  GET scheme details
   **************************************/
  const getSchemeDetails = async (aborter) => {
    if (!schemeId) return;
    const request = api.ac.endpoint.group.$get({
      signal: aborter.signal,
      params: {
        groupId: Number(schemeId),
        secretToken,
      },
    });
    await request.process();
    const group = request.result?.group;
    setScheme(group);
    return group;
  };

  /*************************************
   *  Tenant Firmwares
   **************************************/
  const getTenantFirmwares = async (aborter) => {
    const limit = 100;
    let offset = 0;
    /** @type {Array<FirmwareDto>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.firmware.$get({
        signal: aborter.signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          limit: limit,
          offset: offset,
          tenantId: tenantId,
        },
      });
      await request.process();
      offset += limit;
      const result = request.result?.firmwares;
      results.push(...result);
      if (result.length < limit) break;
    }
    const mappedValue = results.map((item) => ({
      label: `${item.name} v${item.version}`,
      value: item.firmwareId,
      ...item,
    }));
    setFirmwares(mappedValue || []);
    return results;
  };

  /*************************************
   *  Deployed Firmwares
   **************************************/
  const getDeployedFirmwares = async (aborter) => {
    if (!schemeId) return;
    /** @type {Array<FirmwareInfo>} */
    const results = [];
    const request = api.ac.v5.firmware.scheme.$schemeId(schemeId).$get({
      signal: aborter.signal,
      headers: {
        Authorization: secretToken,
      },
    });
    const result = await request.process();
    setDeployedFirmwares(result?.list || []);
    return results;
  };

  /*************************************
   *  Tenant Triggers
   **************************************/
  const getTenantTriggers = async (aborter) => {
    let offset = 0;
    const limit = 100;
    /** @type {Array<CompositeTriggerDto>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.trigger.composite.tenant.$tenantId(tenantId).filter.$get({
        signal: aborter.signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          limit: limit,
          offset: offset,
        },
      });
      await request.process();
      const result = request.result.compositeTriggers;
      // TODO: RAZA told to do that
      // results.push(...result.filter((x) => x.publicationStatus === 'RELEASED'));
      results.push(...result);
      if (result.length < limit) break;
      offset += limit;
    }

    const mappedValue = results.map((item) => ({
      label: `${item.name} ${item?.isSystemTrigger ? '(Shadow)' : ''} ${
        item?.version ? ` - v${item.version}` : ''
      }`,
      value: item.id,
      ...item,
    }));
    setTriggers(mappedValue || []);
    return results;
  };

  /*************************************
   *  Deployed Triggers
   **************************************/
  const getDeployedTriggers = async (aborter) => {
    if (!schemeId) return;
    let offset = 0;
    const limit = 100;
    /** @type {Array<CompositeTriggerDto>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.trigger.composite.scheme.$schemeId(schemeId + '').filter.$get({
        signal: aborter.signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          limit: limit,
          offset: offset,
        },
      });
      await request.process();
      const result = request.result.compositeTriggers;
      results.push(...result);
      if (result.length < limit) break;
      offset += limit;
    }

    setDeployedTriggers(results || []);
    return results;
  };

  /*************************************
   *  Tenant AI Containers
   **************************************/
  const getTenantAiContainers = async (aborter) => {
    let conToken = '';
    const limit = 100;
    /** @type {Array<AiContainerResponse>} */
    const results = [];
    while (true) {
      const request = api.ac.v5.aicontainer.$get({
        signal: aborter.signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          size: limit,
          continuationToken: conToken,
          tenantId,
        },
      });
      await request.process();
      const result = request.result.aiContainers;
      conToken = request.result.continuationToken;
      results.push(
        ...result.filter((x) =>
          [STATUS_LIST.RELEASED, STATUS_LIST.DRAFT].includes(x.publicationStatus)
        )
      );
      if (result.length < limit) break;
    }
    const mappedValue = results.map((item) => ({
      label: item.name + (item.versionNumber ? ` v${item.versionNumber}` : ''),
      value: item.id,
      ...item,
    }));
    setAiContainers(mappedValue || []);
    return results;
  };

  /*************************************
   *  Deployed Ai Containers
   **************************************/
  const getDeployedAiContainer = async (aborter, scheme) => {
    if (!schemeId || !secretToken) return;
    const request = api.ac.v5.aicontainer.scheme.$schemeId(schemeId).$get({
      signal: aborter.signal,
      headers: {
        Authorization: secretToken,
      },
    });
    const results = await request.process();
    setDeployedAiContainer(results.aiContainers?.at(0));
    return results.aiContainers;
  };

  /*************************************
   *  Tenant DBC Files
   **************************************/
  const getTenantDBCFiles = async (aborter) => {
    const request = api.ac.v5.dbc.$get({
      signal: aborter.signal,
      headers: {
        Authorization: secretToken,
      },
    });

    await request.process();
    const results = request.result?.data;

    const mappedValue = results.map((item) => ({
      label: `${item?.name}`,
      value: item?.id,
      ...item,
    }));
    setDBCFiles(mappedValue || []);
    return results;
  };

  /*************************************
   *  Deployed DBC File
   **************************************/
  const getDeployedDBCFile = async (aborter) => {
    if (!schemeId || !secretToken) return;
    const request = api.ac.v5.dbc.scheme.$schemeId(schemeId).$get({
      signal: aborter.signal,
      headers: {
        Authorization: secretToken,
      },
    });
    const results = await request.process();
    setDeployedDBCFiles([results]);
    return results.id;
  };

  /*************************************
   *  Deployed Scheme Settings
   **************************************/
  const fetchGroupSettings = async (aborter) => {
    try {
      if (!schemeId || !secretToken || !tenantId) return;
      const request = api.ac.endpoint.group.settings.$get({
        signal: aborter.signal,
        params: {
          groupId: Number(schemeId),
          secretToken,
        },
      });
      const result = await request.process();
      const mapped = result?.groupSettings?.properties?.map((item) => ({
        [item.key]: item.value,
      }));
      const settings = Object.assign({}, ...(mapped || []));
      setGroupSettings(settings);
    } catch (ex) {
      console.error(ex);
    }
  };

  /*************************************
   *  Wrapping Up All Items
   **************************************/
  useEffect(() => {
    const aborter = new AbortController();

    const fetchData = async () => {
      try {
        const scheme = await getSchemeDetails(aborter);
        if (isAppliedPage) return;
        const promises = [];
        const schemeName = scheme?.name?.toLowerCase();
        if (schemeName?.startsWith('firmware')) {
          promises.push(getTenantFirmwares(aborter));
          promises.push(getDeployedFirmwares(aborter));
        } else if (schemeName?.startsWith('setting')) {
          promises.push(fetchGroupSettings(aborter));
        } else if (schemeName?.startsWith('trigger')) {
          promises.push(getTenantTriggers(aborter));
          promises.push(getDeployedTriggers(aborter));
        } else if (schemeName?.startsWith('aicontainer')) {
          promises.push(getTenantAiContainers(aborter));
          promises.push(getDeployedAiContainer(aborter));
        } else if (schemeName?.startsWith('dbc')) {
          promises.push(getTenantDBCFiles(aborter));
          promises.push(getDeployedDBCFile(aborter));
        } else {
          promises.push(getTenantFirmwares(aborter));
          promises.push(getDeployedFirmwares(aborter));
          promises.push(fetchGroupSettings(aborter));
          promises.push(getTenantTriggers(aborter));
          promises.push(getDeployedTriggers(aborter));
          promises.push(getTenantAiContainers(aborter));
          promises.push(getDeployedAiContainer(aborter));
          promises.push(getTenantDBCFiles(aborter));
          promises.push(getDeployedDBCFile(aborter));
        }
        await Promise.all(promises);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => aborter.abort();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  useEffect(() => {
    if (!schemeId) return;
    setBreadcrumbTitle(scheme?.name, (path) => path.endsWith(schemeId + ''));
  }, [scheme?.name, schemeId, setBreadcrumbTitle]);

  if (error) {
    return (
      <CenterBox>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          size="256px"
          message="Failed to load dependencies"
        />
      </CenterBox>
    );
  }

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <SchemeFormContext.Provider
      value={{
        schemeId: Number(schemeId),
        scheme,
        layoutLoading: loading,
        aiContainers,
        triggers,
        firmwares,
        groupSettings,
        dbcFiles,
        deployedFirmwares,
        deployedTriggers,
        deployedAiContainer,
        deployedDBCFiles,
      }}
    >
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </SchemeFormContext.Provider>
  );
}
