import { EventsPage } from '.';
import { EventPlayPage } from './play';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: 'play',
    breadcrumb: 'Event Playback',
    element: <EventPlayPage />,
  },
  {
    path: '',
    element: <EventsPage />,
  },
];

export default routes;
