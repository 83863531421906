import { Box, Button } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { convertValueByUnit } from '../../utils';
import { isUndefined } from 'lodash';

export function SensitivityLMHSelection(props) {
  const { triggerVariableOverride, overridesValues, variable } = props;

  const { setValue } = useFormContext();

  const sensitivityValue = useMemo(
    () =>
      !isUndefined(overridesValues?.at(0)?.unFormattedValue)
        ? overridesValues?.at(0)?.unFormattedValue
        : variable?.value,
    [overridesValues, variable?.value]
  );

  const sensitivityRange = useMemo(() => variable?.sensitivity, [variable?.sensitivity]);

  const currentState = useMemo(() => {
    const absSensitivityValue = Math.abs(sensitivityValue);
    if (
      //absSensitivityValue >= Math.abs(sensitivityRange?.high) &&
      absSensitivityValue < Math.abs(sensitivityRange?.medium)
    ) {
      return 'HIGH';
    } else if (
      absSensitivityValue >= Math.abs(sensitivityRange?.medium) &&
      absSensitivityValue < Math.abs(sensitivityRange?.low)
    ) {
      return 'MEDIUM';
    } else {
      return 'LOW';
    }
  }, [sensitivityValue, sensitivityRange]);

  const handleUpdate = (type) => {
    let updatedValue;
    if (type === 'HIGH') updatedValue = sensitivityRange?.high;
    else if (type === 'MEDIUM') updatedValue = sensitivityRange?.medium;
    else updatedValue = sensitivityRange?.low;
    setValue('sensitivity', updatedValue);
    const result = convertValueByUnit(updatedValue, variable?.unit, variable?.originalUnit);
    triggerVariableOverride(variable?.key, result, updatedValue);
  };

  if (!sensitivityRange?.high) return null;

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box display="flex" gap={0.5}>
        <Button
          size="small"
          variant={currentState === 'LOW' ? 'contained' : 'text'}
          sx={{
            borderRadius: '20px',
            height: '22px',
            width: '70px',
            backgroundColor: currentState === 'LOW' ? '#596A81' : '',
          }}
          onClick={() => handleUpdate('LOW')}
        >
          Low
        </Button>
        <Button
          size="small"
          variant={currentState === 'MEDIUM' ? 'contained' : 'text'}
          sx={{
            borderRadius: '20px',
            height: '22px',
            width: '80px',
            backgroundColor: currentState === 'MEDIUM' ? '#596A81' : '',
          }}
          onClick={() => handleUpdate('MEDIUM')}
        >
          Medium
        </Button>
        <Button
          size="small"
          variant={currentState === 'HIGH' ? 'contained' : 'text'}
          sx={{
            borderRadius: '20px',
            height: '22px',
            width: '70px',
            backgroundColor: currentState === 'HIGH' ? '#596A81' : '',
          }}
          onClick={() => handleUpdate('HIGH')}
        >
          High
        </Button>
      </Box>
    </Box>
  );
}
