/**
 * Format firmware name to human-readable
 * @param {string} [value]
 * @returns {string}
 */

export const formatFirmwareName = (value) => {
  switch (value) {
    case 'CAN_BUS_FW':
      return 'CAN Bus';
    case 'APPLICATION_PACKAGE':
      return 'Camera Application';
    case 'SYSTEM_IMAGE':
      return 'System Image';
    case 'OEM_IMAGE':
      return 'OEM Image';
    case 'SUPPORTING_APPLICATION':
      return 'Supporting Application';
    case 'LTE_MODEM_FW':
      return 'LTE Modem Firmware';
    case 'SMART_CABLE_FW':
      return 'Smart Cable MCU Firmware';
    case 'DEFAULT':
      return 'Default';
    default:
      return 'Default';
  }
};

export function compareVersions(version1, version2) {
  const parts1 = version1?.split('.')?.map(Number);
  const parts2 = version2?.split('.')?.map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;

    if (part1 > part2) {
      return 1;
    } else if (part1 < part2) {
      return -1;
    }
  }
  return 0;
}
