import { T } from '@/assets/locales';
import { store } from '@/store';
import { Auth, doLoginGeotab, selectRememberAccount } from '@/store/auth';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { toastWarning } from '@/utils/toaster';
import { CustomInput, CustomPasswordInput } from '@/web/@components/CustomForm';
import { LoginPageSwitch } from '@/web/@components/LoginPageSwitch';
import { Box, Button, CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BackButtonContainer } from '../@components/BackButtonContainer';

export function GeoTabLogin() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  /**
   * @typedef {object} LoginWithGeoTabData
   * @property {string} database
   * @property {string} username
   * @property {string} password
   * @property {string} geotabUrl
   */
  const form = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      username: '',
      password: '',
      database: '',
      geotabUrl: 'https://my.geotab.com',
    },
  });

  const rememberMe = useSelector(selectRememberAccount);

  const setRememberMe = (/** @type {boolean} */ value) => {
    store.dispatch(Auth.setRemember(value));
  };

  /** @param {{username: string, password: string, database: string, geotabUrl: string }} data */
  const handleGeotabLogin = (data) => {
    if (loading) return;

    const { username, password, database, geotabUrl } = data || {};

    setLoading(true);
    const startedAt = Date.now();

    store
      .dispatch(
        doLoginGeotab({ authenticationType: 'GEOTAB', username, password, database, geotabUrl })
      )
      .then(() => {
        const duration = Date.now() - startedAt;
        if (duration > 10000) {
          reportEvent(SentryEvents.SLOW_LOGIN, '', {
            authenticationType: 'GEOTAB',
            email: data.username,
            duration,
            tags: { email: data.username, duration },
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 429) {
          toastWarning('Too many failed attempts', 'Please try again in 1 hour.');
        } else {
          toastWarning('Login failed');
        }
        console.error(err);
        reportEvent(SentryEvents.LOGIN_FAILED, '', {
          err,
          email: data.username,
          authenticationType: 'GEOTAB',
          tags: { email: data.username },
        });
      })
      .finally(() => setLoading(false));
    store.dispatch(Auth.setAvatar());
  };

  const handleBackButton = () => {
    navigate('/');
  };

  return (
    <BackButtonContainer onClick={handleBackButton}>
      <Typography fontWeight={600} fontSize="1.25rem" mb={4}>
        Log in with Geotab
      </Typography>

      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={form.handleSubmit(handleGeotabLogin)}
          sx={{
            '& label': {
              color: '#5a6c83',
              lineHeight: '28px',
              fontWeight: 500,
              fontSize: '1rem',
              mb: 0,
            },
          }}
        >
          <CustomInput
            name="username"
            label={t(T['login.label.email.label'])}
            placeholder="Enter username or email"
            InputProps={{ autoComplete: 'email' }}
            rules={{
              required: 'Username / Email is required',
            }}
          />

          <CustomPasswordInput
            name="password"
            label={t(T['login.label.password.label'])}
            rules={{ required: 'Password is required' }}
          />

          <CustomInput
            name="database"
            label={t(T['login.geotab.label.database.label'])}
            placeholder="Enter a database name"
            InputProps={{ autoComplete: 'database' }}
            rules={{
              required: 'Database name is required',
            }}
          />

          <CustomInput
            name="geotabUrl"
            label={t(T['login.geotab.label.url.label'])}
            placeholder="Enter a URL"
            InputProps={{ autoComplete: 'url' }}
            rules={{
              required: 'Enter a geotab URL',
            }}
          />

          <FormControlLabel
            label={t(T['login.label.remember.me'])}
            control={
              <LoginPageSwitch
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            sx={{
              'ml': 0,
              'py': '15px',
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem',
                ml: '8px',
              },
            }}
          />

          <Button type="submit" variant="contained" fullWidth sx={{ mt: 3 }} disabled={loading}>
            {loading && <CircularProgress disableShrink size={16} />}&nbsp;&nbsp; Login
          </Button>
        </Box>
      </FormProvider>
    </BackButtonContainer>
  );
}
