export class NiceLabelRenderer {
  /**
   * @param {import('./_nice').NiceBoundingBoxRenderer} parent
   */
  constructor(parent) {
    this.parent = parent;
    this.canvas = parent.canvas;
    this.ctx = parent.ctx;
    this.options = parent.options;
  }

  /**
   * Draw labels under the Bounding box
   * @param {Array< RenderBoxData>} boxes
   */
  render(boxes) {
    const { scale, fontWeight, fontSize, fontFamily } = this.options;

    // consider displayed boxes as blocked
    /** @type {Array<number[]>} */
    const blocked = [];
    for (const box of boxes) {
      const { x, y, width, height } = box;
      blocked.push([x, y, x + width, y + height]);
    }

    // sort boxes by label count (high to low, triggered ones first)
    boxes.sort((a, b) => {
      const d = b.labels.length - a.labels.length;
      if (a.triggered !== b.triggered) {
        return a.triggered ? -1 : 1;
      } else {
        return d;
      }
    });

    // configure context
    this.ctx.save();
    this.ctx.shadowColor = '#000';
    this.ctx.shadowBlur = 8 * scale;
    this.ctx.textBaseline = 'top';
    this.ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;

    // render labels
    for (const box of boxes) {
      this._renderLabels(box, blocked);
    }
    this.ctx.restore();
  }

  /**
   *
   * @param {RenderBoxData} box
   * @param {Array<number[]>} blocked
   */
  _renderLabels(box, blocked) {
    const { scale } = this.options;
    const { x, y, width, height, labels, color } = box;
    if (!labels.length) return;

    this.ctx.fillStyle = color;

    let tx = x + width / 2;
    let ty = y + height + 7.5 * scale;
    for (let i = 0; i < 3 && i < labels.length; ++i) {
      const { name, value } = labels[i];
      const m = this._measureLabel(tx, ty, name, value);

      // check overlaps
      const sx1 = tx - m.nameWidth - scale; // x1
      const sy1 = ty; // y1
      const sx2 = tx + m.valueWidth + scale; // x2
      const sy2 = ty + m.height; // y2
      if (hasOverlap(blocked, [sx1 - 1, sy1 - 1, sx2 + 2, sy2 + 2])) continue;
      blocked.push([sx1, sy1, sx2, sy2]);

      // render label
      this.ctx.textAlign = 'right';
      this.ctx.fillText(name, tx - scale, ty + m.nameTop);
      this.ctx.fillText(name, tx - scale, ty + m.nameTop); // for deeper color

      // render value
      this.ctx.textAlign = 'left';
      this.ctx.fillText(value, tx + scale, ty + m.valueTop);
      this.ctx.fillText(value, tx + scale, ty + m.valueTop); // for deeper color

      // next text position
      ty += m.height + 5 * scale;
    }
  }

  /**
   * @param {number} x
   * @param {number} y
   * @param {string} name
   * @param {string} value
   */
  _measureLabel(x, y, name, value) {
    const { scale } = this.options;
    const {
      width: nameWidth,
      actualBoundingBoxAscent: nameTop,
      actualBoundingBoxDescent: nameBottom,
    } = this.ctx.measureText(name);
    const {
      width: valueWidth,
      actualBoundingBoxAscent: valueTop,
      actualBoundingBoxDescent: valueBottom,
    } = this.ctx.measureText(value);
    return {
      nameWidth,
      valueWidth,
      nameTop,
      nameBottom,
      valueTop,
      valueBottom,
      top: Math.min(nameTop, valueTop),
      width: nameWidth + valueWidth + 2 * scale,
      height: Math.max(nameTop + nameBottom, valueTop + valueBottom),
    };
  }
}

/**
 * @param {Array<number[]>} rects
 * @param {Array<number>} box
 * @returns {boolean}
 */
function hasOverlap(rects, box) {
  const [bx1, by1, bx2, by2] = box;
  for (const [x1, y1, x2, y2] of rects) {
    if (x1 < bx2 && bx1 < x2 && y1 < by2 && by1 < y2) {
      return true;
    }
  }
  return false;
}
