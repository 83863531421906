import { ChevronLeftRounded as ChevronLeftRoundedIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

/**
 * @typedef {object} BackButtonContainerProps
 * @property {import('react').MouseEventHandler<HTMLButtonElement>} [onClick]
 */

/** @param {import('@mui/material').BoxProps & BackButtonContainerProps} props */
export function BackButtonContainer(props) {
  const { children, onClick, sx, ...extraProps } = props;
  return (
    <Box position="relative" width="100%" px={2}>
      <Box
        position="relative"
        maxWidth={{ sm: '600px' }}
        px={{ xs: 1, sm: 8 }}
        mx="auto"
        {...extraProps}
      >
        {children}
      </Box>
      <Box position="absolute" zIndex="10" left="20px" top="0px">
        <Button
          onClick={onClick}
          variant="contained"
          sx={{
            p: 0,
            minWidth: 0,
            width: { xs: '24px', md: '32px' },
            height: { xs: '24px', md: '32px' },
            marginTop: { xs: '8px', md: 0 },
            borderRadius: '50%',
          }}
        >
          <ChevronLeftRoundedIcon />
        </Button>
      </Box>
    </Box>
  );
}
