import { CustomSwitch } from '@/web/@components/CustomForm';
import { LongMessageViewer } from '@/web/@components/LongMessageViewer';
import { camelCase, startCase } from 'lodash';
import { FeatureNameWithIcon } from '../feature-flags/@components/FeatureNameWithIcon';

export const FEATURES_FLAG_TABLE_COLUMNS = [
  {
    id: 'platformFeature',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    sort: true,
    searchable: true,
    format: (value, _) => <FeatureNameWithIcon name={value} />,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    format: (value, row) => (
      <LongMessageViewer
        message={value}
        title={startCase(camelCase(row.platformFeature))}
        isCodeView={false}
      />
    ),
  },
  {
    id: 'enabled',
    label: 'Enable/Disable',
    align: 'center',
    format: (_, row) => (
      <CustomSwitch
        name={`enabled-${row.platformFeature}`}
        label=""
        BoxProps={{ sx: { justifyContent: 'center' } }}
      />
    ),
  },
  {
    id: 'childTenantInclusive',
    label: 'Apply to child tenants',
    align: 'center',
    format: (_, row) => (
      <CustomSwitch
        name={`childTenantInclusive-${row.platformFeature}`}
        label=""
        BoxProps={{ sx: { justifyContent: 'center' } }}
      />
    ),
  },
];
