import ConstructionIcon from '@mui/icons-material/Construction';
import { Box } from '@mui/material';
import { forwardRef } from 'react';

export const UnderDevelop = forwardRef(
  /**
   * @param {import('@mui/system').BoxProps} props
   * @param {import('react').Ref<any>} ref
   */
  (props, ref) => {
    return (
      <Box
        ref={ref}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
        height={'80vh'}
        {...props}
      >
        <ConstructionIcon fontSize={'large'} htmlColor={'#596A81'} sx={{ fontSize: '80px' }} />
        <p>Under Development</p>
      </Box>
    );
  }
);
