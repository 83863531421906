import { FilterContext, SetFilterContext } from '@/web/@components/FilterContext';
import { useContext } from 'react';
import { CustomDateRangePicker } from '../CustomDateRangePicker';

/** @param {Omit<import('react-date-range').DateRangeProps, 'onChange'>} props */
export function FilteredDateRangePicker(props) {
  const setFilter = useContext(SetFilterContext);
  const { startTime, endTime } = useContext(FilterContext);
  return (
    <CustomDateRangePicker
      {...props}
      startTime={startTime}
      endTime={endTime}
      onChange={setFilter}
    />
  );
}
