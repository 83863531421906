import { NotFoundPage } from '@/web/@components/NotFoundPage';
import { ChangePasswordPage } from './change-password';
import { LoginPage } from './login';
import { ResetPasswordPage } from './reset-password';
import { TenantsPage } from './tenants';
import { GeoTabLogin } from './geotab-login';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <NotFoundPage />,
  },
  {
    path: 'login',
    element: <LoginPage />,
    visibility: 'NO_AUTH',
  },
  {
    path: 'reset-password',
    element: <ResetPasswordPage />,
    visibility: 'NO_AUTH',
  },
  {
    path: 'change-password',
    element: <ChangePasswordPage />,
    visibility: 'ALWAYS',
  },
  {
    path: 'tenants',
    element: <TenantsPage />,
    visibility: 'NO_TENANT_AUTH',
  },
  {
    path: 'geotab-login',
    element: <GeoTabLogin />,
    visibility: 'NO_AUTH',
  },
];

export default routes;
