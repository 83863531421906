import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';

import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CameraTerminal } from '../CameraTerminal';

/**
 * @typedef {object} CameraTerminalModalProps
 * @property {boolean} open
 * @property {EndpointDetailsResponse} camera
 * @property {number} cameraId
 * @property {() => any } onCancel
 */

/**
 * @param {CameraTerminalModalProps} props
 */
export function CameraTerminalModal(props) {
  const { open, camera, cameraId, onCancel } = props;

  const handleClose = async () => {
    onCancel();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog open onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>Terminal</Box>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: 'black' }}>
        <HubConnectionProvider>
          <CameraTerminal camera={camera} cameraId={cameraId} />
        </HubConnectionProvider>
      </DialogContent>
    </Dialog>
  );
}
