import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DBCFileForm } from '../@components/DBCForm';
import { uploadDBCFile } from '../utils';

export function DBCFileCreatePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const uuid = uuidv4();

  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<AbortController>} */
  const [loading, setLoading] = useState();
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);

  /** @type {import('../@components/DBCForm').DBCFileFormProps['onSubmit']} */
  const createDBCFile = async (fields) => {
    if (!fields.dbcFile) {
      setProgressModalOpen(false);
      toastWarning('Please select a DBC file');
      return;
    }

    const aborter = new AbortController();
    setLoading((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      setProgressModalOpen(true);

      /** @type {DbcFileCreateRequest} */
      const body = {};
      body.name = fields.name;
      body.description = fields.description;

      await uploadDBCFile(fields.dbcFile, body, uuid, setPercentage, aborter.signal);

      const request = api.ac.v5.dbc.$id(uuid).$post({
        data: body,
        headers: {
          'Authorization': secretToken,
          'Access-Control-Allow-Origin': '*',
        },
      });
      await request.process();
      toastSuccess('Success', 'DBC File created');
      navigate('/administration/dbc');
    } catch (err) {
      if (!aborter.signal.aborted) {
        if (err.response.data?.status === 'CONFLICT') {
          toastWarning('Error', 'This DBC File already exists.');
        } else {
          toastWarning('Error', 'Could not create DBC File.');
        }
      }
    } finally {
      setLoading(undefined);
      setProgressModalOpen(false);
    }
  };

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Add a New DBC File"
          progressText={'Uploading file...'}
          onClose={() => {
            loading?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['dbc.create.page.title'])}
      </Typography>
      <DBCFileForm onSubmit={createDBCFile} disabled={Boolean(loading)} />
    </Box>
  );
}
