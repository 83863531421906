import { VIN_REGEX } from '@/assets/regex';
import { toastWarning } from '@/utils/toaster';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

/**
 * @typedef {object} CreateVinDialogFields
 * @property {Array<String>} vins
 */

/**
 * @typedef {object} CreateVinDialogProps
 * @property {boolean} [open]
 * @property {boolean} [disabled]
 * @property {VinDetails} [item]
 * @property {() => any} [onCancel]
 * @property {(data: Array<String>) => any} onSubmit
 */

/** @param {CreateVinDialogProps} props */
export function CreateVinDialog(props) {
  const { open, onCancel, onSubmit, disabled } = props;

  const [groupVins, setGroupVins] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleVinSubmit = async () => {
    try {
      onSubmit(groupVins);
      setGroupVins([]);
      setInputValue('');
    } catch (err) {
      toastWarning('danger', 'Failed to add VINs.');
    }
  };

  const isSubmitDisabled = useMemo(() => !groupVins?.length, [groupVins]);

  const handleKeyDown = (event) => {
    const value = inputValue?.trim();
    if (!value) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        event.preventDefault();
        if (!VIN_REGEX.test(value)) {
          toastWarning('Incorrect VIN number');
          break;
        }
        setGroupVins(Array.from(new Set([...groupVins, value])));
        setInputValue('');
        break;
      default:
        break;
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Add New VINs</DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb={1}>
          <Typography variant="subtitle2">Type VIN number and press space or enter</Typography>
        </Box>
        <Autocomplete
          multiple
          disableClearable
          disablePortal
          value={groupVins}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onKeyDown={handleKeyDown}
          options={[]}
          freeSolo
          renderTags={(values, getTagProps) =>
            values.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                onDelete={() => {
                  setGroupVins((items) => items?.filter((i) => i !== option));
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              sx={{
                '& .MuiInputBase-root': {
                  alignItems: 'flex-start',
                  textAlign: 'flex-start !important',
                },
              }}
              {...params}
              variant="outlined"
              multiline
              minRows="5"
            />
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={isSubmitDisabled || disabled}
          variant="contained"
          onClick={handleVinSubmit}
          sx={{ gap: '5px' }}
        >
          <span>Apply</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
