import { FilterContext, SetFilterContext } from '@/web/@components/FilterContext';
import { PageFilteringArea } from '@/web/@components/PageFilteringArea';
import { sortedUniq } from 'lodash';
import { useContext, useMemo } from 'react';
import {
  CoachingFilterContext,
  SetCoachingFilterContext,
} from '../../@context/CoachingFilterContext';

export function CoachingEndpointFilteringArea() {
  const setFilter = useContext(SetFilterContext);
  const setCoachingFilter = useContext(SetCoachingFilterContext);
  const { startTime, endTime } = useContext(FilterContext);
  const { scoreFrom, scoreTo, endpoints, deviceName, deviceSerialNumber } =
    useContext(CoachingFilterContext);

  const cameraNames = useMemo(
    () =>
      sortedUniq(
        (endpoints || [])
          .map((x) => x.deviceLabel)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [endpoints]
  );

  const cameraSerials = useMemo(
    () =>
      sortedUniq(
        (endpoints || [])
          .map((x) => x.deviceSerialNo)
          .filter((x) => x && x !== '-')
          .sort()
      ),
    [endpoints]
  );

  return (
    <PageFilteringArea
      value={{
        dateRange: [startTime, endTime],
        scoreRange: [scoreFrom, scoreTo],
        deviceName,
        deviceSerialNumber,
      }}
      onChange={(data) => {
        setFilter({
          startTime: data.dateRange[0],
          endTime: data.dateRange[1],
        });
        setCoachingFilter({
          deviceName: data.deviceName,
          deviceSerialNumber: data.deviceSerialNumber,
          scoreFrom: data.scoreRange[0],
          scoreTo: data.scoreRange[1],
        });
      }}
      filters={[
        {
          type: 'DATE',
          key: 'dateRange',
          title: 'Date',
          required: true,
        },
        {
          type: 'CHOICE',
          key: 'deviceName',
          title: 'Camera',
          submenu: cameraNames,
          hidden: !cameraNames.length,
        },
        {
          type: 'CHOICE',
          key: 'deviceSerialNumber',
          title: 'Serial',
          submenu: cameraSerials,
          hidden: !cameraSerials.length,
        },
        // {
        //   type: 'SCORE',
        //   key: 'scoreRange',
        //   title: 'Score',
        //   disableClose: true,
        // },
      ]}
    />
  );
}
