import { CheckCircle, Circle } from '@mui/icons-material';
import { Box, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} CustomCheckboxProps
 * @property {any} name
 * @property {string} label
 * @property {import('@mui/material').CheckboxProps} [CheckBoxProps]
 * @property {import('react-hook-form').ControllerProps['rules']} [rules]
 */

/**
 * @param {CustomCheckboxProps} props
 */
export function CustomCheckbox(props) {
  const { name, label, rules, CheckBoxProps } = props;

  const { control } = useFormContext();

  return (
    <FormControl variant="standard" fullWidth>
      <Box display="flex" alignItems="center" gap="4px">
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  sx={{
                    '& .checkbox': {
                      padding: '1px',
                      fontSize: '1.375rem',
                      borderRadius: '50%',
                      background: '#ffffff',
                      border: '1px solid #cfd8e5',
                    },
                  }}
                  checked={Boolean(field.value)}
                  onChange={(e) => field.onChange(e.target.checked)}
                  color={error ? 'error' : 'default'}
                  title={error?.message || ''}
                  checkedIcon={<CheckCircle className="checkbox" />}
                  icon={<Circle htmlColor="#CFD8E5" className="checkbox" />}
                  {...CheckBoxProps}
                />
              }
            />
          )}
        />
      </Box>
    </FormControl>
  );
}
