import { BoxImage } from '@/web/@components/BoxImage';
import { IconButton, TextField } from '@mui/material';
import Popover from '@mui/material/Popover';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePickerPopoverContent } from './DateRangePickerPopoverContent';

/**
 * @typedef {object} CustomDateRangePickerProps
 * @property {number} [startTime]
 * @property {number} [endTime]
 * @property {(range: {startTime: number, endTime: number}) => any} onChange
 */

/** @param {CustomDateRangePickerProps & Omit<import('react-date-range').DateRangeProps, 'onChange'>} props */
export function CustomDateRangePicker(props) {
  const { startTime, endTime, onChange, ...extraProps } = props;

  /** @type {StateVariable<any>} */
  const [popover, setPopover] = useState(null);
  /** @type {StateVariable<string>} */
  const [label, setLabel] = useState('');

  useEffect(() => {
    const dateFormat = 'dd/MM/yyyy';
    setLabel(`${format(startTime, dateFormat)} - ${format(endTime, dateFormat)}`);
  }, [startTime, endTime]);

  /** @type {CustomDateRangePickerProps['onChange']} */
  const handleChange = (range) => {
    onChange(range);
    setPopover(null);
  };

  return (
    <>
      <TextField
        fullWidth
        sx={{
          'width': { sm: '250px' },
          '& .MuiOutlinedInput-root': {
            height: '35px',
            padding: 0,
            borderColor: 'transparent',
          },
          '& .MuiInputBase-root': {
            color: '#596A81',
            fontWeight: '500',
            borderRadius: '6px',
          },
        }}
        aria-readonly
        variant="outlined"
        value={label}
        size="small"
        onClick={(e) => setPopover(e.currentTarget)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton color="primary" onClick={(e) => setPopover(e.currentTarget)}>
              <BoxImage
                src="/images/commons/calendar-icon-datepicker.svg"
                height="20px"
                width="25px"
              />
            </IconButton>
          ),
        }}
      />

      {popover && (
        <Popover
          open
          anchorEl={popover}
          onClose={() => setPopover(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <DateRangePickerPopoverContent
            {...extraProps}
            startTime={startTime}
            endTime={endTime}
            onChange={handleChange}
          />
        </Popover>
      )}
    </>
  );
}
