import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { T } from '@/assets/locales';
import { UnderDevelop } from '@/web/@components/UnderDevelop';

export function TenantDetailsPage() {
  const { t } = useTranslation();

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['tenant.details.page.title'])}
      </Typography>
      <UnderDevelop />
    </Box>
  );
}
