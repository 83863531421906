/**
 * ----------------- HOW IT WORKS --------------------
 * Action sequence to establish an RTC connection:
 *  1. Add listener to HubConnection connection
 *  2. Fetch WebRTC server configurations
 *  3. Establish the RTCPeerConnection with the offer
 *  4. Add listens to RTCPeerConnection instance
 *  5. Hooks to handle playback state change
 *  6. Stop live streaming after a certain time
 * ---------------------------------------------------
 * Trickle ICE logic:
 * - Send stream request to the device
 * - Receive and cache ice candidates
 * - Receive the offer and initialize RTCPeerConnection
 * - Create answer when signaling-state is not stable or closed
 * - Set the answer as local description
 * - Send the answer SDP to the device
 * - Add incoming ICE candidates when remote description is available
 * - Send generated ICE candidates to the device
 * ---------------------------------------------------
 * Non-Trickle ICE logic:
 * - Send stream request to the device
 * - Receive the offer and initialize RTCPeerConnection
 * - Create answer when signaling-state is not stable or closed
 * - Set the answer as local description
 * - When ICE gathering is complete send the answer
 * ---------------------------------------------------
 * Documentations:
 * - https://smarteraicamera.atlassian.net/wiki/spaces/SAP/pages/6390002/Streaming+to+Browser#Offer-and-Answer-negotiation
 * - https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection
 * - https://webrtchacks.com/sdp-anatomy/
 */

import api from '@/api';
import { REPLY_TIMEOUT, STREAMING_PREVIEW_TIMEOUT } from '@/assets/player/config';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken } from '@/store/auth';
import { selectTabVisible } from '@/store/page-view';
import { drawMultiBoundingBox } from '@/utils/bounding-box';
import { SmartCache } from '@/utils/caching/smart-cache';
import { CustomLogger } from '@/utils/logger';
import { captureVideoFrame, makeVideo } from '@/utils/video';
import { HubConnectionContext } from '@/web/@components/HubConnectionContext';
import { sendHubObject } from '@/web/@components/HubConnectionContext/messaging';
import { Box, CircularProgress } from '@mui/material';
import { debounce, throttle } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { parseVideoMetaDataMessage } from './parsers';

const logger = new CustomLogger('LiveStreaming');

/** @type {SmartCache<Array<RTCIceServer>>} */
const iceServerCache = new SmartCache('endpoint.serverconfig', 24 * 3600 * 1000);

/**
 * @typedef {object} LiveStreamingProps
 * @property {string} source
 * @property {number} endpointId
 * @property {(url: string) => any} [onSnapshot]
 */

/** @param {LiveStreamingProps & import('@mui/material').BoxProps} props */
export function LiveStreaming(props) {
  const { endpointId, source, onSnapshot, ...boxProps } = props;

  const hub = useContext(HubConnectionContext);
  const tabVisible = useSelector(selectTabVisible);
  const secretToken = useSelector(selectSecretToken);

  /** @type {import('react').MutableRefObject<HTMLDivElement>} */
  const containerRef = useRef();
  /** @type {import('react').MutableRefObject<HTMLCanvasElement>} */
  const canvasRef = useRef();

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<boolean>} */
  const [active, setActive] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [streaming, setStreaming] = useState(false);
  /** @type {StateVariable<{[key: string]: string}>} */
  const [ssrcToIdMap, setSsrcToIdMap] = useState(null);
  /** @type {StateVariable<Array<RTCIceServer>>} */
  const [iceServers, setIceServers] = useState(null);
  /** @type {StateVariable<string>} */
  const [offer, setOffer] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [disableTrickleICE, setDisableTrickleICE] = useState(null);
  /** @type {StateVariable<Array<string>>} */
  const [iceCandidates, setIceCandidates] = useState([]);
  /** @type {StateVariable<RTCPeerConnection>} */
  const [pc, setPC] = useState(null);
  /** @type {StateVariable<MediaStream>} */
  const [stream, setStream] = useState(null);
  /** @type {StateVariable<Array<VideoMetaData>>} */
  const [bboxes, setBBoxes] = useState(null);
  /** @type {StateVariable<HTMLVideoElement>} */
  const [videoEl, setVideoEl] = useState(null);
  /** @type {StateVariable<string>} */
  const [uniqueStreamId, setUniqueStreamId] = useState(null);

  const stopPending = useMemo(() => Boolean(active && !streaming), [active, streaming]);
  const startPending = useMemo(() => Boolean(!active && streaming), [active, streaming]);

  /// -----------------------------------------------------
  /// 1 | Add listener to HubConnection connection
  /// -----------------------------------------------------
  useEffect(() => {
    if (!hub) return;
    const handler = (/** @type {any} */ message) => {
      switch (message.type) {
        case MESSAGE_TYPE.STREAM_SOURCE_NAMES: {
          const inputSources = JSON.parse(message.text);
          /** @type {{[key: string]: string}} */
          const data = {};
          for (const source of inputSources || []) {
            if (source.status !== 'SUCCESS') continue;
            data[source.ssrc] = source.inputSource;
          }
          logger.debug('Source map:', data);
          setSsrcToIdMap((v) => ({ ...v, ...data }));
          break;
        }
        case MESSAGE_TYPE.STREAM_SOURCE_ADDED: {
          setOffer(null);
          sendHubObject({
            endpointId,
            connectionId: uniqueStreamId,
            type: MESSAGE_TYPE.RTC_HANGUP,
          });
          break;
        }
        case MESSAGE_TYPE.STREAM_SOURCE_REMOVED: {
          const id = message.text;
          setSsrcToIdMap((v) => {
            if (!v[id]) return v;
            delete v[id];
            return { ...v };
          });
          break;
        }
        case MESSAGE_TYPE.RTC_ICE_CANDIDATES: {
          const text = JSON.parse(message.text);
          setIceCandidates((prev) => [...prev, text.icecandidate]);
          break;
        }
        case MESSAGE_TYPE.RTC_OFFER: {
          const text = JSON.parse(message.text);
          logger.debug('offer', text);
          setOffer((x) => x || text.SDP);
          break;
        }
        case MESSAGE_TYPE.RTC_HANGUP: {
          setOffer(null);
          break;
        }
        case MESSAGE_TYPE.CONNECTION_CANCEL: {
          setOffer(null);
          setStreaming(false);
          break;
        }
        default:
          break;
      }
    };
    hub.on('newMessage', handler);
    return () => hub?.off('newMessage', handler);
  }, [hub, uniqueStreamId, endpointId]);

  useEffect(() => {
    if (!offer) {
      setIceCandidates([]);
      setDisableTrickleICE(null);
    } else if (disableTrickleICE === null) {
      const hasICE = offer.includes('a=candidate') || offer.includes('a = candidate');
      logger.info('Offer has ICE', hasICE);
      setDisableTrickleICE(hasICE);
    }
  }, [offer, disableTrickleICE]);

  /// -----------------------------------------------------
  /// 2 | Fetch WebRTC server configurations
  /// -----------------------------------------------------
  useEffect(() => {
    if (!endpointId) return;
    setLoading(true);
    const request = api.ac.endpoint.serverconfig.$get({
      params: {
        secretToken,
        endpointID: endpointId,
      },
    });
    iceServerCache
      .getItem(endpointId)
      .then(async (data) => {
        if (data) {
          setIceServers(data);
          return;
        }
        const result = await request.process();
        /** @type {Array<RTCIceServer>} */
        const servers = [
          {
            urls: `stun:${result.stunUdpHost}:${result.stunUdpPort}`,
          },
          {
            urls: `turn:${result.turnUdpHost}:${result.turnUdpPort}?transport=udp`,
            username: result.turnUser,
            credential: result.turnPass,
          },
        ];
        setIceServers(servers);
        await iceServerCache.setItem(endpointId, servers);
      })
      .catch((err) => {
        logger.error('Could not get server config', err);
        setLoading(false);
      });
  }, [endpointId, secretToken]);

  /// -----------------------------------------------------
  /// 3 | Establish the RTCPeerConnection with the offer
  /// -----------------------------------------------------

  /** @type {StateVariable<RTCSignalingState>} */
  const [signalingState, setSignalingState] = useState(null);
  /** @type {StateVariable<RTCIceGathererState>} */
  const [iceGatheringState, setIceGatheringState] = useState(null);
  /** @type {StateVariable<RTCIceConnectionState>} */
  const [iceConnectionState, setIceConnectionState] = useState(null);

  useEffect(() => {
    if (!hub || !iceServers?.length) return;

    /** @type {RTCConfiguration} */
    const config = {
      rtcpMuxPolicy: 'require',
      bundlePolicy: 'max-bundle',
      iceServers,
    };
    logger.debug('RTCPeerConnection Config:', config);

    try {
      const _pc = new RTCPeerConnection(config);

      _pc.onsignalingstatechange = () => {
        logger.debug('signaling state changed:', _pc.signalingState);
        setSignalingState(_pc.signalingState);
      };
      _pc.onicegatheringstatechange = () => {
        logger.debug('ice gathering state changed:', _pc.iceGatheringState);
        setIceGatheringState(_pc.iceGatheringState);
      };
      _pc.oniceconnectionstatechange = () => {
        logger.debug('ice connection state changed:', _pc.iceConnectionState);
        setIceConnectionState(_pc.iceConnectionState);
      };

      // logger.debug(_pc);
      setPC(_pc);

      return () => {
        _pc.onsignalingstatechange = null;
        _pc.onicegatheringstatechange = null;
        _pc.oniceconnectionstatechange = null;
        _pc.close();
        setPC(null);
      };
    } catch (err) {
      logger.debug('Failed to create RTC Peer Connection', err);
      setStreaming(false);
      setLoading(false);
    }
  }, [hub, iceServers]);

  /// -----------------------------------------------------
  /// 4 | Add listens to RTCPeerConnection instance
  /// -----------------------------------------------------
  useEffect(() => {
    if (!pc) return;
    if (signalingState !== 'have-local-pranswer' && signalingState !== 'have-remote-offer') return;
    pc.createAnswer()
      .then((answer) => {
        logger.debug('Setting local description answer', answer);
        return pc.setLocalDescription(answer);
      })
      .then(() => {
        // send answer for trickle ice enabled devices
        if (disableTrickleICE) return;
        const answer = pc.localDescription;
        logger.debug('Sending answer for trickle ICE', answer);
        sendHubObject({
          endpointId,
          connectionId: uniqueStreamId,
          type: MESSAGE_TYPE.RTC_ANSWER,
          message: { SDP: answer.sdp },
        });
      })
      .catch((err) => {
        logger.warn('Failed to set answer', err);
      });
  }, [pc, signalingState, uniqueStreamId, endpointId, disableTrickleICE]);

  // Add incoming ICE candidates to RTCPeerConnection
  useEffect(() => {
    if (!pc || !iceCandidates?.length) return;
    if (!pc.remoteDescription || !iceCandidates?.length || signalingState === 'closed') return;
    const aborter = new AbortController();
    const added = {};
    Promise.allSettled(
      iceCandidates.map(async (candidate) => {
        try {
          await pc.addIceCandidate({
            candidate,
            sdpMLineIndex: 0, // Could be a hard code for our case
          });
          added[candidate] = true;
          logger.debug('Added ice candidate on stable', candidate);
        } catch (err) {
          logger.error('Failed to add ice candidate', err, {
            signalingState: pc.signalingState,
            iceGatheringState: pc.iceGatheringState,
            iceConnectionState: pc.iceConnectionState,
          });
        }
      })
    ).catch(logger.error);
    setIceCandidates((prev) => prev.filter((x) => !added[x]));
    return () => aborter.abort();
  }, [pc, signalingState, iceCandidates]);

  useEffect(() => {
    if (!pc || !endpointId) return;
    switch (iceGatheringState) {
      case 'new':
      case 'gathering':
        break;
      case 'complete':
        // send answer for non-trickle ice enabled devices
        if (!disableTrickleICE) break;
        const answer = pc.localDescription;
        logger.debug('Sending answer on ice-gathering complete', answer);
        sendHubObject({
          endpointId,
          connectionId: uniqueStreamId,
          type: MESSAGE_TYPE.RTC_ANSWER,
          message: { SDP: answer.sdp },
        });
        break;
      default:
        break;
    }
  }, [pc, iceGatheringState, uniqueStreamId, endpointId, disableTrickleICE]);

  useEffect(() => {
    switch (iceConnectionState) {
      case 'new':
      case 'checking':
        logger.debug('WebRTC connection starting');
        setLoading(true);
        setActive(false);
        break;
      case 'connected':
      case 'completed':
        logger.info('WebRTC connection completed');
        setLoading(false);
        setActive(true);
        break;
      case 'failed':
        logger.error('WebRTC connection failed');
        setLoading(false);
        setActive(false);
        break;
      case 'disconnected':
      case 'closed':
        logger.warn('WebRTC connection closed');
        setLoading(false);
        setActive(false);
        break;
      default:
        break;
    }
  }, [iceConnectionState]);

  useEffect(() => {
    if (!pc) return;
    pc.onicecandidate = (/** @type {RTCPeerConnectionIceEvent}*/ e) => {
      logger.debug('ice candidate:', e);
      const candidate = e.candidate;
      if (candidate && candidate.port) {
        logger.debug('Candidate Type:', candidate.type);
      }
      if (candidate && candidate.address && !disableTrickleICE) {
        logger.debug('Sending candidate:', candidate.address);
        sendHubObject({
          endpointId,
          connectionId: uniqueStreamId,
          type: MESSAGE_TYPE.RTC_ICE_CANDIDATES,
          message: { icecandidate: candidate.candidate },
        });
      }
    };
    return () => {
      pc.onicecandidate = null;
    };
  }, [pc, endpointId, uniqueStreamId, disableTrickleICE]);

  useEffect(() => {
    if (!pc) return;
    /** @type {Array<MediaStreamTrack>} */
    const tracks = [];
    pc.ontrack = async (/** @type {RTCTrackEvent}*/ e) => {
      tracks.push(e.track);
      for (const stream of e.streams) {
        if (stream.id === source) {
          setStream(stream);
        }
      }
    };
    return () => {
      pc.ontrack = null;
      tracks.forEach((track) => track.stop());
    };
  }, [pc, source]);

  useEffect(() => {
    if (!pc) return;
    const aborter = new AbortController();
    pc.ondatachannel = (/** @type {RTCDataChannelEvent}*/ e) => {
      aborter.signal.addEventListener('abort', () => {
        e.channel.onopen = null;
        e.channel.onmessage = null;
      });
      switch (e.channel.label) {
        case 'HeartBeat': {
          const sendPong = debounce(() => {
            if (e.channel.readyState !== 'open') return;
            e.channel.send('1');
          }, 500);
          e.channel.onopen = sendPong;
          e.channel.onmessage = sendPong;
          break;
        }
        case 'meta_data': {
          e.channel.onmessage = async (e) => {
            if (!ssrcToIdMap) return;
            const data = await parseVideoMetaDataMessage(e.data);
            if (data?.bboxes) {
              /** @type {Array<VideoMetaData>} */
              const bboxes = [];
              for (const [key, val] of Object.entries(data.bboxes)) {
                if (!val || !key) continue;
                for (const metadata of val) {
                  if (ssrcToIdMap[key] !== source) continue;
                  metadata.source = ssrcToIdMap[key];
                  bboxes.push(metadata);
                }
              }
              setBBoxes(bboxes);
            }
          };
          break;
        }
        default:
          logger.warn('Unknown data channel:', e.channel.label);
          break;
      }
    };
    return () => {
      aborter.abort();
      pc.ondatachannel = null;
    };
  }, [pc, ssrcToIdMap, source]);

  useEffect(() => {
    if (!offer || pc?.signalingState !== 'stable') return;
    pc.setRemoteDescription({
      type: 'offer',
      sdp: offer,
    });
  }, [pc, offer]);

  /// -----------------------------------------------------
  /// 5 | Hooks to handle playback state change
  /// -----------------------------------------------------
  useEffect(() => {
    setStreaming(hub && tabVisible);
  }, [hub, tabVisible]);

  useEffect(() => {
    if (!endpointId || !startPending) return;
    logger.debug('Start playing', endpointId, startPending);

    const timer = setTimeout(() => {
      logger.warn(`No response for stream request after ${REPLY_TIMEOUT}ms`);
      setStreaming(false);
      setLoading(false);
    }, REPLY_TIMEOUT);

    const id = uuidv4();
    setUniqueStreamId(id);

    setOffer(null);
    setActive(false);
    sendHubObject({
      endpointId,
      connectionId: id,
      type: MESSAGE_TYPE.RTC_OFFER,
      message: { SDP: 'streamRequest' },
    });

    return () => clearTimeout(timer);
  }, [endpointId, startPending]);

  useEffect(() => {
    if (!endpointId || !stopPending) return;
    logger.debug('stop playing', endpointId, stopPending);
    sendHubObject({
      endpointId,
      connectionId: uniqueStreamId,
      type: MESSAGE_TYPE.RTC_HANGUP,
    });
  }, [endpointId, uniqueStreamId, stopPending]);

  useEffect(() => {
    if (!active) return;
    const tid = setTimeout(() => {
      setStreaming(false);
    }, STREAMING_PREVIEW_TIMEOUT);
    return () => clearTimeout(tid);
  }, [active, uniqueStreamId, endpointId]);

  /// -----------------------------------------------------
  /// 7 | Render the player on the view
  /// -----------------------------------------------------

  useEffect(() => {
    if (!stream) return;
    const aborter = new AbortController();
    makeVideo(stream, aborter.signal)
      .then((video) => {
        video.style.objectPosition = 'center';
        video.style.objectFit = 'cover';
        video.play().catch(console.error);
        setVideoEl(video);
        containerRef?.current?.replaceChildren(video);
        aborter.signal.addEventListener('abort', () => {
          video.pause();
          setVideoEl(null);
        });
      })
      .catch((err) => {
        logger.info('Failed to process imager', err);
        setVideoEl(null);
      });
    return () => aborter.abort();
  }, [stream]);

  useEffect(() => {
    // bounding box draw function
    const canvas = canvasRef.current;
    if (!canvas || !videoEl) return;

    // shows the bounding box
    drawMultiBoundingBox(canvas, bboxes, {
      disabledLabels: [],
      imagerWidth: videoEl.videoWidth,
      imagerHeight: videoEl.videoHeight,
    });
  }, [bboxes, videoEl]);

  /** @param {HTMLVideoElement} video */
  useEffect(() => {
    if (!videoEl || !onSnapshot) return;
    const reporter = throttle(() => {
      try {
        onSnapshot(captureVideoFrame(videoEl));
      } catch (err) {
        console.warn('Failed to process iframe', err);
      }
    }, 500);
    reporter();
    videoEl.addEventListener('timeupdate', reporter);
    return () => {
      reporter.cancel();
      videoEl.removeEventListener('timeupdate', reporter);
    };
  }, [videoEl, onSnapshot]);

  return (
    <Box {...boxProps}>
      <Box ref={containerRef} width="100%" height="100%">
        {loading && (
          <CircularProgress
            color="secondary"
            size="32px"
            sx={{
              position: 'absolute',
              top: 'calc(50% - 16px)',
              left: 'calc(50% - 16px)',
            }}
          />
        )}
      </Box>
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
}
