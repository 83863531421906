import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { sortDataByKey } from '@/web/@components/ItemsTableView/utils';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CenterBox } from '../CenterBox';
import { IconMessageBox } from '../IconMessageBox';
import { SearchField } from '../SearchField';
import { DialogTableBodyCell } from './DialogTableBodyCell';
import { DialogTableHeaderCell } from './DialogTableHeaderCell';
import { trimString } from '@/utils/formatting';

/**
 * @template T
 * @typedef {object} DialogTableProps<T>
 * @property {string} [title]
 * @property {Array<T>} results
 * @property {Array<TableColumn<T>>} columns
 * @property {Error} [error]
 * @property {boolean} [loading]
 * @property {string} [noDataText]
 * @property {() => any} [retry]
 * @property {string} [dataSortKey]
 * @property {boolean} [disableMultiSelect]
 * @property {import('react').Dispatch<import('react').SetStateAction<Array<T>>>} [onSelectItem]
 */

/**
 * @template T
 * @param {DialogTableProps<T>} props
 */
export function DialogTable(props) {
  const {
    title = '',
    disableMultiSelect,
    noDataText,
    results,
    columns,
    error,
    loading,
    dataSortKey = '',
    onSelectItem,
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [selection, setSelection] = useState([]);

  /** @type {StateVariable<import('@mui/material').SortDirection>} */
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(dataSortKey);

  const filteredResults = useMemo(() => {
    const searchBy = trimString(filter).toLowerCase();
    const searchable = columns.filter((x) => x.searchable).map((x) => x.id);
    if (!searchBy || !searchable.length) return results || [];
    return (results || []).filter((item) => {
      for (const column of searchable) {
        if ((item[column] + '').toLowerCase().includes(searchBy)) {
          return true;
        }
      }
      return false;
    });
  }, [filter, results, columns]);

  const sortedData = useMemo(
    () => sortDataByKey(filteredResults, orderBy, order, columns[orderBy]?.fallback),
    [filteredResults, orderBy, order, columns]
  );

  /** @type {import('./DialogTableHeaderCell').DialogTableHeaderCellProps<T>['onSortRequest']} */
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /** @type {import('./DialogTableBodyCell').DialogTableBodyCellProps<T>['onSelect']} */
  const handleSelect = (row, checked) => {
    if (checked) {
      if (disableMultiSelect) {
        setSelection([row]);
      } else {
        setSelection([...selection, row]);
      }
      return;
    }

    if (disableMultiSelect) {
      setSelection([]);
    } else {
      setSelection((list) => list.filter((item) => item !== row));
    }
  };

  useEffect(() => {
    onSelectItem && onSelectItem(selection);
  }, [selection, onSelectItem]);

  if (loading) {
    return <CenterBox style={{ height: '50vh' }} children={<CircularProgress />} />;
  }

  if (error) {
    return (
      <CenterBox style={{ height: '50vh' }}>
        <IconMessageBox
          size="256px"
          src={NO_SEARCH_RESULT_IMG}
          message="Sorry! Could not fetch data"
        />
      </CenterBox>
    );
  }

  return (
    <Box className="offset-pagination" sx={{ width: '100%' }}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>

        <SearchField
          clearable
          searchOnEnter
          value={filter}
          onSearch={setFilter}
          placeholder={'Search'}
          InputProps={{
            // @ts-ignore
            onInput: (event) => setFilter(event.target.value),
          }}
          sx={{
            borderColor: 'transparent',
            borderRadius: '6px',
            background: '#f7f7f7',
            width: { xs: '100%', sm: '50%', md: 280 },
          }}
        />
      </Toolbar>

      {!filteredResults?.length ? (
        <CenterBox style={{ height: '50vh' }}>
          <IconMessageBox
            size="256px"
            src={NO_SEARCH_RESULT_IMG}
            message={t(noDataText || T['no.data.found'])}
          />
        </CenterBox>
      ) : (
        <TableContainer sx={{ height: 'calc(80vh - 150px)' }}>
          <Table
            stickyHeader
            size={'small'}
            sx={{
              'fontSize': '0.875rem',
              '& .MuiTableHead-root': {
                '& .MuiTableCell-root': {
                  'fontSize': '1rem',
                  'color': theme.palette.text.disabled,
                  'borderTop': '1px solid #E0ECFC',
                  'borderBottom': '1px solid #E0ECFC',
                  'fontWeight': 500,
                  ':first-of-type': {
                    borderLeft: '1px solid #E0ECFC',
                    borderRadius: '4px 0 0 4px',
                  },
                  ':last-child': {
                    borderRight: '1px solid #E0ECFC',
                    borderRadius: '0 4px 4px 0',
                  },
                  '& .MuiCheckbox-root > .MuiSvgIcon-root': {
                    fill: '#D3E0F2',
                    display: 'none',
                  },
                },
              },
              '& .MuiTableBody-root': {
                '& .MuiTableRow-root': {},
                '& .MuiTableRow-root:hover': {
                  backgroundColor: '#adb4bc17',
                },
                '& .MuiTableCell-root': {
                  'padding': '0 16px !important',
                  'borderBottom': '1px solid #E0ECFC',
                  'fontSize': '1rem',
                  '& .Mui-checked': {
                    color: '#608AC3',
                  },
                },
              },
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <DialogTableHeaderCell
                    key={column.id + index}
                    column={column}
                    orderBy={orderBy}
                    order={order}
                    onSortRequest={handleRequestSort}
                    data={filteredResults || []}
                  />
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {(sortedData || []).map((row, index) => (
                <DialogTableBodyCell
                  key={index + ''}
                  columns={columns}
                  selection={selection}
                  item={row}
                  onSelect={handleSelect}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
