import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useMemo } from 'react';
import { TRIGGER_COLOR } from '../../assets';

const Line = styled('path')(({ color }) => ({
  fill: 'none',
  stroke: color,
  strokeWidth: '7px',
}));

const Node = styled('circle')(({ color }) => ({
  fill: color,
  stroke: 'white', // border color
  strokeWidth: '2', // border width
  r: '8',
}));

const YAxisLine = styled('line')({
  stroke: '#DFE8F4',
  strokeWidth: '1px',
});

const XAxisLine = styled('line')({
  stroke: 'transparent',
  strokeWidth: '1px',
});

const MiniLineChart = ({
  data,
  defaultData,
  min,
  max,
  chartWidth = 200,
  chartHeight = 40,
  viewHeight = 0,
}) => {
  const getNodeColor = useMemo(() => {
    return (value, index) => {
      const defaultItem = defaultData?.find((item, defIndex) => index === defIndex);
      return defaultItem === value ? TRIGGER_COLOR.BLUE : TRIGGER_COLOR.RED;
    };
  }, [defaultData]);

  const calculatePath = (data, width, height) => {
    const maxY = max || Math.max(...data);
    const minY = min || Math.min(...data);
    const scaleY = height / (maxY - minY);

    const points = data.map((value, index) => ({
      x: (index * width) / (data.length - 1),
      y: height - (value - minY) * scaleY,
    }));

    const path = points.map((point, index) => {
      if (index === 0) {
        return `M${point.x},${point.y}`;
      }
      return `L${point.x},${point.y}`;
    });

    return path.join(' ');
  };

  const drawYAxes = (minY, maxY, height) => {
    const interval = (maxY - minY) / 3;

    return Array.from({ length: 4 }).map((_, axisIndex) => {
      const yAxisValue = minY + axisIndex * interval;
      const y = height - ((yAxisValue - minY) / (maxY - minY)) * height;

      return (
        <g key={axisIndex}>
          <YAxisLine x1="0" y1={y} x2={chartWidth} y2={y} />
          {viewHeight && (
            <text
              x="-30"
              y={y}
              fill="#000"
              textAnchor="start"
              alignmentBaseline="middle"
              fontSize={10}
            >
              {yAxisValue.toFixed(1)} s
            </text>
          )}
        </g>
      );
    });
  };

  const drawXAxes = (minX, maxX, width) => {
    const interval = (maxX - minX) / 4;

    return Array.from({ length: 5 }).map((_, axisIndex) => {
      const xAxisValue = minX + axisIndex * interval;
      const x = ((xAxisValue - minX) / (maxX - minX)) * width;

      return (
        <g key={axisIndex}>
          <XAxisLine x1={x} y1="0" x2={x} y2={chartHeight} />
          <text x={x} y={chartHeight + 25} fill="#000" textAnchor="middle" fontSize={10}>
            {xAxisValue} km/h
          </text>
        </g>
      );
    });
  };

  const maxY = max || Math.max(...data);
  const minY = min || Math.min(...data);

  // Add a margin of 10px to the viewBox
  const viewBoxMargin = viewHeight ? 30 : 10;
  const viewBoxWidth = chartWidth - 2 * viewBoxMargin;
  const viewBoxHeight = 75 - 2 * viewBoxMargin + viewHeight;

  const viewBox = `${-viewBoxMargin} ${-viewBoxMargin} ${viewBoxWidth + 2 * viewBoxMargin} ${
    viewBoxHeight + 2 * viewBoxMargin + viewHeight
  }`;

  return (
    <Box>
      <svg width="100%" height="100%" viewBox={viewBox}>
        {/* Ensure that the entire content fits within the viewBox */}
        {drawYAxes(minY, maxY, viewBoxHeight)}

        {viewHeight && drawXAxes(30, 150, viewBoxWidth)}

        {/* CURRENT VALUES */}
        <Line color={'#2196f3'} d={calculatePath(data, viewBoxWidth, viewBoxHeight)} />
        {data.map((value, index) => (
          <Node
            key={index}
            color={getNodeColor(value, index)}
            cx={(index * viewBoxWidth) / (data.length - 1)}
            cy={viewBoxHeight - ((value - minY) / (maxY - minY)) * viewBoxHeight}
          />
        ))}
      </svg>
    </Box>
  );
};

export default MiniLineChart;
