import { styled, Switch } from '@mui/material';

export const LoginPageSwitch = styled(Switch)(() => ({
  'width': 28,
  'height': 16,
  'padding': 0,
  'display': 'flex',
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: '#B7C4D5',
  },
  '& .MuiSwitch-track': {
    borderRadius: 8,
    border: '1px solid #C5D9F0',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    'padding': 2,
    '&.Mui-checked': {
      'color': '#fff',
      'transform': 'translateX(12px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#596A81',
      },
      '& + .MuiSwitch-track': {
        border: '1px solid #596A81',
        backgroundColor: '#fff',
      },
    },
  },
}));
