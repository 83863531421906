import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { generateUniqueKey } from '@/utils/table';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { sortBy } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ACTION_ITEMS } from './actions';
import { TENANT_TABLE_COLUMNS } from './columns';

export function ChildTenantList(props) {
  const { type, isParentInclude = false } = props;
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const getTenantDetails = useCallback(async () => {
    const request = api.ac.v5.tenant.$tenantId(tenantId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    await request.process();
    return request.result;
  }, [secretToken, tenantId]);

  const {
    result: tenants,
    loading,
    error,
  } = useFutureLoader(
    async ({ secretToken }) => {
      if (!tenantId) return;
      const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      const tenants = request.result;

      if (isParentInclude) {
        const currentTenant = await getTenantDetails();
        tenants.push(currentTenant);
      }

      return sortBy(
        tenants.map((item) => ({ ...item, type })),
        ['tenantStatus', (item) => item.tenantName.toLowerCase()]
      );
    },
    [tenantId, getTenantDetails]
  );

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Tenant list');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'Child Tenants'} actions={ACTION_ITEMS} />
        <ContinuationTokenTable
          key={generateUniqueKey([secretToken, 'tenants'])}
          results={tenants}
          loading={loading}
          // @ts-ignore
          columns={TENANT_TABLE_COLUMNS}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
