import { DELETE_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Divider, IconButton } from '@mui/material';

/**
 * @typedef {object} ModifySourceItemContainerProps
 * @property {() => any} onRemove
 * @property {import('react').ReactNode} children
 */

/** @param {ModifySourceItemContainerProps & import('@mui/material').BoxProps} props */
export function ModifySourceItemContainer(props) {
  const { onRemove, children, ...others } = props;
  return (
    <Box
      position="relative"
      borderLeft="2px solid #608AC3"
      mb="20px"
      pr="50px"
      ml={-1.5}
      {...others}
    >
      <Divider
        orientation="vertical"
        sx={{
          'position': 'absolute',
          'right': 0,
          'top': '10px',
          'bottom': '10px',
          '& .MuiDivider-wrapper': {
            p: '2px',
          },
        }}
      >
        <IconButton
          onClick={onRemove}
          color="error"
          sx={{
            'm': 0,
            'p': '5px',
            'borderRadius': '50%',
            'border': '1px solid #D64657',
            '&:hover': {
              background: 'rgba(211, 47, 47, 0.15)',
            },
          }}
        >
          <BoxImage src={DELETE_ICON} size="16px" />
        </IconButton>
      </Divider>
      {children}
    </Box>
  );
}
