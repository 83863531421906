import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @template T
 * @typedef {object} ItemTableViewHeadProps<T>
 * @property {Array<T>} data
 * @property {Array<TableColumn<T>>} columns
 * @property {(key: string) => any} onSortRequest
 * @property {string} [orderBy]
 * @property {import('@mui/material').SortDirection} [order]
 */

/**
 * @template T
 * @param {ItemTableViewHeadProps<T>} props
 */
export function ItemTableViewHead(props) {
  const { columns, data, order, orderBy, onSortRequest } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            key={column.id + index}
            align={column.align}
            style={{
              minWidth: column.minWidth,
              width: column.width,
            }}
            padding={'normal'}
            sortDirection={orderBy === column.id ? order : false}
          >
            <Box
              display={'inline-flex'}
              alignItems={'center'}
              sx={{ cursor: 'pointer' }}
              fontWeight={600}
              fontSize={'0.875rem'}
              onClick={() => column.sort && onSortRequest(column.id)}
            >
              {column?.headerFormat ? column.headerFormat(column.id, data) : t(column.label)}
              {column.sort && (
                <Box display={'grid'} alignContent={'center'}>
                  <ArrowDropUp
                    htmlColor={
                      orderBy === column.id && order === 'asc'
                        ? theme.palette.primary.dark
                        : '#DCE4ED'
                    }
                  />
                  <ArrowDropDown
                    sx={{ marginTop: '-18px' }}
                    htmlColor={
                      orderBy === column.id && order !== 'asc'
                        ? theme.palette.primary.dark
                        : '#DCE4ED'
                    }
                  />
                </Box>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
