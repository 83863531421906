import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { ApnAdditionalSettingsFormItem } from './ApnAdditionalSettingsFormItem';

export function ApnSettingsAdditionalInfo(props) {
  const {
    control,
    formState: { dirtyFields },
  } = useFormContext();

  const { fields, remove, prepend, update } = useFieldArray({
    control,
    name: 'apnAdditionalInfoItemList',
  });

  const mccVal = useWatch({ name: 'mcc', control });
  const mncVal = useWatch({ name: 'mnc', control });

  useEffect(() => {
    if (!(dirtyFields?.mcc || dirtyFields?.mnc)) return;
    //if (!mccVal || !mncVal) return;
    // @ts-ignore
    const numericIndex = fields.findIndex((item) => item?.apnAdditionalInfoKey === 'numeric');
    if (numericIndex < 0) {
      prepend({
        apnAdditionalInfoKey: 'numeric',
        type: 'NUMBER',
        apnAdditionalInfoValue: '' + mccVal + mncVal,
      });
    } else {
      update(numericIndex, {
        apnAdditionalInfoKey: 'numeric',
        type: 'NUMBER',
        apnAdditionalInfoValue: '' + mccVal + mncVal,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mccVal, mncVal, prepend, update]);

  return (
    <Box mt="30px">
      <Box display="flex" justifyContent="space-between" m="10px">
        <Typography variant="body2">Additional Information</Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddOutlined />}
          onClick={() =>
            prepend({ apnAdditionalInfoKey: '', type: 'NUMBER', apnAdditionalInfoValue: '' })
          }
        >
          Additional Parameter
        </Button>
      </Box>
      <Divider />
      <Box mb="15px">
        {fields.map((itemField, fieldIndex) => (
          <Box
            key={itemField.id}
            borderLeft="3px solid #608AC3"
            pt="5px"
            pb="5px"
            pl="10px"
            mb="10px"
          >
            <ApnAdditionalSettingsFormItem remove={remove} fieldIndex={fieldIndex} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
