import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const faceLoginSettings = [
  {
    title: 'Face Login',
    key: GROUP_SETTINGS_CATEGORIES.FACE_LOGIN,
    children: [
      {
        label: 'Face Login',
        key: 'isFaceLoginEnabled',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Face Login Timeout',
        key: 'faceLoginTimeout',
        type: FROM_TYPES.INPUT,
        postText: 'Milliseconds',
        min: 0,
        max: 1800000,
        inputType: 'number',
        isDisabled: (watch) => watch('isFaceLoginEnabled') === false,
      },
    ],
  },
];
