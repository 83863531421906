import { ComponentNode } from './ComponentNode';
import { ComponentNodeContainer } from './ComponentNodeContainer';

/**
 * @typedef {object} ComponentTreeItemProps
 * @property {{[key: string]: AIModelV0[]}} modelGroup
 * @property {string} [modelId]
 * @property {string} [selected]
 * @property {string} [adding]
 * @property {(value: string, add: boolean) => any} [onSelect]
 */

/** @param {ComponentTreeItemProps} props */
export function ComponentNodeTreeItem(props) {
  const { modelGroup, modelId = null, selected, adding, onSelect } = props;
  return (
    <>
      {modelGroup[modelId || '']?.map((model, index, list) => (
        <ComponentNodeContainer key={model.modelId} last={index === list.length - 1}>
          <ComponentNode
            label={`${model.name} (${model.version})`}
            adding={adding === model.modelId}
            selected={selected === model.modelId}
            onSelect={onSelect ? (add) => onSelect(model.modelId, add) : null}
          />
          <ComponentNodeTreeItem
            adding={adding}
            selected={selected}
            modelGroup={modelGroup}
            modelId={model.modelId}
            onSelect={onSelect}
          />
        </ComponentNodeContainer>
      ))}
    </>
  );
}
