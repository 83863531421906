/**
 *  Filter AI Model Input Source Data while Submitting
 * @param {Array<object>} data
 * @returns
 */

export const filterAIModelsInputData = (data) => {
  const videoKeys = [
    'resolution',
    'colorType',
    'bitDepth',
    'fov',
    'fps',
    'bitrate',
    'codec',
    'depthSensor',
    'fovX',
    'fovY',
  ];

  const audioKeys = ['sampleRate', 'quantization', 'channel', 'audioCodec', 'audioType'];

  const sensorKeys = ['sensorType', 'unit'];

  data.forEach((item) => {
    if (item.inputType === 'VIDEO') {
      audioKeys.forEach((e) => item[e] && delete item[e]);
      sensorKeys.forEach((e) => item[e] && delete item[e]);
    }
    if (item.inputType === 'AUDIO') {
      videoKeys.forEach((e) => item[e] && delete item[e]);
      sensorKeys.forEach((e) => item[e] && delete item[e]);
    }
    if (item.inputType === 'SENSOR') {
      videoKeys.forEach((e) => item[e] && delete item[e]);
      audioKeys.forEach((e) => item[e] && delete item[e]);
    }
    item.fov = `${item.fovX}x${item.fovY}`;
  });
  return data;
};
