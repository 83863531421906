import { formatTimestamp } from '@/utils/datetime';
import { LongTextShortener } from '@/web/@components/LongTextShortener';

/**
 * @type {Array<TableColumn<EndpointInfoAggregated & import("@/assets/ota")['FIRMWARE_TYPES']>>}
 */
export const CAMERA_TABLE_COLUMNS = [
  {
    id: 'deviceLabel',
    label: 'Camera name',
    align: 'left',
    isPrimary: true,
    searchable: true,
    width: '300px',
    format: (value) => <LongTextShortener text={value} length={40} />,
    link: (row) => `/cameras/${row.endpointId}`,
  },
  {
    id: 'deviceSerialNo',
    label: 'Serial number',
    align: 'left',
    searchable: true,
    format: (value) => <LongTextShortener text={value} length={15} />,
  },
  {
    id: 'lastOnlineTime',
    label: 'Last online',
    align: 'left',
    width: '250px',
    searchable: true,
    format: (value, row) => (value && Number(value) !== 0 ? formatTimestamp(value) : 'Online'),
    sort: true,
  },
  {
    id: 'APPLICATION_PACKAGE',
    label: 'Camera Firmware',
    align: 'left',
    searchable: true,
    sort: true,
    width: '200px',
  },
  {
    id: 'SYSTEM_IMAGE',
    label: 'System Image',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'OEM_IMAGE',
    label: 'OEM Image',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'CAN_BUS_FW',
    label: 'CAN Bus Firmware ',
    align: 'left',
    searchable: true,
    sort: true,
    width: '200px',
  },
  {
    id: 'SUPPORTING_APPLICATION',
    label: 'Supporting Application',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'SMART_CABLE_FW',
    label: 'Smart Cable',
    align: 'left',
    searchable: true,
    sort: true,
  },
];
