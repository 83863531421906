import { AI_MODEL_INPUT_TYPE_OPTIONS } from '@/assets/ai/ai-model';
import { BoxImage } from '@/web/@components/BoxImage';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { groupBy } from 'lodash';
import { getIconBySourceType } from './utils';

/**
 * @typedef {object} OutputSourceSelectionDialogProps
 * @property {string} type
 * @property {ComponentOutputSource[]} outputSources
 * @property {(value: ComponentOutputSource) => any} onSelect
 */

/** @param {OutputSourceSelectionDialogProps} props */
export function OutputSourceSelectionDialog(props) {
  const { outputSources, type: inputType, onSelect } = props;
  return (
    <Dialog open={true} onClose={() => onSelect(null)} maxWidth="sm">
      <DialogTitle py={0} display="flex" alignItems="center" justifyContent="space-between">
        <Typography>Add Imager or Sensor</Typography>
        <IconButton onClick={() => onSelect(null)} sx={{ p: 1 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ minWidth: '200px' }}>
        {!outputSources?.length ? (
          <IconMessageBox message="No output source available" icon="info" size="48px" mb={2} />
        ) : (
          Object.entries(groupBy(outputSources, 'type')).map(([type, sources]) => (
            <Box key={type} mb={2}>
              <Typography variant="body2" mb={1}>
                {AI_MODEL_INPUT_TYPE_OPTIONS.find((x) => x.value === type)?.label || type}
              </Typography>
              <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
                {sources.map((source) => (
                  <Button
                    key={source.code}
                    onClick={() => onSelect(source)}
                    disabled={inputType !== source.type}
                    sx={{
                      borderRadius: 0,
                      p: 1.5,
                      gap: 1,
                      width: '140px',
                      height: '120px',
                      bgcolor: '#F2F7FC',
                      flexDirection: 'column',
                    }}
                  >
                    <BoxImage
                      src={getIconBySourceType(type)}
                      size="32px"
                      style={{ opacity: inputType !== source.type ? 0.5 : 1 }}
                    />
                    <Typography noWrap fontSize="0.75rem" width="100%" textOverflow="ellipsis">
                      {source.name}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Box>
          ))
        )}
      </DialogContent>
    </Dialog>
  );
}
