import api from '@/api';
import { store } from '@/store';
import {
  selectMainAccessToken,
  selectMainTenantId,
  selectMainTenantName,
  selectMainTokenExpiry,
  selectUserEmail,
  selectUserRole,
} from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { Listenable } from '@/utils/listenable';
import { CustomLogger } from '@/utils/logger';
import { chunk, sortBy } from 'lodash';

const logger = new CustomLogger('CameraHealthFetcher');

const accessTokenCache = new SmartCache('access-token-cache');
const endpointInfoCache = new SmartCache('endpoint-info-cache', 2 * 60 * 1000);
const healthTagListCache = new SmartCache('endpoint-tag-cache', 5 * 1000);

const EXCLUDED_TENANTS = new Set([
  '1', // Smarter AI Development
  'b71a29f2-630f-4adf-bb5a-40a855c35fed', // System Load Test
  '98ed2e00-2390-4786-a49d-6e9d2d72d3e2', // Smarter AI Staging
]);

/**
 * @typedef {'started'|'finish'|'endpoints'|'tags'|'tag-list'|'tenant-list'} CameraHealthTriggers
 */

/**
 * @class
 * @augments Listenable<CameraHealthTriggers>
 */
export class CameraHealthFetcher extends Listenable {
  #aborter = new AbortController();
  /** @type {Array<{tenantId: string, tenantName: string[]}>} */
  tenants = [];
  /** @type {{[key: string]: EndpointHealthInfo}} */
  endpointInfo = {};
  /** @type {{[key: string]: Set<string>}} */
  tenantEndpoints = {};
  /** @type {{[key: string]: Set<string>}} */
  tagEndpoints = {};
  /** @type {{[key: string]: string}} */
  thumbnails = {};
  /** @type {Array<HealthTagResponse>} */
  tags = [];
  /** @type {{[id: string]: GetAccessTokenResponse}} */
  tokenMap = {};

  get loading() {
    return Boolean(this.#aborter);
  }

  abort() {
    this.#aborter?.abort();
  }

  async process() {
    try {
      this.abort();
      endpointInfoCache.clear();
      this.#aborter = new AbortController();
      this.tenants = [];
      this.endpointInfo = {};
      this.tenantEndpoints = {};
      this.tagEndpoints = {};
      this.tags = [];
      this.$invoke('started');

      let tree;
      const state = store.getState();
      if (['SUPER_ADMIN', 'SUPPORT'].includes(selectUserRole(state))) {
        const request = api.ac.v5.tenant.descendants.$get({
          headers: {
            Authorization: selectMainAccessToken(state),
          },
        });
        tree = await request.process();
        if (!tree) return;
      } else {
        tree = {};
      }
      tree.tenantId ||= selectMainTenantId(state);
      tree.tenantName ||= selectMainTenantName(state);
      this.#buildTenantList(tree);
      this.tenants = sortBy(this.tenants, (x) => x.tenantName?.join('>'));
      await this.#buildTokenMap();
      this.$invoke('tenant-list');

      await this.#fetchTagList();
      this.$invoke('tag-list');

      for (const tenants of chunk(this.tenants, 5)) {
        await Promise.all(tenants.map((x) => this.#fetchEndpointList(x.tenantId)));
      }
      this.$invoke('endpoints');

      for (const endpoints of chunk(Object.values(this.endpointInfo), 10)) {
        await Promise.all(endpoints.map((x) => this.#fetchEndpointTags(x)));
      }
      this.$invoke('tags');
    } catch (err) {
      console.error(err);
      this.#aborter.abort();
    } finally {
      logger.info(this);
      this.#aborter = null;
      this.$invoke('finish');
    }
  }

  /**
   * Fetch tenant id
   * @param {string} tenantId
   * @returns {Promise<string>}
   */
  getTenantToken = async (tenantId) => {
    if (!this.tenants.find((x) => x.tenantId === tenantId)) {
      throw new Error('Not authorized');
    }
    // check cache
    const state = store.getState();
    const mainTenantId = selectMainTenantId(state);
    const _cacheKey = mainTenantId + '_' + tenantId;
    const _cache = await accessTokenCache.getItem(_cacheKey);
    if (_cache?.accessToken) {
      if (_cache.accessTokenExpiry + 60 * 1000 > Date.now()) {
        return _cache.accessToken;
      }
    }
    logger.debug('Getting tenant access token for', tenantId);
    /** @type {{accessToken?: string, accessTokenExpiry?: number}} */
    let result;
    if (mainTenantId === tenantId) {
      // use current
      result = {
        accessToken: selectMainAccessToken(state),
        accessTokenExpiry: selectMainTokenExpiry(state),
      };
    } else {
      // fetch new
      const email = selectUserEmail(state);
      const token = selectMainAccessToken(state);
      const request = api.ac.v5.auth['tenant-access'].$post({
        signal: this.#aborter?.signal,
        data: {
          email,
          token,
          tenantId,
          virtualLogin: true,
        },
      });
      result = await request.process();
    }
    await accessTokenCache.setItem(_cacheKey, result); // update cache
    return result.accessToken;
  };

  /**
   * Add health tag to endpoint
   * @param {EndpointHealthInfo} endpoint
   * @param {number} tagId
   */
  addTag = async (endpoint, tagId) => {
    const secretToken = await this.getTenantToken(endpoint.tenantId);
    logger.debug('Assign health tag', endpoint.endpointId, tagId);
    const request = api.ac.v5.health.tag
      .$tagId(tagId)
      .endpoint.$endpointId(Number(endpoint.endpointId))
      .$post({
        params: {
          tenantId: endpoint.tenantId,
        },
        data: {
          resolution: 'NULL',
          healthTagReportType: 'CAMERA',
          resourceId: endpoint.endpointId,
          healthTagReportSubtype: '',
          comment: '',
        },
        headers: {
          Authorization: secretToken,
        },
      });
    const result = await request.process();
    endpoint.tags = [...(endpoint.tags || []), result];
    this.tagEndpoints[tagId] ||= new Set();
    this.tagEndpoints[tagId].add(endpoint.endpointId);
    await healthTagListCache.setItem(endpoint.endpointId, endpoint.tags);
    this.$invoke('endpoints');
    this.$invoke('tags');
  };

  /**
   * Remove health tag to endpoint
   * @param {EndpointHealthInfo} endpoint
   * @param {number} tagId
   */
  removeTag = async (endpoint, tagId) => {
    const secretToken = await this.getTenantToken(endpoint.tenantId);
    logger.debug('Remove health tag', endpoint.endpointId, tagId);
    for (const tag of endpoint.tags.filter((x) => x.tagId === tagId)) {
      const request = api.ac.v5.health.tenant
        .$tenantId(endpoint.tenantId)
        .endpoint.$endpointId(Number(endpoint.endpointId))
        ['assigned-health-tag'].$id(tag.id)
        .$delete({
          headers: {
            Authorization: secretToken,
          },
        });
      request.process().catch(console.error);
    }
    this.tagEndpoints[tagId]?.delete(endpoint.endpointId);
    endpoint.tags = endpoint.tags?.filter((x) => x.tagId !== tagId);
    await healthTagListCache.setItem(endpoint.endpointId, endpoint.tags);
    this.$invoke('endpoints');
    this.$invoke('tags');
  };

  /**
   * Build the tenant list by root of the tree
   * @param {DescendantTenant} root
   * @param {string[]} [parents]
   */
  #buildTenantList = (root, parents = []) => {
    if (!root) return;
    parents = [...parents, root.tenantName];
    if (!EXCLUDED_TENANTS.has(root.tenantId)) {
      this.tenants.push({
        tenantId: root.tenantId,
        tenantName: parents,
      });
    }
    for (const tenant of root?.descendantTenantList || []) {
      this.#buildTenantList(tenant, parents);
    }
  };

  /**
   * Build the tenant access token map
   */
  #buildTokenMap = async () => {
    const state = store.getState();
    const email = selectUserEmail(state);
    const token = selectMainAccessToken(state);
    const mainTenantId = selectMainTenantId(state);
    const request = api.ac.v5.auth['tenant-access'].batch.$post({
      signal: this.#aborter?.signal,
      data: this.tenants.slice(1).map((x) => ({
        email,
        token,
        virtualLogin: true,
        tenantId: x.tenantId,
      })),
    });
    const result = await request.process();
    for (const token of result.accessTokenResponses) {
      const _cacheKey = mainTenantId + '_' + token.tenantId;
      await accessTokenCache.setItem(_cacheKey, token);
    }
  };

  /**
   * Fetch list of all available tags
   */
  #fetchTagList = async () => {
    const state = store.getState();
    const tenantId = selectMainTenantId(state);
    const secretToken = selectMainAccessToken(state);

    logger.debug('Getting tag list by', tenantId);
    const request = api.ac.v5.health.tag.$get({
      signal: this.#aborter?.signal,
      headers: {
        Authorization: secretToken,
      },
    });
    const result = await request.process();
    this.tags = result;
  };

  /**
   * Fetch tenant specific data
   * @param {string} tenantId
   */
  #fetchEndpointList = async (tenantId) => {
    /** @type {EndpointInfoAggregated[]} */
    let endpoints = [];

    // check cache
    const _cached = await endpointInfoCache.getItem(tenantId);
    if (_cached) {
      endpoints = _cached;
    } else {
      // fetch latest
      let offset = 0;
      const limit = 100;
      const secretToken = await this.getTenantToken(tenantId);
      logger.debug('Getting endpoint list for', tenantId);
      while (!this.#aborter?.signal?.aborted) {
        const request = api.ac.v5.endpoint.list.$get({
          signal: this.#aborter?.signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            status: 'ACTIVE',
            type: 'DEVICE',
            tenantId,
            offset,
            limit,
          },
        });
        const result = await request.process();
        const items = result.endpointInfoList;
        endpoints.push(...items);
        if (items.length < limit) break;
        offset += limit;
      }
    }

    this.tenantEndpoints[tenantId] ||= new Set();
    for (const endpoint of endpoints) {
      /** @type {EndpointHealthInfo} */
      const item = {
        tags: null,
        groupId: 0,
        ...endpoint,
        tenantId,
      };
      this.endpointInfo[item.endpointId] = item;
      this.tenantEndpoints[tenantId].add(item.endpointId);
    }

    // cache
    await endpointInfoCache.setItem(tenantId, endpoints);
  };

  /**
   * Fetch health tags of an endpoint
   * @param {EndpointHealthInfo} endpoint
   */
  #fetchEndpointTags = async (endpoint) => {
    /** @type {AssignedHealthTagResponse[]} */
    let tags = await healthTagListCache.getItem(endpoint.endpointId);
    if (!tags?.length) {
      tags = [];
      let offset = 0;
      const limit = 50;
      const secretToken = await this.getTenantToken(endpoint.tenantId);
      logger.debug('Fetch health tags for', endpoint.deviceLabel);
      while (!this.#aborter?.signal?.aborted) {
        const request = api.ac.v5.health.tenant
          .$tenantId(endpoint.tenantId)
          ['assigned-health-tag'].$get({
            signal: this.#aborter?.signal,
            params: {
              endpointId: endpoint.endpointId,
              offset,
              limit,
            },
            headers: {
              Authorization: secretToken,
            },
          });
        const result = await request.process();
        tags.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      await healthTagListCache.setItem(endpoint.endpointId, tags);
    }
    endpoint.tags = tags;
    for (const tag of endpoint.tags) {
      this.tagEndpoints[tag.tagId] ||= new Set();
      this.tagEndpoints[tag.tagId].add(endpoint.endpointId);
    }
  };
}
