import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ApkFileForm } from '../../@components/ApkForm';
import { ApkVersionHistory } from '../../@components/VersionHistory';

export function ApkFileDetailsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: apkId } = useParams();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<FirmwareDto>} */
  const [apkDetails, setApkDetails] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [showVersion, setShowVersion] = useState(true);
  const [apkVersions, setApkVersions] = useState([]);

  useEffect(() => {
    if (!apkId) {
      navigate('/administration/apk-files');
      return;
    }

    const fetchApkDetails = async () => {
      try {
        setLoading(true);
        // Fetch APK Details
        const apkDetails = await api.ac.v5.firmware
          .$firmwareId(apkId)
          .$get({
            headers: { Authorization: secretToken },
          })
          .process();
        setApkDetails(apkDetails);

        // Fetch APK Versions
        const apkVersions = await api.ac.v5.firmware
          .$firmwareId(apkId)
          .versions.$get({
            headers: { Authorization: secretToken },
          })
          .process();
        setApkVersions(apkVersions?.firmwaresVersions);
      } catch (error) {
        navigate('/administration/apk-files');
      } finally {
        setLoading(false);
      }
    };

    fetchApkDetails();
  }, [apkId, secretToken, navigate, tenantId]);

  useEffect(() => {
    if (!apkDetails?.name) return;
    setBreadcrumbTitle(apkDetails?.name);
  }, [apkDetails?.name, setBreadcrumbTitle]);

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['apk.files.details.page.title'])}
      </Typography>

      {loading ? (
        <CenterBox fullView>
          <CircularProgress />
        </CenterBox>
      ) : (
        <>
          <ApkFileForm apkId={apkId} apkDetails={apkDetails} onSubmit={null} isReadOnly={true} />

          {apkVersions?.length > 0 && (
            <Box>
              <Typography
                color={'#7a9fd2'}
                mt={'-25px'}
                onClick={() => {
                  setShowVersion((prev) => !prev);
                }}
                sx={{ fontSize: '0.875rem', color: '#4177BF', cursor: 'pointer' }}
              >
                Version History
              </Typography>
              {showVersion && <ApkVersionHistory versions={apkVersions} />}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
