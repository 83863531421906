import api from '@/api';
import { T } from '@/assets/locales';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DriverTagForm } from './@components/DriverTagForm';
import { ACTION_ITEMS } from './actions';
import { DRIVER_TAG_TABLE_COLUMNS } from './columns';

export function DriverTagList() {
  const secretToken = useSelector(selectSecretToken);
  const { t } = useTranslation();

  const [createModal, setCreateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<DriverTag>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    if (type === 'DISCONTINUE') {
      await deleteDriverTags(selectedItems);
      fetchDriverTags();
    } else if (type === 'CREATE') {
      setCreateModal(true);
    } else if (type === 'MODIFY') {
      setCreateModal(true);
      setSelectedItem(selectedItems[0]);
    }
  };

  const deleteDriverTag = async (item) => {
    try {
      const request = api.ac.v5['driver-tag'].$id(item.id).$delete({
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
      });
      await request.process();
      //toastSuccess('Success', 'Deleted the driver tag');
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not delete the driver tag');
    }
  };

  const deleteDriverTags = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteDriverTag));
  };

  const handelOnModalHide = () => {
    setCreateModal(false);
    setSelectedItem(null);
  };

  const {
    result,
    loading,
    error,
    retry: fetchDriverTags,
  } = useFutureLoader(async ({ signal, secretToken, tenantId }) => {
    /** @type {Array<DriverTag>} */
    const results = [];
    const pageSize = 20;
    let afterName = null;
    while (true) {
      const request = api.ac.v5['driver-tag'].$get({
        signal,
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
        params: {
          pageSize,
          tenantId,
          afterName,
        },
      });
      await request.process();
      const result = request.result.driverTags;
      results.push(...result);
      if (result?.length < pageSize) {
        break;
      }
      afterName = results[results?.length - 1]?.name;
    }
    return results;
  }, []);

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Driver tags');
  }

  return (
    <Box mx={2.5} mt={2}>
      {createModal && (
        <DriverTagForm
          item={selectedItem}
          requestRefresh={fetchDriverTags}
          onHide={handelOnModalHide}
        />
      )}
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={t(T['driver.tags.page.title'])}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <ContinuationTokenTable
          results={result}
          loading={loading}
          columns={DRIVER_TAG_TABLE_COLUMNS}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
