import { FeatureAccessView } from '.';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <FeatureAccessView />,
  },
];

export default routes;
