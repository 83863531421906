import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { Button, Grid, Typography } from '@mui/material';

export function GuideAppInstallationArea() {
  return (
    <CenterBox
      id="app-installation-area"
      px={2}
      py={{ xs: 2, md: 3, xl: 6 }}
      gap={{ xs: 1, md: 1, xl: 2 }}
      height="calc(100vh - 180px)"
      style={{ background: 'linear-gradient(180deg, #EAF3FF 0%, #fff 50%, #fff 100%)' }}
    >
      <Typography fontSize="2rem" fontWeight="500" lineHeight="2.5rem">
        Smarter AI Dashcam Quick Start Guide
      </Typography>
      <Typography fontSize="1rem" fontWeight="500">
        The Smarter AI Dashcam is a connected fleet dashcam with precision AI for trusted data and
        decisions.
      </Typography>
      <BoxImage src="/images/guide/ Smarter-AI-Dashcam-image.png" height="375px" width="100%" />
      <Typography fontSize="1.375rem" fontWeight="600">
        Smarter AI Mobile Application
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'right' }}>
          <Button
            target="_blank"
            href="https://smarterai.camera/app/ios"
            sx={{ p: 0, borderRadius: '10px' }}
          >
            <BoxImage src="/images/guide/app-store.svg" height="60px" />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'left' }}>
          <Button
            target="_blank"
            href="https://smarterai.camera/app/android"
            sx={{ p: 0, borderRadius: '10px' }}
          >
            <BoxImage src="/images/guide/google-play.svg" height="60px" />
          </Button>
        </Grid>
      </Grid>
    </CenterBox>
  );
}
