import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from './caching/smart-cache';

const aiLabelCache = new SmartCache(`ai-labels`, 2 * 24 * 3600 * 1000);

/** @type {{[key: string]: string}} */
export const AI_LABEL_NAME = {};

/** @type {{[key: string]: TriggerClassification}} */
export const AI_LABEL_DATA = {};

/** @type {Array<TriggerClassification>} */
export let AI_LABEL_RAW_DATA = [];

/**
 * @param {AbortSignal} [signal]
 * @returns {Promise<Array<TriggerClassification>>}
 */
export async function fetchAILabels(signal) {
  const state = store.getState();
  const tenantId = selectTenantId(state);
  const secretToken = selectSecretToken(state);

  /** @type {Array<TriggerClassification>} */
  const cached = await aiLabelCache.getItem(tenantId);
  const lastTenant = await aiLabelCache.getItem('last-tenant');
  if (lastTenant === tenantId && cached) return cached;

  /** @type {Array<TriggerClassification>} */
  const results = [];
  let conToken = '';
  const limit = 500;
  while (!signal?.aborted) {
    const request = api.ac.v1.tenant.trigger.classifications.$get({
      signal,
      params: {
        secretToken,
        pageSize: limit,
        continuationToken: conToken,
        tenantId,
      },
    });
    await request.process();
    const result = request.result.data;
    conToken = request.result.continuationToken;
    const filteredData = result.filter((item) => item.publicationStatus !== 'DISCONTINUED');
    results.push(...filteredData);
    if (result.length < limit) break;
  }
  aiLabelCache.setItem(tenantId, results);
  aiLabelCache.setItem('last-tenant', tenantId);
  return results;
}

/**
 * Checks if the input string has a partial match with the keywords "PITCH", "YAW", or "ROLL".
 * @param {string} inputString
 * @returns {boolean}
 */
function toExclude(inputString) {
  if (!inputString) return false;
  inputString = inputString.toUpperCase();
  if (inputString.includes('YAWNING')) {
    return false;
  }
  const keywords = ['PITCH', 'YAW', 'ROLL', 'EYE'];
  for (const keyword of keywords) {
    if (inputString.includes(keyword)) {
      return true;
    }
  }
  return false;
}

/**
 * @param {Array<TriggerClassification>} labels
 */
export function buildAILabelMapping(labels) {
  AI_LABEL_RAW_DATA = labels;
  for (const label of labels) {
    if (toExclude(label.name)) continue;
    AI_LABEL_NAME[label.name] = label.displayName;
    AI_LABEL_DATA[label.name] = label;
  }
}
