import api from '@/api';
import { buildProductProperties } from '@/hooks/useFullProductList';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

/**
 * @typedef {object} AiContainerDetailsContextData
 * @property {AIContainerV2} container
 * @property {import('@/types').ProductProperties} product
 * @property {AIModelV0[]} models
 * @property {() => any} refreshContainer
 * @property {() => any} refreshModels
 */

/** @type {import("react").Context<AiContainerDetailsContextData>} */
export const AiContainerDetailsContext = createContext(null);

export function AiContainerDetailsLayout() {
  const params = useParams();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  const containerId = useMemo(() => params.id, [params]);

  const [refreshId, setRefreshId] = useState(0);
  const [modelRefreshId, setModelRefreshId] = useState(0);

  const [container, setContainer] = useState(null);
  const [containerError, setContainerError] = useState(null);
  const [containerLoading, setContainerLoading] = useState(true);

  const [product, setProduct] = useState(null);
  const [productError, setProductError] = useState(null);
  const [productLoading, setProductLoading] = useState(false);

  const [models, setModels] = useState([]);
  // const [modelsError, setModelsError] = useState(null);
  // const [modelsLoading, setModelsLoading] = useState(true);

  useEffect(() => {
    if (!containerId) return;
    const request = api.ac.v2.repository.aicontainer.$get({
      params: {
        containerId,
        secretToken,
      },
    });

    setContainerError(null);
    setContainerLoading(true);
    request
      .process()
      .then((result) => {
        setContainer(result);
        setBreadcrumbTitle(
          result.name,
          (path) => path === `/administration/ai/ai-containers/${result.containerId}`
        );
      })
      .catch(setContainerError)
      .finally(() => setContainerLoading(false));
  }, [refreshId, containerId, secretToken, setBreadcrumbTitle]);

  useEffect(() => {
    if (!container?.productId) return;
    const request = api.ac.v1.tenant.product.$get({
      params: {
        productId: container.productId,
        secretToken,
        tenantId,
      },
    });

    setProductError(null);
    setProductLoading(true);
    request
      .process()
      .then(buildProductProperties)
      .then(setProduct)
      .catch(setProductError)
      .finally(() => setProductLoading(false));
  }, [container?.productId, tenantId, secretToken, setBreadcrumbTitle]);

  useEffect(() => {
    if (!containerId) return;
    const aborter = new AbortController();
    (async () => {
      const pageSize = 20;
      let continuationToken = null;
      /** @type {AIModelV0[]} */
      const results = [];
      while (!aborter.signal.aborted) {
        const request = api.ac.v2.repository.aicontainer.aimodel.$get({
          params: {
            pageSize,
            containerId,
            secretToken,
            continuationToken,
          },
        });
        const result = await request.process();
        results.push(...result.data);
        continuationToken = result.continuationToken;
        if (!continuationToken?.trim() || result.data.length < pageSize) break;
      }
      results.sort((a, b) => {
        return (
          -a.publicationStatus.localeCompare(b.publicationStatus) ||
          a.name.localeCompare(b.name) ||
          -a.version.localeCompare(b.version)
        );
      });
      setModels(results);
    })().catch(() => setModels([]));
  }, [modelRefreshId, containerId, secretToken]);

  const refreshContainer = useCallback(() => {
    setRefreshId((prev) => prev + 1);
  }, []);

  const refreshModels = useCallback(() => {
    setModelRefreshId((prev) => prev + 1);
  }, []);

  if (containerError) {
    throw new KnownUserError('Failed to fetch AI Container');
  }

  if (productError) {
    throw new KnownUserError('Failed to fetch AI Container product');
  }

  if (productLoading || containerLoading || !container || !product) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <AiContainerDetailsContext.Provider
      value={{
        container,
        models,
        product,
        refreshContainer,
        refreshModels,
      }}
    >
      <Outlet />
    </AiContainerDetailsContext.Provider>
  );
}
