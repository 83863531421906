import { CameraDetailsArea } from '@/web/cameras/_cameraId/@components/CameraDetailsArea';
import { Box } from '@mui/material';
import { ManageCameraCardItems } from '../@components/ManageCameraCardItems';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';

export function ManageCameraDetailsPage() {
  return (
    <Box display="grid" p={2} pb={5} rowGap={5}>
      <HubConnectionProvider>
        <CameraDetailsArea />
      </HubConnectionProvider>

      <ManageCameraCardItems />
    </Box>
  );
}
