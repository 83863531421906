import { T } from '@/assets/locales';
import { useV5ProductList } from '@/hooks/useFullProductList';
import { trimString } from '@/utils/formatting';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ApkFileUpload } from '../ApkFileUpload';

/**
 * @typedef {object} TypeProps
 * @property {string} [label]
 * @property {string} [value]
 */

/**
 * @typedef {object} DbcFileFormFields
 * @property {File} apkFile
 * @property {string} apkId
 * @property {string} name
 * @property {string} version
 * @property {string} storedFileNamePrev
 * @property {Array<any>} apkProducts
 * @property {string} description
 * @property {string} notes
 * @property {TypeProps} type
 */

/**
 * @typedef {object} ApkFileFormProps
 * @property {boolean} [disabled]
 * @property {string} [apkId]
 * @property {FirmwareDto} [apkDetails]
 * @property {boolean} [isReadOnly]
 * @property {import('react-hook-form').SubmitHandler<DbcFileFormFields>} onSubmit
 */

/**
 * @param {ApkFileFormProps} props
 */
export function ApkFileForm(props) {
  const { apkId, onSubmit, disabled = false, apkDetails, isReadOnly = false } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { result: products, loading: productLoading } = useV5ProductList();

  /** @type {import('react-hook-form').UseFormReturn<DbcFileFormFields>} */
  const form = useForm({
    mode: 'all',
    defaultValues: {
      apkId: apkId ?? uuidv4(),
      name: '',
      version: '',
      description: '',
      apkProducts: [],
      type: null,
      apkFile: null,
      storedFileNamePrev: '',
      notes: '',
    },
  });

  const { control } = form;
  const isNewFileUpload = useWatch({ control, name: `apkFile` });

  const handleCancel = () => {
    navigate('/administration/apk-files');
  };

  /** @param {File} file */
  const handleDbcFileFileUpload = async (file) => {
    form.setValue('apkFile', file);
  };

  /** @param {DbcFileFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmit) return;
    data.name = trimString(data.name);
    data.description = trimString(data.description);
    data.notes = trimString(data.notes);
    return onSubmit(data);
  };

  useEffect(() => {
    if (!apkDetails) return;
    form.setValue('apkId', apkDetails.firmwareId);
    form.setValue('name', apkDetails.name);
    form.setValue('version', apkDetails.version);
    form.setValue('description', apkDetails.description);
    form.setValue('storedFileNamePrev', apkDetails.actualFileName);
    form.setValue('notes', apkDetails.uploadNotes);
  }, [apkDetails, form]);

  return (
    <FormProvider {...form}>
      {!isReadOnly ? (
        <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
          APK File
        </Typography>
      ) : (
        <Typography
          variant="body2"
          fontSize="1rem"
          mt={'10px'}
          mb={'5px'}
          fontWeight={500}
        ></Typography>
      )}
      <Divider />

      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={4}>
            <CustomInput
              disabled={!!apkId}
              name="name"
              label={isReadOnly ? 'Name' : 'Name *'}
              placeholder="Enter a APK File name"
              rules={{ required: 'Name is required' }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomInput
              disabled={isReadOnly}
              name="version"
              label={isReadOnly ? 'Version' : 'Version *'}
              placeholder="Enter a version"
              rules={{
                required: 'Version is required',
                validate: (value) =>
                  value !== apkDetails?.version ||
                  'New version must be different from the previous version',
              }}
            />
          </Grid>

          {!apkId && (
            <Grid item xs={12} md={4}>
              <CustomSelect
                disabled={isReadOnly}
                name="apkProducts"
                label={t(T['device-group.form.select.product.label'])}
                loading={productLoading}
                options={products}
                getKey="id"
                getLabel="name"
                placeholder={t(T['device-group.form.select.product.placeholder'])}
                rules={{ required: 'Product is required' }}
                AutocompleteProps={{
                  multiple: true,
                  forcePopupIcon: false,
                  limitTags: 1,
                  loadingText: 'Fetching Products...',
                  disableClearable: true,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <CustomInput
              disabled={isReadOnly}
              name="description"
              label="Description "
              placeholder="Enter a description"
            />
          </Grid>
        </Grid>

        <ApkFileUpload
          isReadOnly={isReadOnly}
          name="apkFile"
          apkId={apkDetails?.firmwareId}
          onDropFile={handleDbcFileFileUpload}
          label={isReadOnly ? 'Upload File' : 'Upload File *'}
          rules={{ required: 'This is required' }}
        />

        {!isReadOnly && (
          <CustomInput name="notes" label="Upload Notes " placeholder="Enter upload notes" />
        )}

        {!isReadOnly && (
          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={5}>
            <Button variant="text" onClick={handleCancel} size="small">
              <Typography fontSize={'0.875rem'}> {t(T['button.cancel'])}</Typography>
            </Button>
            <Button
              disabled={disabled || !isNewFileUpload}
              variant="contained"
              size="small"
              type="submit"
            >
              {disabled && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              <Typography fontSize={'0.875rem'}>
                {apkDetails ? t(T['button.update']) : t(T['button.save'])}
              </Typography>
            </Button>
          </Box>
        )}
      </form>
      <Box height="30px" />
    </FormProvider>
  );
}
