import { COUNTRY_CODES } from '@/assets/constants/country-codes';
import { startCase } from 'lodash';

/**
 * Get Country Flag By country name
 * @param {string} countryName country name
 * @returns {string} an image url
 */
export const getCountryFlagIconByCountryName = (countryName) => {
  return getCountryFlagUrlByCode(COUNTRY_CODES[countryName]);
};

/**
 * Country Flag URL by country code
 * @param {string} code
 * @returns {string} an image url
 */

export function getCountryFlagUrlByCode(code) {
  if (!code) return '';
  return `https://flagcdn.com/h20/${code?.toLowerCase()}.png`;
}

/**
 * Country Flag URL by country code
 * @param {string} code
 * @returns {string} an image url
 */

export function getCountryNameByCode(code) {
  if (!code) return '';
  return (
    Object.keys(COUNTRY_CODES).find((key) => COUNTRY_CODES[key] === code) ||
    startCase(code?.toLowerCase())
  );
}

/**
 * Country Region Name by code
 * @param {string} code
 * @returns {string} an image url
 */

export const formatRegionName = (code) => {
  const countryName = getCountryNameByCode(code);
  if (!countryName) {
    return code === 'LEGACY' ? 'Legacy' : code;
  }
  return countryName;
};
