import { trimString } from '@/utils/formatting';
import qs from 'qs';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {object} FilterParams
 * @property {string} [deviceName]
 * @property {string} [deviceSerialNumber]
 * @property {string} [triggerName]
 * @property {string} [annotationName]
 */

/** @type {FilterParams} */
const defaultValues = {
  deviceName: '',
  deviceSerialNumber: '',
  triggerName: '',
  annotationName: '',
};

const paramAlias = {
  deviceName: 'camera',
  deviceSerialNumber: 'serial',
  triggerName: 'trigger',
  annotationName: 'annotation',
};

/** @type {import("react").Context<FilterParams>} */
export const EventFilterContext = createContext(null);

/** @type {import("react").Context<(params: FilterParams, replace?: boolean) => any>} */
export const SetEventFilterContext = createContext(null);

/** @param {{children: import("react").ReactNode}} props */
function SetEventFilterContextProvider(props) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  /**
   * @type {(data: {[key: string]: any}, replace?: boolean) => any}
   */
  const setEventFilter = useCallback(
    (data, replace = false) => {
      let params = {};
      for (const key of Object.keys(data)) {
        if (paramAlias.hasOwnProperty(key)) {
          params[paramAlias[key]] = data[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          params[key] = data[key];
        }
      }

      const query = qs.parse(location.search.substring(1));
      if (!replace) {
        params = { ...query, ...params };
      }

      for (const key of Object.keys(params)) {
        if (key in params) {
          if (!params[key]) delete params[key];
          else params[key] = trimString(params[key]);
        }
      }

      if (
        JSON.stringify(Object.entries(query).sort()) ===
        JSON.stringify(Object.entries(params).sort())
      ) {
        return;
      }

      navigate(
        {
          ...location,
          search: qs.stringify(params),
        },
        { replace: false }
      );
    },
    [location, navigate]
  );

  return (
    <SetEventFilterContext.Provider value={setEventFilter}>
      {children}
    </SetEventFilterContext.Provider>
  );
}

/** @param {{children: import("react").ReactNode, defaults?: FilterParams}} props */
export function EventFilterContextProvider(props) {
  const { children, defaults = defaultValues } = props;

  const location = useLocation();

  /** @type {StateVariable<FilterParams>} */
  const [value, setValue] = useState(defaults);

  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  /** @type {(value: any, defaultValue: string) => string} */
  const toString = (value, defaultValue) => (value || defaultValue || '') + '';

  useEffect(() => {
    const deviceName = toString(query.camera, defaults.deviceName);
    setValue((v) => (v.deviceName === deviceName ? v : { ...v, deviceName }));
  }, [query.camera, defaults]);

  useEffect(() => {
    const deviceSerialNumber = toString(query.serial, defaults.deviceSerialNumber);
    setValue((v) =>
      v.deviceSerialNumber === deviceSerialNumber ? v : { ...v, deviceSerialNumber }
    );
  }, [query.serial, defaults]);

  useEffect(() => {
    const triggerName = toString(query.trigger, defaults.triggerName);
    setValue((v) => (v.triggerName === triggerName ? v : { ...v, triggerName }));
  }, [query.trigger, defaults]);

  useEffect(() => {
    const annotationName = toString(query.annotation, defaults.annotationName);
    setValue((v) => (v.annotationName === annotationName ? v : { ...v, annotationName }));
  }, [query.annotation, defaults]);

  return (
    <SetEventFilterContextProvider>
      <EventFilterContext.Provider value={value}>{children}</EventFilterContext.Provider>
    </SetEventFilterContextProvider>
  );
}
