import { GOOGLE_MAP_API_KEY } from '@/config';
import qs from 'qs';
import { useEffect, useState } from 'react';

export function useGoogleMap() {
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<any>} */
  const [error, setError] = useState();

  useEffect(() => {
    // https://developers.google.com/maps/documentation/javascript/libraries#libraries-for-dynamic-library-import
    const lib = `https://maps.googleapis.com/maps/api/js?${qs.stringify({
      v: 'weekly',
      key: GOOGLE_MAP_API_KEY,
      callback: '__onGoogleMap__',
      libraries: 'core,maps,geometry',
    })}`;
    new Promise((resolve, reject) => {
      if (window.google?.maps?.Map) {
        return resolve();
      }
      try {
        /** @type {HTMLScriptElement} */
        let script = document.head.querySelector(`script[src="${lib}"]`);
        if (!(script instanceof HTMLScriptElement)) {
          script = document.createElement('script');
          script.src = lib;
          script.crossOrigin = 'anonymous';
          script.async = true;
          script.defer = true;
        }
        window['__onGoogleMap__'] = resolve;
        // script.addEventListener('load', resolve, { once: true });
        script.addEventListener('error', reject, { once: true });
        document.head.appendChild(script);
      } catch (err) {
        reject(err);
      }
    })
      .catch((err) => {
        setError(err);
        console.error('Google map load failed', err);
      })
      .finally(() => setLoading(false));
  }, []);

  return [loading, error];
}
