import {
  BOX_LIST_ICON,
  CAMERA_HEALTH_ON,
  CAMERA_N_OFF_ICON,
  FIRMWARES_ICON,
  NAV_REPORT_ICON_OFF,
  TENANT_ICON_OFF,
  TRIGGER_ICON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const MONITORING_ITEMS = [
  {
    name: T['monitoring.firmwares.page.card.name'],
    image: FIRMWARES_ICON,
    color: '#EFF3F7',
    countHide: true,
    link: '/smarter-ai/monitoring/firmwares/cameras',
    components: [
      {
        name: T['firmwares.page.card.browse.camera.items'],
        image: CAMERA_N_OFF_ICON,
        link: '/smarter-ai/monitoring/firmwares/cameras',
      },
      {
        name: T['firmwares.page.card.browse.tenant.items'],
        image: TENANT_ICON_OFF,
        link: '/smarter-ai/monitoring/firmwares/tenants',
      },
    ],
  },
  {
    name: T['monitoring.triggers.page.card.name'],
    image: TRIGGER_ICON,
    color: '#F4F7EF',
    countHide: true,
    accessLevel: 'SMARTER_AI_ADMIN',
    components: [
      {
        name: T['navigation.triggers.report'],
        image: NAV_REPORT_ICON_OFF,
        link: '/smarter-ai/monitoring/triggers/trigger-report',
      },
    ],
  },
  {
    name: T['camera.health.page.card.name'],
    image: CAMERA_HEALTH_ON,
    color: '#EFE7E7',
    countHide: true,
    link: '/smarter-ai/monitoring/camera-health',
    accessLevel: 'HIDDEN',
    components: [
      {
        name: T['camera.health.browse.by.tenants'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/monitoring/camera-health?group=TENANT',
      },
      {
        name: T['camera.health.browse.by.observations'],
        image: BOX_LIST_ICON,
        link: '/smarter-ai/monitoring/camera-health?group=OBSERVATION',
      },
    ],
  },
];
