import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CAMERA_PRESENCE_TABLE_COLUMNS } from './columns';

export function CameraPresencePage() {
  const { cameraId, isOnline } = useContext(CameraDetailsContext);
  const tenantId = useSelector(selectTenantId);

  const [nextTime, setNextTime] = useState(0);

  // refresh list after every 30 sec, if device is online
  useEffect(() => {
    if (!isOnline) return;
    let intervalTime = setInterval(() => {
      setNextTime((nextTime) => nextTime + 1);
    }, 30000);
    return () => clearInterval(intervalTime);
  }, [isOnline]);

  return (
    <Box mx={2.5} mt={2} mb={5}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title="Presence" />
        <InfiniteScrollView
          key={generateUniqueKey([tenantId, cameraId, nextTime])}
          initialToken={null}
          itemsPerPage={200}
          fetcher={async ({ signal, limit, token: offset }) => {
            const secretToken = selectSecretToken(store.getState());
            const request = api.ac.v3.report.device.presence.history.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                tenantId,
                secretToken,
                pageSize: limit,
                endpointId: cameraId + '',
                continuationToken: offset,
              },
            });
            await request.process();
            const result = request.result.data;
            const token = request.result.continuationToken;
            return { result, token };
          }}
          renderEmpty={() => (
            <IconMessageBox
              size="256px"
              src="/images/player/no-data-icon.svg"
              message="No Presence Found"
            />
          )}
          renderList={(state) => (
            <InfiniteScrollTableView
              state={state}
              columns={CAMERA_PRESENCE_TABLE_COLUMNS}
              sx={{ height: `calc(100vh - 150px)` }}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
