import { CameraCellularUsages } from '../@components/CameraCellularUsages';
import { CameraCpuUsages } from '../@components/CameraCpuUsages';
import { CameraBandwidthConsumptions } from '../@components/CameraBandwidthConsumptions';
import { CameraDiskUsages } from '../@components/CameraDiskUsages';
import { CameraMemoryUsages } from '../@components/CameraMemoryUsages';
import { CameraNetworkChangesRecords } from '../@components/CameraNetworkChangesRecords';
import { CameraUptimeRecords } from '../@components/CameraUptimeRecords';
import { CameraWiFiUsages } from '../@components/CameraWiFiUsages';

export const USAGES_TAB_HEIGHT = '45vh';

export const API_CALL_ITEM_PER_PAGE = 50;

/** @type {Array<SoftwarePageCardItem>} */
export const USAGES_TAB_ITEMS = [
  {
    key: 'cpu',
    label: 'CPU',
    component: <CameraCpuUsages />,
  },
  {
    key: 'memory',
    label: 'Memory',
    component: <CameraMemoryUsages />,
  },
  {
    key: 'disk',
    label: 'Disk',
    component: <CameraDiskUsages />,
  },
  {
    key: 'wifi',
    label: 'Wi-Fi Data',
    component: <CameraWiFiUsages />,
  },
  {
    key: 'cellular',
    label: 'Cellular Data',
    component: <CameraCellularUsages />,
  },
  {
    key: 'network',
    label: 'Network Changes',
    component: <CameraNetworkChangesRecords />,
  },
  {
    key: 'uptime',
    label: 'Camera Uptime',
    component: <CameraUptimeRecords />,
  },
  {
    key: 'report',
    label: 'Bandwidth Consumption',
    component: <CameraBandwidthConsumptions />,
  },
];

export const NETWORK_CHANGES = [
  {
    key: 'wifi',
    label: 'Wifi',
  },
  {
    key: 'cellular',
    label: 'Cellular',
  },
];
