import {
  CAMERA_MANAGE_CARD_ITEMS,
  DEVICE_MANAGE_CARD_ITEMS,
} from '@/assets/page-cards/device-manage';
import {
  selectTeamList,
  selectTenantId,
  selectUserAccessibleFeatures,
  selectUserEmail,
  selectUserRole,
} from '@/store/auth';
import { filterByUserAccess } from '@/utils/user-access';
import { PageCard } from '@/web/@components/PageCard';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Grid } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

export function ManageCameraCardItems() {
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);
  const enableFeatures = useSelector(selectUserAccessibleFeatures);

  const { camera } = useContext(CameraDetailsContext);

  const visibleItems = useMemo(
    () =>
      DEVICE_MANAGE_CARD_ITEMS?.filter(
        filterByUserAccess({ userRole, userEmail, tenantId, teamList, enableFeatures })
      ),
    [userRole, userEmail, tenantId, teamList, enableFeatures]
  );

  const isAppDevice = useMemo(() => camera?.type === 'APP', [camera?.type]);

  return (
    <Grid container spacing={2}>
      {(isAppDevice ? CAMERA_MANAGE_CARD_ITEMS : visibleItems).map((item) => (
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={item.name} minHeight="200px">
          <PageCard item={item} />
        </Grid>
      ))}
    </Grid>
  );
}
