import { SKY_HAWK_ID } from '@/assets/demo';
import { selectCurrentTenantName, selectTenantId } from '@/store/auth';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { FleetDriversScore } from './@components/FleetDriversScore';
import { FleetRecentEventArea } from './@components/FleetRecentEventArea';
import { FleetScoreWidget } from './@components/FleetScoreWidget';
import { FleetStackedBarChart } from './@components/FleetStackedBarChart';
import { FleetTripsPage } from './@components/FleetTripsPage';
import { FleetRecentCameras } from './@components/RecentCameras';

export function DriverFleets() {
  const tenantId = useSelector(selectTenantId);
  const tenantName = useSelector(selectCurrentTenantName);

  return (
    <Box p={2.5} pb={10}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="body2" fontWeight={600}>
          {tenantName} Fleet
        </Typography>
        {/* <DownloadFleetReport /> */}
      </Box>
      <Box
        display={{ xs: '', md: 'flex' }}
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Box width={{ xs: '100%', md: '30%' }}>
          <FleetScoreWidget />
        </Box>
        <Box width={{ xs: '100%', md: '70%' }} className="fleetStackedChart">
          <FleetStackedBarChart />
        </Box>
      </Box>

      <FleetTripsPage />

      <Box my={4}>
        <FleetRecentEventArea />
      </Box>

      {tenantId === SKY_HAWK_ID && (
        <Box my={4}>
          <FleetRecentCameras />
        </Box>
      )}

      <Box my={4}>
        <FleetDriversScore
          order="TOP"
          title="Top Driver Safety Scores"
          linkText="View All Top Driver Safety Scores"
        />
      </Box>

      <Box my={4}>
        <FleetDriversScore
          order="BOTTOM"
          title="Lowest Driver Safety Scores"
          linkText="View All Lowest Driver Safety Scores"
        />
      </Box>
    </Box>
  );
}
