import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import getStoredState from 'redux-persist/es/getStoredState';
import { Auth, authPersistConfig, AuthSlice } from './auth';
import { HubConnectionSlice, hubPersistConfig } from './hub-connection';
import { pageViewPersistConfig, PageViewSlice } from './page-view';

export const store = configureStore({
  enhancers: [
    Sentry.createReduxEnhancer({}), //
  ],
  reducer: {
    auth: persistReducer(authPersistConfig, AuthSlice.reducer),
    hub: persistReducer(hubPersistConfig, HubConnectionSlice.reducer),
    pageView: persistReducer(pageViewPersistConfig, PageViewSlice.reducer),
  },
  /** @param {import('@reduxjs/toolkit/dist/getDefaultMiddleware').CurriedGetDefaultMiddleware<StoreState>} getDefaultMiddleware */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const onBeforeLift = async () => {
  // take some action before the persist gate lifts
};

export const persistor = persistStore(store);

export async function restoreAuthState() {
  const state = await getStoredState(authPersistConfig);
  store.dispatch(Auth.setStoredState(state));
  // store.dispatch({
  //   type: REHYDRATE,
  //   key: authPersistConfig.key,
  //   payload: state,
  // });
}
