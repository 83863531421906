import { Box } from '@mui/material';
import { UserDetailsArea } from '../../@components/UserDetailsArea';
import { UserDeviceArea } from '../../@components/UserDeviceArea';

export function UserDetailsPage() {
  return (
    <Box display="grid" px={2}>
      <UserDetailsArea />
      <UserDeviceArea />
    </Box>
  );
}
