import { DBC_FILE_UPLOAD_LIMIT } from '@/assets/file';
import { toastWarning } from '@/utils/toaster';
import { FileUpload } from '@mui/icons-material';
import { Box, Button, FormLabel, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { formatSize } from '../../@utils';

/**
 * @typedef {object} DbcFileUploadControlProps
 * @property {File} value
 * @property {(file: File) => any} onChange
 */

/** @param {DbcFileUploadControlProps} props */
export function DbcFileUploadControl(props) {
  const { value, onChange } = props;
  const theme = useTheme();

  /** @param {File[]} acceptedFiles */
  const onDropAccepted = (acceptedFiles) => {
    onChange(acceptedFiles[0]);
  };
  const onDropRejected = () => {
    toastWarning('Maximum file upload size is ' + formatSize(DBC_FILE_UPLOAD_LIMIT));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    maxFiles: 1,
    minSize: 0,
    maxSize: DBC_FILE_UPLOAD_LIMIT, //bytes
  });

  return (
    <Box mb={2}>
      <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
        Select a file to upload
      </FormLabel>
      <Button
        {...getRootProps()}
        fullWidth
        variant="text"
        style={{
          padding: '20px',
          textAlign: 'center',
          borderRadius: '4px',
          border: '1px dashed #C5D9F0',
          fontWeight: 'normal',
          fontSize: '0.875rem',
          color: value ? theme.palette.text.primary : theme.palette.text.secondary,
        }}
      >
        <input {...getInputProps()} />
        <Box>{value?.name || "Drag 'n' drop the file here, or click to select file"}</Box>
        <Box flex={1} />
        <FileUpload htmlColor="#596A82" />
      </Button>
    </Box>
  );
}
