import { getTriggerImageByName } from '@/utils/triggers';
import { TriggerDetailsModal } from '@/web/@components/TriggerDetailsModal';
import { Link } from '@mui/material';
import { useState } from 'react';
import { IconLabelItem } from '../IconLabelItem';

/**
 * @typedef {object} TriggerItem
 * @property {string} [id]
 * @property {string} [name]
 * @property {string} [code]
 * @property {TriggerCategoryDetails} [category]
 */

/**
 * @typedef {object} TriggerNameColumnProps
 * @property {TriggerItem} [row]
 */

/** @param {TriggerNameColumnProps} props */
export function TriggerNameColumn(props) {
  const { row } = props;

  /** @type {StateVariable<boolean>} */
  const [detailsDialog, setDetailsDialog] = useState(false);

  const { name: categoryName } = row.category || {};
  const { name: rowName } = row || {};

  const triggerName =
    (categoryName === 'Default' && rowName !== '') || rowName !== categoryName
      ? rowName
      : categoryName;

  return (
    <>
      {detailsDialog && (
        <TriggerDetailsModal
          onCancel={() => setDetailsDialog(false)}
          triggerId={row?.id}
          triggerName={triggerName}
        />
      )}

      <Link onClick={() => setDetailsDialog(true)}>
        <IconLabelItem src={getTriggerImageByName(triggerName)} title={triggerName} />
      </Link>
    </>
  );
}
