export const OTA_STATUS = {
  IDLE: 'IDLE',
  WAITING_FOR_DOWNLOAD: 'WAITING_FOR_DOWNLOAD',
};

export const OTA_STATUS_INFO = {
  IDLE: 'No OTA update in progress',
  WAITING_FOR_DOWNLOAD: 'Waiting to download firmware',
  STARTING_DOWNLOAD: 'Starting download',
  DOWNLOAD_INTERRUPTED: 'Download interrupted by the system',
  DOWNLOAD_IN_PROGRESS: 'Downloading',
  DOWNLOAD_PAUSED: 'Download paused',
  DOWNLOAD_SUCCEEDED: 'Download successful',
  DOWNLOAD_FAILED: 'Download failed',
  WAITING_FOR_DL_RETRY: 'Waiting to retry the download',
  VERIFICATION_IN_PROGRESS: 'Verifying firmware integrity',
  VERIFICATION_FAILED: 'Firmware integrity verification failed',
  VERIFICATION_SUCCESSFUL: 'Firmware integrity verification successful',
  WAITING_FOR_INSTALLATION: 'Waiting for installation to start',
  INSTALLATION_IN_PROGRESS: 'Installing firmware',
  INSTALLATION_SUCCESSFUL: 'Firmware installation successful',
  INSTALLATION_FAILED: 'Firmware installation failed',
  WAITING_FOR_INS_RETRY: 'Waiting to retry the installation',
};

export const FIRMWARE_TYPES = {
  SYSTEM_IMAGE: 'SYSTEM_IMAGE',
  APPLICATION_PACKAGE: 'APPLICATION_PACKAGE',
  OEM_IMAGE: 'OEM_IMAGE',
  CAN_BUS_FW: 'CAN_BUS_FW',
  SUPPORTING_APPLICATION: 'SUPPORTING_APPLICATION',
  LTE_MODEM_FW: 'LTE_MODEM_FW',
  SMART_CABLE_FW: 'SMART_CABLE_FW',
};
