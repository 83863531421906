import { createSelector } from '@reduxjs/toolkit';

/** @param {StoreState} state */
export const selectPageView = (state) => state.pageView;

export const selectSidebarVisible = createSelector(selectPageView, (view) => view.sidebarVisible);
export const selectFullWidthLayout = createSelector(selectPageView, (view) => view.fullWidthLayout);
export const selectHideToolbar = createSelector(selectPageView, (view) => view.hideToolbar);
export const selectToolbarMenu = createSelector(selectPageView, (view) => view.toolbarMenuOpen);
export const selectHoveredItem = createSelector(selectPageView, (view) => view.hoveredItem);
export const selectSidebarMode = createSelector(selectPageView, (view) => false); //view.sidebarCollapsed
export const selectTabVisible = createSelector(selectPageView, (view) => view.tabVisible);
export const selectSimplifyCurve = createSelector(selectPageView, (view) => view.simplifyCurve);
