import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<NfcCardModel>>} */
export const ACCESS_CARD_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'id',
    label: 'Access Id',
    align: 'left',
    searchable: true,
    isPrimary: true,
  },
];
