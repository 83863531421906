import api from '@/api';
import { selectSecretToken, selectTeamList, selectTenantId } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExpressionTriggerForm } from '../../@components/ExpressionTriggerForm';
import { isUndefined } from 'lodash';

export function ExpressionTriggerEdit() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /** @type {import('../../@components/ExpressionTriggerForm').ExpressionTriggerFormProps['onSubmitData']} */
  const updateTrigger = async (data, triggerId, categoryId) => {
    try {
      setLoading(true);

      const hasDescriptionEditAccess = teamList.some((x) =>
        ['AI_TEAM', 'BACK_END_USER']?.includes(x)
      );

      const req = await api.ac.v5.trigger.composite.$triggerId(triggerId).$put({
        headers: {
          Authorization: secretToken,
        },
        data,
      });
      await req.process().then(() => {
        if (!hasDescriptionEditAccess || isUndefined(categoryId)) return;
        const categoryReq = api.ac.v5.trigger.category.$categoryId(categoryId).$put({
          headers: {
            Authorization: secretToken,
          },
          data: { description: data?.description },
        });
        categoryReq?.process();
      });

      navigate('../..');
    } catch (err) {
      toastWarning('Error', 'Failed to update, please try later.');
    } finally {
      setLoading(false);
    }
  };

  // Navigate triggers list page while tenant change
  useEffect(() => {
    return () => navigate('../..');
  }, [tenantId, navigate]);

  return (
    <Box m={3}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Update Trigger
      </Typography>
      <ExpressionTriggerForm onSubmitData={updateTrigger} isLoading={loading} />
    </Box>
  );
}
