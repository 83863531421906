import { trimString } from '@/utils/formatting';
import qs from 'qs';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {object} FilterParams
 * @property {string} [searchText]
 * @property {string} [month]
 */

/** @type {FilterParams} */
const defaultValues = {
  searchText: '',
  month: '',
};

const paramAlias = {
  searchText: 'searchText',
  month: 'month',
};

/** @type {import("react").Context<FilterParams>} */
export const VideoMinutesFilterContext = createContext(null);

/** @type {import("react").Context<(params: FilterParams, replace?: boolean) => any>} */
export const SetVideoMinutesFilterContext = createContext(null);

/** @param {{children: import("react").ReactNode}} props */
function SetVideoMinutesFilterContextProvider(props) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  /**
   * @type {(data: {[key: string]: any}, replace?: boolean) => any}
   */
  const setVideoMinutesFilter = useCallback(
    (data, replace = false) => {
      let params = {};
      for (const key of Object.keys(data)) {
        if (paramAlias.hasOwnProperty(key)) {
          params[paramAlias[key]] = data[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          params[key] = data[key];
        }
      }

      const query = qs.parse(location.search.substring(1));
      if (!replace) {
        params = { ...query, ...params };
      }

      for (const key of Object.keys(params)) {
        if (key in params) {
          if (!params[key]) delete params[key];
          else params[key] = trimString(params[key]);
        }
      }

      if (
        JSON.stringify(Object.entries(query).sort()) ===
        JSON.stringify(Object.entries(params).sort())
      ) {
        return;
      }

      navigate(
        {
          ...location,
          search: qs.stringify(params),
        },
        { replace: false }
      );
    },
    [location, navigate]
  );

  return (
    <SetVideoMinutesFilterContext.Provider value={setVideoMinutesFilter}>
      {children}
    </SetVideoMinutesFilterContext.Provider>
  );
}

/** @param {{children: import("react").ReactNode, defaults?: FilterParams}} props */
export function VideoMinutesFilterContextProvider(props) {
  const { children, defaults = defaultValues } = props;

  const location = useLocation();

  /** @type {StateVariable<FilterParams>} */
  const [value, setValue] = useState(defaults);

  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  /** @type {(value: any, defaultValue: string) => string} */
  const toString = (value, defaultValue) => (value || defaultValue || '') + '';

  useEffect(() => {
    const searchText = toString(query.searchText, defaults.searchText);
    setValue((v) => (v.searchText === searchText ? v : { ...v, searchText }));
  }, [query.searchText, defaults]);

  useEffect(() => {
    const month = toString(query.month, defaults.searchText);
    setValue((v) => (v.searchText === month ? v : { ...v, month }));
  }, [query.month, defaults]);

  return (
    <SetVideoMinutesFilterContextProvider>
      <VideoMinutesFilterContext.Provider value={value}>
        {children}
      </VideoMinutesFilterContext.Provider>
    </SetVideoMinutesFilterContextProvider>
  );
}
