import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DriversBasicInformation } from '../../@components/DriversBasicInformation';
import { DriversFaceIdentity } from '../../@components/DriversFaceIdentity';
import { DriversAccessCardListView } from '../access-card';
import { FACE_REGISTRATION_TENANTS } from '@/utils/user-access';

export function DriverDetailsPage() {
  const { id: editingDriverId } = useParams();

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { setBreadcrumbTitle } = useContext(MainContext);

  /** @type {StateVariable<Boolean>} */
  const [loading, setLoading] = useState(false);
  /** @type {StateVariable<Driver>} */
  const [driver, setDriver] = useState(null);

  const [expanded, setExpanded] = useState('face-identity');

  useEffect(() => {
    if (!editingDriverId) return;
    const request = api.ac.v5.driver.$driverId(Number(editingDriverId)).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    setLoading(true);
    request
      .process()
      .then((driver) => {
        setDriver(driver.result);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [secretToken, editingDriverId, tenantId]);

  useEffect(() => {
    if (!driver) return;
    const driverCombinedName = `${driver.firstName} ${driver.lastName}`;
    setBreadcrumbTitle(driverCombinedName);
  }, [driver, setBreadcrumbTitle]);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  const accordionItems = [
    {
      key: 'face-identity',
      label: 'Camera with Face Identity',
      component: (driver) => <DriversFaceIdentity driver={driver} />,
      hidden: !FACE_REGISTRATION_TENANTS.includes(tenantId),
    },
    {
      key: 'access-card',
      label: 'Driver Access Cards',
      component: (driver) => (
        <Box sx={{ height: '100%', overflowY: 'auto' }}>
          <DriversAccessCardListView />
        </Box>
      ),
    },
  ];

  return (
    <Box display="grid" p={2} pb={5} rowGap={5}>
      <DriversBasicInformation driver={driver} />
      <Box
        mt={2}
        sx={{
          '& .MuiPaper-elevation': {
            border: '1px solid #c5d9f0',
            borderRadius: '5px',
          },
          'overflowY': 'auto',
        }}
        height="98%"
      >
        {accordionItems
          .filter((item) => !item.hidden)
          .map((item) => (
            <Accordion
              key={item.label}
              disableGutters
              elevation={0}
              square
              TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
              expanded={expanded === item.key}
              onChange={handleChange(item.key)}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDown fontSize="large" />}
                aria-controls="controls-content"
                id="controls-header"
              >
                <Typography>{item.label}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ borderTop: '1px solid #c5d9f0' }}>
                {item.component(driver)}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </Box>
  );
}
