import { sortedIndex, sortedIndexOf } from 'lodash';

/**
 * @template T, V
 * @type {OrderedMap<T, V>}
 */
export class OrderedMap {
  /** @type {Array<T>} */
  $keys = [];
  /** @type {Array<V>} */
  $values = [];

  /**
   * Gets the length of the set
   */
  get length() {
    return this.$keys.length;
  }

  /**
   * Gets list of values
   * @returns {Array<T>}
   */
  get keys() {
    return this.$keys;
  }

  /**
   * Gets list of values
   * @returns {Array<V>}
   */
  get values() {
    return this.$values;
  }

  /**
   * Gets list of values
   * @returns {Array<[T, V]>}
   */
  get entries() {
    return this.$keys.map((k, i) => [k, this.$values[i]]);
  }

  /**
   * Set a value by key
   * @param {T} key
   * @param {V} value
   * @param {(current: V) => V} [update]
   */
  set(key, value, update) {
    update ||= () => value;
    const i = sortedIndex(this.$keys, key);
    if (this.$keys[i] === key) {
      this.$values[i] = update(this.$values[i]);
    } else {
      this.$keys.splice(i, 0, key);
      this.$values.splice(i, 0, value);
    }
  }

  /**
   * Delete an entry by key
   * @param {T} key
   */
  delete(key) {
    const i = sortedIndexOf(this.$keys, key);
    if (i === -1) return;
    this.$keys.splice(i, 1);
    this.$values.splice(i, 1);
  }

  /**
   * Check if the key exists
   * @param {T} key
   */
  has(key) {
    return sortedIndexOf(this.$keys, key) !== -1;
  }

  /**
   * Get a value by key
   * @param {T} key
   * @returns {V}
   */
  get(key) {
    const i = sortedIndexOf(this.$keys, key);
    if (i === -1) return undefined;
    return this.$values[i];
  }

  /**
   * Get all values within the range
   * @param {T} start inclusive value
   * @param {T} end inclusive value
   * @returns {Array<V>}
   */
  getRange(start, end) {
    const i = sortedIndex(this.$keys, start);
    const j = sortedIndex(this.$keys, end);
    const k = this.$keys[j] === end ? j + 1 : j;
    return this.$values.slice(i, k);
  }

  /**
   * Get all values within the range
   * @param {T} key inclusive value
   * @returns {V}
   */
  nearestValue(key) {
    let i = sortedIndex(this.$keys, key);
    if (i > 0 && this.$keys[i] !== key && typeof key === 'number') {
      const c = this.$keys[i];
      const p = this.$keys[i - 1];
      if (typeof p !== 'number' || typeof c !== 'number' || key - p < c - key) {
        i--;
      }
    }
    return this.$values[i];
  }

  /**
   * Remove all values within the range
   * @param {T} start inclusive value
   * @param {T} end inclusive value
   */
  removeRange(start, end) {
    const i = sortedIndex(this.$keys, start);
    const j = sortedIndex(this.$keys, end);
    const c = (this.$keys[j] === end ? j + 1 : j) - i;
    this.$keys.splice(i, c);
    this.$values.splice(i, c);
  }

  /**
   * Get the value at an specific index
   * @param {number} index
   * @returns {V}
   */
  at(index) {
    return this.$values[index];
  }

  /**
   * Removes and returns the first item
   * @returns {[T, V]}
   */
  popFirst() {
    const key = this.$keys.shift();
    const value = this.$values.shift();
    return [key, value];
  }

  /**
   * Removes and returns the last item
   * @returns {[T, V]}
   */
  popLast() {
    const key = this.$keys.pop();
    const value = this.$values.pop();
    return [key, value];
  }

  clear() {
    this.$keys = [];
    this.$values = [];
  }

  /**
   * Filter out some elements by a matcher. Return true from the matcher to retain items.
   * @param {(value: V, key: T) => boolean} matcher
   */
  retainBy(matcher) {
    const keys = [];
    const values = [];
    for (let i = 0; i < this.length; ++i) {
      if (matcher(this.$values[i], this.$keys[i])) {
        keys.push(this.$keys[i]);
        values.push(this.$values[i]);
      }
    }
    this.$keys = keys;
    this.$values = values;
  }
}
