import { isDev } from '@/config';
import { useCameraGroupList } from '@/hooks/useCameraGroupList';
import { CenterBox } from '@/web/@components/CenterBox';
import { SearchField } from '@/web/@components/SearchField';
import { CheckCircle, Circle } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';

import { useEffect, useMemo, useState } from 'react';
import { IconMessageBox } from '../IconMessageBox';

/**
 * @typedef {object} DeviceGroupChangeDialogProps
 * @property {boolean} [open]
 * @property {string} [title]
 * @property {string} [message]
 * @property {() => any} onClose
 * @property {(value: Group) => any} onSelect
 * @property {number} [defaultGroupId]
 */

/** @param {DeviceGroupChangeDialogProps} props */
export function DeviceGroupChangeDialog(props) {
  const { title, message, open, onClose, onSelect, defaultGroupId: selectedGroupId } = props;

  /** @type {StateVariable<string>} */
  const [filter, setFilter] = useState(null);
  /** @type {StateVariable<Group>} */
  const [selected, setSelected] = useState(null);

  const { result = [], loading } = useCameraGroupList();

  useEffect(() => {
    if (!result?.length) return;
    setSelected(result.find((x) => x.id === (selectedGroupId || 0)) || result[0]);
  }, [selectedGroupId, result]);

  /** @param {string} searchText */
  const filteredGroups = useMemo(() => {
    if (!filter || !result?.length) {
      return result || [];
    }
    return result
      .filter((group) => group.name?.toLowerCase().includes(filter))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [result, filter]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth keepMounted>
      <DialogTitle>{title || 'Change Group'}</DialogTitle>
      <Divider />
      <DialogContent>
        {message && <DialogContentText pb={'10px'}>{message}</DialogContentText>}

        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={12} md={6}>
            <Box my={1} fontWeight={500} textAlign={{ xs: 'center', md: 'left' }}>
              Device Groups
            </Box>
          </Grid>

          {result && result?.length > 10 && (
            <Grid item xs={12} md={6}>
              <SearchField
                clearable
                ignoreCase
                placeholder="Search a device group"
                onSearch={setFilter}
                InputProps={{
                  onInput: (event) => {
                    // @ts-ignore
                    setFilter(event.target.value);
                  },
                }}
                sx={{
                  borderColor: 'transparent',
                  borderRadius: 10,
                  background: '#f7f7f7',
                }}
              />
            </Grid>
          )}
        </Grid>

        <Divider />

        {loading ? (
          <CenterBox sx={{ pt: '50px' }}>
            <CircularProgress />
          </CenterBox>
        ) : (
          <Box minHeight="200px" height="50vh" overflow="hidden auto">
            {!filteredGroups.length && (
              <CenterBox>
                <IconMessageBox
                  size="128px"
                  src="/images/empty/no-search-results.svg"
                  message="No Device Groups"
                />
              </CenterBox>
            )}
            {filteredGroups.map((group, index) => (
              <Box key={index}>
                {index > 0 && <Divider />}
                <Button
                  onClick={() => setSelected(group)}
                  sx={{
                    'width': '100%',
                    'display': 'flex',
                    'fontWeight': '500',
                    'color': '#011e42',
                    'justifyContent': 'space-between',
                    '& .checkbox': {
                      padding: '1px',
                      fontSize: '1.375rem',
                      borderRadius: '50%',
                      background: '#ffffff',
                      border: '1px solid #cfd8e5',
                    },
                  }}
                >
                  <Box>{group.name}</Box>
                  <Box>
                    {isDev && <Chip label={group.id} size="small" />}
                    {selected?.id === group.id ? (
                      <CheckCircle htmlColor="#596A81" className="checkbox" />
                    ) : (
                      <Circle htmlColor="#CFD8E5" className="checkbox" />
                    )}
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!selected}
          onClick={selected ? () => onSelect(selected) : null}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
