import {
  GEOTAB_ICON,
  GOOGLE_ICON,
  MICROSOFT_ICON,
  SMARTER_AI_ICON,
} from '@/assets/constants/images';
import { startCase } from 'lodash';

/**
 * @param {string} text
 * @param {number} length
 * @param {string} spread
 */
export function takeTextPortion(text, length, spread = '...') {
  if (text.length < length) {
    return text;
  }
  return text.substring(0, length) + spread;
}

/**
 * Formats size of files into human readable format
 * @param {number} size
 * @param {{decimal: number, short: boolean}} options
 */
export function formatFileSize(size, options = { decimal: 2, short: true }) {
  const shortSuffixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const longSuffixes = ['bytes', 'kilobytes', 'megabytes', 'gigabytes', 'terabytes', 'petabytes'];
  const suffixes = options.short ? shortSuffixes : longSuffixes;

  let sign = false;
  if (size < 0) {
    sign = true;
    size = -size;
  }

  let index = 0;
  while (size > 1024) {
    size /= 1024;
    index++;
  }

  return `${sign ? '-' : ''}${size.toFixed(options.decimal)} ${suffixes[index]}`;
}

/** @param {string} name */
export function sanitizeName(name) {
  return startCase(name.toLowerCase().split('_').join(' '));
}

/** @param {string} val */
export function parseProperties(val) {
  const data = {};
  for (const line of val.trim().split('\n')) {
    const kv = line.trim().split('=');
    if (kv.length < 2) continue;
    data[kv[0]] = kv.slice(1).join('=');
  }
  return data;
}

/** @param {Array<string>} arr */
export function arrayToCommaSeparateString(arr) {
  return arr?.join(', ')?.replace(/,(?!.*,)/gim, ' and') || '';
}

/** @param {Number} bitrate */
export function formatBitrate(bitrate) {
  return `${new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(bitrate)}bps`;
}

/** @param {any} value */
export function trimString(value) {
  return ((value || '') + '').trim();
}

/** @param {any} query */
export function formatQuery(query) {
  return trimString(query)
    .replace(/[^\w\d]+/, ' ')
    .toLowerCase();
}

/**
 * @param {any} text
 * @param {string} query
 */
export function testMatch(text, query) {
  if (!query.length) return true;
  return formatQuery(text).includes(query);
}

/**
 * @param {number} number
 */
export function roundToNearestSecond(number) {
  return Math?.round(number / 1000) * 1000;
}

export function getEmailIcon(email) {
  const emailDomain = email.split('@')[1].split('.')[0];
  switch (emailDomain) {
    case 'gmail':
      return GOOGLE_ICON;
    case 'microsoft':
      return MICROSOFT_ICON;
    case 'geotab':
      return GEOTAB_ICON;
    default:
      return SMARTER_AI_ICON;
  }
}
