import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTION_ITEMS } from './actions';
import { AssignAiContainerModalPage } from './@components/AssignAiContainerModalPage';
import { AI_CONTAINER_TABLE_COLUMNS } from '../columns';
import { STATUS_LIST } from '@/assets/constants/table';

export function AssignedChildAiContainerList() {
  const navigate = useNavigate();
  const { tenantId: childTenantId, tenantName } = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [modalType, setModalType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setBreadcrumbTitle(`${tenantName} AI Containers`);
  }, [tenantName, setBreadcrumbTitle]);

  const {
    result = [],
    loading,
    error,
    retry: fetchAIContainers,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    let conToken = '';
    const limit = 100;
    /** @type {Array<AIContainerV2>} */
    const results = [];
    while (!signal.aborted) {
      const request = api.ac.v2.repository.aicontainer.assign.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          pageSize: limit,
          continuationToken: conToken,
          tenantId: childTenantId,
          secretToken,
        },
      });
      await request.process();
      const result = request.result.data;
      conToken = request.result.continuationToken;
      results.push(
        ...result.filter((x) =>
          [STATUS_LIST.RELEASED, STATUS_LIST.DRAFT].includes(x.publicationStatus)
        )
      );
      if (result.length < limit) break;
    }
    return results.sort((a, b) => {
      return (
        -a.publicationStatus.localeCompare(b.publicationStatus) ||
        a.name.localeCompare(b.name) ||
        -a.versionNumber.localeCompare(b.versionNumber)
      );
    });
  }, []);

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<CompositeTriggerDto>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    setModalType(type);
    if (type === 'ASSIGN') {
      setIsModalOpen(true);
    } else if (type === 'DISCONTINUE') {
      await unAssignAiContainers(selectedItems);
      fetchAIContainers();
    }
  };

  const unAssignAiContainer = async (item) => {
    try {
      const request = api.ac.v2.repository.aicontainer.assign.$delete({
        params: {
          containerId: item.containerId,
          secretToken,
          tenantId,
          unassignTenantId: childTenantId,
        },
      });
      await request.process();
      await request.process();
      // toastSuccess('Success', `Successfully assigned ${item.name}`);
    } catch (e) {
      toastWarning('Error', `Failed to unassign ${item.name}`);
    }
  };

  const unAssignAiContainers = async (selectedItems) => {
    await Promise.all(selectedItems.map(unAssignAiContainer));
  };

  const onModalClose = (reload) => {
    setIsModalOpen(false);
    setModalType(null);
    if (reload === true) {
      fetchAIContainers();
    }
  };

  useEffect(() => {
    return () => navigate('/administration/ai/ai-containers/tenant-deployment');
  }, [tenantId, navigate]);

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch AI Containers');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'AI Containers'} actions={ACTION_ITEMS} onAction={onActionHandle} />
        <ContinuationTokenTable
          loading={loading}
          results={result}
          columns={AI_CONTAINER_TABLE_COLUMNS}
        />
      </PaginatedTableContextProvider>
      {isModalOpen && modalType === 'ASSIGN' && (
        <AssignAiContainerModalPage
          onModalClose={onModalClose}
          assignedItems={result}
          childTenantId={childTenantId}
        />
      )}
    </Box>
  );
}
