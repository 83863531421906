import { T } from '@/assets/locales';
import { Box, CircularProgress, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';

/** @param {{count: number}} props */
export function CameraCountHeader(props) {
  const { count } = props;
  const { t } = useTranslation();
  return (
    <Box>
      {!isNumber(count) ? (
        <CircularProgress size="16px" />
      ) : (
        <Typography component={'span'} fontWeight="medium">
          {count}
        </Typography>
      )}
      <Typography component={'span'} ml="5px" variant="subtitle2">
        {t(T['cameras.page.title'])}
      </Typography>
    </Box>
  );
}
