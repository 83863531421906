import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { CenterBox } from '../CenterBox';
import { IconMessageBox } from '../IconMessageBox';
import { ItemsListView } from '../ItemsListView';
import { ItemsTableView } from '../ItemsTableView';
import { InfiniteScrollLoadNext } from './InfiniteScrollLoadNext';

/**
 * @template T
 * @param {InfiniteScrollTableViewProps<T> & {sx?: import('@mui/material').SxProps}} props
 */
export function InfiniteScrollTableView(props) {
  const {
    state,
    columns,
    defaultSortKey,
    renderFailure = (error, retry) => (
      <IconMessageBox
        size="128px"
        src={NO_SEARCH_RESULT_IMG}
        message="Sorry! Could not fetch data"
        onRetry={retry}
      />
    ),
    sx,
  } = props;
  const { results, error, loadNext } = state;

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  if (mdUp) {
    return (
      <ItemsTableView data={results} columns={columns} defaultSortKey={defaultSortKey} sx={sx}>
        <TableRow sx={{ py: 1 }}>
          <TableCell align="center" variant={'footer'} colSpan={columns.length}>
            <InfiniteScrollLoadNext children={error ? renderFailure(error, loadNext) : null} />
          </TableCell>
        </TableRow>
      </ItemsTableView>
    );
  }

  return (
    <ItemsListView data={results} columns={columns} sx={sx}>
      <CenterBox py={1}>
        <InfiniteScrollLoadNext children={error ? renderFailure(error, loadNext) : null} />
      </CenterBox>
    </ItemsListView>
  );
}
