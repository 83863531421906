import { T } from '@/assets/locales';
import { useCameraGroupList } from '@/hooks/useCameraGroupList';
import { trimString } from '@/utils/formatting';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { chain } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} VinGroupFormFields
 * @property {string} groupName
 * @property {string} groupDescription
 * @property {import('@/types').LabelValuePair<number>} cameraGroup
 */

/**
 * @typedef {object} VinGroupFormProps
 * @property {boolean} loading
 * @property {VinGroup} [vinGroup]
 * @property {import('react-hook-form').SubmitHandler<VinGroupFormFields>} onSubmitData
 */

/**
 * @param {VinGroupFormProps} props
 */
export function VinGroupForm(props) {
  const { vinGroup, onSubmitData, loading, ...extra } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { result: rawGroups, loading: cameraGroupsLoading } = useCameraGroupList();

  const cameraGroups = useMemo(
    () =>
      chain(rawGroups || [])
        .map((item) => ({ label: item.name, value: item.id }))
        .sortBy('label')
        .sortedUniqBy('label')
        .value(),
    [rawGroups]
  );

  /** @type {import('react-hook-form').UseFormReturn<VinGroupFormFields>} */
  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      groupName: vinGroup?.name || '',
      groupDescription: vinGroup?.description || '',
      cameraGroup: null,
    },
  });

  useEffect(() => {
    if (!vinGroup || !cameraGroups?.length) return;
    const group = cameraGroups.find((item) => item.value === vinGroup.targetGroupId);
    form.setValue('cameraGroup', group);
  }, [form, vinGroup, cameraGroups]);

  /** @param {VinGroupFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.groupName = trimString(data.groupName);
    data.groupDescription = trimString(data.groupDescription);
    onSubmitData(data);
  };

  return (
    <Box {...extra}>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        Group Details
      </Typography>
      <Divider />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
          <Box
            display="grid"
            gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
            gap="10px"
            py="10px"
          >
            <CustomInput
              name="groupName"
              label="VIN Group Name *"
              placeholder="VIN Group Name"
              rules={{ required: 'Group name is required' }}
            />

            <CustomSelect
              name="cameraGroup"
              label="Camera Group *"
              options={cameraGroups}
              loading={cameraGroupsLoading}
              placeholder="Select Camera Group"
              rules={{ required: 'Camera Group required' }}
            />

            <CustomInput
              name="groupDescription"
              label="Description"
              placeholder="VIN Group Description"
              minRows={3}
            />
          </Box>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
            <Button variant="text" onClick={() => navigate('/administration/vin-groups')}>
              {t(T['button.cancel'])}
            </Button>
            <Button disabled={loading} variant="contained" type="submit">
              {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {vinGroup ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
