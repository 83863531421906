import { T } from '@/assets/locales';
import { CreateDevicePoolPage } from '@/web/administration/device-pools/create';
import { DevicePoolListView } from '@/web/administration/device-pools/index';
import { UpdateDevicePoolPage } from '@/web/administration/device-pools/_id/edit';
import { DevicePoolDetailsPage } from './_id';
import { DevicePoolLayout } from './_id/@components/DevicePoolDetailsLayout';
import { DevicePoolDevices } from './_id/devices';
import { DevicePoolFirmwares } from './_id/firmwares';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DevicePoolListView />,
  },
  {
    path: 'create',
    element: <CreateDevicePoolPage />,
    breadcrumb: T['device-pool.route.create'],
  },
  {
    path: ':id',
    element: <DevicePoolLayout />,
    breadcrumb: 'Device Pool Details',
    children: [
      {
        path: '',
        element: <DevicePoolDetailsPage />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'devices',
        element: <DevicePoolDevices />,
        breadcrumb: T['device.page.title'],
      },
      {
        path: 'firmwares',
        element: <DevicePoolFirmwares />,
        breadcrumb: T['firmwares.page.card.name'],
      },
      {
        path: 'edit',
        element: <UpdateDevicePoolPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
