import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { VinGroupChoiceDialog } from '@/web/@components/VinGroupChoiceDialog';
import { capitalize, startCase } from 'lodash';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { CreateVinDialog } from '../CreateVinDialog';
import { VinGroupDetailsContext } from '../VinGroupDetailsLayout';
import { ACTION_ITEMS } from './actions';

export function VinListActions() {
  const secretToken = useSelector(selectSecretToken);
  const { setTableReload } = useContext(PaginatedTableContext);
  const { groupId, vinGroup } = useContext(VinGroupDetailsContext);

  /** @type {StateVariable<boolean>} */
  const [createDialog, setCreateDialog] = useState(false);
  /** @type {StateVariable<VinDetails>} */
  const [transferDialog, setTransferDialog] = useState(null);

  /** @param {Array<VinDetails>} vins */
  const deleteVins = async (vins) => {
    const success = [];
    const failed = [];
    await Promise.all(
      vins.map(async (item) => {
        try {
          const request = api.ac.v5['vin-groups']
            .$id(groupId)
            .vins.$vin(item.vin)
            .$delete({
              headers: { Authorization: `Bearer ${secretToken}` },
            });
          await request.process();
          success.push(item.vin);
        } catch (err) {
          failed.push(item.vin);
        }
      })
    );
    if (success.length) {
      setTableReload(true);
    }
    if (failed.length) {
      toastWarning(
        'Error',
        failed.length === 1
          ? `Failed to delete <b>${failed[0]}</b>`
          : `Failed to delete ${failed.length} VINs`
      );
    }
  };

  /** @type {import('@/web/@components/VinGroupChoiceDialog').VinGroupChoiceDialogProps['onSubmit']} */
  const handleTransfer = async (vinGroup) => {
    try {
      const vin = transferDialog;
      const request = api.ac.v5['vin-groups']
        .$id(groupId)
        .vin.$vinNumber(vin.vin)
        .transfer.$post({
          data: { newVinGroupId: vinGroup.vinGroupId },
          headers: { Authorization: secretToken },
        });
      await request.process();
      // toastSuccess('Success', `Added <b>${vin}</b>`);
      setTableReload(true);
      setTransferDialog(null);
    } catch (err) {
      console.error('VIN transfer failed', err);
      toastWarning('Sorry!', `Could not transfer the VIN`);
    }
  };

  /** @type {import('../CreateVinDialog').CreateVinDialogProps['onSubmit']} */
  const handleCreate = async (vins) => {
    try {
      const request = api.ac.v5['vin-groups'].$id(groupId).vins.$post({
        data: { vins },
        headers: { Authorization: secretToken },
      });
      await request.process();
      if (request.result.resultList[0].code === 409) {
        toastWarning('Sorry!', request.result.resultList[0].message);
        return;
      }
      if (request.result.resultList[0].code > 200) {
        throw new KnownUserError(request.result.resultList[0].message);
      }
      // toastSuccess('Success', `Added <b>${vin}</b>`);
      setTableReload(true);
      setCreateDialog(false);
    } catch (err) {
      console.error('VIN create failure', err);
      toastWarning(
        'Sorry!',
        err?.message ? capitalize(startCase(err.message)) : `Could not add this VIN`
      );
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<VinDetails>['onAction']} */
  const onActionHandle = (type, selectedItems) => {
    switch (type) {
      case 'DISCONTINUE':
        deleteVins(selectedItems);
        break;
      case 'TRANSFER':
        setTransferDialog(selectedItems[0]);
        break;
      case 'CREATE':
        setCreateDialog(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ItemsListHeader title={vinGroup.name} actions={ACTION_ITEMS} onAction={onActionHandle} />
      <CreateVinDialog
        open={createDialog}
        onSubmit={handleCreate}
        onCancel={() => setCreateDialog(false)}
      />
      {transferDialog && (
        <VinGroupChoiceDialog
          open
          title="Transfer VIN"
          message="Choose a VIN Group to transfer the selected VIN"
          onSubmit={handleTransfer}
          onCancel={() => setTransferDialog(null)}
        />
      )}
    </>
  );
}
