import { formatTimestamp } from '@/utils/datetime';
import { LongMessageViewer } from '@/web/@components/LongMessageViewer';
import { capitalize } from 'lodash';

/** @type {Array<TableColumn<DiagnosticReport>>} */
export const MOBILE_DIAGNOSTICS_LOG_TABLE_COLUMNS = [
  {
    id: 'reportingTimestamp',
    label: 'Service Dump Time',
    align: 'left',
    isPrimary: true,
    width: '16%',
    sort: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'pushTime',
    label: 'Device Send Time',
    align: 'left',
    width: '16%',
    sort: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'Module',
    label: 'Module',
    align: 'left',
    searchable: true,
    sort: true,
  },
  {
    id: 'Severity',
    label: 'Severity',
    align: 'left',
    searchable: true,
    sort: true,
    format: (value) => capitalize(value),
  },
  {
    id: 'Summary',
    label: 'Summary',
    align: 'left',
    searchable: true,
    format: (value) => {
      return <LongMessageViewer message={value || ''} title="Summary" />;
    },
  },
  {
    id: 'Message',
    label: 'Message',
    align: 'left',
    searchable: true,
    format: (value) => {
      return <LongMessageViewer message={value || ''} title="Message" />;
    },
  },
  {
    id: 'details',
    label: 'Details',
    align: 'left',
    searchable: true,
    format: (value, row) => {
      delete row?.properties;
      // @ts-ignore
      delete row?.sortTieBreak;
      return (
        <LongMessageViewer
          message={JSON.stringify(row, null, 4) || ''}
          title="Details"
          moreText="Details"
          showOnCLick={true}
        />
      );
    },
  },
];
