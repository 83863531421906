import { T } from '@/assets/locales';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<DeviceGroupInfo>>} */
export const DEVICE_GROUP_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'groupName',
    label: T['device-group.columns.name'],
    align: 'left',
    isPrimary: true,
    format: (value) => <LongTextShortener text={value} length={45} />,
    link: (row) => `/administration/device-groups/${row.id}`,
  },
  {
    id: 'productName',
    label: T['device-group.columns.product'],
    align: 'left',
  },
  {
    id: 'groupDescription',
    label: T['device-group.columns.description'],
    align: 'left',
    fallback: '...',
    format: (value) => <LongTextShortener text={value} length={300} />,
    width: '30%',
  },
];
