import api from '@/api';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { sendEventsToDevices, sendOtaGroupMessages } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { DeviceGroupChangeDialog } from '@/web/@components/DeviceGroupChangeDialog';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} ChangeDeviceGroupProps
 * @property {() => any} [onModalClose]
 */

/** @param {ChangeDeviceGroupProps} props */
export function ChangeDeviceGroup(props) {
  const { onModalClose } = props;
  const { setTableReload, selectedItems } = useContext(PaginatedTableContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const handleSubmit = async (/** @type {Group} */ selectedGroup) => {
    if (!selectedGroup) {
      toastWarning('Please select a device group');
      return;
    }

    const failedDevices = [];
    const successDevices = [];
    for (const item of selectedItems || []) {
      const request = api.ac.endpoint.group.change.resource_group_id.$put({
        params: {
          secretToken,
          toGroupId: selectedGroup.id,
          tenantId,
          groupResourceType: 'DEVICE',
          resourceId: item.id,
        },
      });
      try {
        await request.process();
        const eventText = JSON.stringify({ groupId: selectedGroup.id });
        sendEventsToDevices([item.id], MESSAGE_TYPE.DEVICE_GROUP_CHANGE, eventText);
        sendOtaGroupMessages(selectedGroup.id, MESSAGE_TYPE.DEVICE_GROUP_CHANGE);
        setTableReload(true);
        successDevices.push(item.label);
      } catch (err) {
        console.error(err);
        failedDevices.push(item.label);
      }
    }
    if (successDevices.length === 1) {
      toastSuccess(`${successDevices[0]} has been assigned to ${selectedGroup.name || 'group'}`);
    } else if (successDevices.length > 1) {
      toastSuccess(
        `${successDevices.length} devices has been assigned to ${selectedGroup.name || 'group'}`
      );
    }
    if (failedDevices.length > 0) {
      toastWarning(
        `Could not assign to ${selectedGroup.name || 'group'}: ${failedDevices.join(', ')}`
      );
    }
    onModalClose && onModalClose();
  };

  return (
    <DeviceGroupChangeDialog
      open={true}
      onClose={onModalClose}
      onSelect={handleSubmit}
      message={'Choose a new group for the selected device.'}
    />
  );
}
