/**
 * @typedef {object} UnitData
 * @property {string} from
 * @property {string} to
 * @property {number} factor
 */

/** @type {Array<UnitData>} */
export const UNIT_CONVERSION = [
  {
    from: 'm',
    to: 'ft',
    factor: 3.28084,
  },
  {
    from: 'm/s',
    to: 'ft/s',
    factor: 3.28084,
  },
  {
    from: 'm/s²',
    to: 'ft/s²',
    factor: 3.28084,
  },
  {
    from: 'm/s^2',
    to: 'ft/s^2',
    factor: 3.28084,
  },
  {
    from: 'km/h',
    to: 'mph',
    factor: 0.621371,
  },
  {
    from: 'km',
    to: 'mi',
    factor: 0.621371,
  },
  {
    from: 'L/h',
    to: 'GPH',
    factor: 0.264172,
  },
];

export const IMPERIAL_COUNTRIES = [
  'USA', //
  'United States',
  'Liberia',
  'Myanmar',
];

/** @type {{[key: string]: UnitData}} */
export const SI_TO_IMPERIAL = {};

/** @type {{[key: string]: UnitData}} */
export const IMPERIAL_TO_SI = {};

for (const unit of UNIT_CONVERSION) {
  SI_TO_IMPERIAL[unit.from] = unit;
  IMPERIAL_TO_SI[unit.to] = unit;
}
