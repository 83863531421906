import { T } from '@/assets/locales';
import { ItemStatus } from '@/web/@components/ItemStatus';
import { LongMessageViewer } from '@/web/@components/LongMessageViewer';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { Box } from '@mui/material';
import { TriggerNameColumn } from '../../@components/TriggerNameColumn';
import { EllipsisTextViewContainer } from '@/web/@components/EllipsisTextView';

/** @type {Array<TableColumn<CompositeTriggerDto & {category: TriggerCategoryDetails}>>} */
export const TRIGGER_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    isPrimary: true,
    width: '10px',
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} disabled={false} />,
  },
  {
    id: 'name',
    label: T['triggers.table.columns.name'],
    align: 'left',
    isPrimary: true,
    maxWidth: '400px',
    format: (value, row) => (
      <Box height="35px" pt="6px">
        <EllipsisTextViewContainer>
          <TriggerNameColumn row={row} />
        </EllipsisTextViewContainer>
      </Box>
    ),
  },
  {
    id: 'version',
    label: T['triggers.table.columns.version'],
    align: 'left',
  },
  {
    id: 'publicationStatus',
    label: T['triggers.table.columns.publication.status'],
    align: 'left',
    format: (value) => <ItemStatus status={value} />,
  },
  {
    id: 'description',
    label: T['triggers.table.columns.description'],
    align: 'left',
    format: (value, row) => (
      <LongMessageViewer message={row?.category?.description || ''} title="Trigger Description" />
    ),
  },
];
