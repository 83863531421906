import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { DeviceUsagesLayout } from '../../../../@contexts/DeviceUsagesContext';
import { USAGES_TAB_ITEMS } from '../../@assets/usages';

export function CameraUsages() {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      py={1}
      px={3}
      mb={10}
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
      }}
    >
      <Box mb={2}>
        <Typography variant="body2" fontSize="1.10rem" p={1}>
          Usages
        </Typography>
        <Divider />
      </Box>

      <DeviceUsagesLayout>
        {USAGES_TAB_ITEMS.map((item, index) => (
          <Accordion
            key={index + item.key}
            disableGutters
            elevation={0}
            square
            TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
            expanded={expanded === item.key}
            onChange={handleChange(item.key)}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDown fontSize="large" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{item.label}</Typography>
            </AccordionSummary>
            <AccordionDetails
              key={item.key}
              sx={{ height: '100%', borderTop: '1px solid #c5d9f0', overflowY: 'auto' }}
            >
              {item.component || 'No Data Found'}
            </AccordionDetails>
          </Accordion>
        ))}
      </DeviceUsagesLayout>
    </Box>
  );
}
