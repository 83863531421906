import api from '@/api';
import { store } from '@/store';
import { selectSecretToken } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { chunk } from 'lodash';
import { createContext, useCallback, useEffect, useState } from 'react';

const defaultSource = 'vid_1';

/** @type {SmartCache<VideoThumbnailData>} */
const thumbnailCache = new SmartCache(`${defaultSource}.thumbnails`, 30 * 60 * 1000);

const thumbnailsBatchSize = 20;

/**
 * @typedef {object} CameraThumbnailContextData
 * @property {{[key: string]: VideoThumbnailData}} thumbnails
 * @property {(endpointId: string, data: VideoThumbnailData) => any} updateThumbnail
 */

/** @type {import('react').Context<CameraThumbnailContextData>} */
export const CameraThumbnailContext = createContext(null);

/** @param {{cameras: Array<EndpointInfoAggregated>, children: any}} props */
export function CameraThumbnailProvider(props) {
  const { cameras } = props;

  /** @type {StateVariable<{[key: string]: VideoThumbnailData}>} */
  const [thumbnails, setThumbnails] = useState({});

  const updateThumbnail = useCallback(
    /**
     * @param {string} endpointId
     * @param {VideoThumbnailData} data
     */
    async (endpointId, data) => {
      setThumbnails((v) => ({ ...v, [endpointId]: data }));
      await thumbnailCache.setItem(endpointId, data).catch(console.error);
    },
    []
  );

  useEffect(() => {
    /** @param {Set<number>} endpointIds */
    const resolve = async (endpointIds) => {
      /** @type {{[key: string]: VideoThumbnailData}} */
      const updates = {};
      try {
        for (const id of endpointIds) {
          const item = await thumbnailCache.getItem(id);
          if (item) {
            updates[id] = item;
            if (Date.now() - item.ts < 5 * 60 * 1000) {
              endpointIds.delete(id);
            }
          }
        }
        if (!endpointIds.size) {
          return;
        }
        const state = store.getState();
        const request = api.ac.v5.media.snapshots.latest.$get({
          headers: {
            Authorization: selectSecretToken(state),
          },
          params: {
            endpointIds: [...endpointIds],
          },
        });
        await request.process();
        for (const snapshot of request?.result?.snapshots) {
          const url =
            snapshot.snapshots.find((x) => x.source === defaultSource) || snapshot.snapshots[0];
          if (!url?.downloadUrl) continue;
          /** @type {VideoThumbnailData} */
          const item = {
            ts: Date.now(),
            source: url.source,
            src: url.downloadUrl,
          };
          updates[snapshot.endpointId] = item;
          await thumbnailCache.setItem(snapshot.endpointId, item);
        }
        for (const id of endpointIds) {
          updates[id] ||= { error: new Error('Not found') };
        }
      } catch (err) {
        console.error('Failed to fetch thumbnails', err);
        for (const id of endpointIds) {
          updates[id] ||= { error: err };
        }
      } finally {
        if (Object.keys(updates).length) {
          setThumbnails((v) => ({ ...v, ...updates }));
        }
      }
    };
    const tidList = chunk(cameras, thumbnailsBatchSize).map((batch, i) => {
      const endpointIds = new Set(batch.map((x) => Number(x.endpointId)));
      return setTimeout(() => resolve(endpointIds), 300 * i);
    });
    return () => {
      tidList.forEach(clearTimeout);
    };
  }, [cameras]);

  return (
    <CameraThumbnailContext.Provider value={{ thumbnails, updateThumbnail }}>
      {props.children}
    </CameraThumbnailContext.Provider>
  );
}
