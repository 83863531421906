import { DBHelper } from './db_helper';

/**
 * @typedef {string|number} SmartCacheKey
 */

const DATABASE_NAME = 'Smarter AI Dashboard';
const DATABASE_VERSION = 1;

/**
 * @template T
 * @typedef DBItem<T>
 * @property {any} key
 * @property {T} value
 * @property {number} expiresAt
 */

/**
 * @template T
 * @type {SmartCache<T>}
 */
export class SmartCache {
  /** @type {DBHelper} */
  #db;
  /** @type {number} */
  defaultExpiry = 24 * 3600 * 1000;

  /**
   * @param {string} storeName
   * @param {number} [defaultExpiry] Default: 24 * 3600 * 1000
   * @param {LocalForageOptions} [options]
   */
  constructor(storeName, defaultExpiry, options) {
    if (!storeName) {
      throw new Error('Store name is required');
    }
    if (defaultExpiry && defaultExpiry > 0) {
      this.defaultExpiry = defaultExpiry;
    }
    this.#db = new DBHelper({
      name: DATABASE_NAME,
      version: DATABASE_VERSION,
      ...options,
      storeName: this.#makeKey(storeName),
    });
    this.#cleanExpired();
  }

  #makeKey = (/** @type {any} */ key) => {
    return (key + '')
      .replace(/[^\w\d]+/, ' ')
      .trim()
      .replace(' ', '_');
  };

  #keys = async function* () {
    for (let key of await this.#db.readAllKeys()) {
      yield key + '';
    }
  };

  #cleanExpired = async () => {
    const tbd = [];
    for await (const key of this.#keys()) {
      const item = await this.#db.readItem(key);
      if (item && item.expiresAt > Date.now()) continue;
      tbd.push(key);
    }
    await this.removeItem(...tbd);
  };

  async clear() {
    for await (const key of this.#keys()) {
      await this.#db.deleteItem(key);
    }
  }

  /**
   * @param {SmartCacheKey[]} keys
   */
  async removeItem(...keys) {
    for (let key of keys) {
      await this.#db.deleteItem(this.#makeKey(key));
    }
  }

  /**
   * @param {SmartCacheKey} key
   * @returns {Promise<T>}
   */
  async getItem(key) {
    /** @type {DBItem<T>} */
    const item = await this.#db.readItem(this.#makeKey(key));
    if (item && item.expiresAt > Date.now()) {
      return item.value;
    } else {
      await this.#db.deleteItem(this.#makeKey(key));
      return undefined;
    }
  }

  /**
   * @param {SmartCacheKey} key
   * @param {T} value
   * @param {number} [expiresAt]
   */
  async setItem(key, value, expiresAt) {
    expiresAt ||= Date.now() + this.defaultExpiry;
    if (expiresAt <= Date.now()) return;
    const item = { key, value, expiresAt };
    await this.#db.writeItem(this.#makeKey(key), item);
  }

  /**
   * @yields {DBItem<T>}
   */
  async *getAll() {
    const tbd = [];
    for await (const key of this.#keys()) {
      /** @type {DBItem<T>} */
      const item = await this.#db.readItem(key);
      if (item && item.expiresAt > Date.now()) {
        yield item;
      } else {
        tbd.push(key);
      }
    }
    for (let key of tbd) {
      await this.#db.deleteItem(key);
    }
  }

  /**
   * @yields {T}
   */
  async *getAllValues() {
    for await (const item of this.getAll()) {
      yield item.value;
    }
  }

  /**
   * @yields {string}
   */
  async *getAllKeys() {
    for await (const key of this.#keys()) {
      yield key.split(',', 2)[1];
    }
  }
}
