import { Checkbox, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { isFunction } from 'lodash';
import { useMemo } from 'react';
import { PageLink } from '../PageLink/index';

/**
 * @template T
 * @typedef {object} DialogTableBodyCellProps
 * @property {Array<TableColumn<T>>} columns
 * @property {Array<T>} selection
 * @property {T} item
 * @property {(item: T, checked: boolean) => any} onSelect
 */

/**
 * @template T
 * @param {DialogTableBodyCellProps<T>} props
 */
export function DialogTableBodyCell(props) {
  const { columns, selection, item, onSelect } = props;

  const selected = useMemo(() => selection?.indexOf(item) !== -1, [selection, item]);

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      selected={selected}
      onClick={() => onSelect(item, !selected)}
    >
      {columns.map((column, index) => (
        <TableCell
          key={column.id + index}
          align={column.align}
          padding={'normal'}
          style={{
            minWidth: column.minWidth,
            width: column.width,
            fontSize: '0.875rem',
          }}
        >
          {column?.type === 'CHECKBOX' ? (
            <Checkbox color="primary" checked={selected} />
          ) : column?.format ? (
            column.format(item[column.id], item)
          ) : 'link' in column ? (
            <PageLink to={isFunction(column.link) ? column.link(item) : column.link}>
              <Tooltip
                title={isFunction(column.tooltip) ? column.tooltip(item) : column.tooltip}
                disableFocusListener
                arrow
                enterDelay={500}
                leaveDelay={200}
                placement="bottom-start"
              >
                <Typography component="span" fontWeight={500} fontSize={'0.875rem'}>
                  {item[column.id] || column?.fallback || '...'}
                </Typography>
              </Tooltip>
            </PageLink>
          ) : (
            item[column.id] || column?.fallback || '...'
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}
