import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { isUndefined } from 'lodash';

export function CameraDigitalIo() {
  const { capabilities } = useContext(CameraDetailsContext);

  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={6} md={3}>
        <Typography variant="body1" fontSize="1rem" fontWeight="600">
          Camera Cover
        </Typography>
        <Typography variant="body2" fontSize="0.875rem">
          {capabilities?.IOCover ? (capabilities?.IOCover ? 'Closed' : 'Opened') : 'Unavailable'}
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="body1" fontSize="1rem" fontWeight="600">
          Digital Input (Brown)
        </Typography>
        <Typography variant="body2" fontSize="0.875rem">
          {!isUndefined(capabilities['Digital_Input.Brown'])
            ? capabilities['Digital_Input.Brown'] === '1'
              ? 'High'
              : 'Low'
            : 'Unavailable'}
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="body1" fontSize="1rem" fontWeight="600">
          Digital Input (Yellow)
        </Typography>
        <Typography variant="body2" fontSize="0.875rem">
          {!isUndefined(capabilities['Digital_Input.Yellow'])
            ? capabilities['Digital_Input.Yellow'] === '1'
              ? 'High'
              : 'Low'
            : 'Unavailable'}
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="body1" fontSize="1rem" fontWeight="600">
          Digital Input (Purple)
        </Typography>
        <Typography variant="body2" fontSize="0.875rem">
          {!isUndefined(capabilities['Digital_Input.Purple'])
            ? capabilities['Digital_Input.Purple'] === '1'
              ? 'High'
              : 'Low'
            : 'Unavailable'}
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="body1" fontSize="1rem" fontWeight="600">
          Digital Output (Gray)
        </Typography>
        <Typography variant="body2" fontSize="0.875rem">
          {!isUndefined(capabilities['Digital_Output.Gray'])
            ? capabilities['Digital_Output.Gray'] === '1'
              ? 'High'
              : 'Low'
            : 'Unavailable'}
        </Typography>
      </Grid>
    </Grid>
  );
}
