import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

/** @type {Array<keyof import('./slice').InitialState>} */
const blacklist = [
  // add state items you want to exclude from local storage
  'tokenRefreshing',
];

/** @type {import('redux-persist').PersistConfig<AuthState>} */
export const authPersistConfig = {
  key: 'auth',
  version: 7,
  storage,
  blacklist,
  stateReconciler: hardSet,
  migrate: async (state, version) => {
    if (state._persist.version !== version) return null;
    return state;
  },
};
