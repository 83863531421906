import { licenseTimestampFormat } from '@/utils/datetime';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Grid, Typography } from '@mui/material';
import { DriverImageNotAvailable } from '../DriverImageNotAvailable';
import { DriverLicenseImage } from '../DriverLicenseImage';

/**
 * @typedef {object} DriversBasicInformationProps
 * @property {Driver} [driver]
 */

/**
 * @param {DriversBasicInformationProps} props
 */

export function DriversBasicInformation(props) {
  const { driver } = props;

  return (
    <Box p={2.5}>
      <Typography variant="body1" mb={5}>
        Driver Details
      </Typography>
      <Box
        display={{ sx: 'grid', md: 'flex' }}
        justifyContent="space-between"
        alignItems="flex-start"
        gap={10}
        sx={{
          '& .MuiAvatar-square > img': {
            objectFit: 'fill',
            userSelect: 'none',
            pointerEvents: 'none',
          },
        }}
      >
        <Box width="330px">
          <BoxImage
            width="100%"
            height="250px"
            loading="eager"
            alt={'driver photo'}
            src={driver?.pictureUrl}
            objectFit="cover"
            objectPosition="center"
            fallback={
              <Box
                width="100%"
                height="250px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                bgcolor="#F5F5F5"
                gap="5px"
              >
                <DriverImageNotAvailable />
              </Box>
            }
          />
        </Box>
        <Box width="100%">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px">
                First Name
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {driver?.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px">
                Last Name
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {driver?.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px">
                Email
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {driver?.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px">
                Phone Number
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {driver?.phone}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px">
                License Number
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {driver?.licenseNo}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px">
                Issuance Date
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {licenseTimestampFormat(driver?.licenseIssuanceDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px">
                Expiration Date
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {licenseTimestampFormat(driver?.licenseExpirationDate)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px" mb={1}>
                License Front Copy
              </Typography>
              <DriverLicenseImage licenseImage={driver?.licenseImageFrontUrl} type="front" />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" lineHeight="18px" mb={1}>
                License Back Copy
              </Typography>
              <DriverLicenseImage licenseImage={driver?.licenseImageBackUrl} type="back" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
