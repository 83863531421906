// @ts-nocheck
import api from '@/api';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const filterCoachingEvents = (eventList) => {
  const coachingEvents = eventList.filter((item) =>
    Boolean(
      item &&
        item.uploadCompleted &&
        item.recordingEndTimestamp &&
        item.recordingStartTimestamp &&
        item.recordingEndTimestamp - item.recordingStartTimestamp > 100 &&
        item.recordingEndTimestamp - item.recordingStartTimestamp < 60 * 3600
    )
  );
  return coachingEvents;
};

/**
 * @typedef {object} CoachingEndpointDetailsContextData
 * @property {boolean} loading
 * @property {number} endpointId
 * @property {Array<UserWithAccountDetails>} users
 * @property {Endpoint} endpoint
 * @property {EventV5ResponseModel[]} events
 * @property {number} visitedCount
 * @property {number} totalCount
 * @property {number} startIndex
 * @property {number} currentSessionId
 * @property {{[key: string]: boolean}} visited
 * @property {(eventId: string) => any} markVisited
 * @property {EventV5ResponseModel | null} selectedEvent
 * @property {(index: number) => any} updateSelection
 * @property {CoachingSessionDto} currentSessionInfo
 * @property {(any) => any} setEvents
 */

// TODO: temporary visit status tracking. replace this with API
/** @type {SmartCache<string[]>} */
const visitedCache = new SmartCache('visited-event-tracking', 365 * 24 * 3600 * 1000);

/** @type {import('react').Context<CoachingEndpointDetailsContextData>} */
export const CoachingEndpointDetailsContext = createContext(null);

/** @param {{children: import('react').ReactNode}} props */
export function CoachingEndpointDetailsContextProvider(props) {
  const params = useParams();
  const { query } = useRouteQuery();

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  const [sessionDataLoading, setSessionDataLoading] = useState(true);
  /** @type {StateVariable<EventV5ResponseModel[]>} */
  const [events, setEvents] = useState([]);
  /** @type {StateVariable<Endpoint>} */
  const [endpoint, setEndpoint] = useState();
  /** @type {StateVariable<{[key: string]: boolean}>} */
  const [visited, setVisited] = useState({});
  /** @type {StateVariable<number>} */
  const [startIndex, setStartIndex] = useState(0);
  /** @type {StateVariable<number>} */
  const [currentSessionId, setCurrentSessionId] = useState(null);
  /** @type {StateVariable<CoachingSessionDto></CoachingSessionDto>}  */
  const [currentSessionInfo, setCurrentSessionInfo] = useState(null);

  const [users, setUsers] = useState(null);

  const endpointId = useMemo(() => Number(params['endpointId'] || ''), [params]);

  const queryOrRouteSessionId = useMemo(
    () => Number(query?.sessionId || params?.sessionId) || null,
    [query?.sessionId, params?.sessionId]
  );

  const visitedCount = useMemo(
    () => Object.keys(visited).filter((x) => visited[x]).length,
    [visited]
  );

  const totalCount = useMemo(() => events?.length || 0, [events?.length]);

  const selectedEvent = useMemo(
    () => (events?.length ? events[startIndex] : null),
    [startIndex, events]
  );

  useEffect(() => {
    if (!events?.length) return;
    // TODO: get it from an API
    visitedCache.getItem(endpointId).then((items) => {
      let start = false;
      /** @type {{[key: string]: boolean}} */
      const data = {};
      for (let i = 0; i < events.length; ++i) {
        const id = events[i]?.id;
        if (!id) continue;
        if (items && items[id]) {
          data[id] = true;
        } else if (!start) {
          start = true;
          setStartIndex(i);
        }
      }
      setVisited(data);
      if (!start) {
        setStartIndex(events.length - 1);
      }
    });
  }, [endpointId, events]);

  /** @type {CoachingEndpointDetailsContextData['markVisited']} */
  const markVisited = useCallback(
    (eventId) => {
      setVisited((v) => ({ ...v, [eventId]: true }));
      // TODO: call an API to update
      visitedCache.getItem(endpointId).then((v) => {
        visitedCache.setItem(endpointId, { ...v, ...visited });
      });
    },
    [visited, endpointId]
  );

  /** @type {CoachingEndpointDetailsContextData['updateSelection']} */
  const updateSelection = useCallback(
    (index) => {
      setStartIndex(Math.max(0, Math.min(totalCount - 1, index)));
    },
    [totalCount]
  );

  const getAllSessions = useCallback(
    async (type = 'GUIDE') => {
      try {
        const request = api.ac.v5.coaching.sessions.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit: 50,
            offset: 0,
            targetId: endpointId?.toString(),
            fromTimestamp: 0,
            coachingSessionType: type,
            coachingByTarget: 'ENDPOINT',
            toTimestamp: Date.now(),
          },
        });
        const result = await request.process();
        const activeSessions = result?.coachingSessions?.filter((item) =>
          ['CREATED', 'STARTED']?.includes(item?.coachingSessionStatus)
        );
        return activeSessions;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    [endpointId, secretToken]
  );

  const getSessionInformation = useCallback(
    async (sessionId) => {
      try {
        const request = api.ac.v5.coaching.sessions.$sessionId(Number(sessionId)).$get({
          headers: {
            Authorization: secretToken,
          },
        });
        const result = await request?.process();
        return result;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    [secretToken]
  );

  useEffect(() => {
    try {
      const request = api.ac.v5.endpoint.$endpointId(endpointId).details.$get({
        headers: {
          Authorization: secretToken,
        },
      });
      request
        .process()
        .then((r) => setEndpoint(r))
        .catch(console.error)
        .finally(() => setLoading(false));
    } catch (error) {
      console.error(error);
    }
  }, [secretToken, endpointId]);

  useEffect(() => {
    const request = api.ac.v5.tenant.$tenantId(tenantId).user.$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        limit: 1000,
        offset: 0,
      },
    });
    request
      .process()
      .then((r) => setUsers(r?.users))
      .catch(console.error);
  }, [secretToken, tenantId]);

  useEffect(() => {
    const fetchSessionData = async (sessionId) => {
      try {
        const result = await getSessionInformation(sessionId);
        const coachingEvents = filterCoachingEvents(result?.eventList);
        setEvents(coachingEvents || []);
        setCurrentSessionInfo(result);
      } catch (error) {
        setEvents([]);
      } finally {
        setSessionDataLoading(false);
      }
    };

    const initializeSessions = async () => {
      if (queryOrRouteSessionId) {
        setCurrentSessionId(queryOrRouteSessionId);
        await fetchSessionData(queryOrRouteSessionId);
      } else {
        try {
          const allSessions = await getAllSessions();
          const sessionId = allSessions?.at(0)?.sessionId;

          if (!sessionId) {
            setEvents([]);
            setSessionDataLoading(false);
            return;
          }

          setCurrentSessionId(sessionId);
          await fetchSessionData(sessionId);
        } catch (error) {
          console.error(error);
          setEvents([]);
          setSessionDataLoading(false);
        }
      }
    };
    setSessionDataLoading(true);
    initializeSessions();
  }, [secretToken, endpointId, queryOrRouteSessionId, getSessionInformation, getAllSessions]);

  return (
    <CoachingEndpointDetailsContext.Provider
      children={props.children}
      value={{
        users,
        endpointId,
        loading: loading && sessionDataLoading,
        endpoint,
        events,
        visited,
        markVisited,
        startIndex,
        totalCount,
        visitedCount,
        selectedEvent,
        currentSessionId,
        currentSessionInfo,
        updateSelection,
        setEvents,
      }}
    />
  );
}
