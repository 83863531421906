import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const onboardingSettings = [
  {
    title: 'Onboarding',
    key: GROUP_SETTINGS_CATEGORIES.ONBOARDING,
    children: [
      {
        label: 'Pairing after Onboard',
        key: 'pairingAfterOnboard',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Pairing on Connectivity Lost',
        key: 'pairingOnConnectivityLost',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Connectivity Timeout',
        key: 'connectivityTimeout',
        type: FROM_TYPES.INPUT,
        postText: 'min',
        min: 0,
        inputType: 'number',
        isDisabled: (watch) => !watch('pairingOnConnectivityLost'),
      },
    ],
  },
];
