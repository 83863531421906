import { Backdrop, CircularProgress } from '@mui/material';

/**
 * @typedef {object} BackdropProgressProps
 * @property {boolean} isOpen
 */

/** @param {BackdropProgressProps} props */

export function BackdropProgress(props) {
  const { isOpen } = props;
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
