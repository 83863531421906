import { TRANSFER_ICON } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<Device>>} */
export const ACTION_ITEMS = [
  // {
  //   label: T['button.discontinue'],
  //   iconImage: BOX_DISCONTINUE,
  //   component: 'BUTTON',
  //   type: 'DISCONTINUE',
  //   variant: 'outlined',
  //   hidden: (selected) => !selected.length,
  //   confirmationMessage: T['device-pool.device.confirmations.discontinue'],
  // },
  {
    type: 'TRANSFER',
    label: 'Transfer',
    iconImage: TRANSFER_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    useCustomDialog: true,
  },
  {
    label: 'Search',
    component: 'SEARCH',
    placeholder: 'Search Device',
  },
  {
    type: 'CREATE',
    label: 'Device',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    useCustomDialog: true,
  },
];
