import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';
import {
  sensorOptionsFour,
  sensorOptionsOne,
  sensorOptionsThree,
  sensorOptionsTwo,
  sensorTypeGPSOptions,
  sensorTypeOptions,
} from '../options';

/** @type {Array<GroupSettingsItems>} */
export const sensorsPipelineSettings = [
  {
    title: 'Sensor Pipelines',
    key: GROUP_SETTINGS_CATEGORIES.SENSOR,
    children: [
      {
        label: 'Accelerometer',
        key: 'accelerometer',
        type: 'group',
        children: [
          {
            label: 'LibIPC Pipeline',
            key: 'accIPC',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsOne,
          },
          {
            label: 'LibIPC Pipeline Type',
            key: 'accTypeIPC',
            type: FROM_TYPES.SELECT,
            options: sensorTypeOptions,
          },
          {
            label: 'Logging Pipeline',
            key: 'accLog',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsTwo,
          },
          {
            label: 'Logging Pipeline Type',
            key: 'accTypeLog',
            type: FROM_TYPES.SELECT,
            options: sensorTypeOptions,
          },
          {
            label: 'Recording Pipeline',
            key: 'accRec',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsOne,
          },
          {
            label: 'Recording Pipeline Type',
            key: 'accTypeRec',
            type: FROM_TYPES.SELECT,
            options: sensorTypeOptions,
          },
          {
            label: 'Streaming Pipeline',
            key: 'accStr',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsThree,
          },
          {
            label: 'Streaming Pipeline Type',
            key: 'accTypeStr',
            type: FROM_TYPES.SELECT,
            options: sensorTypeOptions,
          },
          {
            label: 'Trigger Pipeline',
            key: 'accTri',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsOne,
          },
          {
            label: 'Trigger Pipeline Type',
            key: 'accTypeTri',
            type: FROM_TYPES.SELECT,
            options: sensorTypeOptions,
          },
          {
            label: 'Vision Pipeline',
            key: 'accVis',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsOne,
          },
          {
            label: 'Vision Pipeline Type',
            key: 'accTypeVis',
            type: FROM_TYPES.SELECT,
            options: sensorTypeOptions,
          },
          {
            label: 'Override Recording Pipeline with Trigger Pipeline',
            key: 'accOverrideRecordingPipeWithTriggerPipe',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Override Vision Pipeline with Trigger Pipeline',
            key: 'accOverrideVisionPipeWithTriggerPipe',
            type: FROM_TYPES.SWITCH,
          },
        ],
      },
      {
        label: 'Gyroscope',
        key: 'gyroscope',
        children: [
          {
            label: 'LibIPC Pipeline',
            key: 'gyroIPC',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsFour,
          },
          {
            label: 'Logging Pipeline',
            key: 'gyroLog',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsFour,
          },
          {
            label: 'Recording Pipeline',
            key: 'gyroRec',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsFour,
          },
          {
            label: 'Streaming Pipeline',
            key: 'gyroStr',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsThree,
          },
          {
            label: 'Trigger Pipeline',
            key: 'gyroTri',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsFour,
          },
          {
            label: 'Vision Pipeline',
            key: 'gyroVis',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsFour,
          },
          {
            label: 'Override Recording Pipeline with Trigger Pipeline',
            key: 'gyroOverrideRecordingPipeWithTriggerPipe',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Override Vision Pipeline with Trigger Pipeline',
            key: 'gyroOverrideVisionPipeWithTriggerPipe',
            type: FROM_TYPES.SWITCH,
          },
        ],
      },
      {
        label: 'GPS',
        key: 'GPS',
        children: [
          {
            label: 'LibIPC Pipeline',
            key: 'gpsIPC',
            type: FROM_TYPES.SELECT,
            options: sensorTypeGPSOptions,
          },
          {
            label: 'Logging Pipeline',
            key: 'gpsLog',
            type: FROM_TYPES.SELECT,
            options: sensorTypeGPSOptions,
          },
          {
            label: 'Recording Pipeline',
            key: 'gpsRec',
            type: FROM_TYPES.SELECT,
            options: sensorTypeGPSOptions,
          },
          {
            label: 'Streaming Pipeline',
            key: 'gpsStr',
            type: FROM_TYPES.SELECT,
            options: sensorTypeGPSOptions,
          },
          {
            label: 'Trigger Pipeline',
            key: 'gpsTri',
            type: FROM_TYPES.SELECT,
            options: sensorTypeGPSOptions,
          },
          {
            label: 'Vision Pipeline',
            key: 'gpsVis',
            type: FROM_TYPES.SELECT,
            options: sensorTypeGPSOptions,
          },
          {
            label: 'Override Recording Pipeline with Trigger Pipeline',
            key: 'gpsOverrideRecordingPipeWithTriggerPipe',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Override Vision Pipeline with Trigger Pipeline',
            key: 'gpsOverrideVisionPipeWithTriggerPipe',
            type: FROM_TYPES.SWITCH,
          },
          {
            label: 'Automotive Dead Reckoning (ADR)',
            key: 'enableADR',
            type: FROM_TYPES.SWITCH,
          },
        ],
      },
      {
        label: 'OBD 2',
        key: 'obd2',
        children: [
          {
            label: 'Logged Data',
            key: 'loggedObd2',
            type: FROM_TYPES.OBD2,
          },
        ],
      },
      {
        label: 'CAN Bus',
        key: 'canbusSampling',
        children: [
          {
            label: 'CanBus Sampling Rate',
            key: 'canDataSamplingRate',
            type: FROM_TYPES.SELECT,
            options: sensorOptionsOne,
          },
        ],
      },
    ],
  },
];
