import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const deviceWifiConnectivitySettings = [
  {
    title: 'Connectivity',
    key: GROUP_SETTINGS_CATEGORIES.CONNECTIVITY,
    children: [
      {
        label: 'Wi-Fi Networks',
        key: 'wifiNetworks',
        type: FROM_TYPES.DEVICE_WIFI_CONNECTIVITY,
      },
    ],
  },
];
