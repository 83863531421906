import { Avatar, Box, Grid, Skeleton } from '@mui/material';

/**
 * @typedef {object} EventListPlaceholderProps
 * @property {import('react').CSSProperties['width']} [width]
 * @property {number} [itemsPerRow]
 */

/** @param {EventListPlaceholderProps} props */
export function EventListPlaceholder(props) {
  const width = props.width || 0;
  const itemsPerRow = Math.max(1, props.itemsPerRow || 1);
  const cols = 12 / itemsPerRow;
  const gridSize = { xs: 12, sm: cols, md: cols, lg: cols };

  return [...Array(itemsPerRow > 1 ? 0 : 7)?.keys()]?.map((itemIndex) => (
    <Grid container wrap="nowrap" key={itemIndex}>
      {[...Array(itemsPerRow)?.keys()].map((_, index) => (
        <Grid item key={index} {...gridSize}>
          <Box sx={{ width: width, height: 'fit-content', mb: 0.5 }}>
            <Box
              style={{
                width: '100%',
                aspectRatio: 16 / 9,
                borderRadius: '10px',
                overflow: 'hidden',
              }}
            >
              <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box flex={1}>
                <Skeleton animation="wave" height={20} width="90%" />
                <Skeleton animation="wave" height={14} width="80%" />
                <Skeleton animation="wave" height={14} width="60%" />
              </Box>
              <Box pt={0.5}>
                <Skeleton variant="circular" width={34} height={34}>
                  <Avatar />
                </Skeleton>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  ));
}
