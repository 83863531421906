import { Box, CircularProgress } from '@mui/material';
import { throttle } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { FixedSizeGrid, FixedSizeList } from 'react-window';
import { CenterBox } from '../CenterBox';
import { IconMessageBox } from '../IconMessageBox';
import { SHADOW_DISTANCE } from '../ScrollShadowBox';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';

/**
 * @template T
 * @param {InfiniteScrollFixedListViewProps<T> & import('@mui/material').BoxProps} props
 */
export function InfiniteScrollFixedListView(props) {
  const {
    state,
    itemKey,
    renderItem,
    variant,
    FixedListProps,
    FixedGridProps,
    renderFailure = (error, retry) => (
      <IconMessageBox
        size="128px"
        src={NO_SEARCH_RESULT_IMG}
        message="Sorry! Could not fetch data"
        onRetry={retry}
      />
    ),
    ...boxProps
  } = props;
  const { loading, results, error, finished, loadNext } = state;

  /** @type {import('react').Ref<HTMLDivElement>} */
  const listRef = useRef();
  /** @type {import('react').Ref<HTMLDivElement>} */
  const bottomRef = useRef();

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        const parent = listRef.current?.parentElement;
        if (!parent || !bottomRef.current) return;
        if (parent.scrollTop < parent.scrollHeight - parent.clientHeight - SHADOW_DISTANCE) {
          bottomRef.current.style.display = 'block';
        } else {
          bottomRef.current.style.display = 'none';
          if (!loading && !error && !finished) loadNext();
        }
      }, 100),
    [loading, error, finished, loadNext]
  );

  useEffect(() => {
    const iid = setInterval(handleScroll, 100);
    return () => {
      clearInterval(iid);
      handleScroll.cancel();
    };
  }, [handleScroll]);

  const itemCount = useMemo(
    () => results.length + (finished ? 0 : 1), //
    [results.length, finished]
  );
  const columnCount = useMemo(
    () => FixedGridProps?.columnCount || 1,
    [FixedGridProps?.columnCount]
  );
  const rowCount = useMemo(
    () => Math.ceil(itemCount / columnCount), //
    [itemCount, columnCount]
  );
  const columnWidth = useMemo(
    () => FixedGridProps?.width / columnCount,
    [FixedGridProps?.width, columnCount]
  );

  return (
    <Box position="relative" {...boxProps}>
      {variant === 'grid' ? (
        <FixedSizeGrid
          innerRef={listRef}
          useIsScrolling
          overscanRowCount={5}
          overscanColumnCount={5}
          columnWidth={columnWidth}
          {...FixedGridProps}
          rowCount={rowCount}
          columnCount={columnCount}
          onScroll={handleScroll}
          style={{ paddingBottom: '55px' }}
          className="infinite-scroll-fixed-grid"
          itemKey={({ columnIndex, rowIndex }) => {
            const index = rowIndex * columnCount + columnIndex;
            return itemKey && index < results.length ? itemKey(results[index]) : index;
          }}
          children={(itemProps) => {
            const index = itemProps.rowIndex * columnCount + itemProps.columnIndex;
            if (index < results.length) {
              return renderItem(
                {
                  index,
                  data: results[index],
                  style: itemProps.style,
                  isScrolling: itemProps.isScrolling,
                },
                results
              );
            }
            if (finished) {
              return null;
            }
            return (
              <CenterBox height={FixedGridProps.rowHeight} style={itemProps.style}>
                {error ? renderFailure(error, loadNext) : <CircularProgress size="24px" />}
              </CenterBox>
            );
          }}
        />
      ) : (
        <FixedSizeList
          innerRef={listRef}
          useIsScrolling
          overscanCount={5}
          {...FixedListProps}
          width="100%"
          onScroll={handleScroll}
          className="infinite-scroll-fixed-list"
          itemCount={itemCount}
          itemKey={(index) => (itemKey && index < results.length ? itemKey(results[index]) : index)}
          children={(itemProps) => {
            if (itemProps.index < results.length) {
              return renderItem(
                {
                  ...itemProps,
                  data: results[itemProps.index],
                },
                results
              );
            }
            if (finished) {
              return null;
            }
            return (
              <CenterBox height={FixedListProps.itemSize} style={itemProps.style}>
                {error ? renderFailure(error, loadNext) : <CircularProgress size="24px" />}
              </CenterBox>
            );
          }}
        />
      )}
      <Box ref={bottomRef} width="100%" className="scrollable-bottom-shadow" />
    </Box>
  );
}
