import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { filterAIModelsInputData } from '@/utils/ai-models';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AIModelForm } from '../@components/AIModelForm';
import { uploadAiModelFile } from '../utils';

export function CreateAIModelPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<AbortController>} */
  const [loading, setLoading] = useState();
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);

  /** @type {import('../@components/AIModelForm').AIModelFormProps['onSubmit']} */
  const createAIModel = async (fields) => {
    if (!fields.modelFile) {
      setProgressModalOpen(false);
      toastWarning('Please upload a model file');
      return;
    }

    const aborter = new AbortController();
    setLoading((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      setProgressModalOpen(true);

      /** @type {AIModelV2ForPost} */
      const body = {};
      body.properties = {};

      body.modelId = fields.modelId;
      body.name = fields.name;
      body.version = fields.version;
      body.description = fields.description;
      body.ownerTenantId = tenantId;
      body.tenantList = [{ tenantRole: 'Owner', tenantId }];

      body.properties.modelType = fields.modelType;
      body.properties.vendor = fields.vendor?.trim() || '';
      body.properties.modelParams = JSON.stringify(
        fields.modelParams.map(({ id, ...keepAttrs }) => keepAttrs)
      );

      body.inputLayer = fields.inputLayer?.trim() || '';
      body.outputLayer = fields.outputLayer?.trim() || '';
      body.inputSources = filterAIModelsInputData(fields.inputSources)?.map((x, i) => ({
        inputDescription: { id: i + 1 + '', ...x },
      }));
      body.outputSources = fields.outputSources?.map((source) => ({
        ...source,
        outputCode: source.outputType,
        properties: [],
      }));
      if (fields.aiAccelerator) {
        body.aiAccelerators = [fields.aiAccelerator.codeName];
      }
      body.type = 'DEFAULT';

      await uploadAiModelFile(fields.modelFile, body, setPercentage, aborter.signal);

      const request = api.ac.v2.repository.aimodel.$post({
        data: body,
        params: {
          secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', 'AI Model Added successfully');
      navigate('/administration/ai/ai-models');
    } catch (err) {
      if (!aborter.signal.aborted) {
        console.error('Failed to create', err);
        if (err.response?.data?.status === 'ALREADY_EXISTS_VERSION') {
          toastWarning('Error', 'This AI Model version already exists.');
        } else {
          toastWarning('Error', 'Could not create AI Model.');
        }
      }
    } finally {
      setLoading(undefined);
      setProgressModalOpen(false);
    }
  };

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Add New AI Model"
          progressText={'Uploading file...'}
          onClose={() => {
            loading?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['ai.model.create.page.title'])}
      </Typography>
      <AIModelForm onSubmit={createAIModel} disabled={Boolean(loading)} />
    </Box>
  );
}
