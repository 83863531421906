import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TriggerVariableForm } from '../@components/TriggerVariableForm';

export function TriggerVariableCreatePage() {
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createTriggerVariable = async (form) => {
    const {
      name,
      description,
      customerFacing,
      unit,
      type,
      defaultValue,
      variableSensitivity,
      constraint,
    } = form;

    const formData = {
      description,
      customerFacing: customerFacing?.value,
      unit,
      type: type?.value,
      defaultValue,
      sensitivity: JSON.stringify(variableSensitivity),
      constraint: JSON.stringify(constraint),
    };

    try {
      setLoading(true);
      const req = api.ac.v5.trigger.variable.definition.$variableName(name).$post({
        headers: {
          Authorization: secretToken,
        },
        data: formData,
      });
      await req.process();
      toastSuccess('Success', 'Variable created successfully');
      navigate('/smarter-ai/trigger-variables');
    } catch (err) {
      toastWarning('Error', 'Failed to create variable');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['trigger.variable.form.create.title'])}
      </Typography>
      <TriggerVariableForm onSubmitData={createTriggerVariable} isLoading={loading} />
    </Box>
  );
}
