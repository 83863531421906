import { SearchField } from '@/web/@components/SearchField';
import { SetTripFilterContext, TripFilterContext } from '@/web/trips/@components/TripFilterContext';
import { Box } from '@mui/material';
import { useContext } from 'react';

export function TripsItemSearch(props) {
  const { cameraId } = props;
  const setTripFilter = useContext(SetTripFilterContext);
  const { driverName, endpointName } = useContext(TripFilterContext);

  /**
   *  @param {string} [text]
   *  @param {string} [key]
   */
  const handleSearchTextChange = (text, key) => {
    setTripFilter({
      [key]: text?.trim(),
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={1}
      columnGap={0.8}
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
    >
      <SearchField
        clearable
        searchOnEnter
        value={driverName}
        hideSearchIcon={true}
        onSearch={(text) => handleSearchTextChange(text, 'driverName')}
        placeholder="Driver Name"
        InputProps={{ fullWidth: false }}
        sx={{ width: { xs: '100%', sm: '48%', md: 200, xl: 250 } }}
      />

      {!cameraId && (
        <SearchField
          clearable
          searchOnEnter
          value={endpointName}
          hideSearchIcon={true}
          onSearch={(text) => handleSearchTextChange(text, 'endpointName')}
          placeholder="Camera Name"
          InputProps={{ fullWidth: false }}
          sx={{ width: { xs: '100%', sm: '48%', md: 200, xl: 250 } }}
        />
      )}
    </Box>
  );
}
