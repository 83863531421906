import { T } from '@/assets/locales';
import { AiModelListView } from '@/web/administration/ai/ai-models';
import { Navigate } from 'react-router-dom';
import { CreateAIModelPage } from './create';
import { AIModelEditPage } from './_id/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <AiModelListView />,
  },
  {
    path: 'create',
    element: <CreateAIModelPage />,
    breadcrumb: T['ai.models.create.button'],
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        element: <Navigate to=".." />,
      },
      {
        path: 'edit',
        element: <AIModelEditPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
