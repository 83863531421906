import { T } from '@/assets/locales';
import { store } from '@/store';
import { PageView } from '@/store/page-view';
import { formatDateForLiveTracking } from '@/utils/datetime';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LiveTrackingFilteringArea } from './@components/FilteringArea';
import { LiveTrackingMap } from './@components/LiveTrackingMap';

export function LiveTrackingPage() {
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    status: [],
  });

  useEffect(() => {
    const tid = setTimeout(() => {
      store.dispatch(PageView.setFullWidthLayout(true));
    }, 100);
    return () => clearTimeout(tid);
  }, []);

  return (
    <Box mx={2.5} mt={2} mb={5} height="calc(100% - 130px)" width="calc(100% - 40px)">
      <Box mb={1} display="flex" justifyContent={'space-between'} alignItems={'center'}>
        <Typography component="span" variant="body2" fontWeight="500">
          {t(T['navigation.fleet.live.tracking'])}
        </Typography>
        <Typography component="span" variant="subtitle2">
          {formatDateForLiveTracking(Date.now())}
        </Typography>
      </Box>

      <LiveTrackingFilteringArea value={filters} onChange={setFilters} />
      <LiveTrackingMap filters={filters} />
    </Box>
  );
}
