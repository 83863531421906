import { BOX_DISCONTINUE, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { store } from '@/store';
import { selectUserRole } from '@/store/auth';
import { USER_LEVELS } from '@/utils/user-access';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<UserWithAccountDetails>>} */
export const ACTION_ITEMS = [
  {
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selectedItem) =>
      `/administration/users/${selectedItem.userId}/edit/${selectedItem.accountId}`,
    hidden: (selected) => selected.length !== 1 || !selected[0]?.active,
  },
  {
    label: T['button.deactivate'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    confirmationMessage: T['users.confirmations.discontinue'],
    hidden: (selected) => {
      const loggedUserRole = selectUserRole(store.getState());
      const hasAnyUpperRoleItem = selected?.some(
        (item) => USER_LEVELS[item?.role] > USER_LEVELS[loggedUserRole]
      );
      return !selected.length || hasAnyUpperRoleItem;
    },
  },
  {
    component: 'SEARCH',
    label: 'Search',
    placeholder: 'Search User',
    options: [
      { value: 'name', label: 'Name' },
      { value: 'email', label: 'Email' },
      { value: 'country', label: 'Country' },
    ],
  },
  {
    label: 'User',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/users/create',
  },
];
