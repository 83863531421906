import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { PageLink } from '@/web/@components/PageLink';
import { DriverItem } from '@/web/fleets/@components/DriverItem';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} FleetDriversScoreProps
 * @property {'TOP'|'BOTTOM'} [order]
 * @property {string} [title]
 * @property {string} [linkText]
 */

/**
 * @param {FleetDriversScoreProps} props
 */
export function FleetDriversScore(props) {
  const { order, title, linkText } = props;
  const theme = useTheme();

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    const request = api.ac.v5.driver['rank-list'].$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        count: 5,
        // @ts-ignore
        offset: 0,
        rankOrder: order,
        tenantId,
      },
    });
    request
      .process()
      .then((data) => {
        setDrivers(data.list);
      })
      .catch((ex) => {});
  }, [tenantId, secretToken, order]);

  const visibleItemCount = xlAndUp ? 5 : mdAndUp ? 4 : smAndUp ? 3 : 1;

  const items = useMemo(() => drivers.slice(0, visibleItemCount), [visibleItemCount, drivers]);

  if (drivers.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} color="#0B2547" sx={{ opacity: 0.68 }}>
          {title}
        </Typography>
        <PageLink fontSize={'0.875rem'} fontWeight={600} to={`/fleets/drivers?order=${order}`}>
          {linkText}
        </PageLink>
      </Box>
      <Grid container columnSpacing={4}>
        {items?.map((driver, index) => (
          <Grid key={index} item xs={12} sm={4} md={3} xl={2.4}>
            <Box display="grid" position="relative" gap="5px">
              <DriverItem driver={driver} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
