import { sortedIndex, sortedIndexOf, sortedLastIndex } from 'lodash';
import { IntervalWatcher } from './watcher';

/**
 * @template T
 * @type {TimestampStore<T>}
 * @augments IntervalWatcher
 */
export class TimestampStore extends IntervalWatcher {
  /** @type {Array<number>} */
  $keys = [];
  /** @type {Array<T>} */
  $values = [];

  /**
   * Gets the length of the map
   */
  get length() {
    return this.$keys.length;
  }

  get keys() {
    return this.$keys;
  }

  get values() {
    return this.$values;
  }

  get entries() {
    return this.$keys.map((key, i) => ({ ts: key, value: this.$values[i] }));
  }

  /**
   * Clears the store
   */
  clear() {
    this.$keys = [];
    this.$values = [];
  }

  /**
   * Insert an entry. O(n)
   * @param {number} ts Timestamp
   * @param {T} value
   */
  insert(ts, value) {
    const i = sortedLastIndex(this.$keys, ts);
    if (i === this.$keys.length) {
      this.$keys.push(ts);
      this.$values.push(value);
    } else {
      this.$keys.splice(i, 0, ts);
      this.$values.splice(i, 0, value);
    }
    this.$invoke(ts, ts);
  }

  /**
   * Delete an entry. O(n)
   * If value is not provided, all values at that timestamp will be removed.
   * @param {number} ts
   * @param {T} [value]
   * @param {(a: T, b: T) => boolean} [isEqual]
   */
  delete(ts, value = undefined, isEqual = (a, b) => a === b) {
    const i = sortedIndex(this.$keys, ts);
    let j = i;
    while (this.$keys[j] === ts) {
      if (value !== undefined && isEqual(this.$values[j], value)) {
        this.$keys.splice(j, 1);
        this.$values.splice(j, 1);
        break;
      }
      j++;
    }
    if (value === undefined) {
      this.$keys.splice(i, j - i);
      this.$values.splice(i, j - i);
    }
    this.$invoke(ts, ts);
  }

  /**
   * Delete all values from a start and end time. O(n)
   * @param {number} startTime
   * @param {number} endTime
   */
  deleteRange(startTime, endTime) {
    let count = 0;
    const s = sortedIndex(this.$keys, startTime);
    for (let i = s; this.$keys[i] <= endTime; i++) {
      count++;
    }
    this.$keys.splice(s, count);
    this.$values.splice(s, count);
    this.$invoke(startTime, endTime);
  }

  /**
   * Find all values from a start and end time. O(t)
   * @param {number} startTime
   * @param {number} endTime
   */
  search(startTime, endTime) {
    const results = [];
    for (let i = sortedIndex(this.$keys, startTime); this.$keys[i] <= endTime; i++) {
      results.push(this.$values[i]);
    }
    return results;
  }

  /**
   * Get the value at specific time
   * @param {number} time
   */
  get(time) {
    const i = sortedIndexOf(this.$keys, time);
    if (i === -1) return undefined;
    return this.$values[i];
  }

  /**
   * Gets the value of strictly before the time
   * @param {number} time
   */
  previousValue(time) {
    const i = sortedIndex(this.$keys, time);
    if (i === 0) return undefined;
    return this.$values[i - 1];
  }

  /**
   * Gets the value of strictly after the time
   * @param {number} time
   */
  nextValue(time) {
    const i = sortedLastIndex(this.$keys, time);
    if (i === this.$keys.length) return undefined;
    return this.$values[i];
  }

  /**
   * Gets the value of nearest to the time
   * @param {number} time
   */
  nearestValue(time) {
    if (!this.length) return undefined;
    const i = sortedIndex(this.$keys, time);
    if (i === 0) {
      return this.$values[i];
    } else if (i === this.length) {
      return this.$values[i - 1];
    } else if (time - this.$keys[i - 1] <= this.$keys[i] - time) {
      return this.$values[i - 1];
    } else {
      return this.$values[i];
    }
  }
}

/**
 * @template T
 * @type {UniqueTimestampStore<T>}
 */
export class UniqueTimestampStore extends TimestampStore {
  /**
   * Put an entry
   * @param {number} ts Timestamp
   * @param {T} value
   */
  insert(ts, value) {
    const i = sortedIndex(this.$keys, ts);
    const count = this.$keys[i] === ts ? 1 : 0;
    this.$keys.splice(i, count, ts);
    this.$values.splice(i, count, value);
  }
}
