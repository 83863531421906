import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import { BufferInfo } from '../BufferInfo';
import { CabinDataInfo } from '../CabinDataInfo';
import { CoolDownTimeBar } from '../CoolDownTimeBar';
import { EditButton } from '../EditButton';
import { RecordingInfo } from '../RecordingInfo';
import { TableSensitivityChart } from '../TableSensitivityChart';
import { TriggerActivateSwitch } from '../TriggerActivateSwitch';
import { TriggerNameWithImage } from '../TriggerNameWithImage';

const columns = [
  {
    key: 'on-off-switch',
    label: 'Off/On',
    textAlign: 'left',
    width: '40px',
    component: (trigger) => (
      <Box maxWidth={10}>
        <TriggerActivateSwitch trigger={trigger} />
      </Box>
    ),
  },
  {
    key: 'trigger-name',
    label: 'Trigger',
    textAlign: 'center',
    width: '30px',
    component: (trigger) => {
      const { triggerCategoryName, name: triggerName } = trigger || {};
      const displayName =
        (triggerCategoryName === 'Default' && triggerName !== '') ||
        triggerName !== triggerCategoryName
          ? triggerName
          : triggerCategoryName;
      return (
        <Box ml={5} mr={{ xs: 0, sm: 0, md: 0, xl: -15 }}>
          <TriggerNameWithImage triggerName={displayName} />
        </Box>
      );
    },
  },
  {
    key: 'cabin',
    label: 'Cabin',
    textAlign: 'center',
    width: '100px',
    component: (trigger) => {
      const ttsEnabled = !!trigger?.enableTts?.value && trigger?.tts !== '';
      return (
        <CabinDataInfo
          isChimeEnabled={trigger?.chime?.value !== 'NONE'}
          isTtsEnabled={ttsEnabled}
          chime={trigger?.chime?.value}
          tts={trigger?.tts}
          defaultChime={trigger?.defaultValues?.chime?.value}
          defaultTts={trigger?.defaultValues?.tts}
        />
      );
    },
  },
  {
    key: 'recording',
    label: 'Recording',
    textAlign: 'center',
    width: '100px',
    component: (trigger) => (
      <RecordingInfo
        recodingType={trigger?.uploadStrategy?.value}
        defaultRecodingType={trigger?.defaultValues?.uploadStrategy?.value}
      />
    ),
  },
  {
    key: 'buffers',
    label: 'Buffers',
    textAlign: 'center',
    width: '100px',
    component: (trigger) => (
      <BufferInfo
        preBuffer={trigger?.preBuffer?.value}
        postBuffer={trigger?.postBuffer?.value}
        defaultPreBuffer={trigger?.defaultValues?.preBuffer?.value}
        defaultPostBuffer={trigger?.defaultValues?.postBuffer?.value}
        trigger={trigger}
      />
    ),
  },
  {
    key: 'sensitivity',
    label: 'Sensitivity',
    textAlign: 'center',
    width: '100px',
    component: (trigger, category, isHovered, updateQuery) => (
      <TableSensitivityChart
        trigger={trigger}
        category={category}
        isHovered={isHovered === trigger?.id}
      />
    ),
  },
  {
    key: 'cooldown',
    label: 'Cool Down',
    textAlign: 'center',
    width: '100px',
    component: (trigger, category, isHovered, updateQuery) => (
      <CoolDownTimeBar
        isEnable={trigger?.cooldown?.value}
        coolDownTimer={trigger?.cooldownTimer?.value / 1000}
        defaultCoolDownEnable={trigger?.defaultValues?.cooldown?.value}
        defaultCoolDownTimer={trigger?.defaultValues?.cooldownTimer?.value / 1000}
        isHovered={isHovered === trigger?.id}
      />
    ),
  },
  {
    key: 'action',
    label: '',
    textAlign: 'right',
    width: '30px',
    component: (trigger, category, isHovered, updateQuery) => (
      <EditButton
        isVisible={isHovered === trigger?.id}
        onEdit={() => {
          updateQuery({ id: trigger?.id });
        }}
      />
    ),
  },
];

const LoadingComponent = () => (
  <CenterBox sx={{ height: '80vh !important' }}>
    <CircularProgress />
  </CenterBox>
);

const MessageComponent = ({ message }) => (
  <CenterBox sx={{ height: '80vh !important' }}>
    <IconMessageBox size="256px" src={NO_SEARCH_RESULT_IMG} message={message} />
  </CenterBox>
);

const TriggerTable = () => {
  const { selectionType, parentLoading, triggers } = useContext(TriggerConfigurationContext);
  const { query, updateQuery } = useRouteQuery();

  const [isHovered, setIsHovered] = useState(null);
  const [filteredTriggers, setFilteredTriggers] = useState({ triggers: [], triggersLoading: true });

  const form = useForm({ mode: 'all', defaultValues: {} });

  useEffect(() => {
    if (isEmpty(query?.search)) {
      setFilteredTriggers({ triggers, triggersLoading: false });
      return;
    }
    const filteredItems = triggers?.filter((item) =>
      item?.triggerCategoryName?.toLowerCase()?.includes((query?.search + '')?.toLowerCase())
    );
    setFilteredTriggers({ triggers: filteredItems, triggersLoading: false });
  }, [triggers, query?.search]);

  if (parentLoading || filteredTriggers?.triggersLoading) {
    return <LoadingComponent />;
  }

  if (!selectionType) {
    return <MessageComponent message="Sorry! No entity selected." />;
  }

  if (filteredTriggers?.triggers?.length === 0) {
    return <MessageComponent message="Sorry! No Trigger found." />;
  }

  return (
    <Box maxHeight="80vh" sx={{ overflowY: 'auto' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer
            pb={5}
            component={Box}
            sx={{
              'maxHeight': '80vh',
              '& .MuiTableCell-stickyHeader': {
                opacity: 1,
                background: '#f2f6fc',
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                '& .MuiTableCell-head': {
                  padding: '8px 0px !important',
                  borderBottom: 'none',
                },
                '& .MuiTableHead-root': {
                  border: '1px solid #d6e4f5 !important',
                  borderRadius: '100px',
                  background: '#d6e4f5',
                },
                '& .MuiTableCell-body': {
                  padding: '0px 4px !important',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      sx={{
                        color: '#0B2547',
                        opacity: 0.68,
                        fontSize: '0.875rem',
                        textAlign: 'center',
                      }}
                      key={index}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <FormProvider {...form}>
                <TableBody>
                  {filteredTriggers?.triggers?.map((trigger, index2) => (
                    <TableRow
                      key={index2}
                      sx={{
                        'px': 2,
                        'height': '80px',
                        '&:hover': {
                          backgroundColor: '#E3EDFD',
                        },
                        '&:hover .edit-button': {
                          visibility: 'visible',
                        },
                      }}
                      onMouseEnter={() => setIsHovered(trigger?.id)}
                      onMouseLeave={() => setIsHovered(null)}
                    >
                      {columns.map((item, index) => (
                        <TableCell
                          sx={{
                            textAlign: item?.textAlign,
                            width: item?.width,
                          }}
                          key={index}
                        >
                          {item?.component(
                            trigger,
                            trigger?.triggerCategoryName,
                            isHovered,
                            updateQuery
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </FormProvider>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TriggerTable;
