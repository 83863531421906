// @ts-ignore
import api from '@/api';
import { SKY_HAWK_ID } from '@/assets/demo';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as d3 from 'd3';
import { capitalize } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export const FleetStackedBarChart = () => {
  const svgRef = useRef(null);
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const theme = useTheme();

  const [data, setData] = useState([]);
  const [months, setMonths] = useState(null);
  const [loading, setLoading] = useState(true);

  const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const isSkyHawkTenantLoggedIn = SKY_HAWK_ID === tenantId;

  useEffect(() => {
    if (xlAndUp) {
      setMonths(12);
    } else if (mdAndUp) {
      setMonths(8);
    } else if (smAndUp) {
      setMonths(6);
    } else {
      setMonths(4);
    }
  }, [mdAndUp, smAndUp, xlAndUp]);

  useEffect(() => {
    if (!months) return;
    try {
      setLoading(true);
      const request = api.ac.v5.fleet['score-list'].$get({
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          count: months,
          queryPeriod: 'MONTH',
        },
      });
      request
        .process()
        .then((data) => {
          const pp = data?.list.map((item) => {
            const score =
              isSkyHawkTenantLoggedIn && item.period === 'NOVEMBER-2023' ? 95 : item.score || 0;
            return {
              // @ts-ignore
              period: capitalize(item.period),
              default: 100 - score,
              score: score,
            };
          });
          setData(pp);
        })
        // @ts-ignore
        .catch((ex) => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {}
  }, [secretToken, tenantId, months, isSkyHawkTenantLoggedIn]);

  useEffect(() => {
    if (!months) return;
    let box = document.querySelector('.fleetStackedChart');
    const margin = { top: 20, right: 20, bottom: 40, left: 40 };
    // @ts-ignore
    const width = box?.offsetWidth - 25 || window.innerWidth;
    const height = 300 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    const keys = ['score', 'default'];

    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.period))
      .range([0, width - 30])
      .padding(0.01)
      .paddingInner(1); // space between bars (it's a ratio)

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d3.sum(keys, (key) => d[key]))])
      .nice()
      .range([height, 0]);

    const colorScale = d3.scaleOrdinal().domain(keys).range(['#2680EB', '#E4ECF4']);

    const stackedData = d3.stack().keys(keys)(data);

    //tooltip
    const tooltip = d3.select('body').append('div').attr('id', 'tooltip').style('opacity', 0);

    //X axis Bottom Line
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .style('stroke-dasharray', '5 5')
      .style('stroke-width', '1.2')
      .style('stroke-linecap', 'round')
      .style('font-weight', 600)
      .style('color', '#0B2547')
      .style('opacity', 0.68)
      .call((g) => g.selectAll('path').style('display', 'none'))
      .call((g) => g.selectAll('line').style('stroke', '#DCE1EA'));

    //Y axis left line and grid
    svg
      .append('g')
      .call(d3.axisLeft(yScale))
      .call(d3.axisLeft(yScale).tickSizeInner(-width).ticks(5))
      .style('stroke-dasharray', '5 5')
      .style('stroke-width', '1.2')
      .style('stroke-linecap', 'round')
      .style('font-weight', 600)
      .style('color', '#0B2547')
      .style('opacity', 0.68)
      .call((g) => g.selectAll('path').style('display', 'none'))
      .call((g) => g.selectAll('line').style('stroke', '#DCE1EA'));

    //bars
    const bars = svg
      .selectAll('.bar')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('class', 'bar')
      .attr('fill', (d) => colorScale(d.key));

    bars
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      // @ts-ignore
      .attr('x', (d) => xScale(d.data.period))
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
      .attr('width', 15)
      .attr('rx', 8) // add rounded corners
      .attr('ry', 7)
      .on('mouseover', (event, d) => {
        const value = Math.floor(d.data.score);
        const labelName = d.data.period;
        const innerColors = ['#2680EB', '#789BCB', '#A9B5C6'];

        const angle = (330 - 30) * (value / 100) + 30;
        const valueColor = innerColors[value > 90 ? 0 : value > 80 ? 1 : 2];

        const tooltipContent = `<div style="display:grid;justify-items: center; width: 150px; line-height: 0px;    margin: 0;padding: 0;">
        <p style="color:#5a6c83; margin-bottom:0.5; font-size:11; font-weight:500;">Fleet Safety Score</p>
        <p style="padding: 0; margin-bottom:1; font-size: small;">${labelName}</p>
        <div>
           <svg width="100%" height="100%" viewBox="0 0 200 200">
              <g>
                 <ellipse
                    cx="100"
                    cy="100"
                    rx="82"
                    ry="82"
                    fill="none"
                    stroke-width="12"
                    stroke-linecap="round"
                    stroke="#2680EB"
                    stroke-dasharray="432.78580395852987 82.43539123019617"
                    transform="translate(236 62) rotate(119)"
                    opacity="${value > 0 ? 1 : 0.4}"
                    filter="${value > 0 ? '' : 'grayscale(80%)'}"
                    ></ellipse>
                 <ellipse
                    cx="100"
                    cy="100"
                    rx="82"
                    ry="82"
                    fill="none"
                    stroke-width="12"
                    stroke-linecap="round"
                    stroke="#789BCB"
                    stroke-dasharray="389.50722356267687 125.71397162604914"
                    transform="translate(236 62) rotate(119)"
                    opacity="${value > 0 ? 1 : 0.4}"
                    filter="${value > 0 ? '' : 'grayscale(80%)'}"
                    ></ellipse>
                 <ellipse
                    cx="100"
                    cy="100"
                    rx="82"
                    ry="82"
                    fill="none"
                    stroke-width="12"
                    stroke-linecap="round"
                    stroke="#A9B5C6"
                    stroke-dasharray="346.22864316682393 168.99255202190213"
                    transform="translate(236 62) rotate(119)"
                    opacity="${value > 0 ? 1 : 0.4}"
                    filter="${value > 0 ? '' : 'grayscale(80%)'}"
                    ></ellipse>
                 <g transform="translate(100 100) rotate(${90 + angle})">
                    <ellipse cx="80" cy="0" rx="13" ry="11" fill="#ffffff" />
                    <ellipse cx="82" cy="0" rx="8" ry="8" fill=${valueColor} />
                 </g>
                 <text
                    x="50%"
                    y="50%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                    font-size="50"
                    font-weight="600"
                    fill="#213251"
                    opacity="1.000000"
                    opacity="${value > 0 ? 1 : 0.6}"
                    filter="${value > 0 ? '' : 'grayscale(80%)'}"
                    >
                    ${value}
                 </text>
              </g>
           </svg>
        </div>
     </div>`;

        tooltip.transition().duration(200).style('opacity', 0.9);

        tooltip
          .html(tooltipContent)
          .style(
            'left',
            `${window.innerWidth - event.pageX < 150 ? event.pageX - 160 : event.pageX}px`
          )
          .style('top', `${event.pageY}px`)
          .style('position', 'absolute')
          .style('pointer-events', 'none')
          .style('background-color', 'white')
          .style('box-shadow', '1px 1px 1px 1px #888888')
          .style('padding', '5px');
      })
      .on('mouseout', (event, d) => {
        tooltip.transition().duration(500).style('opacity', 0);
        document?.getElementById('tooltip')?.classList?.remove('active');
      });
  }, [data, mdAndUp, smAndUp, months]);

  if (loading) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <FormControl
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '28px !important',
              color: '#4177BF !important',
              fontWeight: 600,
            },
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={months}
            size="small"
            onChange={(e) => {
              // @ts-ignore
              setMonths(e.target.value);
            }}
          >
            <MenuItem value={12}>12 Months</MenuItem>
            <MenuItem value={8}>8 Months</MenuItem>
            <MenuItem value={4}>4 Months</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <svg ref={svgRef} width="100%" height="100%"></svg>
    </Box>
  );
};
