import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { Box, Checkbox } from '@mui/material';
import { useContext } from 'react';

/**
 * @typedef {object} TableCheckboxHeaderProps
 * @property {string} [itemKey]
 * @property {Array<object>} [rows] TODO: missing type of the array item
 */

/** @param {Partial<TableCheckboxHeaderProps>} props */
export function TableCheckboxHeader(props) {
  const { rows } = props;
  const { selectedItems, setSelectedItems } = useContext(PaginatedTableContext);
  const rowCount = rows?.length || 0;
  const selectedCount = selectedItems?.length || 0;
  return (
    <Box sx={{ maxHeight: '30px !important' }}>
      <Checkbox
        sx={{ p: 0 }}
        color="primary"
        indeterminate={selectedCount > 0 && selectedCount < rowCount}
        checked={rowCount > 0 && selectedCount === rowCount}
        onChange={(event) => {
          if (event.target.checked) {
            setSelectedItems(rows);
          } else {
            setSelectedItems([]);
          }
        }}
      />
    </Box>
  );
}
