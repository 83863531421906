import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { DialogTable } from '@/web/@components/DialogTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import { CAMERA_DEPLOYED_MODEL_TABLE_COLUMNS } from './columns';
/**
 * @typedef {object} GroupDeployedModelsProps
 * @property {string} [productId]
 * @property {number} [groupId]
 * @property {() => any} [onCancel]
 * @property {(value: AIModelV0) => any} [onSubmit]
 */

/** @param {GroupDeployedModelsProps} props */
export function GroupDeployedModels(props) {
  const { productId, groupId, onCancel, onSubmit } = props;

  /** @type {StateVariable<Array<AIModelV0>>} */
  const [selectedModels, setSelectedModels] = useState([]);

  const {
    result = [],
    loading,
    error,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    let conToken = '';
    const limit = 20;
    /** @type {Array<AIModelV0>} */
    const results = [];

    const request = api.ac.v2.repository.aicontainer.deployed.group.history.$get({
      params: {
        groupId,
        secretToken,
        pageSize: 1,
        productId,
        continuationToken: '',
      },
    });
    const container = await request.process();

    const deployedContainer = container.data.filter((item) => !item.deleted);

    if (deployedContainer.length === 0) {
      return [];
    }

    while (true) {
      const request = api.ac.v2.repository.aicontainer.aimodel.$get({
        signal,
        params: {
          pageSize: limit,
          continuationToken: conToken,
          containerId: deployedContainer?.at(0)?.containerId,
          secretToken,
        },
      });
      await request.process();
      const result = request.result.data;
      conToken = request.result.continuationToken;
      results.push(...result);
      if (!conToken || result.length < limit) break;
    }
    return results;
  }, []);

  const handleDeployFirmware = () => {
    onSubmit && onSubmit(selectedModels[0]);
  };

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      maxWidth={'lg'}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Recalibrate AI Model</DialogTitle>
      <Divider />
      <DialogContent sx={{ height: '80vh' }}>
        <DialogTable
          title="AI Models"
          loading={loading}
          error={error}
          results={result}
          columns={CAMERA_DEPLOYED_MODEL_TABLE_COLUMNS}
          onSelectItem={setSelectedModels}
          disableMultiSelect
          dataSortKey="name"
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleDeployFirmware}
          autoFocus
          variant="contained"
          disabled={!selectedModels?.length}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
