import { dayOptions } from '@/assets/schemes/options';
import { Autocomplete, TextField } from '@mui/material';

export function GroupSettingsDaySelection({ day, setDay, isDisabled = false }) {
  return (
    <Autocomplete
      disabled={isDisabled}
      fullWidth
      disablePortal
      disableClearable
      id="combo-box-demo"
      size="medium"
      options={dayOptions}
      onChange={(event, newValue) => {
        // @ts-ignore
        setDay(newValue?.value);
      }}
      value={{ label: `${day}`, value: day }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
}
