import {
  AI_MODEL_PARAMS_THRESHOLD_BOOLEAN_VALUES,
  AI_MODEL_PARAMS_TYPES,
} from '@/assets/ai/ai-model';

/** @type {Array<AIModelForm>} */
export const AI_MODEL_PARAMETER_DATA = [
  {
    name: 'name',
    label: 'Parameter Name *',
    type: 'input',
    mandatory: true,
    placeholder: 'Enter a parameter name',
  },
  {
    name: 'type',
    label: 'Value Type *',
    type: 'select',
    mandatory: true,
    placeholder: 'Select a type',
    options: AI_MODEL_PARAMS_TYPES,
  },
  {
    name: 'value',
    label: 'Threshold Value *',
    mandatory: true,
    type: 'input',
    placeholder: 'Enter a threshold value',
    visibility: (type) => type === 'NUMBER' || type === 'STRING',
  },

  {
    name: 'value',
    label: 'Threshold Value *',
    mandatory: true,
    type: 'select',
    options: AI_MODEL_PARAMS_THRESHOLD_BOOLEAN_VALUES,
    placeholder: 'Select a value',
    visibility: (type) => type === 'BOOLEAN',
  },
];
