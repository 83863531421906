import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ApkFileForm } from '../../@components/ApkForm';
import { ApkVersionHistory } from '../../@components/VersionHistory';
import { uploadFirmwareFile } from '../../utils';

export function ApkFileEditPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: apkId } = useParams();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  /** @type {StateVariable<FirmwareDto>} */
  const [apkDetails, setApkDetails] = useState(null);
  /** @type {StateVariable<AbortController>} */
  const [submitting, setSubmitting] = useState();
  /** @type {StateVariable<boolean>} */
  const [showVersion, setShowVersion] = useState(true);
  const [apkVersions, setApkVersions] = useState([]);

  useEffect(() => {
    if (!apkId) {
      navigate('/administration/apk-files');
      return;
    }

    const fetchApkDetails = async () => {
      try {
        setLoading(true);
        // Fetch APK Details
        const apkDetails = await api.ac.v5.firmware
          .$firmwareId(apkId)
          .$get({
            headers: { Authorization: secretToken },
          })
          .process();
        setApkDetails(apkDetails);

        // Fetch APK Versions
        const apkVersions = await api.ac.v5.firmware
          .$firmwareId(apkId)
          .versions.$get({
            headers: { Authorization: secretToken },
          })
          .process();
        setApkVersions(apkVersions?.firmwaresVersions);
      } catch (error) {
        navigate('/administration/apk-files');
      } finally {
        setLoading(false);
      }
    };

    fetchApkDetails();
  }, [apkId, secretToken, navigate, tenantId]);

  useEffect(() => {
    if (!apkDetails?.name) return;
    setBreadcrumbTitle(apkDetails?.name);
  }, [apkDetails?.name, setBreadcrumbTitle]);

  /** @type {import('../../@components/ApkForm').ApkFileFormProps['onSubmit']} */
  const updateApkFiles = async (fields) => {
    const aborter = new AbortController();
    setSubmitting((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      fields.apkFile && setProgressModalOpen(true);

      /** @type {CreateFirmwareRequest} */
      const body = {};
      body.firmwareId = apkDetails.firmwareId;
      body.name = apkDetails.name;
      body.version = fields.version;
      body.description = fields.description;
      body.uploadNotes = fields.notes;

      if (fields.apkFile) {
        await uploadFirmwareFile(fields.apkFile, body, setPercentage, aborter.signal);
      }

      const request = api.ac.v5.firmware.$firmwareId(apkId).$put({
        data: body,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', 'APK file updated successfully');
      navigate('/administration/apk-files');
    } catch (err) {
      if (!aborter.signal.aborted) {
        if (
          err?.response?.data?.length &&
          err.response.data[0]?.message === 'ALREADY_EXISTS_VERSION'
        ) {
          toastWarning('Error', 'This APK File version already exists.');
        } else {
          toastWarning('Error', 'Could not update APK File.');
        }
      }
    } finally {
      setSubmitting(null);
      setProgressModalOpen(false);
    }
    return null;
  };

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Update APK File"
          progressText={'Uploading file...'}
          onClose={() => {
            submitting?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['apk.files.update.page.title'])}
      </Typography>

      {loading ? (
        <CenterBox fullView>
          <CircularProgress />
        </CenterBox>
      ) : (
        <>
          <ApkFileForm
            apkId={apkId}
            apkDetails={apkDetails}
            onSubmit={updateApkFiles}
            disabled={Boolean(submitting)}
          />

          {apkVersions?.length > 0 && (
            <Box>
              <Typography
                color={'#7a9fd2'}
                mt={'-25px'}
                onClick={() => {
                  setShowVersion((prev) => !prev);
                }}
                sx={{ fontSize: '0.875rem', color: '#4177BF', cursor: 'pointer', fontWeight: 500 }}
              >
                Version History
              </Typography>
              {showVersion && <ApkVersionHistory versions={apkVersions} />}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
