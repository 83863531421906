import { CameraContainer } from '../@components/CameraContainer';
import { CameraFirmwares } from '../@components/CameraFirmwares';

/** @type {Array<SoftwarePageCardItem>} */
export const SOFTWARE_TAB_ITEMS = [
  {
    key: 'softwares',
    label: 'Softwares',
    component: <CameraFirmwares />,
  },
  {
    key: 'containers',
    label: 'AI Container',
    component: <CameraContainer />,
  },
];
