/**
 * Compare two route path
 * @param {string} first
 * @param {string} second
 * @param {boolean} [partial]
 */
export function comparePaths(first, second, partial = false) {
  first = encodeURI((first || '').split('?')[0]);
  second = encodeURI((second || '').split('?')[0]);
  if (!first.startsWith('/')) first = '/' + first;
  if (!second.startsWith('/')) second = '/' + second;
  if (!first.endsWith('/')) first += '/';
  if (!second.endsWith('/')) second += '/';
  if (partial) return second.startsWith(first);
  return first.localeCompare(second) === 0;
}

/**
 * Compares if two route paths are equal
 * @param {string} first
 * @param {string} second
 */
export function isExactMatch(first, second) {
  return comparePaths(first, second);
}

/**
 * Check if two route paths are partially match
 * @param {string} first
 * @param {string} second
 */
export function isPartialMatch(first, second) {
  return comparePaths(first, second, true);
}
