import { selectSidebarMode } from '@/store/page-view';
import { CenterBox } from '@/web/@components/CenterBox';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollFixedListView } from '@/web/@components/InfiniteScrollView/InfiniteScrollFixedListView';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from '@/web/@layouts/MainLayout/config';
import { CircularProgress, useTheme } from '@mui/material';
import { throttle } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CameraGridListItem } from '../CameraGridListItem';
import { CameraThumbnailProvider } from '../CameraThumbnailProvider';

/**
 * @typedef {object} CameraGridListPageProps
 * @property {boolean} fullHeight
 * @property {Array<EndpointInfoAggregated>} cameras
 */

/** @param {CameraGridListPageProps} props */
export function CameraGridListPage(props) {
  const { cameras, fullHeight } = props;

  const theme = useTheme();
  const mediumScreen = theme.breakpoints.values.md;
  const largeScreen = theme.breakpoints.values.lg;
  const isSidebarCollapsed = useSelector(selectSidebarMode);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const aborter = new AbortController();
    const updateSize = throttle(() => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }, 100);
    window.addEventListener('resize', updateSize, {
      passive: true,
      signal: aborter.signal,
    });
    return () => {
      aborter.abort();
      updateSize.cancel();
    };
  }, []);

  const drawerWidth = useMemo(
    () => (isSidebarCollapsed ? COLLAPSED_DRAWER_WIDTH : DRAWER_WIDTH),
    [isSidebarCollapsed]
  );

  const listWidth = useMemo(
    () => windowWidth - (windowWidth >= largeScreen ? drawerWidth + 30 : 30),
    [windowWidth, largeScreen, drawerWidth]
  );

  const listHeight = useMemo(
    () => windowHeight - (fullHeight ? 0 : windowWidth >= mediumScreen ? 170 : 205),
    [windowHeight, windowWidth, fullHeight, mediumScreen]
  );

  const columnCount = useMemo(() => Math.round((listWidth - 10) / 400), [listWidth]);

  const columnWidth = useMemo(
    () => Math.floor((listWidth - 10) / columnCount),
    [listWidth, columnCount]
  );

  const rowHeight = useMemo(() => Math.round(36 + (9 * columnWidth) / 16), [columnWidth]);

  if (!cameras?.length) {
    return (
      <CenterBox fullView={fullHeight}>
        {!cameras ? (
          <CircularProgress />
        ) : (
          <IconMessageBox size="256px" src="/images/empty/no-events.svg" message={'No cameras'} />
        )}
      </CenterBox>
    );
  }

  return (
    <HubConnectionProvider>
      <CameraThumbnailProvider cameras={cameras}>
        <InfiniteScrollFixedListView
          px="15px"
          state={{
            finished: true,
            loading: false,
            itemsPerPage: 10,
            results: cameras,
            error: null,
            loadNext: () => {},
            token: null,
          }}
          itemKey={(item) => item.endpointId}
          variant={'grid'}
          FixedGridProps={{
            width: listWidth,
            height: listHeight,
            rowHeight,
            columnCount,
            columnWidth,
            overscanRowCount: 1,
          }}
          renderItem={({ data, style }) => <CameraGridListItem item={data} style={style} p="5px" />}
        />
      </CameraThumbnailProvider>
    </HubConnectionProvider>
  );
}
