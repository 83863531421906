import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { CheckCircle, Circle } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';

/**
 * @typedef {object} DevicePoolSelectionProps
 * @property {Array<DevicePoolInfoForTenant>} pools
 * @property {DevicePoolInfoForTenant} selected
 * @property {(value: DevicePoolInfoForTenant) => any} onSelect
 */

/** @param {DevicePoolSelectionProps} props */
export function DevicePoolSelection(props) {
  const { pools, selected, onSelect } = props;

  return (
    <Box minHeight="200px" height="50vh" overflow="hidden auto">
      {!pools.length && (
        <CenterBox>
          <IconMessageBox
            size="128px"
            src="/images/empty/no-search-results.svg"
            message="No Device Pool"
          />
        </CenterBox>
      )}
      {pools.map((pool, index) => (
        <Box key={index}>
          {index > 0 && <Divider />}
          <Button
            onClick={() => onSelect(pool)}
            sx={{
              'width': '100%',
              'display': 'flex',
              'fontWeight': '500',
              'color': '#011e42',
              'justifyContent': 'space-between',
              '& .checkbox': {
                padding: '1px',
                fontSize: '1.375rem',
                borderRadius: '50%',
                background: '#ffffff',
                border: '1px solid #cfd8e5',
              },
            }}
          >
            <Box>{pool.name}</Box>
            <Box>
              {selected?.id === pool.id ? (
                <CheckCircle htmlColor="#596A81" className="checkbox" />
              ) : (
                <Circle htmlColor="#CFD8E5" className="checkbox" />
              )}
            </Box>
          </Button>
        </Box>
      ))}
    </Box>
  );
}
