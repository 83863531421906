import { T } from '@/assets/locales';
import { isDev } from '@/config';
import { store } from '@/store';
import { selectTenantId, selectUserEmail, selectUserId, selectUserRole } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { Box, Typography, useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BoxImage } from '../BoxImage';

/**
 * @param {import('@sentry/react').ErrorBoundaryProps} props
 */
export function ErrorBoundary(props) {
  return (
    <Sentry.ErrorBoundary
      {...props}
      fallback={(params) => <ErrorBoundaryPage {...params} />}
      beforeCapture={(scope, error) => {
        const state = store.getState();
        scope.setFingerprint(['4332acf5-574f-4bc3-baf5-ffb9851aed0e', error.message]);
        scope.setLevel(error instanceof KnownUserError ? 'error' : 'fatal');
        scope.setTransactionName('ErrorBoundary');
        scope.setUser({
          ...scope.getUser(),
          email: selectUserEmail(state),
          id: selectUserId(state) + '',
          tenantId: selectTenantId(state),
          role: selectUserRole(state),
        });
      }}
    />
  );
}

/**
 * @typedef {object} ErrorBoundaryPageProps
 * @property {Error} error
 * @property {string} [componentStack]
 * @property {string} [eventId]
 * @property {() => void} resetError
 */

/**
 * @param {ErrorBoundaryPageProps} props
 */
function ErrorBoundaryPage(props) {
  const { error } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const message = useMemo(() => {
    if (error instanceof KnownUserError) {
      return error.message;
    }
    return T['error.boundary.default.message'];
  }, [error]);

  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center">
      <Box
        display="flex"
        gap="10px"
        alignItems="center"
        justifyContent={{ md: 'space-between' }}
        textAlign={{ xs: 'center', md: 'left' }}
        flexDirection={{ xs: 'column', md: 'row-reverse' }}
      >
        <Box textAlign="center">
          <BoxImage src="/images/commons/page-not-found.svg" size="80%" />
        </Box>

        <Box p={{ xs: 2, md: 4 }}>
          <Typography
            component="h1"
            color={theme.palette.primary.main}
            fontWeight="500"
            fontSize="2rem"
          >
            Ooops...
          </Typography>

          <Typography
            component="h3"
            color={theme.palette.primary.light}
            fontWeight="500"
            fontSize="1.25rem"
          >
            {t(message)}
          </Typography>

          {isDev && (
            <Typography
              component="pre"
              variant="subtitle2"
              mx={{ xs: 'auto', md: 0 }}
              pt={1}
              style={{
                color: 'grey',
                fontSize: '0.75em',
                whiteSpace: 'break-spaces',
                fontFamily: '"Fira Code", "Consolas", monospaced',
                maxHeight: '450px',
                overflow: 'auto',
              }}
            >
              {error.stack}
            </Typography>
          )}

          <Box pt={3} />
        </Box>
      </Box>
    </Box>
  );
}
