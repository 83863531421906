import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

/**
 * @typedef {object} ScoreFilterContentProps
 * @property {number} [from]
 * @property {number} [to]
 * @property {(range: {from: number, to: number}) => any} onChange
 * @property {() => any} onCancel
 */

export function ScoreFilterContent(props) {
  const { from, to, onChange, onCancel } = props;

  /** @type {StateVariable<number>} */
  const [tempFromState, setTempFormState] = useState(null);
  /** @type {StateVariable<number>} */
  const [tempToState, setTempToState] = useState(null);

  const onClickApply = () => {
    onChange({
      from: tempFromState,
      to: tempToState,
    });
  };

  const onClickCancel = () => {
    setTempFormState(from);
    setTempToState(to);
    onCancel();
  };

  useEffect(() => {
    setTempFormState(from);
    setTempToState(to);
  }, [from, to]);

  return (
    <Box
      px={2}
      pb={1}
      sx={{ maxHeight: '300px', minWidth: '400px', overflowY: 'auto', marginTop: '2px' }}
    >
      <>
        <Box display={'flex'} flexDirection={'row'} gap={2} pt={2}>
          <TextField
            fullWidth
            focused
            label="From"
            size="small"
            placeholder="Input a number"
            type="number"
            value={tempFromState}
            inputProps={{
              min: 0,
              max: tempToState,
            }}
            onInput={(event) => {
              // @ts-ignore
              const val = event?.target?.value;
              const value = val < 0 ? 0 : val;
              setTempFormState(value);
            }}
          />
          <TextField
            fullWidth
            label="Upto"
            size="small"
            placeholder="Input a number"
            type="number"
            focused
            value={tempToState}
            inputProps={{
              min: tempFromState,
              max: 100,
            }}
            onInput={(event) => {
              // @ts-ignore
              const val = event?.target?.value;
              const value = val > 100 ? 100 : val;
              setTempToState(value);
            }}
          />
        </Box>
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }} onClick={onClickCancel}>
          <Button size="small">Cancel</Button>
          <Button onClick={() => onClickApply()} variant="contained" size="small">
            Apply
          </Button>
        </Box>
      </>
    </Box>
  );
}
