import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ArrowDropDown } from '@mui/icons-material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export function SchemesApplied() {
  const params = useParams();
  const schemeId = useMemo(() => params.id, [params]);

  const [schemeName, setSchemeName] = useState('');

  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : -1);
  };

  const groupItemsByKey = (items, key) => {
    const groupedData = items.reduce((acc, item) => {
      const { targetLevel, ...rest } = item;
      if (!acc[item[key]]) {
        acc[item[key]] = [];
      }

      acc[item[key]].push(rest);
      return acc;
    }, {});
    return groupedData;
  };

  const { result = {}, loading } = useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.v5.scheme.$schemeId(schemeId + '').deploy.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      const result = request.result?.values;
      if (result?.length) {
        setSchemeName(result?.at(0)?.schemeName);
      }
      return groupItemsByKey(result, 'targetLevel');
    },
    [],
    {}
  );

  const getItemName = (name) => {
    if (name === 'ENDPOINT') return 'Cameras';
    else if (name === 'TENANT') return 'Tenants';
    else if (name === 'GROUP') return 'Camera Groups';
    else return name;
  };

  return (
    <Box>
      {loading ? (
        <CenterBox fullView>
          <CircularProgress />
        </CenterBox>
      ) : !loading && isEmpty(result) ? (
        <CenterBox fullView>
          <IconMessageBox
            size="128px"
            src="/images/empty/no-search-results.svg"
            message="Scheme is not applied to any Camera, Group or Tenant yet!"
          />
        </CenterBox>
      ) : (
        <Box p={2.5}>
          <Typography variant="body1" fontSize={'1rem'} fontWeight={500}>
            {schemeName}
          </Typography>

          <Box
            py={4}
            sx={{
              '& .MuiPaper-elevation': {
                border: '1px solid #c5d9f0',
                borderRadius: '5px',
              },
              'overflowY': 'auto',
            }}
          >
            {Object.entries(result || {})?.map((row, index) => (
              <Accordion
                disableGutters
                elevation={0}
                square
                disabled={loading}
                key={index}
                expanded={expanded === index}
                onChange={handleChange(index)}
                sx={{ 'mx': 0.5, '& .Mui-expanded': { m: 0 } }}
              >
                <AccordionSummary expandIcon={<ArrowDropDown fontSize="large" />}>
                  {getItemName(row?.at(0))}
                </AccordionSummary>
                <AccordionDetails>
                  {row?.at(1).map((column, index) => (
                    <Box key={index} py={1}>
                      <Typography fontSize="0.85rem">{column?.targetName}</Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
