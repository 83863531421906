import { BoxImage } from '@/web/@components/BoxImage';
import { Button } from '@mui/material';

/**
 * @typedef {'satellite'|'roadmap'} GoogleMapType
 */

const SATELLITE_PNG = '/images/pages/trips/satellite.png';
const ROADMAP_PNG = '/images/pages/trips/roadmap.png';

/**
 * @typedef {object} MapTypeSwitchButtonProps
 * @property {GoogleMapType} value
 * @property {(v: GoogleMapType) => any} onUpdate
 */

/** @param {MapTypeSwitchButtonProps & import('@mui/material').ButtonProps} props */
export function MapTypeSwitchButton(props) {
  const { value, onUpdate, ...buttonProps } = props;

  const handleClick = () => {
    if (value === 'roadmap') {
      onUpdate('satellite');
    } else {
      onUpdate('roadmap');
    }
  };

  return (
    <Button
      {...buttonProps}
      onClick={handleClick}
      title={value === 'roadmap' ? 'Map' : 'Satellite'}
      sx={{
        ...buttonProps?.sx,
        '&:hover': {
          bgcolor: '#fff',
        },
      }}
      style={{
        overflow: 'hidden',
        position: 'absolute',
        top: '100px',
        right: '6px',
        // zIndex: 100,
        minWidth: 0,
        width: '24px',
        height: '24px',
        padding: '2px',
        borderRadius: '3px',
        background: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
        ...buttonProps?.style,
      }}
    >
      {value === 'roadmap' ? (
        <BoxImage src={SATELLITE_PNG} size="100%" objectFit="cover" />
      ) : (
        <BoxImage src={ROADMAP_PNG} size="100%" objectFit="cover" />
      )}
    </Button>
  );
}
