import { CustomInput } from '@/web/@components/CustomForm';
import { Grid, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import { COOLDOWN_MENU_ITEMS } from '../../assets';
import { useFormContext } from 'react-hook-form';

export function CoolDownSettingsForm(props) {
  const { coolDownUnit, setIsEnableCoolDown, setCoolDownUnit, inheritance, defaultValue } = props;

  const { getValues, setValue } = useFormContext();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle2">Cooldown Time</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <CustomInput
          name="cooldownTimer"
          label=""
          type="number"
          placeholder="Enter cooldown time"
          InputProps={{
            readOnly: coolDownUnit === 'TURN_OFF',
            sx: { color: coolDownUnit === 'TURN_OFF' ? 'white' : '-moz-initial' },
            endAdornment: (
              <InputAdornment position="end">
                <Select
                  size="small"
                  value={coolDownUnit}
                  onChange={(e) => {
                    const newUnit = e.target.value;
                    if (['MINUTES', 'SECONDS'].includes(newUnit)) {
                      setIsEnableCoolDown(true);
                    } else {
                      setIsEnableCoolDown(false);
                    }

                    if (newUnit !== coolDownUnit) {
                      const collDownValue = getValues('cooldownTimer');
                      if (coolDownUnit === 'MINUTES' && newUnit === 'SECONDS') {
                        setValue('cooldownTimer', collDownValue * 60);
                      } else if (coolDownUnit === 'SECONDS' && newUnit === 'MINUTES') {
                        setValue('cooldownTimer', collDownValue / 60);
                      }
                    }
                    setCoolDownUnit(newUnit);
                  }}
                  autoWidth
                >
                  {COOLDOWN_MENU_ITEMS?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value} sx={{ fontSize: '12px' }}>
                      {item?.value === 'TURN_OFF' && coolDownUnit === 'TURN_OFF'
                        ? 'Turned Off'
                        : item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            ),
          }}
          TextFieldProps={{
            helperText: `Default Cooldown Time ${defaultValue} sec`,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3} marginTop={'-25px'}>
        {inheritance('cooldownTimer')}
      </Grid>
    </Grid>
  );
}
