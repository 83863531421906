import { CustomTooltip } from '@/web/@components/CustomTooltip';
import { Box, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { startCase } from 'lodash';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

/**
 * @typedef {object} CustomSelectProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {CustomSelectProps} props */
export function GroupSettingsSelectForm(props) {
  const { item } = props;
  const { key: name, label: labelName, options: optionsOrBuilder, rules, ...rest } = item;

  const { control, watch, setValue } = useFormContext();

  const initVal = useWatch({ name: name, control });

  const options =
    typeof optionsOrBuilder === 'function' ? optionsOrBuilder(watch) : optionsOrBuilder;

  const itemValue = useMemo(
    () =>
      options.find((i) => i.value.toString() === initVal + '') || {
        label: startCase(initVal),
        value: initVal,
      },
    [initVal, options]
  );

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
      }}
    >
      <Box display="inline-flex" width="70%" alignItems="center">
        <Typography color="#0B2547" fontSize="14px">
          {labelName}
        </Typography>
        <CustomTooltip text={item?.infoText} />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => {
            return (
              <Autocomplete
                {...field}
                value={itemValue}
                fullWidth
                disabled={Boolean(
                  rest.hasOwnProperty('isDisabled') ? rest.isDisabled(watch) : false
                )}
                size="small"
                handleHomeEndKeys
                disableClearable
                options={options || []}
                renderInput={(params) => (
                  <TextField
                    inputRef={ref}
                    error={invalid}
                    helperText={error?.message}
                    fullWidth
                    size="small"
                    variant="outlined"
                    type="text"
                    {...params}
                  />
                )}
                onChange={(e, val) => {
                  // @ts-ignore
                  const selectedVal = val.value;
                  field.onChange(selectedVal);
                  if (name === 'usbMode' && selectedVal !== 'FILE_TRANSFER') {
                    setValue('usbFileTransfer', false);
                  }
                }}
              />
            );
          }}
        />
        {rest?.postText && (
          <Typography color="#0B2547" fontSize="14px">
            {rest?.postText}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
