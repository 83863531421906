/** @type {Array<TableColumn<Endpoint>>} */
export const FLEET_CAMERA_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    type: 'CHECKBOX',
  },
  {
    id: 'deviceLabel',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    link: (row) => `/cameras/${row.id}`,
    searchable: true,
    fallback: 'Smarter AI Dashcam',
  },
  {
    id: 'deviceSerialNo',
    label: 'Serial No',
    align: 'left',
    searchable: true,
  },
];
