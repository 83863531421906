import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<VariableDefinitionDto>>} */
export const ACTION_ITEMS = [
  {
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    link: (selectedItem) => `/smarter-ai/trigger-variables/${selectedItem.name}/edit`,
  },
  {
    label: 'Search',
    placeholder: T['device-batch.actions.search.placeholder'],
    component: 'SEARCH',
  },
  {
    label: 'Trigger Variable',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/smarter-ai/trigger-variables/create',
  },
];
