import { KnownUserError } from '@/utils/errors';
import { calculateMd5sum } from '@/utils/file-utils';
import { toastWarning } from '@/utils/toaster';
import { uploadToGCP } from '@/utils/uploader/gcpUpload';

/**
 * @param {File} file
 * @param {AIModelV2ForPost | AIModelV2} body
 * @param {(v: number) => any} onProgress
 * @param {AbortSignal} [signal]
 */
export async function uploadAiModelFile(file, body, onProgress, signal) {
  const id = body.modelId;
  const actualFileName = file.name;
  try {
    const result = await uploadToGCP({
      id,
      file,
      signal,
      onProgress,
      type: 'AI_MODEL',
    });
    body.actualFileName = actualFileName;
    body.storedFileName = result.fileName;
    body.checksum = await calculateMd5sum(file);
    if (body.properties) {
      body.properties.ActualFileName = actualFileName;
    }
  } catch (err) {
    if (err instanceof KnownUserError) {
      toastWarning(err.message);
    } else {
      console.error(err);
    }
  }
}
