// @ts-nocheck
import { BoxImage } from '@/web/@components/BoxImage';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { startCase } from 'lodash';

/**
 * @typedef {object} FeedbackDetailsModalProps
 * @property {FeedbackDto} [feedback]
 * @property {() => any} [onCancel]
 */

/** @param {FeedbackDetailsModalProps} props */
export function FeedbackDetailsModal(props) {
  const { feedback, onCancel } = props;

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Feedbacks</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{
            '& .word-break': {
              wordWrap: 'break-word !important',
            },
          }}
        >
          {Object.entries(feedback)?.map(([label, value], index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="subtitle2" lineHeight="18px">
                {label === 'downloadLink' ? 'Snapshot' : startCase(label)}
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight={500}
                lineHeight="18px"
                className="word-break"
              >
                {label === 'downloadLink' && value?.url ? (
                  <BoxImage src={value?.url} width={'550px'} pt={1} />
                ) : (
                  value || '...'
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
