import { TENANT_ICON_OFF } from '@/assets/constants/images';
import { selectTenantId, selectTenantTree } from '@/store/auth';
import { BoxImage } from '@/web/@components/BoxImage';
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-inputRoot': {
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',
    'whiteSpace': 'nowrap',
    'maxWidth': '100%',
    '& .MuiBox-root': {
      maxWidth: '85% !important',
    },
  },
}));

export function AccessEntitySelection({ onSelectTenant }) {
  const tenantId = useSelector(selectTenantId);
  const tenantTreeV = useSelector(selectTenantTree);

  /** @type {StateVariable<array>} */
  const [selectedItems, setSelectedItems] = useState([]);
  /** @type {StateVariable<string>} */
  const [filter, setFilter] = useState('');
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [open, setOpen] = useState(false);

  const tenantTree = useMemo(() => {
    // @ts-ignore
    const tenantArray = Object.entries(tenantTreeV).map(([key, value]) => value);
    const data = tenantArray?.find((item) => item?.tenantId === tenantId);
    return [data];
  }, [tenantTreeV, tenantId]);

  /** @type {DescendantTenant[]} */
  const filteredTenantTree = useMemo(() => {
    const applyFilter = (tree, depth = 0) => {
      if (!tree?.length) return undefined;
      const filteredTree = [];
      for (const tenant of tree) {
        if (!tenant) continue;
        const filteredChildren = applyFilter(tenant.descendantTenantList || [], depth + 1);

        const matchesFilter =
          !filter ||
          tenant.tenantName?.toLowerCase()?.includes(filter?.toLowerCase()) ||
          filteredChildren?.length > 0;

        if (matchesFilter) {
          filteredTree.push({ ...tenant, descendantTenantList: filteredChildren || [] });
        }
      }
      return filteredTree;
    };

    return applyFilter(tenantTree);
  }, [tenantTree, filter]);

  // @ts-ignore
  const filterOptions = (options, { inputValue }) => filteredTenantTree;

  function findParentTenants(data, targetTenantIds, path = []) {
    const targetTenantId = targetTenantIds[targetTenantIds?.length - 1];
    for (const item of data) {
      path?.push(item);
      if (item.tenantId === targetTenantId) {
        if (targetTenantIds?.length === 1) {
          return path;
        } else {
          const remainingIds = targetTenantIds?.slice(0, -1);
          const parent = findParentTenants(data, remainingIds, path);
          if (parent) return parent;
        }
      }
      if (item?.descendantTenantList?.length > 0) {
        const result = findParentTenants(item.descendantTenantList, targetTenantIds, path);
        if (result) {
          return result;
        }
      }
      path?.pop();
    }
    return null;
  }

  const flattenDescendantTenants = useCallback((itemList) => {
    let flattenedList = [];
    for (const tenant of itemList || []) {
      flattenedList.push(tenant);
      if (tenant.descendantTenantList?.length > 0) {
        flattenedList = [
          ...flattenedList,
          ...flattenDescendantTenants(tenant.descendantTenantList || []),
        ];
      }
    }
    return flattenedList;
  }, []);

  const flattenTreeData = useMemo(
    () => flattenDescendantTenants(tenantTree),
    [tenantTree, flattenDescendantTenants]
  );

  function orderDataByTenantIds(data, targetTenantIds) {
    const orderedData = [];
    for (const targetId of targetTenantIds) {
      const item = data?.find((item) => item.tenantId === targetId);
      if (item) {
        orderedData.push(item);
        data = item.descendantTenantList;
      } else {
        break;
      }
    }
    return orderedData;
  }

  const handleItemChange = async (treePath) => {
    if (!treePath?.length) return;
    setLoading(true);
    try {
      const parent = findParentTenants(flattenTreeData, treePath);
      const orderedData = orderDataByTenantIds(parent, treePath);
      setSelectedItems(orderedData);
    } catch (err) {
      console.error('Tenant switch failed', err);
    } finally {
      setLoading(false);
      setFilter('');
      handleCloseAutocomplete();
    }
  };

  const handleCloseAutocomplete = () => {
    setOpen(false);
  };

  //Only first time
  useEffect(() => {
    onSelectTenant(tenantId);
    setSelectedItems(tenantTree);
  }, [tenantId, tenantTree, onSelectTenant]);

  useEffect(() => {
    const data = selectedItems?.at(selectedItems?.length - 1);
    if (data) {
      onSelectTenant(data?.tenantId);
    } else {
      onSelectTenant(null);
    }
  }, [selectedItems, onSelectTenant]);

  return (
    <StyledAutocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleCloseAutocomplete}
      disablePortal
      disableClearable
      disabled={(filteredTenantTree || [])?.length === 0}
      options={filteredTenantTree || []}
      filterOptions={filterOptions}
      // @ts-ignore
      getOptionLabel={(option) => option?.tenantName}
      getOptionDisabled={(option) => option === selectedItems[0]}
      fullWidth
      value={selectedItems}
      sx={{ width: '100%', pb: 2 }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={selectedItems?.length > 0 ? '' : 'Search items'}
          onChange={(event) => {
            setFilter(event?.target?.value);
          }}
        />
      )}
      multiple={true}
      // @ts-ignore
      onChange={(event, value) => {
        // @ts-ignore
        setSelectedItems(value);
      }}
      renderTags={(value) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value
            // @ts-ignore
            .map((x) => x?.tenantName)
            .filter(Boolean)
            .join(' > ')}
        </Box>
      )}
      // @ts-ignore
      renderOption={(props, option, { inputValue }) => {
        return (
          <TenantTreeItem
            // @ts-ignore
            key={option.tenantId}
            tree={option}
            filter={filter}
            selected={selectedItems || []}
            onSelect={loading ? undefined : handleItemChange}
          />
        );
      }}
    />
  );
}

export function TenantTreeItem(props) {
  const { indent = 0, tree, filter, selected, onSelect } = props;
  const [collapsed, setCollapsed] = useState(true);

  const leaf = !tree.descendantTenantList?.length;
  const active = selected?.length === 1 && selected[0]?.tenantId === tree.tenantId;
  //const active = selected?.at(selected?.length - 1)?.id === tree?.id;

  useEffect(() => {
    setCollapsed(!filter?.length && (!selected?.length || tree.tenantId === selected[0]));
  }, [tree.tenantId, filter, selected]);

  const nameParts = useMemo(() => {
    const name = tree.tenantName || '';
    if (!filter?.length) {
      return [name];
    }
    let offset = 0;
    const parts = [];
    for (const part of name.toLowerCase().split(filter)) {
      parts.push(name.substring(offset, offset + part.length));
      offset += part.length;
      if (name.length === offset) break;
      parts.push(name.substring(offset, offset + filter.length));
      offset += filter.length;
    }
    return parts;
  }, [tree.tenantName, filter]);

  return (
    <>
      <MenuItem
        disableRipple
        key={tree.tenantId}
        onClick={() => onSelect([tree.tenantId])}
        sx={{
          'pl': 2 + indent * 3.5,
          'color': active ? '#fff' : undefined,
          'bgcolor': active ? '#586B81' : undefined,
          '&:hover': {
            bgcolor: active ? undefined : '#F6F9FE',
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" gap={1} justifyContent="center" alignItems="center">
            <BoxImage src={TENANT_ICON_OFF} height="20px" width="20px" />
            <Box flex="1">{nameParts?.map((part, i) => (i % 2 ? <b>{part}</b> : part))}</Box>
          </Box>

          {leaf ? null : (
            <Box
              width="24px"
              height="24px"
              bgcolor="#F8FAFF"
              borderRadius="50%"
              onClick={(e) => {
                e.stopPropagation();
                setCollapsed((v) => !v);
              }}
            >
              <svg
                width="12px"
                height="12px"
                viewBox="0 0 2 2"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transition: 'all 0.3s ease-in',
                  margin: collapsed ? '6px 10px' : '10px 6px',
                  transform: collapsed ? 'rotate(-90deg)' : undefined,
                }}
              >
                <path d="M1,1,2,0H0Z" fill="#5C6A80" />
              </svg>
            </Box>
          )}
        </Box>
      </MenuItem>
      {collapsed || !tree.descendantTenantList?.length ? null : (
        <>
          {tree.descendantTenantList.map((child) => (
            <TenantTreeItem
              key={child.tenantId}
              indent={indent + 1}
              tree={child}
              filter={filter}
              selected={selected.slice(1)}
              onSelect={(path) => onSelect([tree.tenantId, ...path])}
            />
          ))}
        </>
      )}
    </>
  );
}
