import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { formatCameraName, isCameraInParkingMode } from '@/utils/cameras';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { CenterBox } from '@/web/@components/CenterBox';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * @typedef {object} CameraDetailsContextData
 * @property {Number} cameraId
 * @property {boolean} isOnline
 * @property {boolean} isInParkingMode
 * @property {EndpointDetailDto} camera
 * @property {{[key: string]: string}} capabilities
 * @property {GetEndpointCapabilities['capabilities']} rawCapabilities
 * @property {(updates: Partial<EndpointDetailDto>) => any} updateCamera
 * @property {() => any} refreshCamera
 */

/** @type {import('react').Context<CameraDetailsContextData>} */
export const CameraDetailsContext = createContext(null);

export function CameraDetailsLayout() {
  const params = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const cameraId = useMemo(() => Number(params.cameraId), [params?.cameraId]);

  const navigate = useNavigate();
  const location = useLocation();
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<EndpointDetailDto>} */
  const [camera, setCamera] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [initialTenantId] = useState(tenantId);
  /** @type {StateVariable<{[key: string]: string}>} */
  const [capabilities, setCapabilities] = useState({});
  /** @type {StateVariable<EndpointCapabilities>} */
  const [rawCapabilities, setRawCapabilities] = useState({});

  const cameraName = useMemo(
    () => (camera ? formatCameraName(camera.label, camera.deviceSerialNumber) : null),
    [camera]
  );

  const updateCamera = useCallback((updates) => {
    setCamera((v) => ({ ...v, ...updates }));
  }, []);

  const refreshCamera = useCallback(() => {
    setRefresh(Date.now());
  }, []);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  useEffect(() => {
    if (!cameraId) return;
    setError(null);
    setLoading(true);
    const request = api.ac.v5.endpoint.$endpointId(cameraId).details.$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then(setCamera)
      .catch((err) => {
        setError(err);
        reportEvent(SentryEvents.CAMERA_DETAILS_LOAD_FAILED, '', {
          err,
          refresh,
          secretToken,
          cameraId,
          tags: { cameraId },
        });
      })
      .finally(() => setLoading(false));
  }, [cameraId, secretToken, refresh]);

  useEffect(() => {
    if (!cameraName) return;
    setBreadcrumbTitle(cameraName, (path) => path.endsWith(cameraId + ''));
  }, [cameraId, cameraName, setBreadcrumbTitle]);

  const isInParkingMode = useMemo(
    () => isCameraInParkingMode(camera?.parkingModeStatus),
    [camera?.parkingModeStatus]
  );

  const isOnline = useMemo(
    () => camera?.isOnline && !isInParkingMode,
    [camera?.isOnline, isInParkingMode]
  );

  useEffect(() => {
    if (!cameraId) return;
    const request = api.ac.endpoint.capabilities.$get({
      params: {
        secretToken,
        endpointId: Number(cameraId),
      },
    });
    request
      .process()
      .then(({ capabilities }) => {
        setRawCapabilities(capabilities);
        /** @type {{[key: string]: string}} */
        const result = {};
        capabilities.properties.forEach((item) => {
          result[item.key] = item.value;
        });
        result.parkingModeStatus = capabilities?.parkingModeStatus;
        setCapabilities(result);
      })
      .catch((err) => {
        console.error('Failed to fetch endpoint capabilities', err);
        setRawCapabilities({});
        setCapabilities({});
      });
  }, [secretToken, cameraId, refresh]);

  if (error) {
    return (
      <CenterBox>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          size="256px"
          message="Could not get Camera details"
        />
      </CenterBox>
    );
  }

  if (loading || !camera) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <CameraDetailsContext.Provider
      value={{
        cameraId: Number(cameraId),
        camera,
        isOnline,
        isInParkingMode,
        capabilities,
        rawCapabilities,
        refreshCamera,
        updateCamera,
      }}
    >
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </CameraDetailsContext.Provider>
  );
}
