import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Grid } from '@mui/material';
import { differenceInDays, parseISO, subDays } from 'date-fns';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EndpointInfo } from '../@components/EndpointInfo';
import { NeedCoachingForm } from '../@components/NeedCoachingForm';
import { ScoreByPersonaWidget } from '../@components/ScoreByPersonaWidget';
import { TriggerDetailsByType } from '../@components/TriggerDetailsByType';
import { ScoringFilterContext } from '../@context/ScoringFilterContext';

export function EndpointDetails() {
  const { id } = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);
  const { filteredData, setSelectedFilterTypes } = useContext(ScoringFilterContext);

  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<EndpointDetailsResponse & {endpointId: number}>} */
  const [endpoint, setEndpoint] = useState(null);
  /** @type {StateVariable<Array<any>>} */ //TODO::SCORE, API ISSUE
  const [endpointPersonaScores, setEndpointPersonaScores] = useState([]);
  /** @type {StateVariable<number>} */
  const [selectedPersonaId, setSelectedPersonaId] = useState(1);
  const [needCoachingModal, setNeedCoachingModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchEndpointDetails = useCallback(
    async (signal) => {
      try {
        if (!id) return;
        const request = api.ac.v5.endpoint.$endpointId(Number(id)).aggregated.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
        });
        const result = await request.process();
        const newData = {
          ...result,
          endpointId: Number(id),
        };
        setEndpoint(newData);
      } catch (ex) {
        console.error(ex);
      }
    },
    [secretToken, id]
  );

  const fetchEndpointScoreByPersona = useCallback(
    async (signal, isPrevious = false) => {
      try {
        if (!id) return;

        const daysDiff = isPrevious
          ? differenceInDays(
              endOfTheDay(filteredData?.dateRange?.to),
              startOfTheDay(filteredData?.dateRange?.from)
            )
          : 0;

        const fromDate = parseISO(filteredData?.dateRange?.from);

        const startDate = isPrevious
          ? startOfTheDay(subDays(fromDate, daysDiff + 1))
          : startOfTheDay(fromDate);

        const endDate = isPrevious
          ? endOfTheDay(subDays(fromDate, 1))
          : endOfTheDay(filteredData?.dateRange?.to);

        if (!startDate || !endDate) return;

        const request = api.ac.v5.score.endpoint.$endpointId(Number(id)).$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            fromTimestamp: startDate,
            toTimestamp: endDate,
          },
        });

        const result = await request.process();
        // @ts-ignore
        const scores = result?.scores || [];

        if (isPrevious) {
          setEndpointPersonaScores((prevScores) =>
            prevScores.map((prevScore) => ({
              ...prevScore,
              prevScore: scores.find((score) => score.personaId === prevScore.personaId)?.score,
            }))
          );
        } else {
          setEndpointPersonaScores(scores);
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    [secretToken, id, filteredData]
  );

  const handleSelectPersona = (personaId) => {
    setSelectedPersonaId(personaId);
  };

  const handelOnModalHide = () => {
    setNeedCoachingModal(false);
  };

  useEffect(() => {
    if (!endpoint?.endpointName) return;
    setBreadcrumbTitle(endpoint?.endpointName);
    setSelectedFilterTypes([-1, 4, 3, 203]);
    setSelectedPersonaId(1);
  }, [setBreadcrumbTitle, endpoint?.endpointName, setSelectedFilterTypes]);

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);
    const fetchData = async () => {
      await fetchEndpointDetails(aborter?.signal);
      await fetchEndpointScoreByPersona(aborter?.signal);
      await fetchEndpointScoreByPersona(aborter?.signal, true);
      setTimeout(() => setLoading(false), 2500);
    };
    fetchData();
    return () => aborter?.abort();
  }, [fetchEndpointDetails, fetchEndpointScoreByPersona]);

  if (!endpoint) {
    return <CenterBox children={<CircularProgress />} />;
  }

  return (
    <Box mx={2.5}>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mt={2}>
        <EndpointInfo endpoint={endpoint} />
        <Grid
          container
          spacing={1}
          sx={{
            alignItems: 'baseline',
            border:
              !loading && endpointPersonaScores?.length > 0
                ? '1px solid white'
                : '1px solid #c5d9f0',
            p: 2,
          }}
        >
          {loading ? (
            <CenterBox>
              <CircularProgress />
            </CenterBox>
          ) : endpointPersonaScores?.length === 0 ? (
            <CenterBox>
              <IconMessageBox
                size="128px"
                src="/images/empty/no-search-results.svg"
                message="No Data!"
              />
            </CenterBox>
          ) : (
            endpointPersonaScores?.map((endpointPersonaScore, index) => (
              <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
                <Box
                  onClick={() => handleSelectPersona(endpointPersonaScore?.personaId)}
                  sx={{ cursor: 'pointer' }}
                >
                  <ScoreByPersonaWidget
                    isSelected={selectedPersonaId === endpointPersonaScore?.personaId}
                    score={endpointPersonaScore?.score}
                    prevScore={endpointPersonaScore?.prevScore}
                    personaId={endpointPersonaScore?.personaId}
                  />
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </Box>

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => {
            setNeedCoachingModal(true);
          }}
        >
          <BoxImage src={COACHING_ICON_OFF} size={{ xs: '22px', sm: '18px' }} mr={1} />
          Need Coaching
        </Button>
      </Box> */}

      {needCoachingModal && <NeedCoachingForm onHide={handelOnModalHide} endpoint={endpoint} />}

      <Box mt={3}>
        <TriggerDetailsByType type="ENDPOINT" entityId={id} personaId={selectedPersonaId} />
      </Box>
    </Box>
  );
}
