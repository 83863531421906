import { SupportPage } from '.';
import { PrivacyPolicyPage } from './privacy-policy';
import { TermsAndConditionsPage } from './terms-and-conditions';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <SupportPage />,
    visibility: 'TENANT_AUTH',
  },
  {
    path: 'terms-and-conditions',
    element: <TermsAndConditionsPage />,
    breadcrumb: 'Terms & Conditions',
    visibility: 'ALWAYS',
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicyPage />,
    breadcrumb: 'Privacy Policy',
    visibility: 'ALWAYS',
  },
];

export default routes;
