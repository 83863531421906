import { BOX_DISCONTINUE, BOX_MODIFY_ICON, BOX_RELEASE_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<AiContainerResponse>>} */
export const ACTION_ITEMS = [
  {
    label: T['button.release'],
    iconImage: BOX_RELEASE_ICON,
    component: 'BUTTON',
    type: 'RELEASE',
    variant: 'outlined',
    confirmationMessage: T['ai.container.confirmations.release'],
    hidden: (selected) => selected.length !== 1 || selected[0].publicationStatus === 'RELEASED',
  },
  {
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    confirmationMessage: T['ai.container.confirmations.discontinue'],
    hidden: (selected) => !selected.length,
  },
  {
    label: T['button.modify'],
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selectedItem) => `/administration/ai/ai-containers/${selectedItem.id}/edit`,
    hidden: (selected) => selected.length !== 1 || selected[0].publicationStatus === 'RELEASED',
  },
  {
    label: 'Search',
    placeholder: T['ai.containers.table.search.placeholder'],
    component: 'SEARCH',
  },
  {
    label: T['ai.containers.table.button.add'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/ai/ai-containers/create',
  },
];
