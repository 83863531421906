import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<APNInfo>>} */
export const APN_TABLE_COLUMNS = [
  {
    id: 'apnInfoId',
    label: '',
    align: 'center',
    width: 50,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: 'APN Name',
    align: 'left',
    isPrimary: true,
    searchable: true,
  },
  {
    id: 'apn',
    label: 'APN',
    searchable: true,
  },
  {
    id: 'mnc',
    label: 'MNC',
    align: 'left',
    searchable: true,
  },
  {
    id: 'mcc',
    label: 'MCC',
    align: 'left',
    searchable: true,
  },
  {
    id: 'apnType',
    label: 'APN Type',
    align: 'left',
    searchable: true,
  },
];
