import { formatTimestamp } from '@/utils/datetime';
import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { Typography } from '@mui/material';
import { FileUploaderUser } from '../FileUploaderUser';
import { LongMessageViewer } from '@/web/@components/LongMessageViewer';

/** @type {Array<TableColumn<DbcFileDto>>} */
export const APK_VERSION_TABLE_COLUMNS = [
  {
    id: 'version',
    label: 'Version',
    align: 'center',
    isPrimary: true,
    searchable: true,
    format: (value) => <LongTextShortener text={value} length={45} sx={{ fontSize: '14px' }} />,
  },
  {
    id: 'actualFileName',
    label: 'Filename',
    align: 'center',
    searchable: true,
    format: (value) => <LongTextShortener text={value} length={80} sx={{ fontSize: '14px' }} />,
  },
  {
    id: 'updatedAt',
    label: 'Date/Time',
    align: 'center',
    searchable: true,
    format: (value) => <Typography sx={{ fontSize: '14px' }}>{formatTimestamp(value)}</Typography>,
  },
  {
    id: 'updatedBy',
    label: 'User',
    align: 'center',
    searchable: true,
    format: (value, row) => {
      if (!value) return;
      return <FileUploaderUser updatedBy={value} />;
    },
  },
  {
    id: 'uploadNotes',
    label: 'Upload Notes',
    align: 'center',
    searchable: true,
    format: (value) => (
      <LongMessageViewer message={value || '...'} title={'Upload Notes'} isCodeView={false} />
    ),
  },
];
