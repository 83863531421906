import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Build Product properties from a product details
 * @param {ProductWithAccelerator} product
 * @returns {import('@/types').ProductProperties}
 */
export function buildProductProperties(product) {
  if (!product) return null;
  /** @type {any} */
  const result = { ...product };
  for (const p of product.properties || []) {
    result[p.key] = p.value;
  }
  if (product.aiAcceleratorList?.length) {
    result.aiAccelerator = product.aiAcceleratorList[0];
  }
  return result;
}

/**
 * Fetch list of all products
 */
export function useFullProductList() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      let conToken = '';
      const limit = 25;
      /** @type {Array<import('@/types').ProductProperties>} */
      const results = [];
      while (!signal.aborted) {
        const request = api.ac.v1.tenant.product.assign.$get({
          signal,
          params: {
            tenantId,
            secretToken,
            pageSize: limit,
            continuationToken: conToken,
          },
        });
        await request.process();
        if (!request.result) break;
        conToken = request.result.continuationToken;
        for (const product of request.result.data || []) {
          const props = buildProductProperties(product);
          if (props?.productId) {
            results.push(props);
          }
        }
        if (!conToken?.trim()) break;
      }
      return results;
    },
    [],
    { cache: false }
  );
}

export function useV5ProductList() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      const request = api.ac.v5.product.tenant.$tenantId(tenantId).$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      return result?.products;
    },
    [],
    { cache: false }
  );
}
