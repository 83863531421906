import { T } from '@/assets/locales';
import { EndpointCoachingPage } from '.';
import { CoachingEndpointPage } from './_endpointId';
import { GuidedCoachingPage } from './_endpointId/guided';
import { CoachableEventsPage } from './coachable-events';
import { PendingCoachingPage } from './_endpointId/pending';
import { EndpointCoachingGridList } from './@components/EndpointCoachingGridList';
import { CoachingHistory } from './@components/CoachingHistory';
import { CoachingPending } from './@components/CoachingPending';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <EndpointCoachingPage />,
    breadcrumb: T['coaching.page.title'],
    children: [
      {
        path: '',
        element: <EndpointCoachingGridList />,
        breadcrumb: '',
      },
      {
        path: 'history',
        element: <CoachingHistory />,
        breadcrumb: 'Coaching History',
      },
      {
        path: 'pending',
        breadcrumb: 'Coaching Pending',
        children: [
          {
            path: '',
            element: <CoachingPending />,
          },
          {
            path: ':endpointId',
            element: <CoachingEndpointPage />,
            children: [
              {
                path: 'session/:sessionId',
                element: <PendingCoachingPage />,
                breadcrumb: 'Endpoint',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'coachable-events',
    element: <CoachableEventsPage />,
    breadcrumb: T['coaching.events.page.title'],
  },
  {
    path: ':endpointId',
    element: <CoachingEndpointPage />,
    children: [
      {
        path: 'guided',
        element: <GuidedCoachingPage />,
        breadcrumb: T['coaching.guided.page.title'],
      },
      {
        path: 'self',
        element: <GuidedCoachingPage />,
        breadcrumb: T['coaching.self.page.title'],
      },
      // {
      //   path: 'pending/:sessionId',
      //   element: <PendingCoachingPage />,
      //   breadcrumb: T['coaching.pending.page.title'],
      // },
    ],
  },
];

export default routes;
