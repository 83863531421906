import { Box, Paper, Stack, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { useEffect, useMemo, useState } from 'react';
import { ScoreMiniWidget } from '../ScoreMiniWidget';

/**
 * @typedef {object} ScoringLineChartProps
 * @property {Array<{data: Array<ScoreForDate>, persona: PersonaDto}>} [scores]
 */

/**
 * @param {ScoringLineChartProps} props
 */
export default function ScoringLineChart(props) {
  const { scores } = props;

  /** @type {StateVariable<Array<{ data: (number | null)[], label: string, curve: import('@mui/x-charts').CurveType }>>} */
  const [axisData, setAxisData] = useState([]);

  const replaceZerosAndUndefined = (arr) => {
    let lastNonZero = null; // Starting with null
    return arr.map((value) => {
      if (value === 0 || value === undefined) {
        return lastNonZero;
      } else {
        lastNonZero = value;
        return value;
      }
    });
  };

  useEffect(() => {
    const data = scores?.map((i) => {
      const parsedData = replaceZerosAndUndefined(i.data.map((d) => d.score));
      return { data: parsedData, label: i.persona.name, curve: 'linear' };
    });
    // @ts-ignore
    setAxisData(data);
  }, [scores]);

  function getDateArrayWithUnderscores(startDate, endDate, interval) {
    let currentDate = new Date(startDate);
    const dateArray = [];

    while (currentDate <= endDate) {
      const formattedDate = currentDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });

      dateArray.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  const startDate = useMemo(() => {
    const start = scores?.at(0)?.data?.at(0)?.displayDate;
    if (!start) return;
    const [day, month, year] = start?.split('-');
    const formattedDate = new Date(`${month}/${day}/${year}`);
    return formattedDate;
  }, [scores]);

  const endDate = useMemo(() => {
    const end = scores?.at(0)?.data?.at(scores?.at(0)?.data?.length - 1)?.displayDate;
    if (!end) return;
    const [day, month, year] = end?.split('-');
    const formattedDate = new Date(`${month}/${day}/${year}`);
    return formattedDate;
  }, [scores]);

  const xLabels = useMemo(() => {
    const interval = 5;
    const resultArray = getDateArrayWithUnderscores(startDate, endDate, interval);
    return resultArray;
  }, [endDate, startDate]);

  const CustomItemTooltipContent = (props) => {
    const { itemData, series } = props;
    return (
      <Paper sx={{ padding: 3, backgroundColor: series.color }}>
        <p>{series.label}</p>
        <p>{series.data[itemData.dataIndex]}</p>
      </Paper>
    );
  };

  const valueFormatter = (val) => {
    return (
      <Box width="24px" height="24px" position="relative">
        <ScoreMiniWidget value={val} />
        <Typography
          position="absolute"
          left={5}
          right={5}
          top={1}
          bottom={0}
          textAlign={'center'}
          borderRadius="50%"
          lineHeight={val ? '24px' : '20px'}
          fontWeight={600}
          fontSize={Math.round(val) > 99 ? '8px' : '10px'}
        >
          {Math.round(val) || '...'}
        </Typography>
      </Box>
    );
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems="center"
      sx={{ width: '100%', height: { xs: 300, xl: 400 } }}
    >
      <LineChart
        tooltip={{ trigger: 'axis', slots: { itemContent: CustomItemTooltipContent } }}
        // @ts-ignore
        series={axisData.map((series) => ({
          ...series,
          valueFormatter: valueFormatter,
        }))}
        xAxis={[
          {
            scaleType: 'point',
            data: xLabels,
            tickLabelInterval: (value, index) =>
              scores?.at(0)?.data?.length > 10 ? index % 2 === 0 : true,
          },
        ]}
        sx={{
          '& .MuiStack-root': {
            width: '105% !important',
          },
          '& .MuiLineElement-root': {
            strokeWidth: 2,
          },
          '& .MuiMarkElement-root': {
            fill: '#7395bb',
            stroke: '#fff',
          },
          '& .MuiChartsAxis-tickLabel': {
            color: '#4D6076',
            opacity: 0.5,
          },
          '& .MuiChartsAxis-tick': {
            opacity: 0,
          },
          '& .MuiChartsAxis-directionY': {
            '.MuiChartsAxis-line': {
              stroke: '#fff',
            },
          },
          '& .MuiChartsAxis-line': {
            color: '#4D6076',
            opacity: 0.1,
          },
        }}
        yAxis={[{ min: 0, max: 102 }]}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'right' },
            padding: 4,
            labelStyle: {
              fontSize: 14,
              fontWeight: 500,
              fill: 'rgba(11, 37, 71, 0.68)',
              paddingRight: '10px',
            },
          },
        }}
      />
    </Stack>
  );
}
