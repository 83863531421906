import { CheckCircle, Circle } from '@mui/icons-material';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { SELECTION_TYPES } from '../../../assets';

export function ApplicableTypeSelection(props) {
  const { property, inputType, setValue } = props;

  return (
    <RadioGroup row>
      {SELECTION_TYPES.map((item, index) => (
        <FormControlLabel
          key={index}
          value={item.value}
          control={
            <Radio
              sx={{
                '& .checkbox': {
                  padding: '1px',
                  fontSize: '1.375rem',
                  borderRadius: '50%',
                  background: '#ffffff',
                  border: '1px solid #cfd8e5',
                },
              }}
              checked={inputType?.value === item.value}
              checkedIcon={<CheckCircle htmlColor="#596a81" className="checkbox" />}
              icon={<Circle htmlColor="#CFD8E5" className="checkbox" />}
              onChange={() => setValue && setValue(property, item)}
            />
          }
          label={item.label}
        />
      ))}
    </RadioGroup>
  );
}
