import { formatTimestamp } from '@/utils/datetime';
import { parseProperty } from '../../@utils/index';
import { CameraNetworkChangeDetails } from '../CameraNetworkChangeDetails';

/** @type {Array<TableColumn<CameraTypeRecord>>} */
export const GROUP_CAMERA_NETWORK_CHANGE_TABLE_COLUMNS = [
  {
    id: 'reportingTime',
    label: 'Time',
    align: 'left',
    searchable: true,
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'cellular_signal_strength',
    label: 'Cellular Signal Quality',
    align: 'center',
    searchable: true,
    format: (value, row) => {
      if (row?.cellular_available === 'yes') {
        const data = {};
        value.split(',').forEach((line) => {
          const [key, value] = parseProperty(line, ':');
          if (key) data[key] = value;
        });
        return data?.signal_quality;
      }
      return 'Disconnected';
    },
  },
  {
    id: 'wifi_rssi',
    label: 'Wi-Fi  Signal Quality',
    align: 'center',
    searchable: true,
    format: (value, row) => {
      if (row?.wifi_available === 'yes') {
        return `${value} dBm`;
      }
      return 'Disconnected';
    },
  },
  {
    id: '',
    label: 'Details',
    align: 'center',
    searchable: true,
    isPrimary: true,
    format: (value, row) => <CameraNetworkChangeDetails row={row} />,
  },
];
