import {
  AI_MODEL_INPUT_SOURCE_AUDIO_CODEC_LIST,
  AI_MODEL_INPUT_SOURCE_AUDIO_TYPES,
  AI_MODEL_INPUT_SOURCE_BIT_DEPTH,
  AI_MODEL_INPUT_SOURCE_CHANNELS,
  AI_MODEL_INPUT_SOURCE_CODEC_LIST,
  AI_MODEL_INPUT_SOURCE_COLOR_TYPES,
  AI_MODEL_INPUT_SOURCE_MODEL_NAMES,
  AI_MODEL_INPUT_SOURCE_QUANTIZATION,
  AI_MODEL_INPUT_SOURCE_RESOLUTIONS,
  AI_MODEL_INPUT_SOURCE_SAMPLE_RATES,
  AI_MODEL_INPUT_SOURCE_SENSOR_TYPES,
  AI_MODEL_INPUT_TYPE_OPTIONS,
} from '@/assets/ai/ai-model';

/** @type {Array<AIModelForm>} */
export const BASIC_MODEL_INPUT_SOURCE = [
  {
    name: 'inputType',
    type: 'select',
    label: 'Input Type *',
    mandatory: true,
    options: AI_MODEL_INPUT_TYPE_OPTIONS,
    placeholder: 'Select input type',
  },
  {
    name: 'modelName',
    label: 'Model Name *',
    type: 'select',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_MODEL_NAMES,
    placeholder: 'Select model name',
    visibility: (inputType) => {
      return inputType === 'MODEL';
    },
  },
  {
    type: 'input',
    name: 'name',
    label: 'Name',
    placeholder: 'Enter input name',
    visibility: (inputType) =>
      inputType === 'VIDEO' || inputType === 'AUDIO' || inputType === 'SENSOR',
  },
  {
    type: 'input',
    name: 'description',
    label: 'Description',
    placeholder: 'Enter description',
    visibility: (inputType) => true,
  },
];

/** @type {Array<AIModelForm>} */
export const SECONDARY_MODEL_INPUT_SOURCE = [
  {
    name: 'resolution',
    label: 'Resolution *',
    type: 'select',
    placeholder: 'Select Resolution',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_RESOLUTIONS,
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    name: 'colorType',
    label: 'Color Type *',
    type: 'select',
    placeholder: 'Select color type',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_COLOR_TYPES,
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    name: 'bitDepth',
    label: 'Bit Depth',
    type: 'select',
    placeholder: 'Select bit depth',
    options: AI_MODEL_INPUT_SOURCE_BIT_DEPTH,
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    type: 'input',
    name: 'fovX',
    label: 'FOV X (°)',
    placeholder: 'Enter FOV value',
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    type: 'input',
    name: 'fovY',
    label: 'FOV Y (°)',
    placeholder: 'Enter FOV value',
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    type: 'input',
    name: 'fps',
    label: 'FPS',
    placeholder: 'Enter FPS value',
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    type: 'select',
    name: 'sampleRate',
    label: 'Sample Rate *',
    placeholder: 'Select sample rate',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_SAMPLE_RATES,
    visibility: (inputType) => inputType === 'AUDIO',
  },
  {
    type: 'select',
    name: 'quantization',
    label: 'Quantization *',
    placeholder: 'Select quantization',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_QUANTIZATION,
    visibility: (inputType) => inputType === 'AUDIO',
  },
  {
    type: 'select',
    name: 'channel',
    label: 'Channel *',
    placeholder: 'Select channel',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_CHANNELS,
    visibility: (inputType) => inputType === 'AUDIO',
  },
  {
    type: 'select',
    name: 'sensorType',
    label: 'Sensor Type *',
    placeholder: 'Select sensor type',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_SENSOR_TYPES,
    visibility: (inputType) => inputType === 'SENSOR',
  },
  {
    type: 'select',
    name: 'unit',
    label: 'Sensor Unit *',
    mandatory: true,
    placeholder: 'Select sensor unit',
    options: [
      {
        value: 'celsius',
        label: 'Celsius',
        visibility: (sensorType) => sensorType === 'temperature',
      },
      {
        value: 'RH',
        label: 'Relative Humidity %',
        visibility: (sensorType) => sensorType === 'humidity',
      },
      {
        value: 'hPa',
        label: 'Millibar',
        visibility: (sensorType) => sensorType === 'pressure',
      },
      {
        value: 'm/s2',
        label: 'Meters/sec^2',
        visibility: (sensorType) => sensorType === 'accelerometer',
      },
      {
        value: 'g',
        label: 'Gravitational force',
        visibility: (sensorType) => sensorType === 'accelerometer',
      },

      {
        value: 'tesla',
        label: 'Tesla',
        visibility: (sensorType) => sensorType === 'magnetometer',
      },

      {
        value: 'μm',
        label: 'Micro meter (μm)',
        visibility: (sensorType) => sensorType === 'thermal_radiation',
      },
    ],
    visibility: (inputType) => inputType === 'SENSOR',
  },
  {
    type: 'number',
    name: 'bitrate',
    label: 'Bitrate (kbps) *',
    mandatory: true,
    placeholder: 'Enter bitrate',
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    type: 'select',
    name: 'codec',
    label: 'Codec',
    mandatory: true,
    placeholder: 'Select video codec',
    options: AI_MODEL_INPUT_SOURCE_CODEC_LIST,
    visibility: (inputType) => inputType === 'VIDEO',
  },
  {
    type: 'select',
    name: 'audioCodec',
    label: 'Audio Codec *',
    placeholder: 'Select audio codec',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_AUDIO_CODEC_LIST,
    visibility: (inputType) => inputType === 'AUDIO',
  },
  {
    type: 'select',
    name: 'audioType',
    label: 'Audio Type *',
    placeholder: 'Select audio type',
    mandatory: true,
    options: AI_MODEL_INPUT_SOURCE_AUDIO_TYPES,
    visibility: (inputType) => inputType === 'AUDIO',
  },
  {
    type: 'switch',
    name: 'depthSensor',
    label: 'Depth Sensor',
    visibility: (inputType) => inputType === 'VIDEO',
  },
];
