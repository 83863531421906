import { FormLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * @template T
 * @typedef {object} CustomDateSelectProps<T>
 * @property {any} name
 * @property {string} [label]
 * @property {boolean} [disabled]
 * @property {Partial<import('@mui/x-date-pickers').DesktopDatePickerProps>} [DatePickerProps]
 * @property {import('react-hook-form').ControllerProps<T, any>['rules']} [rules]
 */

/**
 * @template T
 * @param {CustomDateSelectProps<T> & import('@mui/material').FormControlProps} props
 */
export function CustomDateSelect(props) {
  const { name, label, disabled = false, rules, DatePickerProps, ...extraProps } = props;

  /** @type {import('react-hook-form').UseFormReturn<T, any>} */
  const { control } = useFormContext();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="medium"
      margin="dense"
      {...extraProps}
      sx={{
        mt: 0,
        ...extraProps.sx,
      }}
    >
      {label && (
        <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
          {label}
        </FormLabel>
      )}

      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={value}
              disabled={disabled}
              onChange={(event) => {
                onChange(event);
              }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  error: Boolean(error),
                  helperText: error?.message || '',
                },
              }}
              {...DatePickerProps}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  );
}
