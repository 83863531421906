import api from '@/api';
import { CustomLogger } from '@/utils/logger';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { EventGridListItem } from '../../events/@components/EventGridListItem';
import { EventListPlaceholder } from './EventListPlaceholder';

const logger = new CustomLogger('EventList');

/**
 * @typedef {object} EventListProps
 * @property {HTMLElement} player
 * @property {number} cameraId
 * @property {string} tenantId
 * @property {string} secretToken
 */

/** @param {EventListProps} props */
export function PlayerEventList(props) {
  const { player, cameraId, tenantId, secretToken } = props;

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<number>} */
  const [itemWidth, setItemWidth] = useState(null);
  /** @type {StateVariable<number>} */
  const [itemsPerRow, setItemsPerRow] = useState(null);
  /** @type {StateVariable<number>} */
  const [startTime, setStartTime] = useState(null);
  /** @type {StateVariable<number>} */
  const [endTime, setEndTime] = useState(null);
  /** @type {StateVariable<Array<EventV5ResponseModel>>} */
  const [events, setEvents] = useState([]);
  /** @type {StateVariable<Element>} */
  const [sidebar, setSidebar] = useState();

  useEffect(() => {
    let lastUpdate = 0;
    const aborter = new AbortController();
    const signal = aborter.signal;
    const update = () => {
      const el = player.querySelector('.player-sidebar');
      setSidebar((v) => {
        if (v !== el) lastUpdate = 0;
        return el;
      });
      if (!el) return;
      const config = player['eventListItemConfig'] || {};
      setItemWidth(config.itemWidth);
      setItemsPerRow(config.itemsPerRow || 1);
      if (Date.now() - lastUpdate < 30000) return;
      lastUpdate = Date.now();
      const range = player['eventListTimeRange'];
      setStartTime(range ? range[0] : undefined);
      setEndTime(range ? range[1] : undefined);
    };
    const iid = setInterval(update, 500);
    player.addEventListener('view-change', update, { signal });
    player.addEventListener('load', update, { signal });
    return () => {
      aborter.abort();
      clearInterval(iid);
    };
  }, [player]);

  useEffect(() => {
    if (!startTime || !endTime) return;
    const aborter = new AbortController();
    const request = api.ac.v5.events.filter.$get({
      signal: aborter.signal,
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
        pageSize: 100,
        endTimestamp: endTime,
        startTimestamp: startTime,
        ...(cameraId ? { endpointId: cameraId } : {}),
      },
    });
    setLoading(true);
    request
      .process()
      .then((r) => r.events)
      .then(setEvents)
      .catch(logger.error)
      .finally(() => setLoading(false));
    return () => aborter.abort();
  }, [secretToken, tenantId, cameraId, startTime, endTime]);

  useEffect(() => {
    if (loading || events?.length) {
      player.setAttribute('show-sidebar', 'on');
    } else {
      player.removeAttribute('show-sidebar');
      setSidebar(null);
    }
  }, [player, loading, events]);

  if (!sidebar) return null;

  if (loading) {
    return createPortal(
      <EventListPlaceholder width={itemWidth || '100%'} itemsPerRow={itemsPerRow} />,
      sidebar
    );
  }

  return createPortal(
    <>
      {events.map((event) => (
        <EventGridListItem key={event.id} item={event} width={itemWidth || '100%'} />
      ))}
    </>,
    sidebar
  );
}
