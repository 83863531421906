import { DELETE_ICON } from '@/assets/constants/images';
import { WIFI_PASSWORD_PATTERN, WIFI_SSID_PATTERN } from '@/assets/regex';
import { BoxImage } from '@/web/@components/BoxImage';
import { CustomInput, CustomPasswordInput } from '@/web/@components/CustomForm';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {object} WifiConnectivityProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {WifiConnectivityProps} props */
export function WifiConnectivity(props) {
  const { item } = props;
  const { key: name, label } = item;

  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, remove, prepend } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb="6px">
        <Typography variant="body1" fontWeight={500}>
          {label}
        </Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<AddOutlined />}
          onClick={() => prepend({ ssid: '', password: '' })}
        >
          {t('group.settings.connectivity.add')}
        </Button>
      </Box>
      <Divider />
      {fields?.length ? (
        <Box
          display="grid"
          gridTemplateColumns={{ md: '1fr 1fr .10fr', sm: '1fr' }}
          gap="10px"
          px="10px"
          pb="20px"
          pt={1}
        >
          {fields?.map((item, index) => (
            <Fragment key={index}>
              <CustomInput
                label={t('group.settings.connectivity.ssid.label')}
                placeholder={t('group.settings.connectivity.ssid.placeholder')}
                name={`wifiNetworks[${index}].ssid`}
                rules={{
                  required: t('group.settings.connectivity.ssid.required'),
                  pattern: {
                    value: WIFI_SSID_PATTERN,
                    message: t('group.settings.connectivity.ssid.pattern'),
                  },
                }}
              />

              <CustomPasswordInput
                label={t('group.settings.connectivity.password.label')}
                placeholder={t('group.settings.connectivity.password.placeholder')}
                name={`wifiNetworks[${index}].password`}
                InputProps={{ autoComplete: 'new-password' }}
                rules={{
                  pattern: {
                    value: WIFI_PASSWORD_PATTERN,
                    message: t('group.settings.connectivity.password.pattern'),
                  },
                }}
              />

              <IconButton
                onClick={() => remove(index)}
                color="error"
                sx={{
                  borderRadius: '50%',
                  border: '1px solid #596A81',
                  width: '30px',
                  height: '30px',
                  lineHeight: '38px',
                  mt: '31px',
                }}
              >
                <BoxImage src={DELETE_ICON} size="20px" />
              </IconButton>
            </Fragment>
          ))}
        </Box>
      ) : (
        <Typography variant="body2" textAlign="center" pt="10px">
          No Wi-Fi Network
        </Typography>
      )}
    </>
  );
}
