import api from '@/api';
import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { VIN_REGEX } from '@/assets/regex';
import { selectSecretToken } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { CustomInput } from '@/web/@components/CustomForm';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

/** @param {import('@mui/material').IconButtonProps} props */
export function EditVinModalButton(props) {
  const secretToken = useSelector(selectSecretToken);
  const { camera, refreshCamera } = useContext(CameraDetailsContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      vin: camera?.vin || '',
    },
  });

  useEffect(() => {
    form.setValue('vin', camera?.vin || '');
  }, [form, camera?.vin]);

  const submitVin = async ({ vin }) => {
    setLoading(true);
    const request = api.ac.v5.device.$deviceId(camera.deviceSerialNumber).vin.$post({
      data: {
        vin: trimString(vin),
      },
      headers: {
        Authorization: `Bearer ${secretToken}`,
      },
    });
    try {
      await request.process();
      toastSuccess(null, vin ? 'Changed VIN' : 'Removed VIN');
      setLoading(false);
      setShow(false);
      refreshCamera();
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastWarning('Failed to change VIN');
    }
  };

  return (
    <>
      <IconButton onClick={() => setShow(true)} {...props}>
        <BoxImage src={BOX_MODIFY_ICON} size="16px" />
      </IconButton>
      <Dialog open={show} onClose={() => setShow(false)} fullWidth maxWidth="xs">
        <DialogTitle>Change VIN</DialogTitle>
        <Divider />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitVin)} autoComplete="off" noValidate>
            <DialogContent>
              <CustomInput
                name="vin"
                label="VIN"
                placeholder="Enter VIN"
                rules={{
                  required: 'VIN is required',
                  pattern: {
                    value: VIN_REGEX,
                    message: 'Invalid VIN',
                  },
                }}
              />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button type="reset" onClick={() => setShow(false)}>
                Cancel
              </Button>
              <Button type="submit" autoFocus variant="contained" disabled={loading}>
                {loading && <CircularProgress color="inherit" size={16} />}
                <span>Apply</span>
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
}
