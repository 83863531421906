import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { CAMERA_VIDEO_IMAGER_INPUT_TABLE_COLUMNS } from './columns';

/**
 * @typedef {object} CameraVideoImagerInputsProps
 * @property {Array} videoInputs
 */

/**
 * @param {CameraVideoImagerInputsProps} props
 */
export function CameraVideoImagerInputs(props) {
  const { videoInputs } = props;

  if (!videoInputs?.length) {
    return (
      <CenterBox>
        <IconMessageBox src={NO_SEARCH_RESULT_IMG} size="256px" message="No items" />
      </CenterBox>
    );
  }

  return (
    <ItemsResponsiveView
      columns={CAMERA_VIDEO_IMAGER_INPUT_TABLE_COLUMNS}
      results={videoInputs}
      sx={{ height: '100%' }}
    />
  );
}
