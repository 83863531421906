import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectMainUserId, selectSecretToken } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CoachingFilterContext } from '../../@context/CoachingFilterContext';
import { EventGridListItem } from '@/web/events/@components/EventGridListItem';

export function CoachableEventList() {
  const navigate = useNavigate();

  const { query } = useRouteQuery();
  const { endpointId, deviceLabel } = query;
  const { setBreadcrumbTitle } = useContext(MainContext);

  const secretToken = useSelector(selectSecretToken);
  const authUserId = useSelector(selectMainUserId);

  const { startTime, endTime } = useContext(FilterContext);
  const { triggerName } = useContext(CoachingFilterContext);

  /** @type {StateVariable<number>} */
  const [hoverItem, setHoverItem] = useState(null);
  const [coachableEvents, setCoachableEvents] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [removedItems, setRemovedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasActiveSession, setHasActiveSession] = useState(false);

  const { result: events, loading: eventLoading } = useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      if (!endpointId) return [];
      let lastMaxTimestamp = null;
      const limit = 100;
      /** @type {Array<EventV5ResponseModel>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.events.filter.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            tenantId,
            pageSize: 100,
            lastMaxTimestamp: lastMaxTimestamp,
            coachingStatus: 'COACHING_CANDIDATE',
            endpointId: Number(endpointId),
            startTimestamp: startOfTheDay(startTime),
            endTimestamp: endOfTheDay(endTime),
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result.events;
        results.push(...result);
        if (result.length < limit) break;
        lastMaxTimestamp = request.result?.maxTimestamp;
      }
      setCoachableEvents(results);
      return results;
    },
    [endpointId, startTime, endTime]
  );

  const showButton = (e, item) => {
    e.preventDefault();
    setHoverItem(item);
  };

  const hideButton = (e) => {
    e.preventDefault();
    setHoverItem(null);
  };

  const handleRemoveEvent = async (event) => {
    try {
      const request = api.ac.v5.events.$eventId(event?.id).coaching.$patch({
        headers: {
          Authorization: secretToken,
        },
        params: {
          coachingStatus: 'NONE',
        },
      });
      await request
        ?.process()
        ?.then(() => {
          const newItems = [...removedItems, event.id];
          const filteredArray = events?.filter((item) => !newItems?.includes(item?.id));
          setFilteredData(filteredArray);
          setCoachableEvents(filteredArray);
          setRemovedItems((prev) => [...prev, event.id]);
        })
        .catch((err) => console.error(err));
    } catch (ex) {
      toastWarning('Error', 'Failed to remove from coachable events');
    }
  };

  const handleSaveCoachableEvents = () => {
    setLoading(true);
    const request = api.ac.v5.coaching.sessions
      .$coachingByTarget('ENDPOINT')
      // @ts-ignore
      .$targetId(endpointId)
      .$post({
        headers: {
          Authorization: secretToken,
        },
        params: {
          coachUserId: authUserId,
          coachingSessionType: 'GUIDE',
          notes: '',
          startTime: new Date().getTime(),
        },
      });
    request
      ?.process()
      ?.then((data) => {
        toastSuccess('Success', 'Guided coaching initiated successfully');
        setTimeout(() => {
          setLoading(false);
          navigate(`/safety/coaching/${endpointId}/guided`);
        }, 1500);
      })
      .catch((error) => {
        setLoading(false);
        toastWarning(
          'Error',
          error?.response?.data?.message || 'Failed to initiate guided coaching!'
        );
      });
  };

  const filterData = (dataArray, triggerName) => {
    return dataArray?.filter((item) => {
      if (triggerName) {
        return item?.triggerName?.toLowerCase().includes(triggerName.toLowerCase());
      }
      return true;
    });
  };

  useEffect(() => {
    const fetchSessions = async () => {
      let offset = 0;
      let allSessions = [];
      let hasMore = true;

      while (hasMore) {
        try {
          const result = await api.ac.v5.coaching.sessions
            .$get({
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit: 50,
                offset: offset,
                targetId: endpointId?.toString(),
                fromTimestamp: 0,
                coachingSessionType: 'GUIDE',
                coachingByTarget: 'ENDPOINT',
                toTimestamp: Date.now(),
              },
            })
            .process();

          const sessions = result?.coachingSessions || [];
          allSessions = [...allSessions, ...sessions];

          if (sessions.length < 50) {
            hasMore = false;
          } else {
            offset += 50;
          }
        } catch (error) {
          console.error(error);
          hasMore = false;
        }
      }

      return allSessions;
    };

    const fetchAllSessions = async () => {
      const guidedSessions = await fetchSessions();
      const activeGuidedSessions = guidedSessions.filter((item) =>
        ['CREATED', 'STARTED'].includes(item?.coachingSessionStatus)
      );

      if (activeGuidedSessions.length > 0) {
        setHasActiveSession(true);
      }
    };

    if (secretToken && endpointId) {
      fetchAllSessions();
    }
  }, [secretToken, endpointId]);

  useEffect(() => {
    if (!query?.deviceLabel) return;
    setBreadcrumbTitle(query?.deviceLabel?.toString());
  }, [query?.deviceLabel, setBreadcrumbTitle]);

  useEffect(() => {
    const filteredEventsVyTrigger = filterData(coachableEvents, triggerName);
    setFilteredData(filteredEventsVyTrigger);
  }, [coachableEvents, triggerName]);

  return (
    <Box px={2.5} mb={10}>
      {eventLoading ? (
        <CenterBox style={{ height: '280px' }}>
          <CircularProgress />
        </CenterBox>
      ) : hasActiveSession ? (
        <CenterBox fullView>
          <IconMessageBox
            size="256px"
            src="/images/player/no-data-icon.svg"
            message="An active session is currently in progress on this endpoint!"
          />
        </CenterBox>
      ) : !eventLoading && !filteredData?.length ? (
        <CenterBox fullView>
          <IconMessageBox
            size="256px"
            src={NO_SEARCH_RESULT_IMG}
            message="No coachable events were found based on the provided filters!"
          />
        </CenterBox>
      ) : (
        <>
          <Typography pb={3}>
            <span style={{ fontWeight: 500 }}>{filteredData?.length}</span> Events
          </Typography>
          <Typography variant="subtitle2" pb={2}>
            Remove the events that you don't want to coach.
          </Typography>

          <Grid container spacing={2}>
            {(filteredData || [])?.map((event, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <Box
                  display="grid"
                  position="relative"
                  gap="5px"
                  onMouseEnter={(e) => showButton(e, index)}
                  onMouseLeave={hideButton}
                >
                  <EventGridListItem item={event} />

                  <Box
                    display={hoverItem === index ? 'grid' : 'none'}
                    gap={1}
                    sx={{
                      'position': 'absolute',
                      'top': '5px',
                      'right': '5px',
                      'zIndex': 100,
                      '& .MuiIconButton-root': {
                        border: '1px solid white',
                        background: 'white',
                        bgcolor: 'white',
                        height: '30px',
                        width: '30px',
                      },
                    }}
                  >
                    <Tooltip title="Remove the coachable event" arrow placement="left" followCursor>
                      <IconButton
                        size="small"
                        aria-haspopup="true"
                        onClick={() => handleRemoveEvent(event)}
                      >
                        <Close style={{ fontSize: '18px' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box display="flex" justifyContent="flex-end" py={3} px={2.5}>
            <Button
              size="small"
              disabled={loading}
              color="primary"
              onClick={() =>
                navigate(`/safety/coaching${deviceLabel ? `?camera=${deviceLabel}` : ''}`)
              }
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleSaveCoachableEvents()}
              startIcon={loading && <CircularProgress sx={{ color: 'white' }} size={16} />}
            >
              Initiate Session
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
