import { Link } from '@mui/material';
import { useContext, useState } from 'react';
import { CameraNetworkChangeModal } from '../CameraNetworkChangeModal';
import { DeviceUsagesContext } from '../../../../@contexts/DeviceUsagesContext';
import { CameraPubSubNetworkChangeModal } from '../CameraPubSubNetworkChangeModal';

/**
 * @typedef {object} CameraNetworkChangeDetailsModalProps
 * @property {CameraTypeRecord} [row]
 */

/** @param {CameraNetworkChangeDetailsModalProps} props */
export function CameraNetworkChangeDetails(props) {
  const { row } = props;
  const { isNewDataSource } = useContext(DeviceUsagesContext);

  /** @type {StateVariable<boolean>} */
  const [detailsDialog, setDetailsDialog] = useState(false);

  return (
    <>
      {detailsDialog &&
        (isNewDataSource ? (
          <CameraPubSubNetworkChangeModal onCancel={() => setDetailsDialog(false)} row={row} />
        ) : (
          <CameraNetworkChangeModal onCancel={() => setDetailsDialog(false)} row={row} />
        ))}
      <Link onClick={() => setDetailsDialog(true)}>Details</Link>
    </>
  );
}
