import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { Box, Button, CircularProgress } from '@mui/material';
import { capitalize, startCase } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import { SENSITIVITY_MEDIA_MAPPING } from '../../assets';
import { ChimeSettingsForm } from '../ChimeSettingsForm';
import { CoolDownSettingsForm } from '../CoolDownSettingsForm';
import { MediaSettingsForm } from '../MediaSettingsForm';
import { PostBufferSettingsForm } from '../PostBufferSettingsForm';
import { PreBufferSettingsForm } from '../PreBufferSettingsForm';
import { RecordingUploadSettingsForm } from '../RecordingUploadSettingsForm';
import { SensitivitySettingsForm } from '../SensitivitySettingsForm';
import { TriggerDescription } from '../TriggerDescription';
import { TriggerPropertyInheritInfo } from '../TriggerPropertyInheritInfo';
import { TriggerResetButton } from '../TriggerResetButton';
import { TtsSettingsForm } from '../TtsSettingsForm';

const selectStyle = {
  '& .MuiSelect-select': {
    'border': 'none',
    'fontSize': '12px !important',
    'color': '#8592a3',
    '& fieldset': { border: 'none !important' },
    '&  .Mui-focused': {
      '& fieldset': { border: 'none !important' },
    },
  },
  '& .MuiInputAdornment-root': {
    'marginRight': '-14px !important',
    '& fieldset': { border: 'none  !important' },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export function TriggerForm(props) {
  const { onCancel, triggerItem: trigger } = props;

  const secretToken = useSelector(selectSecretToken);
  const { selectionType, setTriggers, triggerCategories, variablesDefinition } = useContext(
    TriggerConfigurationContext
  );

  /** @type {StateVariable<string>} */
  const [coolDownUnit, setCoolDownUnit] = useState('SECONDS');
  /** @type {StateVariable<boolean>} */
  const [isEnableCoolDown, setIsEnableCoolDown] = useState(false);
  /** @type {StateVariable<string>} */
  const [ttsLanguage, setTtsLanguage] = useState('EN');
  /** @type {StateVariable<string>} */
  const [chimeValue, setChimeValue] = useState('');
  /** @type {StateVariable<string>} */
  const [uploadTypeValue, setUploadTypeValue] = useState('');
  /** @type {StateVariable<array>} */
  const [mediaTypeValues, setMediaTypeValues] = useState([]);
  /** @type {StateVariable<string>} */
  const [actionType, setActionType] = useState('');
  /** @type {StateVariable<array>} */
  const [overridesValues, setOverridesValues] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues: {
      cooldownTimer: 0,
      tts: '',
      preBuffer: 0,
      postBuffer: 0,
      sensitivity: 0,
    },
  });

  const { setValue } = form;

  const triggerVariableOverrideUpdate = async (newOverridesValues, isReset = false) => {
    try {
      if (!selectionType || !newOverridesValues || newOverridesValues.length === 0) {
        return;
      }

      const requests = newOverridesValues
        ?.filter((x) =>
          variablesDefinition?.find((y) => x.variableName === y.name && y.customerFacing)
        )
        ?.map(async (item) => {
          const defaultVal = variablesDefinition?.find(
            (va) => va?.name === item?.variableName
          )?.defaultValue;

          const request = api.ac.v5.trigger.variable.override
            .$variableName(item?.variableName)
            .$put({
              headers: {
                Authorization: secretToken,
              },
              data: {
                value: isReset ? defaultVal : item?.value,
                targetID: selectionType.value,
                targetLevel: selectionType.type,
              },
            });
          await request.process();
        });
      await Promise.all(requests);
    } catch (ex) {
      toastWarning('Failed to override variable config');
    }
  };

  /**
   * Get the actual media types while submitting.
   * @returns {'NONE'|'SNAPSHOT'|'VIDEO'|'SNAPSHOT_AND_VIDEO'} The actual media type.
   */
  const getActualMediaTypesWhileSubmit = () => {
    if (mediaTypeValues?.includes('VIDEO') && mediaTypeValues?.includes('SNAPSHOT'))
      return 'SNAPSHOT_AND_VIDEO';
    else if (mediaTypeValues?.includes('VIDEO')) return 'VIDEO';
    else if (mediaTypeValues?.includes('SNAPSHOT')) return 'SNAPSHOT';
    else return 'NONE';
  };

  // Helper function to check if a property has been updated
  const hasChanged = (oldValue, newValue) => oldValue !== newValue;

  // Build the object for reset case
  const buildResetValue = (data, selectionType) => ({
    chime: data?.chime?.value,
    mediaType: data?.mediaType?.value,
    postBuffer: data?.postBuffer?.value,
    preBuffer: data?.preBuffer?.value,
    targetId: selectionType?.value,
    targetLevel: selectionType?.type,
    cooldown: data?.cooldown?.value,
    cooldownTimer: data?.cooldownTimer?.value,
    uploadStrategy: data?.uploadStrategy?.value,
    ttsText: data?.tts,
    enableTts: data?.enableTts?.value,
  });

  // Build the object for update case
  const buildUpdatedValue = (
    data,
    selectionType,
    mediaType,
    isEnableCoolDown,
    uploadTypeValue,
    ttsLanguage
  ) => ({
    chime: chimeValue,
    mediaType,
    postBuffer: data.postBuffer,
    preBuffer: data.preBuffer,
    targetId: selectionType?.value,
    targetLevel: selectionType?.type,
    cooldown: isEnableCoolDown,
    cooldownTimer: data.cooldownTimer * 1000,
    uploadStrategy: uploadTypeValue,
    ttsText: data?.tts,
    enableTts: ttsLanguage !== 'TURN_OFF',
  });

  // Create a new value object with only the updated properties
  const buildChangedValue = (
    data,
    trigger,
    mediaType,
    isEnableCoolDown,
    uploadTypeValue,
    ttsLanguage
  ) => ({
    ...(hasChanged(trigger?.chime?.value, chimeValue) && { chime: chimeValue }),
    ...(hasChanged(trigger?.mediaType?.value, mediaType) && { mediaType }),
    ...(hasChanged(trigger?.preBuffer?.value, data.preBuffer) && { preBuffer: data.preBuffer }),
    ...(hasChanged(trigger?.postBuffer?.value, data.postBuffer) && { postBuffer: data.postBuffer }),
    ...(hasChanged(trigger?.cooldown?.value, isEnableCoolDown) && { cooldown: isEnableCoolDown }),
    ...(hasChanged(trigger?.cooldownTimer?.value, data.cooldownTimer * 1000) && {
      cooldownTimer: data.cooldownTimer * 1000,
    }),
    ...(hasChanged(trigger?.uploadStrategy?.value, uploadTypeValue) && {
      uploadStrategy: uploadTypeValue,
    }),
    ...(hasChanged(trigger?.ttsText?.value, data?.tts) && { ttsText: data?.tts }),
    ...(hasChanged(trigger?.enableTts?.value, ttsLanguage !== 'TURN_OFF') && {
      enableTts: ttsLanguage !== 'TURN_OFF',
    }),
    targetId: selectionType?.value,
    targetLevel: selectionType?.type,
  });

  const triggerSettingsOverride = async (data, isReset = false) => {
    if (!selectionType || !data) return;

    let newValues;
    let updatedValues;

    if (isReset) {
      newValues = buildResetValue(data, selectionType);
    } else {
      const mediaType = getActualMediaTypesWhileSubmit();
      updatedValues = buildUpdatedValue(
        data,
        selectionType,
        mediaType,
        isEnableCoolDown,
        uploadTypeValue,
        ttsLanguage
      );
      newValues = buildChangedValue(
        data,
        trigger,
        mediaType,
        isEnableCoolDown,
        uploadTypeValue,
        ttsLanguage
      );
    }

    if (Object.keys(newValues).length <= 2) {
      return isReset ? newValues : updatedValues;
    }

    try {
      const request = api.ac.v5.trigger.composite
        .$triggerCategoryId(trigger.triggerCategoryId)
        .config.$put({
          headers: {
            Authorization: secretToken,
          },
          data: newValues,
        });

      await request?.process();
      return isReset ? newValues : updatedValues;
    } catch (error) {
      toastWarning('Failed to update trigger configuration');
    }
  };

  const triggerVariableOverride = (variableName, value, unFormattedValue) => {
    setOverridesValues((prevArray) =>
      prevArray.some((item) => item.variableName === variableName)
        ? prevArray.map((item) =>
            item.variableName === variableName
              ? {
                  ...item,
                  value,
                  unFormattedValue,
                }
              : item
          )
        : [...prevArray, { variableName, value, unFormattedValue }]
    );
  };

  const handleApply = async (data) => {
    try {
      setLoading(true);
      setActionType('APPLY');
      await triggerVariableOverrideUpdate(overridesValues);
      const changesItems = await triggerSettingsOverride(data);
      if (Object.keys(changesItems)?.length) {
        updateTriggerItemByIdAcrossKeys(trigger?.id, changesItems, false);
      }
      onCancel();
    } catch (ex) {
    } finally {
      setActionType('');
      setLoading(false);
    }
  };

  const handleReset = async () => {
    try {
      setLoading(true);
      setActionType('RESET');
      const triggerDefaultVariables = trigger?.defaultValues?.body?.variableOverrides?.variables;
      await triggerVariableOverrideUpdate(triggerDefaultVariables, true);
      await triggerSettingsOverride(trigger?.defaultValues, true);
      onCancel();
    } catch (ex) {
    } finally {
      setActionType('');
      setLoading(false);
    }
  };

  /**
   * Update local data
   * Updates a trigger item across keys by its ID with specified changes.
   * @param {string} id - The ID of the item to update.
   * @param {object} changes - The changes to apply to the item.
   * @param {boolean} [isReset] - The changes to apply to the item.
   */
  const updateTriggerItemByIdAcrossKeys = (id, changes, isReset = false) => {
    setTriggers((prevTriggers) => {
      return prevTriggers.map((item) => {
        if (item.id === id) {
          const newValues = {
            cooldown: createNewValue(changes?.cooldown, item?.cooldown),
            preBuffer: createNewValue(changes.preBuffer, item?.preBuffer),
            postBuffer: createNewValue(changes.postBuffer, item?.postBuffer),
            cooldownTimer: createNewValue(changes.cooldownTimer, item?.cooldownTimer),
            mediaType: createNewValue(changes.mediaType, item?.mediaType),
            chime: createNewValue(changes.chime, item?.chime),
            uploadStrategy: createNewValue(changes.uploadStrategy, item?.uploadStrategy),
            enableTts: createNewValue(changes.enableTts, item?.enableTts),
            ttsText: createNewValue(changes.ttsText, item?.ttsText),
            variables: getUpdatedVariables(item, overridesValues, isReset),
          };
          return { ...trigger, ...newValues };
        }
        return item;
      });
    });
  };

  /**
   * Creates a new value object based on the changes and existing value.
   * @param {string} newValue - The new value.
   * @param {object} item - The existing value object.
   * @returns {object} - The new value object.
   */
  function createNewValue(newValue, item) {
    const existingValue = item?.value;
    const prevTargetId = item?.targetId;
    const prevTargetLevel = item?.targetLevel;
    const prevTargetName = item?.targetName;
    return {
      value: newValue,
      targetLevel: newValue === existingValue ? prevTargetId : selectionType?.type,
      targetId: newValue === existingValue ? prevTargetLevel : selectionType?.value,
      targetName: newValue === existingValue ? prevTargetName : null,
    };
  }

  /**
   * Updates variables in the trigger item.
   * @param {object} item - The trigger item.
   * @param {object[]} overridesValues - The array of override values.
   * @param {boolean} [isReset] - The updated variables array.
   * @returns {object[]} - The updated variables array.
   */
  function getUpdatedVariables(item, overridesValues, isReset = false) {
    return (
      item?.variables?.map((variable) => {
        const override = overridesValues?.find((ov) => ov.variableName === variable?.key);
        return {
          ...variable,
          value: isReset
            ? override?.default
            : override?.unFormattedValue || override?.value || variable?.value,
        };
      }) || []
    );
  }

  useEffect(() => {
    const mediaType = SENSITIVITY_MEDIA_MAPPING[trigger?.mediaType?.value];
    setMediaTypeValues(mediaType);
    setChimeValue(trigger?.chime?.value);
    setUploadTypeValue(trigger?.uploadStrategy?.value || 'SD_CARD');

    setIsEnableCoolDown(trigger?.cooldown?.value);
    if (!trigger?.cooldown?.value) {
      setCoolDownUnit('TURN_OFF');
    }

    setValue('cooldownTimer', trigger?.cooldownTimer?.value / 1000); //milli to sec
    setValue('preBuffer', trigger?.preBuffer?.value);
    setValue('postBuffer', trigger?.postBuffer?.value);
    setValue('tts', trigger?.ttsText.value || trigger?.tts);

    if (!trigger?.enableTts?.value) {
      setTtsLanguage('TURN_OFF');
    }
  }, [trigger, setValue]);

  const inheritance = (type) => {
    const typeData =
      type === 'sensitivity'
        ? { targetLevel: trigger?.variables?.[0]?.targetLevel }
        : type === 'tts'
          ? { targetLevel: 'TRIGGER' }
          : trigger?.[type] || {};

    const targetName =
      typeData?.targetLevel === 'TRIGGER' || typeData?.targetLevel === 'DEFAULT'
        ? trigger?.name
        : typeData?.targetLevel === 'TRIGGER_CATEGORY'
          ? triggerCategories?.find((category) => category.id === trigger?.triggerCategoryId)
              ?.name || typeData?.targetName
          : capitalize(startCase(typeData?.targetName)) || trigger?.name;

    return <TriggerPropertyInheritInfo targetName={targetName} />;
  };

  return (
    <Box sx={{ ...selectStyle }}>
      <Box pb={3}>
        <TriggerDescription description={trigger?.triggerCategoryDescription} />
      </Box>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleApply)} autoComplete="off" noValidate>
          <Box
            display="flex"
            flexDirection="column"
            gap={1.5}
            sx={{
              '& .MuiMenuItem-root': {
                fontSize: '12px !important',
              },
              '& .MuiFormHelperText-root': {
                marginLeft: '0px !important',
                opacity: '0.7',
                fontWeight: 400,
              },
            }}
          >
            {/* Chime Settings */}
            <ChimeSettingsForm
              chimeValue={chimeValue}
              setChimeValue={setChimeValue}
              inheritance={inheritance}
            />

            {/* Media Settings */}
            <MediaSettingsForm
              mediaTypeValues={mediaTypeValues}
              setMediaTypeValues={setMediaTypeValues}
              inheritance={inheritance}
            />

            {/* Recordings Upload Type Settings */}
            <RecordingUploadSettingsForm
              uploadTypeValue={uploadTypeValue}
              setUploadTypeValue={setUploadTypeValue}
              inheritance={inheritance}
            />

            {/* Pre Buffer Settings */}
            <PreBufferSettingsForm
              defaultValue={trigger?.defaultValues?.preBuffer?.value}
              inheritance={inheritance}
            />

            {/* Post Buffer Settings */}
            <PostBufferSettingsForm
              defaultValue={trigger?.defaultValues?.postBuffer?.value}
              inheritance={inheritance}
            />

            {/* CoolDown Settings */}
            <CoolDownSettingsForm
              coolDownUnit={coolDownUnit}
              setIsEnableCoolDown={setIsEnableCoolDown}
              setCoolDownUnit={setCoolDownUnit}
              inheritance={inheritance}
              defaultValue={trigger?.defaultValues?.cooldownTimer?.value / 1000}
            />

            {/* TTS Settings */}
            <TtsSettingsForm
              ttsLanguage={ttsLanguage}
              setTtsLanguage={setTtsLanguage}
              trigger={trigger}
              inheritance={inheritance}
            />

            {/* Sensitivity Settings */}
            <SensitivitySettingsForm
              trigger={trigger}
              overridesValues={overridesValues}
              triggerVariableOverride={triggerVariableOverride}
              inheritance={inheritance}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" pt={5} gap={0.5} pb={10} pr={4}>
            <Button onClick={onCancel} size="small" disabled={loading}>
              Cancel
            </Button>

            <TriggerResetButton loading={loading} actionType={actionType} onReset={handleReset} />

            <Button
              autoFocus
              variant="contained"
              size="small"
              type="submit"
              disabled={loading}
              startIcon={
                loading &&
                actionType === 'APPLY' && <CircularProgress size={20} sx={{ color: 'white' }} />
              }
            >
              Apply
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
