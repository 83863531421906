import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiContainerBasicForm } from '../@components/AiContainerBasicForm';

export function AiContainerCreatePage() {
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/AiContainerBasicForm').AiContainerBasicFormProps['onSubmit']} */
  const handleSubmit = async (form) => {
    if (!form) {
      navigate('/administration/ai/ai-containers');
      return;
    }
    try {
      setLoading(true);
      const request = api.ac.v2.repository.aicontainer.$post({
        data: {
          name: form.name,
          productId: form.product.productId,
          description: '',
          type: 'DEFAULT',
          properties: {},
          ownerTenantId: tenantId,
          tenantList: [{ tenantRole: 'Owner', tenantId }],
          versionNumber: form.versionNumber,
        },
        params: {
          secretToken,
        },
      });
      await request.process();
      const { containerId } = JSON.parse(request.result.data);
      toastSuccess('Success', 'Created AI Container');
      setLoading(false);
      navigate(`../${containerId}/edit`);
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastWarning('Failed to create AI Container');
    }
  };

  return (
    <Box p={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'} mb={1.5}>
        Create AI Container
      </Typography>
      <AiContainerBasicForm onSubmit={handleSubmit} disabled={loading} />
    </Box>
  );
}
