import { Box } from '@mui/material';
import { groupBy } from 'lodash';
import { useContext } from 'react';
import { AiContainerDetailsContext } from '../AiContainerDetailsLayout';
import { ComponentNode } from './ComponentNode';
import { ComponentNodeTreeItem } from './ComponentNodeTreeItem';

/**
 * @typedef {object} ComponentNodeTreeProps
 * @property {string} [adding]
 * @property {string} [selected]
 * @property {(value: string, add: boolean) => any} [onSelect]
 */

/** @param {ComponentNodeTreeProps} props */
export function ComponentNodeTree(props) {
  const { adding, selected, onSelect } = props;
  const { product, models } = useContext(AiContainerDetailsContext);
  return (
    <Box>
      <ComponentNode
        icon="product"
        adding={adding === ''}
        label={product.productName}
        onSelect={onSelect ? () => onSelect(null, true) : null}
      />
      <ComponentNodeTreeItem
        adding={adding}
        selected={selected}
        onSelect={onSelect}
        modelGroup={groupBy(models || [], (x) => x.inputModelId || '')}
      />
    </Box>
  );
}
