import api from '@/api';
import { T } from '@/assets/locales';
import { PASSWORD_VALIDATION_REGEX } from '@/assets/regex';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { store } from '@/store';
import { doLogout } from '@/store/auth';
import { readErrorMessage as errorSting } from '@/utils/errors';
import { reportEvent, SentryEvents } from '@/utils/sentry';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CustomPasswordInput } from '@/web/@components/CustomForm/CustomPasswordInput';
import { Box, Button } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackButtonContainer } from '../@components/BackButtonContainer';

export function ChangePasswordPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { query } = useRouteQuery();
  const email = useMemo(() => (query.email ?? '') + '', [query.email]);
  const resetCode = useMemo(() => (query.code ?? '') + '', [query.code]);

  useEffect(() => {
    if (!email || !resetCode) {
      navigate('..');
    }
  }, [email, resetCode, navigate]);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  /** @param {{password: string}} data */
  const onValidSubmit = async (data) => {
    const request = api.ac.v5.auth.account['reset-password'].$post({
      data: {
        email,
        resetCode,
        password: data.password,
      },
    });
    try {
      await request.process();
      store.dispatch(doLogout());
      navigate('/');
      toastSuccess('Password reset successfully');
    } catch (err) {
      console.error(err);
      toastWarning(errorSting(err, 'Failed to reset password'));
      reportEvent(SentryEvents.RESET_PASSWORD_FAILED, '', {
        err,
        email,
        resetCode,
        tags: { email },
      });
    }
  };

  const handleBackButton = () => {
    navigate('/');
  };

  return (
    <BackButtonContainer onClick={handleBackButton}>
      <Box fontWeight={600} fontSize="1.375rem" lineHeight="40px" mb={2}>
        Create new password
      </Box>
      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={form.handleSubmit(onValidSubmit)}
          sx={{
            '& label': {
              fontSize: '1rem',
              color: '#5a6c83',
              lineHeight: '28px',
              fontWeight: 500,
              mb: 0,
            },
          }}
        >
          <CustomPasswordInput
            name="password"
            label={t(T['change.password.new.password.label'])}
            placeholder={t(T['change.password.new.password.placeholder'])}
            InputProps={{ autoComplete: 'new-password' }}
            rules={{
              required: t(T['change.password.new.password.required']),
              minLength: {
                value: 8,
                message: t(T['change.password.rules.min.length']),
              },
              pattern: {
                value: PASSWORD_VALIDATION_REGEX,
                message: t(T['change.password.rules.regex']),
              },
            }}
          />

          <CustomPasswordInput
            name="passwordConfirm"
            label={t(T['change.password.confirm.password.label'])}
            placeholder={t(T['change.password.confirm.password.placeholder'])}
            InputProps={{ autoComplete: 'new-password' }}
            rules={{
              required: t(T['change.password.confirm.password.required']),
              validate: (value) => {
                return value && value !== form.getValues('password')
                  ? t(T['change.password.rules.password.match'])
                  : null;
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            disabled={!form.formState.isDirty || !form.formState.isValid}
          >
            Reset Password
          </Button>
        </Box>
      </FormProvider>
    </BackButtonContainer>
  );
}
