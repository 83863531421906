import { AI_MODEL_PARAMETER_DATA } from '@/utils/ai-models/additionalParam';
import { ModifySourceItemContainer } from '@/web/@components/ModifySourceItemContainer';
import { Grid } from '@mui/material';
import { AiModelAdditionalParamFormControl } from './AiModelAdditionalParamFormControl';

/** @param {{fieldIndex: number, remove: import('react-hook-form').UseFieldArrayRemove}} props */
export function AiModelAdditionalParamFormItem(props) {
  const { fieldIndex, remove } = props;
  return (
    <ModifySourceItemContainer onRemove={() => remove(fieldIndex)}>
      <Grid container spacing="10px" px="10px" mb="10px">
        {AI_MODEL_PARAMETER_DATA.map((item, index) => (
          <AiModelAdditionalParamFormControl key={index} item={item} fieldIndex={fieldIndex} />
        ))}
      </Grid>
    </ModifySourceItemContainer>
  );
}
