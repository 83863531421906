import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CameraThumbnailProvider } from '@/web/cameras/@components/CameraThumbnailProvider';
import { Box, CircularProgress, Grid } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CameraSafetyGridListItem } from '../@components/CameraSafetyGridListItem';
import { ScoringFilterContext } from '../@context/ScoringFilterContext';
import { SafetyScoreLayoutContext } from '../@layouts/SafetyScoreLayout';

export function EndpointList() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { setBreadcrumbTitle } = useContext(MainContext);
  const { filteredData, setSelectedFilterTypes, setSelectedPersonaList } =
    useContext(ScoringFilterContext);

  const { personaList, cameras } = useContext(SafetyScoreLayoutContext);

  const personaId = params.get('personaId');

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<Array<EndpointScoreWithEndpointDetailDto>>} */
  const [endpoints, setEndpoints] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);

  const fetchEndpointsByPersona = useCallback(
    (signal) => {
      if (!personaId) return;
      let offset = 0;
      const limit = 100;
      try {
        const request = api.ac.v5.score.persona
          .$personaId(Number(personaId))
          .tenant?.$tenantId(tenantId)
          .endpoint.details.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
            params: {
              endpointIds: filteredData?.cameras?.map((driver) => driver?.endpointId),
              fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
              toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
              limit,
              offset,
              sort: 'DESC',
            },
          });
        request
          .process()
          .then((data) => {
            setEndpoints(data?.list || []);
            if (data?.list) {
              setLoading(false);
            }
          })
          .catch((ex) => {
            setLoading(false);
          });
      } catch (ex) {
        console.error(ex);
        setLoading(false);
      }
    },
    [
      tenantId,
      secretToken,
      personaId,
      filteredData?.cameras,
      filteredData?.dateRange?.from,
      filteredData?.dateRange?.to,
    ]
  );

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);

    const debouncedFetchEndpoints = debounce(() => {
      fetchEndpointsByPersona(aborter?.signal);
    }, 500);

    debouncedFetchEndpoints();

    return () => {
      setLoading(true);
      aborter?.abort();
    };
  }, [fetchEndpointsByPersona]);

  useEffect(() => {
    if (!personaId) return;
    const selectedPersona = personaList?.filter((persona) => persona?.id === Number(personaId));
    setSelectedPersonaList(selectedPersona);
    setBreadcrumbTitle(selectedPersona?.at(0)?.name);
    setSelectedFilterTypes([2, 3, 4, 103]);
  }, [personaId, setSelectedPersonaList, personaList, setBreadcrumbTitle, setSelectedFilterTypes]);

  return (
    <Box mt={2} mb={10} px={2.5}>
      <Grid container spacing={2}>
        {loading ? (
          <CenterBox fullView>
            <CircularProgress />
          </CenterBox>
        ) : endpoints?.length > 0 ? (
          <CameraThumbnailProvider
            // @ts-ignore
            cameras={endpoints}
          >
            {endpoints.map((endpoint, index) => {
              const endpointDetails = cameras?.find(
                (item) => Number(item?.endpointId) === Number(endpoint?.endpointId)
              );
              return (
                <Grid item xs={12} md={6} lg={3} key={index}>
                  <CameraSafetyGridListItem endpoint={endpointDetails} score={endpoint?.score} />
                </Grid>
              );
            })}
          </CameraThumbnailProvider>
        ) : (
          <CenterBox fullView>
            <IconMessageBox
              size="150px"
              src="/images/empty/no-vehicles.svg"
              message="No Cameras!"
            />
          </CenterBox>
        )}
      </Grid>
    </Box>
  );
}
