import { FROM_TYPES } from '@/assets/schemes/constants';
import {
  VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
  audioBitrate,
  audioCodecs,
  frameRateOptions,
  recordingCodecs,
  videoResolution,
} from '@/assets/schemes/options';

export const imagerRecordingVideoSettings = (imager) => {
  const inputCode = imager?.inputCode;
  const postText = `_${inputCode}_recording_`;
  return {
    label: imager.inputName,
    key: `recordingVideoPipeline${inputCode}`,
    underline: false,
    secondaryUnderline: true,
    isDisabled: (watch) => !watch(`enableImagerState_${inputCode}`),
    children: [
      {
        label: 'Enable Recording Pipeline',
        key: `enableVideoPipeline${postText}`,
        type: FROM_TYPES.SWITCH,
        isDisabled: (watch) => {
          let otherImagerState =
            inputCode === 'vid_1'
              ? watch('enableVideoPipeline_vid_2_recording_')
              : inputCode === 'vid_2'
                ? watch('enableVideoPipeline_vid_1_recording_')
                : true;
          return !watch(`enableImagerState_${inputCode}`) || !otherImagerState;
        },
      },
      {
        label: 'Override Configuration from Deployed AI Models',
        key: `shouldTakeSettingsFromModel${postText}`,
        type: FROM_TYPES.SWITCH,
        isDisabled: (watch) => !watch(`enableVideoPipeline${postText}`),
      },
      {
        label: 'Resolution',
        key: `resolution${postText}`,
        type: FROM_TYPES.SELECT,
        options: videoResolution,
        originalOptions: VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },
      {
        label: 'Video Codec', //value has a condition
        key: `videoCodec${postText}`,
        type: FROM_TYPES.SELECT,
        options: recordingCodecs,
        isDisabled: (watch) =>
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
      },
      {
        label: 'Frame Rate', //depending on recordingFrameRate
        key: `frameRateValue${postText}`,
        type: FROM_TYPES.SELECT,
        options: frameRateOptions,
        postText: 'fps',
        isDisabled: (watch) =>
          watch('allRecordingDisabled') ||
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        isHidden: (watch) => watch('recordingFrameRate') !== 'fixed',
      },
      {
        label: 'Frame Rate (Min)',
        key: `frameRateMin${postText}`,
        type: FROM_TYPES.SELECT,
        options: frameRateOptions,
        postText: 'fps',
        isDisabled: (watch) =>
          watch('allRecordingDisabled') ||
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        isHidden: (watch) => watch('recordingFrameRate') !== 'dynamic',
      },
      {
        label: 'Frame Rate (Max)',
        key: `frameRateMax${postText}`,
        type: FROM_TYPES.SELECT,
        options: frameRateOptions,
        postText: 'fps',
        isDisabled: (watch) =>
          watch('allRecordingDisabled') ||
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        isHidden: (watch) => watch('recordingFrameRate') !== 'dynamic',
      },
      {
        label: 'Bit Rate', //depending on recordingBitrate
        key: `bitrateValue${postText}`,
        type: FROM_TYPES.INPUT,
        postText: 'kbps',
        min: 100,
        max: 100000,
        inputType: 'number',
        isDisabled: (watch) =>
          watch('allRecordingDisabled') ||
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        isHidden: (watch) => watch('recordingBitrate') !== 'fixed',
      },
      {
        label: 'Bit Rate (Min)',
        key: `bitrateMin${postText}`,
        type: FROM_TYPES.INPUT,
        postText: 'kbps',
        min: 100,
        max: 100000,
        inputType: 'number',
        isDisabled: (watch) =>
          watch('allRecordingDisabled') ||
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        isHidden: (watch) => watch('recordingBitrate') !== 'dynamic',
      },
      {
        label: 'Bit Rate (Max)',
        key: `bitrateMax${postText}`,
        type: FROM_TYPES.INPUT,
        postText: 'kbps',
        min: 100,
        max: 100000,
        inputType: 'number',
        isDisabled: (watch) =>
          watch('allRecordingDisabled') ||
          !watch(`enableVideoPipeline${postText}`) ||
          watch(`shouldTakeSettingsFromModel${postText}`),
        isHidden: (watch) => watch('recordingBitrate') !== 'dynamic',
      },
    ],
  };
};

export const imagerRecordingAudioSettings = (imager) => {
  const inputCode = imager?.inputCode;
  const postText = `_${inputCode}_recording_`;
  return {
    label: imager.inputName,
    key: 'recordingAudioPipeline' + inputCode,
    underline: false,
    isDisabled: (watch) => !watch(`enableImagerState_${inputCode}`),
    children: [
      {
        label: 'Enable Recording Pipeline',
        key: `enableAudioPipeline${postText}`,
        type: FROM_TYPES.SWITCH,
        isDisabled: (watch) => !watch(`enableImagerState_${inputCode}`),
      },
      {},
      {
        label: 'Audio Codec',
        key: `audioCodec${postText}`,
        type: FROM_TYPES.SELECT,
        options: audioCodecs,
        isDisabled: (watch) => !watch(`enableAudioPipeline${postText}`),
      },
      {
        label: 'Bit Rate',
        key: `audioBitrate${postText}`,
        type: FROM_TYPES.SELECT,
        options: audioBitrate,
        postText: 'kbps',
        isDisabled: (watch) => !watch(`enableAudioPipeline${postText}`),
      },
    ],
  };
};
