import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';
import { driverSides, loginPromptFreq, loginPromptMaxDuration } from '../options';

/** @type {Array<GroupSettingsItems>} */
export const driverSettings = [
  {
    title: 'Driver',
    key: GROUP_SETTINGS_CATEGORIES.DRIVER,
    children: [
      {
        label: 'Mandatory Driver Login',
        key: 'isMandatoryDriverLogin',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Login Prompt Frequency',
        key: 'loginPromptFrequency',
        type: FROM_TYPES.SELECT,
        options: loginPromptFreq,
      },
      {
        label: 'Login Prompt Maximum Duration',
        key: 'loginPromptMaxDuration',
        type: FROM_TYPES.SELECT,
        options: loginPromptMaxDuration,
      },
      {
        label: 'Driver Side',
        key: 'driverSide',
        type: FROM_TYPES.SELECT,
        options: driverSides,
      },
    ],
  },
];
