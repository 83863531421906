import { T } from '@/assets/locales';
import { ItemStatus } from '@/web/@components/ItemStatus';

/** @type {Array<TableColumn<AIModelV0>>} */
export const CAMERA_DEPLOYED_MODEL_TABLE_COLUMNS = [
  {
    id: 'modelId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    searchable: true,
    type: 'CHECKBOX',
  },
  {
    id: 'name',
    label: T['ai.models.table.columns.ai.name'],
    align: 'left',
    isPrimary: true,
    searchable: true,
  },
  {
    id: 'releaseVersion',
    label: T['ai.models.table.columns.release.version'],
    align: 'left',
    searchable: true,
    format: (value) => value,
    minWidth: '120px',
  },
  {
    id: 'version',
    label: T['ai.models.table.columns.version'],
    align: 'left',
    searchable: true,
    format: (value) => value,
  },
  {
    id: 'vendor',
    label: T['ai.models.table.columns.vendor'],
    align: 'left',
    format: (value, row) => row?.properties?.find((x) => x.key === 'vendor')?.value || 'N/A',
  },
  {
    id: 'type',
    label: T['ai.models.table.columns.type'],
    align: 'left',
    format: (value, row) => row?.properties?.find((x) => x.key === 'modelType')?.value || 'N/A',
  },
  {
    id: 'publicationStatus',
    label: T['ai.models.table.columns.publication.status'],
    align: 'left',
    searchable: true,
    format: (value) => <ItemStatus status={value} />,
  },
];
