import { Link } from '@mui/material';
import { useState } from 'react';
import { TriggerVariableDetailsModal } from '../TriggerVariableDetailsModal';

/**
 * @typedef {object} TriggerVariableNameColumnProps
 * @property {VariableDefinitionDto} [row]
 */

/** @param {TriggerVariableNameColumnProps} props */
export function TriggerVariableNameColumn(props) {
  const { row } = props;

  /** @type {StateVariable<boolean>} */
  const [detailsDialog, setDetailsDialog] = useState(false);

  return (
    <>
      {detailsDialog && (
        <TriggerVariableDetailsModal onCancel={() => setDetailsDialog(false)} variable={row} />
      )}

      <Link onClick={() => setDetailsDialog(true)}>{row?.name}</Link>
    </>
  );
}
