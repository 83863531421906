import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { VinListActions } from './@components/VinListActions';
import { VinListView } from './@components/VinListView';

export function VinGroupDetailsPage() {
  return (
    <Box mx={2.5} mt={3}>
      <PaginatedTableContextProvider>
        <VinListActions />
        <VinListView />
      </PaginatedTableContextProvider>
    </Box>
  );
}
