import localforage from 'localforage';
import { CustomLogger } from '../logger';

const logger = new CustomLogger('SmartCache');

export class DBHelper {
  /** @param {LocalForageOptions} options */
  constructor(options) {
    this.db = localforage.createInstance({
      driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
      ...options,
    });
  }

  /**
   * @param {string} [prefix]
   * @returns {Promise<Array<string>>}
   */
  async readAllKeys(prefix = '') {
    try {
      const keys = await this.db.keys();
      if (prefix) {
        return keys.filter((x) => (x + '').startsWith(prefix));
      }
      return keys;
    } catch (err) {
      logger.error('Failed to read all keys', err);
      return [];
    }
  }

  /**
   * @param {any} key
   * @returns {Promise<any>}
   */
  async readItem(key) {
    try {
      const item = await this.db.getItem(key + '');
      return item || null;
    } catch (err) {
      logger.error('Failed to read', key, err);
      return null;
    }
  }

  /**
   * @param {any} key
   * @param {any} value
   * @returns {Promise<any>}
   */
  writeItem(key, value) {
    return this.db.setItem(key + '', value);
  }

  /**
   * @param {any} key
   */
  deleteItem(key) {
    return this.db.removeItem(key + '');
  }
}
