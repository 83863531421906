import { Grid, Typography } from '@mui/material';

/**
 * @typedef {object} CameraAudioInputInfoProps
 * @property {Array} audioInputs
 */

/**
 * @param {CameraAudioInputInfoProps} props
 */
export function CameraAudioInputInfo(props) {
  const { audioInputs } = props;
  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={6} md={3}>
        <Typography variant="body1" fontSize="1rem" fontWeight="600">
          Audio
        </Typography>
        <Typography variant="body2" fontSize="0.875rem">
          {audioInputs.length > 0 ? 'Available' : 'Unavailable'}
        </Typography>
      </Grid>
    </Grid>
  );
}
