import { BOX_DISCONTINUE, TRANSFER_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<NfcCardModel>>} */
export const ACTION_ITEMS = [
  {
    label: 'Discontinue',
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    confirmationMessage: T['nfc.confirmations.discontinue'],
  },
  {
    label: 'Transfer',
    type: 'TRANSFER',
    iconImage: TRANSFER_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    useCustomDialog: true,
  },
  {
    label: 'Access Card',
    type: 'CREATE',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    useCustomDialog: true,
  },
];
