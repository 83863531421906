import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { DeviceGroupForm } from '@/web/administration/device-groups/@components/DeviceGroupForm';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function CreateDeviceGroup() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/DeviceGroupForm').DeviceGroupFormProps['onSubmitData']} */
  const createGroup = async (form) => {
    const { groupName, groupDescription, product } = form;
    try {
      setLoading(true);
      const req = api.ac.endpoint.group.create.$put({
        params: {
          tenantId,
          secretToken,
          groupResourceType: 'DEVICE',
          groupName,
          groupDescription,
          associatedType: 'PRODUCT',
          associatedTypeId: product?.productId,
        },
      });
      await req.process();
      toastSuccess('Success', t(T['device-group.create.success'], { groupName }));
      navigate('/administration/device-groups');
    } catch (err) {
      toastWarning('Error', t(T['device-group.create.retry']));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['device-group.create.page.title'])}
      </Typography>
      <DeviceGroupForm onSubmitData={createGroup} loading={loading} />
    </Box>
  );
}
