import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

/**
 * @typedef {'CREATE_SESSION_FAILED'|'GET_NEGOTIATE_TOKEN_FAILED'|'CONNECTION_CLOSED'|'SIGNALR_CONNECT_FAILURE'} HubFailureReason
 */

/**
 * @typedef {object} InitialState
 * @property {string} deviceId
 * @property {boolean} loading
 * @property {number} sessionId
 * @property {string} connectionId
 * @property {HubFailureReason} failure
 */

/** @returns {InitialState} */
export const getInitialState = () => ({
  deviceId: null,
  loading: false,
  sessionId: null,
  connectionId: null,
  failure: null,
});

export const HubConnectionSlice = createSlice({
  name: 'hub-connection',
  initialState: getInitialState(),
  reducers: {
    /** @type {SliceReducer<InitialState, void>} */
    setDeviceId(state) {
      if (!state.deviceId) {
        state.deviceId = uuidv4();
      }
    },
    /** @type {SliceReducer<InitialState, boolean>} */
    setLoading(state, action) {
      if (state.loading === action.payload) return;
      state.loading = action.payload;
      if (state.loading) {
        state.failure = null;
      }
    },
    /** @type {SliceReducer<InitialState, number>} */
    setSessionId(state, action) {
      if (state.sessionId === action.payload) return;
      state.sessionId = action.payload;
      if (action.payload) {
        state.failure = null;
      } else {
        state.failure = 'CREATE_SESSION_FAILED';
      }
    },
    /** @type {SliceReducer<InitialState, string>} */
    setConnectionId(state, action) {
      if (state.connectionId === action.payload) return;
      state.connectionId = action.payload;
      if (state.connectionId) {
        state.failure = null;
      }
    },
    /** @type {SliceReducer<InitialState, HubFailureReason>} */
    setFailureReason(state, action) {
      if (state.failure === action.payload) return;
      state.failure = action.payload;
    },
  },
});
