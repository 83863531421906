import { formatTimestamp } from '@/utils/datetime';
import { LongMessageViewer } from '@/web/@components/LongMessageViewer';
import { capitalize } from 'lodash';

/** @type {Array<TableColumn<DeviceLog>>} */
export const CAMERA_OTA_LOG_TABLE_COLUMNS = [
  {
    id: 'timestamp',
    label: 'Log Time',
    align: 'left',
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'level',
    label: 'Severity',
    align: 'left',
    searchable: true,
    format: (value) => capitalize(value),
  },
  {
    id: 'name',
    label: 'Summary',
    align: 'left',
    searchable: true,
    format: (value) => {
      return <LongMessageViewer message={value || ''} title="Summary" />;
    },
  },
  {
    id: 'details',
    label: 'Message',
    align: 'left',
    searchable: true,
    format: (value) => {
      return <LongMessageViewer message={value || ''} title="Message" />;
    },
  },
];
