import {
  selectTeamList,
  selectTenantId,
  selectUserAccessibleFeatures,
  selectUserEmail,
  selectUserRole,
} from '@/store/auth';
import { makeColorFromRanged } from '@/utils/colors';
import { filterByUserAccess } from '@/utils/user-access';
import { PageLink } from '@/web/@components/PageLink';
import { Box, Card, Icon, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BoxImage } from '../BoxImage';

/**
 * @typedef {object} PageCardProps
 * @property {number} [count]
 * @property {PageCardItem} item
 */

/** @param {PageCardProps} props */
export function PageCard(props) {
  const { item, count } = props;
  const { name, color, image, components } = item;

  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);
  const enableFeatures = useSelector(selectUserAccessibleFeatures);

  const background = useMemo(() => color || makeColorFromRanged(name, 0, 230, 250), [color, name]);

  const visibleComponents = useMemo(
    () =>
      components?.filter(
        filterByUserAccess({ userRole, userEmail, tenantId, teamList, enableFeatures })
      ),
    [components, userRole, userEmail, tenantId, teamList, enableFeatures]
  );

  return (
    <Card
      elevation={0}
      sx={{
        cursor: item.link ? 'pointer' : null,
        position: 'relative',
        background: background,
        height: '100%',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 3,
        minHeight: '190px',
      }}
    >
      <BoxImage src={image} alt="Icon" size="50px" position="absolute" top="20px" right="25px" />

      <Box onClick={item.link ? () => navigate(item.link) : null}>
        <Typography fontSize="1.875rem" fontWeight={theme.typography.fontWeightBold}>
          {count}
        </Typography>
        <Typography
          variant="body2"
          fontSize="1.25rem"
          fontWeight={theme.typography.fontWeightMedium}
        >
          {t(name)}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        {visibleComponents?.map((sub, index) => (
          <PageLink key={sub.link + index} to={sub?.link} display="flex" gap={1.5}>
            {sub.icon ? (
              <Icon component={sub.icon} sx={{ color: theme.palette.text.secondary }}></Icon>
            ) : sub?.image ? (
              <BoxImage src={sub?.image} size={20} sx={{ mt: '2.5px' }}></BoxImage>
            ) : null}
            <Typography
              color={theme.palette.text.secondary}
              fontWeight={theme.typography.fontWeightMedium}
            >
              {t(sub.name)}
            </Typography>
          </PageLink>
        ))}
      </Box>
    </Card>
  );
}
