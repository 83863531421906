/** @type {Array<TableColumn<EndpointRole & Endpoint>>} */
import { USER_APP_DEFAULT_ICON } from '@/assets/constants/images';
import AvatarWithPresence from '@/web/@components/AvatarWithPresence';
export const USER_MOBILE_DEVICE_TABLE_COLUMNS = [
  {
    id: 'imageUrl',
    label: '',
    align: 'left',
    width: '10px',
    isPrimary: true,
    format: (value, row) => (
      <AvatarWithPresence src={value || USER_APP_DEFAULT_ICON} alt="APP" isActive={row.isOnline} />
    ),
  },
  {
    id: 'label',
    label: 'Name',
    align: 'left',
    searchable: true,
    isPrimary: true,
    link: (row) => `/administration/device-groups/${row.groupId}/cameras/${row.id}`,
  },
  {
    id: 'country',
    label: 'Location',
    align: 'left',
  },
  {
    id: 'platform',
    label: 'Platform',
    align: 'left',
  },
  {
    id: 'packageVersion',
    label: 'Version',
    align: 'left',
  },
];
