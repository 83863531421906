import api from '@/api';
import { SMARTER_AI_ICON } from '@/assets/constants/images';
import { selectSecretToken } from '@/store/auth';
import { getCountryFlagUrlByCode } from '@/utils/country-flags';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomCheckbox, CustomSwitch } from '@/web/@components/CustomForm';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export function ChildTenantDataResidency() {
  const secretToken = useSelector(selectSecretToken);
  const { watch, setValue, getValues } = useFormContext();

  const [expanded, setExpanded] = useState('');
  const [loading, setLoading] = useState(false);

  const childTenantData = watch('childTenantData');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  /***********************************************************
   *                                                          *
   *   Fetch Child Tenant allowed Regions and default value   *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!expanded) return;
    setLoading(true);
    const parentResidency = getValues('region');
    const childTenantData = getValues('childTenantData');
    const request = api.ac.v5.tenant.$tenantId(expanded)['data-residency']['allowed-region'].$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then((data) => {
        const childTenant = childTenantData.find((item) => item.tenantId === expanded);
        const childResidency = childTenant.residency.map((region) => ({
          ...region,
          status: data.regionList.includes(region.code),
          default: data.defaultRegion
            ? region.code === data.defaultRegion
            : region.code === parentResidency?.code,
        }));
        const updatedData = { ...childTenant, residency: childResidency };
        Object.assign(childTenant, updatedData);
        setValue('childTenantData', childTenantData);
      })
      .catch((ex) => {})
      .finally((ex) => {
        setLoading(false);
      });
  }, [secretToken, expanded, setValue, getValues]);

  return (
    <>
      {childTenantData?.map((childTenant, index) => (
        <Accordion
          key={index}
          disableGutters
          elevation={0}
          square
          TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
          expanded={Boolean(expanded === childTenant?.tenantId)}
          onChange={handleChange(childTenant?.tenantId)}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDown fontSize="large" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                m: 0,
              },
              'borderBottom': '0.5px solid #c5d9f0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                m: 0,
              }}
            >
              <Typography sx={{ width: '100%' }}>{childTenant.tenantName}</Typography>
              <Box mr="20%">
                <CustomSwitch name={`childTenantData[${index}].dataResidencyEnabled`} label="" />
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails key={index}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" width="30%">
                Data Residency
              </Typography>
              <Typography align="right" variant="subtitle2">
                Allow/Deny
              </Typography>
              <Typography align="right" variant="subtitle2" mr="15px">
                Default
              </Typography>
            </Box>
            {loading ? (
              <CenterBox sx={{ minHeight: '200px' }}>
                <CircularProgress />
              </CenterBox>
            ) : (
              childTenant?.residency?.map((item, residencyIndex) => (
                <Box
                  key={index + residencyIndex}
                  display="flex"
                  justifyContent="space-between"
                  p={0.5}
                  borderRadius="9px"
                  bgcolor="#F2F7FC"
                  alignItems="center"
                  mb="10px"
                >
                  <IconLabelItem
                    sx={{ ml: 1, width: '30%', alignItems: 'center' }}
                    src={
                      item.code !== 'LEGACY' ? getCountryFlagUrlByCode(item.code) : SMARTER_AI_ICON
                    }
                    title={
                      <Typography variant={'body1'} fontSize="0.875rem" title={item.label}>
                        {item.label === 'Eu' ? 'European Union' : item.label}
                      </Typography>
                    }
                    iconSize={{
                      height: '20px',
                      width: '30px',
                    }}
                  />
                  <Box>
                    <CustomSwitch
                      name={`childTenantData[${index}].residency[${residencyIndex}].status`}
                      label=""
                      SwitchProps={{
                        disabled: !watch(`childTenantData[${index}].dataResidencyEnabled`),
                      }}
                    />
                  </Box>
                  <Box>
                    <CustomCheckbox
                      name={`childTenantData[${index}].residency[${residencyIndex}].default`}
                      label=""
                      CheckBoxProps={{
                        disabled:
                          !watch(`childTenantData[${index}].dataResidencyEnabled`) ||
                          !watch(`childTenantData[${index}].residency[${residencyIndex}].status`),
                      }}
                    />
                  </Box>
                </Box>
              ))
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
