import { SCHEME_PROPERTY_FIELDS } from '@/web/smarter-ai/scheme-management/schemes/assets';
import { Box, Grid, Typography } from '@mui/material';
import { startCase } from 'lodash';
import { memo } from 'react';
import { MemoizedEffectiveSchemePropertyFormControl } from './SchemePropertyFormControl';
import { LongTextShortener } from '@/web/@components/LongTextShortener';

function SchemePropertyFormItem(props) {
  const { effective, fieldIndex, triggers, settings, productInputs } = props;

  return (
    <Grid container spacing="10px" mb="10px" gap={2} alignItems="center">
      {SCHEME_PROPERTY_FIELDS.map((item, index) => (
        <MemoizedEffectiveSchemePropertyFormControl
          key={index + fieldIndex}
          item={item}
          fieldIndex={fieldIndex}
          triggers={triggers}
          settings={settings}
          productInputs={productInputs}
        />
      ))}
      <Box display="row" pt={1}>
        <Typography variant="subtitle1" fontSize="0.80rem">
          <LongTextShortener
            text={startCase(effective?.targetLevel?.toLowerCase())}
            length={50}
            sx={{ fontSize: '.80rem' }}
          />
        </Typography>
        <Typography variant="subtitle2" fontSize="0.80rem">
          <LongTextShortener text={effective?.targetName} length={50} sx={{ fontSize: '.80rem' }} />
        </Typography>
      </Box>
    </Grid>
  );
}

export const MemoizedEffectiveSchemePropertyFormItem = memo(SchemePropertyFormItem);
