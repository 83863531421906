import {
  VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
  frameRateOptions,
  streamingCodecs,
  videoResolution,
} from '../options';
import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const streamingSettings = [
  {
    title: 'Streaming',
    key: GROUP_SETTINGS_CATEGORIES.STREAMING,
    children: [
      {
        label: 'End-to-end Encryption',
        key: 'endToEndEncryption',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Adaptive Streaming Quality',
        key: 'streamingQualityProfile',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Maximum Streaming Duration',
        key: 'maximumStreamingDuration',
        type: FROM_TYPES.INPUT,
        postText: 'sec',
        min: 5,
        max: 600,
        inputType: 'number',
      },
      {
        label: 'Concurrent Streaming',
        key: 'maxConcurrentStreamingLimit',
        type: FROM_TYPES.SLIDER,
        postText: '',
        min: 0,
        max: 5,
      },
      {},
      {
        label: 'Streaming Video Pipeline',
        key: 'streamingVideoQuality',
        underline: true,
        children: [],
      },
      {
        label: 'Streaming Audio Pipeline',
        key: 'streamingAudioQuality',
        underline: true,
        children: [],
      },
      {
        label: 'Legacy Video Quality',
        key: 'video',
        underline: true,
        children: [
          {
            label: 'Resolution',
            key: 'streamingResolution',
            type: FROM_TYPES.SELECT,
            options: videoResolution,
            originalOptions: VIDEO_RECORDING_DEFAULT_RESOLUTIONS,
          },
          {
            label: 'Video Codec',
            key: 'streamingVideoCodec',
            type: FROM_TYPES.SELECT,
            options: streamingCodecs,
            isDisabled: (watch) => watch('streamingVideoCodec') === 'H264',
          },
          {
            label: 'Adaptive Frame Rate',
            key: 'isAbtOnFramerateEnabled',
            type: FROM_TYPES.SWITCH,
            isHidden: (watch) => !watch('streamingQualityProfile'),
          },
          {
            label: 'Adaptive Bitrate',
            key: 'isAbtOnBitrateEnabled',
            type: FROM_TYPES.SWITCH,
            isHidden: (watch) => !watch('streamingQualityProfile'),
          },
          {
            label: 'Frame Rate',
            key: 'streamingFrameRateValue',
            type: FROM_TYPES.SELECT,
            options: frameRateOptions,
            postText: 'fps',
            isHidden: (watch) =>
              watch('streamingQualityProfile') && watch('isAbtOnFramerateEnabled'),
          },
          {
            label: 'Frame Rate',
            key: 'streamingFrameRateGroup',
            isHidden: (watch) =>
              !watch('streamingQualityProfile') || !watch('isAbtOnFramerateEnabled'),
            children: [
              {
                label: 'Minimum Frame Rate',
                key: 'streamingFrameRateMin',
                type: FROM_TYPES.SELECT,
                options: frameRateOptions,
                postText: 'fps',
              },
              {
                label: 'Maximum Frame Rate',
                key: 'streamingFrameRateMax',
                postText: 'fps',
                type: FROM_TYPES.SELECT,
                options: frameRateOptions,
              },
            ],
          },
          {
            label: 'Bit Rate',
            key: 'streamingBitrateValue',
            type: FROM_TYPES.INPUT,
            postText: 'kbps',
            min: 100,
            max: 100000,
            inputType: 'number',
            isHidden: (watch) => watch('streamingQualityProfile') && watch('isAbtOnBitrateEnabled'),
          },
          {
            label: 'Bit Rate',
            key: 'streamingBitRateGroup',
            isHidden: (watch) =>
              !watch('streamingQualityProfile') || !watch('isAbtOnBitrateEnabled'),
            children: [
              {
                label: 'Minimum Bit Rate',
                key: 'streamingBitrateMin',
                type: FROM_TYPES.INPUT,
                postText: 'kbps',
                min: 100,
                max: 100000,
                inputType: 'number',
              },
              {
                label: 'Maximum Bit Rate',
                key: 'streamingBitrateMax',
                type: FROM_TYPES.INPUT,
                postText: 'kbps',
                min: 100,
                max: 100000,
                inputType: 'number',
              },
            ],
          },
        ],
      },
    ],
  },
];
