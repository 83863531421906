import { Box, Button, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SpeedingCustomSlider } from './SpeedingSlider';

const MAX_ALLOWED_VALUE = 20;

export const SpeedingRangeBar = ({ trigger, triggerVariableOverride }) => {
  const { setValue } = useFormContext();

  const [unit, setUnit] = useState('');
  const [sensitivity, setSensitivity] = useState(0);
  const [defaultSensitivity, setDefaultSensitivity] = useState(0);

  const sliderRef = useRef(null);

  const variableData = useMemo(() => trigger?.variables?.at(0), [trigger?.variables]);

  const convertSpeed = (speed, fromUnit) => {
    const conversionFactor = {
      kmphToMph: 0.621371,
      mphToKmph: 1.60934,
    };

    if (fromUnit === 'KMPH') {
      return (speed * conversionFactor['kmphToMph']).toFixed(2);
    } else if (fromUnit === 'MPH') {
      return (speed * conversionFactor['mphToKmph']).toFixed(2);
    } else {
      return speed;
    }
  };

  const sliderMarks = useMemo(() => {
    if (!variableData?.maxValue) return [];
    const maxValue = Math.min(variableData.maxValue, MAX_ALLOWED_VALUE);
    const increment = maxValue > 50 ? 10 : 2;
    const marks = [];
    for (let i = 0; i <= maxValue; i += increment) {
      marks.push({
        value: i,
        label: `${i} ${unit === 'KMPH' ? 'km/h' : 'mph'}`,
      });
    }
    return marks;
  }, [unit, variableData?.maxValue]);

  const updateUnit = (neUnit) => {
    if (neUnit === unit) return;
    setUnit(neUnit);
    const result = convertSpeed(sensitivity, neUnit === 'KMPH' ? 'MPH' : 'KMPH');
    setValue('sensitivity', Number(result));
    setSensitivity(result);
    setDefaultSensitivity(result);
  };

  const handleSliderChange = (event, value) => {
    value = Number(value);
    setValue('sensitivity', value);
    setSensitivity(value);
    const result = unit === 'MPH' ? convertSpeed(value, unit) : value;
    triggerVariableOverride(trigger.variables[0].key, result, value);
  };

  useEffect(() => {
    setSensitivity(variableData?.value || variableData?.default);
    setDefaultSensitivity(variableData?.value || variableData?.default);
    setValue('sensitivity', variableData?.value || variableData?.default);
    setUnit('KMPH');
  }, [variableData?.value, variableData?.default, setValue]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button
          size="small"
          variant={unit === 'KMPH' ? 'contained' : 'outlined'}
          sx={{
            borderRadius: '20px',
            height: '25px',
            backgroundColor: unit === 'KMPH' ? '#608ac3' : '',
          }}
          onClick={() => updateUnit('KMPH')}
        >
          km/h
        </Button>
        <Button
          size="small"
          variant={unit === 'MPH' ? 'contained' : 'outlined'}
          sx={{
            borderRadius: '20px',
            height: '25px',
            backgroundColor: unit === 'MPH' ? '#608ac3' : '',
          }}
          onClick={() => updateUnit('MPH')}
        >
          mph
        </Button>
      </Box>

      <Box display="flex" alignItems="center" position="relative" width="100%">
        <Box
          sx={{
            'width': '100%',
            'whiteSpace': 'nowrap',
            '&::-webkit-scrollbar': { display: 'none' },
            'msOverflowStyle': 'none',
            'scrollbarWidth': 'none',
          }}
        >
          <Box width="100%" display="inline-block">
            <Box width="100%" overflow="none">
              <Box width="100%">
                {sliderMarks?.length > 0 && (
                  <SpeedingCustomSlider
                    sliderKey={unit}
                    name="sensitivity"
                    label=""
                    step={variableData?.stepSize || 0.01}
                    min={0}
                    max={
                      unit === 'KMPH'
                        ? variableData.maxValue || MAX_ALLOWED_VALUE
                        : convertSpeed(variableData.maxValue || MAX_ALLOWED_VALUE, 'KMPH')
                    }
                    defaultVal={defaultSensitivity}
                    SliderProps={{
                      onChange: handleSliderChange,
                      disabled: !variableData,
                      marks: sliderMarks,
                      className: 'slider-thumb',
                      ref: sliderRef,
                      valueLabelDisplay: 'auto',
                    }}
                    postText={unit === 'KMPH' ? 'km/h' : 'mph'}
                    defaultSensitivityValue={variableData?.default}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography sx={{ opacity: '0.5' }}>
        Default Sensitivity {convertSpeed(variableData?.default, unit)}{' '}
        {unit === 'KMPH' ? 'km/h' : 'mph'}
      </Typography>
    </Box>
  );
};
