import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DeviceGroupDetailsContext } from '../../../@components/DeviceGroupDetailsLayout';
import { ApnDetailsContext } from '../../@components/ApnDetailsLayout';
import { ApnSettingsForm } from '../../@components/ApnSettingsForm';

export function UpdateAPNSettingsPage() {
  const navigate = useNavigate();
  const { groupId } = useContext(DeviceGroupDetailsContext);
  const { apnInfo, apnInfoId } = useContext(ApnDetailsContext);
  const secretToken = useSelector(selectSecretToken);

  const [loading, setLoading] = useState(false);

  /** @param {APNInfoForm} form */
  const updateApnSettings = async (form) => {
    try {
      setLoading(true);
      const req = api.ac.v2.endpoint.apn.create.$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          ...form,
          apnInfoId: Number(apnInfoId),
          //groupId,
        },
      });
      await req.process();
      toastSuccess('Success', 'APN Settings updated successfully');
      navigate(`/administration/device-groups/${groupId}/apns`);
    } catch (err) {
      toastWarning('Error', 'Failed to update APN Settings');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        APN Settings
      </Typography>
      <ApnSettingsForm onSubmitData={updateApnSettings} loading={loading} apnInfo={apnInfo} />
    </Box>
  );
}
