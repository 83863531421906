import {
  AI_CONTAINER_ICON,
  AI_MODEL_ICON,
  ASSIGN_CHILD_ICON,
  BOX_CREATE_ICON,
  BOX_LIST_ICON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { EDGE_TENSOR_REGEX } from '@/utils/user-access';

/** @type {Array<PageCardItem>} */
export const AI_ITEMS = [
  {
    name: T['ai.models.page.card.name'],
    image: AI_MODEL_ICON,
    color: '#EFF3F7',
    type: 'AI_MODEL',
    link: '/administration/ai/ai-models',
    accessLevel: 'HIDDEN',
    emailAccess: [EDGE_TENSOR_REGEX],
    teamAccess: ['AI_TEAM', 'FRONT_END_TEAM', 'PRODUCT_MANAGER'],
    components: [
      {
        name: T['ai.models.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/administration/ai/ai-models',
      },
      {
        name: T['ai.models.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/administration/ai/ai-models/create',
      },
    ],
  },
  {
    name: T['ai.containers.page.card.title'],
    image: AI_CONTAINER_ICON,
    color: '#F7F5EF',
    type: 'AI_CONTAINER',
    link: '/administration/ai/ai-containers',
    accessLevel: 'HIDDEN',
    emailAccess: [EDGE_TENSOR_REGEX],
    teamAccess: ['AI_TEAM', 'FRONT_END_TEAM', 'PRODUCT_MANAGER'],
    components: [
      {
        name: T['ai.containers.page.card.browse'],
        image: BOX_LIST_ICON,
        link: '/administration/ai/ai-containers',
      },
      {
        name: T['ai.containers.page.card.create'],
        image: BOX_CREATE_ICON,
        link: '/administration/ai/ai-containers/create',
      },
      {
        name: T['page.card.tenant.deployment'],
        image: ASSIGN_CHILD_ICON,
        link: '/administration/ai/ai-containers/tenant-deployment',
      },
    ],
  },
];
