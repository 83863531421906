import { formatDuration, formatTimestamp } from '@/utils/datetime';

export const GROUP_CAMERA_UPTIME_RECORD_TABLE_COLUMNS = [
  {
    id: 'reportingTime',
    label: 'Time',
    align: 'left',
    searchable: true,
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'uptime',
    label: 'Duration',
    align: 'center',
    searchable: true,
    format: (value) => formatDuration(value * 1000, { short: false }),
  },
];
