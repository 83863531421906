import { PageFilteringArea } from '@/web/@components/PageFilteringArea';

/**
 * @typedef {object} FilterValue
 * @property {string[]} status
 */

/**
 * @typedef {object} FilteringAreaProps
 * @property {FilterValue} value
 * @property {StateAction<FilterValue>} onChange
 */

/** @param {FilteringAreaProps} props */
export function LiveTrackingFilteringArea(props) {
  const { value, onChange } = props;
  return (
    <PageFilteringArea
      value={value}
      onChange={onChange}
      filters={[
        {
          type: 'SELECT',
          key: 'status',
          title: 'Status',
          submenu: ['Online', 'Parked', 'Offline'],
        },
      ]}
    />
  );
}
