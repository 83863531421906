import { LocationWithIcon } from '@/web/@components/LocationWithIcon';

/** @type {Array<TableColumn<Endpoint>>} */
export const GROUP_CAMERA_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    type: 'CHECKBOX',
  },
  {
    id: 'label',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    link: (row) => `/cameras/${row.id}`,
    sort: true,
    searchable: true,
    fallback: 'Smarter AI Dashcam',
  },
  {
    id: 'country',
    label: 'Location',
    format: (value, row) => <LocationWithIcon location={`${row?.city}, ${row?.country}`} />,
    sort: true,
    searchable: true,
  },
  {
    id: 'deviceKey',
    label: 'Serial No',
    align: 'left',
    sort: true,
    searchable: true,
  },
  {
    id: 'packageVersion',
    label: 'Version',
    align: 'left',
    sort: true,
    searchable: true,
  },
];
