/** SOURCE: https://github.com/LucasAndrad/gapi-script-live-example/blob/master/src/components/GoogleLogin.js */

import { useGoogleAuth } from '@/hooks/useGoogleAuth';
import { T } from '@/assets/locales';
import { store } from '@/store';
import { Auth } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GOOGLE_ICON } from '@/assets/constants/images';

/**
 * @typedef {object} GoogleLoginButtonPropsBase
 * @property {boolean} [disabled]
 * @property {(email: string, idToken: string) => void} [onLogin]
 */

/**
 * @typedef {import('@mui/material').ButtonProps & GoogleLoginButtonPropsBase} GoogleLoginButtonProps
 */

/**
 * @param {GoogleLoginButtonProps} props
 */
export function GoogleLoginButton(props) {
  const { disabled, onLogin, ...extraProps } = props;

  const { t } = useTranslation();
  const { loading, signIn } = useGoogleAuth();

  const handleSignIn = async () => {
    try {
      const user = await signIn();
      onLogin && onLogin(user.email, user.idToken);
      store.dispatch(Auth.setAvatar(user.picture));
    } catch (err) {
      if (err.error === 'popup_closed_by_user') return;
      console.error('Sign in with Google failed', err);
      toastWarning('Failed', 'Sign in with Google failed');
    }
  };

  return (
    <Button {...extraProps} disabled={Boolean(disabled || loading)} onClick={handleSignIn}>
      {loading ? (
        <CircularProgress disableShrink size={16} />
      ) : (
        <BoxImage src={GOOGLE_ICON} alt="google-login" width={16} height={16} />
      )}
      &nbsp;&nbsp;
      <span>{t(T['login.button.google'])}</span>
    </Button>
  );
}
