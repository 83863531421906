import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, TableCell, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @template T
 * @typedef {object} DialogTableHeaderCellProps
 * @property {TableColumn<T>} column
 * @property {Array<T>} data
 * @property {string} orderBy
 * @property {import('@mui/material').SortDirection} order
 * @property {(columnId: string) => any} onSortRequest
 */

/**
 * @template T
 * @param {DialogTableHeaderCellProps<T>} props
 */
export function DialogTableHeaderCell(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { column, orderBy, order, onSortRequest, data } = props;
  return (
    <TableCell
      align={column.align}
      style={{
        minWidth: column.minWidth,
        width: column.width,
      }}
      padding={'normal'}
      sortDirection={orderBy === column.id ? order : false}
    >
      <Box
        display={'inline-flex'}
        alignItems={'center'}
        sx={{ cursor: 'pointer' }}
        fontWeight={600}
        fontSize={'0.875rem'}
        onClick={() => column.sort && onSortRequest(column.id)}
      >
        {column?.headerFormat ? column.headerFormat(column.id, data) : t(column.label)}
        {column.sort && (
          <Box display={'grid'} alignContent={'center'}>
            <ArrowDropUp
              sx={{ mt: '2px' }}
              htmlColor={
                orderBy === column.id && order === 'asc' ? theme.palette.primary.dark : '#DCE4ED'
              }
            />
            <ArrowDropDown
              sx={{ mt: '-18px' }}
              htmlColor={
                orderBy === column.id && order !== 'asc' ? theme.palette.primary.dark : '#DCE4ED'
              }
            />
          </Box>
        )}
      </Box>
    </TableCell>
  );
}
