import { Clear, Delete, Done, Edit } from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export function ThresholdSettings(props) {
  const { trigger, formName, hoveredRowId } = props;

  const { setValue } = useFormContext();

  const [isEditMode, setInEditMode] = useState(false);
  const [tempThresHold, setTempThreshold] = useState(trigger?.threshold || undefined);

  const handleThresholdButtonClick = () => {
    setInEditMode(true);
  };

  useEffect(() => {
    setInEditMode((prev) => (prev ? hoveredRowId === trigger?.id : false));
  }, [hoveredRowId, trigger?.id]);

  return (
    <Box>
      {tempThresHold || isEditMode ? (
        <Box display="flex" gap={1} alignItems="center">
          <TextField
            name={formName}
            sx={{ 'width': '70px', '& .MuiInputBase-formControl': { height: '26px' } }}
            size="small"
            type="number"
            variant="outlined"
            defaultValue={trigger?.threshold}
            onInput={(e) => {
              // @ts-ignore
              setTempThreshold(e?.target?.value);
            }}
            InputProps={{
              readOnly: isEditMode ? false : true,
            }}
            disabled={isEditMode ? false : true}
          />
          {hoveredRowId === trigger?.id ? (
            <>
              {isEditMode ? (
                <>
                  <Button
                    aria-label="clear"
                    size="small"
                    variant="outlined"
                    sx={{ minWidth: '15px !important', width: '30px', height: '25px !important' }}
                    onClick={() => {
                      setInEditMode(false);
                      setTempThreshold(trigger?.threshold);
                      setValue(formName, trigger?.threshold);
                    }}
                  >
                    <Clear sx={{ fontSize: '15px' }} />
                  </Button>
                  <Button
                    aria-label="done"
                    size="small"
                    variant="contained"
                    sx={{ minWidth: '15px !important', width: '30px', height: '25px !important' }}
                    onClick={() => {
                      setInEditMode(false);
                      setValue(formName, tempThresHold || trigger?.threshold);
                    }}
                  >
                    <Done sx={{ fontSize: '15px' }} />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    aria-label="delete"
                    size="small"
                    variant="outlined"
                    color={'warning'}
                    sx={{ minWidth: '15px !important', width: '30px', height: '25px !important' }}
                    onClick={() => {
                      setInEditMode(false);
                      setTempThreshold(null);
                      setValue(formName, undefined);
                    }}
                  >
                    <Delete sx={{ fontSize: '15px' }} />
                  </Button>
                  <Button
                    aria-label="edit"
                    size="small"
                    variant="contained"
                    sx={{ minWidth: '15px !important', width: '30px', height: '25px !important' }}
                    onClick={() => {
                      setInEditMode(true);
                    }}
                  >
                    <Edit sx={{ fontSize: '15px' }} />
                  </Button>
                </>
              )}
            </>
          ) : null}
        </Box>
      ) : hoveredRowId === trigger?.id ? (
        <Button
          startIcon={<TuneIcon style={{ fontSize: '13px' }} />}
          size="small"
          onClick={handleThresholdButtonClick}
          sx={{ color: '#1E59A8' }}
        >
          <Typography fontSize="0.75rem">Add Threshold</Typography>
        </Button>
      ) : null}
    </Box>
  );
}
