import api from '@/api';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken } from '@/store/auth';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { UserDetailsContext } from '@/web/@layouts/UserDetailsLayout';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { USER_MOBILE_DEVICE_TABLE_COLUMNS } from './columns';

export function UserMobileAppDevices() {
  const secretToken = useSelector(selectSecretToken);

  const { userId } = useContext(UserDetailsContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);

  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    if (!userId) return;
    setLoading(true);
    const request = api.ac.endpoint.acl.associatedendpoints.$get({
      params: {
        endpointType: 'APP',
        userId,
        secretToken: secretToken,
      },
    });
    request
      .process()
      .then((data) => {
        let promises = [];
        data.endpointRoles.forEach((item) => {
          const infoReq = api.ac.v1.endpoint.info.$get({
            params: {
              endpointId: item.endpointId,
              secretToken,
            },
          });
          promises.push(infoReq.process());
        });

        Promise.all(promises).then((values) => {
          const mergedData = data.endpointRoles.map((o1) => {
            const newObj = values.find((o2) => o2.id === o1.endpointId);
            return { ...o1, ...newObj };
          });

          const request = api.ac.v1['event-messaging']['check-presence'].$post({
            params: {
              secretToken,
            },
            data: {
              endpointIds: mergedData.map((item) => item.id),
            },
          });

          request
            .process()
            .then((item) => {
              const mergedPresenceData = item.presence.map((o1) => {
                const newObj = mergedData.find((o2) => o2.id === o1.endpointId);
                const priority = o1.state === MESSAGE_TYPE.PRESENCE_ONLINE;
                return {
                  ...{ isOnline: priority, priority: priority ? 0 : 1 },
                  ...newObj,
                };
              });
              setEndpoints(mergedPresenceData);
            })
            .catch((ex) => {
              setEndpoints(mergedData);
            })
            .finally(() => {
              setLoading(false);
            });
        });
      })
      .catch((ex) => {});
  }, [secretToken, userId]);

  return (
    <Box my={2}>
      <PaginatedTableContextProvider>
        <ContinuationTokenTable
          results={endpoints}
          loading={loading}
          // @ts-ignore
          columns={USER_MOBILE_DEVICE_TABLE_COLUMNS}
          height="42vh"
          dataSortKey="priority"
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
