import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FROM_TYPES } from '@/assets/schemes/constants';
import { GroupSettingsInputForm } from '../Forms/GroupSettingsInputForm';

export function ModelValidation(props) {
  const { product } = props;

  const [inputSources, setInputSources] = useState([]);

  const { control } = useFormContext();

  const dependentValue = useWatch({ name: 'enableModelValidation', control });

  useEffect(() => {
    if (!product) return;
    const inputs = product?.inputs
      .filter((item) => item.inputType === 'VIDEO')
      .map((item) => {
        return {
          label: item.inputName,
          key: `modelValidationFileUrl_${item.inputCode}`,
          type: FROM_TYPES.INPUT,
          inputType: 'textarea',
          isDisabled: () => !dependentValue,
          isHidden: !dependentValue,
          multiline: true,
        };
      });
    setInputSources(inputs);
  }, [product, dependentValue]);

  return (
    <Grid
      container
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing="10px"
      width="100%"
    >
      {inputSources
        .filter((x) => !x.isHidden)
        .map((item) => (
          <Grid item xs={11} md={5} key={item.key}>
            <GroupSettingsInputForm item={item} />
          </Grid>
        ))}
    </Grid>
  );
}
