import { useDashcamPlayer } from '@/hooks/useDashcamPlayer';
import {
  selectIsSupport,
  selectRefreshToken,
  selectSecretToken,
  selectTenantId,
} from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CoachingEndpointDetailsContext } from '../../@context/EndpointDetailsContext';
import { CoachingPlayerEvents } from '../guided/CoachingPlayerEvents';
import { CoachingInfoSection } from './CoachingInfo';

/** @type {SmartCache<{endpointId: number, tenantId: string, triggerName: string}>} */
const eventCache = new SmartCache('event-player-info-cache', 365 * 24 * 3600 * 1000);

export function PendingCoachingPage() {
  /** @type {import('react').Ref<HTMLDivElement>} */
  const parentRef = useRef();

  const player = useDashcamPlayer();

  const { selectedEvent, currentSessionId, loading, currentSessionInfo } = useContext(
    CoachingEndpointDetailsContext
  );

  const { setBreadcrumbTitle } = useContext(MainContext) || {};

  const tenantId = useSelector(selectTenantId);
  const support = useSelector(selectIsSupport);
  const accessToken = useSelector(selectSecretToken);
  const refreshToken = useSelector(selectRefreshToken);
  const endpointId = useMemo(() => selectedEvent?.endpointId, [selectedEvent]);
  const triggerName = useMemo(() => selectedEvent?.triggerName, [selectedEvent]);
  const deviceLabel = useMemo(() => selectedEvent?.deviceLabel, [selectedEvent]);

  useEffect(() => {
    if (!deviceLabel) return;
    setBreadcrumbTitle(deviceLabel, (path) => path.endsWith(`${currentSessionId}`));
  }, [deviceLabel, setBreadcrumbTitle, currentSessionId]);

  useEffect(() => {
    if (!selectedEvent) return;
    eventCache.setItem(selectedEvent.id, { tenantId, endpointId, triggerName });
  }, [selectedEvent, tenantId, endpointId, triggerName]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('tenant-id', tenantId);
    if (support) {
      player.setAttribute('support', 'on');
    } else {
      player.removeAttribute('support');
    }
    player.setAttribute('refresh-token', refreshToken);
    player.setAttribute('access-token', accessToken);
  }, [player, support, tenantId, refreshToken, accessToken]);

  useEffect(() => {
    if (!player || !selectedEvent) return;
    player.setAttribute('event-id', selectedEvent.id + '');
  }, [player, selectedEvent]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('show-trigger-report', 'on');
    player.setAttribute('custom-report-area', 'on');
    player.setAttribute('hide-annotation', 'on');
  }, [player]);

  useEffect(() => {
    if (!player || !selectedEvent) return;
    parentRef?.current?.replaceChildren(player);
    // store.dispatch(PageView.setHideToolbar(true));
    // store.dispatch(PageView.setFullWidthLayout(true));
  }, [selectedEvent, refreshToken, accessToken, player]);

  return (
    <>
      <CoachingInfoSection
        sessionId={currentSessionId}
        coachingInfo={currentSessionInfo}
        loading={loading}
        isGuided={false}
        eventId={selectedEvent?.id}
      />

      {selectedEvent && player && <CoachingPlayerEvents player={player} />}

      {!loading && !currentSessionId ? (
        <CenterBox>
          <CircularProgress />
        </CenterBox>
      ) : (
        <Box ref={parentRef} width="100%" height={{ xs: '100%', xl: '89%' }} p={1}>
          <CenterBox>
            <CircularProgress />
            <Typography variant="subtitle2" fontSize="12px" mt={1}>
              Verifying access
            </Typography>
          </CenterBox>
        </Box>
      )}
    </>
  );
}
