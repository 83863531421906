import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { Box, Typography } from '@mui/material';

export function TriggerPropertyInheritInfo({ targetName }) {
  return (
    <Box
      display="flex"
      sx={{
        '& .MuiTypography-root': {
          fontSize: '0.70rem',
        },
      }}
    >
      <Box display="grid" textAlign="center" alignItems="center">
        <Typography variant="subtitle2" textAlign="left">
          Inherited from
        </Typography>
        <Box fontWeight={500} textAlign="left">
          <LongTextShortener
            text={targetName ? `${targetName}` : ''}
            length={50}
            sx={{ fontWeight: 500, fontSize: '1rem' }}
          />
        </Box>
      </Box>
    </Box>
  );
}
