import api from '@/api';
import { T } from '@/assets/locales';
import { EMAIL_VALIDATION_REGEX } from '@/assets/regex';
import { useFullProductList } from '@/hooks/useFullProductList';
import { selectSecretToken } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Typography, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const defaultValues = {
  tenantName: '',
  tenantId: '',
  adminEmail: '',
  adminName: '',
  products: [],
};

/**
 * @typedef {object} TenantFormPropsBase
 * @property {import('react-hook-form').SubmitHandler<typeof defaultValues>} onSubmitData
 * @property {boolean} isLoading
 */

/**
 * @typedef {TenantFormPropsBase & import('@mui/material').BoxProps} TenantFormProps
 */

/**
 * @param {TenantFormProps} props
 */
export function TenantForm(props) {
  const { onSubmitData, isLoading } = props;

  const params = useParams();
  const selectedTenantId = useMemo(() => params.tenantId, [params]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const secretToken = useSelector(selectSecretToken);

  const { result: products, loading: productLoading } = useFullProductList();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!selectedTenantId) return;
    setLoading(true);
    const req = api.ac.v5.tenant.$tenantId(selectedTenantId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    req.process().then((result) => {
      const { tenantName } = result;
      form.setValue('tenantName', tenantName);
      form.setValue('tenantId', selectedTenantId);
      setLoading(false);
    });
    return () => req.abort();
  }, [selectedTenantId, secretToken, form]);

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.tenantName = trimString(data.tenantName);
    data.adminEmail = trimString(data?.adminEmail);
    data.adminName = trimString(data?.adminName);
    onSubmitData(data);
  };

  const handleCancel = () => {
    navigate('/administration/tenants');
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        {t(T['tenant.form.text.secondary'])}
      </Typography>
      <Divider />

      <FormProvider {...form}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="tenantName"
                label={t(T['tenant.form.name.label'])}
                placeholder={t(T['tenant.form.name.placeholder'])}
                rules={{ required: t(T['tenant.form.name.required']) }}
              />
            </Grid>

            {!selectedTenantId ? (
              <>
                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="adminName"
                    label={t(T['tenant.form.admin.name'])}
                    placeholder={t(T['tenant.form.admin.name.placeholder'])}
                    rules={{ required: t(T['tenant.form.admin.name.required']) }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="adminEmail"
                    label={t(T['tenant.form.admin.email'])}
                    placeholder={t(T['tenant.form.admin.email.placeholder'])}
                    InputProps={{
                      type: 'email',
                      autoComplete: 'email',
                    }}
                    rules={{
                      required: t(T['tenant.form.admin.email.required']),
                      pattern: {
                        value: EMAIL_VALIDATION_REGEX,
                        message: t(T['tenant.form.admin.email.rules.regex']),
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomSelect
                    name="products"
                    label="Product *"
                    getKey="productId"
                    getLabel="productName"
                    loading={productLoading}
                    options={products}
                    placeholder="Select a product"
                    rules={{ required: 'Product is required' }}
                    AutocompleteProps={{
                      multiple: true,
                    }}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              type="submit"
              onClick={form.handleSubmit(prepareAndSubmit)}
            >
              {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {selectedTenantId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
