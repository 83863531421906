import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CameraAddModal } from '../@components/CameraAddModal';
import { ChangeDeviceGroup } from '../@components/ChangeDeviceGroup';
import { DeviceGroupDetailsContext } from '../@components/DeviceGroupDetailsLayout/index';
import { ACTION_ITEMS } from './actions';
import { GROUP_CAMERA_TABLE_COLUMNS } from './columns';

export function DeviceGroupCameras() {
  const { t } = useTranslation();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { groupId } = useContext(DeviceGroupDetailsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [pageRefresh, setPageRefresh] = useState(true);
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onModalClose = (reload) => {
    setIsModalOpen(false);
    setModalType('');
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<Endpoint>['onAction']} */
  const onActionHandle = (type) => {
    setIsModalOpen(true);
    setModalType(type);
  };

  const fetchEndpointGroupList = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      setLoading(true);
      setError(null);
      try {
        let limit = 100;
        let offset = 0;
        /** @type {Array<Endpoint>} */
        const results = [];
        while (!signal.aborted) {
          const request = api.ac.v1.endpoint.group.list.resources.$get({
            signal,
            params: {
              limit: limit,
              offset,
              groupId,
              tenantID: tenantId,
              secretToken,
              groupResourceType: 'DEVICE',
            },
          });
          await request.process();
          const result = request.result.endpoints;
          results.push(...result);
          offset += result.length;
          if (result.length < limit) break;
        }
        setCameras(results);
      } catch (ex) {
        setError(ex);
      } finally {
        setLoading(false);
        setPageRefresh(false);
      }
    },
    [tenantId, secretToken, groupId]
  );

  useEffect(() => {
    if (!pageRefresh) return;
    const aborter = new AbortController();
    fetchEndpointGroupList(aborter.signal);
    return () => aborter.abort();
  }, [fetchEndpointGroupList, pageRefresh]);

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Cameras');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        {isModalOpen &&
          (modalType === 'CREATE' ? (
            <CameraAddModal onModalClose={onModalClose} cameras={cameras} />
          ) : (
            <ChangeDeviceGroup onModalClose={onModalClose} />
          ))}
        <ItemsListHeader
          title={t(T['cameras.page.title'])}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <ContinuationTokenTable
          loading={loading}
          results={cameras}
          columns={GROUP_CAMERA_TABLE_COLUMNS}
          onReload={() => {
            setPageRefresh(true);
          }}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
