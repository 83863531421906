import { Link } from '@mui/material';
import { useState } from 'react';
import { FeedbackDetailsModal } from '../FeedbackDetailsModal';

/**
 * @typedef {object} TriggerDetailsButtonProps
 * @property {FeedbackDto} [row]
 */

/** @param {TriggerDetailsButtonProps} props */
export function TriggerDetailsButton(props) {
  const { row } = props;

  /** @type {StateVariable<boolean>} */
  const [detailsDialog, setDetailsDialog] = useState(false);

  return (
    <>
      {detailsDialog && (
        <FeedbackDetailsModal onCancel={() => setDetailsDialog(false)} feedback={row} />
      )}

      <Link onClick={() => setDetailsDialog(true)}>Details</Link>
    </>
  );
}
