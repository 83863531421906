import { TRIGGER_VOICE_BLUE_ICON } from '@/assets/constants/images';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectTenantId } from '@/store/auth';
import { BoxImage } from '@/web/@components/BoxImage';
import { CollapsibleSearch } from '@/web/@components/CollapsibleSearch';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import { AutoCompleteItemSelection } from '../AutoCompleteItemSelection';
import { TriggerForm } from '../TriggerForm';
import TriggerTable from '../TriggerTable';
import { TriggersCSVDownload } from '../TriggersCSVDownload';

const TRIGGER_PAGE_MODE = {
  TABLE_VIEW: 1,
  TRIGGER_EDIT_VIEW: 2,
};

export function TriggerConfigurationPage() {
  const { query, updateQuery } = useRouteQuery();
  const { triggers, selectionType } = useContext(TriggerConfigurationContext);

  const theme = useTheme();
  const navigate = useNavigate();
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<number>} */
  const [pageMode, setPageMode] = useState(TRIGGER_PAGE_MODE?.TABLE_VIEW);
  /** @type {StateVariable<any>} */
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const handleCancelEdit = () => {
    setPageMode(TRIGGER_PAGE_MODE?.TABLE_VIEW);
    updateQuery({ id: null });
  };

  const isTtsChangeVisible = useMemo(
    () =>
      selectionType?.value === tenantId &&
      !isSearchEnabled &&
      pageMode === TRIGGER_PAGE_MODE?.TABLE_VIEW,
    [selectionType?.value, tenantId, pageMode, isSearchEnabled]
  );

  useEffect(() => {
    if (!query?.id) return;
    const selectedTrigger = triggers?.find((i) => i?.id === query?.id);
    if (selectedTrigger) {
      setSelectedTrigger(selectedTrigger);
      setPageMode(TRIGGER_PAGE_MODE?.TRIGGER_EDIT_VIEW);
    }
  }, [query?.id, triggers]);

  return (
    <Box>
      {pageMode !== TRIGGER_PAGE_MODE?.TTS_EDIT_VIEW && (
        <Box>
          <Typography variant="body2" fontSize="1rem" fontWeight={'medium'} mb={1} color="#596A82">
            {pageMode !== 2 ? 'Select Entity' : selectedTrigger?.name}
          </Typography>

          <Box
            display={'flex'}
            columnGap={2.5}
            flexDirection={smAndUp ? 'row' : 'column'}
            alignItems="center"
          >
            <Box width={{ xs: '100%', md: '60%' }}>
              <AutoCompleteItemSelection
                isEditMode={pageMode === TRIGGER_PAGE_MODE?.TRIGGER_EDIT_VIEW}
              />
            </Box>

            {selectionType && (
              <Box
                width={{ xs: '100%', md: '40%' }}
                display="flex"
                justifyContent={isTtsChangeVisible ? 'space-between' : 'flex-end'}
                alignItems="center"
                pb={2}
                sx={{ transition: 'all 0.3s ease-in-out' }}
              >
                {isTtsChangeVisible && (
                  <Box
                    onClick={() =>
                      navigate('/administration/trigger-configuration/incab-coach-settings')
                    }
                    display="flex"
                    flexDirection="row"
                    gap={0.5}
                    sx={{
                      flex: isSearchEnabled ? 0 : 1,
                      opacity: isSearchEnabled ? 0 : 1,
                      transition: 'all 0.3s ease-in-out',
                      cursor: 'pointer',
                    }}
                  >
                    <BoxImage
                      src={TRIGGER_VOICE_BLUE_ICON}
                      size={{ xs: '13px', sm: '15px' }}
                      sx={{ fill: 'green' }}
                    />
                    <Typography
                      fontWeight={'medium'}
                      color="#4177BF"
                      fontSize={'0.78rem'}
                      sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}
                    >
                      Incab Coach Settings
                    </Typography>
                  </Box>
                )}

                {pageMode === TRIGGER_PAGE_MODE?.TABLE_VIEW && (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    sx={{
                      flex: isSearchEnabled ? 0 : 1,
                      opacity: isSearchEnabled ? 0 : 1,
                      transition: 'all 0.3s ease-in-out',
                    }}
                  >
                    <TriggersCSVDownload />
                  </Box>
                )}

                {pageMode === TRIGGER_PAGE_MODE?.TABLE_VIEW && (
                  <Box
                    sx={{
                      flex: isSearchEnabled ? 3 : 1,
                      width: isSearchEnabled ? '100%' : 'auto',
                      transition: 'all 0.3s ease-in-out',
                    }}
                  >
                    <CollapsibleSearch
                      collapsedText="Search Triggers"
                      onSearchEnable={(val) => setIsSearchEnabled(val)}
                      fontSize={'0.78rem'}
                      iconSize={'14px'}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}

      {pageMode === TRIGGER_PAGE_MODE?.TRIGGER_EDIT_VIEW ? (
        <TriggerForm onCancel={handleCancelEdit} triggerItem={selectedTrigger} />
      ) : (
        <TriggerTable />
      )}
    </Box>
  );
}
