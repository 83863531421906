import { Box } from '@mui/material';

/** @param {{last?: boolean, children: any} & import('@mui/material').BoxProps} props  */
export function ComponentNodeContainer(props) {
  const { last = false, children, ...extra } = props;
  return (
    <Box display="flex" width="fit-content" marginLeft="21px" {...extra}>
      <Box>
        <Box height="35px" borderLeft="0.1em dashed #596A81" />
        <Box
          borderTop="0.1em dashed #596A81"
          width="15px"
          height="100%"
          minHeight="20px"
          style={{
            borderLeft: last ? null : '0.1em dashed #596A81',
          }}
        />
      </Box>
      <Box mt="15px" mr="15px">
        {children}
      </Box>
    </Box>
  );
}
