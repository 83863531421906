import { FROM_TYPES } from '@/assets/schemes/constants';

export const deviceImagerStates = (imager) => ({
  label: imager.inputName,
  key: `enableImagerState_${imager?.inputCode}`,
  type: FROM_TYPES.SWITCH,
  isDisabled: (watch) => {
    let otherImagerState =
      imager?.inputCode === 'vid_1'
        ? watch('enableImagerState_vid_2')
        : imager?.inputCode === 'vid_2'
          ? watch('enableImagerState_vid_1')
          : true;
    return !otherImagerState;
  },
});
