import { BOX_DELETE_ICON, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<VinGroupInfoAggregated>>} */
export const ACTION_ITEMS = [
  {
    label: 'Delete',
    iconImage: BOX_DELETE_ICON,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    confirmationMessage: T['vin-groups.confirmations.discontinue'],
  },
  {
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selectedItem) => `/administration/vin-groups/${selectedItem.vinGroupId}/edit`,
    hidden: (selected) => selected.length !== 1,
  },
  {
    component: 'SEARCH',
    label: 'Search',
    placeholder: 'Search VIN Group',
    options: [
      { value: 'vinGroupName', label: 'Name' },
      { value: 'targetGroupName', label: 'Device Group' },
    ],
  },
  {
    label: 'VIN Group',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/vin-groups/create',
  },
];
