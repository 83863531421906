import { DELETE_ICON } from '@/assets/constants/images';
import { CAMERA_SETTINGS_DATA } from '@/assets/operations/manageCameras';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CameraSettingsFormControl } from './CameraSettingsFormControl';

/** @param {{fieldIndex: number, remove: import('react-hook-form').UseFieldArrayRemove}} props */
export function CameraSettingsFormItem(props) {
  const { fieldIndex, remove } = props;

  const { watch } = useFormContext();
  const inputType = watch(`settings[${fieldIndex}].isDeletable`);

  return (
    <Box position="relative" pr="50px">
      <Divider
        orientation="vertical"
        sx={{
          position: 'absolute',
          right: 10,
          top: '10px',
          bottom: 0,
        }}
      >
        <IconButton
          disabled={!inputType}
          onClick={() => remove(fieldIndex)}
          color="error"
          sx={{
            p: '5px',
            borderRadius: '50%',
            border: '1px solid #596A81',
          }}
        >
          <BoxImage src={DELETE_ICON} size="20px" />
        </IconButton>
      </Divider>

      <Grid container spacing="10px" px="10px" mb="10px">
        {CAMERA_SETTINGS_DATA.map((item, index) => (
          <CameraSettingsFormControl key={index} item={item} fieldIndex={fieldIndex} />
        ))}
      </Grid>
    </Box>
  );
}
