import { useFullscreenControl } from '@/hooks/useFullscreenControl';
import { EmptyImagePlaceholder } from '@/web/@components/EmptyImagePlaceholder';
import { Close as CloseIcon } from '@mui/icons-material';
import { Avatar, Box, IconButton } from '@mui/material';

/**
 * @typedef {object} DriverLicenseImageProps
 * @property {string} [licenseImage]
 * @property {string} [type]
 */

/**
 * @param {DriverLicenseImageProps} props
 */

export function DriverLicenseImage(props) {
  const { licenseImage, type } = props;

  const { fullscreen, openFullscreen, exitFullscreen } = useFullscreenControl();

  return (
    <>
      {licenseImage ? (
        <Box onClick={() => openFullscreen(document.getElementById(type))}>
          <Box
            id={type}
            sx={{
              position: fullscreen ? 'fixed' : '',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            {fullscreen && (
              <IconButton
                size="small"
                id="fullscreenImage"
                onClick={() => exitFullscreen()}
                sx={{
                  'color': '#011e42',
                  'background': 'white',
                  'opacity': '0.5',
                  ':hover': { background: 'white', opacity: 1 },
                  'position': 'absolute',
                  'zIndex': 9999,
                  'right': '10px',
                  'top': '10px',
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            <Avatar
              variant="square"
              src={licenseImage}
              sx={{
                ...(fullscreen
                  ? {
                      display: 'block',
                      width: '100%',
                      maxWidth: { xs: '100%', md: '50%' },
                      height: 'auto',
                      margin: 'auto',
                    }
                  : {
                      height: { xs: '100%', lg: '80%' },
                      width: { xs: '100%', md: '200px' },
                    }),
                ':hover': {
                  cursor: 'pointer',
                },
              }}
            ></Avatar>
          </Box>
        </Box>
      ) : (
        <EmptyImagePlaceholder />
      )}
    </>
  );
}
