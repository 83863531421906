import { EventGridListItem } from '@/web/events/@components/EventGridListItem';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';

const EventCarouselItem = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < items.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <Box position="relative" width="290px" overflow="hidden">
      <Box
        display="flex"
        height="100%"
        sx={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: 'transform 0.5s ease-in-out',
          width: `${items.length * 100}%`,
        }}
      >
        {items.map((item, index) => (
          <Box key={index} minWidth="100%">
            <Box width={'285px'}>
              <EventGridListItem item={item} p="1px" disabledPlay={true} />
            </Box>
          </Box>
        ))}
      </Box>

      {currentIndex !== 0 && (
        <IconButton
          onClick={handlePrev}
          disabled={currentIndex === 0}
          sx={{
            'position': 'absolute',
            'top': '40%',
            'left': '5px',
            'transform': 'translateY(-50%)',
            'backgroundColor': 'rgba(255, 255, 255, 0.3)',
            'color': '#fff',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            'width': '24px',
            'height': '24px',
          }}
        >
          <KeyboardArrowLeft fontSize="small" />
        </IconButton>
      )}

      {currentIndex !== items.length - 1 && (
        <IconButton
          onClick={handleNext}
          disabled={currentIndex === items.length - 1}
          sx={{
            'position': 'absolute',
            'top': '40%',
            'right': '10px',
            'transform': 'translateY(-50%)',
            'backgroundColor': 'rgba(255, 255, 255, 0.3)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            },
            'width': '24px',
            'height': '24px',
          }}
        >
          <KeyboardArrowRight fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default EventCarouselItem;
