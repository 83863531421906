import { useRouteQuery } from '@/hooks/useRouteQuery';
import { store } from '@/store';
import { PageView } from '@/store/page-view';
import { ScrollShadowBox } from '@/web/@components/ScrollShadowBox';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect } from 'react';
import { TripDetailsBox } from '../TripDetailsBox';
import { TripFilterContext } from '../TripFilterContext';
import { TripListView } from '../TripListView';

export function TripListContainer() {
  const { query } = useRouteQuery();
  const { tripId } = useContext(TripFilterContext);

  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const tid = setTimeout(() => {
      store.dispatch(PageView.setFullWidthLayout(true));
    }, 100);
    return () => clearTimeout(tid);
  }, []);

  if (!mdAndUp) {
    return (
      <Box bgcolor={'#F2F7FC'} mt={1}>
        <TripListView />
      </Box>
    );
  }

  return (
    <Grid
      container
      sx={
        query.layout === 'empty'
          ? { height: '100vh' }
          : { width: 'calc(100% - 8px)', height: 'calc(100vh - 130px)', mt: '10px' }
      }
    >
      <Grid item xs={12} md={tripId ? 4 : 12} xl={tripId ? 3 : 12}>
        <ScrollShadowBox
          height={query.layout === 'empty' ? '100vh' : 'calc(100vh - 130px)'}
          bgcolor={tripId ? '#F2F7FC' : undefined}
          ContainerProps={{ overflow: 'auto scroll' }}
        >
          <TripListView />
        </ScrollShadowBox>
      </Grid>
      {tripId && (
        <Grid item xs={12} md={8} xl={9} height={{ xs: '100%', md: '97%', lg: '94%', xl: '97%' }}>
          <TripDetailsBox />
        </Grid>
      )}
    </Grid>
  );
}
