import api from '@/api';
import { FIRMWARE_TYPES, OTA_STATUS, OTA_STATUS_INFO } from '@/assets/ota';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken } from '@/store/auth';
import { sendEventsToDevices, sendOtaMessagesToDevice } from '@/utils/event-messaging';
import { getOTAType } from '@/utils/event-messaging/ota';
import { formatFirmwareName } from '@/utils/firmwares';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
let otaStatusCheckTime;

export function CameraFirmwares() {
  const { cameraId, camera } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [firmwareVersions, setFirmwareVersions] = useState(null);
  const [otaStatus, setOtaStatus] = useState(null);
  const [firmwareUpdatePercentage, setFirmwareUpdatePercentage] = useState({});
  const [isFirmwareUpdating, setIsFirmwareUpdating] = useState(false);
  const [availableUpdates, setAvailableUpdates] = useState([]);

  const checkAvailableFirmwareUpdates = useCallback(
    (deployedFirmwares) => {
      const availableUpdates = [];
      deployedFirmwares.forEach((firmware) => {
        //TODO:: API type fix
        // @ts-ignore
        let { type, version } = firmware;
        if (type === FIRMWARE_TYPES.SYSTEM_IMAGE && version !== firmwareVersions?.SYSTEM_IMAGE) {
          availableUpdates.push(firmware);
        } else if (
          type === FIRMWARE_TYPES.APPLICATION_PACKAGE &&
          version !== firmwareVersions?.APPLICATION_PACKAGE
        ) {
          availableUpdates.push(firmware);
        } else if (type === FIRMWARE_TYPES.OEM_IMAGE && version !== firmwareVersions?.OEM_IMAGE) {
          availableUpdates.push(firmware);
        } else if (type === FIRMWARE_TYPES.CAN_BUS_FW && version !== firmwareVersions?.CAN_BUS_FW) {
          availableUpdates.push(firmware);
        } else if (
          type === FIRMWARE_TYPES.SUPPORTING_APPLICATION &&
          version !== firmwareVersions?.SUPPORTING_APPLICATION
        ) {
          availableUpdates.push(firmware);
        } else if (
          type === FIRMWARE_TYPES.LTE_MODEM_FW &&
          version !== firmwareVersions?.LTE_MODEM_FW
        ) {
          availableUpdates.push(firmware);
        } else if (
          type === FIRMWARE_TYPES.SMART_CABLE_FW &&
          version !== firmwareVersions?.SMART_CABLE_FW
        ) {
          availableUpdates.push(firmware);
        }
      });
      setAvailableUpdates(availableUpdates);
      return true;
    },
    [firmwareVersions, setAvailableUpdates]
  );

  const getAvailableUpdateByType = useCallback(
    (type) => {
      if (type in firmwareUpdatePercentage) {
        const percentage = Number(firmwareUpdatePercentage[type]);
        if (otaStatus === 'IDLE') {
          return '';
        } else if (otaStatus === 'DOWNLOAD_IN_PROGRESS') {
          return OTA_STATUS_INFO[otaStatus] + `${percentage > 0 ? `, ${percentage}%` : ''}`;
        } else {
          return OTA_STATUS_INFO[otaStatus];
        }
      }

      const update = availableUpdates?.find((item) => item.type === type);
      if (update) {
        return `${formatFirmwareName(type)} ${update.version} available`;
      }

      return '';
    },
    [firmwareUpdatePercentage, availableUpdates, otaStatus]
  );

  const getDeviceOTASettings = useCallback(async () => {
    const res = api.ac.endpoint.devices
      .$deviceSerialNumber(camera.deviceSerialNumber)
      .otasettings.$get({
        headers: {
          Authorization: secretToken,
        },
      });
    await res.process();
    return res.result.otaSettings;
  }, [camera, secretToken]);

  const sendOtaMessageToDevice = (onUserTriggerPolicy) => {
    const { deviceSerialNumber } = camera;
    const { type, message } = getOTAType(onUserTriggerPolicy);
    try {
      if (type) {
        sendOtaMessagesToDevice(deviceSerialNumber, type);
        toastSuccess(message);
        return;
      }
      toastWarning(message);
    } catch (err) {
      toastWarning(message);
    }
  };

  const handleFirmwareUpdate = async () => {
    if (firmwareVersions?.OEM_IMAGE) {
      const oemImageNumber = +firmwareVersions.OEM_IMAGE.replace(/\D/g, '');
      if (oemImageNumber < 4) {
        sendEventsToDevices([cameraId], MESSAGE_TYPE.FIRMWARE_UPDATE);
      }
    }
    try {
      const ota = await getDeviceOTASettings();
      if (ota) {
        setIsFirmwareUpdating(true);
        sendOtaMessageToDevice(ota?.onUserTriggerPolicy);
        setOtaStatus(OTA_STATUS.WAITING_FOR_DOWNLOAD);
      }
    } catch (e) {
      toastWarning('Could not send Firmware Update signal');
    }
  };

  const getKeyValue = (array, key) => array?.find((item) => item.indexOf(key) >= 0)?.split('=')[1];

  const getFirmwareUpdateStatus = useCallback((otaMetaData) => {
    if (!otaMetaData) return;
    let splittedMeta = otaMetaData?.split('\n');
    let splittedType = getKeyValue(splittedMeta, 'type');
    const totalSize = getKeyValue(splittedMeta, 'size') || 0;
    const downloadedSize = getKeyValue(splittedMeta, 'dwDone') || 0;
    const percentage = Math.floor((downloadedSize / totalSize) * 100) || 0;

    setFirmwareUpdatePercentage((percentages) => {
      return {
        ...percentages,
        [splittedType]: percentage,
      };
    });
  }, []);

  const getOTAStatus = useCallback(async () => {
    try {
      const { deviceSerialNumber } = camera;
      if (!deviceSerialNumber) return;
      const res = api.ac.v2.endpoint.devices
        .$deviceSerialNumber(deviceSerialNumber)
        .otastatus.$get({
          headers: {
            Authorization: secretToken,
          },
        });
      await res.process();
      if (res.response.status === 200) {
        setOtaStatus(res.result.status);
        getFirmwareUpdateStatus(res.result.metadata);
      }
    } catch (ex) {
      return false;
    }
  }, [camera, secretToken, getFirmwareUpdateStatus]);

  const otaStatusPolling = useCallback(() => {
    otaStatusCheckTime = setInterval(async () => {
      await getOTAStatus();
    }, 10000); // 10 sec
  }, [getOTAStatus]);

  const clearOtaStatusPolling = () => {
    clearInterval(otaStatusCheckTime);
    otaStatusCheckTime = null;
  };

  /***********************************************************
   *                                                          *
   *               Initial Firmware Data fetching             *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!camera) return;
    setLoading(true);
    const request = api.ac.v1.endpoint.devices
      .$deviceSerialNumber(camera.deviceSerialNumber)
      .firmwareversion.$get({
        headers: {
          Authorization: secretToken,
        },
      });

    request
      .process()
      .then(({ firmwareVersions }) => {
        const result = {};
        firmwareVersions.forEach((item) => {
          result[item.firmwareType] = item.firmwareVersion;
        });
        setFirmwareVersions(result);
      })
      .catch((err) => {
        console.error('Failed to fetch endpoint capabilities', err);
      })
      .finally(() => {
        setLoading(false);
        getOTAStatus();
      });
  }, [camera, secretToken, otaStatusPolling, getOTAStatus]);

  /***********************************************************
   *                                                          *
   * Continuously polling otaStatus after 10 sec all the time *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!otaStatusCheckTime) {
      otaStatusPolling();
    }
    return () => clearOtaStatusPolling();
  }, [otaStatusPolling]);

  useEffect(() => {
    if (otaStatus === OTA_STATUS.IDLE) {
      if (isFirmwareUpdating) {
        const request = api.ac.v1.endpoint.devices
          .$deviceSerialNumber(camera.deviceSerialNumber)
          .firmwareversion.$get({
            headers: {
              Authorization: secretToken,
            },
          });
        request.process().then(({ firmwareVersions }) => {
          const result = {};
          firmwareVersions.forEach((item) => {
            result[item.firmwareType] = item.firmwareVersion;
          });
          setFirmwareVersions(result);
          setIsFirmwareUpdating(false);
        });
      }
    } else {
      setIsFirmwareUpdating(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otaStatus, isFirmwareUpdating]);

  /***********************************************************
   *                                                         *
   *  IF ota status IDLE, then check for firmware updates    *
   *                                                         *
   ***********************************************************/
  useEffect(() => {
    const { deviceSerialNumber } = camera;
    if (otaStatus !== OTA_STATUS.IDLE || !camera || !secretToken) return;
    const response = api.ac.v2.endpoint.devices
      .$deviceSerialNumber(deviceSerialNumber)
      .firmwares.$get({
        headers: {
          Authorization: secretToken,
        },
      });
    response.process().then((res) => {
      checkAvailableFirmwareUpdates(res?.deployments || []);
    });
    return () => response.abort();
  }, [camera, secretToken, otaStatus, checkAvailableFirmwareUpdates]);

  return (
    <Box display={{ xs: 'grid', md: 'flex' }} justifyContent="space-between" gap={2}>
      <Grid container spacing={2} textAlign="left">
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            Camera Application
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {firmwareVersions?.APPLICATION_PACKAGE || 'Not Available'}
          </Typography>
          <Typography variant="body2" fontSize="0.75rem" fontStyle="italic">
            {getAvailableUpdateByType('APPLICATION_PACKAGE') || ''}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2" lineHeight="18px">
            System Image
          </Typography>
          <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
            {firmwareVersions?.SYSTEM_IMAGE || 'Not Available'}
          </Typography>
          <Typography variant="body2" fontSize="0.75rem" fontStyle="italic">
            {getAvailableUpdateByType('SYSTEM_IMAGE') || ''}
          </Typography>
        </Grid>

        {firmwareVersions?.OEM_IMAGE && (
          <Grid item xs={12} md={4} position="relative">
            <Typography variant="subtitle2" lineHeight="18px">
              OEM Image
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {firmwareVersions.OEM_IMAGE || 'Not Available'}
            </Typography>
            <Typography variant="body2" fontSize="0.75rem" fontStyle="italic">
              {getAvailableUpdateByType('OEM_IMAGE') || ''}
            </Typography>
          </Grid>
        )}

        {firmwareVersions?.CAN_BUS_FW && (
          <Grid item xs={12} md={4} position="relative">
            <Typography variant="subtitle2" lineHeight="18px">
              CAN Bus Firmware
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {firmwareVersions.CAN_BUS_FW || 'Not Available'}
            </Typography>
            <Typography variant="body2" fontSize="0.75rem" fontStyle="italic">
              {getAvailableUpdateByType('CAN_BUS_FW') || ''}
            </Typography>
          </Grid>
        )}

        {firmwareVersions?.SUPPORTING_APPLICATION && (
          <Grid item xs={12} md={4} position="relative">
            <Typography variant="subtitle2" lineHeight="18px">
              Supporting Application
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {firmwareVersions.SUPPORTING_APPLICATION || 'Not Available'}
            </Typography>
            <Typography variant="body2" fontSize="0.75rem" fontStyle="italic">
              {getAvailableUpdateByType('SUPPORTING_APPLICATION') || ''}
            </Typography>
          </Grid>
        )}

        {firmwareVersions?.LTE_MODEM_FW && (
          <Grid item xs={12} md={4} position="relative">
            <Typography variant="subtitle2" lineHeight="18px">
              LTE Modem Firmware
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {firmwareVersions.LTE_MODEM_FW || 'Not Available'}
            </Typography>
            <Typography variant="body2" fontSize="0.75rem" fontStyle="italic">
              {getAvailableUpdateByType('LTE_MODEM_FW') || ''}
            </Typography>
          </Grid>
        )}

        {firmwareVersions?.SMART_CABLE_FW && (
          <Grid item xs={12} md={4} position="relative">
            <Typography variant="subtitle2" lineHeight="18px">
              Smart Cable MCU Firmware
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {firmwareVersions.SMART_CABLE_FW || 'Not Available'}
            </Typography>
            <Typography variant="body2" fontSize="0.75rem" fontStyle="italic">
              {getAvailableUpdateByType('SMART_CABLE_FW') || ''}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box>
        {availableUpdates.length > 0 && (
          <Button
            sx={{ minWidth: '180px' }}
            fullWidth
            variant="contained"
            size="small"
            onClick={handleFirmwareUpdate}
            disabled={isFirmwareUpdating}
            {...(smAndDown && { fullWidth: true })}
          >
            {isFirmwareUpdating ? 'Updating' : 'Update'} Software
            {(isFirmwareUpdating || loading) && (
              <CircularProgress size={20} color="secondary" sx={{ mx: '5px' }} />
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
}
