import { Box } from '@mui/material';

/** @param {import('@mui/material').BoxProps} props */
export function ImageNotAvailable(props) {
  const { children, sx, ...boxProps } = props;
  return (
    <Box
      {...boxProps}
      sx={{
        width: `100%`,
        height: '100%',
        backgroundColor: '#F5F5F5',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '5px',
        ...sx,
      }}
    >
      {children ?? (
        <>
          <img
            loading="eager"
            alt="not-available"
            src="/images/commons/no-img.svg"
            width="24%"
            height="24%"
            style={{
              display: 'block',
              opacity: 1,
            }}
          />
          <div
            style={{
              color: '#d5d5d5',
              fontWeight: '600',
              whiteSpace: 'nowrap',
              fontSize: '78%',
              opacity: 0.7,
            }}
          >
            Image Not Available
          </div>
        </>
      )}
    </Box>
  );
}
