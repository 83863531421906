import { CAMERA_N_ON_ICON, FIRMWARE_ICON_ON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { PageLink } from '@/web/@components/PageLink';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

/** @type {Array<DevicePoolPageCardItem>} */
const navItems = [
  {
    name: 'Devices',
    icon: CAMERA_N_ON_ICON,
    bgcolor: '#F0F7EF',
    link: 'devices',
  },
  {
    name: 'Firmwares',
    icon: FIRMWARE_ICON_ON,
    bgcolor: '#F7F5EF',
    link: 'firmwares',
  },
];

export function DevicePoolCardItems() {
  return (
    <Grid container columnSpacing={3} rowSpacing={2}>
      {navItems.map((item, index) => (
        <Grid
          key={item.name + index}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          component={PageLink}
          underline="none"
          to={item.link}
        >
          <Box
            bgcolor={item.bgcolor}
            sx={{ height: '110px', borderRadius: '20px' }}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize="22px" fontWeight="500" variant="body2">
              {item.name}
            </Typography>
            <BoxImage
              src={item.icon}
              height="50px"
              width="50px"
              sx={{ filter: 'grayscale(50%)' }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
