import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { isNumber, startCase } from 'lodash';

/**
 * @typedef {object} TriggerDetailsModalProps
 * @property {VariableDefinitionDto} [variable]
 * @property {() => any} [onCancel]
 */

/** @param {TriggerDetailsModalProps} props */
export function TriggerVariableDetailsModal(props) {
  const { variable, onCancel } = props;

  const createButton = (key, label, value) => (
    <Button key={key} sx={{ fontSize: '0.80rem', color: '#011e42' }}>
      {label} {isNumber(value) ? value : '...'}
    </Button>
  );

  const constraintsButtons = [
    createButton('one', 'Min Value:', variable?.constraint?.minValue),
    createButton('two', 'Max Value:', variable?.constraint?.maxValue),
    createButton('three', 'Step Size:', variable?.constraint?.stepSize),
  ];

  const sensitivityButtons = [
    createButton('one', 'Low:', variable?.variableSensitivity?.low),
    createButton('two', 'Medium:', variable?.variableSensitivity?.medium),
    createButton('three', 'High:', variable?.variableSensitivity?.high),
  ];

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Trigger Variable Details</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{
            '& .word-break': {
              wordWrap: 'break-word !important',
            },
          }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle2" lineHeight="18px">
              Variable Name
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={500}
              lineHeight="18px"
              className="word-break"
            >
              {variable?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" lineHeight="18px">
              Type
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {startCase(variable?.type?.toLowerCase())}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" lineHeight="18px">
              Default Value
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {variable?.defaultValue || '...'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" lineHeight="18px">
              Unit
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {variable?.unit || '...'}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" lineHeight="18px">
              Customer Facing
            </Typography>
            <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
              {variable?.customerFacing ? 'Yes' : 'No'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" lineHeight="18px" pb={0.5}>
              Constraint
            </Typography>
            <ButtonGroup size="small" aria-label="Sensitivity">
              {constraintsButtons}
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" lineHeight="18px" pb={0.5}>
              Sensitivity
            </Typography>
            <ButtonGroup size="small" aria-label="Sensitivity">
              {sensitivityButtons}
            </ButtonGroup>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2" lineHeight="18px">
              Description
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={500}
              lineHeight="18px"
              className="word-break"
            >
              {variable?.description || '...'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
