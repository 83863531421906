import { T } from '@/assets/locales';
import { AIPage } from '.';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <AIPage />,
  },
  {
    path: 'ai-models',
    children: require('./ai-models/routes').default,
    breadcrumb: T['navigation.ai.models'],
  },
  {
    path: 'ai-containers',
    children: require('./ai-container/routes').default,
    breadcrumb: T['navigation.ai.container'],
  },
];

export default routes;
