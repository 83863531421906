import api from '@/api';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { FilterContext } from '@/web/@components/FilterContext';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { subDays } from 'date-fns';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { COACHING_PENDING_TABLE_COLUMNS } from './columns';

export function CoachingPending() {
  const { query } = useRouteQuery();
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const { startTime, endTime } = useContext(FilterContext);

  const [loading, setLoading] = useState(true);
  const [coachingData, setCoachingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getCameraDetails = useCallback(
    async (endpointId) => {
      try {
        const request = api.ac.v5.endpoint.$endpointId(endpointId).details.$get({
          headers: {
            Authorization: secretToken,
          },
        });
        const result = await request?.process();
        return result;
      } catch (ex) {}
    },
    [secretToken]
  );

  const getCameraScore = useCallback(
    async (endpointId, startDate, endDate) => {
      try {
        const request = api.ac.v5.score.endpoint.$endpointId(endpointId).$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            fromTimestamp: startDate,
            toTimestamp: endDate,
          },
        });

        const result = await request?.process();
        // @ts-ignore
        return result?.scores?.find((item) => item?.personaId === 1)?.score;
      } catch (err) {}
    },
    [secretToken]
  );

  const getCoachInfo = useCallback(
    async (coachUserId) => {
      try {
        const request = api.ac.v5.tenant
          .$tenantId(tenantId)
          .user.$userId(coachUserId)
          .$get({
            headers: {
              Authorization: secretToken,
            },
          });
        const result = await request?.process();
        return result || {};
      } catch (err) {}
    },
    [secretToken, tenantId]
  );

  const getTotalEventCoachInfo = useCallback(
    async (sessionId) => {
      try {
        const request = api.ac.v5.coaching.sessions.$sessionId(sessionId).$get({
          headers: {
            Authorization: secretToken,
          },
        });
        const result = await request?.process();
        return result?.eventList || [];
      } catch (err) {}
    },
    [secretToken]
  );

  const fetchAllCoachingSessions = useCallback(async (startTime, endTime, secretToken, status) => {
    const allItems = [];
    try {
      let offset = 0;
      const limit = 10;
      let hasMore = true;
      while (hasMore) {
        const request = api.ac.v5.coaching.sessions.$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
            fromTimestamp: startOfTheDay(startTime),
            coachingByTarget: 'ENDPOINT',
            coachingSessionStatus: status,
            toTimestamp: endOfTheDay(endTime),
          },
        });
        await request.process();
        const items = request.result?.coachingSessions || [];
        allItems.push(...items);
        offset += limit;
        hasMore = items.length === limit;
      }
      return allItems;
    } catch (ex) {
      return allItems;
    }
  }, []);

  const getCoachingInformation = useCallback(async () => {
    setLoading(true);
    const items = await fetchAllCoachingSessions(startTime, endTime, secretToken, 'STARTED');
    const createdItems = await fetchAllCoachingSessions(startTime, endTime, secretToken, 'CREATED');
    const updatedItems = await Promise.all(
      [...items, ...createdItems]?.map(async (item) => {
        const scoreCurrent = await getCameraScore(
          item?.endpointId,
          subDays(new Date().getTime(), 7).getTime(),
          new Date().getTime()
        );

        const totalCoachedEvents = await getTotalEventCoachInfo(item?.sessionId);

        const coachInfo = await getCoachInfo(item?.coachUserId);

        const getCameraInfo = await getCameraDetails(item?.endpointId);

        const newItem = {
          ...item,
          scoreCurrent: scoreCurrent,
          totalCoachedEvents: totalCoachedEvents,
          coachInfo: coachInfo,
          camera: getCameraInfo,
        };

        return newItem;
      })
    );
    setCoachingData(updatedItems);
    setFilteredData(updatedItems);
    return updatedItems;
  }, [
    endTime,
    getCameraDetails,
    getCameraScore,
    getCoachInfo,
    getTotalEventCoachInfo,
    fetchAllCoachingSessions,
    secretToken,
    startTime,
  ]);

  useEffect(() => {
    const fetchHistoryData = async () => {
      await getCoachingInformation();
      setLoading(false);
    };
    fetchHistoryData();
  }, [getCoachingInformation]);

  useEffect(() => {
    if (isEmpty(query?.camera) && isEmpty(query?.serial)) {
      setFilteredData(coachingData); // Or set an initial state if needed
      return;
    }

    if (!isEmpty(query?.camera) || !isEmpty(query?.serial)) {
      setLoading(true);
      const filteredData = coachingData?.filter(
        (item) =>
          item.camera.label === query?.camera || item?.camera.deviceSerialNumber === query?.serial
      );
      setFilteredData(filteredData || []);
      setLoading(false);
    }
  }, [coachingData, query?.camera, query?.serial]);

  return (
    <Box mt={2}>
      <PaginatedTableContextProvider>
        <ContinuationTokenTable
          results={filteredData}
          loading={loading}
          // @ts-ignore
          columns={COACHING_PENDING_TABLE_COLUMNS}
          onReload={() => getCoachingInformation()}
          height="80vh"
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
