import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CustomSelect } from '@/web/@components/CustomForm';
import { sortBy } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export function TenantSelection(props) {
  const { property } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const getTenantDetails = useCallback(async () => {
    const request = api.ac.v5.tenant.$tenantId(tenantId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    await request.process();
    return request.result;
  }, [secretToken, tenantId]);

  const { result: tenants = [], loading: tenantsLoading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      /** @type {Array<TenantInfo>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
          signal: signal,
          headers: {
            Authorization: secretToken,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result;
        results.push(...result);
        break;
      }
      const currentTenant = await getTenantDetails();
      results.push(currentTenant);
      return sortBy(results, ['tenantStatus', (item) => item.tenantName.toLowerCase()]);
    },
    [getTenantDetails]
  );

  return (
    <CustomSelect
      name={property}
      loading={tenantsLoading}
      size="small"
      placeholder={'Select Tenant'}
      options={tenants || []}
      getLabel={'tenantName'}
      rules={{ required: true }}
    />
  );
}
