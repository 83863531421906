import { Autocomplete, TextField } from '@mui/material';

/**
 * @typedef {object} DateSelectionProps
 * @property {number} dateInt
 * @property {function} setDateInt
 * @property {boolean} [isDisabled]
 */

/**
 * @param {DateSelectionProps} props
 */

export function GroupSettingsDateSelection({ dateInt, setDateInt, isDisabled }) {
  const options = [...Array(32).keys()].slice(1).map((i) => {
    return { label: `${i}`, value: i };
  });

  return (
    <Autocomplete
      disabled={isDisabled}
      fullWidth
      disablePortal
      disableClearable
      id="combo-box-demo"
      size="medium"
      options={options}
      onChange={(e, newValue) => {
        setDateInt(typeof newValue === 'string' ? Number(newValue) : newValue?.value);
      }}
      value={{ label: `${dateInt}`, value: dateInt }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
}
