import { createSelector } from '@reduxjs/toolkit';

/** @param {StoreState} state */
export const selectHub = (state) => state.hub;

export const selectHubLoading = createSelector(selectHub, (hub) => hub.loading);
export const selectHubFailure = createSelector(selectHub, (hub) => hub.failure);

export const selectDeviceId = createSelector(selectHub, (hub) => hub.deviceId);
export const selectSessionId = createSelector(selectHub, (hub) => hub.sessionId);

export const selectHubConnectionId = createSelector(selectHub, (hub) => hub.connectionId);
export const selectHubConnected = createSelector(selectHub, (hub) => Boolean(hub.connectionId));
