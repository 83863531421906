import api from '@/api';
import { SmartCache } from '@/utils/caching/smart-cache';
import { throttle, uniq } from 'lodash';
import { store } from '../store';
import { selectSecretToken } from '../store/auth';
import { DEFAULT_ACCESS_TOKEN } from '@/config';

/** @typedef {GetGeoInformationResponse & {latitude: number, longitude: number}} GeoInfo */

/** @type {SmartCache<GeoInfo>} */
const geoCache = new SmartCache('geo-location', 30 * 24 * 3600 * 1000);
/** @type {SmartCache<GetIPLocationResponse>} */
const ipCache = new SmartCache('ip-info', 30 * 24 * 3600 * 1000);

/**
 * Fetch and cache the geoLocation information
 * @param {number} latitude
 * @param {number} longitude
 * @param {boolean} [refresh] Force refresh cache
 * @returns {Promise<GeoInfo>}
 */
export async function fetchGeoInfo(latitude, longitude, refresh) {
  if (!latitude || !longitude) return;
  const key = `${latitude.toFixed(6)}_${longitude.toFixed(6)}`;
  try {
    if (!refresh) {
      const data = await geoCache.getItem(key);
      if (data) return data;
    }
    const state = store.getState();
    const accessToken = selectSecretToken(state);
    const request = api.ac.ip_locator.geo_information.$get({
      params: {
        accessToken,
        latitude,
        longitude,
      },
    });
    const data = await request.process();
    if (!data) return null;
    const geoInfo = { ...data, latitude, longitude };
    geoCache.setItem(key, geoInfo);
    return geoInfo;
  } catch (err) {
    console.error('Failed to fetch Geo-Info for location', latitude, longitude, err);
    return null;
  }
}

/**
 * Fetch and cache the my ip-location info from IP address
 * @param {string} ip
 * @param {boolean} [refresh] Force refresh cache
 * @returns {Promise<GetIPLocationResponse>}
 */
export async function fetchIpLocation(ip, refresh) {
  if (!ip) return null;
  try {
    if (!refresh) {
      const data = await ipCache.getItem(ip);
      if (data) return data;
    }
    const state = store.getState();
    const accessToken = selectSecretToken(state);
    const request = api.ac.ip_locator.ip_location.$get({
      params: {
        ip,
        accessToken: accessToken || DEFAULT_ACCESS_TOKEN,
      },
    });
    const data = await request.process();
    if (!data) return null;
    ipCache.setItem(ip, data);
    return data;
  } catch (err) {
    console.error('Failed to fetch Geo-Info from IP', ip, err);
    return null;
  }
}

/**
 * Fetch and cache the my geo-location info from IP address
 * @param {string} ip
 * @param {boolean} [refresh] Force refresh cache
 * @returns {Promise<GeoInfo>}
 */
export async function fetchGeoInfoByIP(ip, refresh) {
  const ipInfo = await fetchIpLocation(ip, refresh);
  if (!ipInfo) return null;
  localStorage.setItem('COUNTRY_CODE', ipInfo?.countryCode);
  const key = `${ipInfo.latitude.toFixed(6)}_${ipInfo.longitude.toFixed(6)}`;
  const data = await geoCache.getItem(key);
  if (data) return data;
  /** @type {GeoInfo} */
  const geoInfo = {
    latitude: ipInfo.latitude,
    longitude: ipInfo.longitude,
    city: ipInfo.city,
    country: ipInfo.country,
    state: ipInfo.state,
    street: ipInfo.place,
    fullAddress: uniq([ipInfo.place, ipInfo.city, ipInfo.state, ipInfo.country])
      .filter((x) => x?.trim())
      .join(', '),
  };
  geoCache.setItem(key, geoInfo);
  return geoInfo;
}

/**
 * Fetch and cache the my current IP address
 * @param {boolean} [refresh] Force refresh cache
 * @returns {Promise<string>}
 */
export async function fetchMyIP(refresh) {
  const ipCacheKey = 'MY_IP_ADDRESS';
  const data = JSON.parse(localStorage.getItem(ipCacheKey) || '{}') || {};
  if (!refresh && data.ip && data.expiresAt > Date.now()) {
    return data.ip;
  }
  const resp = await fetch('https://ipapi.co/ip/');
  const ip = await resp.text();
  localStorage.setItem(
    ipCacheKey,
    JSON.stringify({
      ip,
      expiresAt: Date.now() + 24 * 3600 * 1000,
    })
  );
  return ip;
}

/**
 * Fetch and cache the my current geo-location from my-ip
 * @param {boolean} [refresh] Force refresh cache
 * @returns {Promise<GetGeoInformationResponse>}
 */
export const fetchMyGeoInfo = throttle(async (refresh) => {
  try {
    const ip = await fetchMyIP(refresh);
    return await fetchGeoInfoByIP(ip, refresh);
  } catch (err) {
    console.error(err);
  }
}, 1500);
