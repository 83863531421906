import { GOOGLE_CLIENT_ID } from '@/config';
import { CustomLogger } from '@/utils/logger';
import { useCallback, useEffect, useState } from 'react';

/**
 * @typedef {object} LoginResponse
 * @property {string} email
 * @property {string} idToken
 * @property {string} picture
 */

const logger = new CustomLogger('GoogleAuth');

/**
 * A hook to provide login and logout with Google OAuth Client.
 * @returns {{ loading: boolean, signIn: () => Promise<LoginResponse> }}
 */
export function useGoogleAuth() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.gapi) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/platform.js';
      script.async = true;
      script.defer = true;
      script.addEventListener('load', () => {
        logger.debug('Loaded GAPI script', window.gapi);
        window.gapi.load('auth2', () => {
          logger.debug('Loaded auth2', window.gapi.auth2);
          setLoading(false);
        });
      });
      script.addEventListener('error', (err) => {
        logger.error('Failed to load GAPI script', err);
      });
      document.body.append(script);
      return () => script.remove();
    } catch (err) {
      setLoading(false);
      logger.error('Failed to process GAPI script', err);
    }
  }, []);

  const signIn = useCallback(async () => {
    if (!window.gapi) {
      throw new Error('No google auth library');
    }
    try {
      setLoading(true);
      const auth2 = await new Promise((resolve, reject) =>
        window.gapi.auth2
          .init({
            client_id: GOOGLE_CLIENT_ID,
            plugin_name: 'chat',
          })
          .then(resolve)
          .catch(reject)
      );
      logger.debug('Initialized GoogleAuth instance', auth2);
      // if (auth2.isSignedIn.get()) {
      //   logger.info('Signing out');
      //   await auth2.signOut();
      // }
      logger.info('Signing in');
      const user = await auth2.signIn({
        ux_mode: 'popup',
        scope: 'email profile',
        prompt: 'select_account',
      });
      logger.log('Basic profile', user);
      const profile = user.getBasicProfile();
      const email = profile.getEmail();
      const picture = profile.getImageUrl();
      const idToken = user.getAuthResponse(true).id_token;
      if (!email || !idToken) {
        throw new Error('Failed to read basic profile');
      }
      return { email, idToken, picture };
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, signIn };
}
