import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { filterAIModelsInputData } from '@/utils/ai-models';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { FileUploadProgress } from '@/web/@components/FileUploadProgress';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AIModelForm } from '../../@components/AIModelForm';
import { uploadAiModelFile } from '../../utils';

export function AIModelEditPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: editingModelId } = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);
  /** @type {StateVariable<boolean>} */
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  /** @type {StateVariable<AIModelV2WithAccelerator>} */
  const [aiModel, setAiModel] = useState(null);
  /** @type {StateVariable<AbortController>} */
  const [submitting, setSubmitting] = useState();

  // Navigate AI Models list page while tenant change
  useEffect(() => {
    return () => navigate('/administration/ai/ai-models');
  }, [tenantId, navigate]);

  useEffect(() => {
    if (!editingModelId) return;
    const request = api.ac.v2.repository.aimodel.$get({
      params: {
        secretToken,
        modelId: editingModelId,
      },
    });
    setLoading(true);
    request
      .process()
      .then(setAiModel)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [secretToken, editingModelId]);

  useEffect(() => {
    if (!aiModel) return;
    setBreadcrumbTitle(aiModel.name);
  }, [aiModel, setBreadcrumbTitle]);

  /** @type {import('../../@components/AIModelForm').AIModelFormProps['onSubmit']} */
  const handleUpdateModel = async (fields) => {
    const aborter = new AbortController();
    setSubmitting((prev) => {
      prev?.abort();
      return aborter;
    });

    try {
      setPercentage(0);
      setProgressModalOpen(true);

      /** @type {AIModelV2} */
      const body = { ...aiModel };
      body.properties = aiModel.properties || {};

      body.properties.modelType = fields.modelType;
      body.properties.vendor = fields.vendor?.trim() || '';
      body.properties.modelParams = JSON.stringify(
        fields.modelParams.map(({ id, ...keepAttrs }) => keepAttrs)
      );

      body.inputLayer = fields.inputLayer?.trim() || '';
      body.outputLayer = fields.outputLayer?.trim() || '';
      const formattedInputSources = filterAIModelsInputData(fields.inputSources);
      body.inputSources = formattedInputSources?.map((x, i) => ({
        inputDescription: { id: i + 1 + '', ...x },
      }));
      body.outputSources = fields.outputSources?.map((source) => ({
        ...source,
        properties: [],
      }));
      if (fields.aiAccelerator) {
        body.aiAccelerators = [fields.aiAccelerator.codeName];
      } else if (aiModel.aiAcceleratorList?.length && aiModel.aiAcceleratorList[0]) {
        body.aiAccelerators = [aiModel.aiAcceleratorList[0].codeName];
      }

      body.name = fields.name;
      body.version = fields.version;
      body.description = fields.description;

      if (fields.modelFile) {
        await uploadAiModelFile(fields.modelFile, body, setPercentage, aborter.signal);
      }

      const request = api.ac.v2.repository.aimodel.$put({
        data: body,
        params: {
          secretToken,
          forceUpdate: true,
          tenantId,
          type: 'DEFAULT',
          version: body.version,
        },
      });
      await request.process();
      toastSuccess('Success', 'AI Model updated successfully');
      navigate('/administration/ai/ai-models');
    } catch (err) {
      if (!aborter.signal.aborted) {
        console.error('Failed to create', err);
        if (err.response?.data?.status === 'ALREADY_EXISTS_VERSION') {
          toastWarning('Error', 'This AI Model version already exists.');
        } else {
          toastWarning('Error', 'Could not create AI Model.');
        }
      }
    } finally {
      setSubmitting(undefined);
      setProgressModalOpen(false);
    }
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box m={2.5}>
      {progressModalOpen && (
        <FileUploadProgress
          percentage={percentage}
          headerText="Update AI Model"
          progressText={'Uploading file...'}
          onClose={() => {
            submitting?.abort();
            setProgressModalOpen(false);
          }}
        />
      )}
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['ai.model.update.page.title'])}
      </Typography>
      <AIModelForm aiModel={aiModel} onSubmit={handleUpdateModel} disabled={Boolean(submitting)} />
    </Box>
  );
}
