import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export function CameraSchemes() {
  const { cameraId } = useContext(CameraDetailsContext);

  const navigate = useNavigate();

  const groupItemsByKey = (items, key) => {
    const groupedData = items.reduce((acc, item) => {
      const { targetLevel, ...rest } = item;
      if (!acc[item[key]]) {
        acc[item[key]] = [];
      }

      acc[item[key]].push(rest);
      return acc;
    }, {});
    return groupedData;
  };

  const { result: schemes, loading } = useFutureLoader(async ({ secretToken }) => {
    const request = api.ac.v5.scheme.deploy.endpoint.$endpointId(cameraId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    const result = await request.process();

    return groupItemsByKey(result?.values, 'targetLevel');
  }, []);

  if (loading) {
    return <CenterBox fullView={true} children={<CircularProgress />} />;
  }

  const groupItemByTypes = (items) => {
    return Object.values(groupItemsByKey(items, 'targetId')).map((schemes) => (
      <Box pt={1.5}>
        <Typography variant="h6" fontSize="0.90rem" fontWeight={600}>
          {schemes?.at(0)?.targetName}
        </Typography>
        <Box pt={1}>
          {schemes?.map((scheme) => (
            <Typography key={scheme?.schemeId} variant="body1" fontSize="0.875rem" pt={0.8}>
              {scheme?.schemeName}
            </Typography>
          ))}
        </Box>
      </Box>
    ));
  };

  return (
    <Box p={2.5} pb={10}>
      <Grid container justifyContent="flex-end">
        <Button variant="contained" size="small" onClick={() => navigate('effective')}>
          View Effective Scheme
        </Button>
      </Grid>

      {!loading && isEmpty(schemes) && (
        <CenterBox fullView>
          <IconMessageBox
            size="128px"
            src="/images/empty/no-search-results.svg"
            message="No Scheme Found"
          />
        </CenterBox>
      )}

      {schemes?.TENANT?.length > 0 && (
        <Box display="grid" pb={3}>
          <Typography variant="subtitle2" fontSize="1rem">
            Tenants
          </Typography>
          <Divider />
          {groupItemByTypes(schemes?.TENANT)}
        </Box>
      )}

      {schemes?.GROUP?.length > 0 && (
        <Box display="grid" pb={3}>
          <Typography variant="subtitle2" fontSize="1rem">
            Camera Groups
          </Typography>
          <Divider />
          {groupItemByTypes(schemes?.GROUP)}
        </Box>
      )}

      {schemes?.ENDPOINT?.length > 0 && (
        <Box display="grid" pb={3}>
          <Typography variant="subtitle2" fontSize="1rem">
            Cameras
          </Typography>
          <Divider />
          {groupItemByTypes(schemes?.ENDPOINT)}
        </Box>
      )}
    </Box>
  );
}
