import { orderBy } from 'lodash';

/**
 * Sort a list by key and order
 * @template T
 * @param {Array<T>} data
 * @param {string} orderByKey
 * @param {import('@mui/material').SortDirection} order
 * @param {string} fallbackText
 * @returns
 */

export function sortDataByKey(data, orderByKey, order = 'asc', fallbackText = '') {
  return orderBy(
    data || [],
    [(item) => (orderByKey ? item[orderByKey] : fallbackText + '')],
    [order]
  );
}
