/**
 * @typedef {object} Point
 * @property {number} x
 * @property {number} y
 */

import { sortedUniqBy } from 'lodash';

const EARTH_RADIUS_METER = 6371000;
const IDLE_SPEED_THRESHOLD = 1; // Define the speed threshold for idle in km/h
const IDLE_TIME_THRESHOLD = 20000; // Define the minimum duration of idle time in milliseconds

/**
 * Checking Latitude validity
 * @param {number} lat
 * @returns {boolean}
 */
export function isValidLatitude(lat) {
  return lat && isFinite(lat) && Math.abs(lat) <= 90;
}

/**
 * Checking Longitude validity
 * @param {number} lng
 * @returns  {boolean}
 */
export function isValidLongitude(lng) {
  return lng && isFinite(lng) && Math.abs(lng) <= 180;
}

/**
 * Calculate connected edges with the Geo Points.
 * @param {Array<GeoWithTimestamp>} geoPoints
 * @param {number} tolerance
 */
export function generateEdges(geoPoints, tolerance) {
  if (geoPoints.length < 2) {
    return [];
  }
  /** @type {Array<{connected: boolean, path: Array<GeoWithTimestamp>}>} */
  const edges = [];
  for (let i = 1; i < geoPoints.length; i++) {
    const left = geoPoints[i - 1];
    const right = geoPoints[i];
    const connected = right.timestamp - left.timestamp <= tolerance;
    const edge = { path: [left, right], connected };
    if (edges.length) {
      const last = edges[edges.length - 1];
      if (last.connected === connected && last.path[last.path.length - 1] === left) {
        edges.pop();
        edge.path = [...last.path, right];
      }
    }
    edges.push(edge);
  }
  return edges;
}

/**
 * Calculate connected edges with the Geo Points.
 * @param {Array<GeoWithTimestamp>} geoPoints
 * @param {number} tolerance
 */
export function filterBySpeed(geoPoints, tolerance) {
  const filtered = [];
  for (let i = 0; i < geoPoints.length; ++i) {
    const x = geoPoints[i];
    if (!filtered.length) {
      filtered.push(x);
      continue;
    }
    const y = filtered[filtered.length - 1];
    const time1 = Math.abs(x.timestamp - y.timestamp);
    if (!time1) continue;
    // if speed(x...y) < tolerance, take x
    const speed1 = (3.6e6 * calculateDistance(x.geo.lat, x.geo.lon, y.geo.lat, y.geo.lon)) / time1;
    if (speed1 < tolerance) {
      filtered.push(x);
      continue;
    }
    // otherwise, discard x if it is the last point
    if (i + 1 === geoPoints.length) continue;
    const z = geoPoints[i + 1];
    // if speed(x...z) < tolerance, remove y, take x, z
    const time2 = Math.abs(x.timestamp - z.timestamp);
    const speed2 = (3.6e6 * calculateDistance(x.geo.lat, x.geo.lon, z.geo.lat, z.geo.lon)) / time2;
    if (speed2 < tolerance) {
      filtered[filtered.length - 1] = x;
      filtered.push(z);
      i++;
    }
    // otherwise, discard x, and calculate for z in next iteration
  }
  return filtered;
}

/**
 * Calculate the geographic distance between two points and
 * returns a formatted string with SI metric unit as suffix.
 * @param {Point} start
 * @param {Point} stop
 * @param {{decimal?: number, min?: 'nm'|'µm'|'mm'|'cm'|'m'|'km'}} [options]
 * @returns {string}
 */
export function formatDistance(start, stop, options) {
  // https://stackoverflow.com/a/365853/1583052
  const d2r = Math.PI / 180;
  const dy = (stop.y - start.y) * d2r;
  const dx = (stop.x - start.x) * d2r;
  const sindx = Math.sin(dx / 2.0);
  const sindy = Math.sin(dy / 2.0);
  const cosx1 = Math.cos(start.x * d2r);
  const cosx2 = Math.cos(stop.x * d2r);
  const a = sindx * sindx + cosx1 * cosx2 * sindy * sindy;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = EARTH_RADIUS_METER * c;

  const { min = 'm', decimal = 0 } = options || {};
  const suffixes = ['nm', 'µm', 'mm', 'cm', 'm', 'km'];
  const multiples = [1, 1e3, 1e6, 1e7, 1e9, 1e12];

  let i = suffixes.indexOf(min) + 1;
  let nm = Math.round(d * 1e9) + 1e-8;
  while (nm > multiples[i] && i < multiples.length) {
    i++;
  }
  i--;

  return `${(nm / multiples[i]).toFixed(decimal)} ${suffixes[i]}`;
}

/**
 * Calculate Distance From Two Geo Points.
 *
 * This function calculates the distance (in kilometers) between two geo points using the Haversine formula.
 * @param {number} lat1 - Latitude of the first geo point in degrees.
 * @param {number} lon1 - Longitude of the first geo point in degrees.
 * @param {number} lat2 - Latitude of the second geo point in degrees.
 * @param {number} lon2 - Longitude of the second geo point in degrees.
 * @returns {number} The distance between the two geo points in kilometers.
 * @example
 * // Usage example:
 * const lat1 = 40.7128;
 * const lon1 = -74.0060;
 * const lat2 = 34.0522;
 * const lon2 = -118.2437;
 * const distance = calculateDistance(lat1, lon1, lat2, lon2);
 * console.log(distance); // Output: 3944.8642184739416 (in kilometers)
 */
export function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Earth's radius in kilometers
  // Convert latitude and longitude from degrees to radians
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lon1Rad = (lon1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lon2Rad = (lon2 * Math.PI) / 180;
  // Haversine formula to calculate distance between two points
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;
  return distance;
}

/**
 * Calculate Vehicle Speed by Geo Location.
 *
 * This function calculates the vehicle speed in kilometers per hour (km/h) based on sorted geo location data.
 * @param {GeoWithTimestamp[]} geoPoints - [sorted] An array of objects representing sorted geo location data with timestamp, latitude, and longitude.
 * @returns {{
 *   averageSpeed: number,
 *   totalDistance: number
 *   points: {ts: any, speed: number, distance: number}[],
 * }} An object containing:
 * - `newPoints`: An array of objects with timestamp and speed representing the calculated speed for each point.
 * - `vehicleAvgSpeed`: The average speed of the vehicle in km/h.
 * - `totalDistance`: The total distance traveled by the vehicle in kilometers.
 * @example
 * // Usage example:
 * const geoPoints = [
 *   { timestamp: 1627915900000, lat: 40.7128, lon: -74.0060 },
 *   { timestamp: 1627916000000, lat: 34.0522, lon: -118.2437 },
 *   // More geo points...
 * ];
 * const speedData = calculateSpeed(geoPoints);
 * console.log(speedData.newPoints); // [{ timestamp: 1627915900000, speed: 60 }, { timestamp: 1627916000000, speed: 100 }, ...]
 * console.log(speedData.vehicleAvgSpeed); // 80.5
 * console.log(speedData.totalDistance); // 150.2 (in kilometers)
 */
export function calculateSpeed(geoPoints) {
  let totalTime = 0;
  let totalDistance = 0;

  const newPoints = [];
  for (let i = 1; i < (geoPoints?.length ?? 0); i++) {
    const { geo: geo1, timestamp: time1 } = geoPoints[i - 1];
    const { geo: geo2, timestamp: time2 } = geoPoints[i];

    const { lat: lat1, lon: lon1 } = geo1;
    const { lat: lat2, lon: lon2 } = geo2;

    const delta = time2 - time1;
    const distance = calculateDistance(lat1, lon1, lat2, lon2);
    const speed = delta ? 3.6e6 * (distance / delta) : 0;

    totalTime += delta;
    totalDistance += distance;
    newPoints.push({ ts: time1, distance, speed });
  }

  const vehicleAvgSpeed = totalTime ? 3.6e6 * (totalDistance / totalTime) : 0;
  return {
    points: newPoints,
    totalDistance: totalDistance,
    averageSpeed: vehicleAvgSpeed,
  };
}

/**
 * Calculate Idle Time for Vehicle.
 *
 * This function calculates the idle time for a vehicle based on its speed data.
 * @param {{ts: any, speed: number, distance: number}[]} data - An array of objects representing the vehicle's speed data.
 * @param {TripDetailsResponse} [trip] - The current trip status of the vehicle (optional).
 * @param {boolean} [isOnline] - Device current status (optional).
 * @returns {number} The idle time in milliseconds.
 * @description
 * This function calculates the total idle time from a dataset of speed and timestamp values,
 * with speed calculated from geo points. Idle time is defined as periods when speed is at or below 1 km/h (IDLE_SPEED_THRESHOLD)
 * for at least 20 seconds(IDLE_TIME_THRESHOLD). The function iterates through the data, tracking start and end times of idle periods,
 * and sums durations meeting the threshold. It also checks for any ongoing idle time if the camera is online
 * and the trip hasn't ended. The result is the total idle time in milliseconds, accounting for all qualifying idle periods.
 */
export function calculateIdleTime(data, trip, isOnline = false) {
  if (!data?.length) return 0;

  let idleTime = 0;
  let startTime = 0;

  // Calculate idle time using a sliding window
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.speed <= IDLE_SPEED_THRESHOLD) {
      if (!startTime) startTime = item.ts;
    } else {
      if (startTime) {
        const duration = item.ts - startTime;
        if (duration >= IDLE_TIME_THRESHOLD) {
          idleTime += duration;
        }
        startTime = 0;
      }
    }
  }

  // Check for any remaining idle time at the end
  if (startTime) {
    const duration = data[data.length - 1].ts - startTime;
    if (duration >= IDLE_TIME_THRESHOLD) {
      idleTime += duration;
    }
  }

  // Idle time when camera is online
  if (isOnline && trip.tripStatus !== 'ENDED') {
    let lastItemTimestamp = data[data.length - 1].ts;
    const duration = Date.now() - lastItemTimestamp;
    if (duration >= IDLE_TIME_THRESHOLD) {
      idleTime += duration;
    }
  }

  return idleTime;
}

/**
 * @typedef {object} TripStats
 * @property {number} ts
 * @property {number} idleTime
 * @property {number} tripDuration
 * @property {number} totalDistance
 * @property {number} lastUpdateTime
 */

/**
 * Update the TripCachedInfo item
 * @param {TripDetailsResponse} trip
 * @param {boolean} [isOnline]
 * @returns {TripStats}
 */
export function buildTripStats(trip, isOnline = false) {
  const geoPoints = sortedUniqBy(
    (trip.geoPoints || []).flatMap((x) => x),
    'timestamp'
  ).reverse();
  const lastUpdateTime = geoPoints[geoPoints.length - 1]?.timestamp;
  const { points, totalDistance } = calculateSpeed(geoPoints);
  const tripDuration = geoPoints[geoPoints.length - 1]?.timestamp - geoPoints[0]?.timestamp;
  const idleTime = calculateIdleTime(points, trip, isOnline);
  return {
    ts: Date.now(),
    idleTime,
    tripDuration,
    totalDistance,
    lastUpdateTime,
  };
}
