import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { HealthTagItem } from '@/web/smarter-ai/monitoring/camera-health/@components/HealthTagList/HealthTagItem';
import { Box } from '@mui/material';
import { sortBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

/**
 * @typedef {object} CameraHealthTagListProps
 * @property {Array<AssignedHealthTagResponse>} cameraTags
 * @property {(tagId: number) => Promise<any>} onRemove
 */

/** @param {CameraHealthTagListProps} props */
export function CameraHealthTagList(props) {
  const { cameraTags, onRemove } = props;
  const { camera } = useContext(CameraDetailsContext);

  /** @type {import('react').Ref<HTMLElement>} */
  const containerRef = useRef();

  /** @type {StateVariable<number>} */
  const [updating, setUpdating] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [moveMove, setMoveMode] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [showLeft, setShowLeft] = useState(false);
  /** @type {StateVariable<boolean>} */
  const [showRight, setShowRight] = useState(false);

  const latestTags = useMemo(() => {
    if (!cameraTags?.length) return [];
    /** @type {{[key: string]: AssignedHealthTagResponse}} */
    const tags = {};
    for (const tag of cameraTags) {
      if (tags[tag.tagId] && tag.reportedTimestamp < tags[tag.tagId].reportedTimestamp) continue;
      tags[tag.tagId] = tag;
    }
    return sortBy(Object.values(tags), 'reportedTimestamp').reverse();
  }, [cameraTags]);

  const handleScroll = useCallback(() => {
    try {
      const div = containerRef.current;
      if (!div) return;
      const left = div.scrollLeft;
      const right = div.scrollWidth - (left + div.clientWidth);
      setShowLeft(left > 1);
      setShowRight(right > 1);
    } catch {}
  }, []);

  useEffect(() => {
    const tid = setTimeout(() => {
      requestAnimationFrame(handleScroll);
    }, 100);
    return () => clearTimeout(tid);
  }, [latestTags, handleScroll]);

  useEffect(() => {
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, [handleScroll]);

  /** @type {import('react').PointerEventHandler} */
  const handleMouseMove = (e) => {
    if (!moveMove) return;
    const div = containerRef.current;
    div.scrollLeft -= e.movementX;
  };

  /** @param {number} tagId */
  const handleRemove = async (tagId) => {
    try {
      setUpdating(tagId);
      await onRemove(tagId);
    } finally {
      setUpdating(null);
    }
  };

  if (!cameraTags?.length) {
    return null;
  }

  return (
    <Box
      position="relative"
      onPointerMove={handleMouseMove}
      onPointerDown={() => setMoveMode(true)}
      onPointerLeave={() => setMoveMode(false)}
      onPointerUp={() => setMoveMode(false)}
      sx={{
        cursor: 'grab',
        userSelect: 'none',
        height: '100%',
        maxWidth: { xs: '250px', md: '350px', xl: '600px' },
        padding: '0 20px',
        borderRight: '1px solid #E9EBF2',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        ref={containerRef}
        onScroll={handleScroll}
        overflow="scroll visible"
        display="flex"
        alignItems="center"
        fontSize="0.675rem"
        fontWeight="500"
        sx={{
          '&::-webkit-scrollbar': {
            height: 0,
            width: 0,
          },
        }}
      >
        {latestTags?.map((tag) => (
          <HealthTagItem
            key={tag.id}
            tag={tag}
            groupId={camera.groupId}
            onRemove={() => handleRemove(tag.tagId)}
            loading={updating === tag.tagId}
          />
        ))}
      </Box>
      {showLeft && (
        <Box
          style={{
            color: 'white',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: '25px',
            background: 'linear-gradient(90deg, #07070720 0%, #FFFFFF00 100%) no-repeat',
          }}
        />
      )}
      {showRight && (
        <Box
          style={{
            color: 'white',
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            width: '10px',
            background: 'linear-gradient(270deg, #07070720 0%, #FFFFFF00 100%) no-repeat',
          }}
        />
      )}
    </Box>
  );
}
