import { CustomInput } from '@/web/@components/CustomForm';
import { Box, Grid, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import { LANGUAGE_MENU_ITEMS } from '../../assets';
import { TtsPreviewButton } from '../TtsPreviewButton';

export function TtsSettingsForm(props) {
  const { ttsLanguage, setTtsLanguage, trigger, inheritance } = props;

  return (
    <Grid container spacing={2} alignItems="baseline" className="tts">
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle2">Voice Alert</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box>
          <CustomInput
            name="tts"
            label=""
            placeholder="Enter voice alert"
            InputProps={{
              // disabled: ttsLanguage !== 'TURN_OFF',
              readOnly: ttsLanguage === 'TURN_OFF',
              sx: { color: ttsLanguage === 'TURN_OFF' ? 'white' : '-moz-initial' },
              endAdornment: (
                <InputAdornment position="end">
                  <Box>
                    <Select
                      size="small"
                      value={ttsLanguage}
                      onChange={(e) => {
                        setTtsLanguage(e.target.value);
                      }}
                      autoWidth
                    >
                      {LANGUAGE_MENU_ITEMS?.map((item) => (
                        <MenuItem key={item?.value} value={item?.value} sx={{ fontSize: '12px' }}>
                          {item?.value === 'TURN_OFF' && ttsLanguage === 'TURN_OFF'
                            ? 'Turned Off'
                            : item?.value === 'EN' && ttsLanguage === 'EN'
                              ? 'Turned On'
                              : item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          {ttsLanguage !== 'TURN_OFF' && <TtsPreviewButton trigger={trigger} />}
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        {inheritance('tts')}
      </Grid>
    </Grid>
  );
}
