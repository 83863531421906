import api from '@/api';
import { IMPERIAL_COUNTRIES } from '@/assets/constants/unit-system';
import { store } from '@/store';
import { selectSecretToken } from '@/store/auth';
import { drawMultiBoundingBox } from '@/utils/bounding-box';
import { SmartCache } from '@/utils/caching/smart-cache';
import { TimestampStore } from '@/utils/collections/timestamp-store';
import { reportSnapshotStatistics } from '@/utils/events/snapshot-reporter';
import { downloadJsonFile } from '@/utils/file-utils';
import { CustomLogger } from '@/utils/logger';
import { fetchSensorFile } from '@/utils/sensors';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { ImageNotAvailable } from '@/web/@components/SnapshotImageSlider/ImageNotAvailable';
import { Box, CircularProgress } from '@mui/material';
import { isArray } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const logger = new CustomLogger('EventListItemThumbnail');

/** @type {SmartCache<EventPreview>} */
const previewRecordingCache = new SmartCache('event.preview.recording.cache', 15 * 60 * 1000, {
  name: 'Dashcam Player v3',
});
/** @type {SmartCache<any>} */
const previewMediaCache = new SmartCache('event.preview.media.cache', 15 * 60 * 1000, {
  name: 'Dashcam Player v3',
});

/**
 * @typedef {object} EventGridItemThumbnailPropsBase
 * @property {EventV5ResponseModel | EventV2} item
 * @property {EndpointDetailDto} [camera]
 * @property {string} source
 * @property {boolean} [disabled]
 * @property {boolean} [disablePreview]
 */

/** @typedef {EventGridItemThumbnailPropsBase& import('@mui/material').BoxProps} EventGridItemThumbnailProps */

/** @param {EventGridItemThumbnailProps} props */
export function EventGridItemThumbnail(props) {
  const { item, camera, source, disabled, disablePreview, onClick, ...boxProps } = props;

  /** @type {import('react').MutableRefObject<HTMLVideoElement>} */
  const videoRef = useRef();
  /** @type {import('react').MutableRefObject<HTMLCanvasElement>} */
  const canvasRef = useRef();
  /** @type {TimestampStore<VideoMetaData>} */
  const bboxes = useMemo(() => new TimestampStore(), []);

  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imperial, setImperial] = useState(false);

  /** @type {StateVariable<SensorData>} */
  const [sensorData, setSensorData] = useState(null);
  /** @type {StateVariable<MediaRecordingV5>} */
  const [previewItem, setPreviewItem] = useState(null);
  /** @type {StateVariable<number>} */
  const [previewPlayTime, setPreviewPlayTime] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [imageLoaded, setImageLoaded] = useState(false);
  /** @type {StateVariable<string>} */
  const [snapshotUrl, setSnapshotUrl] = useState(null);
  /** @type {StateVariable<number>} */
  const [snapshotLoadStart, setSnapshotLoadStart] = useState(Date.now());

  useEffect(() => {
    if (!item?.snapshots?.length) {
      setSnapshotUrl(null);
      return;
    }
    const snapshot = item.snapshots.find((x) => x.source === source);
    setSnapshotUrl(snapshot?.downloadUrl);
  }, [item?.snapshots, source]);

  useEffect(() => {
    if (!camera) return;
    const country = camera.country.toLowerCase();
    const exists = IMPERIAL_COUNTRIES.find(
      (x) => country.includes(x.toLowerCase()) || x.toLowerCase().includes(country)
    );
    setImperial(Boolean(exists));
  }, [camera]);

  /***********************************************************
   *                                                          *
   *      SNAPSHOT IMAGE AND BOUNDING BOX IN VIEW             *
   *                                                          *
   ***********************************************************/
  const { ref } = useInView({
    delay: 100,
    threshold: 0.1,
    initialInView: false,
    onChange: async (inView) => {
      if (!inView || sensorData || !item?.snapshots?.length) return;
      try {
        const sensorFileUrl = item.snapshots.find((x) => x.source === 'sensor')?.downloadUrl;
        if (!sensorFileUrl) throw new Error('empty');
        const data = await fetchSensorFile(sensorFileUrl);
        setSensorData(data);
      } catch (err) {
        logger.warn(`Could not get sensor data. ${err}`, item);
      }
    },
  });

  /***********************************************************
   *                                                          *
   *               PROCESS VIDEO WHILE ON HOVER               *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!item || !hovered || previewItem || disabled) return;

    const aborter = new AbortController();
    const signal = aborter.signal;

    const process = async () => {
      bboxes.clear();
      setLoading(true);
      try {
        const state = store.getState();
        const secretToken = selectSecretToken(state);

        // Get media recordings
        let result = await previewRecordingCache.getItem(item.id);
        if (!result) {
          const request = api.ac.v5.events.$eventId(Number(item?.id)).preview.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
          result = await request.process();
          await previewRecordingCache.setItem(item.id, result);
        }

        if (signal.aborted) return;
        if (!result) {
          throw new Error('Empty result');
        }

        // structure video item to preview
        let selectedRecord = {
          url: result?.videoPreviewUrl,
          startTimestamp: result?.videoStartTimestamp,
          endTimestamp: result?.videoEndTimestamp,
        };

        if (!selectedRecord?.url) {
          throw new Error('No video to preview');
        }

        // Get bounding boxes items to display
        const internal = async () => {
          try {
            if (signal.aborted) return;
            const cacheKey = `${item?.deviceId}_${item?.id}_${item?.eventTimestamp}_${item?.eventEndTimestamp}_metadata`;
            /** @type {Array<Array<VideoMetaData>>} */
            let data = await previewMediaCache.getItem(cacheKey);
            if (!data) {
              data = await downloadJsonFile(result?.videoMetaPreviewUrl, signal);
              await previewMediaCache.setItem(cacheKey, data);
            }
            if (!isArray(data)) return;
            if (signal.aborted) return;
            for (const items of data) {
              for (const item of items) {
                if (item.ts < selectedRecord.startTimestamp) continue;
                if (item.ts > selectedRecord.endTimestamp) continue;
                item.source = source;
                bboxes.insert(item.ts, item);
              }
            }
          } catch (err) {
            if (signal.aborted) return;
            logger.debug('Failed to process bbox', err);
          }
        };
        await internal();
        setPreviewItem(selectedRecord);
      } catch (err) {
        logger.debug('Failed to process recordings', err);
      } finally {
        setLoading(false);
      }
    };
    process();
    return () => aborter.abort();
  }, [item, previewItem, hovered, bboxes, source, disabled]);

  useEffect(() => {
    try {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (hovered || !imageLoaded) return;
      const data = sensorData?.VIDEO_META[source];
      if (!data) throw new Error(`No data for ${source}`);
      drawMultiBoundingBox(canvas, data, {
        imperial,
        scale: 4,
        fontSize: 7,
        clearCanvas: false,
      });
    } catch (err) {
      logger.warn(`Failed to draw bounding boxes. ${err}`, item, sensorData);
    }
  }, [sensorData, hovered, source, imageLoaded, imperial, item]);

  useEffect(() => {
    try {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      if (!canvas || !video?.readyState) return;
      const value = bboxes.nearestValue(previewPlayTime);
      if (!value || value.ts < 1000) return;
      const data = bboxes.search(value.ts, value.ts);
      drawMultiBoundingBox(canvas, data, {
        imperial,
        scale: 4,
        fontSize: 7,
        imagerWidth: video.videoWidth,
        imagerHeight: video.videoHeight,
      });
    } catch (err) {
      logger.warn(`Failed to draw bounding boxes. ${err}`, item, bboxes);
    }
  }, [previewPlayTime, bboxes, item, imperial]);

  /** @type {import('react').MouseEventHandler<HTMLDivElement>} */
  const handlePointerEnter = (e) => {
    if (disablePreview) return;
    if (!imageLoaded && videoRef.current) {
      videoRef.current.play().catch(console.error);
      return;
    }
    setHovered(true);
  };

  /** @type {import('react').MouseEventHandler<HTMLDivElement>} */
  const handlePointerLeave = (e) => {
    if (!imageLoaded && videoRef.current) {
      videoRef.current.pause();
      return;
    }
    requestAnimationFrame(() => setHovered(false));
  };

  /** @type {import('react').MouseEventHandler<HTMLDivElement>} */
  const handleOnClick = (e) => {
    handlePointerLeave(e);
    onClick(e);
  };

  /** @type {import('react').ReactEventHandler<HTMLVideoElement>} */
  const handleVideoPlay = (e) => {
    const start = Math.max(
      previewItem.startTimestamp,
      item.recordingStartTimestamp,
      item.eventTimestamp - 1000,
      bboxes.$keys[0] || 0
    );
    setPreviewPlayTime(start);
    e.currentTarget.currentTime = (start - previewItem.startTimestamp) / 1000;
    e.currentTarget.play().catch(console.error);
  };

  /** @type {import('react').ReactEventHandler<HTMLVideoElement>} */
  const handleTimeUpdate = (e) => {
    const ts = previewItem.startTimestamp + e.currentTarget.currentTime * 1000;
    setPreviewPlayTime(Math.ceil(ts));
  };

  const handleLoadStart = () => {
    setImageLoaded(false);
    setSnapshotLoadStart(Date.now());
  };

  /** @param {import('react').SyntheticEvent<HTMLImageElement> & {error?: boolean}} e */
  const handleSnapshotLoad = (e) => {
    setImageLoaded(!e.error);
    reportSnapshotStatistics(item, {
      source,
      url: snapshotUrl,
      success: !e.error,
      duration: Date.now() - snapshotLoadStart,
    });
  };

  return (
    <Box
      ref={ref}
      {...boxProps}
      className="event-grid-list-item-preview"
      onClick={disabled ? null : handleOnClick}
      onMouseEnter={disabled ? null : handlePointerEnter}
      onMouseLeave={disabled ? null : handlePointerLeave}
      sx={{
        width: '100%',
        aspectRatio: 16 / 9,
        overflow: 'hidden',
        position: 'relative',
        background: '#F5F5F5',
        borderRadius: '8px',
        cursor: disabled ? null : 'pointer',
        // boxShadow: hovered ? '0 0 5px rgba(0, 0, 0, 0.15)' : null,
        transition: hovered ? '0.2s ease-in' : null,
        ...boxProps?.sx,
      }}
    >
      {!item ? (
        <CircularProgress color="secondary" size="32px" />
      ) : (
        <BoxImage
          src={snapshotUrl}
          size="100%"
          objectFit="cover"
          fallback={<ImageNotAvailable />}
          onLoad={handleSnapshotLoad}
          onLoadStart={handleLoadStart}
        />
      )}

      {item && hovered && (
        <>
          {loading || !previewItem ? (
            <CenterBox absolute gap="5px">
              {loading ? <CircularProgress color="secondary" /> : null}
            </CenterBox>
          ) : (
            <video
              ref={videoRef}
              muted
              autoPlay
              poster={snapshotUrl}
              src={previewItem?.url}
              onTimeUpdate={handleTimeUpdate}
              onLoadedData={handleVideoPlay}
              onEnded={handleVideoPlay}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          )}
        </>
      )}

      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 10,
        }}
      />
    </Box>
  );
}
