import { T } from '@/assets/locales';
import { FleetLandingPage } from '.';
import { LiveTrackingPage } from './live-tracking';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <FleetLandingPage />,
  },
  {
    path: 'drivers',
    children: require('./drivers/routes').default,
    breadcrumb: T['navigation.device.drivers'],
    accessLevel: 'ADMIN',
  },
  {
    path: 'driver-groups',
    children: require('./driver-group/routes').default,
    breadcrumb: 'Driver Groups',
    accessLevel: 'ADMIN',
  },
  {
    path: 'my-fleet',
    children: require('./my-fleet/routes').default,
    breadcrumb: T['navigation.device.my.fleet'],
  },
  {
    path: 'driver-tags',
    children: require('./driver-tags/routes').default,
    breadcrumb: 'Driver Tags',
    accessLevel: 'ADMIN',
  },
  {
    path: 'live-tracking',
    element: <LiveTrackingPage />,
    breadcrumb: T['navigation.fleet.live.tracking'],
    accessLevel: 'USER',
  },
];

export default routes;
