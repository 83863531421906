import { CheckCircle, Circle, Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SERVER_ENVIRONMENTS } from '../../@assets/environments';

/**
 * @typedef {object} CameraEnvironmentModalProps
 * @property {string} [endpointName]
 * @property {string} [baseUrl]
 * @property {() => any} [onCancel]
 * @property {(value: string) => any} [onSubmit]
 */

/** @param {CameraEnvironmentModalProps} props */
export function CameraEnvironmentModal(props) {
  const { endpointName, baseUrl, onCancel, onSubmit } = props;

  /** @type {StateVariable<string>} */
  const [selectedItem, setSelectedItem] = useState();

  const handleSubmit = () => {
    onSubmit && onSubmit(selectedItem);
  };

  useEffect(() => {
    let defaultItem;
    defaultItem = SERVER_ENVIRONMENTS.find((item) => item.value === baseUrl)?.value;
    if (!defaultItem) {
      defaultItem = SERVER_ENVIRONMENTS[0].value;
    }
    setSelectedItem(defaultItem);
  }, [baseUrl]);

  return (
    <Dialog open onClose={onCancel} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>Camera Environment</Box>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>{`Please select the environment for ${endpointName}`}</Typography>
        <FormControl>
          <RadioGroup row>
            {SERVER_ENVIRONMENTS.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={
                  <Radio
                    sx={{
                      '& .checkbox': {
                        padding: '1px',
                        fontSize: '1.375rem',
                        borderRadius: '50%',
                        background: '#ffffff',
                        border: '1px solid #cfd8e5',
                      },
                    }}
                    checked={selectedItem === item.value}
                    checkedIcon={<CheckCircle htmlColor="#608ac3" className="checkbox" />}
                    icon={<Circle htmlColor="#CFD8E5" className="checkbox" />}
                    onChange={() => setSelectedItem(item.value)}
                  />
                }
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit} autoFocus variant="contained" disabled={!selectedItem}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
