import {
  BOX_LIST_ICON,
  BOX_SCHEME_ICON,
  BOX_TOOLS_ICON,
  CAMERA_SETTINGS_ICON,
  CONTROLS_ICON,
  DIAGNOSTICS_ICON,
  PRESENCE_ICON,
  SENSORS_ICON,
  SOFTWARES_ICON,
  USAGES_ICON,
  USERS_ICON,
} from '@/assets/constants/images';

/** @type {Array<PageCardItem>} */
export const DEVICE_MANAGE_CARD_ITEMS = [
  {
    name: 'Users',
    image: USERS_ICON,
    color: '#EFF3F7',
    link: 'users',
    components: [
      {
        name: 'Browse Users',
        image: BOX_LIST_ICON,
        link: 'users',
      },
    ],
  },
  {
    name: 'Softwares',
    image: SOFTWARES_ICON,
    color: '#F5F2EC',
    link: 'softwares',
    components: [
      {
        name: 'Browse Softwares',
        image: BOX_LIST_ICON,
        link: 'softwares',
      },
    ],
  },
  {
    name: 'Camera Settings',
    image: CAMERA_SETTINGS_ICON,
    color: '#EDEDED',
    link: 'settings',
    components: [
      {
        name: 'Manage Settings',
        image: BOX_TOOLS_ICON,
        link: 'settings',
      },
      {
        name: 'Schemes',
        image: BOX_SCHEME_ICON,
        link: 'schemes',
      },
    ],
  },
  {
    name: 'Diagnostics',
    image: DIAGNOSTICS_ICON,
    color: '#F7F5EF',
    link: 'diagnostics-logs',
    accessLevel: 'SUPER_ADMIN',
    components: [
      {
        name: 'Browse Diagnostics Logs',
        image: BOX_LIST_ICON,
        link: 'diagnostics-logs',
      },
      {
        name: 'Browse OTA Logs',
        image: BOX_LIST_ICON,
        link: 'ota-logs',
        accessLevel: 'SMARTER_AI_ADMIN',
      },
      {
        name: 'Browse ADB Logs',
        image: BOX_LIST_ICON,
        link: 'adb-logs',
        accessLevel: 'SMARTER_AI_ADMIN',
      },
    ],
  },
  {
    name: 'Presence',
    image: PRESENCE_ICON,
    color: '#F0F7EF',
    link: 'presence',
    accessLevel: 'SMARTER_AI_ADMIN',
    components: [
      {
        name: 'Browse Presence Logs',
        image: BOX_LIST_ICON,
        link: 'presence',
      },
    ],
  },
  {
    name: 'Usages',
    image: USAGES_ICON,
    color: '#EDEDED',
    link: 'usages',
    accessLevel: 'SMARTER_AI_ADMIN',
    components: [
      {
        name: 'Browse Usages',
        image: BOX_LIST_ICON,
        link: 'usages',
      },
    ],
  },
  {
    name: 'Controls',
    image: CONTROLS_ICON,
    color: '#F2F2ED',
    link: 'controls',
    accessLevel: 'SMARTER_AI_ADMIN',
    components: [
      {
        name: 'Control',
        image: BOX_LIST_ICON,
        link: 'controls',
      },
    ],
  },
  {
    name: 'Sensors',
    image: SENSORS_ICON,
    color: '#F2EDF2',
    link: 'sensors',
    accessLevel: 'SMARTER_AI_ADMIN',
    components: [
      {
        name: 'Sensors Status',
        image: BOX_LIST_ICON,
        link: 'sensors',
      },
    ],
  },
];

/** @type {Array<PageCardItem>} */
export const CAMERA_MANAGE_CARD_ITEMS = [
  {
    name: 'Diagnostics',
    image: DIAGNOSTICS_ICON,
    color: '#F7F5EF',
    link: 'diagnostics-logs',
    accessLevel: 'SUPER_ADMIN',
    components: [
      {
        name: 'Browse Diagnostics Logs',
        image: BOX_LIST_ICON,
        link: 'diagnostics-logs',
      },
    ],
  },
];
