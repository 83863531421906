import { useRouteQuery } from '@/hooks/useRouteQuery';
import { FilterContextProvider } from '@/web/@components/FilterContext';
import { Box } from '@mui/material';
import { EventFilterContextProvider } from '../@components/EventFilterContext';
import { AutoTenantSwitch } from '../@utils/AutoTenantSwitch';
import { EventCountHeader } from './@components/EventCountHeader';
import { EventFilteringArea } from './@components/EventFilteringArea';
import { EventInfiniteList } from './@components/EventInfiniteList';
import { EventListContextProvider } from './@components/EventListContextProvider';

export function EventsPage() {
  const { query } = useRouteQuery();

  if (query.layout === 'empty') {
    return (
      <FilterContextProvider>
        <EventFilterContextProvider>
          <EventListContextProvider>
            <EventInfiniteList fullHeight />
          </EventListContextProvider>
        </EventFilterContextProvider>
      </FilterContextProvider>
    );
  }

  return (
    <FilterContextProvider>
      <EventFilterContextProvider>
        <EventListContextProvider>
          <Box px={2.5} mt={1} display="flex" gap={1} alignItems="center">
            <EventCountHeader />
            <Box flex={1} />
          </Box>
          <Box px={2} mt={0.5}>
            <EventFilteringArea />
          </Box>
          <Box mt={0.5}>
            {<AutoTenantSwitch />}
            <EventInfiniteList />
          </Box>
        </EventListContextProvider>
      </EventFilterContextProvider>
    </FilterContextProvider>
  );
}
