import { LongMessageViewer } from '@/web/@components/LongMessageViewer';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { camelCase, startCase } from 'lodash';
import { TriggerDetailsButton } from './@components/TriggerDetailsButton';

/** @type {Array<TableColumn<FeedbackDto>>} */
export const FEEDBACK_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: '1%',
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'feedbackType',
    label: 'Type',
    align: 'center',
    isPrimary: true,
    searchable: true,
    sort: true,
    format: (value, row) => startCase(camelCase(value)),
  },
  {
    id: 'endpointId',
    label: 'Endpoint',
    align: 'center',
    searchable: true,
    sort: true,
  },
  {
    id: 'userAgent',
    label: 'Agent',
    align: 'center',
    searchable: true,
    sort: true,
    format: (value, row) => startCase(camelCase(value)),
  },
  {
    id: 'feedback',
    label: 'Feedback',
    align: 'center',
    searchable: true,
    sort: true,
    format: (value) => (
      <LongMessageViewer message={value || ''} title="Feedback" isCodeView={false} />
    ),
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center',
    width: '15%',
    searchable: true,
    sort: true,
    format: (value, row) => startCase(camelCase(value)),
  },
  {
    id: '',
    label: 'Details',
    align: 'center',
    width: '15%',
    searchable: true,
    sort: true,
    format: (value, row) => <TriggerDetailsButton row={row} />,
  },
];
