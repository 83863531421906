import { formatDuration } from '@/utils/datetime';
import { formatFileSize } from '@/utils/formatting';

export class ApiRequestProgress {
  #current = 0;
  #total = 0;
  #start = Date.now();

  constructor(current = 0, total = 0, startAt = Date.now()) {
    this.#current = current;
    this.#total = total;
    this.#start = startAt;
  }

  get now() {
    return this.#current;
  }

  get max() {
    return this.#total;
  }

  get progress() {
    return (100 * this.#current) / this.#total;
  }

  get label() {
    return isNaN(this.progress) ? '-' : `${this.progress.toFixed(0)}%`;
  }

  get speed() {
    const val = (1000 * this.#current) / (Date.now() - this.#start);
    return `${formatFileSize(val)}/s`;
  }

  get remaining() {
    return formatDuration((this.#total * (Date.now() - this.#start)) / this.#current);
  }
}
