import { Box, Typography } from '@mui/material';
import { TRIGGER_COLOR } from '../../assets';
import { calculatePercentage } from '../../utils';

export function SensitivityMiniRangeBar(props) {
  const { value, defaultValue, unit, min = 0, max = 100, isHovered } = props;

  // Normalize the value to be between min and max
  const positionPercentage = calculatePercentage(value, min, max);

  const lineStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '4.6px',
    backgroundColor:
      defaultValue !== value ? TRIGGER_COLOR.SHALLOW_RED : isHovered ? '#fff' : '#ECF0F4',
    position: 'relative',
    borderRadius: '50px',
  };

  const circleStyle = {
    width: '11px',
    height: '11px',
    borderRadius: '70%',
    border: '2px solid white',
    backgroundColor: defaultValue === value ? TRIGGER_COLOR.BLUE : TRIGGER_COLOR.RED,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: `${positionPercentage - 5}%`,
  };

  const blueLineStyle = {
    flexGrow: 1,
    height: '100%',
    backgroundColor: '#608AC3',
    margin: `0 ${100 - positionPercentage - 6}% 0 0`,
    borderRadius: '50px',
  };

  // const lineColor = useMemo(
  //   () => (defaultValue !== value ? TRIGGER_COLOR.SHALLOW_RED : TRIGGER_COLOR.BLUE),
  //   [defaultValue, value]
  // );

  return (
    <Box sx={{ ...lineStyle, mt: -1 }}>
      {/* <Box sx={{ ...blueLineStyle, backgroundColor: lineColor }}></Box> */}
      <Box sx={{ ...blueLineStyle }}></Box>
      <Box sx={circleStyle}>
        <Box pt={0.4}></Box>
        <Typography
          variant="caption"
          sx={{
            whiteSpace: 'nowrap',
            ml: value === 0 ? 0 : `${-4 * unit?.length}px`,
          }}
        >
          {value} {unit}
        </Typography>
      </Box>
    </Box>
  );
}
