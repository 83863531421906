import {
  STATUS_DEPLOY_ICON,
  STATUS_DISCONTINUE_ICON,
  STATUS_DRAFT_ICON,
} from '@/assets/constants/images';
import { STATUS_LIST } from '@/assets/constants/table';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box } from '@mui/material';
import { capitalize } from 'lodash';
/**
 * @typedef {object} ItemStatusPropsBase
 * @property {string} [status]
 */

/**
 * @typedef {import('@mui/material').BoxProps & ItemStatusPropsBase} ItemStatusProps
 */

/** @param {ItemStatusProps} props */
export function ItemStatus(props) {
  const { status } = props;
  return (
    <Box
      display={'inline-flex'}
      gap={1}
      fontSize={{ sx: '1rem', md: '0.875rem' }}
      alignItems="center"
    >
      {status === STATUS_LIST.DRAFT ? (
        <BoxImage src={STATUS_DRAFT_ICON} size="16px" />
      ) : status === STATUS_LIST.RELEASED ? (
        <BoxImage src={STATUS_DEPLOY_ICON} size="16px" />
      ) : status === STATUS_LIST.DISCONTINUED ? (
        <BoxImage src={STATUS_DISCONTINUE_ICON} size="16px" />
      ) : null}
      {capitalize(status)}
    </Box>
  );
}
