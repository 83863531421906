import { Typography } from '@mui/material';
import { IconLabelItem } from '../IconLabelItem';

export function LocationWithIcon(props) {
  const { location } = props;
  return (
    <IconLabelItem
      hidden={!location}
      src="/images/commons/map-view-off.svg"
      iconSize={{
        xs: '17px',
        md: '20px',
      }}
      title={
        <Typography
          variant={location ? 'body1' : 'body2'}
          fontSize="0.875rem"
          width={{ xs: 'calc(100vw - 80px)', md: 200, lg: 250 }}
          title={location}
        >
          {location || 'Location not available'}
        </Typography>
      }
    />
  );
}
