import api from '@/api';
import { T } from '@/assets/locales';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/tenants/actions';
import { TENANT_TABLE_COLUMNS } from '@/web/administration/tenants/columns';
import { Box } from '@mui/material';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function TenantListView() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const { t } = useTranslation();

  const {
    result = [],
    loading,
    error,
    retry: fetchTenantList,
  } = useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return sortBy(request.result, ['tenantStatus', (item) => item.tenantName.toLowerCase()]);
    },
    [tenantId, secretToken]
  );

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<TenantInfo>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteTenants(selectedItems);
      fetchTenantList();
    }
  };

  const deleteTenant = async (data) => {
    try {
      const request = api.ac.v5.tenant.$tenantId(data.tenantId).$delete({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();

      toastSuccess(
        'Success',
        t(T['tenant.delete.success'], {
          name: data.tenantName,
        })
      );
    } catch (e) {
      toastWarning(
        'Error',
        t(T['tenant.delete.failed'], {
          name: data.tenantName,
        })
      );
    }
  };

  const deleteTenants = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteTenant));
  };

  if (error) {
    // @ts-ignore
    throw new KnownUserError(error?.response?.data.message || 'Sorry! Could not fetch Tenant list');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={t(T['tenant.table.title'])}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <ContinuationTokenTable
          key={generateUniqueKey([secretToken, 'tenants'])}
          results={result}
          loading={loading}
          columns={TENANT_TABLE_COLUMNS}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
