import { endOfTheDay, minusOneMonth, startOfTheDay } from '@/utils/datetime';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { addMonths, differenceInDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

/**
 * @typedef {object} DateRangePickerPopoverContentProps
 * @property {number} [startTime]
 * @property {number} [endTime]
 * @property {(range: {startTime: number, endTime: number}) => any} onChange
 */

/** @param {DateRangePickerPopoverContentProps & Omit<import('react-date-range').DateRangeProps, 'onChange'>} props */
export function DateRangePickerPopoverContent(props) {
  const { startTime, endTime, onChange, ...extraProps } = props;

  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  /** @type {StateVariable<import('react-date-range').Range[]>} */
  const [ranges, setRanges] = useState();
  /** @type {StateVariable<import('react-date-range').Range>} */
  const [defaultRange, setDefaultRange] = useState();
  /** @type {StateVariable<number>} */
  const [selectedDays, setSelectedDays] = useState(1);

  useEffect(() => {
    /** @type {import('react-date-range').Range} */
    const range = {
      key: 'selection',
      startDate: new Date(startTime),
      endDate: new Date(endTime),
    };
    setDefaultRange(range);
    setRanges([range]);
    const diff = differenceInDays(endTime, startTime);
    setSelectedDays(diff);
  }, [startTime, endTime]);

  /** @param {number} numberOfDays */
  const selectDate = (numberOfDays) => {
    setSelectedDays(numberOfDays);
    let endTime = endOfTheDay(new Date());
    let startTime = startOfTheDay(endTime - numberOfDays * 24 * 3600 * 1000 + 1);
    const range = {
      key: 'selection',
      startDate: new Date(startTime),
      endDate: new Date(endTime),
    };
    setRanges([range]);
  };

  /** @type {(range: import('react-date-range').Range) => any}  */
  const handleChange = ({ startDate, endDate }) => {
    onChange({
      startTime: startOfTheDay(startDate),
      endTime: endOfTheDay(endDate),
    });
  };

  return (
    <Box
      sx={{
        '& .rdrCalendarWrapper': {
          '.rdrMonthAndYearWrapper': {
            'marginTop': { xs: 0, md: '-13px' },
            '.rdrMonthAndYearPickers': {
              display: { xs: 'flex', md: 'none' },
            },
          },
          '.rdrMonth': {
            paddingBottom: '0 !important',
          },
          '.rdrMonthName': {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            textAlign: 'center',
            fontSize: '1rem',
            fontWeight: 500,
          },
          '.rdrWeekDay': {
            fontSize: '0.75rem',
            fontWeight: 500,
          },
          '.rdrDays': {
            '.rdrDayNumber': {
              fontSize: '0.75rem',
              fontWeight: 500,
            },
            '.rdrDayNumber span:after': {
              display: 'none',
            },
            '.rdrDayInPreview, .rdrDayEndPreview, .rdrDayStartPreview': {
              borderStyle: 'dashed',
            },
          },
        },
      }}
    >
      <DateRange
        key={ranges + ''}
        ranges={ranges}
        months={mdAndUp ? 2 : 1}
        maxDate={new Date(endOfTheDay())}
        minDate={new Date(minusOneMonth())}
        onChange={(e) => setRanges([e.selection])}
        showPreview
        preventSnapRefocus
        editableDateInputs
        displayMode="dateRange"
        calendarFocus="backwards"
        weekdayDisplayFormat="EEEEE"
        monthDisplayFormat="MMMM yyyy"
        showDateDisplay={false} // top date preview
        showMonthAndYearPickers={false} // month, year selection manual
        direction={mdAndUp ? 'horizontal' : 'vertical'}
        rangeColors={[theme.palette.primary.main]}
        {...extraProps}
        navigatorRenderer={(date, changeDate) => (
          <Box
            position={{ md: 'absolute' }}
            top={0}
            left={0}
            right={0}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={1.5}
            px={2}
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() => changeDate(addMonths(date, -1), 'set')}
            >
              <ChevronLeft fontSize="small" />
            </IconButton>
            {mdAndUp ? (
              <Box flex={1} />
            ) : (
              <Box flex={1} className="rdrMonthName">
                {format(date, 'MMMM yyyy')}
              </Box>
            )}
            <IconButton
              color="primary"
              size="small"
              onClick={() => changeDate(addMonths(date, 1), 'set')}
            >
              <ChevronRight fontSize="small" />
            </IconButton>
          </Box>
        )}
      />

      <Divider />

      <Box p={1} display="flex" gap="5px" justifyContent="space-between">
        <Box>
          <Button
            size="small"
            color="primary"
            onClick={() => selectDate(1)}
            sx={{ color: selectedDays === 1 ? '#1E59A8' : '' }}
          >
            Today
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => selectDate(7)}
            sx={{ color: selectedDays === 7 ? '#1E59A8' : '' }}
          >
            Last 7 Days
          </Button>
          <Button
            size="small"
            color="primary"
            onClick={() => selectDate(30)}
            sx={{ color: selectedDays === 30 ? '#1E59A8' : '' }}
          >
            Last 30 Days
          </Button>
        </Box>

        <Box>
          {ranges?.length && ranges[0] !== defaultRange && (
            <Button size="small" color="primary" onClick={() => handleChange(defaultRange)}>
              Reset
            </Button>
          )}
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleChange(ranges[0])}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
