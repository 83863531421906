import { Box, Button, TextField } from '@mui/material';
import { add, format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';
import { formatDate1 } from '../../utils';

/**
 * @typedef {object} ScoreDateRangePickerProps
 * @property {() => any} [onApply]
 * @property {() => any} [onCancel]
 */

/**
 * @param {ScoreDateRangePickerProps} props
 */
export function ScoreDateRangePicker(props) {
  const { onApply, onCancel } = props;

  const { selectedFromDate, setSelectedFromDate, selectedToDate, setSelectedToDate } =
    useContext(ScoringFilterContext);

  /** @type {StateVariable<string>} */
  const [tempFromState, setTempFormState] = useState(null);
  /** @type {StateVariable<string>} */
  const [tempToState, setTempToState] = useState(null);

  const today = new Date();
  // Calculate the date one month earlier from today
  const oneMonthEarlier = format(add(today, { months: -1 }), 'yyyy-MM-dd');

  // eslint-disable-next-line no-unused-vars
  const customDateSelection = (date) => {
    const currentDate = new Date();
    setTempToState(formatDate1(currentDate));
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - date + 1);
    setTempFormState(formatDate1(sevenDaysAgo));
  };

  const onClickApply = () => {
    setSelectedFromDate(tempFromState);
    setSelectedToDate(tempToState);
    onApply();
  };

  const onClickCancel = () => {
    setTempFormState(selectedFromDate);
    setTempToState(selectedToDate);
    onCancel();
  };

  const handleTempFromChange = (event) => {
    const newTempFrom = event.target.value;
    // Ensure tempFrom is not greater than tempTo
    if (newTempFrom > tempToState) {
      setTempToState(newTempFrom);
    }
    setTempFormState(newTempFrom);
  };

  const handleTempToChange = (event) => {
    const newTempTo = event.target.value;
    setTempToState(newTempTo);
  };

  useEffect(() => {
    setTempFormState(selectedFromDate);
    setTempToState(selectedToDate);
  }, [selectedFromDate, selectedToDate]);

  return (
    <Box
      px={2}
      pb={2}
      sx={{ maxHeight: '300px', minWidth: '400px', overflowY: 'auto', marginTop: '2px' }}
    >
      <>
        <Box display={'flex'} flexDirection={'column'} gap={2} mt={1}>
          <>
            <TextField
              fullWidth
              type="date"
              size="small"
              placeholder="Input a text"
              value={tempFromState}
              inputProps={{
                min: oneMonthEarlier, // Minimum date is one month earlier from today
                max: format(today, 'yyyy-MM-dd'), // Maximum date is today
              }}
              onChange={handleTempFromChange}
            />

            <TextField
              fullWidth
              size="small"
              type="date"
              placeholder="Input a text"
              value={tempToState}
              inputProps={{
                min: tempFromState, // Minimum date is tempFromState
                max: format(today, 'yyyy-MM-dd'), // Maximum date is today
              }}
              onChange={handleTempToChange}
            />
          </>
        </Box>
        <Box
          sx={{ p: 1, pt: 2, display: 'flex', justifyContent: 'flex-end' }}
          onClick={onClickCancel}
        >
          <Button size="small">Cancel</Button>
          <Button onClick={() => onClickApply()} variant="contained" size="small">
            Apply
          </Button>
        </Box>
      </>
    </Box>
  );
}
