import { T } from '@/assets/locales';
import { EMAIL_VALIDATION_REGEX, PHONE_VALIDATION_REGEX } from '@/assets/regex';
import { useFetchDriverTags } from '@/hooks/useFetchDriverTags';
import { selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { CustomDateSelect } from '@/web/@components/CustomForm/CustomDate';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DriverFileUpload } from '../DriverFileUpload';

/**
 * @typedef {object} DriverFormFields
 * @property {File} driverFile
 * @property {number} [driverId]
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phone
 * @property {string} licenseNo
 * @property {Date} [licenseIssuanceDate]
 * @property {Date} [licenseExpirationDate]
 * @property {string} [licenseImageBackContainer]
 * @property {string} [licenseImageBackPath]
 * @property {string} [licenseImageFrontContainer]
 * @property {string} [licenseImageFrontPath]
 * @property {string} [pictureContainer]
 * @property {string} [picturePath]
 * @property {File} [driverPhotoFile]
 * @property {File} [driverLicenseFrontFile]
 * @property {File} [driverLicenseBackFile]
 * @property {Array<DriverTag>} [tags]
 * @property {string} [tenantId]
 */

/**
 * @typedef {object} DriverFormProps
 * @property {boolean} disabled
 * @property {Driver} [driver]
 * @property {import('react-hook-form').SubmitHandler<DriverFormFields>} onSubmit
 */

/**
 * @param {DriverFormProps} props
 */
export function DriverForm(props) {
  const { onSubmit, disabled, driver } = props;

  const tenantId = useSelector(selectTenantId);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { result: driverTags, loading: driverTagsLoading } = useFetchDriverTags();

  /** @type {DriverFormFields} */
  const defaultValues = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      licenseNo: '',
      licenseIssuanceDate: null,
      licenseExpirationDate: null,
      licenseImageBackContainer: '',
      licenseImageBackPath: '',
      licenseImageFrontContainer: '',
      licenseImageFrontPath: '',
      pictureContainer: '',
      picturePath: '',
      tags: [],
      tenantId,
      driverPhotoFile: null,
      driverLicenseFrontFile: null,
      driverLicenseBackFile: null,
      driverFile: null,
    }),
    [tenantId]
  );

  const form = useForm({
    mode: 'all',
    defaultValues,
  });

  const { watch } = form;

  useEffect(() => {
    if (!driver || driverTagsLoading) return;
    form.setValue('driverId', driver.driverId);
    form.setValue('firstName', driver.firstName);
    form.setValue('lastName', driver.lastName);
    form.setValue('email', driver.email);
    form.setValue('phone', driver.phone);
    form.setValue('licenseNo', driver.licenseNo);

    form.setValue('licenseIssuanceDate', parseISO(driver.licenseIssuanceDate));
    form.setValue('licenseExpirationDate', parseISO(driver.licenseExpirationDate));

    if (driver.tags?.length > 0 && driverTags && driverTags?.length > 0) {
      const dTags = driver.tags?.map((item) => driverTags.find((driver) => driver.id === item));
      form.setValue('tags', dTags);
    }
  }, [driver, form, driverTags, driverTagsLoading]);

  const handleCancel = () => {
    navigate('/fleets/drivers');
  };

  /** @param {File} file */
  const handleDriverPhotoUpload = async (file) => {
    form.setValue('driverPhotoFile', file);
  };

  /** @param {File} file */
  const handleDriverLicenseFrontUpload = async (file) => {
    form.setValue('driverLicenseFrontFile', file);
  };

  /** @param {File} file */
  const handleDriverLicenseBackUpload = async (file) => {
    form.setValue('driverLicenseBackFile', file);
  };

  /** @param {DriverFormFields} data */
  const prepareDataAndSubmit = (data) => {
    data.email = trimString(data.email);
    data.firstName = trimString(data.firstName);
    data.lastName = trimString(data.lastName);
    data.phone = trimString(data.phone);
    data.licenseNo = trimString(data.licenseNo);
    data.licenseImageBackContainer = trimString(data.licenseImageBackContainer);
    data.licenseImageBackPath = trimString(data.licenseImageBackPath);
    data.licenseImageFrontContainer = trimString(data.licenseImageFrontContainer);
    data.licenseImageFrontPath = trimString(data.licenseImageFrontPath);
    data.pictureContainer = trimString(data.pictureContainer);
    data.picturePath = trimString(data.picturePath);
    data.tags.forEach((item) => {
      item.name = trimString(item.name);
      item.description = trimString(item.description);
    });
    return onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
        Driver Details
      </Typography>
      <Divider />

      <form autoComplete="off" noValidate>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="firstName"
              label="First Name *"
              placeholder="Enter driver first name"
              rules={{ required: 'First name is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="lastName"
              label="Last Name *"
              placeholder="Enter driver last name"
              rules={{ required: 'Last name is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="phone"
              label="Phone *"
              placeholder="Enter driver phone number"
              rules={{
                required: 'Phone is required',
                pattern: {
                  value: PHONE_VALIDATION_REGEX,
                  message: 'Enter a valid phone number',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="email"
              label="Email *"
              placeholder="Enter driver email address"
              InputProps={{
                type: 'email',
                autoComplete: 'email',
              }}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: EMAIL_VALIDATION_REGEX,
                  message: 'Enter a valid email',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <CustomInput
              name="licenseNo"
              label="License No *"
              placeholder="Enter driver license"
              rules={{ required: 'License is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomDateSelect
              name="licenseIssuanceDate"
              label="License Issuance Date *"
              rules={{ required: 'License issuance date is required' }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomDateSelect
              disabled={!watch('licenseIssuanceDate')}
              name="licenseExpirationDate"
              label="License Expiration Date *"
              rules={{ required: 'License expiration date is required' }}
              DatePickerProps={{
                minDate: watch('licenseIssuanceDate'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomSelect
              name="tags"
              label="Tags"
              placeholder="Select driver tags"
              options={driverTags}
              loading={driverTagsLoading}
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={4}>
            <DriverFileUpload
              name="driverPhotoFile"
              path={driver?.pictureUrl}
              driverId={driver?.driverId}
              onDropFile={handleDriverPhotoUpload}
              label="Upload Driver Photo"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <DriverFileUpload
              name="driverLicenseFrontFile"
              path={driver?.licenseImageFrontUrl}
              driverId={driver?.driverId}
              onDropFile={handleDriverLicenseFrontUpload}
              label="Upload Driver License Front Image"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <DriverFileUpload
              name="driverLicenseBackFile"
              driverId={driver?.driverId}
              path={driver?.licenseImageBackUrl}
              onDropFile={handleDriverLicenseBackUpload}
              label="Upload Driver License Back Image"
            />
          </Grid>
        </Grid>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={5}>
          <Button variant="text" onClick={handleCancel}>
            {t(T['button.cancel'])}
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            onClick={form.handleSubmit(prepareDataAndSubmit)}
          >
            {disabled && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {driver ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
      </form>
      <Box height="30px" />
    </FormProvider>
  );
}
