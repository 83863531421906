import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Transforms a list of V5 AiAcceleratorEntity objects to a list of V1 AIAccelerator objects
 * @param {Array<AiAcceleratorDto>} entities
 * @returns {AIAccelerator[]}
 */
function transformAiAcceleratorEntities(entities) {
  return entities.map((entity) => ({
    codeName: entity.codeName,
    description: entity.description,
    name: entity.name,
    type: entity.type,
    vendor: entity.vendor,
  }));
}

/**
 * Fetch list of all Ai Accelerators
 */
export function useFetchAIAccelerators() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      /** @type {Array<AIAccelerator>} */
      let results = [];
      while (!signal.aborted) {
        const request = api.ac.v5.aiaccelerator.$get({
          headers: {
            Authorization: secretToken,
          },
        });
        await request.process();
        if (!request.result) break;
        let oldAiAcceleratorList = transformAiAcceleratorEntities(request.result.list);
        /** @type {Array<AIAccelerator>} */
        results.push(...oldAiAcceleratorList);
        break;
      }
      return results;
    },
    [],
    { cache: false }
  );
}
