import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApplicableTypeSelection } from './@components/ApplicableTypeSelection';
import { CameraGroupSelection } from './@components/CameraGroupSelection';
import { CameraSelection } from './@components/CameraSelection';
import { APKSelection } from './@components/APKSelection';
import { TenantSelection } from './@components/TenantSelection';
import { SELECTION_TYPES } from '../assets';
import { deployedAPKsArraysDifferent } from '../@utils';

export function DeployApkFile() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues: {
      selectionType: SELECTION_TYPES[0],
      camera: null,
      includeChildTenant: false,
      tenant: null,
      group: null,
      selectedApks: [],
      availableApks: [],
      deployedItems: [],
      isDirty: false,
      submitCount: 0,
    },
  });

  const { control, setValue } = form;

  const inputType = useWatch({ control, name: `selectionType` });
  const selectedApks = useWatch({ control, name: `selectedApks` });
  const deployedAPKs = useWatch({ control, name: `deployedItems` });
  const availableApks = useWatch({ control, name: `availableApks` });
  const submitCount = useWatch({ control, name: `submitCount` });
  const camera = useWatch({ control, name: `camera` });
  const tenant = useWatch({ control, name: `tenant` });
  const group = useWatch({ control, name: `group` });

  const areArraysDifferent = useMemo(
    () => deployedAPKsArraysDifferent(deployedAPKs, selectedApks, availableApks),
    [selectedApks, deployedAPKs, availableApks]
  );

  useEffect(() => {
    setValue('camera', null);
    setValue('tenant', null);
    setValue('group', null);
    setValue('selectedApks', []);
  }, [inputType, setValue, tenantId]);

  const handleCancel = () => {
    navigate('/administration/apk-files?type=development');
  };

  const undeployAPKs = async (selectedItems, camera, group, tenant) => {
    if (!selectedItems?.length) return;
    let errorCount = 0;
    await Promise.allSettled(
      selectedItems.map(async (scheme) => {
        try {
          const request = api.ac.v5.scheme.undeploy.$delete({
            headers: {
              Authorization: secretToken,
            },
            params: {
              ...(camera ? { endpointID: camera?.endpointId } : {}),
              ...(group ? { groupId: group?.id } : {}),
              ...(tenant ? { tenantId: tenant?.tenantId } : {}),
              schemeId: scheme.schemeId,
            },
          });
          await request.process();
        } catch (e) {
          errorCount = errorCount + 1;
          // toastWarning(`Failed to Undeploy ${scheme.schemeName}`);
        }
      })
    );
    return errorCount;
  };

  const deployAPKs = async (selectedItems, camera, group, tenant) => {
    if (!selectedItems?.length) return;
    let errorCount = 0;
    await Promise.allSettled(
      selectedItems.map(async (scheme) => {
        try {
          const request = api.ac.v5.scheme.deploy.$post({
            headers: {
              Authorization: secretToken,
            },
            params: {
              ...(camera ? { endpointID: camera?.endpointId } : {}),
              ...(group ? { groupId: group?.id } : {}),
              ...(tenant ? { tenantId: tenant?.tenantId } : {}),
              schemeId: scheme.id,
            },
          });
          await request.process();
        } catch (e) {
          errorCount = errorCount + 1;
          // toastWarning(`Failed to Deploy APK ${scheme.name}`);
        }
      })
    );
    return errorCount;
  };

  const prepareAndSubmit = async (data) => {
    try {
      const { availableApks, selectedApks, deployedItems, camera, group, tenant } = data;

      // Find newly added items based on id
      const newlyAddedItems = selectedApks?.filter(
        (newItem) =>
          !deployedItems?.some((deployedItem) => Number(deployedItem.schemeId) === newItem.id)
      );

      //Find the unselected Items
      const unselectedItems = availableApks?.filter(
        (item) => !selectedApks?.some((selected) => Number(selected.id) === item.id)
      );
      // Find deleted items based on id
      const deletedItems = deployedItems?.filter((deployedItem) =>
        unselectedItems?.some((newItem) => newItem.id === Number(deployedItem.schemeId))
      );

      const undeployErrorCount = await undeployAPKs(deletedItems, camera, group, tenant);
      const deployErrorCount = await deployAPKs(newlyAddedItems, camera, group, tenant);

      const totalChangedItems = deletedItems + newlyAddedItems;
      const totalErrorCount = undeployErrorCount + deployErrorCount;
      const successMessage =
        totalErrorCount > 0
          ? `${
              totalChangedItems - totalErrorCount
            } out of ${totalChangedItems} schemes applied successfully.`
          : 'APKs Applied Successfully';

      if (totalErrorCount === totalChangedItems) {
        toastWarning('Failed to Apply APK');
      } else {
        toastSuccess(successMessage);
      }

      setValue('submitCount', submitCount + 1);
      setIsLoading(false);
    } catch (error) {
      toastWarning('Failed to Apply APK');
      setIsLoading(false);
    }
  };

  return (
    <Box
      px={2}
      pb={14}
      sx={{ '& .MuiFormGroup-root': { justifyContent: 'space-between !important' } }}
    >
      <Typography variant="body2" fontSize="1rem" fontWeight={600} mb={2}>
        Apply APK Files
      </Typography>

      <FormProvider {...form}>
        <Grid
          container
          spacing={{ xs: 2, md: 10 }}
          sx={{
            'alignItems': 'center',
            '& input': {
              fontSize: '15px',
              fontWeight: 500,
            },
          }}
        >
          <Grid item xs={12} md={6}>
            <ApplicableTypeSelection
              property="selectionType"
              inputType={inputType}
              setValue={setValue}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {inputType?.value === 'ENDPOINT' ? (
              <CameraSelection property="camera" />
            ) : inputType?.value === 'GROUP' ? (
              <CameraGroupSelection property="group" />
            ) : inputType?.value === 'TENANT' ? (
              <TenantSelection property="tenant" />
            ) : null}
          </Grid>
        </Grid>

        <APKSelection
          property={'selectedApks'}
          submitCount={submitCount}
          camera={camera}
          group={group}
          tenant={tenant}
        />

        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          gap={'5px'}
          mt={4}
          sx={{
            '& .Mui-disabled': {
              backgroundColor: '#acb4c0 !important',
              color: 'white !important',
            },
          }}
        >
          <Button variant="text" onClick={handleCancel} size="small">
            <Typography fontSize={'0.875rem'}> {t(T['button.cancel'])}</Typography>
          </Button>
          <Button
            size="small"
            disabled={isLoading || !areArraysDifferent}
            variant="contained"
            onClick={form.handleSubmit(prepareAndSubmit)}
          >
            {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            <Typography fontSize={'0.875rem'}> {t(T['button.save'])}</Typography>
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
