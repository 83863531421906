import { toastInfo } from '@/utils/toaster';
import { ContentCopyOutlined } from '@mui/icons-material';
import { Alert, Grid, IconButton, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';

/**
 * Displays expression details for a trigger.
 * @param {object} props - React component props.
 * @param {SingleTriggerDto} props.body - The trigger details to display.
 * @param {string} props.title - The trigger label.
 * @param {import('@mui/material').AlertColor} [props.severity] - The trigger label.
 */
export function ExpressionTriggerDetails({ body, title, severity = 'success' }) {
  if (!body) return null;
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle2" lineHeight="18px">
        {title || 'Expression'}
      </Typography>
      <Alert
        severity={severity}
        icon={false}
        action={
          <CopyToClipboard
            text={body?.displayFormula || body?.expression}
            onCopy={() => toastInfo('Expression copied to clipboard')}
          >
            <IconButton title="Copy">
              <ContentCopyOutlined sx={{ fontSize: '18px' }} />
            </IconButton>
          </CopyToClipboard>
        }
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '18px',
            className: 'word-break',
          }}
        >
          {body?.displayFormula || body?.expression}
        </Typography>
      </Alert>
    </Grid>
  );
}
