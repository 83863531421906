import { UserHardwareDevices } from '../UserHardwareDevices';
import { UserMobileAppDevices } from '../UserMobileAppDevices';

export const USER_DEVICES_ACCORDION_ITEMS = [
  {
    key: 'apps',
    label: 'Mobile Apps Devices',
    component: () => <UserMobileAppDevices />,
  },
  {
    key: 'hardwares',
    label: 'Hardware Devices',
    component: () => <UserHardwareDevices />,
  },
];
