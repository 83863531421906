export const VIDEO_RECORDING_DEFAULT_RESOLUTIONS = [
  { label: '426x240 (240p)', value: 'WH_426x240' },
  { label: '640x360 (360p)', value: 'WH_640x360' },
  { label: '854x480 (480p)', value: 'WH_854x480' },
  { label: '960x540 (540p)', value: 'WH_960x540' },
  { label: '1280x720 (720p)', value: 'WH_1280x720' },
];

/**
 * @param {import("react-hook-form").UseFormWatch<any>} watch
 * @returns {Array<SettingsOptions>}
 */
export const videoResolution = (watch) => {
  const options = VIDEO_RECORDING_DEFAULT_RESOLUTIONS;
  const state = watch('imagerState');
  const parsedStates = state ? JSON.parse(state) : [];
  const count = parsedStates?.filter(
    (x) => x.inputType === 'VIDEO' && watch(`enableImagerState_${x.inputCode}`)
  )?.length;

  if (count < 2) {
    options.push({ label: '1920x1080 (1080p)', value: 'WH_1920x1080' });
  }
  return options;
};

export const recordingCodecs = [
  { label: 'H264', value: 'H264' },
  { label: 'H265', value: 'H265' },
];

export const streamingCodecs = [{ label: 'H264', value: 'H264' }];

export const audioCodecs = [{ label: 'OPUS', value: 'OPUS' }];

export const periodicSnapshotFreqOptions = [
  { label: '30 seconds', value: 30000 },
  { label: '1 minute', value: 60000 },
  { label: '5 minutes', value: 300000 },
];

export const periodicAwakeningFromParkingModeFreqOptions = [
  { label: 'Off', value: -1 },
  { label: '30 minutes', value: 1800000 },
  { label: '1 hour', value: 3600000 },
  { label: '6 hours', value: 21600000 },
  { label: '12 Hours', value: 43200000 },
  { label: '24 hours', value: 86400000 },
  { label: 'Once a week', value: 604800000 },
  { label: 'Once a month', value: 2629800000 },
];

export const startPolicyOptions = [
  { label: 'As Soon As Possible', value: 'ASAP' },
  { label: 'Before Parking Mode', value: 'BEFORE_PARKING_MODE' },
  { label: 'Scheduled', value: 'SCHEDULED' },
  // { label: 'Geo Fence', value: 'GEO_FENCE' },
];

export const retryPolicyOptions = [
  { label: 'As Soon As Possible', value: 'ASAP' },
  { label: 'Next Day', value: 'NEXT_DAY' },
  { label: 'Next Week', value: 'NEXT_WEEK' },
  { label: 'Scheduled', value: 'SCHEDULED' },
  { label: 'Disabled', value: 'DISABLED' },
];

export const periodicCheckPolicyOptions = [
  { label: 'Interval', value: 'INTERVAL' },
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Disabled', value: 'DISABLED' },
];

export const triggerPolicyOptions = [
  { label: 'Instantly', value: 'INSTANTLY' },
  { label: 'As Soon As Possible', value: 'ASAP' },
  { label: 'Disabled', value: 'DISABLED' },
];

export const deploymentPolicyOptions = [
  { label: 'Instantly', value: 'INSTANTLY' },
  { label: 'As Soon As Possible', value: 'ASAP' },
  { label: 'Disabled', value: 'DISABLED' },
];

export const networkToDownloadOptions = [
  { label: 'Any', value: 'ANY' },
  { label: 'Non-Metered only', value: 'WIFI_ONLY' },
];

export const dayOptions = [
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
];

export const parkingModeOptions = [
  { label: 'Off', value: 'OFF' },
  { label: 'On', value: 'ON' },
  { label: 'Power Save', value: 'POWER SAVE' },
];

export const frameRateOptions = [
  { label: '1', value: 1 },
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
];

export const logLevelOptions = [
  { label: 'None', value: 'none' },
  { label: 'Error', value: 'error' },
  { label: 'Warning', value: 'warning' },
  { label: 'Trace', value: 'trace' },
  { label: 'Info', value: 'info' },
  { label: 'Debug', value: 'debug' },
];

export const accelerometerTypeVisionOptions = [
  { label: 'Raw', value: 'RAW' },
  { label: 'Linear', value: 'LINEAR' },
];

export const hertzOptions = [
  { label: '1 Hz', value: 1 },
  { label: '2 Hz', value: 2 },
  { label: '4 Hz', value: 4 },
  { label: '5 Hz', value: 5 },
  { label: '10 Hz', value: 10 },
  { label: '20 Hz', value: 20 },
  { label: '25 Hz', value: 25 },
  { label: '50 Hz', value: 50 },
  { label: '100 Hz', value: 100 },
];

export const hertzRecordingOptions = [
  { label: '0 Hz', value: 0 },
  { label: '1 Hz', value: 1 },
  { label: '2 Hz', value: 2 },
  { label: '4 Hz', value: 4 },
  { label: '5 Hz', value: 5 },
  { label: '10 Hz', value: 10 },
  { label: '20 Hz', value: 20 },
  { label: '25 Hz', value: 25 },
  { label: '50 Hz', value: 50 },
  { label: '100 Hz', value: 100 },
];

export const usbModeOptions = [
  { label: 'Charging', value: 'CHARGING' },
  { label: 'File Transfer', value: 'FILE_TRANSFER' },
  { label: 'Tethering', value: 'TETHERING' },
  { label: 'PTP', value: 'PTP' },
];

export const sdCardFormattingOptions = [
  { label: 'Never', value: 'NEVER' },
  { label: 'If SD card is unreadable', value: 'UNREADABLE' },
  { label: 'Always', value: 'ALWAYS' },
];

export const sdCardEncryptionOptions = [
  { label: 'NONE', value: 'NONE' },
  { label: 'AES128', value: 'AES128' },
  { label: 'AES256', value: 'AES256' },
];

export const switchOptions = [
  { label: 'ON', value: 'ON' },
  { label: 'OFF', value: 'OFF' },
];

export const sensorOptionsOne = [
  { label: '1 Hz', value: 1 },
  { label: '2 Hz', value: 2 },
  { label: '4 Hz', value: 4 },
  { label: '5 Hz', value: 5 },
  { label: '10 Hz', value: 10 },
  { label: '20 Hz', value: 20 },
  { label: '25 Hz', value: 25 },
  { label: '50 Hz', value: 50 },
  { label: '100 Hz', value: 100 },
  { label: '200 Hz', value: 200 },
  { label: '400 Hz', value: 400 },
  { label: 'OFF', value: 0 },
];

export const sensorOptionsTwo = [
  { label: '1 Hz', value: 1 },
  { label: '2 Hz', value: 2 },
  { label: '4 Hz', value: 4 },
  { label: '5 Hz', value: 5 },
  { label: 'OFF', value: 0 },
];

export const sensorOptionsThree = [
  { label: '1 Hz', value: 1 },
  { label: '2 Hz', value: 2 },
  { label: '4 Hz', value: 4 },
  { label: '5 Hz', value: 5 },
  { label: '10 Hz', value: 10 },
  { label: '20 Hz', value: 20 },
  { label: '25 Hz', value: 25 },
  { label: 'OFF', value: 0 },
];

export const sensorTypeOptions = [
  { label: 'Raw', value: 'RAW' },
  { label: 'Linear', value: 'LINEAR' },
  { label: 'Both', value: 'BOTH' },
];

export const sensorOptionsFour = [
  { label: '1 Hz', value: 1 },
  { label: '2 Hz', value: 2 },
  { label: '4 Hz', value: 4 },
  { label: '5 Hz', value: 5 },
  { label: '10 Hz', value: 10 },
  { label: '20 Hz', value: 20 },
  { label: '25 Hz', value: 25 },
  { label: '50 Hz', value: 50 },
  { label: '100 Hz', value: 100 },
  { label: 'OFF', value: 0 },
];

export const sensorTypeGPSOptions = [
  { label: '1 Hz', value: 1 },
  { label: '0 Hz', value: 0 },
];

export const billingCycleOptions = [
  { label: '1st', value: 1 },
  { label: '2nd', value: 2 },
  { label: '3rd', value: 3 },
  { label: '4th', value: 4 },
];

export const frequencyOptions = [
  { label: '30 seconds', value: 30 },
  { label: '1 minute', value: 60 },
  { label: '2 minute', value: 120 },
  { label: '5 minute', value: 300 },
];

export const audioBitrate = [
  { label: '8 kbps', value: 8 },
  { label: '16 kbps', value: 16 },
  { label: '24 kbps', value: 24 },
  { label: '48 kbps', value: 48 },
];

//in sec
export const eventPollingIntervalOptions = [
  { label: '15 seconds', value: 15 },
  { label: '30 seconds', value: 30 },
  { label: '1 minute', value: 60 },
  { label: '2 minutes', value: 120 },
  { label: '5 minutes', value: 300 },
  { label: '10 minutes', value: 600 },
  { label: '15 minutes', value: 900 },
];

//in hours
export const firmwareDownloadDurationOptions = [
  { label: '1 hour', value: 1 },
  { label: '2 hours', value: 2 },
  { label: '3 hours', value: 3 },
  { label: '4 hours', value: 4 },
  { label: '5 hours', value: 5 },
  { label: '6 hours', value: 6 },
  { label: '12 hours', value: 12 },
];

//in sec
export const dlProgressReportingIntervalOptions = [
  { label: '5 seconds', value: 5 },
  { label: '10 seconds', value: 10 },
  { label: '15 seconds', value: 15 },
  { label: '20 seconds', value: 20 },
  { label: '30 seconds', value: 30 },
  { label: '60 seconds', value: 60 },
];

//in milliseconds
export const loginPromptFreq = [
  { label: '30 seconds', value: 30000 },
  { label: '1 minute', value: 60000 },
  { label: '2 minutes', value: 120000 },
];

//in milliseconds
export const loginPromptMaxDuration = [
  { label: 'None', value: 0 },
  { label: '2 minutes', value: 120000 },
  { label: '5 minutes', value: 300000 },
  { label: '30 minutes', value: 1800000 },
  { label: '1 hour', value: 3600000 },
];

export const driverSides = [
  { label: 'Left', value: 'LEFT' },
  { label: 'Right', value: 'RIGHT' },
  { label: 'Both', value: 'BOTH' },
];

export const captureVoltageOptions = [
  { label: 'Off', value: 0 },
  { label: '5 Hz', value: 5 },
];

export const listOfHighways = [
  { label: 'Motorway', value: 'motorway' },
  { label: 'Trunk', value: 'trunk' },
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Tertiary', value: 'tertiary' },
  { label: 'Residential', value: 'residential' },
];

export const speedOffsetTypes = [
  { label: 'Fixed', value: 'FIXED' },
  { label: 'Percentage', value: 'PERCENTAGE' },
];

export const tripDecisionSources = [
  { label: 'Parking Mode', value: 'PARKING' },
  { label: 'ACC Signal', value: 'ACC' },
  { label: 'Vehicle Movement', value: 'MOVEMENT' },
];

export const periodicReportOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '5', value: 5 },
  { label: '7', value: 7 },
  { label: '9', value: 9 },
  { label: '11', value: 11 },
  { label: '13', value: 13 },
  { label: '15', value: 15 },
];

export const adbLogsMaxSizeOptions = [
  { label: '100 MB', value: 100 },
  { label: '300 MB', value: 300 },
  { label: '500 MB', value: 500 },
  { label: '1000 MB', value: 1000 },
];

export const adbUploadIntervalOptions = [
  { label: '1 Hour', value: 1 },
  { label: '3 Hours', value: 3 },
  { label: '6 Hours', value: 6 },
  { label: '12 Hours', value: 12 },
  { label: '24 Hours', value: 24 },
];
