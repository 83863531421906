import { BOX_DELETE_ICON, TRANSFER_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<VinDetails>>} */
export const ACTION_ITEMS = [
  {
    type: 'DISCONTINUE',
    label: 'Delete',
    iconImage: BOX_DELETE_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    confirmationMessage: T['vins.confirmations.discontinue'],
  },
  {
    type: 'TRANSFER',
    label: 'Transfer',
    iconImage: TRANSFER_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    useCustomDialog: true,
  },
  {
    label: 'Search',
    component: 'SEARCH',
    placeholder: 'Search VIN',
  },
  {
    type: 'CREATE',
    label: 'VIN',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    useCustomDialog: true,
  },
];
