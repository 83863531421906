import axios from 'axios';

/**
 * Upload blob to azure Manually
 * @param {File} file
 * @param {string} storedFileName
 * @param {string} url
 * @param {(number) => any} onProgress
 */

export async function uploadFileToAzureBlob(file, storedFileName, url, onProgress) {
  var blob = new Blob([file], { type: file.type || 'image/jpeg' });
  return axios.put(url, blob, {
    headers: {
      'Content-Type': file.type || 'image/jpeg',
      'x-ms-blob-type': 'BlockBlob',
    },
    onUploadProgress(progressEvent) {
      let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      onProgress(progress);
    },
    timeout: 30000,
  });
}
