import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * @typedef {object} UserDetailsContextData
 * @property {Number} userId
 * @property {UserWithAccountDetails} user
 * @property {() => any} refreshUser
 */

/** @type {import('react').Context<UserDetailsContextData>} */
export const UserDetailsContext = createContext(null);

export function UserDetailsLayout() {
  const { id } = useParams();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const navigate = useNavigate();
  const location = useLocation();
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<UserWithAccountDetails>} */
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [initialTenantId] = useState(tenantId);

  const userId = useMemo(() => Number(id), [id]);

  const refreshUser = useCallback(() => {
    setRefresh(Date.now());
  }, []);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  useEffect(() => {
    if (!userId) return;
    setError(null);
    setLoading(true);

    const request = api.ac.v5.tenant
      .$tenantId(tenantId)
      .user.$userId(userId)
      .$get({
        headers: {
          Authorization: secretToken,
        },
      });

    request
      .process()
      .then(setUser)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [userId, secretToken, tenantId, refresh]);

  useEffect(() => {
    if (!user) return;
    setBreadcrumbTitle(user.name, (path) => path.endsWith(id));
  }, [id, user, setBreadcrumbTitle]);

  if (loading || !user) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error) {
    return (
      <CenterBox>
        <IconMessageBox
          src={NO_SEARCH_RESULT_IMG}
          size="256px"
          message="Could not get User details"
        />
      </CenterBox>
    );
  }

  return (
    <UserDetailsContext.Provider
      value={{
        userId,
        user,
        refreshUser,
      }}
    >
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </UserDetailsContext.Provider>
  );
}
