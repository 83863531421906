import { TriggerNameWithImage } from '@/web/administration/triggers-configuration/@components/TriggerNameWithImage';
import { Box } from '@mui/material';
import { ThresholdSettings } from '../ThresholdSettings';
import { isDev } from '@/config';

export function TriggerNameWithThreshold(props) {
  const { trigger, formName, hoveredRowId } = props;

  const triggerName =
    trigger.triggerCategoryName === 'Default' && trigger?.name !== ''
      ? trigger?.name
      : trigger?.name !== trigger?.triggerCategoryName
        ? trigger?.name
        : trigger?.triggerCategoryName;

  return (
    <Box display="flex" gap={1} alignItems="center">
      <TriggerNameWithImage triggerName={triggerName} length={50} sx={{ fontWeight: 500 }} />
      {isDev && (
        <ThresholdSettings trigger={trigger} formName={formName} hoveredRowId={hoveredRowId} />
      )}
    </Box>
  );
}
