import { FilterContextProvider } from '@/web/@components/FilterContext';
import { Box, Divider, Typography } from '@mui/material';
import { AdbLogsTable } from '../../@components/AdbLogsTable';
import { CreateLogRequestModal } from '../../@components/CreateLogRequestModal';

export function CameraAdbLogs() {
  return (
    <Box
      px={2.5}
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
        'overflowY': 'auto',
      }}
      height="98%"
    >
      <FilterContextProvider>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
          <Typography variant="body2" fontSize="1rem" fontWeight="500">
            ADB Logs
          </Typography>
          <Box display="flex" gap={1}>
            {/* <DiagnosticsLogsFilter searchable={false} selectable={true} /> */}
            {/* <CustomDateRangePicker /> */}
            <CreateLogRequestModal />
          </Box>
        </Box>
        <Divider />
        <AdbLogsTable />
      </FilterContextProvider>
    </Box>
  );
}
