import { CamerasPage } from '.';
import { CameraDetailsLayout } from '../@layouts/CameraDetailsLayout';
import { CameraDetailsPage } from './_cameraId';
import { CameraEventsPage } from './_cameraId/events';
import { CameraTripsPage } from './_cameraId/trips';
import { LiveStreamPage } from './live';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <CamerasPage />,
  },
  {
    path: 'live',
    element: <LiveStreamPage />,
  },
  {
    path: ':cameraId',
    element: <CameraDetailsLayout />,
    breadcrumb: 'Camera Details',
    children: [
      {
        path: '',
        element: <CameraDetailsPage />,
      },
      {
        path: 'trips',
        element: <CameraTripsPage />,
      },
      {
        path: 'events',
        element: <CameraEventsPage />,
      },
    ],
  },
];

export default routes;
