import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<FirmwareV2>>} */
export const ACTION_ITEMS = [
  {
    label: 'Search',
    component: 'SEARCH',
    placeholder: 'Search Firmware',
  },
  {
    type: 'DEPLOY_FIRMWARE',
    label: 'Firmware',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    useCustomDialog: true,
  },
];
