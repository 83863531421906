// https://stackoverflow.com/a/63000900/5537132
export const EMAIL_VALIDATION_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

// https://regexr.com/3c53v
export const PHONE_VALIDATION_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i;

// https://stackoverflow.com/a/72686232/5537132
export const PASSWORD_VALIDATION_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*\W)(?!.* ).{8,}$/i;

// https://regex101.com/r/49qCxI
export const VIN_REGEX = /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/i;

export const AI_MODEL_VERSION_REGEX = /(^\d{1,3})+(\.\d{1,3}){1,2}$/;

export const WIFI_SSID_PATTERN = /^[A-Za-z0-9\s!@#$%^&*()-_=+[{\]}\\|;:'",<.>/?]*$/;

export const WIFI_PASSWORD_PATTERN = /^.{8,}$|^$/;
