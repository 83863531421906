import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectTeamList } from '@/store/auth';
import { formatMediaType, formatTriggerType } from '@/utils/triggers';
import { CenterBox } from '@/web/@components/CenterBox';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { capitalize, startCase } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ExpressionTriggerDetails } from './ExpressionTriggerDetails';
import { formatTriggerName } from '@/utils/events';

/**
 * @typedef {object} TriggerDetailsModalProps
 * @property {CompositeTriggerDto['id']} [triggerId]
 * @property {String} [triggerName]
 * @property {() => any} [onCancel]
 */

/** @param {TriggerDetailsModalProps} props */
export function TriggerDetailsModal(props) {
  const { triggerId, triggerName, onCancel } = props;

  const teamList = useSelector(selectTeamList);

  /** @type {import('@/hooks/useFutureLoader').FutureLoaderResponse<CompositeTriggerDto>} */
  const { result: trigger, loading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      const request = api.ac.v5.trigger.composite.$triggerId(triggerId).$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
        },
      });
      await request.process();
      return request.result;
    },
    []
  );

  const hasExpressionViewAccess = useMemo(
    () =>
      teamList.find((x) => x === 'AI_TEAM' || x === 'PRODUCT_MANAGER' || x === 'FRONT_END_TEAM'),
    [teamList]
  );

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Trigger Details</DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? (
          <CenterBox>
            <CircularProgress />
          </CenterBox>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              '& .word-break': {
                wordWrap: 'break-word !important',
              },
            }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" lineHeight="18px">
                Trigger Name
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight={500}
                lineHeight="18px"
                className="word-break"
              >
                {formatTriggerName(triggerName)}
              </Typography>
            </Grid>

            {hasExpressionViewAccess && trigger?.type === 'EXPRESSION' && (
              <ExpressionTriggerDetails body={trigger?.body} title="Expression" />
            )}

            {hasExpressionViewAccess && trigger?.magnitudeFormula?.expression && (
              <ExpressionTriggerDetails
                body={trigger?.magnitudeFormula}
                title="Magnitude Expression"
                severity="warning"
              />
            )}

            {hasExpressionViewAccess && trigger?.snapshotFormula?.expression ? (
              <ExpressionTriggerDetails
                body={trigger?.snapshotFormula}
                title="Snapshot Expression"
                severity="info"
              />
            ) : null}

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Type
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {formatTriggerType(trigger?.type)}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Version
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.version}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Media Type
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {formatMediaType(trigger?.mediaType?.value)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Is Critical
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.critical ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Is System Trigger
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.isSystemTrigger ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Status
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {startCase(trigger?.publicationStatus?.toLocaleLowerCase())}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Cooldown
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.cooldown?.value ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Cooldown Timer
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.cooldownTimer?.value / 1000} sec
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Chime
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {startCase(trigger?.chime?.value?.toLocaleLowerCase())}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Upload Strategy
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {startCase(trigger?.uploadStrategy?.value?.toLocaleLowerCase())}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Pre Buffer
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.preBuffer?.value} sec
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Post Buffer
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.postBuffer?.value} sec
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Media Source
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.mediaSource}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Chime
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {startCase(trigger?.chime?.value?.toLocaleLowerCase())}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                TTS Status
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.enableTts?.value ? 'Enabled' : 'Disabled'}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                TTS Gender
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {capitalize(trigger?.ttsGender?.value || 'NEUTRAL')}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                TTS Language Locale
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} lineHeight="18px">
                {trigger?.ttsLanguageLocale?.value || '...'}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" lineHeight="18px">
                Text to speech (TTS)
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight={500}
                lineHeight="18px"
                className="word-break"
              >
                {trigger?.ttsText.value || trigger?.tts || '...'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
