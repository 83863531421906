import api from '@/api';
import { SmartCache } from '@/utils/caching/smart-cache';
import { sortBy } from 'lodash';

const tagListCache = new SmartCache('tag-list-cache', 2 * 60 * 1000);

/**
 * Fetch list of all available tags
 * @param {string} tenantId
 * @param {string} secretToken
 * @param {AbortSignal} [signal]
 * @returns {Promise<Array<HealthTagResponse>>}
 */
export async function fetchTagList(tenantId, secretToken, signal) {
  let result = await tagListCache.getItem(tenantId);
  if (!result?.length) {
    const request = api.ac.v5.health.tag.$get({
      signal,
      headers: {
        Authorization: secretToken,
      },
      params: {
        tenantId,
      },
    });
    result = await request.process();
    await tagListCache.setItem(tenantId, result); // cache
  }
  return sortBy(result, 'tagName');
}

/**
 * Fetch health tags of an endpoint
 * @param {number} cameraId
 * @param {string} tenantId
 * @param {string} secretToken
 * @param {AbortSignal} [signal]
 * @returns {Promise<Array<AssignedHealthTagResponse>>}
 */
export async function fetchEndpointTags(cameraId, tenantId, secretToken, signal) {
  const tags = [];
  let offset = 0;
  const limit = 50;
  while (!signal?.aborted) {
    const request = api.ac.v5.health.tenant.$tenantId(tenantId)['assigned-health-tag'].$get({
      signal,
      params: {
        limit,
        offset,
        endpointId: cameraId + '',
      },
      headers: {
        Authorization: secretToken,
      },
    });
    const result = await request.process();
    tags.push(...result);
    if (result.length < limit) break;
    offset += limit;
  }
  return tags;
}
