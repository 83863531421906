import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { Box, Button, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AiContainerDetailsContext } from '../AiContainerDetailsLayout';
import { InputSourceListItem } from './InputSourceListItem';
import { OutputSourceSelectionDialog } from './OutputSourceSelectionDialog';

/**
 * @typedef {object} InputSourceMappingProps
 * @property {'edit'|'add'} [mode]
 * @property {string} [componentId]
 * @property {AIModelV2WithAccelerator} model
 * @property {AIModelV2WithAccelerator[]} supportedModels
 * @property {() => any} [onReset]
 */

/** @param {InputSourceMappingProps & import('@mui/system').BoxProps} props */
export function InputSourceMapping(props) {
  const { mode, model, supportedModels, componentId, onReset, ...extra } = props;
  const {
    container,
    product,
    models: components,
    refreshModels,
  } = useContext(AiContainerDetailsContext);

  /** @type {StateVariable<{[key: string]: ComponentOutputSource}>} */
  const [selected, setSelected] = useState({});
  /** @type {StateVariable<ComponentInputSource>} */
  const [sourceDialog, setSourceDialog] = useState(null);
  const [dirty, setDirty] = useState(false);

  const component = useMemo(
    () => components?.find((item) => item.modelId === model?.modelId),
    [components, model]
  );

  const inputSources = useMemo(() => {
    /** @type {ComponentInputSource[]} */
    const sources = [];
    model?.inputSources?.forEach((item, i) => {
      if (!item?.inputDescription?.inputType) return;
      sources.push({
        id: '' + (item.inputDescription.id || i + 1),
        type: '' + item.inputDescription.inputType,
        name: '' + (item.inputDescription.name || ''),
        description: '' + (item.inputDescription.description || ''),
        properties: Object.entries(item.inputDescription).map(([key, value]) => ({ key, value })),
      });
    });
    return sources;
  }, [model?.inputSources]);

  const outputSources = useMemo(() => {
    /** @type {ComponentOutputSource[]} */
    const sources = [];
    if (componentId) {
      supportedModels
        ?.find((item) => item.modelId === componentId)
        ?.outputSources?.forEach((x) => {
          sources.push({
            code: x.outputCode,
            type: x.outputType,
            name: x.outputName,
          });
        });
    } else {
      product?.inputs?.forEach((x) => {
        sources.push({
          code: x.inputCode,
          type: x.inputType,
          name: x.inputName,
        });
      });
    }
    return sources;
  }, [supportedModels, product?.inputs, componentId]);

  useEffect(() => {
    /** @type {{[key: string]: ComponentOutputSource}} */
    const mapping = {};
    for (const input of component?.inputMappingList || []) {
      const sourceId = input?.modelInputs?.find((x) => x.key === 'id')?.value;
      const outputSource = outputSources.find((x) => x.code === input.productInputCode);
      if (sourceId && outputSource) mapping[sourceId] = outputSource;
    }
    setSelected(mapping);
    setDirty(false);
  }, [model, component, outputSources]);

  const handleSubmit = async () => {
    try {
      const state = store.getState();
      const tenantId = selectTenantId(state);
      const secretToken = selectSecretToken(state);
      const data = Object.entries(selected)
        .filter((entry) => Boolean(entry[1]))
        .map(([modelId, outputSource]) => ({
          productInputCode: outputSource.code,
          modelInputs: inputSources.find((x) => x.id === modelId).properties,
        }));
      if (components.find((x) => x.modelId === model.modelId && x.inputModelId === componentId)) {
        const request = api.ac.v2.repository.aicontainer.aimodel.$put({
          data,
          params: {
            tenantId,
            secretToken,
            modelId: model.modelId,
            inputModelId: componentId || '',
            containerId: container.containerId,
          },
        });
        await request.process();
        toastSuccess('Success', `Saved <b>${model.name}<b>`);
      } else {
        const request = api.ac.v2.repository.aicontainer.aimodel.$post({
          data,
          params: {
            tenantId,
            secretToken,
            modelId: model.modelId,
            inputModelId: componentId || '',
            containerId: container.containerId,
          },
        });
        await request.process();
        toastSuccess('Success', `Added <b>${model.name}<b>`);
        onReset && onReset();
      }
      refreshModels();
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not save changes.');
    }
  };

  const handleDelete = async () => {
    try {
      const state = store.getState();
      const tenantId = selectTenantId(state);
      const secretToken = selectSecretToken(state);
      const request = api.ac.v2.repository.aicontainer.aimodel.$delete({
        params: {
          tenantId,
          secretToken,
          modelId: model.modelId,
          containerId: container.containerId,
        },
      });
      await request.process();
      toastSuccess('Success', `Removed the <b>${model.name}</b>`);
      onReset && onReset();
      refreshModels();
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Could not remove the component.');
    }
  };

  /** @type {import('./OutputSourceSelectionDialog').OutputSourceSelectionDialogProps['onSelect']} */
  const handleOutputSourceSelected = (selection) => {
    setSelected((prev) => ({ ...prev, [sourceDialog.id]: selection }));
    setSourceDialog(null);
    setDirty(true);
  };

  return (
    <Box {...extra}>
      <Typography variant="subtitle2">
        Add the desired Imager / Sensor Outputs to matching AI Model Inputs
      </Typography>

      {!inputSources?.length ? (
        <IconMessageBox message="No input sources available" text="secondary" height={'100px'} />
      ) : (
        <Box>
          {inputSources.map((inputSource) => (
            <InputSourceListItem
              key={inputSource.id}
              inputSource={inputSource}
              selected={selected[inputSource.id]}
              onClick={() => {
                if (!outputSources?.length) {
                  toastWarning(null, 'No output source available');
                } else {
                  setSourceDialog(inputSource);
                }
              }}
            />
          ))}
        </Box>
      )}

      <Box display="flex" alignItems="center" gap="5px" mt={2}>
        {mode === 'edit' && (
          <Button variant="outlined" color="error" onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Box flex={1} />
        <Button variant="text" onClick={onReset}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={mode === 'edit' && !dirty}>
          Save
        </Button>
      </Box>

      {sourceDialog && (
        <OutputSourceSelectionDialog
          type={sourceDialog.type}
          outputSources={outputSources}
          onSelect={handleOutputSourceSelected}
        />
      )}
    </Box>
  );
}
