import { useLabelListFetch } from '@/hooks/useLabelListFetch';
import { intersection, not, union } from '@/utils/group-settings';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const LABEL_TYPE = 'Canbus';

/**
 * @typedef {object} OBD2SelectionProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {OBD2SelectionProps} props */
export default function OBD2Selection(props) {
  const { item } = props;
  const { key: name } = item;
  const { getValues, setValue } = useFormContext();

  const initValues = useMemo(() => getValues(name), [name, getValues]);

  const [checked, setChecked] = useState([]);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const leftChecked = intersection(checked, leftItems);
  const rightChecked = intersection(checked, rightItems);

  const { result: labelList, loading: labelListLoading } = useLabelListFetch(LABEL_TYPE);

  const handleToggle = (value) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => {
    return intersection(checked, items)?.length;
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRightItems(rightItems.concat(leftChecked));
    setLeftItems(not(leftItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftItems(leftItems.concat(rightChecked));
    setRightItems(not(rightItems, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    if (labelListLoading) return;
    const arrayList = initValues && initValues?.split(','); //make comma separate string to array
    const filteredArray = labelList?.filter((item, index) => arrayList.includes(item.name));
    setRightItems(filteredArray);
  }, [labelList, labelListLoading, initValues]);

  useEffect(() => {
    let filteredArray = labelList?.filter((x) => !rightItems.includes(x));
    setLeftItems(filteredArray);
  }, [rightItems, labelList]);

  useEffect(() => {
    let filteredValue = rightItems?.map((item, index) => item.name);
    setValue(name, filteredValue?.join());
  }, [rightItems, setValue, name]);

  const customList = (title, items) => {
    const itemsLength = items?.length;
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          sx={{ px: 2, py: 0.5 }}
          avatar={
            <Checkbox
              disabled={itemsLength === 0}
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === itemsLength && itemsLength !== 0}
              indeterminate={numberOfChecked(items) !== itemsLength && numberOfChecked(items) !== 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
        />
        <Divider />
        <List
          sx={{
            width: '100%',
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
        >
          {(items || []).map((item, index) => {
            const labelId = `transfer-list-all-item-${item.name}-label`;
            return (
              <ListItem
                key={item.name + index}
                role="listitem"
                button
                onClick={handleToggle(item)}
                sx={{ py: 0 }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& .MuiTypography-body1': {
                      fontSize: '14px',
                    },
                    '& .MuiTypography-body2': {
                      fontSize: '11px',
                    },
                  }}
                  id={labelId}
                  primary={item.displayName}
                  secondary={item.name}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Box
      gap={'20px'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{ pointerEvents: 'auto' }}
    >
      <Box width="100%" color="#0B2547" fontSize="14px">
        {customList('Available', leftItems)}
      </Box>
      <Box>
        <Box display={'flex'} flexDirection="column" gap="10px" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked?.length === 0}
            aria-label="move selected rightItems"
          >
            <ArrowRight />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked?.length === 0}
            aria-label="move selected leftItems"
          >
            <ArrowLeft />
          </Button>
        </Box>
      </Box>
      <Box width="100%" color="#0B2547" fontSize="14px">
        {customList('Selected', rightItems)}
      </Box>
    </Box>
  );
}
