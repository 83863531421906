import { ADMINISTRATION_ITEMS } from '@/assets/page-cards/administration';
import { DATA_PRIVACY_ITEMS } from '@/assets/page-cards/data-privacy';
import { FLEETS_ITEMS } from '@/assets/page-cards/fleets';
import { OPERATIONS_ITEMS } from '@/assets/page-cards/operations';
import { SAFETY_PAGE_ITEMS } from '@/assets/page-cards/safety';
import { SMARTER_AI_ITEMS } from '@/assets/page-cards/smarter-ai';
import { WASTE_MANAGEMENT_ITEMS } from '@/assets/page-cards/waste-management';
import { WINTER_OPERATION_ITEMS } from '@/assets/page-cards/winter-operations';
import {
  selectTeamList,
  selectTenantId,
  selectUserAccessibleFeatures,
  selectUserEmail,
  selectUserRole,
} from '@/store/auth';
import { selectFullWidthLayout, selectSidebarMode } from '@/store/page-view';
import { filterByUserAccess } from '@/utils/user-access';
import { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from '@/web/@layouts/MainLayout/config';
import { CloseOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Drawer,
  Hidden,
  IconButton,
  Link,
  Popper,
  TextField,
  keyframes,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { random } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BoxImage } from '../BoxImage';
import { PageLink } from '../PageLink';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const GroupHeader = styled('div')(({ theme }) => ({
  // position: 'sticky',
  top: '-8px',
  padding: '4px 48px',
  color: theme.palette.primary.main,
}));

const GroupItems = styled('ul')({ padding: 0 });

export function MasterHeaderSearch() {
  const wrapperRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);
  const enableFeatures = useSelector(selectUserAccessibleFeatures);

  const fullWidthLayout = useSelector(selectFullWidthLayout);
  const isSidebarCollapsed = useSelector(selectSidebarMode);
  const smAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [searchEnable, setSearchEnable] = useState(false);

  const flattenItems = useMemo(
    () =>
      [
        ADMINISTRATION_ITEMS,
        DATA_PRIVACY_ITEMS,
        FLEETS_ITEMS,
        OPERATIONS_ITEMS,
        SMARTER_AI_ITEMS,
        WASTE_MANAGEMENT_ITEMS,
        WINTER_OPERATION_ITEMS,
        SAFETY_PAGE_ITEMS,
      ].flat(),
    []
  );

  const visibleItems = useMemo(
    () =>
      flattenItems?.filter(
        filterByUserAccess({ userRole, userEmail, tenantId, teamList, enableFeatures })
      ),
    [flattenItems, userRole, userEmail, tenantId, teamList, enableFeatures]
  );

  const drawerWidth = useMemo(
    () => (isSidebarCollapsed ? COLLAPSED_DRAWER_WIDTH : DRAWER_WIDTH),
    [isSidebarCollapsed]
  );

  const handleOnClick = (value) => {
    navigate(value?.link);
    setSearchEnable(false);
    setSearchEnable(false);
  };

  // Reformat API response JSON
  const reformatedNavigation = useMemo(
    () =>
      visibleItems
        .reduce(
          (
            /** @type {Array<PageCardItemComponent & {name: string, image: string, groupName: string}>} */ nav,
            row
          ) => {
            row?.components?.forEach((children) => {
              nav.push({
                ...children,
                groupName: row.name,
                image: children.image || children.icon,
              });
            });
            return nav;
          },
          []
        )
        ?.filter((item) => item.link !== '')
        ?.sort((a, b) => a.name.localeCompare(b.name)),
    [visibleItems]
  );

  // Handle Click outside for bigger display
  const handleMenuCloseWhileClickOutside = useCallback(
    (e) => {
      // @ts-ignore
      if (wrapperRef.current && searchEnable && !wrapperRef.current?.contains(e.target)) {
        setTimeout(() => {
          setSearchEnable(false);
        }, 100);
      }
    },
    [searchEnable]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMenuCloseWhileClickOutside);
    return () => {
      document.removeEventListener('visibilitychange', handleMenuCloseWhileClickOutside);
    };
  }, [handleMenuCloseWhileClickOutside]);

  const PopperComponent = function (props) {
    return (
      <>
        {smAndUp ? (
          <Popper {...props} placement="bottom-start" />
        ) : (
          <Popper
            {...props}
            sx={{ width: '100vw !important', height: '40vh !important' }}
            placement="bottom-start"
          />
        )}
      </>
    );
  };

  const SearchComponent = () => (
    <IconButton onClick={() => setSearchEnable(true)}>
      <BoxImage src="/images/commons/search-icon.svg" size="100%" />
    </IconButton>
  );

  const HeaderSearchComponent = () => (
    <Autocomplete
      id="grouped-demo"
      fullWidth
      size="small"
      autoFocus
      openOnFocus={!!smAndUp}
      options={reformatedNavigation}
      groupBy={(option) => option.groupName}
      forcePopupIcon={false}
      disableClearable
      autoHighlight={true}
      getOptionLabel={(option) => t(option.name)}
      noOptionsText=""
      onClick={(event, value) => {
        handleOnClick(value);
      }}
      PopperComponent={PopperComponent}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ animation: `${fadeIn} .5s linear` }}
          autoFocus
          placeholder={'Search'}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <IconButton sx={{ mt: '-2px' }}>
                <BoxImage src="/images/commons/search-icon.svg" size={{ xs: '22px', sm: '18px' }} />
              </IconButton>
            ),
          }}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key} style={{ paddingBottom: '15px' }}>
          <GroupHeader>
            <Link underline="none" color="#768498">
              {t(params.group)}
            </Link>
          </GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(t(option.name), inputValue, { insideWords: true });
        const parts = parse(t(option.name), matches);
        return (
          <li {...props} key={option.link + option.name + random(1000)}>
            <PageLink
              to={option.link}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 1,
                pl: '32px',
              }}
            >
              <BoxImage src={option?.image} size={20}></BoxImage>
              <div>
                {parts?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      flexGrow: 1,
                      fontWeight: part.highlight ? 600 : 500,
                      fontSize: '0.875rem',
                      color: part.highlight ? '#0B2547' : theme.palette.text.secondary,
                    }}
                  >
                    {t(part.text)}
                  </span>
                ))}
              </div>
            </PageLink>
          </li>
        );
      }}
    />
  );

  if (reformatedNavigation.length < 5) {
    return null;
  }

  return (
    <>
      <Hidden smDown>
        {searchEnable ? (
          <Box
            ref={wrapperRef}
            sx={{
              'width': '350px',
              'display': 'flex',
              '& .MuiInputBase-root': {
                'bgcolor': '#f5f9ff',
                'padding': '5px !important',
                'borderRadius': '20px',
                '& input::placeholder': {
                  fontSize: '16px',
                },
                '& fieldset': {
                  display: 'none',
                },
              },
            }}
          >
            <HeaderSearchComponent />
          </Box>
        ) : (
          <SearchComponent />
        )}
      </Hidden>

      <Hidden smUp>
        <SearchComponent />
        <Drawer anchor="top" open={searchEnable} onClose={() => setSearchEnable(false)}>
          <Box
            sx={{
              pl: '20px',
              width: { xs: '100%', lg: `calc(100% - ${drawerWidth}px)` },
              display: 'flex',
              justifyContent: 'center',
              minHeight: '160px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                'maxWidth': fullWidthLayout ? null : '1500px',
                'width': '100%',
                'pr': '20px',
                'display': 'flex',
                '& .MuiInputBase-root': {
                  '& input::placeholder': {
                    fontSize: '17px',
                  },
                  '& fieldset': {
                    display: 'none',
                  },
                },
              }}
            >
              <HeaderSearchComponent />
              <IconButton onClick={() => setSearchEnable(false)}>
                <CloseOutlined htmlColor="#7691b3" sx={{ fontSize: '30px' }} />
              </IconButton>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
}
