import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { capitalize } from 'lodash';

/** @type {Array<TableColumn<UserRole>>} */
export const CAMERA_USER_TABLE_COLUMNS = [
  {
    id: 'modelId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    format: (value, row) => <TableCheckboxItem row={row} disabled={row.role === 'OWNER'} />,
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    searchable: true,
    isPrimary: true,
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
    searchable: true,
  },
  {
    id: 'role',
    label: 'Permission',
    align: 'left',
    searchable: true,
    format: (value) => capitalize(value),
  },
];
