import { T } from '@/assets/locales';
import { CreateVinGroup } from '@/web/administration/vin-groups/create';
import { VinGroupListView } from '@/web/administration/vin-groups/index';
import { EditVinGroupPage } from '@/web/administration/vin-groups/_id/edit';
import { VinGroupDetailsPage } from './_id';
import { VinGroupDetailsLayout } from './_id/@components/VinGroupDetailsLayout';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <VinGroupListView />,
  },
  {
    path: 'create',
    element: <CreateVinGroup />,
    breadcrumb: 'Create VIN Group',
  },
  {
    path: ':id',
    element: <VinGroupDetailsLayout />,
    breadcrumb: 'VIN Group Details',
    children: [
      {
        path: '',
        element: <VinGroupDetailsPage />,
      },
      {
        path: 'edit',
        element: <EditVinGroupPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
