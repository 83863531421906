import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { DBC_VERSION_TABLE_COLUMNS } from './columns';

export function DbcVersionHistory({ versions }) {
  return (
    <Box mt={2}>
      <PaginatedTableContextProvider>
        <ContinuationTokenTable
          results={versions}
          columns={DBC_VERSION_TABLE_COLUMNS}
          height="100%"
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
