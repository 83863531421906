import api from '@/api';
import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { formatCameraName } from '@/utils/cameras';
import { sendEventsToDevices, sendOtaGroupMessages } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { DeviceGroupChangeDialog } from '@/web/@components/DeviceGroupChangeDialog';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

/** @param {import('@mui/material').IconButtonProps} props */
export function EditGroupModal(props) {
  const { camera, cameraId, refreshCamera } = useContext(CameraDetailsContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [show, setShow] = useState(false);

  const handleSubmit = async (/** @type {Group} */ group) => {
    if (!group) {
      toastWarning('Please select a device group');
      return;
    }
    const request = api.ac.endpoint.group.change.resource_group_id.$put({
      params: {
        secretToken,
        toGroupId: group.id,
        tenantId,
        groupResourceType: 'DEVICE',
        resourceId: cameraId,
      },
    });
    try {
      await request.process();
      const eventText = JSON.stringify({ groupId: group.id });
      sendEventsToDevices([cameraId], MESSAGE_TYPE.DEVICE_GROUP_CHANGE, eventText);
      sendOtaGroupMessages(group.id, MESSAGE_TYPE.DEVICE_GROUP_CHANGE);
      toastSuccess(
        null,
        `<b>${formatCameraName(camera.label, camera.deviceSerialNumber)}</b>` +
          ` has been assigned to <b>${group.name || 'group'}</b>`
      );
      setShow(false);
      refreshCamera();
    } catch (err) {
      console.error(err);
      toastWarning(null, `Could not assign to <b>${group.name || 'group'}</b>`);
    }
  };

  return (
    <>
      <IconButton onClick={() => setShow(true)} {...props}>
        <BoxImage src={BOX_MODIFY_ICON} size="16px" />
      </IconButton>
      {show && (
        <DeviceGroupChangeDialog
          open={true}
          onSelect={handleSubmit}
          onClose={() => setShow(false)}
          defaultGroupId={camera.groupId}
        />
      )}
    </>
  );
}
