import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { CustomSelect } from '@/web/@components/CustomForm';
import { sortBy } from 'lodash';

export function CameraGroupSelection(props) {
  const { property } = props;

  const { result: groups = [], loading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      let offset = 0;
      const limit = 500;
      /** @type {Array<DeviceGroupInfo>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.group.device.list.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
            tenantId,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result;
        results.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      return sortBy(results, [(item) => item?.groupName?.toLowerCase()]);
    },
    [],
    { cache: true }
  );

  return (
    <CustomSelect
      name={property}
      loading={loading}
      size="small"
      placeholder={'Select Camera Group'}
      options={groups || []}
      getLabel={'groupName'}
      rules={{ required: true }}
    />
  );
}
