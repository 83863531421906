import { useFetchDrivers } from '@/hooks/useFetchDrivers';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  IconButton,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

/**
 * @typedef {object} AccessCardTransferModalProps
 * @property {() => any} [onCancel]
 * @property {(value: DevicePoolInfoForTenant) => any} onSubmit
 */

/** @param {AccessCardTransferModalProps} props */
export function AccessCardTransferModal(props) {
  const { onCancel, onSubmit } = props;

  const [selected, setSelected] = useState(null);

  const { result: drivers, loading: driversLoading } = useFetchDrivers();

  return (
    <Dialog open onClose={onCancel} fullWidth keepMounted>
      <DialogTitle display="flex" justifyContent="space-between">
        Transfer Access Card
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box mb="10px">
          <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
            Select a Driver
          </FormLabel>
          <Autocomplete
            fullWidth
            disableClearable
            options={drivers}
            loading={driversLoading}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : `${option?.firstName} ${option?.lastName}`
            }
            renderInput={(params) => <TextField {...params} />}
            onChange={(event, newValue) => {
              setSelected(newValue);
            }}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!selected}
          onClick={selected ? () => onSubmit(selected) : null}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
