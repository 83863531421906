import api from '@/api';
import { STATUS_LIST } from '@/assets/constants/table';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';

import { toastSuccess, toastWarning } from '@/utils/toaster';
import { DialogTable } from '@/web/@components/DialogTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ASSIGNED_AI_CONTAINER_TABLE_COLUMNS } from './columns';

/**
 * @typedef {object} AssignAiContainerModalPageProps
 * @property {(any) => any} onModalClose
 * @property {Array<AIContainerV2>} assignedItems
 * @property {string} childTenantId
 */

/** @param {AssignAiContainerModalPageProps} props */
export function AssignAiContainerModalPage(props) {
  const { onModalClose, childTenantId, assignedItems } = props;
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  /** @type {StateVariable<Array<CompositeTriggerDto>>} */
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    result = [],
    loading: aiContainerLoading,
    error,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    let conToken = '';
    const limit = 100;
    /** @type {Array<AiContainerResponse>} */
    const results = [];
    while (!signal.aborted) {
      const request = api.ac.v5.aicontainer.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          size: limit,
          continuationToken: conToken,
          tenantId,
        },
      });
      await request.process();
      const result = request.result.aiContainers;
      conToken = request.result.continuationToken;
      results.push(...result.filter((x) => [STATUS_LIST.RELEASED].includes(x.publicationStatus)));
      if (result.length < limit) break;
    }

    const filteredItem = results.filter(
      (o1) => !assignedItems.some((o2) => o1.id === o2.containerId)
    );

    return filteredItem.sort((a, b) => {
      return (
        -a.publicationStatus.localeCompare(b.publicationStatus) ||
        a.name.localeCompare(b.name) ||
        -a.versionNumber.localeCompare(b.versionNumber)
      );
    });
  }, []);

  const handleAssignAiContainer = async () => {
    if (!selectedItems || selectedItems.length === 0) {
      toastWarning('Please select a AI Container');
      return;
    }

    try {
      setLoading(true);
      const { id, name } = selectedItems[0];
      const request = api.ac.v2.repository.aicontainer.assign.$post({
        data: [
          {
            tenantId: childTenantId,
            tenantRole: 'Admin',
          },
        ],
        params: {
          containerId: id,
          secretToken,
          tenantId,
        },
      });
      request
        .process()
        .then((data) => {
          toastSuccess(`AI Container '${name}' assigned successfully`);
          onModalClose(true);
        })
        .catch((err) => onModalClose());
    } catch (error) {
      toastWarning(`Failed to assign AI Container.`);
      onModalClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={onModalClose} maxWidth={'lg'} fullWidth={true}>
      <DialogTitle>Assign AI Container</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogTable
          title="AI Container"
          loading={aiContainerLoading}
          error={error}
          results={result}
          columns={ASSIGNED_AI_CONTAINER_TABLE_COLUMNS}
          onSelectItem={setSelectedItems}
          dataSortKey="name"
          disableMultiSelect
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onModalClose}>Cancel</Button>
        <Button onClick={handleAssignAiContainer} autoFocus variant="contained" disabled={loading}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
