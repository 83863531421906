import { BoxImage } from '@/web/@components/BoxImage';
import { Button, CircularProgress, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @template T
 * @typedef {object} TableActionButtonProps<T>
 * @property {boolean} [loading]
 * @property {TableHeaderAction<T>} [item]
 * @property {import('react').MouseEventHandler<HTMLElement>} [onClick]
 */

/**
 * @template T
 * @param {TableActionButtonProps<T>} props
 */
export function TableActionButton(props) {
  const { item, loading, onClick: handleButtonClick } = props;
  const { t } = useTranslation();
  return (
    <Button
      size={'small'}
      disabled={loading}
      onClick={loading ? undefined : handleButtonClick}
      variant={item?.variant ?? 'outlined'}
      sx={{
        fontSize: { xs: '.875rem', xl: '1rem' },
        fontWeight: '500',
        borderRadius: '6px',
        px: '15px',
        gap: '5px',
        width: { xs: '100%', sm: 'fit-content' },
        height: '35px',
      }}
    >
      {loading ? (
        <CircularProgress size={18} />
      ) : item.icon ? (
        <Icon
          component={item.icon}
          sx={{ fontSize: '18px', color: item.variant === 'outlined' ? '#8390a0' : '#fff' }}
        />
      ) : item?.iconImage ? (
        <BoxImage src={item.iconImage} size="15px" />
      ) : null}
      {t(item.label)}
    </Button>
  );
}
