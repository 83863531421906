import api from '@/api';
import { Auth } from '..';

/**
 * Logout of the current session
 * @returns {StoreAction<Promise>}
 */
export const doLogout = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const token = state.auth.accessToken;
    const request = api.ac.v5.auth.user.logout.$post({
      data: { token },
    });
    await request.process();
  } catch (err) {
    console.warn(err);
  } finally {
    dispatch(Auth.logout());
  }
};
