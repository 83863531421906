import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { DEFAULT_ITEM_PER_PAGE, DEFAULT_ROW_PER_PAGE_OPTIONS } from '@/assets/constants/table';
import { T } from '@/assets/locales';
import { store } from '@/store';
import { selectSecretToken } from '@/store/auth';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { ArrowLeftSharp, ArrowRightSharp } from '@mui/icons-material';
import { Box, Button, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CenterBox } from '../CenterBox';
import { FilterContext, SetFilterContext } from '../FilterContext';
import { IconMessageBox } from '../IconMessageBox';

/**
 * @typedef {object} FetcherParams
 * @property {number} [limit]
 * @property {number} [offset]
 * @property {string} secretToken
 * @property {string} [searchText]
 * @property {string} [searchType]
 * @property {AbortSignal} signal
 */

/**
 * @template T
 * @typedef {object} FetcherResult<T>
 * @property {Array<T>} data
 * @property {number} [count]
 * @property {Array<T>} [selected]
 */

/**
 * @template T
 * @typedef {object} OffsetPaginationProps<T>
 * @property {number} [startOffset] Starting offset
 * @property {number} [itemsPerPage] Items per page
 * @property {Array} [columns] Table columns
 * @property {Array} [rowsPerPageOptions]  Row Per Page Choice
 * @property {Boolean} [disablePagination] Pagination
 * @property {(params: FetcherParams) => boolean} [Disables] pagination
 * @property {(params: FetcherParams) => PromiseLike<FetcherResult<T>>} fetcher
 * @property {(result: Array<T>) => import('react').ReactNode} renderList Component to render on success
 * @property {(reload: () => any) => import('react').ReactNode} [renderEmpty] Component to render when list is empty
 * @property {(error: Error, retry: () => any) => import('react').ReactNode} [renderFailure] Component to render on failure
 * @property {() => import('react').ReactNode} [renderLoading] Component to render while loading
 * @property {import('react').ElementType<any>} [component]
 * @property {import('react').ElementType<any>} [itemComponent]
 * @property {import('@mui/material').SxProps<import('@mui/material').Theme>} [sx]
 */

/**
 * @template T
 * @param {OffsetPaginationProps<T> & import('@mui/material').BoxProps} props
 */
export function OffsetPagination(props) {
  const { t } = useTranslation();

  const {
    fetcher,
    startOffset = 0,
    itemsPerPage = DEFAULT_ITEM_PER_PAGE,
    rowsPerPageOptions = DEFAULT_ROW_PER_PAGE_OPTIONS,
    disablePagination = false,
    renderLoading = () => (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    ),
    renderFailure = (err, retry) => (
      <CenterBox fullView>
        <IconMessageBox size="256px" src={NO_SEARCH_RESULT_IMG} error={error} />
      </CenterBox>
    ),
    renderEmpty = (reload) => (
      <CenterBox fullView>
        <IconMessageBox size="256px" src={NO_SEARCH_RESULT_IMG} message={t(T['no.data.found'])} />
      </CenterBox>
    ),
    renderList = (items) => (
      <>
        {items.map((item, i) => (
          <Paper key={i} elevation={1} sx={{ p: 1, my: 1 }}>
            <pre>{JSON.stringify(item, null, 2)}</pre>
          </Paper>
        ))}
      </>
    ),
    component,
    itemComponent,
    sx,
  } = props;

  const setFilter = useContext(SetFilterContext);
  const { searchText, searchType, offset, limit } = useContext(FilterContext);
  const { reloadStatus, setSelectedItems, setTableReload } = useContext(PaginatedTableContext);

  const theme = useTheme();

  const [loading, setLoading] = useState(null);
  /** @type {StateVariable<Array<T>>} */
  const [results, setResults] = useState([]);
  /** @type {StateVariable<Error>} */
  const [error, setError] = useState(null);
  /** @type {StateVariable<number>} */
  const [count, setCount] = useState(0);
  const [isCountLoading, setIsCountLoading] = useState(true);

  useEffect(() => {
    if (limit && limit > itemsPerPage) return;
    //setFilter({ limit: itemsPerPage });
  }, [setFilter, itemsPerPage, limit]);

  const loadData = async () => {
    try {
      const state = store.getState();
      const secretToken = selectSecretToken(state);
      const aborter = new AbortController();
      setLoading(true);
      setError(null);
      const result = await fetcher({
        limit,
        offset,
        secretToken,
        searchText,
        searchType,
        signal: aborter.signal,
      });
      setResults(result.data);
      setCount(result.count || 0);
      setSelectedItems(result.selected || []);
    } catch (err) {
      console.error(err);
      setError(err);
      setSelectedItems([]);
    } finally {
      setLoading(false);
      setIsCountLoading(false);
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [limit, offset, searchText]);

  useEffect(() => {
    if (!reloadStatus) return;
    setTableReload(false);
    if (offset > 0) {
      setFilter({ offset: 0 });
    } else {
      loadData();
    }
    // eslint-disable-next-line
  }, [reloadStatus]);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const handlePrevClick = (e) => {
    e.stopPropagation();
    setFilter({ limit, offset: Math.max(startOffset, offset - limit) });
  };

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const handleNextClick = (e) => {
    e.stopPropagation();
    setFilter({ limit, offset: offset + limit });
  };

  const handleItemPerPageChange = (event) => {
    setFilter({ limit: event.target.value });
  };

  return (
    <Box className="offset-pagination" component={component} sx={{ ...sx }}>
      {loading
        ? renderLoading()
        : error
          ? renderFailure(error, loadData)
          : !results?.length
            ? renderEmpty(loadData)
            : renderList(results)}

      {!disablePagination && !error && count !== 0 && (
        <Box
          className="pagination"
          component={itemComponent}
          display="flex"
          justifyContent="space-between"
          p={2}
          gap={1}
          alignContent="center"
          alignItems="center"
        >
          <Box display={'inline-flex'}>
            <Typography pr={2} pt={0.4}>
              {isCountLoading ? <CircularProgress size={20} /> : count}
            </Typography>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                disabled={!results?.length}
                sx={{
                  '& .MuiSelect-select': {
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    color: theme.palette.text.disabled,
                  },
                }}
                labelId="demo-select-small"
                id="per-page-selection"
                value={limit || itemsPerPage}
                onChange={handleItemPerPageChange}
              >
                {rowsPerPageOptions.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(T['table.per.page'], { item })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display={'inline-flex'} gap={1}>
            <Button
              sx={{ padding: 0, minWidth: 0 }}
              className="previous-button"
              size="small"
              variant="outlined"
              disabled={offset <= startOffset}
              onClick={handlePrevClick}
              children={<ArrowLeftSharp />}
            />
            <Button
              sx={{ padding: 0, minWidth: 0 }}
              className="next-button"
              size="small"
              variant="outlined"
              children={<ArrowRightSharp />}
              disabled={offset + limit >= count}
              onClick={handleNextClick}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
