import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FleetScoreCircularChart } from '../FleetScoreCircularChart';
import { SKY_HAWK_ID } from '@/assets/demo';

export function FleetScoreWidget() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const isSkyHawkTenantLoggedIn = SKY_HAWK_ID === tenantId;
  const titleName = isSkyHawkTenantLoggedIn ? 'Camera Safety Score' : 'Fleet Score';
  const defaultScore = isSkyHawkTenantLoggedIn ? 95 : 0;

  const [score, setScore] = useState(defaultScore);

  useEffect(() => {
    try {
      if (isSkyHawkTenantLoggedIn) return;
      const request = api.ac.v5.fleet['monthly-score'].$get({
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
        },
      });
      request
        .process()
        .then((data) => {
          setScore(Math.floor(data.result.score));
        })
        .catch((ex) => {})
        .finally(() => {});
    } catch (error) {}
  }, [secretToken, tenantId, isSkyHawkTenantLoggedIn]);

  return (
    <Box display="grid" justifyContent="center" alignItems="center" justifyItems="center">
      <Typography variant="body2" fontWeight={600} fontSize={'18px'} my={1}>
        {titleName}
      </Typography>
      <FormControl
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '28px !important',
            color: '#4177BF !important',
            fontWeight: 600,
          },
          '& .Mui-disabled': {
            WebkitTextFillColor: '#4177BF !important',
          },
        }}
      >
        <TextField
          size="small"
          value="Last 30 Days"
          disabled
          sx={{ '& input': { textAlign: 'center' } }}
        />
      </FormControl>
      <Box height="240px">
        <FleetScoreCircularChart value={score} />
      </Box>
    </Box>
  );
}
