import {
  bracketClosingCheck,
  expressionLastWordAtPosition,
  expressionNextOptions,
  formatLabelData,
  handleTriggerBetweenFormula,
  insertTextAt,
  isSelectedValueContainsSpecialFormula,
  replaceStringAtPosition,
} from '@/utils/triggers';
import {
  Box,
  ClickAwayListener,
  FormLabel,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} ExpressionGeneratorProps
 * @property {Array<TriggerClassification>} labels
 * @property {string} itemLabel
 * @property {boolean} [isRequired]
 * @property {string} keyName
 */

/**
 * @param {ExpressionGeneratorProps} props
 */
export function ExpressionGenerator(props) {
  const { labels, itemLabel, isRequired = false, keyName } = props;
  const { register, formState, setValue, watch } = useFormContext();
  const error = get(formState.errors, keyName);

  const expression = watch(keyName);

  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [lastCursorPos, setLastCursorPos] = useState(0);

  /**@type  {import('react').FocusEventHandler<HTMLDivElement>} */
  const handleClosePopover = (e) => {
    setOpen(false);
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const formattedLabels = useMemo(() => formatLabelData(labels), [labels]);

  const handleInput = (val) => setValue(keyName, val);

  const addItemToExpression = (value) => {
    const lastWord = expressionLastWordAtPosition(expression, lastCursorPos);
    let newExp = '';
    let selectedVal = value;
    if (isSelectedValueContainsSpecialFormula(lastWord, selectedVal)) {
      selectedVal = handleTriggerBetweenFormula(expression, value);
      newExp = replaceStringAtPosition(expression, lastCursorPos, selectedVal);
    } else {
      newExp = insertTextAt(expression, ` ${selectedVal} `, lastCursorPos);
    }

    setValue(keyName, newExp);
    setLastCursorPos((lastCursorPos) => lastCursorPos + selectedVal.length + 2);
  };

  const options = useMemo(
    () => expressionNextOptions(expression, formattedLabels, lastCursorPos),
    [expression, formattedLabels, lastCursorPos]
  );

  const isBracketClosed = useMemo(() => bracketClosingCheck(expression), [expression]);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, expression]);

  useEffect(() => {
    setCursor(lastCursorPos);
  }, [lastCursorPos]);

  return (
    <Box width="100%" position="relative">
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        disablePortal={false}
        sx={{ width: anchorEl?.clientWidth }}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ maxHeight: '300px', overflow: 'auto' }}>
              <ClickAwayListener onClickAway={() => {}}>
                <MenuList dense id="composition-menu" aria-labelledby="composition-button">
                  {options.map((item, index) => (
                    <MenuItem
                      key={`${item.key}_${index}`}
                      onClick={(e) => {
                        const value = item.value;
                        addItemToExpression(value);
                      }}
                    >
                      {item.key}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Box display="grid" width="100%">
        <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
          {itemLabel}
        </FormLabel>
        <TextField
          ref={ref}
          sx={{
            '& .MuiOutlinedInput-input': {
              border: `1px dashed ${isBracketClosed ? '#B2D5ED' : 'red'}`,
              bgcolor: '#FFFFFF',
              borderRadius: '9px',
              boxShadow: '0px 0px 15px #00000010',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              display: 'none',
            },
          }}
          fullWidth
          size="medium"
          variant="outlined"
          margin="dense"
          type="text"
          onFocus={handleClick()}
          onBlurCapture={handleClosePopover}
          onClick={(e) => {
            // @ts-ignore
            setLastCursorPos(e.target.selectionStart);
          }}
          onKeyUp={(e) => {
            // @ts-ignore
            setLastCursorPos(e.target.selectionStart);
          }}
          onInput={(e) => {
            // @ts-ignore
            const val = e.target.value;
            handleInput(val);
          }}
          error={Boolean(error) || !isBracketClosed}
          helperText={error?.message || ' '}
          {...register(keyName, {
            shouldUnregister: true,
            required: isRequired ? 'Expression is required' : false,
          })}
        />
      </Box>
    </Box>
  );
}
