import { DEFAULT_GROUP_SETTINGS } from '@/assets/schemes/defaultValues';
import { GroupSettingsDateSelection } from '@/web/administration/device-groups/_id/settings/@components/Forms/GroupSettingsDateSelection';
import { GroupSettingsDateTimeSelection } from '@/web/administration/device-groups/_id/settings/@components/Forms/GroupSettingsDateTimeSelection';
import { GroupSettingsDaySelection } from '@/web/administration/device-groups/_id/settings/@components/Forms/GroupSettingsDaySelection';
import { GroupSettingsTimeSelection } from '@/web/administration/device-groups/_id/settings/@components/Forms/GroupSettingsTimeSelection';
import { Box, TextField } from '@mui/material';
import { addDays, format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

/**
 * @typedef {object} DateTimePickerFormProps
 * @property {GroupSettingsChildItems} item
 * @property {string} name
 */

/** @param {DateTimePickerFormProps} props */
export function DateTimePickerForm(props) {
  const { item, name } = props;
  const { control } = useFormContext();

  const properties = useWatch({ control, name: `properties` });

  const nextDay = format(addDays(new Date(), 1), 'yyyy-MM-dd');
  const [date, setDate] = useState(`${nextDay}T19:30`);
  const [time, setTime] = useState('15:45');
  const [day, setDay] = useState('Sunday');
  const [dateInt, setDateInt] = useState(25);
  const [firsTimeLoad, setFirsTimeLoad] = useState(true);

  const getAddedSettings = useMemo(() => {
    let resultObject = {};
    properties?.forEach((entity) => {
      if (entity?.item[0]?.value === 'SETTINGS') {
        const lastItem = entity?.item[entity?.item?.length - 1];
        resultObject[lastItem?.value] = entity?.value?.value || entity?.value;
      }
    });
    return resultObject;
  }, [properties]);

  const { getValues, setValue } = useFormContext();

  const initValues = useMemo(() => getValues(name), [name, getValues]);

  const dependentValue = item?.dependentKey
    ? getAddedSettings[item?.dependentKey] || DEFAULT_GROUP_SETTINGS[item?.dependentKey]
    : 'DAILY';

  const handleOnChange = useCallback(() => {
    let updateValue;
    switch (dependentValue) {
      case 'SCHEDULED':
        const splittedTimestamp = date ? date?.split('T') : [];
        const formattedDate =
          splittedTimestamp?.length > 1
            ? `${format(parseISO(splittedTimestamp[0]), 'dd/MM/yyyy')} ${splittedTimestamp[1]}`
            : '';

        updateValue = formattedDate;
        break;
      case 'NEXT_DAY':
      case 'DAILY':
        updateValue = time ? time : '';
        break;
      case 'NEXT_WEEK':
      case 'WEEKLY':
        updateValue = `${day || ''} ${time || ''}`;
        break;
      case 'MONTHLY':
        updateValue = `${dateInt || ''} ${time || ''}`;
        break;
      default:
        updateValue = '';
        break;
    }

    if (typeof updateValue !== 'undefined') {
      setValue(name, updateValue);
    }
  }, [dependentValue, date, time, day, dateInt, setValue, name]);

  const convertDateForInput = (date, time) => {
    const dateSplit = date?.split('/');
    return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${time}`;
  };

  const dateTimeModification = useCallback(() => {
    const selectedTime = initValues;

    const splittedTime = selectedTime && selectedTime?.split(' ');
    if (!splittedTime || splittedTime?.length === 0) return;
    switch (dependentValue) {
      case 'SCHEDULED':
        splittedTime[0] &&
          splittedTime[1] &&
          setDate(convertDateForInput(splittedTime[0], splittedTime[1]));
        break;
      case 'NEXT_DAY':
      case 'DAILY':
        selectedTime && setTime(selectedTime);
        break;
      case 'NEXT_WEEK':
      case 'WEEKLY':
        splittedTime[0] && setDay(splittedTime[0]);
        splittedTime[1] && setTime(splittedTime[1]);
        break;
      case 'MONTHLY':
        splittedTime[0] && setDateInt(splittedTime[0]);
        splittedTime[1] && setTime(splittedTime[1]);
        break;
      default:
        break;
    }
  }, [dependentValue, initValues]);

  useEffect(() => {
    if (!firsTimeLoad) {
      handleOnChange();
    }
  }, [dependentValue, firsTimeLoad, handleOnChange]);

  useEffect(() => {
    if (firsTimeLoad && typeof dependentValue !== 'undefined') {
      dateTimeModification();
      setFirsTimeLoad(false);
    }
  }, [item.dependentKey, firsTimeLoad, dateTimeModification, setFirsTimeLoad, dependentValue]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        flexWrap: 'nowrap',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'baseline' }}>
        {dependentValue === 'SCHEDULED' ? (
          <GroupSettingsDateTimeSelection date={date} setDate={setDate} isDisabled={true} />
        ) : ['DAILY', 'NEXT_DAY'].includes(dependentValue) ? (
          <GroupSettingsTimeSelection time={time} setTime={setTime} isDisabled={true} />
        ) : ['WEEKLY', 'NEXT_WEEK'].includes(dependentValue) ? (
          <Box display="flex" width="100%" gap="10px">
            <GroupSettingsDaySelection day={day} setDay={setDay} isDisabled={true} />
            <GroupSettingsTimeSelection time={time} setTime={setTime} isDisabled={true} />
          </Box>
        ) : dependentValue === 'MONTHLY' ? (
          <Box display="flex" width="100%" gap="10px">
            <GroupSettingsDateSelection
              dateInt={Number(dateInt)}
              setDateInt={setDateInt}
              isDisabled={true}
            />
            <GroupSettingsTimeSelection time={time} setTime={setTime} isDisabled={true} />
          </Box>
        ) : (
          <TextField
            fullWidth
            disabled
            className="form-control"
            size="medium"
            placeholder="Disabled due to dependent field value selection."
          />
        )}
      </Box>
    </Box>
  );
}
