import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { VinGroupForm } from '../../@components/VinGroupForm';
import { VinGroupDetailsContext } from '../@components/VinGroupDetailsLayout';

export function EditVinGroupPage() {
  const navigate = useNavigate();
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const { groupId, vinGroup } = useContext(VinGroupDetailsContext);

  const [loading, setLoading] = useState(false);

  /** @type {import('../../@components/VinGroupForm').VinGroupFormProps['onSubmitData']} */
  const updateGroup = async ({ groupName, groupDescription, cameraGroup }) => {
    try {
      setLoading(true);
      const req = api.ac.v5['vin-groups'].$id(groupId).$patch({
        headers: {
          Authorization: secretToken,
        },
        data: {
          name: groupName,
          description: groupDescription,
          targetGroupId: cameraGroup?.value,
        },
      });
      await req.process();
      toastSuccess('Success', 'VIN Group Updated successfully');
      navigate('/administration/vin-groups/');
    } catch (err) {
      toastWarning('Error', 'Failed to update, Please try later.');
    } finally {
      setLoading(false);
    }
  };

  // Navigate vin groups list page while tenant change
  useEffect(() => {
    return () => navigate('/administration/vin-groups/');
  }, [tenantId, navigate]);

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Update VIN Group
      </Typography>
      <VinGroupForm vinGroup={vinGroup} onSubmitData={updateGroup} loading={loading} />
    </Box>
  );
}
