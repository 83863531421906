import { Box } from '@mui/material';

/**
 * @typedef {object} CircleBoxPropsBase
 * @property {number} [pulseDistance]
 * @property {boolean} [pulse] Show pulse animation
 * @property {import('@mui/system').ResponsiveStyleValue<any>} [size]
 */

/**
 * @typedef {CircleBoxPropsBase & import('@mui/material').BoxProps} CircleBoxProps
 */

/**
 * @param {CircleBoxProps} props
 */
export function CircleBox(props) {
  const { width, height, pulse, pulseDistance = 5, size = 'auto', ...extra } = props;
  return (
    <Box
      width={size}
      height={size}
      borderRadius="50%"
      bgcolor="#5F6B7E"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...extra}
      sx={{
        'animation': pulse ? 'pulse-animation 2s infinite' : null,
        '@keyframes pulse-animation': {
          '0%': {
            boxShadow: '0 0 0 0px rgba(247,136,136, 0.8)',
          },
          '100%': {
            boxShadow: `0 0 0 ${pulseDistance}px rgba(247,136,136, 0)`,
          },
        },
        ...extra?.sx,
      }}
    />
  );
}
