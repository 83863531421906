import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';
import { listOfHighways, speedOffsetTypes } from '../options';

/** @type {Array<GroupSettingsItems>} */
export const trafficSettings = [
  {
    title: 'Traffic',
    key: GROUP_SETTINGS_CATEGORIES.TRAFFIC,
    children: [
      {
        label: 'Traffic Features',
        key: 'trafficFeatureEnabled',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Map Download Size',
        key: 'mapDownloadSize',
        type: FROM_TYPES.INPUT,
        postText: 'meter',
        min: 1000,
        max: 50000,
        inputType: 'number',
        isDisabled: (watch) => watch('trafficFeatureEnabled') === false,
      },
      {
        label: 'Highways',
        key: 'highways',
        type: FROM_TYPES.MULTISELECT,
        options: listOfHighways,
        isDisabled: (watch) => watch('trafficFeatureEnabled') === false,
      },
      {},
      {
        label: 'Speed Offset',
        key: 'speedOffset',
        type: FROM_TYPES.INPUT,
        min: 0,
        max: 100,
        inputType: 'number',
        isDisabled: (watch) => watch('trafficFeatureEnabled') === false,
      },
      {
        label: 'Speed Offset Unit',
        key: 'speedOffsetUnit',
        type: FROM_TYPES.SELECT,
        options: speedOffsetTypes,
        isDisabled: (watch) => watch('trafficFeatureEnabled') === false,
      },
    ],
  },
];
