import { T } from '@/assets/locales';
import { trimString } from '@/utils/formatting';
import { CustomInput } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DBCFileUpload } from '../DBCFileUpload';

/**
 * @typedef {object} TypeProps
 * @property {string} [label]
 * @property {string} [value]
 */

/**
 * @typedef {object} DbcFileFormFields
 * @property {File} dbcFile
 * @property {string} dbcId
 * @property {string} name
 * @property {string} version
 * @property {string} storedFileNamePrev
 * @property {string} description
 * @property {TypeProps} type
 */

/**
 * @typedef {object} DBCFileFormProps
 * @property {boolean} disabled
 * @property {string} [dbcId]
 * @property {DbcFileDto} [dbcDetails]
 * @property {import('react-hook-form').SubmitHandler<DbcFileFormFields>} onSubmit
 */

/**
 * @param {DBCFileFormProps} props
 */
export function DBCFileForm(props) {
  const { dbcId, onSubmit, disabled, dbcDetails } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  /** @type {import('react-hook-form').UseFormReturn<DbcFileFormFields>} */
  const form = useForm({
    mode: 'all',
    defaultValues: {
      dbcId: dbcId ?? uuidv4(),
      name: '',
      version: '',
      description: '',
      type: null,
      dbcFile: null,
      storedFileNamePrev: '',
    },
  });

  useEffect(() => {
    if (!dbcDetails) return;
    form.setValue('dbcId', dbcDetails.id);
    form.setValue('name', dbcDetails.name);
    // @ts-ignore
    form.setValue('version', dbcDetails.version);
    form.setValue('description', dbcDetails.description);
    form.setValue('storedFileNamePrev', dbcDetails.fileName);
  }, [dbcDetails, form]);

  const handleCancel = () => {
    navigate('/administration/dbc');
  };

  /** @param {File} file */
  const handleDbcFileFileUpload = async (file) => {
    form.setValue('dbcFile', file);
  };

  /** @param {DbcFileFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmit) return;
    data.name = trimString(data.name);
    data.description = trimString(data.description);
    return onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <Typography variant="body2" fontSize="1rem" mt={'20px'} mb={'5px'} fontWeight={500}>
        DBC File
      </Typography>
      <Divider />

      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={1} mt={2}>
          <Grid item xs={12} md={6}>
            <CustomInput
              disabled={!!dbcId}
              name="name"
              label="Name *"
              placeholder="Enter a DBC File name"
              rules={{ required: 'Name is required' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="description"
              label="Description "
              placeholder="Enter a description"
            />
          </Grid>
        </Grid>

        <DBCFileUpload
          name="dbcFile"
          dbcId={dbcDetails?.id}
          onDropFile={handleDbcFileFileUpload}
          label="Upload File *"
          rules={{ required: 'This is required' }}
        />

        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
          <Button variant="text" onClick={handleCancel}>
            {t(T['button.cancel'])}
          </Button>
          <Button disabled={disabled} variant="contained" type="submit">
            {disabled && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {dbcDetails ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
      </form>
      <Box height="30px" />
    </FormProvider>
  );
}
