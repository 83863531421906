import storage from 'redux-persist/lib/storage';

/** @type {Array<keyof import('./slice').InitialState>} */
const whitelist = [
  // add state items you want to persist in local storage
  'deviceId',
  // 'sessionId',
  // 'sessionIdExpiresAt',
  // 'accessToken',
  // 'connectionUrl',
];

/** @type {import('redux-persist').PersistConfig<HubConnectionState>} */
export const hubPersistConfig = {
  key: 'hub-connection',
  version: 1,
  storage,
  whitelist,
  migrate: async (state, version) => {
    if (state._persist.version !== version) return null;
    return state;
  },
};
