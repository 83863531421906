import { T } from '@/assets/locales';
import { useFullProductList } from '@/hooks/useFullProductList';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} DeviceGroupFormFields
 * @property {string} groupName
 * @property {string} groupDescription
 * @property {import('@/types').ProductProperties} [product]
 */

/**
 * @typedef {object} DeviceGroupFormProps
 * @property {import('react-hook-form').SubmitHandler<DeviceGroupFormFields>} onSubmitData
 * @property {boolean} loading
 * @property {GroupV2} [group]
 */

/**
 * @param {DeviceGroupFormProps} props
 */
export function DeviceGroupForm(props) {
  const { group, onSubmitData, loading } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { result: products, loading: productLoading } = useFullProductList();

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      groupName: group?.name || '',
      groupDescription: group?.groupDescription || '',
      product: null,
    },
  });

  useEffect(() => {
    form.setValue('groupName', group?.name || '');
  }, [group?.name, form]);

  useEffect(() => {
    form.setValue('groupDescription', group?.groupDescription || '');
  }, [group?.groupDescription, form]);

  useEffect(() => {
    if (!group || !products?.length) return;
    const product = products.find((item) => item.productId === group.associatedTypeId);
    form.setValue('product', product);
  }, [products, group, form]);

  /** @param {DeviceGroupFormFields} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.groupName = trimString(data.groupName);
    data.groupDescription = trimString(data.groupDescription);
    onSubmitData(data);
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Box
          display="grid"
          gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
          gap="10px"
          mt={2}
        >
          <CustomInput
            name="groupName"
            label={t(T['device-group.form.group.name.label'])}
            placeholder={t(T['device-group.form.group.name.placeholder'])}
            rules={{ required: t(T['device-group.form.group.name.hint']) }}
          />

          <CustomSelect
            name="product"
            label={t(T['device-group.form.select.product.label'])}
            loading={productLoading}
            options={products}
            disabled={!!group}
            getKey="productId"
            getLabel="productName"
            placeholder={t(T['device-group.form.select.product.placeholder'])}
            rules={{ required: t(T['device-group.form.select.product.hint']) }}
          />

          <CustomInput
            name="groupDescription"
            label={t(T['pages.administration.deviceGroups.form.description.name'])}
            placeholder={t(T['device-group.form.description.placeholder'])}
            TextFieldProps={{
              minRows: 3,
              multiline: true,
            }}
          />
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={4}>
          <Button variant="text" onClick={() => navigate('/administration/device-groups')}>
            {t(T['button.cancel'])}
          </Button>
          <Button disabled={loading} variant="contained" type="submit">
            {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {group ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
