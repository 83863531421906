import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';

import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { sendEventsToDevices, sendOtaGroupMessages } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { DialogTable } from '@/web/@components/DialogTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeviceGroupDetailsContext } from '../DeviceGroupDetailsLayout/index';
import { GROUP_CAMERA_TABLE_COLUMNS } from './columns';

/** @param {{cameras: Array<Endpoint>, onModalClose: () => any}} props */
export function CameraAddModal(props) {
  const { cameras, onModalClose } = props;
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { setTableReload } = useContext(PaginatedTableContext);
  const { groupId, group } = useContext(DeviceGroupDetailsContext);

  const productId = useMemo(() => group?.associatedTypeId, [group]);

  const [selectedCameraItems, setSelectedCameraItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    result = [],
    loading: deviceLoading,
    error: deviceError,
  } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      if (!productId) return;
      let limit = 100;
      let offset = 0;
      /** @type {Array<Endpoint>} */
      const results = [];
      while (!signal.aborted) {
        const request = api.ac.endpoint.list.byproduct.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit: limit,
            offset,
            productId,
            tenantId,
            secretToken,
            endpointType: 'DEVICE',
          },
        });
        await request.process();
        const endpoints = request.result.endpoints;
        const filteredEndpoint = endpoints.filter(
          ({ id, status }) => !cameras.some((x) => x.id === id) && status === 'ACTIVE'
        );
        results.push(...filteredEndpoint);
        offset += endpoints.length;
        if (endpoints.length < limit) break;
      }
      return results;
    },
    [productId]
  );

  const handleAddDevice = () => {
    if (!selectedCameraItems || selectedCameraItems.length === 0) {
      toastWarning('Please select a device');
      return;
    }
    try {
      setLoading(true);
      (selectedCameraItems || []).forEach((item) => {
        const request = api.ac.endpoint.group.change.resource_group_id.$put({
          params: {
            secretToken,
            toGroupId: groupId,
            tenantId,
            groupResourceType: 'DEVICE',
            resourceId: item.id,
          },
        });
        request.process().then((res) => {
          const eventText = JSON.stringify({ groupId: groupId });
          sendEventsToDevices([item.id], MESSAGE_TYPE.DEVICE_GROUP_CHANGE, eventText);
          setTableReload(true);
        });
      });
      sendOtaGroupMessages(groupId, MESSAGE_TYPE.DEVICE_GROUP_CHANGE);
      toastSuccess(
        `${selectedCameraItems.length} device(s) has been added to ${group?.name || 'group'}`
      );
    } catch (error) {
      toastWarning(`Failed to add device to this group`);
    } finally {
      setLoading(false);
      onModalClose();
    }
  };

  return (
    <Dialog open={true} onClose={onModalClose} maxWidth={'lg'} fullWidth={true}>
      <DialogTitle>Add Cameras to group</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogTable
          title="Cameras"
          loading={deviceLoading}
          error={deviceError}
          results={result}
          columns={GROUP_CAMERA_TABLE_COLUMNS}
          dataSortKey="label"
          onSelectItem={setSelectedCameraItems}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onModalClose}>Cancel</Button>
        <Button onClick={handleAddDevice} autoFocus variant="contained" disabled={loading}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
