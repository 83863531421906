import { implement } from './@utils/apply';
import { ApiRequest } from './@utils/request';
export * from './@utils/apply';
export * from './@utils/progress';
export * from './@utils/request';

const api = {
  ac: require('./ac-service'),
};

for (const item of Object.values(api)) {
  implement(item, (config) => new ApiRequest(config));
}

export default api;
