import { EllipsisTextViewContainer } from '@/web/@components/EllipsisTextView';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { Box, Chip } from '@mui/material';
import { TriggerVariableNameColumn } from './@components/TriggerVariableNameColumn';

/** @type {Array<TableColumn<VariableDefinitionDto>>} */
export const TRIGGER_VARIABLES_TABLE_COLUMNS = [
  {
    id: 'name',
    label: '',
    align: 'left',
    width: '1%',
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'name'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    width: '30%',
    isPrimary: true,
    searchable: true,
    sort: true,
    maxWidth: '400px',
    format: (value, row) => (
      <Box height="30px" pt="5px">
        <EllipsisTextViewContainer>
          <TriggerVariableNameColumn row={row} />
        </EllipsisTextViewContainer>
      </Box>
    ),
  },
  {
    id: 'defaultValue',
    label: 'Default',
    align: 'center',
    searchable: true,
    sort: true,
  },
  {
    id: 'unit',
    label: 'Unit',
    align: 'center',
    searchable: true,
    sort: true,
  },
  {
    id: 'customerFacing',
    label: 'Customer Facing',
    align: 'center',
    width: '15%',
    searchable: true,
    sort: true,
    format: (value, row) => (
      <Chip
        label={value ? 'Yes' : 'No'}
        size="small"
        variant="outlined"
        color={value ? 'success' : 'warning'}
      />
    ),
  },
];
