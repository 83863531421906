import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken } from '@/store/auth';
import { sendEventsToDevices } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { sortedUniqBy, uniqBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CameraSettingsFormItem } from './CameraSettingsFormItem';

export function CameraSettings() {
  const { cameraId, capabilities, rawCapabilities } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);
  const { t } = useTranslation();

  const newSetting = { name: '', type: 'NUMBER', value: '', isDeletable: true };

  const [loading, setLoading] = useState(false);
  const [initSettings, setInitSettings] = useState([]);

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      settings: [newSetting],
    },
  });

  const { control, handleSubmit, setValue, formState, reset } = form;

  const { isDirty } = formState;

  const { fields, remove, prepend } = useFieldArray({ control, name: 'settings' });

  const splitStringByKey = (string, key) => {
    let concatItems = [];
    string
      ?.filter((item) => item.indexOf(key) >= 0)
      ?.map((item) => (concatItems = [...concatItems, ...JSON.parse(item?.split('=')[1])]));
    return uniqBy(concatItems, 'name');
  };

  async function onSubmit(data) {
    const traitProperties = data.settings.map((item) => {
      item?.isDeletable && delete item.isDeletable;
      return item;
    });

    rawCapabilities.properties = rawCapabilities.properties.filter(
      (item) => item.key !== 'TRAITS_DEVICE_SETTINGS'
    );
    rawCapabilities.properties.push({
      key: 'TRAITS_DEVICE_SETTINGS',
      value: JSON.stringify(traitProperties),
    });

    try {
      setLoading(true);
      const request = api.ac.endpoint.capabilities.$post({
        params: {
          endpointId: cameraId,
          secretToken,
        },
        data: rawCapabilities,
      });
      request.process();
      sendEventsToDevices([cameraId], MESSAGE_TYPE.UPDATE_CAMERA_SETTINGS);
      toastSuccess('Camera settings updated successfully!');
    } catch (ex) {
      toastWarning('Failed to update camera settings!');
    } finally {
      setLoading(false);
    }
  }

  const handleCancel = () => {
    setValue('settings', initSettings);
    reset({}, { keepValues: true });
  };

  useEffect(() => {
    const traitProperties = capabilities?.TRAITS_AI_MODELS;
    const splitProperties = traitProperties?.split('\n');
    const aiModels = splitStringByKey(splitProperties, `modelParams=`) || [];

    const traitDeviceProperties = capabilities?.TRAITS_DEVICE_SETTINGS;
    const parsedDeviceSettings =
      traitDeviceProperties && sortedUniqBy(JSON.parse(traitDeviceProperties), 'name');
    const formattedSettings = (parsedDeviceSettings || []).map((settings) => {
      // @ts-ignore
      const { name, type, value } = settings;
      return {
        name,
        type,
        value,
        // @ts-ignore
        isDeletable: !aiModels.find((modelItem) => modelItem.name === settings.name),
      };
    });
    // merge and Override the TRAITS_AI_MODELS value with TRAITS_DEVICE_SETTINGS if duplicate
    const uniqNames = new Set(formattedSettings.map((d) => d.name));
    const data = [...formattedSettings, ...aiModels.filter((d) => !uniqNames.has(d.name))];
    setInitSettings(data);
    setValue('settings', data);
  }, [capabilities, setValue]);

  return (
    <Box px={2.5} pb={15}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            zIndex={9}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            position="sticky"
            top={0}
            height="50px"
            p={1}
            mb="5px"
            bgcolor="white"
            borderBottom="1px solid #c5d9f0"
          >
            <Typography variant="body1" fontWeight="500" fontSize="1rem">
              {t([T['button.settings']])}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => prepend(newSetting)}
              startIcon={<AddOutlined />}
            >
              {t([T['button.settings']])}
            </Button>
          </Box>

          {fields.length > 0 ? (
            fields.map((itemField, fieldIndex) => (
              <Box key={itemField.id} borderLeft="3px solid #608AC3" py={0.5} pl={1} mb={1}>
                <CameraSettingsFormItem remove={remove} fieldIndex={fieldIndex} />
              </Box>
            ))
          ) : (
            <CenterBox>
              <IconMessageBox
                src={NO_SEARCH_RESULT_IMG}
                size="100px"
                message="No Settings Available"
              />
            </CenterBox>
          )}

          {isDirty && (
            <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} px={2} py={1}>
              <Button variant="text" onClick={handleCancel}>
                {t(T['button.reset'])}
              </Button>
              <Button disabled={loading} variant="contained" type="submit">
                {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
                {t(T['button.update'])}
              </Button>
            </Box>
          )}
        </form>
      </FormProvider>
    </Box>
  );
}
