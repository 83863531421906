/**
 * @typedef {typeof import('./en/translation')} TRANSLATION
 */

export const T = {
  ...require('./en/translation'),
};

export const resources = {
  en: {
    translation: require('./en/translation'),
  },
  bn: {
    translation: require('./bn/translation'),
  },
  ar: {
    translation: require('./ar/translation'),
  },
  ru: {
    translation: require('./ru/translation'),
  },
  uk: {
    translation: require('./uk/translation'),
  },
};

function hydrateTemplate(template, path = []) {
  for (const key of Object.keys(template)) {
    if (typeof template[key] !== 'string') {
      hydrateTemplate(template[key], [...path, key]);
    } else {
      template[key] = [...path, key].join('.');
    }
  }
}

hydrateTemplate(T);
