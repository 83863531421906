/** @type {Array<SoftwarePageCardItem>} */
export const SENSOR_TAB_ITEMS = [
  {
    key: 'video',
    label: 'Video Imagers',
  },
  {
    key: 'sensor',
    label: 'Sensors',
  },
  {
    key: 'audio',
    label: 'Audio',
  },
  {
    key: 'digitalIO',
    label: 'Digital I/O',
  },
];
