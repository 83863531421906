const { default: api } = require('@/api');
const { store } = require('@/store');
const { selectSecretToken, selectTenantId, selectCurrentTenantName } = require('@/store/auth');

const SNAPSHOT_STATISTICS_REPORT_INTERVAL = 3000;

/**
 * @typedef {object} SnapshotStatisticsReportData
 * @property {string} source
 * @property {string} url
 * @property {boolean} success,
 * @property {number} duration
 */

class SnapshotReporter {
  /** @type {NodeJS.Timeout} */
  #iid;
  /** @type {{[key: string]: SnapshotStatistics}} */
  _queue = {};

  constructor() {
    this.$start();
  }

  $start = () => {
    this.#iid = setInterval(
      () => this.sendReport(), //
      SNAPSHOT_STATISTICS_REPORT_INTERVAL
    );
  };

  $stop = () => {
    clearInterval(this.#iid);
  };

  async sendReport() {
    const original = this._queue;
    this._queue = {};
    try {
      const data = Object.values(original);
      if (!data.length) return;
      const state = store.getState();
      const request = api.ac.v5.events.snapshot.statistics.$post({
        data,
        headers: {
          Authorization: selectSecretToken(state),
        },
      });
      await request.process();
    } catch (err) {
      this._queue = { ...original, ...this._queue };
      console.error('Failed to report snapshots');
    }
  }

  /**
   * @param {EventV5ResponseModel | EventV2} item
   * @param {SnapshotStatisticsReportData} data
   */
  saveReport(item, data) {
    if (!item || !data) return;
    const state = store.getState();
    this._queue[`${item.id}_${data.source}`] = {
      eventId: item.id + '',
      occurrenceTimestamp: Date.now(),
      clientType: 'BROWSER',
      endpointId: item.endpointId,
      loadingDuration: data.duration,
      success: data.success,
      tenantId: selectTenantId(state),
      tenantName: selectCurrentTenantName(state),
      loadingPage: window.location.href,
      triggerId: item.eventTriggerId,
      failureReason: data.success ? null : data.url ? 'corrupted' : 'missing',
      snapshotUrl: data.url,
      mediaType: data.source,
    };
  }
}

const instance = new SnapshotReporter();

/**
 * @param {EventV5ResponseModel | EventV2} item
 * @param {SnapshotStatisticsReportData} data
 */
export const reportSnapshotStatistics = (item, data) => {
  instance.saveReport(item, data);
};
