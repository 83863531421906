import { T } from '@/assets/locales';
import { CreateDeviceGroup } from '@/web/administration/device-groups/create';
import { DeviceGroupListView } from '@/web/administration/device-groups/index';
import { UpdateDeviceGroup } from '@/web/administration/device-groups/_id/edit';
import { DeviceGroupDetails } from './_id';
import { DeviceGroupDetailsLayout } from './_id/@components/DeviceGroupDetailsLayout';
import { GroupSettings } from './_id/settings';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DeviceGroupListView />,
  },
  {
    path: 'create',
    element: <CreateDeviceGroup />,
    breadcrumb: T['device-group.create.page.title'],
  },
  {
    path: ':id',
    breadcrumb: 'Details',
    element: <DeviceGroupDetailsLayout />,
    children: [
      {
        path: '',
        element: <DeviceGroupDetails />,
      },
      {
        path: 'edit',
        element: <UpdateDeviceGroup />,
        breadcrumb: T['button.edit'],
      },
      {
        path: 'settings',
        element: <GroupSettings />,
        breadcrumb: 'Settings',
        teamAccess: ['FRONT_END_TEAM'],
      },
      {
        path: 'cameras',
        breadcrumb: 'Cameras',
        children: require('../device-groups/_id/cameras/manage-cameras/routes').default,
      },
      {
        path: 'apns',
        children: require('../device-groups/_id/apns/routes').default,
        breadcrumb: 'APN Settings',
      },
    ],
  },
];

export default routes;
