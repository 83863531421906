/**
 * @typedef {object} FleetScoreCircularChart
 * @property {number} [value]
 * @property {string} [height]
 */

/**
 * @param {FleetScoreCircularChart} props
 */
export function FleetScoreCircularChart(props) {
  const { value = 0, height } = props;

  // const statusText = useMemo(
  //   () => (value >= 90 ? 'Excellent' : value >= 80 ? 'Good' : 'Poor'),
  //   [value]
  // );

  const innerRadius = 82;
  const innerPerimeter = 2 * innerRadius * Math.PI;
  const innerValues = [0.84 * 1, 0.84 * 0.9, 0.84 * 0.8];
  const innerColors = ['#2680EB', '#789BCB', '#A9B5C6'];

  const angle = (330 - 30) * (value / 100) + 30;
  const valueColor = innerColors[value > 90 ? 0 : value > 80 ? 1 : 2];

  return (
    <svg
      width="100%"
      height={height || '100%'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
    >
      <g>
        {innerValues.map((x, i) => (
          <ellipse
            key={i}
            cx="100"
            cy="100"
            rx={innerRadius}
            ry={innerRadius}
            fill="none"
            strokeWidth="12"
            strokeLinecap="round"
            stroke={innerColors[i]}
            strokeDasharray={`${x * innerPerimeter} ${(1 - x) * innerPerimeter}`}
            transform="translate(236 62) rotate(119)"
            opacity={value > 0 ? 1 : 0.4}
            filter={value > 0 ? '' : 'grayscale(80%)'}
          />
        ))}
        <g transform={`translate(100 100) rotate(${90 + angle})`}>
          <ellipse cx="80" cy="0" rx="13" ry="11" fill="#ffffff" />
          <ellipse cx="82" cy="0" rx="8" ry="8" fill={valueColor} />
        </g>

        {/* {value >= 80 ? (
          <path d={'M65,80,L58,90,L72,90Z'} fill="#8AB24C" strokeWidth="2" />
        ) : (
          <path d={'M69,88,L62,80,L75,80Z'} fill="#cd1717" strokeWidth="2" />
        )} */}

        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={50}
          fontWeight={600}
          fill="#213251"
          opacity={value > 0 ? 1 : 0.6}
          filter={value > 0 ? '' : 'grayscale(80%)'}
        >
          {value}
        </text>
        {/* <text
          x="50%"
          y="58%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={12}
          fontWeight={500}
          fill="#4676b7"
          opacity="1.000000"
        >
          {statusText}
        </text> */}
      </g>
    </svg>
  );
}
