import { useRouteQuery } from '@/hooks/useRouteQuery';
import { EventFilterContextProvider } from '@/web/@components/EventFilterContext';
import { FilterContextProvider } from '@/web/@components/FilterContext';
import { FilteredDateRangePicker } from '@/web/@components/FilteredDateRangePicker';
import { EventCountHeader } from '@/web/events/@components/EventCountHeader';
import { EventInfiniteList } from '@/web/events/@components/EventInfiniteList';
import { EventItemSearch } from '@/web/events/@components/EventItemSearch';
import { EventListContextProvider } from '@/web/events/@components/EventListContextProvider';
import { Box, Hidden } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export function CameraEventsPage() {
  const params = useParams();
  const { query } = useRouteQuery();

  const cameraId = useMemo(() => Number(params.cameraId), [params?.cameraId]);

  if (query.layout === 'empty') {
    return (
      <FilterContextProvider>
        <EventFilterContextProvider>
          <EventListContextProvider cameraId={cameraId}>
            <EventInfiniteList fullHeight />
          </EventListContextProvider>
        </EventFilterContextProvider>
      </FilterContextProvider>
    );
  }

  return (
    <FilterContextProvider>
      <EventFilterContextProvider>
        <EventListContextProvider cameraId={cameraId}>
          <Box px={2.5} mt={1} display="flex" gap={1} alignItems="center">
            <EventCountHeader />
            <Box flex={1} />
            <Hidden mdDown>
              <EventItemSearch cameraId={cameraId} />
              <FilteredDateRangePicker />
            </Hidden>
          </Box>
          <Hidden mdUp>
            <Box px={2} mt={0.5}>
              <EventItemSearch cameraId={cameraId} />
              <FilteredDateRangePicker />
            </Box>
          </Hidden>
          <Box mt={2}>
            <EventInfiniteList />
          </Box>
        </EventListContextProvider>
      </EventFilterContextProvider>
    </FilterContextProvider>
  );
}
