import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { capitalize, startCase } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { NETWORK_CHANGES } from '../../@assets/usages';

/**
 * @typedef {object} CameraNetworkChangeModalProps
 * @property {CameraTypeRecord} [row]
 * @property {() => any} [onCancel]
 */

/** @param {CameraNetworkChangeModalProps} props */
export function CameraNetworkChangeModal(props) {
  const { row, onCancel } = props;

  const [expanded, setExpanded] = useState(null);

  const handleChange = (item) => {
    if (item === expanded && item === 'wifi') {
      setExpanded('cellular');
    } else if (item === expanded && item === 'cellular') {
      setExpanded('wifi');
    } else {
      setExpanded(item);
    }
  };

  const items = useMemo(() => {
    const data = {};
    Object.keys(row)?.forEach((key) => {
      if (key.startsWith(expanded)) data[key] = row[key];
    });
    return data;
  }, [expanded, row]);

  const networkItemValue = (key) => {
    if (key?.includes('dns')) {
      return items[key]?.replace(/[[\]/]/g, '');
    }
    return items[key];
  };

  useEffect(() => {
    if (row?.wifi_available === 'yes') {
      setExpanded('wifi');
    } else {
      setExpanded('cellular');
    }
  }, [row?.wifi_available]);

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Network Details</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            '& .MuiPaper-elevation': {
              border: '1px solid #c5d9f0',
              borderRadius: '5px',
            },
          }}
          height="100%"
        >
          {NETWORK_CHANGES.map((item, index) => (
            <Accordion
              key={index + item.key}
              disableGutters
              elevation={0}
              square
              TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
              expanded={expanded === item.key}
              onChange={(e) => handleChange(item.key)}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDown fontSize="large" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.label}</Typography>
              </AccordionSummary>
              <AccordionDetails
                key={item.key}
                sx={{ height: '100%', borderTop: '1px solid #c5d9f0', overflowY: 'auto' }}
              >
                <table style={{ width: '100%' }}>
                  <tbody>
                    {Object.keys(items)?.map((key, index) => (
                      <tr
                        key={index + key}
                        style={{ background: index % 2 === 0 ? '#e9e9e9' : '' }}
                      >
                        <td style={{ width: '40%' }}>
                          <Typography variant="subtitle2" mx={1}>
                            {capitalize(startCase(key))}
                          </Typography>
                        </td>
                        <td style={{ width: '60%' }}>
                          <Typography variant="subtitle1" mx={1}>
                            {networkItemValue(key)}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
