import { T } from '@/assets/locales';
import { UserDetailsLayout } from '@/web/@layouts/UserDetailsLayout';
import { CreateUserPage } from '@/web/administration/users/create';
import { UserListView } from '@/web/administration/users/index';
import { UserDetailsPage } from './id/details';
import { UpdateUserPage } from './id/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <UserListView />,
  },
  {
    path: 'create',
    element: <CreateUserPage />,
    breadcrumb: T['users.page.card.createNew'],
  },
  {
    path: ':id',
    element: <UserDetailsLayout />,
    breadcrumb: 'User Details',
    children: [
      {
        path: '',
        element: <UserDetailsPage />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit/:accountId',
        element: <UpdateUserPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
