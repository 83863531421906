import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectTenantId, selectTenantList, selectTenantTree } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { SearchField } from '@/web/@components/SearchField';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import { uniq } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DevicePoolDetailsContext } from '../DevicePoolDetailsLayout';
import { DevicePoolSelection } from '../DevicePoolSelection';

/**
 * @typedef {object} DeviceTransferDialogProps
 * @property {string} [title]
 * @property {string} [message]
 * @property {boolean} [open]
 * @property {() => any} [onCancel]
 * @property {(value: DevicePoolInfoForTenant) => any} onSubmit
 */

/** @param {DeviceTransferDialogProps} props */
export function DeviceTransferDialog(props) {
  const { title, message, open, onCancel, onSubmit } = props;
  const { poolId } = useContext(DevicePoolDetailsContext);

  const tenantId = useSelector(selectTenantId);
  const tenantList = useSelector(selectTenantList);
  const tenantTree = useSelector(selectTenantTree);

  /** @type {StateVariable<string>} */
  const [filter, setFilter] = useState(null);
  /** @type {StateVariable<DevicePoolInfoForTenant>} */
  const [selected, setSelected] = useState(null);
  /** @type {StateVariable<TenantIdWithName>} */
  const [selectedTenant, setSelectedTenant] = useState(null);

  const flattenTenants = useCallback((tenants, result = []) => {
    for (const tenant of tenants) {
      result.push({
        tenantId: tenant.tenantId,
        tenantName: tenant.tenantName,
      });

      if (tenant?.descendantTenantList?.length > 0) {
        flattenTenants(tenant.descendantTenantList, result);
      }
    }
    return result;
  }, []);

  const { result = [], loading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      if (!open) return;
      const request = api.ac.v5.tenant.$tenantId(tenantId)['target-pool'].$get({
        signal,
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
      });
      const pools = await request.process();
      return pools.map((item) => item.pools).flat();
    },
    [open]
  );

  const filterredTenantList = useMemo(() => {
    const tenantIds = uniq(result?.map((item) => item.tenantId));
    const tenants = (flattenTenants(Object.values(tenantTree)) || tenantList).filter(
      (item) => tenantIds?.includes(item.tenantId) || item.tenantId === tenantId
    );
    setSelectedTenant(tenants.find((item) => item.tenantId === tenantId));
    return tenants;
  }, [result, tenantList, tenantId, tenantTree, flattenTenants]);

  /** @param {string} searchText */
  const filteredPools = useMemo(() => {
    if (!selectedTenant || !result?.length) return [];
    const newData = (result || []).filter(
      (item) => item.tenantId === selectedTenant.tenantId && item.id !== poolId
    );
    if (!filter) return newData;

    return newData
      .filter((pool) => pool.name?.toLowerCase().includes(filter))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [result, filter, selectedTenant, poolId]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onCancel} fullWidth keepMounted>
      <DialogTitle>{title || 'Choose Device Pool'}</DialogTitle>
      <Divider />
      <DialogContent>
        {message && <DialogContentText pb={'10px'}>{message}</DialogContentText>}

        <Box mb="10px">
          <FormLabel color="secondary" sx={{ mb: '4px', fontWeight: 500, fontSize: '0.875rem' }}>
            Tenant
          </FormLabel>
          {selectedTenant && (
            <Autocomplete
              fullWidth
              disablePortal
              disableClearable
              value={selectedTenant}
              options={filterredTenantList}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.tenantName)}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, newValue) => {
                // @ts-ignore
                setSelectedTenant(newValue);
                setSelected(null);
              }}
            />
          )}
        </Box>

        <Divider />

        <Grid container sx={{ m: 1 }}>
          <Grid item xs={12} md={6}>
            <Box my={1} fontWeight={500} textAlign={{ xs: 'center', md: 'left' }}>
              Device Pools
            </Box>
          </Grid>

          {filteredPools && (
            <Grid item xs={12} md={6}>
              <SearchField
                clearable
                ignoreCase
                onSearch={setFilter}
                placeholder="Search a device pool"
                sx={{
                  borderColor: 'transparent',
                  borderRadius: 10,
                  background: '#f7f7f7',
                }}
              />
            </Grid>
          )}
        </Grid>

        <Divider />

        {loading ? (
          <CenterBox sx={{ pt: '50px' }}>
            <CircularProgress />
          </CenterBox>
        ) : (
          <DevicePoolSelection pools={filteredPools} selected={selected} onSelect={setSelected} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!selected}
          onClick={selected ? () => onSubmit(selected) : null}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
