import { COACHING_ICON_ON, FEATURE_FLAG_ON_ICON } from '@/assets/constants/images';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { Typography } from '@mui/material';
import { camelCase, startCase } from 'lodash';
import { useMemo } from 'react';

const ITEM_IMAGES = {
  COACHING: COACHING_ICON_ON,
};

export function FeatureNameWithIcon({ name }) {
  const img = useMemo(() => ITEM_IMAGES[name] || FEATURE_FLAG_ON_ICON, [name]);

  return (
    <IconLabelItem
      src={img}
      iconSize={{
        xs: '17px',
        md: img.includes('events-n-off') ? '21px' : '25px',
      }}
      fontSize="0.875rem"
      pl={'4px'}
      sx={{
        alignItems: 'center',
        gap: '10px',
        alignContent: 'left',
      }}
      title={
        <Typography
          fontSize={{ sx: '1rem', md: '0.875rem' }}
          title={name}
          sx={{ textAlign: 'left' }}
        >
          {startCase(camelCase(name))}
        </Typography>
      }
    />
  );
}
