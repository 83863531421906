/** @type {Array<CameraSettingsFormOption>} */
export const CAMERA_SETTINGS_PARAMS_TYPES = [
  { value: 'BOOLEAN', label: 'Boolean' },
  { value: 'NUMBER', label: 'Number' },
  { value: 'STRING', label: 'String' },
];

/** @type {Array<CameraSettingsFormOption>} */
export const CAMERA_SETTINGS_PARAMS_THRESHOLD_BOOLEAN_VALUES = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];

/** @type {Array<CameraSettingsForm>} */
export const CAMERA_SETTINGS_DATA = [
  {
    name: 'name',
    label: 'Property Type *',
    type: 'input',
    mandatory: true,
    placeholder: 'Enter a property type',
  },
  {
    name: 'type',
    label: 'Value Type *',
    type: 'select',
    mandatory: true,
    placeholder: 'Select a type',
    options: CAMERA_SETTINGS_PARAMS_TYPES,
  },
  {
    name: 'value',
    label: 'Threshold Value *',
    mandatory: true,
    type: 'input',
    placeholder: 'Enter a threshold value',
    visibility: (type) => type === 'NUMBER' || type === 'STRING',
  },

  {
    name: 'value',
    label: 'Threshold Value *',
    mandatory: true,
    type: 'select',
    options: CAMERA_SETTINGS_PARAMS_THRESHOLD_BOOLEAN_VALUES,
    placeholder: 'Select a value',
    visibility: (type) => type === 'BOOLEAN',
  },
];
