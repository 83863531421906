import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/users/actions';
import { USER_TABLE_COLUMNS } from '@/web/administration/users/columns';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function UserListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { t } = useTranslation();

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<UserWithAccountDetails>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteUsers(selectedItems);
    }
    setTableReload(true);
  };

  const deleteUsers = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteUser));
  };

  const deleteUser = async (user) => {
    try {
      const request = api.ac.v5.user.$userId(user.userId).deactivate.$post({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', t(T['user.delete.success']));
    } catch (e) {
      toastWarning('Error', t(T['user.delete.failed']));
    }
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'Users'} actions={ACTION_ITEMS} onAction={onActionHandle} />
        <OffsetPagination
          key={generateUniqueKey([secretToken, 'users'])}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            const request = api.ac.v5.tenant.$tenantId(tenantId).user.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit: limit,
                offset,
                ...(searchText !== '' && searchType && { [searchType]: searchText }),
              },
            });
            await request.process();
            return {
              data: request.result.users,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<UserInfoDto>} */ results) => (
            <ItemsResponsiveView
              results={results}
              columns={USER_TABLE_COLUMNS}
              defaultSortKey={'userName'}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
