import api from '@/api';
import { isDev } from '@/config';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { CenterBox } from '@/web/@components/CenterBox';
import { SearchField } from '@/web/@components/SearchField';
import { CheckCircle, Circle } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { IconMessageBox } from '../IconMessageBox';

/**
 * @typedef {object} VinGroupChoiceDialogProps
 * @property {string} [title]
 * @property {string} [message]
 * @property {boolean} [open]
 * @property {() => any} [onCancel]
 * @property {(value: VinGroupInfoAggregated) => any} onSubmit
 */

/** @param {VinGroupChoiceDialogProps} props */
export function VinGroupChoiceDialog(props) {
  const { title, message, open, onCancel, onSubmit } = props;

  /** @type {StateVariable<string>} */
  const [filter, setFilter] = useState(null);
  /** @type {StateVariable<VinGroupInfoAggregated>} */
  const [selected, setSelected] = useState(null);

  const { result = [], loading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      if (!open) return;
      const limit = 25;
      let offset = 0;
      /** @type {Array<VinGroupInfoAggregated>} */
      const results = [];
      while (!signal.aborted) {
        const request = api.ac.v5['vin-groups'].list.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
            tenantId,
          },
        });
        const groups = await request.process();
        results.push(...groups);
        offset += limit;
        if (groups.length < limit) break;
      }
      return results;
    },
    [open]
  );

  /** @param {string} searchText */
  const filteredGroups = useMemo(() => {
    if (!filter || !result?.length) {
      return result || [];
    }
    return result
      .filter((group) => group.vinGroupName?.toLowerCase().includes(filter))
      .sort((a, b) => a.vinGroupName.localeCompare(b.vinGroupName));
  }, [result, filter]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onCancel} fullWidth keepMounted>
      <DialogTitle>{title || 'Choose VIN Group'}</DialogTitle>
      <Divider />
      <DialogContent>
        {message && <DialogContentText pb={'10px'}>{message}</DialogContentText>}

        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={12} md={6}>
            <Box my={1} fontWeight={500} textAlign={{ xs: 'center', md: 'left' }}>
              VIN Groups
            </Box>
          </Grid>

          {result && result?.length > 10 && (
            <Grid item xs={12} md={6}>
              <SearchField
                clearable
                ignoreCase
                onSearch={setFilter}
                placeholder="Search a device group"
                sx={{
                  borderColor: 'transparent',
                  borderRadius: 10,
                  background: '#f7f7f7',
                }}
              />
            </Grid>
          )}
        </Grid>

        {loading ? (
          <CenterBox sx={{ pt: '50px' }}>
            <CircularProgress />
          </CenterBox>
        ) : (
          <Box minHeight="200px" height="50vh" overflow="hidden auto">
            {!filteredGroups.length && (
              <CenterBox>
                <IconMessageBox
                  size="128px"
                  src="/images/empty/no-search-results.svg"
                  message="No VIN Groups"
                />
              </CenterBox>
            )}
            {filteredGroups.map((group, index) => (
              <Box key={index}>
                {index > 0 && <Divider />}
                <Button
                  onClick={() => setSelected(group)}
                  sx={{
                    'width': '100%',
                    'display': 'flex',
                    'fontWeight': '500',
                    'color': '#011e42',
                    'justifyContent': 'space-between',
                    '& .checkbox': {
                      padding: '1px',
                      fontSize: '1.375rem',
                      borderRadius: '50%',
                      background: '#ffffff',
                      border: '1px solid #cfd8e5',
                    },
                  }}
                >
                  <Box>{group.vinGroupName}</Box>
                  <Box>
                    {isDev && <Chip label={group.vinGroupId} size="small" />}
                    {selected?.vinGroupId === group.vinGroupId ? (
                      <CheckCircle htmlColor="#596A81" className="checkbox" />
                    ) : (
                      <Circle htmlColor="#CFD8E5" className="checkbox" />
                    )}
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!selected}
          onClick={selected ? () => onSubmit(selected) : null}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}
