import {
  endOfDay,
  endOfMonth,
  format,
  formatDistanceToNow,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { padStart, replace, startCase } from 'lodash';

/**
 * Date format docs: https://date-fns.org/v2.29.3/docs/format
 */

/**
 * @param {any} [time]
 * @returns {Date | undefined}
 */
export function createDate(time) {
  try {
    if (typeof time === 'string') {
      time = Date.parse(time) || Number(time);
    }
    if (typeof time === 'number') {
      time = new Date(time);
    }
    if (time instanceof Date && typeof time?.valueOf() === 'number') {
      return time;
    }
  } catch (err) {
    console.warn('Failed to parse date', time, err);
  }
  return undefined;
}

/**
 * @param {any} [time]
 * @param {number} [defaultValue]
 * @returns {number}
 */
export function parseUnix(time, defaultValue = 0) {
  const date = createDate(time);
  if (!date) return defaultValue;
  return date.getTime();
}

/**
 * @param {any} [time]
 * @param {{includeSeconds?: boolean, addSuffix?: boolean}} [options]
 */
export function timeFromNow(time, options = {}) {
  time = createDate(time);
  if (!time) return '';
  return formatDistanceToNow(time, options);
}

/**
 * @param {number} duration Duration in milliseconds
 * @param {{short?: boolean, zero?: boolean, delimiter?: string}} [options]
 */
export function formatDuration(duration, options) {
  const { short = true, zero = false, delimiter = ' ' } = options || {};
  const keys = ['years', 'days', 'hours', 'minutes', 'seconds'];
  const format = short ? ['y', 'd', 'h', 'm', 's'] : keys;

  const data = {};
  duration = Number(duration) || 0;
  if (duration < 0) {
    return '';
  }
  duration = Math.floor(duration / 1000);
  data.seconds = duration % 60;
  duration = Math.floor(duration / 60);
  data.minutes = duration % 60;
  duration = Math.floor(duration / 60);
  data.hours = duration % 24;
  duration = Math.floor(duration / 24);
  data.days = duration % 365;
  duration = Math.floor(duration / 365);
  data.years = duration;

  return keys
    .map((k, i) => [data[k], format[i], k])
    .filter(([n, s, k], i) => (zero ? false : n || (k === 'seconds' && i === 0)))
    .map(([n, s, k]) => `${n}${short ? '' : ' '}${s}`)
    .join(delimiter);
}

/**
 * @param {number} duration Duration in milliseconds
 * @param {{short?: boolean, zero?: boolean, delimiter?: string}} [options]
 */
export function formatDurationIgnoringSec(duration, options) {
  const m = 60 * 1000;
  if (duration < m) {
    return '<1m';
  }
  return formatDuration(Math.floor(duration / m) * m, options);
}

/***************************************
 *                                      *
 *   USE THIS FORMAT FOR WHOLE SYSTEM   *
 *                                      *
 ***************************************/
/**
 * @param {any} [time]
 * Common use time format
 */
export function formatTimestamp(time) {
  if (!time) return '';
  time = createDate(time);
  return format(time, 'MMM dd h:mm:ss a');
}

/**
 * @param {any} [time]
 * @param {string} [sep]
 */
export function formatDate(time, sep = '-') {
  time = createDate(time);
  if (!time) return '';
  return format(time, `yyyy${sep}MM${sep}dd`);
}

/**
 * @param {any} [startTime]
 * @param {any} [endTime]
 * @param {string} [sep]
 */
export function formatDateRange(startTime, endTime, sep = ' - ') {
  startTime = formatDate(startTime, '/');
  endTime = formatDate(endTime, '/');
  if (!startTime || !endTime) {
    return startTime || endTime;
  }
  return `${startTime}${sep}${endTime}`;
}

/**
 * @param {[any, any]} [range]
 * @param {string} [sep]
 */
export function formatDateRangePair(range, sep = ' - ') {
  if (!range?.length || range.length !== 2) return '';
  return formatDateRange(range[0], range[1]);
}

/**
 * @param {any} [time]
 * License Timestamp Format
 */
export function licenseTimestampFormat(time) {
  if (!time) return '';
  time = createDate(time);
  return format(time, 'MMM dd, yyyy');
}

/**
 * @param {any} [time]
 */
export function formatDateForTriggerReport(time) {
  time = createDate(time);
  if (!time) return '';
  return format(time, 'dd MMM');
}

/**
 * @param {any} [time]
 */
export function formatDateForLiveTracking(time) {
  time = createDate(time);
  if (!time) return '';
  return format(time, 'MMM dd yyyy');
}

/**
 * @param {any} time Playback duration in milliseconds
 * @param {boolean} [noHours] Do not show hours
 */
export function formatTime(time, noHours = false) {
  time = createDate(time);
  if (!time) return '';
  return format(time, noHours ? 'mm:ss' : 'H:mm:ss');
}

/**
 * @param {any} time 12 hour time format
 */
export function format12HourTime(time) {
  time = createDate(time);
  if (!time) return '';
  return format(time, 'h:mm:ss a');
}

/**
 * @param {any} [time]
 */
export function formatPlayerCurrentTime(time) {
  time = createDate(time);
  if (!time) return '';
  return format(time, 'dd MMM yyyy H:mm:ss');
}

/**
 * @param {any} [time]
 */
export function formatDateWithWeek(time) {
  time = createDate(time);
  if (!time) return '';
  return format(time, 'E MMMM dd, yyyy');
}

/**
 * Format date by Type
 * @param {*} time
 * @param {*} format
 * @returns
 */

export function formatDateByType(time, format) {
  time = createDate(time);
  if (!time) return '';
  return format(time, format);
}

/**
 * Format sec to Time format
 * @param {number} secs
 * @returns
 */

export const secondToTimeFormat = (secs) => {
  if (!secs) return;
  const date = new Date(0);
  date.setSeconds(secs);
  return date.toISOString().substring(11, 19);
};

/**
 * Get date and time with week number.
 * @param {any} [time]
 */
export function formatTimeForFolder(time) {
  time = createDate(time);
  if (!time) return '';
  return format(time, 'yyyymmddhhmmss');
}

/**
 * Get date and time with week number.
 * @param {any} [time]
 */
export function formatDateTimeWeek(time) {
  const date = createDate(time);
  if (!date) return '';
  // return date.toLocaleDateString();
  return format(date, 'eee, MMM d, yyyy h:mm:ss a');
}

/**
 * Get date and time.
 * @param {any} [time]
 */
export function formatDateTime(time) {
  const date = createDate(time);
  if (!date) return '';
  // return date.toLocaleDateString();
  return format(date, 'MMM d, yyyy h:mm:ss a');
}

/**
 * Get time in RFC 1123 format.
 * @param {any} [time]
 */
export function formatTimeRfc1123(time) {
  time = createDate(time);
  if (!time) return '';
  return format(time.getUTCDate(), 'eee, dd MMM yyy HH:mm:ss GMT');
}

/**
 * Get the start time of today.
 * @param {any} [time] Any time value of today
 * @returns {number}
 */
export function startOfTheDay(time) {
  time = createDate(time) || Date.now();
  return startOfDay(time).getTime();
}

/**
 * Get the end of today.
 * @param {any} [time] Any time value of today
 * @returns {number}
 */
export function endOfTheDay(time) {
  time = createDate(time) || Date.now();
  return endOfDay(time).getTime();
}

/**
 * Get the start time of the month.
 * @param {any} [time] Any time value of today
 * @returns {number}
 */
export function startOfTheMonth(time) {
  time = createDate(time) || Date.now();
  return startOfMonth(time).getTime();
}

/**
 * Get the start of the day one month ago.
 * @param {any} [time] Any time value of today
 * @returns {number}
 */
export function minusOneMonth(time) {
  time = createDate(time) || endOfDay(Date.now());
  return startOfDay(time - 30 * 24 * 3600 * 1000 + 1).getTime();
}

/**
 * Get the end of the month.
 * @param {any} [time] Any time value of today
 * @returns {number}
 */
export function endOfTheMonth(time) {
  time = createDate(time) || Date.now();
  return endOfMonth(time).getTime();
}

/**
 * @param {number} duration Playback duration in milliseconds
 * @param {boolean} [noHours] Do not show hours
 */
export function formatPlayTime(duration, noHours = false) {
  if (!duration || isNaN(duration)) {
    return '0:00';
  }

  duration = Math.round(duration / 1000);
  let s = duration % 60;
  duration = Math.floor(duration / 60);
  let m = duration % 60;
  duration = Math.floor(duration / 60);
  let h = duration;

  if (noHours) {
    m += h * 60;
    h = 0;
  }

  let r = padStart(s + '', 2, '0');
  r = padStart(m + '', 2, '0') + ':' + r;
  if (h) {
    r = h + ':' + r;
  }
  return r;
}

/**
 * Get the start tick time from a time value
 * @param {number} time The current time
 * @param {number} step Single tick duration
 * @param {number} start The start time
 */
export function getStartTickTime(time, step = 1, start = 0) {
  time = Number(time);
  step = Number(step) || 1;
  start = Number(start) || 0;
  return Math.ceil((time - start) / step) * step + start;
}

/**
 * Get the end tick time from a time value
 * @param {number} time The current time
 * @param {number} step Single tick duration
 * @param {number} start The start time
 */
export function getEndTickTime(time, step = 1, start = 0) {
  time = Number(time);
  step = Number(step) || 1;
  start = Number(start) || 0;
  return Math.floor((time - start) / step) * step + start;
}

/**
 * Get a tick time in the middle from a time value
 * @param {number} time The current time
 * @param {number} step Single tick duration
 * @param {number} start The start time
 */
export function getTickTime(time, step = 1, start = 0) {
  time = Number(time);
  step = Number(step) || 1;
  start = Number(start) || 0;
  return Math.round((time - start) / step) * step + start;
}

/**
 * Convert byte to percentage
 * @param {number|string} value
 * @param {number|string} totalValue
 * @returns
 */

export function byteToPercentage(value, totalValue) {
  return `${parseInt((Number(value) / Number(totalValue)) * 100 + '')} %`;
}

/**
 * Get Human Readable Time
 * @param {number} date [in Milliseconds]
 * @returns {string}
 */
export function getHumanReadableDateDiff(date) {
  if (!date) return '';
  const newDate = new Date(date);
  const diff = formatDistanceToNow(newDate, {
    includeSeconds: false,
    addSuffix: true,
  });
  return startCase(replace(replace(diff, 'about', ''), 'less than a minute ago', 'Just Now'));
}
