import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { CustomSelect } from '@/web/@components/CustomForm';
import { sortBy } from 'lodash';

export function CameraSelection(props) {
  const { property } = props;
  const { result: cameras = [], loading: cameraLoading } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      let offset = 0;
      const limit = 500;
      /** @type {Array<EndpointInfoAggregated>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.endpoint.list.$get({
          signal: signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            type: 'DEVICE',
            status: 'ACTIVE',
            limit,
            offset,
            tenantId,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result.endpointInfoList;
        results.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      return sortBy(results, [(item) => item?.deviceLabel?.toLowerCase()]);
    },
    [],
    { cache: true }
  );

  return (
    <CustomSelect
      name={property}
      size="small"
      loading={cameraLoading}
      placeholder={'Select Camera'}
      options={cameras || []}
      getLabel={'deviceLabel'}
      rules={{ required: true }}
    />
  );
}
