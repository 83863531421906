import { FilterContextProvider } from '@/web/@components/FilterContext';
import { TripFilterContextProvider } from '@/web/trips/@components/TripFilterContext';
import { TripContextProvider } from '@/web/trips/@components/TripContext';
import { FleetRecentTripsArea } from '../FleetRecentTripsArea';

export function FleetTripsPage() {
  return (
    <FilterContextProvider>
      <TripFilterContextProvider>
        <TripContextProvider>
          <FleetRecentTripsArea />
        </TripContextProvider>
      </TripFilterContextProvider>
    </FilterContextProvider>
  );
}
