import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { PageLink } from '@/web/@components/PageLink';
import { TripDetailsView } from '@/web/trips/@components/TripDetailsView';
import { TripListViewItem } from '@/web/trips/@components/TripListViewItem';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import qs from 'qs';

/** @param {import('@mui/material').BoxProps} props */
export function FleetRecentTripsArea(props) {
  const {
    result: trip,
    error,
    loading,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    const request = api.ac.v5.trips.$get({
      signal,
      headers: {
        Authorization: secretToken,
      },
      params: {
        offset: 0,
        limit: 5,
        tenantId,
        fromTimestamp: 0,
        toTimestamp: Date.now(),
      },
    });
    await request.process();
    return (
      request.result?.tripList
        ?.filter((trip) => trip.tripStatus === 'STARTED' || trip?.distance > 50)
        ?.at(0) || {}
    );
  }, []);

  const {
    result: tripDetails,
    error: detailsError,
    loading: detailsLoading,
  } = useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      if (loading || !trip?.id) return {};
      const request = api.ac.v5.trips.$tripId(trip.id).$get({
        signal,
        params: {
          tenantId,
        },
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return request.result;
    },
    [loading, trip?.id]
  );

  const { result: camera } = useFutureLoader(
    async ({ signal, secretToken }) => {
      if (detailsLoading || !trip?.endpointId) return {};
      const request = api.ac.v5.endpoint.$endpointId(trip.endpointId).details.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return request.result;
    },
    [loading, trip?.id]
  );

  if (error) {
    return null;
  }

  return (
    <Box {...props}>
      <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} color="#0B2547" sx={{ opacity: 0.68 }}>
          Recent Trips
        </Typography>

        {!isEmpty(trip) && (
          <PageLink
            fontWeight={600}
            fontSize={'0.875rem'}
            to={`/trips?${qs.stringify({
              from: startOfTheDay(trip.startTimestamp),
              to: endOfTheDay(Date.now()),
              type: 'driverName',
              fullWidth: true,
            })}`}
          >
            View All Trips
          </PageLink>
        )}
      </Box>

      {loading || detailsLoading ? (
        <CenterBox style={{ border: '1px solid #f8f8f8', height: '200px' }}>
          <CircularProgress />
        </CenterBox>
      ) : isEmpty(trip) ? (
        <CenterBox style={{ border: '1px solid #f8f8f8' }}>
          <IconMessageBox
            size="150px"
            src="/images/pages/trips/no-trips-icon.svg"
            message="No Trips"
          />
        </CenterBox>
      ) : (
        <Grid container>
          <Grid item xs={12} md={4} xl={3} p={1.5} bgcolor="#F2F7FC">
            <TripListViewItem item={trip} />
          </Grid>
          <Grid item xs={12} md={8} xl={9} bgcolor="#F0F0F0">
            {detailsLoading ? (
              <CenterBox height="175px">
                <CircularProgress />
              </CenterBox>
            ) : detailsError || !tripDetails ? (
              <CenterBox height="175px">
                <IconMessageBox
                  size="64px"
                  src={NO_SEARCH_RESULT_IMG}
                  message="Could not load trip details"
                />
              </CenterBox>
            ) : (
              <TripDetailsView details={tripDetails} camera={camera} />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
