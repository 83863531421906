import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CustomInput } from '@/web/@components/CustomForm';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} PersonaCreateModalProps
 * @property  {(boolean) => any} [onHide]
 */

/**
 * @param {PersonaCreateModalProps} props
 */
export function PersonaCreateModal(props) {
  const { onHide } = props;
  const secretToken = useSelector(selectSecretToken);

  const defaultValues = useMemo(
    () => ({
      name: '',
    }),
    []
  );

  const methods = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  const { handleSubmit } = methods;

  /** @param {typeof defaultValues} data */
  const handleAdd = async (data) => {
    try {
      const request = api.ac.v5.persona.$post({
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
        params: {
          personaName: data?.name,
        },
      });
      await request.process();
      toastSuccess('Success', 'Persona Added');
      onHide && onHide(true);
    } catch (err) {
      console.error(err);
      toastWarning('Failed', 'Failed to add persona.');
    }
  };

  /** @param {typeof defaultValues} data */
  const handelOnSubmit = (data) => {
    data.name = trimString(data.name);
    handleAdd(data);
  };

  return (
    <Dialog
      open={true}
      onClose={onHide}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handelOnSubmit)} autoComplete="off" noValidate>
          <DialogTitle id="alert-dialog-title">Persona Create</DialogTitle>
          <Divider />
          <DialogContent>
            <CustomInput
              name="name"
              label="Persona *"
              placeholder="Please enter a persona name"
              InputProps={{
                inputProps: { maxLength: 100 },
              }}
              rules={{ required: 'This is required' }}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={() => onHide(false)}>Cancel</Button>
            <Button variant="contained" type="submit">
              Apply
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
