import { DEFAULT_ITEM_PER_PAGE } from '@/assets/constants/table';
import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { trimString } from '@/utils/formatting';
import qs from 'qs';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {object} FilterParams
 * @property {string} [searchType]
 * @property {string} [searchText]
 * @property {number} [startTime]
 * @property {number} [endTime]
 * @property {number} [offset]
 * @property {number} [limit]
 */

/** @type {FilterParams} */
const defaultValues = {
  searchText: '',
  searchType: '',
  startTime: minusOneMonth(),
  endTime: endOfTheDay(Date.now()),
  offset: 0,
  limit: DEFAULT_ITEM_PER_PAGE,
};

const paramAlias = {
  startTime: 'from',
  endTime: 'to',
  searchText: 'search',
  searchType: 'type',
  offset: 'offset',
  limit: 'limit',
};

/** @type {import("react").Context<FilterParams>} */
export const FilterContext = createContext(null);

/** @type {import("react").Context<(params: FilterParams, replace?: boolean) => any>} */
export const SetFilterContext = createContext(null);

/** @param {{children: import("react").ReactNode}} props */
function SetFilterContextProvider(props) {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();
  /**
   * @type {(data: {[key: string]: any}, replace?: boolean) => any}
   */
  const setFilter = useCallback(
    (data, replace = false) => {
      let params = {};
      for (const key of Object.keys(data)) {
        if (paramAlias.hasOwnProperty(key)) {
          params[paramAlias[key]] = data[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          params[key] = data[key];
        }
      }

      const query = qs.parse(location.search.substring(1));
      if (!replace) {
        params = { ...query, ...params };
      }

      for (const key of Object.keys(params)) {
        if (key in params) {
          if (!params[key]) delete params[key];
          else params[key] = trimString(params[key]);
        }
      }

      if (
        JSON.stringify(Object.entries(query).sort()) ===
        JSON.stringify(Object.entries(params).sort())
      ) {
        return;
      }

      navigate(
        {
          ...location,
          search: qs.stringify(params),
        },
        { replace: false }
      );
    },
    [location, navigate]
  );

  return <SetFilterContext.Provider value={setFilter}>{children}</SetFilterContext.Provider>;
}

/** @param {{children: import("react").ReactNode, defaults?: FilterParams}} props */
export function FilterContextProvider(props) {
  const { children, defaults = defaultValues } = props;

  const location = useLocation();

  /** @type {StateVariable<FilterParams>} */
  const [value, setValue] = useState(defaults);

  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);

  /** @type {(value: any, defaultValue: string) => string} */
  const toString = (value, defaultValue) => (value || defaultValue || '') + '';

  /** @type {(value: any, defaultValue: number) => number} */
  const toNumber = (value, defaultValue) => Number(value) || defaultValue || 0;

  useEffect(() => {
    const searchText = toString(query.search, defaults.searchText);
    setValue((v) => (v.searchText === searchText ? v : { ...v, searchText }));
  }, [query.search, defaults]);

  useEffect(() => {
    const searchType = toString(query.type, defaults.searchType);
    setValue((v) => (v.searchType === searchType ? v : { ...v, searchType }));
  }, [query.type, defaults]);

  useEffect(() => {
    const startTime = toNumber(query.from, defaults.startTime);
    setValue((v) => (v.startTime === startTime ? v : { ...v, startTime }));
  }, [query.from, defaults]);

  useEffect(() => {
    const endTime = toNumber(query.to, defaults.endTime);
    setValue((v) => (v.endTime === endTime ? v : { ...v, endTime }));
  }, [query.to, defaults]);

  useEffect(() => {
    const offset = toNumber(query.offset, defaults.offset);
    setValue((v) => (v.offset === offset ? v : { ...v, offset }));
  }, [query.offset, defaults]);

  useEffect(() => {
    const limit = toNumber(query.limit, defaults.limit);
    setValue((v) => (v.limit === limit ? v : { ...v, limit }));
  }, [query.limit, defaults]);

  return (
    <SetFilterContextProvider>
      <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
    </SetFilterContextProvider>
  );
}
