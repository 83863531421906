import { CloseRounded } from '@mui/icons-material';
import { IconButton, InputBase, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { BoxImage } from '../BoxImage';

/**
 * @typedef {object} SearchFieldPropsBase
 * @property {number} [elevation] Elevation of the component
 * @property {boolean} [clearable] Whether the clear button be visible
 * @property {string} [value] The current input value
 * @property {boolean} [autoFocus] Whether to focus automatically
 * @property {boolean} [searchOnEnter] Whether to request search on Enter key
 * @property {boolean} [ignoreCase] Search text will always be lowercase
 * @property {string} [placeholder] The input placeholder to display
 * @property {boolean} [hideSearchIcon] Hide Search Icon
 * @property {(text: string) => any} [onSearch] Called when search is requested
 * @property {"elevation" | "outlined"} [variant] Input border variant
 * @property {'small' | 'medium'} [size] Input size
 * @property {import('@mui/material').InputBaseProps} [InputProps] Other input field props
 * @property {import('@mui/material').SxProps<import('@mui/material').Theme> } [sx]
 * @property {import('@mui/material').SxProps<import('@mui/material').Theme> } [iconSx]
 */

/** @typedef {SearchFieldPropsBase} SearchFieldProps */

/** @param {SearchFieldProps} props */
export function SearchField(props) {
  const {
    value,
    onSearch,
    searchOnEnter,
    variant = 'outlined',
    elevation,
    clearable,
    ignoreCase,
    placeholder,
    size,
    sx,
    iconSx,
    InputProps,
    autoFocus,
    hideSearchIcon = false,
  } = props;

  const [text, setText] = useState(value || '');

  useEffect(() => {
    setText(value || '');
  }, [value]);

  const clearSearch = () => {
    onSearch && onSearch(null);
    setText('');
  };

  /** @type {import('react').KeyboardEventHandler<HTMLInputElement>} */
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch && onSearch(text);
    } else if (e.key === 'Escape') {
      clearSearch();
    }
    if (InputProps?.onKeyDown) {
      InputProps.onKeyDown(e);
    }
  };

  /** @type {import('react').ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>} */
  const handleChange = (e) => {
    let val = e.currentTarget.value;
    if (ignoreCase) {
      val = val.toLowerCase();
    }
    if (text !== val) {
      setText(val);
      if (!searchOnEnter) {
        onSearch && onSearch(val);
      }
    }
    if (InputProps?.onChange) {
      InputProps.onChange(e);
    }
  };

  useEffect(() => {
    if (!searchOnEnter || value === text || !onSearch) return;
    const tid = setTimeout(() => onSearch(text || ''), 1000);
    return () => clearTimeout(tid);
  }, [text, searchOnEnter, value, onSearch]);

  return (
    <Paper
      elevation={elevation}
      variant={variant}
      // component="form"
      sx={{
        p: '2px 7px',
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        borderRadius: '6px',
        // background: '#f7f7f7',
        // borderColor: 'transparent',
        height: '35px',
        //width: { xs: '100%', sm: 250, md: 300, xl: 350 },
        ...sx,
      }}
    >
      {!hideSearchIcon && (
        <IconButton type="button" sx={{ p: '5px' }} onClick={() => onSearch(text)}>
          <BoxImage
            alt="search"
            src="/images/commons/search-icon.svg"
            width="16px"
            height="16px"
            sx={{ ...iconSx }}
          />
        </IconButton>
      )}
      <InputBase
        size={size}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...InputProps}
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        sx={{
          '& input': { p: '6px' },
        }}
        style={{
          flex: 1,
          width: '100%',
          color: '#011e42',
          fontWeight: '500',
          fontSize: '0.875rem',
        }}
      />
      {clearable && text.length > 0 && (
        <IconButton type="button" sx={{ p: '5px' }} onClick={clearSearch}>
          <CloseRounded fontSize="small" htmlColor="#7791b3" sx={{ ...iconSx }} />
        </IconButton>
      )}
    </Paper>
  );
}
