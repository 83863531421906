import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/fleets/drivers/actions';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DriverItemView } from './@components/DriverItemView';
import { InfiniteDriverScrollView } from './@components/InfiniteDriverScrollView';

export function DriversListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const [searchParams] = useSearchParams();

  const [refreshCount, setRefreshCount] = useState(0);

  const orderBy = useMemo(() => searchParams?.get('order'), [searchParams]);

  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteDrivers(selectedItems);
    }
    setTableReload(true);
    setRefreshCount((value) => value + 1);
  };

  /** @param {Driver} driver */
  const deleteDriver = async (driver) => {
    const driverName = `${driver.firstName} ${driver.lastName}`;
    try {
      const request = api.ac.v5.driver.$driverId(driver.driverId).$delete({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully deleted ${driverName}`);
    } catch (e) {
      toastWarning('Error', `Failed to delete ${driverName}`);
    }
  };

  /** @param {Array<Driver>} selectedItems */
  const deleteDrivers = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteDriver));
  };

  return (
    <Box mx={2.5} mt={2} mb={5}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title={'Drivers'} actions={ACTION_ITEMS} onAction={onActionHandle} />

        <InfiniteScrollView
          key={generateUniqueKey([tenantId, refreshCount])}
          initialToken={null}
          itemsPerPage={20}
          fetcher={async ({ signal, limit, token: offset }) => {
            if (!orderBy) {
              const request = api.ac.v5.driver.list.$get({
                signal,
                headers: {
                  Authorization: secretToken,
                },
                params: {
                  limit: limit || 50,
                  offset: offset || 0,
                  tenantId,
                },
              });
              const result = await request.process();
              return {
                result: result?.drivers || [],
                token: offset + limit,
              };
            } else {
              const request = api.ac.v5.driver['rank-list'].$get({
                signal,
                headers: {
                  Authorization: secretToken,
                },
                params: {
                  count: limit || 50,
                  // @ts-ignore
                  offset: offset || 0,
                  rankOrder: orderBy?.toUpperCase() === 'BOTTOM' ? 'BOTTOM' : 'TOP',
                  tenantId,
                },
              });
              const result = await request.process();
              return {
                result: result?.list || [],
                token: offset + limit,
              };
            }
          }}
          // renderFailure={(state) => {
          //   throw new KnownUserError('No driver found');
          // }}
          renderEmpty={() => (
            <IconMessageBox
              size="256px"
              src="/images/player/no-data-icon.svg"
              message="No Driver Found"
            />
          )}
          renderList={(state) => (
            <InfiniteDriverScrollView
              state={state}
              renderItem={(driver) => <DriverItemView driver={driver} onAction={onActionHandle} />}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
