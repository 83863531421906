import { CustomInput } from '@/web/@components/CustomForm';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

/**
 * @typedef {object} AccessCardFormProps
 * @property {string} [title]
 * @property  {() => any} [onCancel]
 * @property  {() => any} [onSubmit]
 */

/**
 * @param {AccessCardFormProps} props
 */
export function AccessCardForm(props) {
  const { title, onCancel, onSubmit } = props;

  const form = useForm({
    mode: 'all',
    defaultValues: {
      id: '',
    },
    shouldUnregister: false,
  });

  return (
    <Dialog open fullWidth maxWidth={'sm'} onClose={onCancel}>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>{title}</Box>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormProvider {...form}>
          <form autoComplete="off" noValidate>
            <CustomInput
              name="id"
              label="Access Card *"
              placeholder="Please enter a access card number"
              rules={{ required: 'This is required' }}
            />
          </form>
        </FormProvider>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant="contained" onClick={form.handleSubmit(onSubmit)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
