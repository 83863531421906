import { T } from '@/assets/locales';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput } from '@/web/@components/CustomForm';
import { CustomPasswordInput } from '@/web/@components/CustomForm/CustomPasswordInput';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeviceGroupDetailsContext } from '../../../@components/DeviceGroupDetailsLayout';
import { ApnSettingsAdditionalInfo } from '../ApnSettingsAdditionalInfo';

/**
 * @typedef {object} ApnSettingsFormPropsBase
 * @property {import('react-hook-form').SubmitHandler<APNInfoForm>} onSubmitData
 * @property {boolean} loading
 * @property {APNInfo} [apnInfo]
 */

/**
 * @typedef {ApnSettingsFormPropsBase & import('@mui/material').BoxProps} ApnSettingsFormProps
 */

/**
 * @param {ApnSettingsFormProps} props
 */
export function ApnSettingsForm(props) {
  const { onSubmitData, loading, apnInfo } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { groupId } = useContext(DeviceGroupDetailsContext);

  /** @type {APNInfoForm} */
  const defaultValues = useMemo(() => {
    let apnAdditionalInfoItem = [];
    if (apnInfo) {
      apnAdditionalInfoItem = apnInfo?.apnAdditionalInfoItemList?.map((item) => {
        const val = item?.apnAdditionalInfoValue;
        let type = 'STRING';
        if (['true', 'false'].includes(val)) type = 'BOOLEAN';
        else if (isNumber(Number(val))) type = 'NUMBER';
        else type = 'STRING';
        return { ...item, type };
      });
    }

    return {
      apn: apnInfo?.apn || '',
      apnProtocol: apnInfo?.apnProtocol || '',
      apnType: apnInfo?.apnType || '',
      authType: apnInfo?.authType || '',
      mcc: apnInfo?.mcc || '',
      mnc: apnInfo?.mnc || '',
      name: apnInfo?.name || '',
      password: apnInfo?.password || '',
      port: apnInfo?.port || '',
      proxy: apnInfo?.port || '',
      server: apnInfo?.server || '',
      userName: apnInfo?.userName || '',
      apnAdditionalInfoItemList: apnAdditionalInfoItem,
    };
  }, [apnInfo]);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  /** @param {APNInfoForm} data */
  const onSubmit = (data) => {
    data.apn = trimString(data.apn);
    data.apnProtocol = trimString(data.apnProtocol);
    data.apnType = trimString(data.apnType);
    data.authType = trimString(data.authType);
    data.mcc = trimString(data.mcc);
    data.mnc = trimString(data.mnc);
    data.name = trimString(data.name);
    data.port = trimString(data.port);
    data.proxy = trimString(data.proxy);
    data.server = trimString(data.server);
    data.userName = trimString(data.userName);
    data.password = trimString(data.password);
    data.apnAdditionalInfoItemList.forEach((item) => {
      item.apnAdditionalInfoKey = trimString(item.apnAdditionalInfoKey);
      item.apnAdditionalInfoValue = trimString(item.apnAdditionalInfoValue);
    });
    onSubmitData(data);
  };

  const handleCancel = () => {
    navigate(`/administration/device-groups/${groupId}/apns`);
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        APN Settings Details
      </Typography>
      <Divider sx={{ mb: 1 }} />

      <FormProvider {...form}>
        <form autoComplete="off" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="name"
                label="Name *"
                placeholder="Enter a Name"
                rules={{ required: 'This field is required' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="apn"
                label="APN *"
                placeholder="Enter APN"
                rules={{ required: 'This field is required' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="mnc"
                label="MNC *"
                placeholder="Enter MNC"
                rules={{ required: 'This field is required' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="mcc"
                label="MCC *"
                placeholder="Enter MCC"
                rules={{ required: 'This field is required' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="proxy" label="Proxy" placeholder="Enter proxy" />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="port" label="Port" placeholder="Enter port" />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="userName" label="Username" placeholder="Enter a username" />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomPasswordInput
                name="password"
                label="Password"
                placeholder="Enter password"
                InputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="server" label="Server" placeholder="Enter server" />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="authType"
                label="Authentication Type"
                placeholder="Enter authentication type"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="apnType" label="APN Type" placeholder="Enter APN type" />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="apnProtocol"
                label="APN Protocol"
                placeholder="Enter APN protocol"
              />
            </Grid>
          </Grid>

          <ApnSettingsAdditionalInfo />

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button disabled={loading} variant="contained" onClick={form.handleSubmit(onSubmit)}>
              {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {apnInfo ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
