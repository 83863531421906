import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { chain } from 'lodash';

/**
 * Fetch Label Data
 * @param {string} [type]
 * @returns
 */

export function useLabelListFetch(type = null) {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      let conToken = '';
      const limit = 500;
      /** @type {Array<TriggerClassification>} */
      const results = [];
      while (!signal.aborted) {
        const request = api.ac.v1.tenant.trigger.classifications.$get({
          params: {
            secretToken,
            pageSize: limit,
            continuationToken: conToken,
            tenantId,
            ...(type ? { type: type } : {}),
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result.data;
        conToken = request.result.continuationToken;
        results.push(...result);
        if (result.length < limit) break;
      }

      return chain(results || [])
        .filter((item) => ['RELEASED', 'DRAFT'].includes(item.publicationStatus))
        .sortBy('displayName')
        .unionBy('name')
        .value();
    },
    [],
    { cache: true }
  );
}
