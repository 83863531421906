import { formatTimestamp } from '@/utils/datetime';
import { downloadFile } from '@/utils/file-utils';
import { DownloadForOffline, Error } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { camelCase, capitalize, startCase } from 'lodash';

/** @type {Array<TableColumn<LogRequest>>} */
export const GROUP_ADB_LOG_TABLE_COLUMNS = [
  {
    id: 'fromTime',
    label: 'From',
    align: 'left',
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'toTime',
    label: 'To',
    align: 'left',
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'logType',
    label: 'Type',
    searchable: true,
    format: (value) => startCase(camelCase(value)),
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    format: (value) => capitalize(value),
  },
  {
    id: 'fileUrl',
    label: 'Action',
    align: 'left',
    searchable: true,
    format: (value, row) => {
      return row.status === 'AVAILABLE' ? (
        <Button
          size="small"
          onClick={() => downloadFile(value, `${row.id}`)}
          className="btn-sm my-1"
          variant="outlined"
          startIcon={<DownloadForOffline />}
        >
          Download
        </Button>
      ) : row.status === 'FAILED' ? (
        <Button
          size="small"
          className="btn-sm my-1"
          variant="outlined"
          color="error"
          startIcon={<Error />}
          sx={{ cursor: 'not-allowed' }}
        >
          Upload Failed
        </Button>
      ) : (
        <CircularProgress size="24px" />
      );
    },
  },
];
