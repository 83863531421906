import { T } from '@/assets/locales';
import { isDev, isGeotabDashboard } from '@/config';
import { store } from '@/store';
import { Auth, doLogin, selectRememberAccount } from '@/store/auth';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { toastWarning } from '@/utils/toaster';
import { CustomInput } from '@/web/@components/CustomForm';
import { CustomPasswordInput } from '@/web/@components/CustomForm/CustomPasswordInput';
import { LoginPageSwitch } from '@/web/@components/LoginPageSwitch';
import { PageLink } from '@/web/@components/PageLink';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GeoTabLoginButton } from '../@components/GeoTabLoginButton';
import { GoogleLoginButton } from '../@components/GoogleLoginButton';
import { MicrosoftLoginButton } from '../@components/MicrosoftLoginButton';

export function LoginPage() {
  const theme = useTheme();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const rememberMe = useSelector(selectRememberAccount);

  const setRememberMe = (/** @type {boolean} */ value) => {
    store.dispatch(Auth.setRemember(value));
  };

  /** @param {{username: string, password: string}} data */
  const handlePasswordLogin = (data) => {
    if (loading) return;
    setLoading(true);
    const startedAt = Date.now();
    store
      .dispatch(
        doLogin({
          authenticationType: 'PASSWORD',
          email: data.username,
          password: data.password,
        })
      )
      .then(() => {
        const duration = Date.now() - startedAt;
        if (duration > 10000) {
          reportEvent(SentryEvents.SLOW_LOGIN, '', {
            authenticationType: 'PASSWORD',
            email: data.username,
            duration,
            tags: { email: data.username, duration },
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 429) {
          toastWarning('Too many failed attempts', 'Please try again in 1 hour.');
        } else {
          toastWarning('Login failed');
        }
        console.error(err);
        reportEvent(SentryEvents.LOGIN_FAILED, '', {
          err,
          email: data.username,
          authenticationType: 'PASSWORD',
          tags: { email: data.username },
        });
      })
      .finally(() => setLoading(false));
    store.dispatch(Auth.setAvatar());
  };

  /**
   * @param {AccountLoginRequest['authenticationType']} authenticationType
   * @returns {(email: string, authToken: string) => any}
   */
  const handleSocialLogin = (authenticationType) => (email, authenticationToken) => {
    if (!email || !authenticationToken || loading) return;
    setLoading(true);
    const startedAt = Date.now();
    store
      .dispatch(
        doLogin({
          authenticationType,
          authenticationToken,
          email,
        })
      )
      .then(() => {
        const duration = Date.now() - startedAt;
        if (duration > 10000) {
          reportEvent(SentryEvents.SLOW_LOGIN, '', {
            authenticationType: 'PASSWORD',
            duration,
            email,
            tags: { email, duration },
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 429) {
          toastWarning('Too many failed attempts', 'Please try again in 1 hour.');
        } else {
          toastWarning('Login failed');
        }
        console.error(err);
        reportEvent(SentryEvents.LOGIN_FAILED, '', {
          err,
          email,
          authenticationType,
          tags: { email, authenticationType },
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box maxWidth="400px" display="block" mx="auto" padding={1} px={3}>
      <Box letterSpacing={0} fontWeight={600} fontSize="1.25rem" mb="30px">
        {t(T['login.title'])}
      </Box>
      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={form.handleSubmit(handlePasswordLogin)}
          sx={{
            'mb': 3,
            '& label': {
              fontSize: '1rem',
              color: '#5a6c83',
              lineHeight: '28px',
              fontWeight: 500,
              mb: 0,
            },
          }}
        >
          <CustomInput
            name="username"
            label={t(T['login.label.email.label'])}
            placeholder="Enter username or email"
            InputProps={{ autoComplete: 'email' }}
            rules={{
              required: 'Username / Email is required',
            }}
          />

          <CustomPasswordInput
            name="password"
            label={t(T['login.label.password.label'])}
            rules={{ required: 'Password is required' }}
          />

          <FormControlLabel
            label={t(T['login.label.remember.me'])}
            control={
              <LoginPageSwitch
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            sx={{
              'ml': 0,
              'py': '15px',
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem',
                ml: '8px',
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            disableElevation
            fullWidth
            disabled={loading}
            sx={{ fontSize: '1rem' }}
          >
            {loading && <CircularProgress disableShrink size={16} />}&nbsp;&nbsp;
            {t(T['login.button'])}
          </Button>

          <PageLink
            fontWeight={500}
            fontSize={'0.875rem'}
            to="../reset-password"
            sx={{
              display: 'block',
              py: '5px',
              textAlign: 'right',
            }}
          >
            {t(T['login.forget.password'])}
          </PageLink>
        </Box>
      </FormProvider>

      <Divider />
      <Box textAlign={'center'} mt={2} mb={3} fontWeight={600}>
        {t(T['login.alternate.text'])}
      </Box>

      <Grid
        container
        spacing={2}
        mb={5}
        sx={{
          '& .social-button': {
            'width': '100%',
            'fontWeight': 600,
            'textTransform': 'unset',
            'color': theme.palette.text.secondary,
            '&:hover': { borderColor: '#405571' },
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <GoogleLoginButton
            disabled={loading}
            variant="outlined"
            className="social-button"
            onLogin={handleSocialLogin('GOOGLE')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MicrosoftLoginButton
            disabled={loading}
            variant="outlined"
            className="social-button"
            onLogin={handleSocialLogin('MICROSOFT')}
          />
        </Grid>
        {(isGeotabDashboard || isDev) && (
          <Grid item xs={12} md={6}>
            <GeoTabLoginButton disabled={loading} variant="outlined" className="social-button" />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
