import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';

/**
 * @typedef {object} FileUploadProgressProps
 * @property {string} [progressText]
 * @property {string} headerText
 * @property {number} percentage
 * @property {() => any} onClose
 */

/** @param {FileUploadProgressProps} props */
export function FileUploadProgress(props) {
  const { percentage, headerText, progressText = 'Uploading file...', onClose } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog
      fullWidth
      open={true}
      keepMounted
      disableEscapeKeyDown={true}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{headerText}</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', mt: '30px' }}>
          <CircularProgress
            variant={percentage ? 'determinate' : 'indeterminate'}
            value={percentage}
            color="secondary"
            size="100px"
            style={{
              borderRadius: '50%',
              border: percentage ? '1px dotted #bfcbdb' : null,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(percentage)}%`}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt="30px">
          <Typography variant="body2">{progressText}</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
