import { selectTenantId } from '@/store/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

export const CoachingLayout = () => {
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);

  const [initialTenantId] = useState(tenantId);

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId]);

  return <Outlet />;
};
