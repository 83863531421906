import { CustomTooltip } from '@/web/@components/CustomTooltip';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GroupSettingsContext } from '../../../index';

const Android12Switch = styled(Switch)(({ theme }) => ({
  'padding': 8,
  '& .MuiSwitch-track': {
    borderRadius: 11,
    border: '1px solid #608AC3',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: '#B7C4D5',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      'color': '#fff',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#608AC3',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        border: '1px solid #608AC3',
        backgroundColor: '#fff',
      },
    },
  },
}));

/**
 * @typedef {object} SwitchFormProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {SwitchFormProps} props */
export function GroupSettingsSwitchForm(props) {
  const { item } = props;
  const { key: name, label: labelName, postText, rules, ...rest } = item;

  const { watch, setValue, control } = useFormContext();

  const { inputs } = useContext(GroupSettingsContext);

  const initVal = useWatch({ name: name, control });

  const handleChange = (event) => {
    const checked = event.target.checked;
    setValue(name, checked);

    if (name === 'continuousRecordingAtEdge' && checked) {
      setValue('continuousRecording', false);
      return;
    }

    if (name === 'isBlurEnabled') {
      if (!checked) {
        setValue('blurredLabels', '');
      } else {
        setValue('shouldTakeStreamingSettingsFromModel', true);
        setValue('shouldTakeRecordingSettingsFromModel', true);
        const videoInputs = inputs?.filter((item) => item.inputType === 'VIDEO');
        videoInputs?.forEach((item) => {
          ['recording', 'streaming'].forEach((type) => {
            setValue(`shouldTakeSettingsFromModel_${item.inputCode}_${type}_`, true);
          });
        });
      }
      return;
    }

    if (name === 'streamingQualityProfile') {
      if (checked) {
        setValue('isAbtOnBitrateEnabled', true);
      } else {
        setValue('isAbtOnBitrateEnabled', false);
        setValue('isAbtOnFramerateEnabled', false);
      }
    }

    if (name === 'enableGPIO0' && !checked) {
      setValue('enableGPIO0ChangeDetectOnFallingEdge', false);
    }
    if (name === 'enableGPIO1' && !checked) {
      setValue('enableGPIO1ChangeDetectOnFallingEdge', false);
    }
    if (name === 'enableGPIO2' && !checked) {
      setValue('enableGPIO2ChangeDetectOnFallingEdge', false);
    }
    if (name === 'enableGPIO3' && !checked) {
      setValue('enableGPIO3ChangeDetectOnFallingEdge', false);
      setValue('setGPIO3', false);
    }

    //IMAGER SPECIFIC SETTINGS
    if (name.includes('enableImagerState_')) {
      const inputCode = name?.substring(name?.indexOf('_') + 1);
      ['recording', 'streaming'].forEach((type) => {
        setValue(`enableVideoPipeline_${inputCode}_${type}_`, Boolean(checked));
        setValue(`enableAudioPipeline_${inputCode}_${type}_`, Boolean(checked));
      });
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
      }}
    >
      <Box display="inline-flex" width="70%" alignItems="center">
        <Typography
          color="#0B2547"
          fontSize={item?.bold ? '16px' : '14px'}
          fontWeight={item?.bold ? '500' : '400'}
        >
          {labelName}
        </Typography>
        <CustomTooltip text={item?.infoText} />
      </Box>
      <FormControlLabel
        sx={{ 'margin': 0, '& .MuiTypography-root': { color: '#0B2547', fontSize: '14px' } }}
        control={
          <Android12Switch
            disabled={Boolean(rest.hasOwnProperty('isDisabled') ? rest.isDisabled(watch) : false)}
            checked={Boolean(initVal)}
            onChange={handleChange}
          />
        }
        label={initVal ? rest?.enableText || 'Enabled' : rest?.disableText || 'Disabled'}
      />
    </Box>
  );
}
