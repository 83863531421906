import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { GROUP_ADB_LOG_TABLE_COLUMNS } from './columns';

export function AdbLogsTable() {
  const { cameraId, camera } = useContext(CameraDetailsContext);
  const tenantId = useSelector(selectTenantId);

  return (
    <Box mt={2} height="90%">
      <PaginatedTableContextProvider>
        <InfiniteScrollView
          key={generateUniqueKey([tenantId, cameraId])}
          initialToken={''}
          fullView={false}
          itemsPerPage={25}
          fetcher={async ({ signal, limit, token: conToken }) => {
            const secretToken = selectSecretToken(store.getState());
            const request = api.ac.v3.report.device
              .$deviceId(camera.deviceSerialNumber)
              ['log-request'].$get({
                signal,
                params: {
                  secretToken,
                  pageSize: limit,
                  deviceId: camera.deviceSerialNumber,
                  continuationToken: conToken,
                },
              });

            await request.process();
            const result = request.result.data;
            const token = request.result.continuationToken;

            return { result, token };
          }}
          renderEmpty={() => (
            <IconMessageBox
              size="256px"
              src="/images/player/no-data-icon.svg"
              message="No Data Found"
            />
          )}
          renderList={(state) => (
            <InfiniteScrollTableView
              state={state}
              columns={GROUP_ADB_LOG_TABLE_COLUMNS}
              sx={{ height: 'calc(100vh - 220px)' }}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
