import { Buffer } from 'buffer';
import { isArray } from 'lodash';

/**
 * @param {Blob|ArrayBuffer|Uint8Array} message
 * @returns {Promise<{ bboxes?: {[key: string]: Array<VideoMetaData>}, telematics?: Array<TelematicsData> }>}
 */
export async function parseVideoMetaDataMessage(message) {
  try {
    let arr = message;
    if (message instanceof Blob) {
      arr = new Uint8Array(await message.arrayBuffer());
    } else if (message instanceof ArrayBuffer) {
      arr = new Uint8Array(message);
    }
    if (!(arr instanceof Uint8Array)) {
      throw new Error(`Expected Uint8Array. Found: ${arr}`);
    }

    const ssrc = (arr[4] << 24) | (arr[5] << 16) | (arr[6] << 8) | arr[7];

    let last = arr.length - 1;
    while (last > 0 && !arr[last]) last--;

    const json = Buffer.from(arr.slice(12, last + 1).buffer).toString('utf-8');
    if (!json || json === 'NONE') {
      return {};
    }

    const data = JSON.parse(json);
    if (!data?.telematics && isArray(data) && data?.length) {
      return { bboxes: { [`${ssrc}`]: data } };
    }
  } catch (err) {
    console.warn('Failed to parse video metadata message', err, message);
  }
  return {};
}

/**
 * Displays statistics of a video player
 * @param {HTMLVideoElement} video
 */
export function printVideoDetails(video) {
  if (!video) return;
  const readyStates = [
    'HAVE_NOTHING', //
    'HAVE_METADATA',
    'HAVE_CURRENT_DATA',
    'HAVE_FUTURE_DATA',
    'HAVE_ENOUGH_DATA',
  ];
  const networkStates = [
    'NETWORK_EMPTY', //
    'NETWORK_IDLE',
    'NETWORK_LOADING',
    'NETWORK_NO_SOURCE',
  ];
  console.warn(
    'Video =>',
    'readyState',
    video.readyState,
    readyStates[video.readyState],
    'networkState',
    video.networkState,
    networkStates[video.networkState],
    'ended:',
    video.ended,
    'seeking:',
    video.seeking,
    'paused:',
    video.paused,
    'currentTime:',
    video.currentTime
  );
}
