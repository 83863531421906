import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { CustomInput } from '../CustomInput';

/**
 * @template T
 * @typedef {object} CustomInputProps<T>
 * @property {any} name
 * @property {string} label
 * @property {number} [minRows]
 * @property {string} [placeholder]
 * @property {import('@mui/material').TextFieldProps['type']} [type]
 * @property {Partial<import('@mui/material').TextFieldProps>} [TextFieldProps]
 * @property {import('react-hook-form').ControllerProps<T, any>['rules']} [rules]
 * @property {Partial<import('@mui/material').TextFieldProps['InputProps']>} [InputProps]
 */

/**
 * @template T
 * @param {CustomInputProps<T> & import('@mui/material').FormControlProps} props
 */
export function CustomPasswordInput(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <CustomInput
      placeholder="Enter password"
      {...props}
      InputProps={{
        autoComplete: 'current-password',
        ...props.InputProps,
        sx: { pr: '5px', ...props.InputProps?.sx },
        type: passwordVisible ? 'text' : 'password',
        endAdornment: (
          <IconButton size="small" onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <VisibilityOutlined htmlColor="#1E59A8" />
            ) : (
              <VisibilityOffOutlined htmlColor="#C5D9F0" />
            )}
          </IconButton>
        ),
      }}
    />
  );
}
