import { AI_LABEL_DATA } from '../ai-labels';
import {
  extractAccelerometerItems,
  extractGpsItems,
  extractGyroscopeItems,
  makeDisplayText,
} from './extractor';

/**
 * Sorts the keys of an object based on the order specified in an array,
 * and returns a new object with the sorted keys.
 * @param {object} obj - The input object to sort.
 * @param {string[]} keys - An array specifying the desired order of keys.
 */
export const sortObjectKeysByArray = (obj, keys) => {
  const sortedKeys = keys.filter((key) => obj.hasOwnProperty(key));
  const remainingKeys = Object.keys(obj).filter((key) => !sortedKeys.includes(key));
  return {
    ...Object.fromEntries(sortedKeys.map((key) => [key, obj[key]])),
    ...Object.fromEntries(remainingKeys.map((key) => [key, obj[key]])),
  };
};

/**
 * Fetch and parse the sensor report files
 * @param {string} fileUrl
 * @param {AbortSignal} [signal]
 * @returns {Promise<SensorData>}
 */
export async function fetchSensorFile(fileUrl, signal) {
  if (!fileUrl) return null;
  const request = await fetch(fileUrl, {
    signal,
    credentials: 'omit',
    cache: 'force-cache',
  });
  if (request.status !== 200) return null;

  /** @type {SensorData} */
  const result = await request.json();

  if (result.ACCELEROMETER) {
    Object.entries(result.ACCELEROMETER).forEach(([k, v]) => {
      result.ACCELEROMETER[k] = Number(v);
    });
  }
  if (result.GYROSCOPE) {
    Object.entries(result.GYROSCOPE).forEach(([k, v]) => {
      result.GYROSCOPE[k] = Number(v);
    });
  }
  if (result.GEO_LOCATION) {
    Object.entries(result.GEO_LOCATION).forEach(([k, v]) => {
      result.GEO_LOCATION[k] = Number(v);
    });
  }
  result.VIDEO_META = result.VIDEO_META || {};
  return result;
}

/**
 * Build sensor info data for event item view.
 * @param {SensorData} reports
 * @param {boolean} [imperial]
 */
export function buildEventSensorInfo(reports, imperial) {
  if (!reports?.VIDEO_META) {
    return {};
  }
  /** @type {{[key: string]: string}} */
  const info = {};
  for (const meta of Object.values(reports.VIDEO_META)) {
    for (const box of meta || []) {
      for (const det of box?.detections || []) {
        if (det?.length !== 3) continue;
        const [label, value] = det;
        const aiLabel = AI_LABEL_DATA[label];
        if (!aiLabel) continue;
        /** @type {TelematicsTemplate} */
        const item = {
          source: 'bbox',
          name: label,
          value: value,
          unit: aiLabel.unit,
          type: typeof value === 'number' ? 'number' : 'string',
        };
        const text = makeDisplayText(item, 2, imperial);
        const key = aiLabel.displayName;
        const prev = info[key];
        info[key] = prev ? `${prev}, ${text}` : text;
      }
    }
  }
  return info;
}

/**
 * Build sensor info data for event item view.
 * @param {SensorData} reports
 * @param {boolean} [imperial]
 */
export function buildCameraSensorInfo(reports, imperial) {
  const geoLocation = extractGpsItems(reports?.GEO_LOCATION);
  const accelerometer = extractAccelerometerItems(reports?.ACCELEROMETER);
  const gyroscope = extractGyroscopeItems(reports?.GYROSCOPE);
  /** @type {{[key: string]: string}} */
  const info = {};
  for (const item of [accelerometer, geoLocation, gyroscope].flat()) {
    const aiLabel = AI_LABEL_DATA[`${item.name}`];
    if (!aiLabel) return;
    const text = makeDisplayText(item, 2, imperial);
    const key = aiLabel.displayName;
    const prev = info[key];
    info[key] = prev ? `${prev}, ${text}` : text;
  }
  return info;
}

/**
 * Build sensor info data for both camera and event item view.
 * @param {SensorData} reports
 * @param {boolean} [imperial]
 */
export function buildSensorInfo(reports, imperial) {
  const event = buildEventSensorInfo(reports, imperial);
  const camera = buildCameraSensorInfo(reports, imperial);
  /** @type {{[key: string]: string}} */
  const info = { ...event };
  for (const [key, text] of Object.entries(camera)) {
    const prev = info[key];
    info[key] = prev ? `${prev}, ${text}` : text;
  }
  return info;
}
