import { T } from '@/assets/locales';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * @typedef {object} ConfirmationDialogProps
 * @property {boolean} [open]
 * @property {string} [title]
 * @property {string} [message]
 * @property {any} [onCancel]
 * @property {any} [onApply]
 */

/**
 * @param {ConfirmationDialogProps & import('@mui/material').DialogProps} props
 */
export function ConfirmationDialog(props) {
  const { open, title, message, onCancel, onApply, ...dialogProps } = props;
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <Dialog {...dialogProps} open onClose={onCancel}>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>{t(title) || 'Confirmation'}</Box>
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText variant="subtitle1">
          {t(message) || 'Are you sure you want to execute this action?'}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={onCancel}>{t(T['button.disagree'])}</Button>
        <Button onClick={onApply} variant="contained">
          {t(T['button.agree'])}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
