import { T } from '@/assets/locales';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const defaultValues = {
  name: '',
};

/**
 * @typedef {object} DevicePoolFormPropsBase
 * @property {import('react-hook-form').SubmitHandler<typeof defaultValues>} onSubmitData
 * @property {boolean} loading
 */

/**
 * @typedef {DevicePoolFormPropsBase & import('@mui/material').BoxProps} DevicePoolFormProps
 */

/**
 * @param {DevicePoolFormProps} props
 */
export function DevicePoolForm(props) {
  const { onSubmitData, loading } = props;

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const poolId = useMemo(() => params.id, [params]);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.name = trimString(data.name);
    onSubmitData(data);
  };

  const handleCancel = () => {
    navigate('/administration/device-pools');
  };

  if (loading) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Box>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        {t(T['device-pool.form.text.secondary'])}
      </Typography>
      <Divider />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} md={6}>
              <CustomInput
                name="name"
                label={t(T['device-pool.form.name.label'])}
                placeholder={t(T['device-pool.form.name.placeholder'])}
                rules={{ required: t(T['device-pool.form.name.required']) }}
              />
            </Grid>
          </Grid>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={10}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button disabled={loading} variant="contained" type="submit">
              {loading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {poolId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
