import { DASHBOARD_VERSION, SENTRY_DSN, isLocalNetwork, isProduction } from '@/config';
import { store } from '@/store';
import { selectTenantId, selectUserEmail, selectUserId, selectUserRole } from '@/store/auth';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

function getUser() {
  const state = store.getState();
  return {
    id: selectUserId(state) + '',
    email: selectUserEmail(state),
    tenantId: selectTenantId(state),
    role: selectUserRole(state),
  };
}

export function setupSentryIO() {
  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: !isLocalNetwork,
    attachStacktrace: true,
    release: DASHBOARD_VERSION,
    tracesSampleRate: 1.0,
    environment: isProduction ? 'production' : 'development',
    ignoreErrors: ['AbortError', 'KnownUserError', 'CustomLoggerError'],
    integrations: [
      // new Sentry.Replay({}),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
}

/**
 * @param {SentryEvent} event
 * @param {string} details
 * @param {any} extra
 * @param {import('@sentry/react').Event['level']} [level]
 */
export function reportEvent(event, details, extra, level) {
  if (level !== 'fatal') return; // TODO: to reduce consumption
  const user = getUser();
  Sentry.captureEvent({
    user: getUser(),
    level: level || event.level,
    message: event.message,
    fingerprint: [event.fingerprint, details],
    transaction: details,
    timestamp: Date.now() / 1000,
    extra,
    tags: {
      email: user.email,
      tenantId: user.tenantId,
      connectionType: window.navigator?.connection?.type,
      ...extra.tags,
    },
  });
}

/**
 * @template T, D
 * @param {import('axios').AxiosError<T, D>} error
 * @param {import('axios').AxiosRequestConfig<D>} [request]
 * @param {import('axios').AxiosResponse<T, D>} [response]
 * @param {any} [extra]
 */
export function reportAxiosError(error, request, response, extra) {
  if (!error) return;
  request = request || error.config || {};
  response = response || error.response;
  if ([403, 404].includes(Number(response?.status))) return;
  reportEvent(
    SentryEvents.SERVICE_CALL_FAILURE,
    request.url,
    {
      method: request.method,
      url: request.url,
      payload: request.data,
      request_headers: request.headers,
      status_code: response?.status,
      headers: response?.headers,
      body: response?.data,
      ...extra,
      tags: {
        url: request.url,
        method: request.method,
        status_code: response?.status,
        ...extra?.tags,
      },
    },
    request.method.toLowerCase() === 'get' ? 'warning' : 'error'
  );
}

export const SentryEvents = {
  /** @type {SentryEvent} */
  LOGIN_FAILED: {
    fingerprint: 'fa1761ba-a0e6-4796-b816-ed2f3155934f',
    message: 'Failed to login',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  SLOW_LOGIN: {
    fingerprint: '4ffd0ace-34d5-49a1-9867-5cd34cc1b30e',
    message: 'Login takes more than 10 seconds',
    level: 'warning',
  },
  /** @type {SentryEvent} */
  RESET_LINK_SEND_FAILED: {
    fingerprint: '9d697b4b-c308-4f8d-adc9-f5f3c711446d',
    message: 'Failed to send reset link',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  RESET_PASSWORD_FAILED: {
    fingerprint: 'ceea9ec4-a9b0-4bb3-bc7a-815157f52fa6',
    message: 'Failed to reset password',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  TENANT_LOGIN_FAILED: {
    fingerprint: '1c67c204-3dbf-48f9-9bea-cf253ae41b7b',
    message: 'Failed to tenant login',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  TENANT_SWITCH_FAILED: {
    fingerprint: '1b5da789-f4df-4bd0-8788-7f65ded972b3',
    message: 'Failed to switch to tenant',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  CHANGE_PASSWORD_FAILED: {
    fingerprint: '15617dbc-a3d6-49a8-b6b5-b54a557f4a58',
    message: 'Failed to change password',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  CAMERA_LIST_LOAD_FAILED: {
    fingerprint: '322a42a0-ca0c-485c-82ec-5585e0b034a9',
    message: 'Failed to load camera list',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  CAMERA_DETAILS_LOAD_FAILED: {
    fingerprint: '06490ed8-4179-4633-9fef-148948918bae',
    message: 'Failed to load camera details',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  SLOW_CAMERA_LIST_LOAD: {
    fingerprint: '63cb28f7-7afe-47d9-ae4c-296d75dd2c3e',
    message: 'Camera list loading takes more than 10 seconds',
    level: 'warning',
  },
  /** @type {SentryEvent} */
  EVENT_LIST_LOAD_FAILED: {
    fingerprint: 'ab46c7f8-93cc-486d-8a4b-cde6109ce2fb',
    message: 'Failed to load event list',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  SLOW_EVENT_LIST_LOAD: {
    fingerprint: 'fce8e038-1cd5-4b40-9800-9991d242d80b',
    message: 'Event list loading takes more than 10 seconds',
    level: 'warning',
  },
  /** @type {SentryEvent} */
  TRIP_LIST_LOAD_FAILED: {
    fingerprint: '76d0fe44-d81b-4d18-b112-4a3db9a5a746',
    message: 'Failed to load trip list',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  SLOW_TRIP_LIST_LOAD: {
    fingerprint: '6933b3c1-1dcc-47ba-984f-19e3224d0f5a',
    message: 'Trip list loading takes more than 10 seconds',
    level: 'warning',
  },
  /** @type {SentryEvent} */
  TRIP_LIST_DETAIL_LOAD_FAILED: {
    fingerprint: 'cd8e571e-2d41-4cbe-beb9-471f59f15d37',
    message: 'Failed to load trip list details',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  CREATE_SESSION_FAILED: {
    fingerprint: '06490ed8-4179-4633-9fef-148948918bae',
    message: 'Failed to create session',
    level: 'error',
  },
  /** @type {SentryEvent} */
  GET_NEGOTIATE_TOKEN_FAILED: {
    fingerprint: 'a9712e83-edca-43b9-86b7-8aa6a6b89ba8',
    message: 'Failed to get SignalR access token',
    level: 'error',
  },
  /** @type {SentryEvent} */
  HUB_CONNECTION_CLOSED: {
    fingerprint: '68f7dc04-85f8-43ad-87ea-3ae19a5a4be0',
    message: 'SignalR connection was closed',
    level: 'info',
  },
  /** @type {SentryEvent} */
  HUB_CONNECTION_FAILED: {
    fingerprint: 'fd481567-f2d9-495e-9891-3b525566d47d',
    message: 'SignalR connection failed',
    level: 'error',
  },
  /** @type {SentryEvent} */
  HUB_FAILED_TO_SEND_MESSAGE: {
    fingerprint: '3e91a718-65bc-49df-945c-5f890c575f89',
    message: 'Failed to send SignalR message',
    level: 'error',
  },
  /** @type {SentryEvent} */
  LIVE_STREAM_HANGUP_RECEIVED: {
    fingerprint: '06024ed1-9d1d-44f8-859a-c1db07d7de30',
    message: 'Received hangup from the Camera',
    level: 'info',
  },
  /** @type {SentryEvent} */
  LIVE_STREAM_MAX_CONNECTION: {
    fingerprint: '452b0dbf-58bc-4e94-829c-31aec8a9f89d',
    message: 'The maximum number of streaming connections reached',
    level: 'info',
  },
  /** @type {SentryEvent} */
  LIVE_STREAM_SERVER_CONFIG_FAILURE: {
    fingerprint: '4cf3f89b-28d4-40b9-826d-0e9fa967b6a4',
    message: 'Failed to get relay server config for live streaming',
    level: 'error',
  },
  /** @type {SentryEvent} */
  LIVE_STREAM_CONNECTION_FAILED: {
    fingerprint: '7965e522-3d03-4492-b4f0-b90bb6ee41a6',
    message: 'Failed to establish WebRTC connection',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  LIVE_STREAM_NO_RESPONSE: {
    fingerprint: '20440e18-89b0-4793-9470-23a8fab45f26',
    message: 'No response for stream request after 60 seconds',
    level: 'fatal',
  },
  /** @type {SentryEvent} */
  LIVE_STREAM_REPORT: {
    fingerprint: 'a8d378a5-8c1d-4e35-b691-e119a8ae342a',
    message: 'Live stream report',
    level: 'info',
  },
  /** @type {SentryEvent} */
  SERVICE_CALL_FAILURE: {
    fingerprint: 'fb212a5d-e6e4-4ec1-a4d0-f86a6104e008',
    message: 'API call failed',
    level: 'warning',
  },
  /** @type {SentryEvent} */
  CONSOLE_ERROR_LOG: {
    fingerprint: '4af9ef7e-c2b4-466c-bb8b-7466992843e8',
    message: 'Console error log',
    level: 'warning',
  },
  /** @type {SentryEvent} */
  CONTINUOUS_RECORDING_REPORT: {
    fingerprint: 'fa659542-8b6d-42b3-a048-afe2642e6d1e',
    message: 'Continuous recording report',
    level: 'info',
  },
};

export const _placeholders = {
  /** @type {SentryEvent} */
  _c1d16449: {
    fingerprint: 'c1d16449-271c-4b78-a62a-34eaa3ab67a2',
    message: '',
  },
  /** @type {SentryEvent} */
  _20672166: {
    fingerprint: '20672166-4c2e-4e50-9d0a-73ee83f0db5b',
    message: '',
  },
  /** @type {SentryEvent} */
  _58cfb3c2: {
    fingerprint: '58cfb3c2-6f65-4d73-a8ed-b02bc44908e1',
    message: '',
  },
  /** @type {SentryEvent} */
  _d99e29c1: {
    fingerprint: 'd99e29c1-ab42-440f-bdc6-f9073809f025',
    message: '',
  },
  /** @type {SentryEvent} */
  _97cadc7c: {
    fingerprint: '97cadc7c-e24f-41ee-84b4-e5874aabaa38',
    message: '',
  },
  /** @type {SentryEvent} */
  _581c6ad6: {
    fingerprint: '581c6ad6-1f7c-442a-bb97-baf065b368cf',
    message: '',
  },
  /** @type {SentryEvent} */
  _6177af7e: {
    fingerprint: '6177af7e-08cf-4450-802f-3c18cbc9b77d',
    message: '',
  },
  /** @type {SentryEvent} */
  _114d8596: {
    fingerprint: '114d8596-e6e1-4e02-9e8e-37ea03611f79',
    message: '',
  },
  /** @type {SentryEvent} */
  _cbdcd0f8: {
    fingerprint: 'cbdcd0f8-1fa3-4c17-b299-863b04afb562',
    message: '',
  },
  /** @type {SentryEvent} */
  _d1797130: {
    fingerprint: 'd1797130-a768-4380-a184-ea6fe1c3a933',
    message: '',
  },
  /** @type {SentryEvent} */
  _e3a6dbd7: {
    fingerprint: 'e3a6dbd7-9fe8-422c-95b3-32337b52defa',
    message: '',
  },
  /** @type {SentryEvent} */
  _f8a73437: {
    fingerprint: 'f8a73437-eaed-4044-ac22-0a2698fe6963',
    message: '',
  },
  /** @type {SentryEvent} */
  _a7bd4f09: {
    fingerprint: 'a7bd4f09-86f0-4973-9c8e-18f2651cc9d6',
    message: '',
  },
  /** @type {SentryEvent} */
  _ce02134d: {
    fingerprint: 'ce02134d-09e6-43ff-8351-85b4ad172ceb',
    message: '',
  },
  /** @type {SentryEvent} */
  _50e9e530: {
    fingerprint: '50e9e530-a5f1-43a0-9131-f49e10ae87a8',
    message: '',
  },
  /** @type {SentryEvent} */
  _83c12d56: {
    fingerprint: '83c12d56-58c4-4722-8f29-a94262d957dc',
    message: '',
  },
  /** @type {SentryEvent} */
  _9cef9d92: {
    fingerprint: '9cef9d92-1d87-46ef-bb75-e1d140b02ac8',
    message: '',
  },
  /** @type {SentryEvent} */
  _9df579be: {
    fingerprint: '9df579be-49bd-4bfe-9dd6-09db5481edbd',
    message: '',
  },
  /** @type {SentryEvent} */
  _cd7c3719: {
    fingerprint: 'cd7c3719-69c4-403e-92f0-e4da3c03e348',
    message: '',
  },
  /** @type {SentryEvent} */
  _a7728ed1: {
    fingerprint: 'a7728ed1-925b-4aa0-9c8d-6a040bba405c',
    message: '',
  },
  /** @type {SentryEvent} */
  _785a91fe: {
    fingerprint: '785a91fe-b2ec-41cc-9e46-55847e610249',
    message: '',
  },
  /** @type {SentryEvent} */
  _a37b2bc9: {
    fingerprint: 'a37b2bc9-8fe2-4a3f-94a9-f443753e3080',
    message: '',
  },
  /** @type {SentryEvent} */
  _41067a22: {
    fingerprint: '41067a22-3678-4a70-b078-7bb3e14d16d9',
    message: '',
  },
  /** @type {SentryEvent} */
  _7456d840: {
    fingerprint: '7456d840-9761-418e-90c4-27fee7a8eb16',
    message: '',
  },
  /** @type {SentryEvent} */
  _038bfb4e: {
    fingerprint: '038bfb4e-e355-4ee3-aa56-4bcdb34345c3',
    message: '',
  },
  /** @type {SentryEvent} */
  _615a1da2: {
    fingerprint: '615a1da2-ab10-4a19-913a-d61aca2728da',
    message: '',
  },
  /** @type {SentryEvent} */
  _3282fbda: {
    fingerprint: '3282fbda-59be-4771-8e8e-b2019bd17d98',
    message: '',
  },
  /** @type {SentryEvent} */
  _2cdbdd37: {
    fingerprint: '2cdbdd37-a1d4-40e6-90e9-74a62a40a3db',
    message: '',
  },
  /** @type {SentryEvent} */
  _ba739bf4: {
    fingerprint: 'ba739bf4-ffbc-4f5e-b7b4-64eed3dfd071',
    message: '',
  },
  /** @type {SentryEvent} */
  _4f294f7b: {
    fingerprint: '4f294f7b-6abe-4fc0-9a17-f29021bfde32',
    message: '',
  },
  /** @type {SentryEvent} */
  _9efc02b3: {
    fingerprint: '9efc02b3-66a8-4ea8-822d-c989b7efa0b7',
    message: '',
  },
  /** @type {SentryEvent} */
  _197fa527: {
    fingerprint: '197fa527-c091-465d-8065-9f6c72124cdb',
    message: '',
  },
  /** @type {SentryEvent} */
  _e4241c60: {
    fingerprint: 'e4241c60-60b6-4eef-98b1-9127723432a2',
    message: '',
  },
  /** @type {SentryEvent} */
  _c7d3b823: {
    fingerprint: 'c7d3b823-4a3a-4339-a52e-ca039752eb50',
    message: '',
  },
  /** @type {SentryEvent} */
  _1b88c5c5: {
    fingerprint: '1b88c5c5-524e-49a6-8f1b-68bc370cdc8b',
    message: '',
  },
  /** @type {SentryEvent} */
  _8c046e22: {
    fingerprint: '8c046e22-27d7-477b-acbb-b04273901ce6',
    message: '',
  },
  /** @type {SentryEvent} */
  _aa0904f5: {
    fingerprint: 'aa0904f5-c018-41bd-af3f-c2f997810d36',
    message: '',
  },
  /** @type {SentryEvent} */
  _f3f67531: {
    fingerprint: 'f3f67531-8684-4b5d-a122-d8debbf0feb3',
    message: '',
  },
  /** @type {SentryEvent} */
  _d1ecf655: {
    fingerprint: 'd1ecf655-ea9d-444e-a618-ef91c032d1f9',
    message: '',
  },
  /** @type {SentryEvent} */
  _5adfd548: {
    fingerprint: '5adfd548-dd46-428e-bff0-c520268b641f',
    message: '',
  },
  /** @type {SentryEvent} */
  _c08656bf: {
    fingerprint: 'c08656bf-0df3-4efb-b772-ae20134e1591',
    message: '',
  },
  /** @type {SentryEvent} */
  _edeef7bd: {
    fingerprint: 'edeef7bd-96b3-4f52-8ad6-c634995c08b0',
    message: '',
  },
  /** @type {SentryEvent} */
  _1b304365: {
    fingerprint: '1b304365-1c45-4d32-9b37-022a7674e3ba',
    message: '',
  },
  /** @type {SentryEvent} */
  _25c027cd: {
    fingerprint: '25c027cd-1f1c-4fed-bf02-ddd56858c4f2',
    message: '',
  },
  /** @type {SentryEvent} */
  _790f5fc9: {
    fingerprint: '790f5fc9-75c5-4b57-810c-760cc719973e',
    message: '',
  },
  /** @type {SentryEvent} */
  _00b3b289: {
    fingerprint: '00b3b289-57e8-436e-b393-775457332390',
    message: '',
  },
  /** @type {SentryEvent} */
  _4bd0330f: {
    fingerprint: '4bd0330f-46c9-4311-8367-ee4c83030860',
    message: '',
  },
  /** @type {SentryEvent} */
  _f4b4e524: {
    fingerprint: 'f4b4e524-a1f3-4afa-934f-c6d4b0b7e9c5',
    message: '',
  },
  /** @type {SentryEvent} */
  _b75a4be0: {
    fingerprint: 'b75a4be0-af6a-4c1a-814c-6d5058ac2de4',
    message: '',
  },
  /** @type {SentryEvent} */
  _f56fd4ca: {
    fingerprint: 'f56fd4ca-bbea-4541-9ccd-03738428d14b',
    message: '',
  },
  /** @type {SentryEvent} */
  _e783d4b0: {
    fingerprint: 'e783d4b0-205a-46dd-812b-a9afb87eee3c',
    message: '',
  },
  /** @type {SentryEvent} */
  _4cf456e0: {
    fingerprint: '4cf456e0-0d16-40dc-bbd2-0279ac85ed82',
    message: '',
  },
  /** @type {SentryEvent} */
  _5b7cc823: {
    fingerprint: '5b7cc823-745a-4a50-a095-1eb08a627117',
    message: '',
  },
  /** @type {SentryEvent} */
  _5e3e37b8: {
    fingerprint: '5e3e37b8-0685-41e4-8951-f0bd388dddb5',
    message: '',
  },
  /** @type {SentryEvent} */
  _ce9aa8b5: {
    fingerprint: 'ce9aa8b5-e7a9-4d0c-9ca9-b8a2679f67d5',
    message: '',
  },
  /** @type {SentryEvent} */
  _dcf29aed: {
    fingerprint: 'dcf29aed-2246-42f5-99d3-84d35c59d73a',
    message: '',
  },
  /** @type {SentryEvent} */
  _9ae656ae: {
    fingerprint: '9ae656ae-b13d-482d-9a8c-9484dcd045cb',
    message: '',
  },
  /** @type {SentryEvent} */
  _f29c3c15: {
    fingerprint: 'f29c3c15-ed4e-4d59-a301-a5cb33b3d07f',
    message: '',
  },
  /** @type {SentryEvent} */
  _ea5e0236: {
    fingerprint: 'ea5e0236-ac30-4ebc-915c-9d3ebb13e25b',
    message: '',
  },
  /** @type {SentryEvent} */
  _a2e680f5: {
    fingerprint: 'a2e680f5-0250-4c7b-834c-117f8191fd42',
    message: '',
  },
  /** @type {SentryEvent} */
  _6c49ca01: {
    fingerprint: '6c49ca01-52db-4cac-abe3-41058c5655bf',
    message: '',
  },
  /** @type {SentryEvent} */
  _35994739: {
    fingerprint: '35994739-4f78-4b39-a05f-57143310e991',
    message: '',
  },
  /** @type {SentryEvent} */
  _8befb14c: {
    fingerprint: '8befb14c-c1e5-4ebc-8a28-b68da2c6f7c1',
    message: '',
  },
  /** @type {SentryEvent} */
  _22faa660: {
    fingerprint: '22faa660-fde9-42b4-88bb-c331b803814d',
    message: '',
  },
  /** @type {SentryEvent} */
  _69690bdb: {
    fingerprint: '69690bdb-093a-402f-bc1b-3365c9fd072a',
    message: '',
  },
  /** @type {SentryEvent} */
  _f7e93cdb: {
    fingerprint: 'f7e93cdb-15a4-49fe-b60a-a47293120c40',
    message: '',
  },
  /** @type {SentryEvent} */
  _bfd8825b: {
    fingerprint: 'bfd8825b-cf9a-4752-a2de-9422ed6aee55',
    message: '',
  },
  /** @type {SentryEvent} */
  _3fc76ef2: {
    fingerprint: '3fc76ef2-2d02-4641-9804-09016d0f9a28',
    message: '',
  },
  /** @type {SentryEvent} */
  _5569c41b: {
    fingerprint: '5569c41b-313c-4013-ba22-2f05b12508a0',
    message: '',
  },
};
