import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    'backgroundColor': theme.palette.common.white,
    'color': 'rgba(0, 0, 0, 0.87)',
    'boxShadow': theme.shadows[1],
    'fontSize': 11,
    '& .MuiTooltip-arrow': {
      'color': theme.palette.common.white,
      'borderColor': 'rgba(0, 0, 0, 0.1)',
      'borderWidth': '1px',
      '&:before': {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
}));

export default LightTooltip;
