import { SMARTER_AI_ICON } from '@/assets/constants/images';
import { getCountryFlagUrlByCode, getCountryNameByCode } from '@/utils/country-flags';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<TenantInfo>>} */
export const TENANT_TABLE_COLUMNS = [
  {
    id: 'tenantId',
    label: '',
    align: 'left',
    isPrimary: true,
    width: '1%',
    searchable: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'tenantId'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'tenantName',
    label: 'Name',
    align: 'left',
    width: '40%',
    isPrimary: true,
    searchable: true,
  },
  {
    id: 'tenantRegion',
    label: 'Region',
    align: 'left',
    width: '20%',
    searchable: true,
    format: (value) => {
      const region = value?.toUpperCase();
      const icon = region !== 'LEGACY' ? getCountryFlagUrlByCode(region) : SMARTER_AI_ICON;
      const countryName =
        value === 'EU' ? 'European Union' : getCountryNameByCode(value?.toUpperCase());
      return (
        <IconLabelItem
          src={icon}
          title={countryName}
          sx={{
            gap: 1,
            alignItems: 'center',
          }}
          iconSize={{ xs: '17px', md: '22px' }}
        />
      );
    },
  },
];
