import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { PageView } from '@/store/page-view';
import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { EventFilterContext } from '@/web/@components/EventFilterContext';
import { FilterContext, SetFilterContext } from '@/web/@components/FilterContext';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} EventListContextValue
 * @property {string} tableKey
 * @property {number} totalEvents
 * @property {InfiniteScrollViewProps<EventV5ResponseModel>['fetcher']} fetcher
 */

/** @type {import("react").Context<EventListContextValue>} */
export const EventListContext = createContext(null);

/** @param {{cameraId?: number, children: any}} props */
export function EventListContextProvider(props) {
  const { cameraId, children } = props;

  const setFilter = useContext(SetFilterContext);
  const { searchType, searchText, startTime, endTime } = useContext(FilterContext);
  const { deviceName, deviceSerialNumber, triggerName, annotationName } =
    useContext(EventFilterContext);

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  useEffect(() => {
    if (!startTime || !endTime) {
      setFilter({
        startTime: minusOneMonth(),
        endTime: endOfTheDay(Date.now()),
      });
    }
  }, [startTime, endTime, setFilter]);

  const [tableKey, setTableKey] = useState('');
  const [totalEvents, setTotalEvents] = useState(null);

  useEffect(() => {
    setTableKey(
      [
        cameraId,
        searchText,
        startTime,
        endTime,
        tenantId,
        deviceName,
        deviceSerialNumber,
        triggerName,
        annotationName,
      ]
        .filter(Boolean)
        .map((x) => x + '')
        .join('_')
    );
  }, [
    cameraId,
    searchText,
    startTime,
    endTime,
    tenantId,
    deviceName,
    deviceSerialNumber,
    triggerName,
    annotationName,
  ]);

  useEffect(() => {
    store.dispatch(PageView.setHoveredItem(null));
  }, [totalEvents]);

  /** @type {InfiniteScrollViewProps<EventV5ResponseModel>['fetcher']} */
  const fetcher = async ({ signal, limit, token: offset }) => {
    if (!offset) {
      setTotalEvents(null);
    }
    let time = Date.now();
    const reportSentry = (/** @type {import('@/api').ApiRequest} */ request) => {
      if (request.error) {
        reportEvent(SentryEvents.EVENT_LIST_LOAD_FAILED, '', {
          err: request.error,
          url: request.request.url,
          tags: request.request.params,
          headers: request.request.headers,
        });
      }
      const duration = Date.now() - time;
      if (duration >= 10000) {
        reportEvent(SentryEvents.SLOW_EVENT_LIST_LOAD, '', {
          duration,
          url: request.request.url,
          tags: request.request.params,
          headers: request.request.headers,
        });
      }
    };
    if (searchText && searchType === 'annotationName') {
      const request = api.ac.v5.events.annotation.filter.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          limit,
          offset: offset || 0,
          endTimestamp: endTime,
          startTimestamp: startTime,
          annotationName: searchText,
          ...(cameraId ? { endpointId: cameraId } : {}),
        },
      });
      await request.process();
      reportSentry(request);
      const result = request.result;
      const token = request.result.length < limit ? 0 : offset + limit;
      const total = Number(request.response.headers['x-total-count']);
      setTotalEvents(total);
      return { result, token };
    } else {
      const request = api.ac.v5.events.filter.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          pageSize: limit,
          endTimestamp: endTime,
          startTimestamp: startTime,
          lastMaxTimestamp: offset,
          ...(cameraId ? { endpointId: cameraId } : {}),
          ...(searchText ? { [searchType]: searchText } : {}),
          ...(deviceName ? { deviceName } : {}),
          ...(deviceSerialNumber ? { deviceSerialNumber } : {}),
          ...(triggerName ? { triggerName } : {}),
          ...(annotationName ? { annotationName } : {}),
        },
      });
      await request.process();
      reportSentry(request);
      const result = request.result.events;
      const token = request.result.maxTimestamp;
      const total = Number(request.response.headers['x-total-count']);
      setTotalEvents(total);
      return { result, token };
    }
  };

  return (
    <EventListContext.Provider
      value={{
        fetcher,
        tableKey,
        totalEvents,
      }}
    >
      <>{children}</>
    </EventListContext.Provider>
  );
}
