import { FilterContextProvider } from '@/web/@components/FilterContext';
import { FilteredDateRangePicker } from '@/web/@components/FilteredDateRangePicker';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, Divider, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { DiagnosticsLogsFilter } from '../../@components/DiagnosticsLogsFilter';
import { DiagnosticsLogsTable } from '../../@components/DiagnosticsLogsTable';
import { DiagnosticsMobileLogsTable } from '../../@components/DiagnosticsLogsTable/@components/MobileDeviceDiagnosticsLogs/mobile';

export function CameraDiagnosticsLogs() {
  const { camera } = useContext(CameraDetailsContext);

  const isAppDevice = useMemo(() => camera?.type === 'APP', [camera?.type]);

  return (
    <Box
      px={2.5}
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
        'overflowY': 'auto',
      }}
      height="98%"
    >
      <FilterContextProvider>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
          <Typography variant="body2" fontSize="1rem" fontWeight="500">
            Diagnostics Logs
          </Typography>
          <Box display="flex" gap={1}>
            <DiagnosticsLogsFilter searchable={!isAppDevice} selectable={true} />
            <FilteredDateRangePicker />
          </Box>
        </Box>
        <Divider />
        {isAppDevice ? <DiagnosticsMobileLogsTable /> : <DiagnosticsLogsTable />}
      </FilterContextProvider>
    </Box>
  );
}
