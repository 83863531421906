import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<VinGroupInfoAggregated>>} */
export const VIN_GROUP_TABLE_COLUMNS = [
  {
    id: 'vinGroupId',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'vinGroupId'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'vinGroupName',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    link: (row) => `/administration/vin-groups/${row.vinGroupId}`,
  },
  {
    id: 'targetGroupName',
    label: 'Device Group',
    align: 'left',
    link: (row) => `/administration/device-groups/${row.targetGroupId}`,
  },
  {
    id: 'totalVinCount',
    label: 'Total VINs',
    align: 'center',
    fallback: '0',
  },
  {
    id: 'onboardedVinCount',
    label: 'Total Onboarded VINs',
    align: 'center',
    fallback: '0',
  },
];
