import { FilterContextProvider } from '@/web/@components/FilterContext';
import { Box, Divider, Typography } from '@mui/material';
import { DiagnosticsLogsFilter } from '../../@components/DiagnosticsLogsFilter';
import { OtaLogsTable } from '../../@components/OtaLogsTable';

export function CameraOTALogs() {
  return (
    <Box
      px={2.5}
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
        'overflowY': 'auto',
      }}
      height="98%"
    >
      <FilterContextProvider>
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
          <Typography variant="body2" fontSize="1rem" fontWeight="500">
            OTA Logs
          </Typography>
          <Box display="flex" gap={1}>
            <DiagnosticsLogsFilter searchable={true} selectable={false} />
            {/* <CustomDateRangePicker /> */}
          </Box>
        </Box>
        <Divider />
        <OtaLogsTable />
      </FilterContextProvider>
    </Box>
  );
}
