import { Box, Grid, Typography } from '@mui/material';
import LineChart from '../LineChart';
import { SpeedingRangeBar } from '../SpeedingRangeBar/SpeedingRangeBar';
import { SensitivityLMHSelection } from '../SensitivityLMHSelection';
import { CustomSlider } from '@/web/@components/CustomForm';
import { convertValueByUnit, getSliderMarks } from '../../utils';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { isProduction } from '@/config';

export function SensitivitySettingsForm(props) {
  const { trigger, overridesValues, triggerVariableOverride, inheritance } = props;

  const { setValue } = useFormContext();

  return trigger.variables?.length ? (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle2">Sensitivity</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box>
          {trigger?.variables?.length > 0 && (
            <Box sx={{ '& .MuiTypography-root': { fontSize: '0.75rem' } }}>
              {[21, 22].includes(trigger?.triggerCategoryId) ? (
                <Box pt={3}>
                  <LineChart
                    variables={trigger?.variables}
                    onChange={(variableName, value) => {
                      triggerVariableOverride(variableName, value?.toFixed(1));
                    }}
                  />
                  <Typography sx={{ opacity: '0.5' }}>
                    Default Sensitivity [
                    {trigger?.variables?.map((item) => `${item.default} ${item?.unit}`)?.join(', ')}
                    ]
                  </Typography>
                </Box>
              ) : trigger?.triggerCategoryName?.includes('Speeding') ? (
                <SpeedingRangeBar
                  trigger={trigger}
                  triggerVariableOverride={triggerVariableOverride}
                />
              ) : (
                trigger?.variables?.slice(0, 1)?.map((variable, indexChild) => (
                  <Box key={indexChild} pt={0}>
                    {!isProduction && (
                      <SensitivityLMHSelection
                        variable={variable}
                        overridesValues={overridesValues}
                        triggerVariableOverride={triggerVariableOverride}
                      />
                    )}

                    <CustomSlider
                      name="sensitivity"
                      label=""
                      step={variable?.stepSize || 0.01}
                      min={variable?.minValue}
                      max={variable?.maxValue}
                      height={5}
                      defaultVal={Number(variable?.value || variable?.default)}
                      SliderProps={{
                        onChange: (_, value) => {
                          value = Number(value);
                          const result = convertValueByUnit(
                            value,
                            variable?.unit,
                            variable?.originalUnit
                          );
                          triggerVariableOverride(variable?.key, result, value);
                          setValue('sensitivity', Number(value));
                        },
                        disabled: isEmpty(variable),
                        marks: getSliderMarks(
                          variable?.minValue,
                          variable?.maxValue,
                          variable?.stepSize,
                          variable?.unit
                        ),
                      }}
                      postText={variable?.unit + ' '}
                    />
                    <Typography sx={{ opacity: '0.5' }}>
                      Default Sensitivity {variable?.default} {variable?.unit}
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        {inheritance('sensitivity')}
      </Grid>
    </Grid>
  ) : null;
}
