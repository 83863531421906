import {
  BOX_BIOMETRIC_ICON,
  BOX_CONSENT_ICON,
  BOX_SOFTWARE_ICON,
  BOX_TOOLS_ICON,
  DATA_PRIVACY_POLICY_ICON_ON,
  DATA_RESIDENCY_ICON_ON,
  DATA_RETENTION_ICON_ON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const DATA_PRIVACY_ITEMS = [
  {
    name: T['data-residency.page.card.name'],
    image: DATA_RESIDENCY_ICON_ON,
    color: '#EFF3F7',
    link: '/data-privacy/data-residency',
    countHide: true,
    accessLevel: 'SUPER_ADMIN',
    components: [
      {
        name: T['data-residency.page.card.configurations'],
        image: BOX_SOFTWARE_ICON,
        link: '/data-privacy/data-residency',
      },
    ],
  },
  {
    name: T['data-retention.page.card.name'],
    image: DATA_RETENTION_ICON_ON,
    color: '#F7F5EF',
    countHide: true,
    link: '/data-privacy/data-retention',
    accessLevel: 'HIDDEN',
    components: [
      {
        name: T['data-retention.page.card.configurations'],
        image: BOX_SOFTWARE_ICON,
        link: '/data-privacy/data-retention',
      },
    ],
  },
  {
    name: T['privacy-policies.page.card.name'],
    image: DATA_PRIVACY_POLICY_ICON_ON,
    color: '#F4F7EF',
    countHide: true,
    accessLevel: 'HIDDEN',
    link: '/data-privacy/privacy-policies',
    components: [
      {
        name: T['privacy-policies.page.card.biometric'],
        image: BOX_BIOMETRIC_ICON,
        link: '',
      },
      {
        name: T['privacy-policies.page.card.consent'],
        image: BOX_CONSENT_ICON,
        link: '',
      },
      {
        name: T['privacy-policies.page.card.tools'],
        image: BOX_TOOLS_ICON,
        link: '',
      },
    ],
  },
];
