import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { Avatar, Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function FileUploaderUser({ updatedBy }) {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [user, setUser] = useState(null);

  const getUserInfo = useCallback(
    async (coachUserId) => {
      try {
        const request = api.ac.v5.tenant
          .$tenantId(tenantId)
          .user.$userId(updatedBy)
          .$get({
            headers: {
              Authorization: secretToken,
            },
          });
        const result = await request?.process();
        setUser(result);
      } catch (err) {}
    },
    [secretToken, tenantId, updatedBy]
  );

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <Box display="flex" alignItems="center" gap={1} justifyContent={'center'}>
      <Avatar alt={user?.name} src={user?.pictureURL} sx={{ width: 30, height: 30 }} />
      <Typography fontSize="0.875rem">{user?.name}</Typography>
    </Box>
  );
}
