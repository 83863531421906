import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DeviceGroupDetailsContext } from '../../@components/DeviceGroupDetailsLayout';
import { ApnSettingsForm } from '../@components/ApnSettingsForm';

export function CreateAPNSettingsPage() {
  const secretToken = useSelector(selectSecretToken);
  const { groupId } = useContext(DeviceGroupDetailsContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /** @param {APNInfoForm} form */
  const createApnSettings = async (form) => {
    try {
      setLoading(true);
      const req = api.ac.v2.endpoint.apn.create.$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          ...form,
          groupId,
        },
      });
      await req.process();
      toastSuccess('Success', 'APN Settings created successfully');
      navigate(`/administration/device-groups/${groupId}/apns`);
    } catch (err) {
      toastWarning('Error', 'Failed to create APN Settings');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        APN Settings
      </Typography>
      <ApnSettingsForm onSubmitData={createApnSettings} loading={loading} />
    </Box>
  );
}
