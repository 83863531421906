import { T } from '@/assets/locales';
import { DriverTagList } from '.';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DriverTagList />,
    breadcrumb: T['pages.operations.name'],
  },
];

export default routes;
