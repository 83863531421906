import api from '@/api';
import { CAMERA_COMMON_ICON, CAMERA_COMMON_ICON_OFF } from '@/assets/constants/images';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { selectDisplaySourceId } from '@/utils/events';
import { BoxImage } from '@/web/@components/BoxImage';
import { EllipsisTextViewContainer } from '@/web/@components/EllipsisTextView';
import LightTooltip from '@/web/@components/LightTooltip';
import { ScoreMiniWidget } from '@/web/safety/scoring/@components/ScoreMiniWidget';
import { ArrowBack } from '@mui/icons-material';
import { Avatar, AvatarGroup, Box, Button, Grid, Typography } from '@mui/material';
import { subDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EventCarouselItem from '../../@components/EventCarouselItem';
import { formatNumberWithLeadingZero } from '../../@utils';
import { isCameraInParkingMode } from '@/utils/cameras';

export function CoachingInfoSection({
  sessionId,
  coachingInfo,
  loading,
  isGuided = false,
  eventId,
}) {
  const navigate = useNavigate();

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [coachingDetails, setCoachingDetails] = useState(null);
  const [playerWidth, setPlayerWidth] = useState(null); // State to hold the player width
  const [isPlayerContainerAvailable, setIsPlayerContainerAvailable] = useState(false);

  const handleBackToScoring = () => {
    navigate('/safety/coaching/pending');
  };

  const getCameraDetails = useCallback(
    async (endpointId) => {
      try {
        if (!endpointId) return;
        const request = api.ac.v5.endpoint.$endpointId(endpointId).details.$get({
          headers: {
            Authorization: secretToken,
          },
        });
        const result = await request?.process();
        return result;
      } catch (ex) {}
    },
    [secretToken]
  );

  const getCameraScore = useCallback(
    async (endpointId, startDate, endDate) => {
      try {
        if (!endpointId) return;
        const request = api.ac.v5.score.endpoint.$endpointId(endpointId).$get({
          headers: {
            Authorization: secretToken,
          },
          params: {
            fromTimestamp: startDate,
            toTimestamp: endDate,
          },
        });

        const result = await request?.process();
        // @ts-ignore
        return result?.scores?.find((item) => item?.personaId === 1)?.score;
      } catch (err) {}
    },
    [secretToken]
  );

  const getCoachInfo = useCallback(
    async (coachUserId) => {
      if (!coachUserId) return;
      try {
        const request = api.ac.v5.tenant
          .$tenantId(tenantId)
          .user.$userId(coachUserId)
          .$get({
            headers: {
              Authorization: secretToken,
            },
          });
        const result = await request?.process();
        return result || {};
      } catch (err) {}
    },
    [secretToken, tenantId]
  );

  const handleCoachingStart = () => {
    navigate(
      `/safety/coaching/${coachingInfo?.endpointId}/guided?sessionId=${coachingInfo?.sessionId}`
    );
  };

  useEffect(() => {
    if (!coachingInfo) return;
    const getCoachingInfo = async () => {
      try {
        const [scoreCurrent, coachInfo, cameraInfo] = await Promise.all([
          getCameraScore(
            coachingInfo?.endpointId,
            subDays(new Date().getTime(), 7).getTime(),
            new Date().getTime()
          ),

          getCoachInfo(coachingInfo?.coachUserId),
          getCameraDetails(coachingInfo?.endpointId),
        ]);

        const updatedItem = {
          ...coachingInfo,
          scoreCurrent,
          camera: cameraInfo,
          coachInfo,
        };

        setCoachingDetails(updatedItem);
        return updatedItem;
      } catch (error) {
        console.error('Error fetching coaching info:', error);
      }
    };

    getCoachingInfo();
  }, [coachingInfo, getCameraDetails, getCoachInfo, getCameraScore]);

  useEffect(() => {
    let intervalId;

    const checkPlayerContainer = () => {
      const playerContainer = document.getElementsByClassName('player-container')[0];
      if (playerContainer && !isPlayerContainerAvailable) {
        setIsPlayerContainerAvailable(true);
        clearInterval(intervalId); // Stop checking once player-container is found

        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.target === playerContainer) {
              const newWidth = entry.contentRect.width;
              if (newWidth !== playerWidth) {
                setPlayerWidth(newWidth);
              }
            }
          }
        });

        resizeObserver.observe(playerContainer);
      }
    };

    intervalId = setInterval(checkPlayerContainer, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [playerWidth, isPlayerContainerAvailable]);

  useEffect(() => {
    setIsPlayerContainerAvailable(false);
  }, [eventId]);

  const CoachableEvents = () => {
    const snapshots = coachingDetails?.eventList?.map((i) => ({
      downloadUrl: i?.snapshots?.find(
        (y) => y?.source === selectDisplaySourceId(i?.triggerCategoryId)
      )?.downloadUrl,
      triggerName: i?.triggerName,
    }));

    return (
      <LightTooltip
        arrow
        //followCursor
        placement="bottom-start"
        title={<EventCarouselItem items={coachingDetails?.eventList} />}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          sx={{ '& .MuiAvatarGroup-root': { flexDirection: 'unset !important' } }}
        >
          <AvatarGroup sx={{ '& .MuiAvatar-root': { marginRight: '-22px !important' } }}>
            {snapshots?.slice(0, 4).map((item) => (
              <Avatar
                key={item?.downloadUrl}
                alt={item?.triggerName}
                src={item?.downloadUrl}
                sx={{ width: 39, height: 39 }}
              />
            ))}
          </AvatarGroup>
          <Typography variant="subtitle2" ml="27px">
            {coachingDetails
              ? formatNumberWithLeadingZero(coachingDetails?.eventList?.length || 0)
              : '...'}
          </Typography>
        </Box>
      </LightTooltip>
    );
  };

  const isInParkingMode = useMemo(
    () => isCameraInParkingMode(coachingDetails?.camera?.parkingModeStatus),
    [coachingDetails?.camera?.parkingModeStatus]
  );

  if (!sessionId) return null;

  return (
    <Box mx={2}>
      {!isGuided && (
        <Button
          variant="text"
          size="small"
          startIcon={<ArrowBack />}
          onClick={handleBackToScoring}
          sx={{ mt: 1, mb: 1, fontSize: '0.875rem', color: '#4177BF' }}
        >
          Back to Coaching Pending
        </Button>
      )}

      {loading ? null : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" pb={1}>
          <Grid container spacing={1} pt={1} direction="row" width={playerWidth || '100%'}>
            <Grid item xs={12} md={6} lg={2.5} container justifyContent="flex-start">
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2" fontSize="0.875rem">
                  Camera
                </Typography>
                <Box display="inline-flex" gap={1} alignItems="center">
                  <Box
                    sx={{
                      width: '52px',
                      height: '42px',
                      borderRadius: '50%',
                      backgroundColor: isInParkingMode
                        ? coachingDetails?.camera?.isOnline
                          ? 'rgb(203, 163, 5)'
                          : '#5e6a7f'
                        : coachingDetails?.camera?.isOnline
                          ? '#537bbc'
                          : '#f9faff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <BoxImage
                      src={
                        coachingDetails?.camera?.isOnline
                          ? CAMERA_COMMON_ICON
                          : CAMERA_COMMON_ICON_OFF
                      }
                      height="22px"
                    />
                  </Box>
                  <EllipsisTextViewContainer>
                    <Typography variant="subtitle1">
                      {coachingDetails?.camera?.label || 'Smarter AI Dashcam'}
                    </Typography>
                  </EllipsisTextViewContainer>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={2.5} container>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2" fontSize="0.875rem">
                  Coach
                </Typography>
                <Typography variant="subtitle1">
                  <Box display="inline-flex" gap={1} alignItems="center">
                    <Avatar
                      alt={coachingDetails?.coachInfo?.name}
                      src={coachingDetails?.coachInfo?.pictureURL}
                      sx={{ width: 40, height: 40 }}
                    />
                    <EllipsisTextViewContainer>
                      <Typography variant="subtitle1">
                        {coachingDetails?.coachInfo?.name || '...'}
                      </Typography>
                    </EllipsisTextViewContainer>
                  </Box>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={2.5} container>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2" fontSize="0.875rem">
                  Coachable Events
                </Typography>
                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                  <CoachableEvents />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={2.5} container>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2" fontSize="0.875rem">
                  Previous Score
                </Typography>
                <Typography variant="subtitle1">
                  <Box width="35px" height="35px" position="relative">
                    <ScoreMiniWidget value={coachingDetails?.scoreCurrent} />
                    <Typography
                      position="absolute"
                      left={5}
                      right={5}
                      top={1}
                      bottom={0}
                      textAlign={'center'}
                      borderRadius="50%"
                      lineHeight={coachingDetails?.scoreCurrent ? '35px' : '25px'}
                      fontWeight={600}
                      fontSize={Math.round(coachingDetails?.scoreCurrent) > 99 ? '10px' : '12px'}
                    >
                      {Math.round(coachingDetails?.scoreCurrent) || '...'}
                    </Typography>
                  </Box>
                </Typography>
              </Box>
            </Grid>

            {!isGuided && (
              <Grid
                item
                xs={12}
                md={6}
                lg={2}
                container
                alignItems={'center'}
                justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleCoachingStart()}
                  sx={{ maxHeight: '40px', fontSize: { xs: '0.78rem' }, lineHeight: 'normal' }}
                >
                  Start Coaching Session
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
