import api from '@/api';
import { WHITE_BOX_EDIT_ICON } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { ConfirmationDialog } from '@/web/@components/ConfirmationDialog';
import { CustomInput } from '@/web/@components/CustomForm';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const defaultValues = {
  tlsIp: '',
  tlsPort: '',
  udpIp: '',
  udpPort: '',
  tcpIp: '',
  tcpPort: '',
};

export function CameraConfigurationForm(props) {
  const { configurations } = props;

  const { cameraId, refreshCamera } = useContext(CameraDetailsContext);
  const secretToken = useSelector(selectSecretToken);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isStatic, setIsStatic] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  const { setValue, handleSubmit } = methods;

  useEffect(() => {
    if (!configurations) return;
    const {
      stunTlsHost,
      stunTlsPort,
      turnUdpHost,
      turnUdpPort,
      turnTcpHost,
      turnTcpPort,
      isStatic,
    } = configurations;
    setValue('tlsIp', stunTlsHost);
    setValue('tlsPort', stunTlsPort);
    setValue('udpIp', turnUdpHost);
    setValue('udpPort', turnUdpPort);
    setValue('tcpIp', turnTcpHost);
    setValue('tcpPort', turnTcpPort);
    setIsStatic(isStatic);
    setLoading(false);
  }, [configurations, setValue]);

  /** @param {typeof defaultValues} data */
  const handleUpdate = async (data) => {
    setIsUpdating(true);
    const { tcpIp, tcpPort, tlsIp, tlsPort, udpIp, udpPort } = data;

    const request = api.ac.endpoint.serverconfig.$put({
      params: {
        endpointID: cameraId,
        secretToken,
        tcpIp,
        tcpPort: Number(tcpPort),
        tlsIp,
        tlsPort: Number(tlsPort),
        udpIp,
        udpPort: Number(udpPort),
      },
    });

    request
      .process()
      .then((result) => {
        toastSuccess('Configuration updated successfully!');
        refreshCamera();
      })
      .catch((ex) => {
        toastWarning('Could not update configuration.');
      })
      .finally(() => {
        setIsEditing(false);
        setIsUpdating(false);
      });
  };

  /** @param {typeof defaultValues} data */
  const handleCreate = async (data) => {
    setIsUpdating(true);
    const { tcpIp, tcpPort, tlsIp, tlsPort, udpIp, udpPort } = data;

    const request = api.ac.endpoint.serverconfig.$post({
      params: {
        endpointID: cameraId,
        secretToken,
        tcpIp,
        tcpPort: Number(tcpPort),
        tlsIp,
        tlsPort: Number(tlsPort),
        udpIp,
        udpPort: Number(udpPort),
      },
    });

    request
      .process()
      .then((result) => {
        refreshCamera();
        toastSuccess('Configuration created successfully!');
      })
      .catch((ex) => {
        toastWarning('Could not create configuration.');
      })
      .finally(() => {
        setIsEditing(false);
        setIsUpdating(false);
      });
  };

  const handleConfigurationDelete = () => {
    const request = api.ac.endpoint.serverconfig.$delete({
      params: {
        endpointID: cameraId,
        secretToken,
      },
    });
    request
      .process()
      .then((result) => {
        refreshCamera();
        toastSuccess('Configuration reset successfully!');
      })
      .catch((ex) => {
        toastWarning('Could not reset configuration.');
      })
      .finally(() => {
        setShowDeleteModal(false);
      });
  };

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    data.tlsIp = trimString(data.tlsIp);
    data.tlsPort = trimString(data.tlsPort);
    data.udpIp = trimString(data.udpIp);
    data.udpPort = trimString(data.udpPort);
    data.tcpIp = trimString(data.tcpIp);
    data.tcpPort = trimString(data.tcpPort);
    if (isStatic) {
      handleUpdate(data);
    } else {
      handleCreate(data);
    }
  };

  if (loading)
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );

  return (
    <Box>
      <ConfirmationDialog
        open={showDeleteModal}
        title="Server Configuration"
        message="Are you sure you want to delete this device server configuration?"
        onCancel={() => setShowDeleteModal(false)}
        onApply={handleConfigurationDelete}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
          <Box display={'flex'} justifyContent={'flex-end'} columnGap={'5px'}>
            {isStatic ? (
              <Button variant="outlined" size="small" onClick={() => setShowDeleteModal(true)}>
                {t(T['button.reset'])}
              </Button>
            ) : null}
            {isEditing ? (
              <>
                <Button variant="outlined" size="small" onClick={() => setIsEditing(false)}>
                  {t(T['button.cancel'])}
                </Button>
                <Button disabled={isUpdating} variant="contained" type="submit" size="small">
                  {isUpdating && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
                  {isEditing ? t(T['button.update']) : t(T['button.save'])}
                </Button>
              </>
            ) : (
              <Button
                size="small"
                variant="contained"
                startIcon={<img src={WHITE_BOX_EDIT_ICON} alt="Edit" />}
                onClick={() => setIsEditing(true)}
              >
                {t(T['button.edit'])}
              </Button>
            )}
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <CustomInput name="tlsIp" label="STUN TLS Host" disabled={!isEditing} />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="tlsPort" label="TURN UDP Host" disabled={!isEditing} />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="tcpIp" label="TURN TCP Host" disabled={!isEditing} />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="tcpPort" label="STUN TLS Port" disabled={!isEditing} />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="udpIp" label="TURN UDP Port" disabled={!isEditing} />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput name="udpPort" label="TURN TCP Port" disabled={!isEditing} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
}
