import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { CameraThumbnailProvider } from '@/web/cameras/@components/CameraThumbnailProvider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, CircularProgress, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { debounce } from 'lodash';
import qs from 'qs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CoachingFilterContext } from '../../@context/CoachingFilterContext';
import { EndpointGridItem } from '../EndpointGridItem';
import SelfCoachingModal from '../SelfCoachingModal';

export function EndpointCoachingGridList() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();

  const { startTime, endTime } = useContext(FilterContext);
  const { endpoints: cameras, deviceName, deviceSerialNumber } = useContext(CoachingFilterContext);

  /** @type {StateVariable<Array<EndpointScoreWithEndpointDetailDto>>} */
  const [endpointScores, setEndpointScores] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<any>} */
  const [anchorEl, setAnchorEl] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [showSelfModal, setShowSelfModal] = useState(null);
  /** @type {StateVariable<EndpointInfoAggregated>} */
  const [selectedEndpoint, setSelectedEndpoint] = useState(null);
  /** @type {StateVariable<Array<EndpointInfoAggregated>>} */
  const [updatedEndpoints, setUpdatedEndpoints] = useState(null);

  const endpointsWithScores = useMemo(() => {
    return updatedEndpoints?.map((endpoint, index) => {
      const endpointScore = endpointScores?.find(
        (item) => Number(item?.endpointId) === Number(endpoint?.endpointId)
      );
      return { ...endpoint, score: endpointScore?.score || 0 };
    });
  }, [updatedEndpoints, endpointScores]);

  const fetchEndpointsByDefaultPersona = useCallback(
    (signal) => {
      let offset = 0;
      const limit = 100;
      try {
        const request = api.ac.v5.score.persona
          .$personaId(1) //default persona
          .tenant?.$tenantId(tenantId)
          .endpoint.details.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
            params: {
              fromTimestamp: startOfTheDay(startTime),
              toTimestamp: endOfTheDay(endTime),
              limit,
              offset,
              sort: 'DESC',
            },
          });
        request
          .process()
          .then((data) => {
            setEndpointScores(data?.list || []);
            if (data?.list) {
              setTimeout(() => setLoading(false), 300);
            }
          })
          .catch((ex) => {
            setTimeout(() => setLoading(false), 300);
          });
      } catch (ex) {
        console.error(ex);
        setTimeout(() => setLoading(false), 300);
      }
    },
    [tenantId, secretToken, startTime, endTime]
  );

  // /** @type {import('react').MouseEventHandler} */
  const handleClick = (e, endpoint) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setSelectedEndpoint(endpoint);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelfClick = (endpointId) => {
    handleClose();
    setShowSelfModal(endpointId);
  };

  const filterData = (dataArray, deviceName = null, deviceSerialNumber = null) => {
    return dataArray?.filter((item) => {
      const deviceLabel = item?.deviceLabel?.toLowerCase();
      const deviceSerialNo = item?.deviceSerialNo?.toLowerCase();

      if (deviceName && deviceSerialNumber) {
        return (
          deviceLabel?.includes(deviceName?.toLowerCase()) &&
          deviceSerialNo?.includes(deviceSerialNumber?.toLowerCase())
        );
      } else if (deviceName) {
        return deviceLabel?.includes(deviceName?.toLowerCase());
      } else if (deviceSerialNumber) {
        return deviceSerialNo?.includes(deviceSerialNumber?.toLowerCase());
      }
      return true;
    });
  };

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);
    const debouncedFetchEndpoints = debounce(() => {
      fetchEndpointsByDefaultPersona(aborter?.signal);
    }, 500);
    debouncedFetchEndpoints();
    return () => {
      setLoading(true);
      aborter?.abort();
    };
  }, [fetchEndpointsByDefaultPersona]);

  useEffect(() => {
    const filteredDataByNameAndSerial = filterData(cameras, deviceName, deviceSerialNumber);
    setUpdatedEndpoints(filteredDataByNameAndSerial);
  }, [cameras, deviceName, deviceSerialNumber]);

  return (
    <Box mt={2} mb={10}>
      {showSelfModal ? (
        <SelfCoachingModal
          onCancelSelfModal={() => setShowSelfModal(null)}
          endpointId={showSelfModal}
        />
      ) : null}

      <Grid container spacing={2}>
        {loading ? (
          <CenterBox fullView>
            <CircularProgress />
          </CenterBox>
        ) : updatedEndpoints?.length > 0 ? (
          <>
            <CameraThumbnailProvider cameras={updatedEndpoints}>
              {endpointsWithScores
                ?.sort((a, b) => b?.score - a?.score)
                ?.map((endpoint, index) => {
                  return (
                    <Grid item xs={12} md={6} lg={3} key={index}>
                      <Box position="relative">
                        <EndpointGridItem endpoint={endpoint} score={endpoint?.score} />
                        <IconButton
                          size="small"
                          className="hover-item"
                          onClick={(e) => handleClick(e, endpoint)}
                          sx={{
                            'position': 'absolute',
                            'height': '25px',
                            'width': '25px',
                            'top': '5px',
                            'right': '5px',
                            'bgcolor': '#fff',
                            '&:hover': {
                              bgcolor: '#fff',
                            },
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Grid>
                  );
                })}
            </CameraThumbnailProvider>

            <Menu
              elevation={0}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                sx={{ fontSize: '0.875rem' }}
                onClick={() => {
                  const search = qs.stringify({
                    endpointId: selectedEndpoint.endpointId,
                    deviceLabel: `${selectedEndpoint.deviceLabel}`,
                  });
                  navigate(`/safety/coaching/coachable-events?${search}`);
                }}
              >
                Coachable Events
              </MenuItem>
              <MenuItem
                sx={{ fontSize: '0.875rem' }}
                onClick={() => {
                  navigate(`/safety/coaching/${selectedEndpoint.endpointId}/guided`);
                }}
              >
                Guided Coaching
              </MenuItem>
              <MenuItem
                sx={{ fontSize: '0.875rem' }}
                onClick={() => {
                  handleSelfClick(selectedEndpoint?.endpointId);
                }}
              >
                Self Coaching
              </MenuItem>
            </Menu>
          </>
        ) : (
          <CenterBox fullView>
            <IconMessageBox
              size="150px"
              src="/images/empty/no-vehicles.svg"
              message="No Cameras!"
            />
          </CenterBox>
        )}
      </Grid>
    </Box>
  );
}
