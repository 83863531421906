import { Box, Typography } from '@mui/material';
import { addDays, format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { GroupSettingsDateSelection } from '../Forms/GroupSettingsDateSelection';
import { GroupSettingsDateTimeSelection } from '../Forms/GroupSettingsDateTimeSelection';
import { GroupSettingsDaySelection } from '../Forms/GroupSettingsDaySelection';
import { GroupSettingsTimeSelection } from '../Forms/GroupSettingsTimeSelection';

/**
 * @typedef {object} DateTimePickerFormProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {DateTimePickerFormProps} props */
export function DateTimePickerForm(props) {
  const { item } = props;

  const nextDay = format(addDays(new Date(), 1), 'yyyy-MM-dd');
  const [date, setDate] = useState(`${nextDay}T19:30`);
  const [time, setTime] = useState('15:45');
  const [day, setDay] = useState('Sunday');
  const [dateInt, setDateInt] = useState(25);
  const [firsTimeLoad, setFirsTimeLoad] = useState(true);

  const { getValues, setValue, watch } = useFormContext();

  const initValues = useMemo(() => getValues(item.key), [item.key, getValues]);

  const dependentValue = item?.dependentKey ? watch(item?.dependentKey) : 'DAILY';

  const handleOnChange = useCallback(() => {
    let updateValue;
    switch (dependentValue) {
      case 'SCHEDULED':
        const splittedTimestamp = date ? date?.split('T') : [];
        const formattedDate =
          splittedTimestamp?.length > 1
            ? `${format(parseISO(splittedTimestamp[0]), 'dd/MM/yyyy')} ${splittedTimestamp[1]}`
            : '';

        updateValue = formattedDate;
        break;
      case 'NEXT_DAY':
      case 'DAILY':
        updateValue = time ? time : '';
        break;
      case 'NEXT_WEEK':
      case 'WEEKLY':
        updateValue = `${day || ''} ${time || ''}`;
        break;
      case 'MONTHLY':
        updateValue = `${dateInt || ''} ${time || ''}`;
        break;
      default:
        updateValue = '';
        break;
    }

    if (typeof updateValue !== 'undefined') {
      setValue(item.key, updateValue);
    }
  }, [dependentValue, date, time, day, dateInt, setValue, item.key]);

  const convertDateForInput = (date, time) => {
    const dateSplit = date?.split('/');
    return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${time}`;
  };

  const dateTimeModification = useCallback(() => {
    const selectedTime = initValues;
    const splittedTime = selectedTime?.split(' ');
    if (!splittedTime || splittedTime?.length === 0) return;
    switch (dependentValue) {
      case 'SCHEDULED':
        splittedTime[0] &&
          splittedTime[1] &&
          setDate(convertDateForInput(splittedTime[0], splittedTime[1]));
        break;
      case 'NEXT_DAY':
      case 'DAILY':
        selectedTime && setTime(selectedTime);
        break;
      case 'NEXT_WEEK':
      case 'WEEKLY':
        splittedTime[0] && setDay(splittedTime[0]);
        splittedTime[1] && setTime(splittedTime[1]);
        break;
      case 'MONTHLY':
        splittedTime[0] && setDateInt(splittedTime[0]);
        splittedTime[1] && setTime(splittedTime[1]);
        break;
      default:
        break;
    }
  }, [dependentValue, initValues]);

  useEffect(() => {
    if (!firsTimeLoad) {
      handleOnChange();
    }
  }, [dependentValue, firsTimeLoad, handleOnChange]);

  useEffect(() => {
    if (firsTimeLoad && typeof dependentValue !== 'undefined') {
      dateTimeModification();
      setFirsTimeLoad(false);
    }
  }, [item.dependentKey, firsTimeLoad, dateTimeModification, setFirsTimeLoad, dependentValue]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        flexWrap: 'nowrap',
      }}
    >
      <Typography color="#0B2547" fontSize="14px" width="70%">
        {item.label}
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'baseline' }}>
        {dependentValue === 'SCHEDULED' ? (
          <GroupSettingsDateTimeSelection date={date} setDate={setDate} />
        ) : dependentValue === 'DAILY' ? (
          <GroupSettingsTimeSelection time={time} setTime={setTime} />
        ) : ['WEEKLY', 'NEXT_WEEK'].includes(dependentValue) ? (
          <Box display="flex" width="100%" gap="10px">
            <GroupSettingsDaySelection day={day} setDay={setDay} />
            <GroupSettingsTimeSelection time={time} setTime={setTime} />
          </Box>
        ) : dependentValue === 'MONTHLY' ? (
          <Box display="flex" width="100%" gap="10px">
            <GroupSettingsDateSelection dateInt={Number(dateInt)} setDateInt={setDateInt} />
            <GroupSettingsTimeSelection time={time} setTime={setTime} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
