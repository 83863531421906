import api from '@/api';
import { COACHING_ICON_ON } from '@/assets/constants/images';
import {
  selectCurrentTenantName,
  selectSecretToken,
  selectTenantId,
  selectUserEmail,
} from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { formatDateTime } from '@/utils/datetime';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Button, Card, CircularProgress, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CoachingAccessPermissionModal } from '../CoachingAccessPermissionModal';
import { T } from '@/assets/locales';
import { useTranslation } from 'react-i18next';

const featureName = 'COACHING';
const webhookUrl =
  'https://chat.googleapis.com/v1/spaces/AAAA-yjf3W4/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=LudLeUXSwz1NssPg2Ekx2CDMwWv448y6TrcSyAlcEvo';
const imageURL = 'https://dev-dashboard.smarterai.com/images/layouts/sidebar/coaching-on-hook.png';

const featureRequestCache = new SmartCache('feature-access-request', 1 * 24 * 3600 * 1000);

export function CoachingLockLandingPage() {
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [isPending, setIsPending] = useState(true);

  const { t } = useTranslation();

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const userEmail = useSelector(selectUserEmail);
  const tenantName = useSelector(selectCurrentTenantName);

  const cacheKey = useMemo(() => `${tenantId}_${userEmail}`, [tenantId, userEmail]);

  const sendFormattedMessageToGoogleChat = async () => {
    const message = {
      cards: [
        {
          header: {
            title: 'Smarter AI',
            subtitle: 'Coaching Request',
            imageUrl: imageURL,
            imageStyle: 'AVATAR',
          },
          sections: [
            {
              widgets: [
                {
                  keyValue: {
                    topLabel: 'Request by',
                    content: userEmail,
                  },
                },
                {
                  keyValue: {
                    topLabel: 'Tenant Name',
                    content: tenantName,
                  },
                },
                {
                  keyValue: {
                    topLabel: 'Tenant Id',
                    content: tenantId,
                  },
                },
                {
                  keyValue: {
                    topLabel: 'Request Date',
                    content: formatDateTime(new Date()),
                  },
                },
              ],
            },
          ],
        },
      ],
    };

    try {
      await axios.post(webhookUrl, message);
      console.log('Formatted message sent successfully!');
    } catch (error) {
      console.error('Error sending formatted message:', error);
    }
  };

  const requestFeatureAccess = async () => {
    try {
      const request = api.ac.v5.tenant.access.features.request.$feature(featureName).$post({
        headers: {
          Authorization: secretToken,
        },
      });
      await request?.process();
      featureRequestCache.setItem(cacheKey, true);
      setIsPending(true);
    } catch (ex) {}
  };

  const handleGetInTouch = async () => {
    try {
      setLoading(true);
      await requestFeatureAccess();
      sendFormattedMessageToGoogleChat();
      setTimeout(() => {
        setLoading(false);
        setSuccessModal(true);
      }, 1000);
    } catch (ex) {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setSuccessModal(false);
  };

  useEffect(() => {
    const checkCacheData = async () => {
      const data = await featureRequestCache.getItem(cacheKey);
      setIsPending(data);
    };

    checkCacheData();
  }, [cacheKey]);

  return (
    <>
      {successModal && !loading && <CoachingAccessPermissionModal handleClose={handleModalClose} />}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          p: 2,
        }}
      >
        <Card
          variant="outlined"
          sx={{
            width: '800px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '34px',
            border: '1px solid #E0ECFB',
            p: 4,
          }}
        >
          <Box
            sx={{
              width: 120,
              height: 120,
              backgroundColor: '#F5F9FF',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BoxImage src={COACHING_ICON_ON} size={70} />
          </Box>

          <Typography fontWeight={500} py={2} fontSize={'1rem'} color="#596A82">
            Driver Coaching
          </Typography>

          <Typography fontWeight={400} pb={2} fontSize={'.80rem'} color="#0B2547">
            {t(T['safety.coaching.permission.text'])}
          </Typography>

          <Typography fontWeight={500} pb={4} fontSize={'.80rem'}>
            {t(T['safety.coaching.recommendation.text'])}
          </Typography>

          <Tooltip
            title={
              isPending && !loading
                ? 'We have received your request and will be reaching out to you soon.'
                : ''
            }
            arrow
            followCursor
          >
            <span>
              <Button
                variant="contained"
                size="small"
                onClick={handleGetInTouch}
                disabled={loading || isPending}
                startIcon={loading && <CircularProgress sx={{ color: 'white' }} size={12} />}
              >
                <Typography fontSize={'.75rem'}>Get in Touch</Typography>
              </Button>
            </span>
          </Tooltip>
        </Card>
      </Box>
    </>
  );
}
