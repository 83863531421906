import { DeviceGroupApnSettings } from '.';
import { ApnDetailsLayout } from './@components/ApnDetailsLayout';
import { CreateAPNSettingsPage } from './create';
import { UpdateAPNSettingsPage } from './_apnInfoId/edit';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    breadcrumb: 'APN Settings',
    element: <DeviceGroupApnSettings />,
  },
  {
    path: 'create',
    breadcrumb: 'Create',
    element: <CreateAPNSettingsPage />,
  },
  {
    path: ':apnInfoId',
    element: <ApnDetailsLayout />,
    children: [
      {
        path: 'edit',
        element: <UpdateAPNSettingsPage />,
        breadcrumb: 'Edit',
      },
    ],
  },
];

export default routes;
