import { SI_TO_IMPERIAL } from '@/assets/constants/unit-system';
import { isArray, isObject } from 'lodash';
import { AI_LABEL_DATA } from '../ai-labels';

/**
 * @param {TelematicsData | AccelerometerData} [data]
 * @returns {Array<TelematicsTemplate>}
 */
export function extractAccelerometerItems(data) {
  if (!data) return [];
  const labels = {
    X: AI_LABEL_DATA['ACCELERATION_X'],
    Y: AI_LABEL_DATA['ACCELERATION_Y'],
    Z: AI_LABEL_DATA['ACCELERATION_Z'],
  };

  const items = [];
  for (const [key, label] of Object.entries(labels)) {
    if (!label) continue;
    /** @type {TelematicsTemplate} */
    const t = {
      type: 'number',
      source: 'accelerometer',
      ts: data['ts'],
      name: label.name,
      value: data[key],
      min: label.min || -9.8,
      max: label.max || 9.8,
      unit: label.unit || 'm/s²',
    };
    normalizeItem(t);
    items.push(t);
  }
  return items;
}

/**
 * @param {TelematicsData | GeoLocationData} [data]
 * @returns {Array<TelematicsTemplate>}
 */
export function extractGpsItems(data) {
  if (!data) return [];
  const labels = {
    speed: AI_LABEL_DATA['LOCATION_SPEED'],
  };

  const items = [];
  for (const [key, label] of Object.entries(labels)) {
    if (!label) continue;
    /** @type {TelematicsTemplate} */
    const t = {
      type: 'number',
      source: 'gps',
      ts: data['ts'],
      value: data[key],
      name: label.name,
      min: label.min || 0,
      max: label.max || 200,
      unit: label.unit || 'km/h',
    };
    if (t.unit === 'km/h') {
      t.value = 3.6 * t.value; // converted from m/s to km/h
    }
    normalizeItem(t);
    items.push(t);
  }
  return items;
}

/**
 * @param {TelematicsData | GyroscopeData} [data]
 * @returns {Array<TelematicsTemplate>}
 */
export function extractGyroscopeItems(data) {
  if (!data) return [];
  const labels = {
    X: AI_LABEL_DATA['GYROSCOPE_X'],
    Y: AI_LABEL_DATA['GYROSCOPE_Y'],
    Z: AI_LABEL_DATA['GYROSCOPE_Z'],
  };

  const items = [];
  for (const [key, label] of Object.entries(labels)) {
    if (!label) continue;
    const value = (Number(data[key]) * 180) / Math.PI;
    /** @type {TelematicsTemplate} */
    const tx = {
      type: 'number',
      source: 'gyrometer',
      name: label.name,
      min: label.min || -180,
      max: label.max || 180,
      unit: label.unit || '°',
      value: value || 0,
      ts: data['ts'],
    };
    normalizeItem(tx);
    items.push(tx);
  }
  return items;
}

/**
 * @param {TelematicsData} [data]
 * @returns {Array<TelematicsTemplate>}
 */
export function extractCanbusItems(data) {
  if (!data) return [];
  const label = AI_LABEL_DATA[data.name || data.code];
  if (!label) return [];
  /** @type {TelematicsTemplate['type']} */
  let type = 'text';
  let value = data.value;
  if (!Number.isNaN(Number(value))) {
    type = 'number';
    value = Number(value);
  }
  /** @type {TelematicsTemplate} */
  const t = {
    source: 'canbus',
    type,
    value,
    ts: data.ts,
    name: label.name,
    min: label.min,
    max: label.max,
    unit: label.unit,
  };
  normalizeItem(t);
  return [t];
}

/**
 * @param {{[key: string]: Array<VideoMetaData>}} bboxData
 * @returns {Array<TelematicsTemplate>}
 */
export function formatBoundingBoxData(bboxData) {
  const items = [];
  if (!isObject(bboxData)) return [];
  for (const bboxes of Object.values(bboxData)) {
    if (!isArray(bboxes)) continue;
    for (const bbox of bboxes) {
      if (!isObject(bbox)) continue;
      const { ts } = bbox;
      const detections = bbox.figures?.flatMap((x) => x.detections || []) || bbox.detections || [];
      for (const detection of detections) {
        if (!isArray(detection)) continue;
        const [name, value] = detection;
        const label = AI_LABEL_DATA[name];
        if (!label) continue;
        /** @type {TelematicsTemplate} */
        const t = {
          type: 'number',
          source: 'bbox',
          ts,
          name,
          value,
          min: label.min,
          max: label.max,
          unit: label.unit,
        };
        normalizeItem(t);
        items.push(t);
      }
    }
  }
  return items;
}

const tens = [1, 10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000];

/**
 * @param {number} number
 * @param {number} fractionDigits
 * @returns {string}
 */
export function collapseDecimal(number, fractionDigits) {
  const ten = tens[fractionDigits] || Math.pow(10, fractionDigits);
  number = (Number(number) || 0) * ten;
  number = Math.round(number) / ten;
  return number.toFixed(fractionDigits);
}

/**
 * @param {TelematicsTemplate} item
 * @param {number} fractionDigits
 * @param {boolean} [imperial]
 * @returns {string}
 */
export function makeDisplayText(item, fractionDigits, imperial) {
  const data = AI_LABEL_DATA[item.name];
  if (data) {
    const x = Math.min(1, Number(data.inputStepSize) || 0);
    fractionDigits = Math.ceil(Math.abs(Math.log10(x)));
  }

  let unit = data?.unit || item?.unit || '';
  const unitData = imperial && SI_TO_IMPERIAL[unit];
  unit = unitData?.to || unit;

  if (item.dummy) {
    return '-- ' + unit;
  }

  switch (item.type) {
    case 'number': {
      const value = item.value * (unitData?.factor || 1);
      let text = collapseDecimal(value, fractionDigits);
      if (unit.length > 2) text += ' ';
      text += unit;
      return text;
    }
    case 'text':
      return ((item.value || '') + '').trim() || '-';
    default:
      return '-';
  }
}

/**
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function normalizeValue(value, min, max) {
  if (!value) return 0;
  if (value < min) value = min;
  if (value > max) value = max;
  return (value - min) / (max - min);
}

/**
 * @param {TelematicsTemplate} item
 * @param {number} [min]
 * @param {number} [max]
 * @returns {void}
 */
export function normalizeItem(item, min, max) {
  if (item.type !== 'number') return;
  if (typeof item.value !== 'number') {
    const value = Number(item.value);
    if (Number.isNaN(value)) {
      item.type = 'text';
      return;
    }
    item.value = value;
  }
  if (!item.original) {
    item.original = {
      min: item.min,
      max: item.max,
    };
  }
  if (item.unit === 'degrees') {
    item.unit = '°';
  }
  item.min = min ?? item.min;
  item.max = max ?? item.max;
  item.normalized = normalizeValue(item.value, item.min, item.max);
}
