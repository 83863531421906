import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const visionSettings = [
  {
    title: 'Vision',
    key: GROUP_SETTINGS_CATEGORIES.VISION,
    children: [
      {
        label: 'Metadata Reporting Threshold',
        key: 'metadataThreshold',
        type: FROM_TYPES.INPUT,
        min: -10000,
        max: 10000,
        inputType: 'number',
      },
      {
        label: 'AI Container Update Check Interval',
        key: 'aicInterval',
        type: FROM_TYPES.INPUT,
        postText: 'min',
        min: 0,
        max: 120,
        inputType: 'number',
      },
      {
        label: 'Model Validation',
        key: 'enableModelValidationSection',
        children: [
          {
            label: 'Model Validation',
            key: 'enableModelValidation',
            type: FROM_TYPES.SWITCH,
          },
          {},
        ],
      },
      {
        label: 'Event Post Processing',
        key: 'EventPostProcessing',
        children: [
          {
            label: 'EventPostProcessing.FPS',
            key: 'PostProcessingFrameRate',
            type: FROM_TYPES.SELECT,
            options: [
              { label: '15', value: 15 },
              { label: '7.5', value: 7.5 },
              { label: '5', value: 5 },
            ],
          },
          {
            label: 'EventPostProcessing.ON',
            key: 'EnablePostProcessing',
            type: FROM_TYPES.SWITCH,
          },
        ],
      },
    ],
  },
];
