import { Box, FormControl, FormLabel, Slider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} SpeedingCustomSlider
 * @property {any} name
 * @property {string} label
 * @property {boolean} [isDisabled]
 * @property {number} [step]
 * @property {number} [defaultVal]
 * @property {string} [postText]
 * @property {number} [min]
 * @property {number} [max]
 * @property {number} [height]
 * @property {number} [defaultSensitivityValue]
 * @property {any} [sliderKey]
 * @property {import('@mui/material').SliderProps} [SliderProps]
 * @property {import('react-hook-form').ControllerProps['rules']} [rules]
 */

/**
 * @param {SpeedingCustomSlider} props
 */
export function SpeedingCustomSlider(props) {
  const {
    name,
    label,
    isDisabled = false,
    step = 1,
    defaultVal = 0,
    postText = '',
    rules,
    min = 0,
    max = 100,
    height = 4,
    sliderKey,
    defaultSensitivityValue,
    SliderProps,
  } = props;

  const { control, watch } = useFormContext();

  const sliderValue = watch(name);

  // Generate linear gradient string based on the given percentage ranges
  const generateLinearGradient = () => {
    // Calculate percentages based on the full range (min to max)
    const fullRangePercentage = ((max - min) / (max - min)) * 100;

    // Calculate percentages for default and current positions
    const defaultPositionPercentage = ((defaultSensitivityValue - min) / (max - min)) * 100;
    const currentPositionPercentage = ((sliderValue - min) / (max - min)) * 100;

    const endPercentage =
      currentPositionPercentage < 100
        ? 100
        : Math.max(defaultPositionPercentage, currentPositionPercentage);

    const percentage = (defaultSensitivityValue / sliderValue) * 100;

    // Generate the linear gradient string
    return `linear-gradient(to right, 
            #608AC3 0%, 
            #608AC3 ${percentage}%, 
            #586b81 ${percentage}%, 
            #586b81 ${endPercentage}%,
            #608AC3 ${endPercentage}%,
            #608AC3 ${fullRangePercentage}%)`;
  };

  return (
    <FormControl variant="standard" fullWidth>
      <Box display="flex" alignItems="center" gap="4px">
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Slider
              key={sliderKey + defaultVal}
              sx={{
                'color': '#608AC3',
                'height': height,
                'padding': '15px 0',
                '& .MuiSlider-thumb': {
                  backgroundColor: '#fff',
                  border: '4px solid #608AC3',
                },
                '& .MuiSlider-track': {
                  borderRadius: 2,
                  height: 4,
                  background: generateLinearGradient(),
                },
                '& .MuiSlider-valueLabel': {
                  fontSize: 12,
                  fontWeight: 'normal',
                  background: '#596A82',
                  color: 'white',
                },
                '& .MuiSlider-markLabel': {
                  fontSize: '0.75rem',
                },
                ...(SliderProps?.marks && {
                  '& .MuiSlider-markLabel[data-index="0"]': {
                    transform: 'translateX(0) !important',
                  },
                  // @ts-ignore
                  [`& .MuiSlider-markLabel[data-index="${SliderProps?.marks?.length - 1}"]`]: {
                    transform: 'translateX(-100%) !important',
                  },
                }),
              }}
              disabled={Boolean(isDisabled) || false}
              defaultValue={Number(defaultVal)}
              aria-label="Slider"
              marks={false}
              valueLabelDisplay="auto"
              valueLabelFormat={(x) => (postText ? `${x} ${postText}` : '')}
              step={Number(step) || 1}
              min={Number(min) || 0}
              max={Number(max) || 100}
              onChange={(event, value) => {
                field.onChange(name, value);
              }}
              {...SliderProps}
            />
          )}
        />
        <FormLabel sx={{ fontWeight: 500, fontSize: '0.875rem' }}>{label}</FormLabel>
      </Box>
    </FormControl>
  );
}
