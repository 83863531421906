import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { DialogTable } from '@/web/@components/DialogTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import { POOL_DEPLOYABLE_FIRMWARE_TABLE_COLUMNS } from './columns';
/**
 * @typedef {object} PoolFirmwareDeployDialogProps
 * @property {boolean} [open]
 * @property {() => any} [onCancel]
 * @property {(value: FirmwareV2) => any} onSubmit
 */

/** @param {PoolFirmwareDeployDialogProps} props */
export function PoolFirmwareDeployDialog(props) {
  const { onCancel, onSubmit } = props;

  /** @type {StateVariable<Array<FirmwareV2>>} */
  const [selectedFirmwareItems, setSelectedFirmwareItems] = useState([]);

  const {
    result = [],
    loading: deviceLoading,
    error,
  } = useFutureLoader(async ({ signal, tenantId, secretToken }) => {
    let limit = 200;
    let token = '';
    /** @type {Array<FirmwareV2>} */
    const results = [];
    while (!signal.aborted) {
      const request = api.ac.v2.repository.firmware.assign.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          secretToken,
          tenantId,
          pageSize: limit,
          continuationToken: token,
        },
      });
      await request.process();
      const firmwares = request.result.data;
      results.push(...firmwares);
      token = request.result.continuationToken;
      if (!token || firmwares.length < limit) break;
    }
    return results;
  }, []);

  const handleDeployFirmware = () => {
    onSubmit && onSubmit(selectedFirmwareItems[0]);
  };

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      maxWidth={'lg'}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deploy Firmware</DialogTitle>
      <Divider />
      <DialogContent sx={{ height: '80vh' }}>
        <DialogTable
          title="Firmwares"
          loading={deviceLoading}
          error={error}
          results={result}
          columns={POOL_DEPLOYABLE_FIRMWARE_TABLE_COLUMNS}
          onSelectItem={setSelectedFirmwareItems}
          disableMultiSelect
          dataSortKey="name"
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          onClick={handleDeployFirmware}
          autoFocus
          variant="contained"
          disabled={!selectedFirmwareItems?.length}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
