import { formatTimestamp } from '@/utils/datetime';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';
import { Chip } from '@mui/material';

/** @type {Array<TableColumn<ZeroTouchDeviceModel>>} */
export const CAMERA_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'id',
    label: 'Device Serial Number',
    align: 'left',
    isPrimary: true,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'left',
    fallback: 'N/A',
    format: (value, row) => formatTimestamp(value),
  },
  {
    id: 'onboarded',
    label: 'Onboarded',
    align: 'left',
    fallback: 'Not Onboarded',
    format: (value, row) => {
      return <Chip size="small" label={value ? 'Onboarded' : 'Not Onboarded'} />;
    },
  },
];
