import api from '@/api';
import { T } from '@/assets/locales';
import { EMAIL_VALIDATION_REGEX } from '@/assets/regex';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectTenantId, selectUserId, selectUserRole } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { sortBy, startCase } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { USER_LEVELS } from '@/utils/user-access';

/**
 * @typedef {object} UserRole
 * @property {string} name
 * @property {number} id
 */

/**
 * @typedef {object} UserFormInputFields
 * @property {string} tenant
 * @property {string} email
 * @property {string} name
 * @property {string} pictureUrl
 * @property {UserRole} role
 */

/**
 * @typedef {object} UserFormProps
 * @property {boolean} loading
 * @property {UserWithAccountDetails} [user]
 * @property {(data: UserFormInputFields) => any} onSubmitData
 */

/**
 * @param {UserFormProps} props
 */
export function UserForm(props) {
  const { onSubmitData, loading: isLoading, user } = props;
  const tenantId = useSelector(selectTenantId);
  const loggedUserId = useSelector(selectUserId);
  const loggedUserRole = useSelector(selectUserRole);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const isRoleDisabled = useMemo(() => {
    if (!user) return false;
    return loggedUserId === user?.userId || USER_LEVELS[user?.role] > USER_LEVELS[loggedUserRole];
  }, [loggedUserId, user, loggedUserRole]);

  /** @type {import('@/hooks/useFutureLoader').FutureLoaderResponse<UserRole[]>} */
  const { result: roles, loading: rolesLoading } = useFutureLoader(
    async ({ tenantId, secretToken }) => {
      const request = api.ac.v5['role-manager'].tenant.$tenantId(tenantId).roles.$get({
        headers: {
          Authorization: secretToken,
        },
      });
      /** @type {UserRole[]} */ // @ts-ignore TODO: fix types
      const roles = await request.process();
      return sortBy(roles, 'name');
    },
    [],
    { cache: true }
  );

  const rolesOptions = useMemo(
    () => roles?.filter((item) => USER_LEVELS[item?.name] <= USER_LEVELS[loggedUserRole]),
    [roles, loggedUserRole]
  );

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      name: '',
      email: '',
      role: null,
      pictureUrl: '',
      tenant: tenantId,
    },
  });

  useEffect(() => {
    if (!user) return;
    form.setValue('name', user.name);
    form.setValue('email', user.email);
  }, [user, form]);

  useEffect(() => {
    if (!user || !roles) return;
    form.setValue(
      'role',
      roles.find((x) => x.name === user.role)
    );
  }, [user, form, roles]);

  const handleCancel = () => {
    navigate('/administration/users');
  };

  /** @type {import('react-hook-form').SubmitHandler<UserFormInputFields>} */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.name = trimString(data.name);
    data.email = trimString(data.email);
    data.pictureUrl = trimString(data.pictureUrl);
    onSubmitData(data);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(prepareAndSubmit)}
        autoComplete="off"
        noValidate
        style={{ marginTop: '10px' }}
      >
        <Grid container spacing={2} mb="20px">
          <Grid item xs={12} md={6}>
            <CustomInput
              name="name"
              type="input"
              label={t(T['user.form.name.label'])}
              placeholder={t(T['user.form.name.placeholder'])}
              rules={{
                required: t(T['user.form.name.required']),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="email"
              type="email"
              label={t(T['user.form.email.label'])}
              placeholder={t(T['user.form.email.placeholder'])}
              rules={{
                required: t(T['user.form.email.required']),
                pattern: {
                  value: EMAIL_VALIDATION_REGEX,
                  message: t(T['user.form.email.invalid']),
                },
              }}
              InputProps={{ autoComplete: 'email' }}
              TextFieldProps={{
                disabled: !!user,
              }}
            />
          </Grid>
          {loggedUserRole !== 'USER' && (
            <Grid item xs={12} md={6}>
              <CustomSelect
                name="role"
                options={rolesOptions || []}
                loading={rolesLoading}
                label={t(T['user.form.role.label'])}
                placeholder={t(T['user.form.role.placeholder'])}
                getLabel={(item) => startCase(item.name.toLowerCase())}
                rules={{ required: t(T['user.form.role.required']) }}
                disabled={isRoleDisabled}
              />
            </Grid>
          )}
        </Grid>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'}>
          <Button variant="text" onClick={handleCancel}>
            {t(T['button.cancel'])}
          </Button>
          <Button disabled={isLoading} variant="contained" type="submit">
            {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {user ? t(T['button.update']) : t(T['button.save'])}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
