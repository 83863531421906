import { DRIVER_DEFAULT_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box } from '@mui/material';

/** @param {import('@mui/material').BoxProps} props */
export function DriverImageNotAvailable(props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bgcolor="#F5F5F5"
      gap="5px"
      {...props}
    >
      <BoxImage
        loading="eager"
        display="block"
        src={DRIVER_DEFAULT_ICON}
        width="60%"
        height="60%"
        maxWidth="80px"
        maxHeight="80px"
      />
      <Box
        color="#D3D3D3"
        fontWeight="600"
        whiteSpace="nowrap"
        fontSize={{ xs: '0.5rem', sm: '0.75rem' }}
      >
        <Box>Driver Photo</Box>
        <Box>Not Available</Box>
      </Box>
    </Box>
  );
}
