import React from 'react';
import { TRIGGER_EDIT_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Button } from '@mui/material';

export function EditButton(props) {
  const { onEdit, isVisible } = props;

  return (
    <Button size="small" onClick={onEdit}>
      <Box
        sx={{ display: isVisible ? 'grid' : 'none' }}
        display="flex"
        flexDirection="column"
        justifyItems="center"
        gap={0.4}
        fontSize={{ sx: '1rem', md: '0.875rem' }}
        alignItems="center"
      >
        <BoxImage src={TRIGGER_EDIT_ICON} size="16px" />
        Edit
      </Box>
    </Button>
  );
}
