import { CircularProgress, List } from '@mui/material';
import { Fragment } from 'react';
import { CenterBox } from '../CenterBox';
import { IconMessageBox } from '../IconMessageBox';
import { InfiniteScrollLoadNext } from './InfiniteScrollLoadNext';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';

/**
 * @template T
 * @param {InfiniteScrollListViewProps<T> & import('@mui/material').ListProps} props
 */
export function InfiniteScrollListView(props) {
  const {
    state,
    itemKey,
    renderItem,
    renderFailure = (error, retry) => (
      <IconMessageBox
        size="128px"
        src={NO_SEARCH_RESULT_IMG}
        message="Sorry! Could not fetch data"
        onRetry={retry}
      />
    ),
    ...listProps
  } = props;
  const { results, loading, error, finished, loadNext } = state;

  return (
    <List {...listProps}>
      {results.map((data, index) => (
        <Fragment key={itemKey ? itemKey(data) : index}>
          {renderItem(data, index, results)}
        </Fragment>
      ))}
      {!finished && (
        <CenterBox>
          <InfiniteScrollLoadNext>
            {loading ? (
              <CircularProgress size="24px" />
            ) : error ? (
              renderFailure(error, loadNext)
            ) : null}
          </InfiniteScrollLoadNext>
        </CenterBox>
      )}
    </List>
  );
}
