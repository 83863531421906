import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { store } from '@/store';
import {
  selectTeamList,
  selectTenantId,
  selectUserAccessibleFeatures,
  selectUserEmail,
  selectUserRole,
} from '@/store/auth';
import { filterByUserAccess } from '@/utils/user-access';
import { PageCard } from '@/web/@components/PageCard';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CenterBox } from '../CenterBox';
import { IconMessageBox } from '../IconMessageBox';

/**
 * @typedef {object} CommonLandingPageProps
 * @property {Array<PageCardItem>} items
 */

/** @param {CommonLandingPageProps} props */
export function CommonLandingPage(props) {
  const { items } = props;
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);
  const tenantId = useSelector(selectTenantId);
  const teamList = useSelector(selectTeamList);
  const enableFeatures = useSelector(selectUserAccessibleFeatures);

  const visibleItems = useMemo(
    () =>
      items?.filter(
        filterByUserAccess({ userRole, userEmail, tenantId, teamList, enableFeatures })
      ),
    [items, userRole, userEmail, tenantId, teamList, enableFeatures]
  );

  const { result, loading, error } = useFutureLoader(
    async ({ signal, secretToken }) => {
      const availableItems = visibleItems.filter((entity) => entity.type);
      if (!availableItems.length) return [];
      const tenantId = selectTenantId(store.getState());
      const request = api.ac.v5.tenant.$tenantId(tenantId)['service-entity-count'].$post({
        signal,
        headers: {
          Authorization: secretToken,
        },
        data: {
          entityTypeList: availableItems.map((entity) => entity.type),
        },
      });
      await request.process();

      /** @type {{[key: string]: number}} */
      const result = {};
      for (const item of request.result || []) {
        result[item.entityType] = item.entityCount;
      }
      return result;
    },
    [visibleItems],
    { cache: true }
  );

  if (loading) {
    return (
      <Box height="80vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (result || error) {
    return (
      <Grid container spacing={2} p={2} mb={10}>
        {visibleItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3.9} xl={3.4} key={item.name}>
            <PageCard
              count={
                item.type
                  ? item.defaultCount || (!error ? result[item.type] : !item?.countHide ? 0 : null)
                  : null
              }
              item={item}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <CenterBox>
      <IconMessageBox src={NO_SEARCH_RESULT_IMG} size="256px" message="No items" />
    </CenterBox>
  );
}
