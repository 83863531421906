import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/device-groups/actions';
import { DEVICE_GROUP_TABLE_COLUMNS } from '@/web/administration/device-groups/columns';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function DeviceGroupListView() {
  const { t } = useTranslation();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @param {Array<DeviceGroupInfo>} selectedItems */
  const deleteDeviceGroups = async (selectedItems) => {
    const success = [];
    const failed = [];
    await Promise.allSettled(
      selectedItems.map(async (group) => {
        try {
          const request = api.ac.endpoint.group.delete.$delete({
            params: {
              groupId: group.id,
              secretToken,
            },
          });
          await request.process();
          success.push(group.groupName);
        } catch (e) {
          failed.push(group.groupName);
        }
      })
    );
    if (success?.length) {
      toastSuccess(
        'Success',
        t(T['device-group.delete.success'], {
          count: success.length,
          groupName: success.join(', '),
        })
      );
    }
    if (failed?.length) {
      toastWarning(
        'Failure',
        t(T['device-group.delete.retry'], {
          count: failed.length,
          groupName: failed.join(', '),
        })
      );
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<DeviceGroupInfo>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteDeviceGroups(selectedItems);
    }
    setTableReload(true);
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={t(T['device-group.title'])}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <OffsetPagination
          key={secretToken}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            const request = api.ac.v5.group.device.list.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit,
                offset,
                tenantId,
                ...(searchText !== '' && searchType && { [searchType]: searchText }),
              },
            });
            await request.process();
            return {
              data: request.result,
              count: Number(request.response.headers['x-total-count']),
            };
          }}
          renderList={(/** @type {Array<DeviceGroupInfo>} */ results) => (
            <ItemsResponsiveView results={results} columns={DEVICE_GROUP_TABLE_COLUMNS} />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
