import { FROM_TYPES } from '@/assets/schemes/constants';
import { deviceImagerStates } from '@/utils/schemes/imagerState';
import { CustomTooltip } from '@/web/@components/CustomTooltip';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { random } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { AiLabelSelection } from '../AiLabelSelection';
import { GroupSettingsInputForm } from '../Forms/GroupSettingsInputForm';
import { GroupSettingsMultiSelectForm } from '../Forms/GroupSettingsMultiSelectForm/index';
import { GroupSettingsSelectForm } from '../Forms/GroupSettingsSelectForm';
import { GroupSettingsSliderForm } from '../Forms/GroupSettingsSliderForm';
import { GroupSettingsSwitchForm } from '../Forms/GroupSettingsSwitchForm';
import { GeoSelection } from '../GeoSelection';
import { ModelValidation } from '../ModelValidation';
import OBD2Selection from '../OBD2Selection';
import { DateTimePickerForm } from '../TimeSelection';
import { WifiConnectivity } from '../WifiConnectivity';
import {
  imagerRecordingAudioSettings,
  imagerRecordingVideoSettings,
} from '@/utils/schemes/recodingPipelines';
import {
  imagerStreamingAudioSettings,
  imagerStreamingVideoSettings,
} from '@/utils/schemes/streamingPipelines';
/**
 * @typedef {object} GroupSettingsFormsProps
 * @property {Array<GroupSettingsChildItems>} settings
 * @property {ProductWithAccelerator} product
 */
/** @param {GroupSettingsFormsProps} props */
export function GroupSettingsForms(props) {
  const { settings, product } = props;
  const { watch } = useFormContext();

  const videoInputs = useMemo(
    () => product?.inputs.filter((item) => item.inputType === 'VIDEO'),
    [product?.inputs]
  );

  const audioInputs = useMemo(
    () => product?.inputs.filter((item) => item.inputType === 'AUDIO'),
    [product?.inputs]
  );

  const filteredSections = () => {
    return settings?.filter((children) => {
      // Imager Based Pipeline controls
      if (children?.key === 'recordingVideoQuality') {
        children['children'] = videoInputs?.map((item) => imagerRecordingVideoSettings(item));
      } else if (children?.key === 'recordingAudioQuality') {
        children['children'] = audioInputs?.map((item) => imagerRecordingAudioSettings(item));
      } else if (children?.key === 'streamingVideoQuality') {
        children['children'] = videoInputs?.map((item) => imagerStreamingVideoSettings(item));
      } else if (children?.key === 'streamingAudioQuality') {
        children['children'] = audioInputs?.map((item) => imagerStreamingAudioSettings(item));
      } else if (children?.key === 'deviceVideoImagerState') {
        children['children'] = videoInputs?.map((item) => deviceImagerStates(item));
      } else if (children?.key === 'deviceAudioImagerState') {
        children['children'] = audioInputs?.map((item) => deviceImagerStates(item));
      }
      //End of Imager Based Pipeline controls

      return typeof children?.isHidden === 'function' ? !children?.isHidden(watch) : true;
    });
  };

  const filteredData = filteredSections();

  return (
    <Grid
      container
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing="12px"
      width="100%"
      py="5px"
    >
      {(filteredData || [])?.map((item, index) =>
        item.children ? (
          <Box width="100%" key={item.key + index + random(false)}>
            <Box display="inline-flex" width="70%" alignItems="center">
              <Typography fontSize="16px" fontWeight="500" my={'10px'}>
                {item.label}
              </Typography>
              <CustomTooltip text={item?.infoText} />
            </Box>
            {item?.underline && <Divider sx={{ borderWidth: '1px', mb: '10px' }} />}
            {item?.secondaryUnderline && (
              <Divider
                sx={{ borderWidth: '1px', mb: '5px', border: '1px dashed #c5d9f0', opacity: 0.5 }}
              />
            )}
            <GroupSettingsForms settings={item.children} product={product} />
            {item.key === 'enableModelValidationSection' && <ModelValidation product={product} />}
          </Box>
        ) : (
          <Fragment key={item?.key || `test-${index}` + index}>
            <GroupSettingsItem item={item} index={index} />
          </Fragment>
        )
      )}
    </Grid>
  );
}

/**
 * @typedef {object} GroupSettingsItemProps
 * @property {GroupSettingsChildItems} item
 * @property {number} index
 */
/** @param {GroupSettingsItemProps} props */
export function GroupSettingsItem(props) {
  const { item } = props;

  const isParkingSensitivity = item.key === 'parkingBatteryVoltSensitivity';

  if (isParkingSensitivity) {
    return (
      <>
        <Box sx={{ width: '100%', bgcolor: '#F2F6FA' }} p="10px">
          <Grid item xs={11} md={5}>
            <GroupSettingsSliderForm item={item} />
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <>
      {item.type === FROM_TYPES.SWITCH ? (
        <Grid item xs={11} md={5}>
          <GroupSettingsSwitchForm item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.SLIDER ? (
        <Grid item xs={11} md={5}>
          <GroupSettingsSliderForm item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.INPUT || item.type === FROM_TYPES.NUMBER ? (
        <Grid item xs={11} md={5}>
          <GroupSettingsInputForm item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.SELECT ? (
        <Grid item xs={11} md={5}>
          <GroupSettingsSelectForm item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.OBD2 ? (
        <OBD2Selection item={item} />
      ) : item.type === FROM_TYPES.AI_MODEL_LEVELS ? (
        <Grid item xs={12}>
          <AiLabelSelection item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.DEVICE_WIFI_CONNECTIVITY ? (
        <Grid item xs={12}>
          <WifiConnectivity item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.DATE_TIME ? (
        <Grid item xs={11} md={5}>
          <DateTimePickerForm item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.GEO_FENCE ? (
        <Grid item xs={11} md={5}>
          <GeoSelection item={item} />
        </Grid>
      ) : item.type === FROM_TYPES.MULTISELECT ? (
        <Grid item xs={11} md={5}>
          <GroupSettingsMultiSelectForm item={item} />
        </Grid>
      ) : (
        <Grid item xs={11} md={5}></Grid>
      )}
    </>
  );
}
