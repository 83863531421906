import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { Box, CircularProgress } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from '../FilterContext';

/**
 * @template T
 * @typedef {object} ContinuationTokenTableProps<T>
 * @property {Array<T>} results
 * @property {Array<TableColumn<T>>} columns
 * @property {boolean} [loading]
 * @property {string} [dataSortKey]
 * @property {string} [noDataText]
 * @property {() => any} [onReload]
 * @property {string} [height]
 * @property {boolean} [fullView]
 * @property {boolean} [hideHeaderBorder]
 */

/**
 * @template T
 * @param {ContinuationTokenTableProps<T>} props
 */
export function ContinuationTokenTable(props) {
  const { t } = useTranslation();
  const {
    results,
    columns,
    loading,
    dataSortKey,
    noDataText,
    onReload,
    height,
    fullView,
    hideHeaderBorder,
  } = props;

  const { searchText } = useContext(FilterContext);
  const { setSelectedItems, reloadStatus, setTableReload, setFilteredDataCount } =
    useContext(PaginatedTableContext);

  const [filteredData, setFilteredData] = useState([]);
  const filterText = useMemo(() => searchText, [searchText]);

  useEffect(() => {
    setSelectedItems([]);
  }, [setSelectedItems, results]);

  useEffect(() => {
    const filter = (filterText || '').toLowerCase();
    const searchable = columns?.filter((x) => x.searchable).map((x) => x.id);
    if (!filter || !searchable?.length) {
      setFilteredData(results || []);
      return;
    }
    const filtered = (results || []).filter((item) => {
      for (const col of searchable) {
        const d = ((item[col] || '') + '').toLowerCase();
        if (d && d.includes(filter)) return true;
      }
      return false;
    });
    setFilteredData(filtered);
  }, [results, filterText, columns]);

  useEffect(() => {
    if (!reloadStatus) return;
    onReload && onReload();
    setTableReload(false);
  }, [reloadStatus, setTableReload, onReload]);

  useEffect(() => {
    setFilteredDataCount(filteredData?.length || 0);
  }, [filteredData, setFilteredDataCount]);

  return (
    <Box className="offset-pagination">
      {loading ? (
        <CenterBox fullView={!height || fullView} children={<CircularProgress />} />
      ) : !loading && !filteredData?.length ? (
        <CenterBox fullView={!height}>
          <IconMessageBox
            size="256px"
            src={NO_SEARCH_RESULT_IMG}
            message={noDataText || t(T['no.data.found'])}
          />
        </CenterBox>
      ) : (
        <ItemsResponsiveView
          columns={columns}
          results={filteredData}
          defaultSortKey={dataSortKey}
          hideHeaderBorder={hideHeaderBorder}
          sx={{ height: height ? height : 'calc(100vh - 170px)' }}
        />
      )}
    </Box>
  );
}
