import { CanceledError } from 'axios';
import FileSaver from 'file-saver';
import { ArrayBuffer as SparkArrayBuffer } from 'spark-md5';

/**
 * @param {Blob} blob
 */
export function blobAsDataUrl(blob) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.abort = () => reject(new Error('aborted'));
    reader.readAsDataURL(blob);
  });
}

/**
 * Strips the access codes from a blob storage url
 * @param {string} url
 */
export function stripAuthFromSAS(url) {
  if (!url?.includes('?')) {
    return url || '';
  }
  const [path, query] = url.split('?');
  const baddies = new Set(['sig', 'se', 'sv', 'sp', 'sr']);
  return (
    path +
    '?' +
    query
      .split('&')
      .filter((x) => !baddies.has(x.split('=')[0]))
      .join('&')
  );
}

/**
 * Calculate MD5 checksum of a file.
 * @param {File} file
 * @param {BufferEncoding} [_encoding]
 * @param {number} [_chunkSize] in bytes [Default: 5mb]
 * @returns {Promise<string>}
 */
export function calculateMd5sum(file, _encoding = 'base64', _chunkSize = 5 * 1024 * 1024) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const spark = new SparkArrayBuffer();

    reader.onabort = () => reject(reader.error || new CanceledError());
    reader.onerror = () => reject(reader.error || 'Failed to calculate MD5 checksum');

    let currentChunk = 0;
    const totalChunks = Math.ceil(file.size / _chunkSize);

    const loadNext = () => {
      const start = currentChunk * _chunkSize;
      const end = start + _chunkSize >= file.size ? file.size : start + _chunkSize;

      // Selectively read the file and only store part of it in memory.
      // This allows client-side applications to process huge files without the need for huge memory
      reader.readAsArrayBuffer(file.slice(start, end));
    };

    reader.onload = () => {
      let buffer = reader.result;
      if (typeof buffer === 'string') return; // must be ArrayBuffer

      spark.append(buffer);
      currentChunk++;
      if (currentChunk < totalChunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };

    loadNext();
  });
}

/**
 * Downloads JSON content from a file
 * @param {string} fileUrl
 * @param {AbortSignal} [signal]
 * @returns {Promise<any>}
 */
export async function downloadJsonFile(fileUrl, signal) {
  if (!fileUrl?.startsWith('http')) {
    throw Error('Invalid URL: ' + fileUrl);
  }
  const resp = await fetch(fileUrl, { signal, cache: 'force-cache' });
  if (resp.status >= 400) {
    throw new Error(resp.statusText);
  }
  return await resp.json();
}

/**
 * Downloads TEXT content from a file
 * @param {string} fileUrl
 * @param {AbortSignal} [signal]
 * @returns {Promise<string>}
 */
export async function downloadTextFile(fileUrl, signal) {
  if (!fileUrl?.startsWith('http')) {
    throw Error('Invalid URL: ' + fileUrl);
  }
  const resp = await fetch(fileUrl, { signal, cache: 'force-cache' });
  if (resp.status >= 400) {
    throw new Error(resp.statusText);
  }
  return await resp.text();
}

/**
 * Downloads IMAGE file and get a blob URL
 * @param {string} fileUrl
 * @param {AbortSignal} [signal]
 * @returns {Promise<string>}
 */
export async function downloadImageFile(fileUrl, signal) {
  if (!fileUrl || !fileUrl.startsWith('http')) {
    return fileUrl;
  }
  const resp = await fetch(fileUrl, { signal, cache: 'force-cache' });
  if (resp.status >= 400) {
    throw new Error(resp.statusText);
  }
  const data = await resp.arrayBuffer();
  const blob = new Blob([data], { type: 'image/png' });
  return await blobAsDataUrl(blob);
}

/**
 * Downloads a video file and get a blob URL
 * @param {string} fileUrl
 * @param {AbortSignal} [signal]
 * @returns {Promise<string>}
 */
export async function downloadVideoFile(fileUrl, signal) {
  if (!fileUrl || !fileUrl.startsWith('http')) {
    return fileUrl;
  }
  const resp = await fetch(fileUrl, { signal, cache: 'force-cache' });
  if (resp.status >= 400) {
    throw new Error(resp.statusText);
  }
  const data = await resp.arrayBuffer();
  const blob = new Blob([data], { type: 'video/mp4' });
  return await blobAsDataUrl(blob);
  // return URL.createObjectURL(blob);
}

/**
 * Downloads a file and get content as ArrayBuffer
 * @param {string} fileUrl
 * @param {AbortSignal} [signal]
 * @returns {Promise<ArrayBuffer>}
 */
export async function downloadFileToBuffer(fileUrl, signal) {
  if (!fileUrl?.startsWith('http')) {
    throw Error('Invalid URL: ' + fileUrl);
  }
  const resp = await fetch(fileUrl, { signal, cache: 'force-cache' });
  if (resp.status >= 400) {
    throw new Error(resp.statusText);
  }
  const data = await resp.arrayBuffer();
  return data.slice(0);
}

/**
 * File Download form URL
 * @param {string} url
 * @param {string} fileName
 * @param {AbortSignal} [signal]
 */

export const downloadFile = async (url, fileName, signal) => {
  const resp = await fetch(url, { signal, cache: 'force-cache' });
  if (resp.status >= 400) {
    throw new Error(resp.statusText);
  }
  const buffer = await resp.arrayBuffer();
  const blob = new Blob([buffer], { type: resp.headers.get('Content-Type') });
  FileSaver.saveAs(blob, fileName);
};
