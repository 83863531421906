import { TenantListView } from '@/web/administration/tenants';
import { T } from '@/assets/locales';
import { TenantCreatePage } from '@/web/administration/tenants/create';
import { TenantUpdatePage } from '@/web/administration/tenants/_id/edit';
import { TenantDetailsPage } from '@/web/administration/tenants/_id';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <TenantListView />,
    breadcrumb: T['tenant.route.list.title'],
  },
  {
    path: 'create',
    element: <TenantCreatePage />,
    breadcrumb: T['tenant.route.create.title'],
  },
  {
    path: ':tenantId',
    children: [
      {
        path: '',
        element: <TenantDetailsPage />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit',
        element: <TenantUpdatePage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
