import api from '@/api';
import { BOOLEAN_TYPE_SELECT_OPTIONS, INPUT_TYPE_OPTIONS } from '@/assets/administrations/apn';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const defaultValues = {
  name: '',
  description: '',
  unit: '',
  type: INPUT_TYPE_OPTIONS[1],
  defaultValue: null,
  customerFacing: BOOLEAN_TYPE_SELECT_OPTIONS[0],
  constraint: {
    minValue: 0,
    maxValue: 0,
    stepSize: 0,
  },
  variableSensitivity: {
    high: null,
    low: null,
    medium: null,
  },
};

/**
 * @typedef {object} TriggerVariableFormPropsBase
 * @property {import('react-hook-form').SubmitHandler<typeof defaultValues>} onSubmitData
 * @property {boolean} isLoading
 */

/**
 * @typedef {TriggerVariableFormPropsBase & import('@mui/material').BoxProps} TriggerVariableFormProps
 */

/**
 * @param {TriggerVariableFormProps} props
 */
export function TriggerVariableForm(props) {
  const { onSubmitData, isLoading, ...extra } = props;

  const secretToken = useSelector(selectSecretToken);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const variableId = useMemo(() => params.id, [params]);

  const [loading, setLoading] = useState(true);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!variableId) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const req = api.ac.v5.trigger.variable.definition.$variableName(variableId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    req.process().then((result) => {
      const {
        name,
        description,
        customerFacing,
        unit,
        type,
        defaultValue,
        variableSensitivity,
        constraint,
      } = result;

      const customerFacingOption = BOOLEAN_TYPE_SELECT_OPTIONS?.find(
        (item) => item?.value === customerFacing
      );

      const inputTypeOption = INPUT_TYPE_OPTIONS?.find((item) => item?.value === type);

      form.setValue('name', name);
      form.setValue('description', description);
      form.setValue('customerFacing', customerFacingOption);
      form.setValue('unit', unit);
      form.setValue('type', inputTypeOption);
      form.setValue('defaultValue', defaultValue);
      // @ts-ignore
      form.setValue('variableSensitivity', variableSensitivity);
      // @ts-ignore
      form.setValue('constraint', constraint);
      setLoading(false);
    });
    return () => req.abort();
  }, [variableId, secretToken, form]);

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;
    data.description = trimString(data.description);
    data.defaultValue = Number(data.defaultValue);
    data.constraint.maxValue = Number(data.constraint.maxValue);
    data.constraint.minValue = Number(data.constraint.minValue);
    data.constraint.stepSize = Number(data.constraint.stepSize);
    data.variableSensitivity.low = Number(data.variableSensitivity.low) || null;
    data.variableSensitivity.medium = Number(data.variableSensitivity.medium) || null;
    data.variableSensitivity.high = Number(data.variableSensitivity.high) || null;
    onSubmitData(data);
  };

  const handleCancel = () => {
    navigate('/smarter-ai/trigger-variables');
  };

  const handleDecimalValues = (value) => {
    if (!value || value === '' || value === undefined) return true;

    return (
      (!isNaN(parseFloat(value)) &&
        (value.toString().split('.')[1]?.length <= 2 || Number.isInteger(parseFloat(value)))) ||
      'Must be a number with up to two decimal places'
    );
  };

  if (loading)
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );

  return (
    <Box {...extra} pb={10}>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        {t(T['trigger.variable.form.details'])}
      </Typography>
      <Divider />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="name"
                label="Name*"
                placeholder="Enter variable name"
                rules={{ required: 'Variable name is required' }}
                InputProps={{
                  readOnly: !!variableId,
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomInput
                name="unit"
                label="Unit*"
                placeholder="Enter variable unit"
                rules={{ required: 'Variable unit is required' }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomSelect
                disabled
                name="type"
                label="Type*"
                options={INPUT_TYPE_OPTIONS}
                placeholder="Select variable type"
                rules={{ required: 'Variable type is required' }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomInput
                name="defaultValue"
                label="Default Value*"
                placeholder="Enter variable default value"
                type="number"
                rules={{ required: 'Variable default value is required' }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomSelect
                name="customerFacing"
                label="Customer Facing*"
                placeholder="Select variable customer facing status"
                options={BOOLEAN_TYPE_SELECT_OPTIONS}
                rules={{ required: 'Customer facing is required' }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="description"
                label="Description"
                placeholder="Enter variable description"
              />
            </Grid>
          </Grid>

          {/*  CONSTRAINTS */}
          <Box py={1.5}>
            <fieldset
              style={{ border: '2px solid #cfd9e5', borderRadius: '10px', paddingBottom: '20px' }}
            >
              <legend>
                <Typography variant="subtitle1" fontWeight={500}>
                  Constraint
                </Typography>
              </legend>

              <Grid container spacing={1} px={1}>
                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="constraint.minValue"
                    label="Min Value*"
                    placeholder="Enter min value"
                    rules={{
                      required: 'Variable min value is required',
                      validate: (value) => handleDecimalValues(value),
                    }}
                    labelFont="0.75rem"
                    type="number"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="constraint.maxValue"
                    label="Max Value*"
                    placeholder="Enter variable max value"
                    rules={{
                      required: 'Variable max value is required',
                      validate: (value) => handleDecimalValues(value),
                    }}
                    labelFont="0.75rem"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="constraint.stepSize"
                    label="Step Size*"
                    type="number"
                    placeholder="Enter step size"
                    rules={{
                      required: 'Variable step size is required',
                      validate: (value) => handleDecimalValues(value),
                    }}
                    labelFont="0.75rem"
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Box>

          {/*  SENSITIVITY */}
          <Box>
            <fieldset
              style={{ border: '2px solid #cfd9e5', borderRadius: '10px', paddingBottom: '20px' }}
            >
              <legend>
                <Typography variant="subtitle1" fontWeight={500}>
                  Sensitivity
                </Typography>
              </legend>

              <Grid container spacing={1} px={1}>
                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="variableSensitivity.low"
                    label="Low"
                    placeholder="Enter low sensitivity"
                    labelFont="0.75rem"
                    type="number"
                    rules={{
                      required: false,
                      validate: (value) => handleDecimalValues(value),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="variableSensitivity.medium"
                    label="Medium"
                    placeholder="Enter medium sensitivity"
                    labelFont="0.75rem"
                    type="number"
                    rules={{
                      required: false,
                      validate: (value) => handleDecimalValues(value),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomInput
                    name="variableSensitivity.high"
                    label="High"
                    placeholder="Enter high sensitivity"
                    labelFont="0.75rem"
                    type="number"
                    rules={{
                      required: false,
                      validate: (value) => handleDecimalValues(value),
                    }}
                  />
                </Grid>
              </Grid>
            </fieldset>
          </Box>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mt={4}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button disabled={isLoading} variant="contained" type="submit">
              {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {variableId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
