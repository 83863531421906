import { BOX_DISCONTINUE, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';
import { T } from '@/assets/locales';

/** @type {Array<TableHeaderAction<DriverTag>>} */
export const ACTION_ITEMS = [
  {
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    confirmationMessage: T['driver.tags.confirmations.discontinue'],
    hidden: (selected) => !selected.length,
  },
  {
    type: 'MODIFY',
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    useCustomDialog: true,
    hidden: (selected) => selected.length !== 1,
  },
  {
    label: 'Search',
    placeholder: T['driver.tags.actions.search'],
    component: 'SEARCH',
  },
  {
    type: 'CREATE',
    label: T['driver.tags.actions.create'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    useCustomDialog: true,
  },
];
