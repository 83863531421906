import { GROUP_SETTINGS_CATEGORIES } from '@/assets/schemes/constants';
import { DEVICE_GROUP_SETTINGS } from '@/assets/schemes/settings';
import { isObject, startsWith } from 'lodash';
import { trimSpaceFromString } from '.';
import { SCHEME_PARENT_ITEMS } from '../assets';

/**
 * Generates input selections based on the provided inputs.
 * @param {Array} inputs - An array of input elements.
 * @returns {Array}
 */
export function getInputSelections(inputs) {
  const items = [];
  inputs?.forEach((element) => {
    const item = {
      label: trimSpaceFromString(element.inputName),
      value: element.inputCode,
      inputType: element.inputType,
      customEnable: true,
    };
    items.push(item);
  });
  return items;
}

/**
 * Simplifies a nested settings structure by flattening and transforming it into a simplified format.
 * @param {Array} settings - An array of nested settings objects.
 * @returns {Array} An array of simplified settings with transformed labels and values.
 */
export function simplifySettings(settings) {
  const flattenedSettings = [];

  function flatten(setting) {
    if (setting?.key === 'deviceWifiConnectivity' || setting?.key === 'blurredLabels') {
      return;
    }

    if (setting?.children) {
      setting.children.forEach(flatten);
    } else if (Object.keys(setting)?.length > 0) {
      flattenedSettings.push(setting);
    }
  }

  settings?.forEach(flatten);

  return flattenedSettings.map(({ key, ...item }) => ({
    ...item,
    value: key,
    label: trimSpaceFromString(item.label),
  }));
}

/**
 * Groups device settings by category, simplifying the nested structure.
 * @returns {object} An object where keys are category names and values are arrays of simplified settings.
 */
export function groupSettingsByCategory() {
  const categorizedSettings = {};

  DEVICE_GROUP_SETTINGS.forEach((categorySettings) => {
    const categoryKey = categorySettings?.key;
    if (categoryKey) {
      categorizedSettings[categoryKey] = simplifySettings([categorySettings]);
    }
  });

  return categorizedSettings;
}

/**
 * Update or add pipeline settings in an array.
 * @param {Array} settingsArray - The array of pipeline settings.
 * @param {object} thirdItem - The third item object.
 * @param {string} pipelineType - The pipeline type.
 * @param {string} lastElementKey - The key for the last element.
 * @param {*} mappedValue - The value to be assigned to the last element.
 */
function updatePipelineSettings(
  settingsArray,
  thirdItem,
  pipelineType,
  lastElementKey,
  mappedValue
) {
  const pipelineItem = settingsArray.find(
    (item) => item.src === thirdItem.value && item.pipeline === pipelineType
  );

  if (!pipelineItem) {
    settingsArray.push({
      src: thirdItem.value,
      pipeline: pipelineType,
      [lastElementKey]: mappedValue,
    });
  } else {
    pipelineItem[lastElementKey] = mappedValue;
  }
}

/**
 * Formats settings before submission.
 * @param {Array} properties - An array of setting properties.
 * @returns {object} The formatted settings object.
 */
export function formatSettingsBeforeSubmit(properties) {
  const settings = {};
  const imagerStateSettings = [];
  const videoPipelineSettings = [];
  const audioPipelineSettings = [];

  properties?.forEach((obj) => {
    if (obj.item.at(0).value !== SCHEME_PARENT_ITEMS.SETTINGS) return;

    const lastElement = obj.item[obj.item.length - 1];
    const lastElementKey = lastElement?.value || lastElement?.key;
    const mappedValue = isObject(obj.value) ? obj.value.value : obj.value;

    if (obj.item.length < 4) {
      settings[lastElementKey] = mappedValue;
      return;
    } else {
      const secondItem = obj.item[1];
      const thirdItem = obj.item[2];
      const mappedValue = isObject(obj.value) ? obj.value.value : obj.value;

      const pipelineType = secondItem?.value;
      if (
        [GROUP_SETTINGS_CATEGORIES.RECORDING, GROUP_SETTINGS_CATEGORIES.STREAMING].includes(
          pipelineType
        )
      ) {
        const settingsArray = startsWith(thirdItem.value, 'vid_')
          ? videoPipelineSettings
          : audioPipelineSettings;
        updatePipelineSettings(settingsArray, thirdItem, pipelineType, lastElementKey, mappedValue);
      } else if (pipelineType === GROUP_SETTINGS_CATEGORIES.DEVICE) {
        const item = imagerStateSettings.find(
          (i) => i.src === thirdItem.value && thirdItem.inputType
        );

        if (!item) {
          imagerStateSettings.push({
            inputCode: thirdItem.value,
            inputType: thirdItem.inputType,
            [lastElementKey]: mappedValue,
          });
        } else {
          item[lastElementKey] = mappedValue;
        }
      }
    }
  });

  if (imagerStateSettings?.length) {
    settings['imagerState'] = JSON.stringify(imagerStateSettings);
  }
  if (videoPipelineSettings?.length) {
    settings['videoPipeline'] = JSON.stringify(videoPipelineSettings);
  }
  if (audioPipelineSettings?.length) {
    settings['audioPipeline'] = JSON.stringify(audioPipelineSettings);
  }

  if (settings.hasOwnProperty('periodicCheckInterval')) {
    settings['periodicCheckInterval'] = settings.periodicCheckInterval * 60;
  }

  if (settings.hasOwnProperty('reportingThreshold')) {
    settings['reportingThreshold'] = settings.reportingThreshold * 1024;
  }

  if (settings.hasOwnProperty('lowBandwidthThreshold')) {
    settings['lowBandwidthThreshold'] = settings.lowBandwidthThreshold * 1024 * 1024;
  }

  if (settings.hasOwnProperty('blackoutThreshold')) {
    settings['blackoutThreshold'] = settings.blackoutThreshold * 1024 * 1024;
  }

  const uniqueObject = Object.fromEntries(
    Array.from(new Set(Object.entries(settings))).filter(
      ([key, value]) => key !== undefined && value !== undefined
    )
  );

  return uniqueObject;
}
