export const SESSION_TIMEOUT = 100 * 60 * 1000;

export const MESSAGE_TYPE = {
  RTC_OFFER: 'camera.smarterai.connection.rtc.offer',
  RTC_ANSWER: 'camera.smarterai.connection.rtc.answer',
  RTC_ICE_CANDIDATES: 'camera.smarterai.connection.rtc.icecandidates',
  RTC_HANGUP: 'camera.smarterai.connection.rtc.hangup',
  RTC_MEDIA_HOLD: 'camera.smarterai.connection.rtc.mediahold',
  RTC_MEDIA_UNHOLD: 'camera.smarterai.connection.rtc.mediaunhold',
  SHELL_EXECUTE: 'com.anyconnect.remoteshell',
  STREAM_SOURCE_NAMES: 'com.anyconnect.stream.source.name',
  STREAM_SOURCE_ADDED: 'com.anyconnect.stream.source.add',
  STREAM_SOURCE_REMOVED: 'com.anyconnect.stream.source.remove',
  RECORDING_UPCOMING: 'camera.smarterai.device.recording.upcoming',
  RECORDING_RESPONSE: 'camera.smarterai.device.recording.response',
  GROUP_SETTINGS_UPDATE: 'com.anyconnect.dashboard.configuration.group.updated',
  DEVICE_GROUP_CHANGE: 'com.anyconnect.dashboard.group.change',
  AI_EVENT_UPDATED: 'com.anyconnect.dashboard.aievent.updated',
  FIRMWARE_DEPLOYED: 'com.anyconnect.dashboard.firmware.deployed',
  AI_CONTAINER_DEPLOYED: 'com.anyconnect.dashboard.aicontainer.deployed',
  AI_CONTAINER_UNDEPLOY: 'com.anyconnect.dashboard.aicontainer.undeployed',
  FIRMWARE_UPDATE: 'com.anyconnect.firmware.update',
  DASHBOARD_CONFIGURATION_GROUP_UPDATE: 'com.anyconnect.dashboard.configuration.group.updated',
  DASHBOARD_FIRMWARE_DEPLOYED: 'com.anyconnect.dashboard.firmware.deployed',
  DASHBOARD_GROUP_CHANGE: 'com.anyconnect.dashboard.group.change',
  DEVICE_REBOOT: 'com.anyconnect.device.reboot',
  UPDATE_CAMERA_SETTINGS: 'com.anyconnect.dashboard.configuration.device.settings.updated',
  FORMAT_SD_CARD: 'com.anyconnect.device.sdcard.format',
  ENROLLMENT_START_REQUEST: 'object.enrollment.start',
  ENROLLMENT_STATE: 'object.enrollment.state',
  ENROLLMENT_PROGRESS: 'object.enrollment.progress',
  ENROLLMENT_REMOVE_REQUEST: 'object.enrollment.remove',
  ENROLLMENT_REMOVED: 'object.enrollment.removed',
  QUERY_RECORDING_AVAILABILITY: 'query.recording.availability',
  RECORDING_AVAILABILITY: 'answer.recording.availability',
  PRESENCE_ONLINE: 'com.anyconnect.presence.online',
  CONNECTION_CANCEL: 'com.anyconnect.connection.cancel',
  DEVICE_CALIBRATE: 'com.anyconnect.device.calibration.delete',
  SET_ENVIRONMENT: 'com.anyconnect.dashboard.baseurl.update',
  DBC_FILE_UPDATE: 'com.anyconnect.dbc.update',
  PARKING_DEVICE_AWAKE: 'com.anyconnect.device.wakeup',
};
