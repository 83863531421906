import { PageLink } from '@/web/@components/PageLink';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import { isFunction } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisTextViewContainer } from '../EllipsisTextView/index';

/**
 * @template T
 * @typedef {object} ItemsListViewProps<T>
 * @property {Array<T>} [data]
 * @property {JSX.Element} [children]
 * @property {Array<TableColumn<T>>} [columns]
 */

/**
 * @template T
 * @param {ItemsListViewProps<T> & import('@mui/material').BoxProps} props
 */
export function ItemsListView(props) {
  const { columns, data, children, ...boxProps } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(-1);
  const accordionTitleColumns = useMemo(() => columns.filter((col) => col.isPrimary), [columns]);
  const accordionBodyColumns = useMemo(() => columns.filter((col) => !col.isPrimary), [columns]);

  /** @type {(panel: number) => import('@mui/material').AccordionProps['onChange']} */
  const handleChange = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : -1);
  };

  return (
    <Box {...boxProps} sx={{ height: 'calc(100vh - 280px)' }}>
      <Box
        sx={{
          height: 'calc(100vh - 280px)',
          overflow: 'auto',
          background: '#fff',
        }}
      >
        {data.map((row, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{ 'mx': 0.5, '& .Mui-expanded': { m: 0 } }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <EllipsisTextViewContainer>
                <Box
                  display="inline-flex"
                  width="100%"
                  alignItems="center"
                  alignContent="center"
                  gap={1}
                  flexDirection="row"
                >
                  {accordionTitleColumns.map((column, index) => (
                    <Box key={column.id + index}>
                      {column?.format ? (
                        isFunction(column.link) && column.link(row) ? (
                          <PageLink to={column.link(row)}>
                            <Typography
                              fontSize="0.825rem"
                              variant="subtitle2"
                              fontWeight={500}
                              lineHeight="20px"
                            >
                              {column.format(row[column.id], row)}
                            </Typography>
                          </PageLink>
                        ) : (
                          <Typography
                            fontSize="0.825rem"
                            variant="subtitle2"
                            fontWeight={500}
                            lineHeight="20px"
                          >
                            {column.format(row[column.id], row)}
                          </Typography>
                        )
                      ) : 'link' in column ? (
                        <PageLink to={isFunction(column.link) ? column.link(row) : column.link}>
                          <Typography
                            fontSize="0.825rem"
                            variant="subtitle2"
                            fontWeight={500}
                            lineHeight="20px"
                          >
                            {row[column.id] || column?.fallback || '...'}
                          </Typography>
                        </PageLink>
                      ) : (
                        <Typography
                          fontSize="0.825rem"
                          variant="subtitle2"
                          fontWeight={500}
                          lineHeight="20px"
                        >
                          {row[column.id] || column?.fallback || '...'}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>
              </EllipsisTextViewContainer>
            </AccordionSummary>

            <AccordionDetails>
              {accordionBodyColumns.map((column, index) => (
                <Box
                  key={column.id + index}
                  id="11"
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center !important',
                  }}
                >
                  <Typography color={theme.palette.text.disabled}>{t(column.label)}:</Typography>
                  {column?.format ? (
                    <Box>{column.format(row[column.id], row)}</Box>
                  ) : (
                    <Typography>{row[column.id] || column?.fallback || '...'}</Typography>
                  )}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
        {children}
      </Box>
    </Box>
  );
}
