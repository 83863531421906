import { Box } from '@mui/material';
import { PageLink } from '@/web/@components/PageLink';

export function SupportPage() {
  return (
    <Box p={3}>
      <i>Under development</i>
      <ul>
        <li>
          <PageLink to="./privacy-policy">Privacy Policy</PageLink>
        </li>
        <li>
          <PageLink to="./terms-and-conditions">Terms and Conditions</PageLink>
        </li>
      </ul>
    </Box>
  );
}
