import { InfoRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

/**
 * @typedef {object} CustomTooltipProps
 * @property {string} [text]
 */

/** @param {CustomTooltipProps} props */
export function CustomTooltip(props) {
  const { text } = props;

  if (!text) {
    return null;
  }

  return (
    <Tooltip
      arrow
      placement="right"
      title={text}
      componentsProps={{
        tooltip: {
          sx: {
            'color': '#4D6076',
            'bgcolor': '#E0ECFC',
            '& .MuiTooltip-arrow': {
              color: '#E0ECFC',
            },
          },
        },
      }}
    >
      <IconButton size="small" sx={{ pointerEvents: 'visible !important', opacity: '1' }}>
        <InfoRounded htmlColor="#608AC3" />
      </IconButton>
    </Tooltip>
  );
}
