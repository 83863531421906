import {
  AI_CONTAINER_AI_MODEL_ICON,
  AI_CONTAINER_AUDIO_ICON,
  AI_CONTAINER_CAMERA_VIDEO_ICON,
  AI_CONTAINER_SENSOR_ICON,
} from '@/assets/constants/images';

/**
 * Get the icon by item source type
 * @param {string} type
 */
export function getIconBySourceType(type) {
  switch (type) {
    case 'VIDEO':
      return AI_CONTAINER_CAMERA_VIDEO_ICON;
    case 'AUDIO':
      return AI_CONTAINER_AUDIO_ICON;
    case 'SENSOR':
      return AI_CONTAINER_SENSOR_ICON;
    default:
      return AI_CONTAINER_AI_MODEL_ICON;
  }
}
