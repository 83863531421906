import { T } from '@/assets/locales';
import { EDGE_TENSOR_REGEX } from '@/utils/user-access';
import { AdministrationPage } from '.';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <AdministrationPage />,
  },
  {
    path: 'ai',
    children: require('./ai/routes').default,
    breadcrumb: T['navigation.ai'],
    accessLevel: 'HIDDEN',
    emailAccess: [EDGE_TENSOR_REGEX],
    teamAccess: ['AI_TEAM', 'FRONT_END_TEAM', 'PRODUCT_MANAGER'],
  },
  {
    path: 'device-groups',
    children: require('./device-groups/routes').default,
    breadcrumb: T['navigation.device.groups'],
  },
  {
    path: 'device-pools',
    children: require('./device-pools/routes').default,
    breadcrumb: T['navigation.device.pools'],
  },
  {
    path: 'vin-groups',
    children: require('./vin-groups/routes').default,
    breadcrumb: T['navigation.vin.groups'],
  },
  {
    path: 'users',
    children: require('./users/routes').default,
    breadcrumb: T['navigation.users'],
  },
  {
    path: 'tenants',
    children: require('./tenants/routes').default,
    breadcrumb: T['navigation.tenants'],
  },
  {
    path: 'schemes',
    children: require('./schemes/routes').default,
    breadcrumb: T['navigation.schemes'],
  },
  {
    path: 'trigger-configuration',
    children: require('./triggers-configuration/routes').default,
    breadcrumb: T['navigation.trigger-sensitivity'],
  },
  {
    path: 'video-minutes',
    children: require('./video-minutes/routes').default,
    breadcrumb: T['navigation.video-minutes'],
  },
  {
    path: 'my-notifications',
    children: require('./my-notifications/routes').default,
    breadcrumb: T['navigation.navigation.my-notifications'],
  },
  {
    path: 'dbc',
    children: require('../administration/dbc/routes').default,
    breadcrumb: T['navigation.dbc'],
  },
  {
    path: 'apk-files',
    children: require('../administration/apk-files/routes').default,
    breadcrumb: T['navigation.apk.files'],
  },
];

export default routes;
