import { ArrowDropDown } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useState } from 'react';
import { USER_DEVICES_ACCORDION_ITEMS } from './items';

export function UserDeviceArea() {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      py="5px"
      sx={{
        '& .MuiPaper-elevation': {
          border: '1px solid #c5d9f0',
          borderRadius: '5px',
        },
        'overflowY': 'auto',
      }}
    >
      {USER_DEVICES_ACCORDION_ITEMS.map((item, index) => {
        return (
          <Accordion
            key={item.key + index}
            disableGutters
            elevation={0}
            square
            TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
            expanded={expanded === item.key}
            onChange={handleChange(item.key)}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDown fontSize="large" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{item.label}</Typography>
            </AccordionSummary>
            <AccordionDetails key={item.key + index}>
              <div>{item.component()}</div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
