import { LongTextShortener } from '@/web/@components/LongTextShortener';
import { ApkEditButton } from './@components/ApkEditButton';

/** @type {Array<TableColumn<FirmwareDto>>} */
export const APK_TABLE_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    searchable: true,
    format: (value) => <LongTextShortener text={value} length={45} />,
    link: (row) => `/administration/apk-files/${row.firmwareId}/details`,
  },
  {
    id: 'version',
    label: 'Version',
    align: 'left',
    searchable: true,
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    searchable: true,

    format: (value) => <LongTextShortener text={value} length={80} />,
  },
  {
    id: 'action',
    label: '',
    align: 'left',
    searchable: true,
    width: '30px',
    showOnlyOnHover: true,
    format: (_, row) => <ApkEditButton firmwareId={row?.firmwareId} isVisible={true} />,
  },
];
