import { CustomInput, CustomSelect, CustomSwitch } from '@/web/@components/CustomForm';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

/** @param {{item: AIModelForm, fieldIndex: number}} props */
export function SecondaryInputSourceFormControl(props) {
  const { item, fieldIndex } = props;

  const form = useFormContext();
  const inputType = form.watch(`inputSources[${fieldIndex}].inputType`);
  const sensorType = form.watch(`inputSources[${fieldIndex}].sensorType`);

  if (item.visibility && !item.visibility(inputType)) {
    return null;
  }

  if (item.type === 'input' || item.type === 'number') {
    return (
      <Grid item xs={12} md={3}>
        <CustomInput
          disabled={item.disabled}
          label={item.label}
          placeholder={item.placeholder}
          type={item.type === 'input' ? 'text' : 'number'}
          name={`inputSources[${fieldIndex}].${item.name}`}
          rules={{ required: item?.mandatory ? 'This is required' : false }}
        />
      </Grid>
    );
  }

  if (item.type === 'switch') {
    return (
      <Grid item xs={12} md={3}>
        <CustomSwitch
          label={item.label}
          name={`inputSources[${fieldIndex}].${item.name}`}
          rules={{ required: item?.mandatory ? 'This is required' : false }}
        />
      </Grid>
    );
  }

  if (item.type === 'select') {
    let filteredOptions = item.options || [];
    if (item.name === 'unit' && sensorType) {
      if (['gps', 'gyrometer'].includes(sensorType)) return null;
      filteredOptions = item.options.filter((x) => x?.visibility && x.visibility(sensorType));
    }

    return (
      <Grid item xs={12} md={3}>
        <CustomSelect
          disabled={item.disabled}
          name={`inputSources[${fieldIndex}].${item.name}`}
          label={item.label}
          placeholder={item.placeholder}
          options={(filteredOptions || []).map((x) => x.value)}
          getLabel={(value) => filteredOptions?.find((x) => x.value === value)?.label || ''}
          rules={{ required: item?.mandatory ? 'This is required' : false }}
        />
      </Grid>
    );
  }

  return null;
}
