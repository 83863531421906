import { BoundingBoxLabelRenderer } from './_labels';
import { NiceBoundingBoxRenderer } from './_nice';
import { BoundingBoxV4Renderer } from './_v4';

export * from './_labels';
export * from './_legacy';
export * from './_v4';

/**
 * @typedef {object} DrawBoundingBoxOptions
 * @property {boolean} [imperial]
 * @property {string} [boxColor]
 * @property {number} [scale]
 * @property {number} [lineWidth]
 * @property {number} [lineDash]
 * @property {number} [labelGap]
 * @property {number} [meshGap]
 * @property {number} [textPaddingX]
 * @property {number} [textPaddingY]
 * @property {number} [connectorLength]
 * @property {number} [fontWeight]
 * @property {number} [fontSize]
 * @property {string} [fontFamily]
 * @property {number} [imagerWidth]
 * @property {number} [imagerHeight]
 * @property {Array<string>} [disabledLabels]
 * @property {boolean} [clearCanvas]
 * @property {boolean} [autoResize]
 * @property {number} [decimalPlaces]
 * @property {Array<string>} [triggerLabels]
 * @property {number} [startTime]
 * @property {number} [endTime]
 * @property {string} [laneColor]
 */

/** @type {import('.').DrawBoundingBoxOptions} */
const defaultOptions = {
  imperial: false,
  scale: 6,
  lineWidth: 1,
  lineDash: 4,
  meshGap: 8,
  imagerWidth: 1280,
  imagerHeight: 720,
  triggerLabels: [],
  startTime: 0,
  endTime: 0,
  boxColor: '#EBF411',
  labelGap: 2,
  textPaddingX: 3,
  textPaddingY: 3,
  connectorLength: 10,
  fontWeight: 500,
  fontSize: 10,
  fontFamily: '"Poppins"',
  disabledLabels: [],
  decimalPlaces: 2,
  laneColor: '#2683FF',
};

/**
 * Draw a single item to bounding box for V4
 * Lane support
 * @param {HTMLCanvasElement} canvas
 * @param {Array<VideoMetaData>} items
 * @param {DrawBoundingBoxOptions} [options]
 */
export async function drawMultiBoundingBox(canvas, items, options) {
  if (!canvas) return;
  try {
    const renderer = new BoundingBoxRenderer(canvas, options);
    renderer.render(items);
  } catch (err) {
    console.error(err);
  }
}

export class BoundingBoxRenderer {
  /**
   * @param {HTMLCanvasElement} canvas
   * @param {import('.').DrawBoundingBoxOptions} options
   */
  constructor(canvas, options) {
    this.canvas = canvas;
    this.options = { ...defaultOptions, ...options };

    const { scale } = this.options;
    this.options.lineWidth *= scale;
    this.options.lineDash *= scale;
    this.options.meshGap *= scale;
    this.options.labelGap *= scale;
    this.options.textPaddingX *= scale;
    this.options.textPaddingY *= scale;
    this.options.connectorLength *= scale;
    this.options.fontSize *= scale;

    if (
      scale * this.canvas.width !== this.canvas.clientWidth ||
      scale * this.canvas.height !== this.canvas.clientHeight
    ) {
      this.canvas.width = scale * this.canvas.clientWidth;
      this.canvas.height = scale * this.canvas.clientHeight;
    }
    this.ctx = this.canvas.getContext('2d');

    this.v4Renderer = new BoundingBoxV4Renderer(this);
    this.labelRenderer = new BoundingBoxLabelRenderer(this);
    this.niceRenderer = new NiceBoundingBoxRenderer(this);
  }

  clear() {
    this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  /**
   * @param {Array<VideoMetaData>} items
   */
  render(items) {
    this.clear();
    if (!items?.length) return;
    if (items[0].figures) {
      for (const item of items || []) {
        if (!item) continue;
        this.v4Renderer?.render(item);
      }
    } else {
      this.niceRenderer?.render(items);
    }
  }
}
