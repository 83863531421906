import { formatTimestamp } from '@/utils/datetime';
import { prettyBytes } from '@/utils/pretty-bytes';
import { capitalize, startCase } from 'lodash';

/** @type {Array<TableColumn<CameraTypeRecord>>} */
export const GROUP_CAMERA_DATA_CONSUMPTION_RECORD_TABLE_COLUMNS = [
  {
    id: 'reportingTime',
    label: 'Time',
    align: 'left',
    searchable: true,
    isPrimary: true,
    minWidth: '190px',
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'strategy',
    label: 'Strategy',
    align: 'center',
    searchable: true,
    format: (value) => capitalize(startCase(value)),
  },
  {
    id: 'MEDIA',
    label: 'Media',
    align: 'center',
    searchable: true,
    minWidth: '100px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'STREAMING',
    label: 'Streaming',
    align: 'center',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'VIDEO',
    label: 'Video',
    align: 'center',
    searchable: true,
    minWidth: '100px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'AUDIO',
    label: 'Audio',
    align: 'center',
    searchable: true,
    minWidth: '100px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'SNAPSHOT',
    label: 'Snapshot',
    align: 'center',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'THUMBNAIL',
    label: 'Thumbnail',
    align: 'center',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'SENSOR',
    label: 'Sensor',
    align: 'center',
    searchable: true,
    minWidth: '100px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'REST',
    label: 'Rest',
    align: 'center',
    searchable: true,
    minWidth: '100px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'AI_DATA',
    label: 'AI Data',
    align: 'center',
    searchable: true,
    minWidth: '100px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'SIGNALR',
    label: 'SignalR',
    align: 'center',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'COMMUNICATION',
    label: 'Communication',
    align: 'center',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'LOGS',
    label: 'Logs',
    align: 'center',
    searchable: true,
    minWidth: '100px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'AI_MODEL_DOWNLOAD',
    label: 'AI Model DL',
    align: 'center',
    searchable: true,
    minWidth: '120px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'FW_DOWNLOAD',
    label: 'Firmware DL',
    align: 'center',
    searchable: true,
    minWidth: '120px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'DBC_FILE_DOWNLOAD',
    label: 'DBC File DL',
    align: 'center',
    searchable: true,
    minWidth: '120px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'TRAFFIC_DATA_DOWNLOAD',
    label: 'Traffic Data DL',
    align: 'center',
    searchable: true,
    minWidth: '140px',
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'REST_DOWNLOAD',
    label: 'Rest DL',
    align: 'center',
    minWidth: '120px',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'OTHERS',
    label: 'Others',
    align: 'center',
    minWidth: '100px',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'TOTAL_DEVICE_USAGE',
    label: 'Total',
    align: 'center',
    minWidth: '100px',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  // {
  //   id: 'dataConsumed',
  //   label: 'Consumptions',
  //   align: 'center',
  //   format: (value) => {
  //     const keys = Object.keys(value);
  //     return (
  //       <Box display="inline-flex">
  //         {keys?.map((key, i) => (
  //           <Box pl={keys?.length < 5 ? '50px' : '22px'}>
  //             <Typography variant="body2" fontSize="13px">
  //               {capitalize(startCase(key))}
  //             </Typography>
  //             <Typography variant="body1" fontSize="14px">
  //               {prettyBytes(value[key])}
  //             </Typography>
  //           </Box>
  //         ))}
  //       </Box>
  //     );
  //   },
  // },
];
