import { T } from '@/assets/locales';
import { DriversListView } from '.';
import { DriverCreatePage } from './create';
import { DriverDetailsPage } from './_id/details';
import { DriverEditPage } from './_id/edit';
import { FACE_REGISTRATION_TENANTS } from '@/utils/user-access';
import { FaceRegistrationPage } from './registration';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DriversListView />,
    breadcrumb: 'Drivers',
  },
  {
    path: 'create',
    element: <DriverCreatePage />,
    breadcrumb: 'Create New Driver',
  },
  {
    path: 'registration',
    breadcrumb: 'Face Registration',
    tenantAccess: FACE_REGISTRATION_TENANTS,
    accessLevel: 'SMARTER_AI_ADMIN',
    element: <FaceRegistrationPage />,
  },
  {
    path: ':id',
    children: [
      {
        path: 'details',
        element: <DriverDetailsPage />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit',
        element: <DriverEditPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
