import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { capitalize, isBoolean, startCase } from 'lodash';
import { useMemo, useState } from 'react';
import { NETWORK_CHANGES } from '../../@assets/usages';
import { convertKbpsToBytesPerSecond, flattenNetworkObject } from '../../@utils';
import { prettyBytes } from '@/utils/pretty-bytes';

/**
 * @typedef {object} CameraPubSubNetworkChangeModalProps
 * @property {CameraTypeRecord} [row]
 * @property {() => any} [onCancel]
 */

/** @param {CameraPubSubNetworkChangeModalProps} props */
export function CameraPubSubNetworkChangeModal(props) {
  const { row, onCancel } = props;

  const [expanded, setExpanded] = useState('wifi');

  const handleChange = (item) => {
    if (item === expanded && item === 'wifi') {
      setExpanded('cellular');
    } else if (item === expanded && item === 'cellular') {
      setExpanded('wifi');
    } else {
      setExpanded(item);
    }
  };

  const items = useMemo(
    () => flattenNetworkObject(row[expanded === 'wifi' ? 'wifiInfo' : 'cellularInfo']),
    [expanded, row]
  );

  const networkItemValue = (key) => {
    if (['dns', 'ipv4', 'ipv6', 'ssid']?.includes(key)) {
      return items[key]?.replace(/[[\]/]/g, '');
    } else if (['rssi']?.includes(key)) {
      const rssi = items[key];
      return `${rssi > 2147483647 ? rssi - 4294967296 : rssi} dBm`;
    } else if (['rxKbps', 'txKbps']?.includes(key)) {
      return prettyBytes(convertKbpsToBytesPerSecond(items[key]));
    } else if (isBoolean(items[key])) {
      return items[key] ? 'Yes' : 'No';
    } else {
      return items[key];
    }
  };

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DialogTitle>Network Details</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{
            '& .MuiPaper-elevation': {
              border: '1px solid #c5d9f0',
              borderRadius: '5px',
            },
          }}
          height="100%"
        >
          {NETWORK_CHANGES.map((item, index) => (
            <Accordion
              key={index + item.key}
              disableGutters
              elevation={0}
              square
              TransitionProps={{ unmountOnExit: false, mountOnEnter: true }}
              expanded={expanded === item.key}
              onChange={(e) => handleChange(item.key)}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDown fontSize="large" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.label}</Typography>
              </AccordionSummary>
              <AccordionDetails
                key={item.key}
                sx={{ height: '100%', borderTop: '1px solid #c5d9f0', overflowY: 'auto' }}
              >
                <table style={{ width: '100%' }}>
                  <tbody>
                    {Object.keys(items)
                      ?.sort()
                      ?.map((key, index) => (
                        <tr
                          key={index + key}
                          style={{ background: index % 2 === 0 ? '#e9e9e9' : '' }}
                        >
                          <td style={{ width: '40%' }}>
                            <Typography variant="subtitle2" mx={1}>
                              {capitalize(startCase(key))}
                            </Typography>
                          </td>
                          <td style={{ width: '60%' }}>
                            <Typography variant="subtitle1" mx={1}>
                              {networkItemValue(key)}
                            </Typography>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="text" onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
