import { CustomTooltip } from '@/web/@components/CustomTooltip';
import { Box, Slider, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * @typedef {object} SliderFormProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {SliderFormProps} props */
export function GroupSettingsSliderForm(props) {
  const { item } = props;
  const { key: name, label: labelName, postText, min, max, step, rules, ...rest } = item;

  const theme = useTheme();
  const { getValues, watch, setValue } = useFormContext();

  const defaultVal = useMemo(() => Number(getValues(name) || 0), [getValues, name]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
      }}
    >
      <Box display="inline-flex" width="70%" alignItems="center">
        <Typography color="#0B2547" fontSize="14px">
          {labelName}
        </Typography>
        <CustomTooltip text={item?.infoText} />
      </Box>
      <Slider
        sx={{
          'color': '#608AC3',
          'height': 2,
          'padding': '15px 0',
          '& .MuiSlider-thumb': {
            backgroundColor: '#fff',
            border: '4px solid #608AC3',
          },
          '& .MuiSlider-valueLabel': {
            'fontSize': 12,
            'fontWeight': 'normal',
            'top': 0,
            'backgroundColor': 'unset',
            'color': theme.palette.text.primary,
            '&:before': {
              display: 'none',
            },
            '& *': {
              background: 'transparent',
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            },
          },
          '& .MuiSlider-valueLabelLabel': {
            color: '#608AC3',
            fontSize: '0.75rem',
          },
        }}
        // {...register(name)}
        disabled={Boolean(rest.hasOwnProperty('isDisabled') ? rest.isDisabled(watch) : false)}
        defaultValue={Number(defaultVal)}
        aria-label="Slider"
        marks={false}
        //value={Number(watch(name))}
        valueLabelDisplay="on"
        valueLabelFormat={(x) => `${x} ${postText}`}
        step={Number(step) || 1}
        min={Number(min) || 0}
        max={Number(max) || 100}
        onChange={(event, value) => {
          setValue(name, value);
        }}
      />
    </Box>
  );
}
