const hostname = window.location.hostname;
export const isLocalNetwork =
  ['localhost', '127.0.0.1', '', '::1'].includes(hostname) ||
  hostname.startsWith('192.168.') ||
  hostname.startsWith('10.0.');
export const isStagingDashboard = hostname === 'stage-dashboard.smarterai.com';
export const isGeotabDashboard = hostname === 'geotab.smarterai.com';
export const isProductionDashboard = isGeotabDashboard || hostname === 'dashboard.smarterai.com';
export const isHack = window.location.href.includes('powerlevel=10k');
export const isDev = isHack || process.env.REACT_APP_ENV !== 'production';
export const isProduction = !isDev;

export const GOOGLE_CLIENT_ID =
  '628460593207-6umc584a7fug5s6m3la87ldc1fietf4q.apps.googleusercontent.com';
export const MICROSOFT_CLIENT_ID = '22de9888-f7d6-4c5e-963f-3c6285ca1a39';

export const DEFAULT_ACCESS_TOKEN = 'AWKFIJRMLOFORMUG78234KJD';

export const GOOGLE_MAP_API_KEY = 'AIzaSyD3ttjJtudxFtyNasHeMQ_-9ph8h0c4H4g';
export const VIN_API_KEY = 'ZrQEPSkKaWZ0YUBhbnljb25uZWN0LmNvbQ==';

export const DASHBOARD_VERSION = require('./assets/version.json').version;

export const GOOGLE_MAP_ID = 'c86e79060501e3d1';
export const SENTRY_DSN =
  'https://990eb51f31de45219e9163799a4d940b@o829178.ingest.sentry.io/4504678483296256';

export const DASHCAM_PLAYER_VERSION = isProductionDashboard
  ? 'production'
  : isStagingDashboard
    ? 'staging'
    : 'latest';

export const DASHCAM_PLAYER_JS = `https://storage.googleapis.com/sai-web-libs/dashcam-player/${DASHCAM_PLAYER_VERSION}/dashcam-player.min.js?ignoreCache=1`;
export const DASHCAM_PLAYER_CSS = `https://storage.googleapis.com/sai-web-libs/dashcam-player/${DASHCAM_PLAYER_VERSION}/dashcam-player.min.css?ignoreCache=1`;

// Use dashcam player build folder and run a server with python, build watch command needed
// export const DASHCAM_PLAYER_JS = `http://localhost:8000/dashcam-player.min.js`;
// export const DASHCAM_PLAYER_CSS = `http://localhost:8000/dashcam-player.min.css`;
