import {
  TOAST_ERROR_ICON,
  TOAST_INFO_ICON,
  TOAST_SUCCESS_ICON,
  TOAST_WARNING_ICON,
} from '@/assets/constants/images';
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import { readErrorMessage } from './errors';

/**
 * @typedef {import('izitoast').IziToastSettings & { iconUrl: string }} CustomToastSettings
 */

export function setupToaster() {
  iziToast.settings({
    zindex: 9999,
    position: 'topCenter',
    titleSize: '0.80rem',
    titleColor: 'rgba(11, 37, 71, 0.68)',
    backgroundColor: 'transparent',
    messageColor: '#0B2547',
    messageSize: '10px',
    resetOnHover: true,
    maxWidth: 800,
    onOpening: function (settings, toast, closedBy) {
      var icon = toast.querySelector('.iziToast-icon');
      var closeButton = toast.querySelector('.iziToast-close');
      var body = toast.querySelector('.iziToast-body');
      var message = toast.querySelector('.iziToast-message');
      var progressBar = toast.querySelector('.iziToast-progressbar');

      toast.style.borderRadius = '14px';

      if (body) {
        body.style.paddingLeft = '42px';
        body.style.paddingRight = '10px';
      }

      if (message) {
        message.style.fontSize = '12px';
      }

      if (closeButton) {
        closeButton.style.backgroundSize = '12px';
      }

      if (icon) {
        icon.style.marginLeft = '-4px';
        icon.style.marginTop = '-15px';
        icon.style.height = '32px';
        icon.style.width = '32px';
      }

      if (progressBar) {
        progressBar.style.paddingLeft = '8px';
        progressBar.style.paddingRight = '8px';
      }
    },
  });
}

/**
 * @typedef {object} ConfirmDialogParams
 * @property {string} [title='Are you sure?'] The dialog title
 * @property {string} [message = 'Please confirm.'] The dialog message
 * @property {false | number} [timeout = 10000] Timeout is milliseconds, or false to show forever.
 * @property {() => {}} onConfirm
 */

/**
 * Display a confirm dialog.
 * @param {Partial<ConfirmDialogParams>} [params]
 * @returns {Promise<void>}
 */
export async function toastConfirm(params = {}) {
  return new Promise((resolve, reject) => {
    iziToast.question({
      overlay: true,
      maxWidth: 500,
      position: 'center',
      timeout: params.timeout === undefined ? 10000 : params.timeout,
      title: params.title === undefined ? 'Are you sure?' : params.title,
      message: params.message === undefined ? 'Please confirm.' : params.message,
      buttons: [
        [
          '<button style="width: 100px"><b>YES</b></button>',
          (instance, toast) => instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes'),
          false,
        ],
        [
          '<button style="width: 100px"><b>NO</b></button>',
          (instance, toast) => instance.hide({ transitionOut: 'fadeOut' }, toast, 'no'),
          true,
        ],
      ],
      // @ts-ignore
      onClosing: (settings, toast, closedBy) => {
        if (closedBy === 'yes') {
          resolve();
          params.onConfirm && params.onConfirm();
        } else {
          reject();
        }
      },
    });
  });
}

/**
 * Displays an error toast.
 * @param {Error} error
 */
export function toastError(error) {
  if (!error) return;
  if (error.message === 'invalid token') return;
  console.error(error);

  /** @type {CustomToastSettings} */
  const errorToastSettings = {
    title: 'Error',
    message: readErrorMessage(error),
    backgroundColor: '#FCEDE9',
    iconUrl: TOAST_ERROR_ICON,
  };

  iziToast.error(errorToastSettings);
}

/**
 * Display a success toast.
 * @param {string} title
 * @param {string} message
 */
export function toastSuccess(title, message = '') {
  /** @type {CustomToastSettings} */
  const successToastSettings = {
    displayMode: 2,
    title: 'Success',
    message: message || title,
    backgroundColor: '#EAF7EE',
    iconUrl: TOAST_SUCCESS_ICON,
  };

  iziToast.success(successToastSettings);
}

/**
 * Display a warning toast.
 * @param {string} title
 * @param {string} message
 */
export function toastWarning(title, message = '') {
  /** @type {CustomToastSettings} */
  const warningToastSettings = {
    displayMode: 2,
    title: 'Warning',
    message: message || title,
    backgroundColor: '#FEF7EA',
    iconUrl: TOAST_WARNING_ICON,
  };

  iziToast.warning(warningToastSettings);
}

/**
 * Display an info toast.
 * @param {string} title
 * @param {string} message
 */
export function toastInfo(title, message = '') {
  /** @type {CustomToastSettings} */
  const infoToastSettings = {
    displayMode: 2,
    title: 'Information',
    message: message || title,
    iconUrl: TOAST_INFO_ICON,
    backgroundColor: '#F5F9FF',
  };

  iziToast.info(infoToastSettings);
}
