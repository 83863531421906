import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { VinGroupForm } from '@/web/administration/vin-groups/@components/VinGroupForm';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function CreateVinGroup() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/VinGroupForm').VinGroupFormProps['onSubmitData']} */
  const createGroup = async (form) => {
    const { groupName, groupDescription, cameraGroup } = form;
    try {
      setLoading(true);
      const req = api.ac.v5['vin-groups'].$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          name: groupName,
          description: groupDescription,
          targetGroupId: cameraGroup?.value,
          tenantId,
        },
      });
      await req.process();
      toastSuccess('Success', 'Vin Group Created successfully');
      navigate('/administration/vin-groups');
    } catch (err) {
      toastWarning('Error', 'Failed to create, Please try later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Create VIN Group
      </Typography>
      <VinGroupForm onSubmitData={createGroup} loading={loading} />
    </Box>
  );
}
