import { useRouteQuery } from '@/hooks/useRouteQuery';
import { store } from '@/store';
import { selectTenant } from '@/store/auth';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { toastWarning } from '@/utils/toaster';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SWITCH_INTERVAL = 60 * 1000;

export function AutoTenantSwitch() {
  const navigate = useNavigate();
  const { query } = useRouteQuery();

  useEffect(() => {
    if (query.auto_tenant_switch !== 'on') return;
    console.warn('!!! Auto tenant switch is on !!!');
    const iid = setInterval(async () => {
      const state = store.getState();
      const tenants = state.auth.tenantList || [];
      if (!tenants) return;
      const index = tenants.findIndex((x) => x.tenantId === state.auth.tenantId);
      if (index < 0) return;
      const tenant = tenants[(index + 1) % tenants.length];
      await store.dispatch(selectTenant({ tenantId: tenant.tenantId }));
      toastWarning('Automation', `Switched to <b>${tenant.tenantName}</b>`);
      navigate(`/events?from=${startOfTheDay()}&to=${endOfTheDay()}&auto_tenant_switch=on`);
    }, SWITCH_INTERVAL);
    return () => clearInterval(iid);
  }, [query.auto_tenant_switch, navigate]);

  return null;
}
