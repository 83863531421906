import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { PageLink } from '@/web/@components/PageLink';
import { EventGridListItem } from '@/web/events/@components/EventGridListItem';
import { Box, CircularProgress, Grid, Hidden, Typography } from '@mui/material';
import qs from 'qs';

/** @param {import('@mui/material').BoxProps} props */
export function FleetRecentEventArea(props) {
  const {
    result: events,
    error,
    loading,
  } = useFutureLoader(async ({ signal, secretToken, tenantId }) => {
    const request = api.ac.v5.events.filter.$get({
      signal,
      headers: {
        Authorization: secretToken,
      },
      params: {
        pageSize: 100, //min= 100, otherwise API returns "INVALID PAGE SIZE"
        tenantId,
        startTimestamp: 0,
        endTimestamp: Date.now(),
      },
    });
    await request.process();
    return request.result.events;
  }, []);

  if (error) {
    return null;
  }

  return (
    <Box {...props}>
      <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} color="#0B2547" sx={{ opacity: 0.68 }}>
          Recent Events
        </Typography>

        {events?.length > 0 && (
          <PageLink
            fontWeight={600}
            fontSize={'0.875rem'}
            to={`/events?${qs.stringify({
              from: startOfTheDay(events[0]?.recordingStartTimestamp),
              to: endOfTheDay(Date.now()),
              type: 'triggerName',
            })}`}
          >
            View All Events
          </PageLink>
        )}
      </Box>

      <HubConnectionProvider>
        <Grid container columns={60}>
          {loading ? (
            <CenterBox style={{ border: '1px solid #f8f8f8', height: '200px' }}>
              <CircularProgress></CircularProgress>
            </CenterBox>
          ) : !events?.length ? (
            <CenterBox style={{ border: '1px solid #f8f8f8' }}>
              <IconMessageBox size="150px" src="/images/empty/no-events.svg" message="No Events" />
            </CenterBox>
          ) : (
            <>
              <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                <EventGridListItem px={0} mb={0} mx={'2px'} item={events[0]} />
              </Grid>
              <Hidden smDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[1]} />
                </Grid>
              </Hidden>
              <Hidden mdDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[2]} />
                </Grid>
              </Hidden>
              <Hidden lgDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[3]} />
                </Grid>
              </Hidden>
              <Hidden xlDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[4]} />
                </Grid>
              </Hidden>
            </>
          )}
        </Grid>
      </HubConnectionProvider>
    </Box>
  );
}
