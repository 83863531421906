import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { KnownUserError } from '@/utils/errors';
import { sendMessageToDevice } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { uploadToGCP } from '@/utils/uploader/gcpUpload';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { DbcFileUploadControl } from './DbcFileUploadControl';

/**
 * @typedef {object} CameraUploadDbcModalProps
 * @property {number} cameraId
 * @property {() => any } onCancel
 */

/** @param {CameraUploadDbcModalProps} props */
export function CameraUploadDbcModal(props) {
  const { cameraId, onCancel } = props;

  /** @type {StateVariable<File>} */
  const [selectedFile, setSelectedFile] = useState(null);
  /** @type {StateVariable<AbortController>} */
  const [loading, setLoading] = useState(null);
  /** @type {StateVariable<number>} */
  const [percentage, setPercentage] = useState(0);

  const handleClose = () => {
    loading?.abort();
    setLoading(null);
    onCancel();
  };

  const handleSubmit = async () => {
    if (!selectedFile || loading) return;
    const aborter = new AbortController();
    setLoading(aborter);
    try {
      const result = await uploadToGCP({
        type: 'DBC',
        id: cameraId,
        file: selectedFile,
        signal: aborter.signal,
        onProgress: setPercentage,
      });
      toastSuccess('Sent DBC file to the Camera');
      const downloadUrl = await result.getDownloadUrl();
      await sendMessageToDevice(
        cameraId,
        MESSAGE_TYPE.DBC_FILE_UPDATE,
        `${downloadUrl},maker,model,year,source`
      );
    } catch (err) {
      if (err instanceof KnownUserError) {
        toastWarning(err.message);
      } else {
        console.error('DBC file upload error', err);
      }
    } finally {
      setLoading(null);
      onCancel();
    }
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="sm" fullWidth keepMounted>
      <DialogTitle display="flex" justifyContent="space-between">
        <Box>Upload DBC File</Box>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!loading ? (
          <DbcFileUploadControl value={selectedFile} onChange={setSelectedFile} />
        ) : (
          <>
            <Box position="relative" display="flex" justifyContent="center" mt="30px">
              <CircularProgress
                variant={percentage ? 'determinate' : 'indeterminate'}
                value={percentage}
                color="secondary"
                size="100px"
                style={{
                  borderRadius: '50%',
                  border: percentage ? '1px dotted #bfcbdb' : null,
                }}
              />
              <Box
                top={0}
                left={0}
                right={0}
                bottom={0}
                position={'absolute'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Typography variant="caption" component="div" color="text.secondary">
                  {`${Math.round(percentage)}%`}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" mt="30px">
              <Typography variant="body2">Uploading file...</Typography>
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={Boolean(loading) || !selectedFile}
        >
          {loading && <CircularProgress size={16} sx={{ color: 'grey', mr: 1 }} />} Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
