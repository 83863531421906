import { CustomInput } from '@/web/@components/CustomForm';
import { Grid, Typography } from '@mui/material';

export function PostBufferSettingsForm(props) {
  const { inheritance, defaultValue } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={2}>
        <Typography variant="subtitle2">Buffer (Post)</Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <CustomInput
          name="postBuffer"
          label=""
          type="number"
          placeholder="Enter post buffer"
          InputProps={{
            endAdornment: (
              <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
            ),
          }}
          TextFieldProps={{
            helperText: `Default Buffer (Post) ${defaultValue} sec`,
          }}
        />
      </Grid>
      <Grid item xs={12} md={3} marginTop={'-25px'}>
        {inheritance('postBuffer')}
      </Grid>
    </Grid>
  );
}
