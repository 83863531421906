import api from '@/api';
import { STATUS_LIST } from '@/assets/constants/table';
import { T } from '@/assets/locales';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/ai/ai-models/actions';
import { AI_MODELS_TABLE_COLUMNS } from '@/web/administration/ai/ai-models/columns';
import { Box } from '@mui/material';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

export function AiModelListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const {
    result = [],
    loading,
    clear: clearResult,
    retry: fetchAIModels,
  } = useFutureLoader(
    async ({ tenantId, secretToken }) => {
      const limit = 100;
      /** @type {Array<AIModelV2WithAccelerator>} */
      const results = [];
      let continuationToken = null;
      while (true) {
        const request = api.ac.v2.repository.aimodel.assign.$get({
          params: {
            pageSize: limit,
            secretToken,
            tenantId,
            continuationToken,
          },
        });
        const result = await request.process();
        results.push(...result.data);
        continuationToken = result.continuationToken;
        if (!continuationToken || result.data.length < limit) break;
      }
      return results;
    },
    [],
    { cache: true }
  );

  useEffect(() => {
    clearResult();
  }, [tenantId, clearResult]);

  const models = useMemo(
    () =>
      (result || [])
        .filter((x) => [STATUS_LIST.RELEASED, STATUS_LIST.DRAFT].includes(x.publicationStatus))
        .sort((a, b) => {
          return (
            -a.publicationStatus.localeCompare(b.publicationStatus) ||
            a.name.localeCompare(b.name) ||
            -a.version.localeCompare(b.version)
          );
        }),
    [result]
  );

  /** @param {Array<AIModelV2WithAccelerator>} selectedItems */
  const deleteAIModels = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteAIModel));
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<AIModelV2WithAccelerator>['onAction']} */
  const onActionHandle = async (type, selectedItems) => {
    if (type === 'DISCONTINUE') {
      await deleteAIModels(selectedItems);
    }
    if (type === 'RELEASE') {
      await releaseAIModel(selectedItems[0]);
    }
    fetchAIModels();
  };

  /** @param {AIModelV2WithAccelerator} model  */
  const releaseAIModel = async (model) => {
    try {
      const request = api.ac.v2.repository.aimodel.release.$post({
        params: {
          secretToken,
          tenantId,
          modelId: model.modelId,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully Released ${model.name}`);
    } catch (e) {
      toastWarning('Error', `Failed to release ${model.name}`);
    }
  };

  /** @param {AIModelV2WithAccelerator} model  */
  const deleteAIModel = async (model) => {
    try {
      const request = api.ac.v2.repository.aimodel.discontinue.$post({
        params: {
          secretToken,
          tenantId,
          modelId: model.modelId,
        },
      });
      await request.process();
      toastSuccess('Success', `Successfully discontinued ${model.name}`);
    } catch (e) {
      toastWarning('Error', `Failed to discontinue ${model.name}`);
    }
  };

  return (
    <Box mx={2.5} mt={2} mb={5}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={T['ai.models.name']}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <ContinuationTokenTable
          results={models}
          loading={loading}
          columns={AI_MODELS_TABLE_COLUMNS}
          onReload={() => fetchAIModels()}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
