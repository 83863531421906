import { createSelector } from '@reduxjs/toolkit';
import { store } from '..';

/** @param {StoreState} state */
export const selectAuth = (state) => state.auth;

export const selectRememberAccount = createSelector(selectAuth, (auth) => auth.remember);
export const selectAccountToken = createSelector(selectAuth, (auth) => auth.accountToken);
export const selectMainRefreshToken = createSelector(selectAuth, (auth) => auth.refreshToken);
export const selectMainAccessToken = createSelector(selectAuth, (auth) => auth.accessToken);
export const selectMainTokenExpiry = createSelector(selectAuth, (auth) => auth.accessTokenExpiry);
export const selectTenantList = createSelector(selectAuth, (auth) => auth.tenantList || []);
export const selectTenantTree = createSelector(selectAuth, (auth) => auth.tenantTree);

export const selectUserAvatar = createSelector(selectAuth, (auth) => auth.avatar);
export const selectMainUserId = createSelector(selectAuth, (auth) => auth.userId);
export const selectSupportUserId = createSelector(selectAuth, (auth) => auth.supportUserId);
export const selectUserEmail = createSelector(selectAuth, (auth) => auth.email);
export const selectTeamList = createSelector(selectAuth, (auth) => auth.teamList);
export const selectUserRole = createSelector(selectAuth, (auth) => auth.role);

export const selectUserAccessibleFeatures = createSelector(
  selectAuth,
  (auth) => auth.enabledFeatures
);
export const selectIsTokenRefreshing = createSelector(selectAuth, (auth) => auth.tokenRefreshing);

export const isLoggedIn = createSelector(selectAuth, (auth) => Boolean(auth.accountToken));
export const selectMainTenantId = createSelector(selectAuth, (auth) => auth.tenantId);
export const selectSupportTenantId = createSelector(selectAuth, (auth) => auth.supportTenantId);
export const selectSupportPath = createSelector(selectAuth, (auth) => auth.supportPath);

export const selectAuthenticationType = createSelector(
  selectAuth,
  (auth) => auth.request.authenticationType
);

export const selectIsSupport = createSelector(
  selectSupportPath,
  (supportPath) => supportPath && supportPath.length > 1
);
export const selectSupportAccessToken = createSelector(
  selectAuth,
  (auth) => auth.supportAccessToken
);
export const selectSupportRefreshToken = createSelector(
  selectAuth,
  (auth) => auth.supportRefreshToken
);
export const isTenantLoggedIn = createSelector(selectAuth, (auth) =>
  Boolean(auth.tenantId && auth.accessToken)
);
export const selectSecretToken = createSelector(
  selectMainAccessToken,
  selectSupportAccessToken,
  selectIsSupport,
  (mainToken, supportToken, support) => (support ? supportToken : mainToken)
);
export const selectRefreshToken = createSelector(
  selectMainRefreshToken,
  selectSupportRefreshToken,
  selectIsSupport,
  (mainToken, supportToken, support) => (support ? supportToken : mainToken)
);
export const selectUserId = createSelector(
  selectMainUserId,
  selectSupportUserId,
  selectIsSupport,
  (mainUserId, supportUserId, support) => (support ? supportUserId : mainUserId)
);
export const selectTenantId = createSelector(
  selectMainTenantId,
  selectSupportTenantId,
  selectIsSupport,
  (mainTenantId, supportTenantId, support) => (support ? supportTenantId : mainTenantId)
);
export const selectCurrentTenantTree = createSelector(
  selectMainTenantId,
  selectTenantTree,
  selectTenantList,
  (mainTenantId, tenantTree, tenantList) => {
    return (
      (tenantTree && tenantTree[mainTenantId]) ||
      tenantList.find((x) => x.tenantId === mainTenantId)
    );
  }
);
export const selectMainTenantName = createSelector(
  selectTenantId,
  selectTenantList,
  (tenantId, tenantList) => {
    const tenant = tenantList?.find((tenant) => tenant?.tenantId === tenantId);
    return tenant?.tenantName || 'Smarter AI Tenant';
  }
);

export const selectCurrentTenantName = createSelector(
  selectTenantId,
  selectTenantList,
  selectCurrentTenantTree,
  selectIsSupport,
  (tenantId, tenantList, currentTenantTree, support) => {
    const tenant = tenantList?.find((tenant) => tenant?.tenantId === tenantId);
    if (!support || tenant) {
      return tenant?.tenantName || 'Smarter AI Tenant';
    }
    function recur(/** @type {DescendantTenant} */ root) {
      if (!root) return;
      if (root?.tenantId === tenantId) {
        return root?.tenantName || 'Smarter AI Tenant';
      }
      for (const item of root?.descendantTenantList || []) {
        const name = recur(item);
        if (name) return name;
      }
    }
    return recur(currentTenantTree) || 'Smarter AI Tenant';
  }
);

/**
 * @param {string} tenantId
 * @param {DescendantTenant} [root]
 * @returns {{tenantId: string; tenantName: string; path: string[]}}
 */
export function findTenant(tenantId, root = null) {
  if (!tenantId) return undefined;
  const state = store.getState();
  if (!root) {
    for (const tenant of selectTenantList(state) || []) {
      if (tenant.tenantId === tenantId) {
        return {
          path: [tenant.tenantId],
          tenantId: tenant.tenantId,
          tenantName: tenant.tenantName,
        };
      }
    }
    for (const tenant of Object.values(selectTenantTree(state))) {
      const result = findTenant(tenantId, tenant);
      if (!result) continue;
      return {
        ...result,
        path: [tenant.tenantId, ...result.path],
      };
    }
  } else {
    for (const tenant of root.descendantTenantList) {
      if (tenant.tenantId === tenantId) {
        return {
          path: [tenant.tenantId],
          tenantId: tenant.tenantId,
          tenantName: tenant.tenantName,
        };
      }
      if (tenant.descendantTenantList) {
        const result = findTenant(tenantId, tenant);
        if (!result) continue;
        return {
          ...result,
          path: [tenant.tenantId, ...result.path],
        };
      }
    }
  }
  return undefined;
}
