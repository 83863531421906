import api from '@/api';
import { useDashcamPlayer } from '@/hooks/useDashcamPlayer';
import {
  selectIsSupport,
  selectRefreshToken,
  selectSecretToken,
  selectTenantId,
} from '@/store/auth';
import { SmartCache } from '@/utils/caching/smart-cache';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { ConfirmationDialog } from '@/web/@components/ConfirmationDialog';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CoachingEndpointDetailsContext } from '../../@context/EndpointDetailsContext';
import { CoachingInfoSection } from '../pending/CoachingInfo';
import { CoachingCommentArea } from './CoachingCommentArea';
import { CoachingPlayerEvents } from './CoachingPlayerEvents';

/** @type {SmartCache<{endpointId: number, tenantId: string, triggerName: string}>} */
const eventCache = new SmartCache('event-player-info-cache', 365 * 24 * 3600 * 1000);

export function GuidedCoachingPage() {
  /** @type {import('react').Ref<HTMLDivElement>} */
  const parentRef = useRef();
  const navigate = useNavigate();
  const player = useDashcamPlayer();

  const { selectedEvent, markVisited, currentSessionId, loading, currentSessionInfo, endpoint } =
    useContext(CoachingEndpointDetailsContext);

  const { setBreadcrumbTitle } = useContext(MainContext) || {};

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const support = useSelector(selectIsSupport);
  const accessToken = useSelector(selectSecretToken);
  const refreshToken = useSelector(selectRefreshToken);
  const endpointId = useMemo(() => selectedEvent?.endpointId, [selectedEvent]);
  const triggerName = useMemo(() => selectedEvent?.triggerName, [selectedEvent]);
  const deviceLabel = useMemo(() => endpoint?.label, [endpoint?.label]);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!deviceLabel) return;
    setBreadcrumbTitle(`${deviceLabel}`);
  }, [deviceLabel, setBreadcrumbTitle]);

  useEffect(() => {
    if (!selectedEvent) return;
    eventCache.setItem(selectedEvent.id, { tenantId, endpointId, triggerName });
  }, [selectedEvent, tenantId, endpointId, triggerName]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('tenant-id', tenantId);
    if (support) {
      player.setAttribute('support', 'on');
    } else {
      player.removeAttribute('support');
    }
    player.setAttribute('refresh-token', refreshToken);
    player.setAttribute('access-token', accessToken);
  }, [player, support, tenantId, refreshToken, accessToken]);

  useEffect(() => {
    if (!player || !selectedEvent) return;
    player.setAttribute('event-id', selectedEvent.id + '');
  }, [player, selectedEvent]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('show-trigger-report', 'on');
    player.setAttribute('custom-report-area', 'on');
    player.setAttribute('hide-annotation', 'on');
  }, [player]);

  useEffect(() => {
    if (!player) return;
    const aborter = new AbortController();
    player.addEventListener(
      'watch-complete',
      () => markVisited(player.getAttribute('event-id')),
      aborter
    );
    return () => aborter.abort();
  }, [player, markVisited]);

  useEffect(() => {
    if (!player || !selectedEvent) return;
    parentRef?.current?.replaceChildren(player);
    // store.dispatch(PageView.setHideToolbar(true));
    // store.dispatch(PageView.setFullWidthLayout(true));
  }, [selectedEvent, refreshToken, accessToken, player]);

  const handleFinishWatching = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirmation = () => {
    const request = api.ac.v5.coaching.sessions.$sessionId(currentSessionId).$patch({
      headers: {
        Authorization: secretToken,
      },
      params: {
        type: 'CONCLUDE',
      },
    });
    request
      ?.process()
      ?.then((data) => {
        toastSuccess('Success', 'Coaching successfully completed.');
        navigate('/safety/coaching');
      })
      .catch((err) => {
        toastWarning('Error', 'Failed to finish this coaching session.');
        console.error(err);
      })
      .finally(() => {
        setDialogOpen(false);
      });
  };

  return (
    <>
      <ConfirmationDialog
        open={dialogOpen}
        message={'Are you sure you want to finish the coaching session?'}
        onCancel={handleDialogClose}
        onApply={handleDialogConfirmation}
      />

      <CoachingInfoSection
        sessionId={currentSessionId}
        coachingInfo={currentSessionInfo}
        loading={loading}
        isGuided={true}
        eventId={selectedEvent?.id}
      />

      {player && <CoachingCommentArea player={player} onFinish={handleFinishWatching} />}

      {selectedEvent && player && <CoachingPlayerEvents player={player} />}

      {loading ? (
        <CenterBox>
          <CircularProgress />
        </CenterBox>
      ) : !loading && !currentSessionId ? (
        <CenterBox>
          <IconMessageBox
            size="150px"
            src="/images/empty/no-vehicles.svg"
            message="No coaching sessions have been initiated to start!"
          />
        </CenterBox>
      ) : (
        <Box ref={parentRef} width="100%" height={{ xs: '100%', xl: '89%' }} p={1}>
          <CenterBox>
            <CircularProgress />
            <Typography variant="subtitle2" fontSize="12px" mt={1}>
              Verifying access
            </Typography>
          </CenterBox>
        </Box>
      )}
    </>
  );
}
