import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CAMERA_DIAGNOSTICS_LOG_TABLE_COLUMNS } from './columns';

export function DiagnosticsLogsTable() {
  const { cameraId } = useContext(CameraDetailsContext);
  const tenantId = useSelector(selectTenantId);
  const { searchText, searchType, startTime, endTime } = useContext(FilterContext);

  const timestamps = {
    fromTimestamp: startTime,
    toTimestamp: endTime,
  };

  return (
    <Box height="90%">
      <PaginatedTableContextProvider>
        <InfiniteScrollView
          key={generateUniqueKey([tenantId, cameraId, searchText, searchType, startTime, endTime])}
          initialToken={''}
          fullView={false}
          itemsPerPage={50}
          fetcher={async ({ signal, limit, token: offset }) => {
            const secretToken = selectSecretToken(store.getState());
            const request = api.ac.v3.report.device.diagnostics.search.$get({
              signal,
              // @ts-ignore
              params: {
                secretToken,
                pageSize: limit,
                endpointId: cameraId,
                tenantId,
                offset: offset || 0,
                module: !searchType || searchType === 'All' ? '' : searchType,
                //severity: 'ALL',
                ...(searchText && { searchText }),
                ...(startTime && timestamps),
              },
            });

            await request.process();
            const result = request.result.diagnosticReportList;
            const mappedData = result.map((item) => ({
              ...item,
              ...item.properties,
            }));
            const token = result.length < limit ? 0 : Number(offset) + limit;
            return { result: mappedData, token };
          }}
          // mergeResults={(prev, next) => sortBy([...prev, ...next], 'reportingTimestamp').reverse()}
          renderEmpty={() => (
            <IconMessageBox
              size="256px"
              src="/images/player/no-data-icon.svg"
              message="No Data Found"
            />
          )}
          renderList={(state) => (
            <InfiniteScrollTableView
              state={state}
              columns={CAMERA_DIAGNOSTICS_LOG_TABLE_COLUMNS}
              defaultSortKey="reportingTimestamp"
              sx={{ height: 'calc(100vh - 200px)' }}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
