import { AI_CONTAINER_AI_MODEL_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { Autocomplete, TextField } from '@mui/material';
import { useContext } from 'react';
import { AiContainerDetailsContext } from '../AiContainerDetailsLayout';

/**
 * @typedef {object} SelectAiModelControlProps
 * @property {boolean} [loading]
 * @property {boolean} [disabled]
 * @property {string} [componentId]
 * @property {AIModelV2WithAccelerator} value
 * @property {Array<AIModelV2WithAccelerator>} options
 * @property {(value: AIModelV2WithAccelerator) => any} onChange
 */

/** @param {SelectAiModelControlProps} props */
export function SelectAiModelControl(props) {
  const { loading, value, componentId, options, disabled, onChange } = props;
  const { models: components } = useContext(AiContainerDetailsContext);
  return (
    <Autocomplete
      fullWidth
      handleHomeEndKeys
      inputMode="search"
      loading={loading}
      options={options || []}
      value={value}
      disabled={disabled}
      disableClearable
      openOnFocus
      id="select-ai-model"
      onChange={(e, val) => onChange(typeof val === 'string' ? null : val)}
      isOptionEqualToValue={(option, value) => option?.modelId === value?.modelId}
      getOptionDisabled={(option) =>
        Boolean(
          components.find((x) => x.modelId === option.modelId && x.inputModelId === componentId)
        )
      }
      getOptionLabel={(item) => {
        if (typeof item === 'string') return item;
        const parts = [`${item.name} (${item.version})`];
        if (item.aiAcceleratorList?.length) {
          parts.push(`[${item.aiAcceleratorList[0].vendor}]`);
        }
        return parts.join(' ');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            placeholder: 'Select AI Model',
            startAdornment: <BoxImage src={AI_CONTAINER_AI_MODEL_ICON} size="24px" />,
          }}
        />
      )}
      sx={{
        '& .MuiInputBase-root': {
          'p': '6px 12px',
          'borderRadius': '0',
          'background': '#FFFFFF',
          'boxShadow': '0px 0px 10px #608AC30F',
          '&:hover': {
            boxShadow: '0px 0px 10px #608AC30F',
          },
          '& input': {
            pr: '18px !important',
          },
        },
      }}
    />
  );
}
