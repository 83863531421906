import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DevicePoolForm } from '../@components/DevicePoolForm';

export function CreateDevicePoolPage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/DevicePoolForm').DevicePoolFormProps['onSubmitData']} */
  const createPool = async (form) => {
    try {
      setLoading(true);
      const req = api.ac.v1.endpoint.devicepools.$post({
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          devicePoolName: form.name,
        },
      });
      await req.process();
      toastSuccess('Success', t(T['device-pool.create.success'], { name: form.name }));
      navigate('/administration/device-pools');
    } catch (err) {
      toastWarning('Error', t(T['device-pool.create.retry']));
    } finally {
      setLoading(false);
    }
    return;
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['device-pool.create.page.title'])}
      </Typography>
      <DevicePoolForm onSubmitData={createPool} loading={loading} />
    </Box>
  );
}
