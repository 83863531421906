import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const triggerSettings = [
  {
    title: 'Trigger',
    key: GROUP_SETTINGS_CATEGORIES.TRIGGER,
    children: [
      {
        label: 'Enable Plugins Test Mode',
        key: 'triggersPluginsTestMode',
        type: FROM_TYPES.SWITCH,
      },
    ],
  },
];
