import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { selectTenantId } from '@/store/auth';
import { ErrorBoundary } from '@/web/@components/ErrorBoundary';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * @typedef {object} DeviceGroupDetailsContextData
 * @property {GroupV2} group
 * @property {boolean} loading
 * @property {Error} error
 * @property {number} groupId
 */

/** @type {import("react").Context<DeviceGroupDetailsContextData>} */
export const DeviceGroupDetailsContext = createContext(null);

export function DeviceGroupDetailsLayout() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const tenantId = useSelector(selectTenantId);
  const { setBreadcrumbTitle } = useContext(MainContext);

  const [initialTenantId] = useState(tenantId);

  const groupId = useMemo(() => Number(params.id), [params]);

  const {
    result: group,
    loading,
    error,
  } = useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.endpoint.group.$get({
        signal,
        params: {
          groupId,
          secretToken,
        },
      });
      await request.process();
      const group = request.result?.group;
      setBreadcrumbTitle(group.name, (path) =>
        path.includes('device-groups')
          ? path === `/administration/device-groups/${groupId}`
          : path === `/smarter-ai/scheme-management/schemes/${groupId}`
      );
      return group || {};
    },
    [groupId, setBreadcrumbTitle],
    { cache: false }
  );

  useEffect(() => {
    if (initialTenantId !== tenantId) {
      navigate('../');
    }
  }, [navigate, initialTenantId, tenantId, group]);

  return (
    <DeviceGroupDetailsContext.Provider value={{ group, groupId, loading, error }}>
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
    </DeviceGroupDetailsContext.Provider>
  );
}
