import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiContainerBasicForm } from '../../@components/AiContainerBasicForm';
import { AiContainerComponents } from '../../@components/AiContainerComponents';
import { AiContainerDetailsContext } from '../../@components/AiContainerDetailsLayout';

export function AiContainerEditPage() {
  const navigate = useNavigate();
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { container, product, refreshContainer: refresh } = useContext(AiContainerDetailsContext);

  const [loading, setLoading] = useState(false);

  /** @type {import('../../@components/AiContainerBasicForm').AiContainerBasicFormProps['onSubmit']} */
  const handleSubmit = async (form) => {
    if (!form) {
      navigate('/administration/ai/ai-containers');
      return;
    }
    try {
      setLoading(true);
      const request = api.ac.v2.repository.aicontainer.$put({
        data: {
          name: form.name,
          productId: container.productId,
          containerId: container.containerId,
          description: container.description,
          type: container.type,
          properties: container.properties,
          ownerTenantId: container.ownerTenantId,
          tenantList: [{ tenantRole: 'Owner', tenantId }],
          versionNumber: container?.versionNumber,
        },
        params: {
          secretToken,
          forceUpdate: false,
          oldType: container.type,
          oldVersion: container.versionNumber,
          oldOwnerTenantId: container.ownerTenantId,
        },
      });
      await request.process();
      toastSuccess('Success', 'Saved AI Container');
      setLoading(false);
      refresh();
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastWarning('Failed to save AI Container');
    }
  };

  // Navigate Ai Containers list page while tenant change
  useEffect(() => {
    return () => navigate('/administration/ai/ai-containers');
  }, [tenantId, navigate]);

  return (
    <Box p={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'} mb={1.5}>
        Edit AI Container
      </Typography>
      <AiContainerBasicForm
        container={container}
        product={product}
        onSubmit={handleSubmit}
        disabled={loading}
      />
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'} mt={2} mb={1.5}>
        AI Container Components
      </Typography>
      <AiContainerComponents />
    </Box>
  );
}
