import { AI_MODEL_OUTPUT_NAME, AI_MODEL_OUTPUT_TYPE } from '@/assets/ai/ai-model';

/** @type {Array<AIModelForm>} */
export const AI_MODEL_OUTPUT_SOURCE = [
  {
    name: 'outputType',
    label: 'Output Type *',
    type: 'select',
    mandatory: true,
    placeholder: 'Select a type',
    options: AI_MODEL_OUTPUT_TYPE,
  },
  {
    name: 'outputName',
    label: 'Output Name *',
    mandatory: true,
    type: 'select',
    placeholder: 'Select a name',
    options: AI_MODEL_OUTPUT_NAME,
  },
  {
    name: 'outputCode',
    label: 'Output Code',
    type: 'input',
    disabled: true,
  },
];
