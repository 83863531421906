import { DELETE_ICON } from '@/assets/constants/images';
import { parseCSV } from '@/utils/csv';
import { BoxImage } from '@/web/@components/BoxImage';
import { CustomInput } from '@/web/@components/CustomForm';
import { AddOutlined, FileUpload } from '@mui/icons-material';
import { Box, Button, Card, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} CreateDeviceInformationProps
 * @property {boolean} [disabled]
 */

/** @param {CreateDeviceInformationProps} props */
export function CreateDeviceInformation(props) {
  const { control } = useFormContext();
  const { fields, remove, append, prepend } = useFieldArray({
    control,
    name: 'devices',
  });

  // const batch = useWatch({ name: 'batch', control });
  // const skuNumber = useWatch({ name: 'skuNumber', control });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const parsedData = parseCSV(csvData);
        parsedData?.forEach((item) => {
          append({ batch: '', deviceId: item?.PSN, imei: item?.IMEI1, skuNumber: '' });
        });
      };
      reader.readAsText(file);
    }
  };

  // useEffect(() => {
  //   if (!batch) return;
  //   fields.forEach((item, index) => {
  //     setValue(`devices[${index}].batch`, batch);
  //   });
  // }, [fields, setValue, batch]);

  // useEffect(() => {
  //   if (!skuNumber) return;
  //   fields.forEach((item, index) => {
  //     setValue(`devices[${index}].skuNumber`, skuNumber);
  //   });
  // }, [fields, setValue, skuNumber]);

  return (
    <Card sx={{ m: '15px' }}>
      <Box display="flex" justifyContent="space-between" m="10px">
        <Tooltip title={`Device Count: ${fields?.length}`} arrow followCursor>
          <Typography variant="body2">Device Information</Typography>
        </Tooltip>

        <Box display="flex" gap={1}>
          <Button size="small" component="label" variant="outlined" startIcon={<FileUpload />}>
            Import CSV
            <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
          </Button>
          <Button
            size="small"
            variant="outlined"
            startIcon={<AddOutlined />}
            onClick={() => prepend({ batch: '', deviceId: '', imei: '', skuNumber: '' })}
          >
            Add New Device
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box
        display="grid"
        gridTemplateColumns={{ md: '1fr 1fr .15fr', sm: '1fr' }}
        gap="10px"
        px="10px"
        pb="20px"
        maxHeight="65vh"
        overflow="auto"
      >
        {fields?.map((item, index) => (
          <Fragment key={item?.id}>
            <CustomInput
              label="Device Id *"
              placeholder="Enter a Device Id"
              name={`devices[${index}].deviceId`}
              rules={{ required: 'Please provide a valid device Id' }}
              InputProps={{
                size: 'small',
              }}
            />

            <CustomInput
              label="IMEI Number(s) *"
              placeholder="Enter IMEIs"
              name={`devices[${index}].imei`}
              infoText="Add multiple items, separated by commas."
              rules={{ required: 'Please provide valid device IMEI' }}
              InputProps={{
                multiline: true,
                size: 'small',
              }}
            />

            <IconButton
              disabled={fields.length <= 1}
              onClick={() => remove(index)}
              color="error"
              sx={{
                borderRadius: '50%',
                border: '1px solid #596A81',
                width: '30px',
                height: '30px',
                lineHeight: '38px',
                mt: '27px',
              }}
            >
              <BoxImage src={DELETE_ICON} size="20px" />
            </IconButton>
          </Fragment>
        ))}
      </Box>
    </Card>
  );
}
