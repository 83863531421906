import api from '@/api';
import { DASHCAM_PLAYER_CSS, DASHCAM_PLAYER_JS, SENTRY_DSN, isDev } from '@/config';
import { resetConsole } from '@/utils/logger';
import { toastInfo } from '@/utils/toaster';
import 'c3/c3.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export async function loadDashcamPlayerScript() {
  return new Promise((resolve, reject) => {
    try {
      //  dashcam-player script
      if (!document.head.querySelector(`script[src="${DASHCAM_PLAYER_JS}"]`)) {
        const script = document.createElement('script');
        script.src = DASHCAM_PLAYER_JS;
        script.async = true;
        script.defer = true;
        script.addEventListener('load', resolve);
        script.addEventListener('error', reject);
        document.head.appendChild(script);
      } else {
        resolve();
      }

      // dashcam-player style
      if (!document.head.querySelector(`link[href="${DASHCAM_PLAYER_CSS}"]`)) {
        const link = document.createElement('link');
        link.setAttribute('type', 'text/css');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('href', DASHCAM_PLAYER_CSS);
        document.head.appendChild(link);
      }
    } catch (err) {
      reject(err);
    }
  });
}

/** @param {'dashcam-player'|'face-registration'} [element] */
export function useDashcamPlayer(element = 'dashcam-player') {
  const navigate = useNavigate();

  /** @type {StateVariable<HTMLElement>} */
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    resetConsole();
    loadDashcamPlayerScript().then(() => {
      // add the component
      const player = document.createElement(element);

      // set attributes
      if (isDev) {
        player.setAttribute('powerlevel', '10k');
      }
      player.setAttribute('sentry-dsn', SENTRY_DSN);
      player.setAttribute('api-url', api.ac.$baseURL);
      player.setAttribute('base-url', window.location.origin);
      player.setAttribute('disable-auto-wake', 'on');
      player.style.overflow = 'hidden scroll';

      // notify current instance
      setPlayer(player);
    });
  }, [element]);

  useEffect(() => {
    if (!player) return;
    /** @param {MouseEvent & InputEvent & {error: any}} e */
    const handleClick = (e) => {
      e.stopPropagation();
      if (e.error) {
        toastInfo(e.error);
      }
      const url = `/cameras/${e.data}`;
      if (e.ctrlKey || e.metaKey) {
        window.open(url, '_blank').focus();
      } else {
        navigate(url);
      }
    };
    player.addEventListener('camera-click', handleClick);
    return () => player.removeEventListener('camera-click', handleClick);
  }, [player, navigate]);

  useEffect(() => {
    if (!player) return;
    /** @param {MouseEvent & InputEvent} e */
    const handleClick = (e) => {
      e.stopPropagation();
      const url = `/events/play?id=${e.data}`;
      if (e.ctrlKey || e.metaKey) {
        window.open(url, '_blank').focus();
      } else {
        navigate(url);
      }
    };
    player.addEventListener('event-click', handleClick);
    return () => player.removeEventListener('event-click', handleClick);
  }, [player, navigate]);

  return player;
}
