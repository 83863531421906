import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Parse and return the search string as object from current route
 */
export function useRouteQuery() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = qs.parse(location.search.substring(1));

  /**
   * @type {(params: {[key: string]: any}, replace?: boolean) => any}
   */
  const updateQuery = (params, replace = false) => {
    if (!replace) {
      params = { ...query, ...params };
    }
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }
    const nextQuery = qs.stringify(params);
    if (nextQuery === location.search) {
      return;
    }
    navigate(
      {
        ...location,
        search: nextQuery,
      },
      {
        replace: false,
      }
    );
  };

  return { query, updateQuery };
}
