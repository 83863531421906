import { throttle } from 'lodash';
import { IntervalTree } from './IntervalTree';
import { OrderedMap } from './ordered-map';

/**
 * @typedef {object} IntervalTrackerNode
 * @property {number} startTime
 * @property {number} endTime
 * @property {AbortSignal} [signal]
 * @property {IntervalTrackerWatcherCallback} [callback]
 */

/**
 * @typedef {(entries: number) => any} IntervalTrackerWatcherCallback
 */

/**
 * @typedef {object} IntervalNodeForWatcher
 * @property {AbortSignal} [signal]
 * @property {IntervalTrackerWatcherCallback} [callback]
 */

export class IntervalWatcher {
  /** @type {OrderedMap<number, number>} */
  #queue = new OrderedMap();
  /** @type {IntervalTree<IntervalTrackerNode>} */
  #watchers = new IntervalTree();

  /**
   * Watch changes for entries. O(n)
   * @param {number} startTime
   * @param {number} endTime
   * @param {IntervalTrackerWatcherCallback} callback
   * @param {AbortSignal} [signal]
   */
  watch(startTime, endTime, callback, signal) {
    if (startTime > endTime || !callback || !signal) return;
    const item = { startTime, endTime, callback, signal };
    this.#watchers.insert([startTime, endTime], item);
    signal?.addEventListener('abort', () => {
      this.#watchers.remove([startTime, endTime], item);
    });
  }

  /**
   * Internal method to invoke all watchers. O(n^2)
   * @param {number} startTime
   * @param {number} endTime
   */
  $invoke = (startTime, endTime) => {
    this.#queue.set(startTime, endTime, (x) => Math.max(x, endTime));
    this.#doJob();
  };

  #doJob = throttle(() => {
    const entries = this.#queue.entries;
    this.#queue.clear();

    const combined = [];
    for (let i = 0; i < entries.length; ++i) {
      const current = entries[i];
      const last = combined[combined.length - 1];
      if (last && current[0] <= last[1] + 1) {
        last[1] = Math.max(last[1], current[1]);
      } else {
        combined.push(current);
      }
    }

    for (const range of combined) {
      const nodes = this.#watchers.search(range);
      for (const node of nodes) {
        try {
          if (node.signal.aborted) continue;
          node.callback(Date.now());
        } catch (err) {
          console.debug('Interval watcher callback error', err);
        }
      }
    }
  }, 100);
}
