import { EventListPlaceholder } from '@/web/@components/PlayerEventList/EventListPlaceholder';
import { EventGridListItem } from '@/web/events/@components/EventGridListItem';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { CoachingEndpointDetailsContext } from '../../@context/EndpointDetailsContext';

/** @param {{player: HTMLElement}} props */
export function CoachingPlayerEvents(props) {
  const { player } = props;
  const { loading, visited, events, startIndex, updateSelection } = useContext(
    CoachingEndpointDetailsContext
  );

  /** @type {StateVariable<number>} */
  const [itemWidth, setItemWidth] = useState(null);
  /** @type {StateVariable<number>} */
  const [itemsPerRow, setItemsPerRow] = useState(null);
  /** @type {StateVariable<Element>} */
  const [sidebar, setSidebar] = useState();

  useEffect(() => {
    let lastUpdate = 0;
    const aborter = new AbortController();
    const signal = aborter.signal;
    const update = () => {
      const el = player.querySelector('.player-sidebar');
      setSidebar((v) => {
        if (v !== el) lastUpdate = 0;
        return el;
      });
      if (!el) return;
      const config = player['eventListItemConfig'] || {};
      setItemWidth(config.itemWidth);
      setItemsPerRow(config.itemsPerRow || 1);
      if (Date.now() - lastUpdate < 30000) return;
      lastUpdate = Date.now();
    };
    const iid = setInterval(update, 500);
    player.addEventListener('view-change', update, { signal });
    player.addEventListener('load', update, { signal });
    return () => {
      aborter.abort();
      clearInterval(iid);
    };
  }, [player]);

  useEffect(() => {
    if (loading || events?.length) {
      player.setAttribute('show-sidebar', 'on');
    } else {
      player.removeAttribute('show-sidebar');
      setSidebar(null);
    }
  }, [player, loading, events]);

  if (!sidebar) return null;

  if (loading) {
    return createPortal(
      <EventListPlaceholder width={itemWidth || '100%'} itemsPerRow={itemsPerRow} />,
      sidebar
    );
  }

  return createPortal(
    <>
      {events.map((event, i) => (
        <Box
          key={event?.id}
          onClick={(e) => {
            e.stopPropagation();
            updateSelection(i);
          }}
          p={1}
          borderRadius="7px"
          width={itemWidth || '100%'}
          bgcolor={visited[event.id] ? '#F4F7FC' : '#fff'}
          border={startIndex === i ? '2px solid #C5D9F0' : undefined}
          boxShadow={startIndex === i ? '0 0 10px rgba(0,0,0,0.2)' : undefined}
        >
          <EventGridListItem key={event.id + i} item={event} width={'100%'} disabled />
        </Box>
      ))}
    </>,
    sidebar
  );
}
