import { handleKeyPress, handleOnPaste } from '@/utils/input';
import { Box, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * @typedef {object} GeoSelectionProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {GeoSelectionProps} props */
export function GeoSelection(props) {
  const { item } = props;

  const { getValues, setValue } = useFormContext();

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [radius, setRadius] = useState('');

  const initValues = useMemo(() => getValues(item.key), [item.key, getValues]);

  useEffect(() => {
    const splittedItem = initValues?.split(' ');
    if (splittedItem?.length === 3) {
      if (!splittedItem[0]?.split(':')[1]) return;
      setLatitude(splittedItem[0]?.split(':')[1]);
      setLongitude(splittedItem[1]?.split(':')[1]);
      setRadius(splittedItem[2]?.split(':')[1]);
    }
  }, [initValues, setLatitude, setLongitude, setRadius]);

  useEffect(() => {
    setValue(item.key, `latitude:${latitude} longitude:${longitude} radius:${radius}`);
  }, [latitude, longitude, radius, setValue, item.key]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        flexWrap: 'nowrap',
        width: '100%',
      }}
    >
      <Typography color="#0B2547" fontSize="14px" width="70%">
        {item.label}
      </Typography>
      <Box
        sx={{
          'display': 'flex',
          'flexWrap': 'wrap',
          'width': '100%',
          'gap': '10px',
          'alignItems': 'baseline',
          '& .MuiTextField-root': {
            width: { xs: '100%', lg: '48%' },
          },
        }}
      >
        <TextField
          id="outlined-basic"
          label="Latitude"
          size="small"
          variant="outlined"
          type="number"
          value={latitude}
          onKeyPress={handleKeyPress}
          onPaste={handleOnPaste}
          onChange={(e) => {
            setLatitude(e.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          label="Longitude"
          variant="outlined"
          size="small"
          type="number"
          value={longitude}
          onKeyPress={handleKeyPress}
          onPaste={handleOnPaste}
          onChange={(e) => {
            setLongitude(e.target.value);
          }}
        />
        <TextField
          id="outlined-basic"
          label="Radius"
          variant="outlined"
          size="small"
          type="number"
          value={radius}
          onKeyPress={handleKeyPress}
          onPaste={handleOnPaste}
          onChange={(e) => {
            setRadius(e.target.value);
          }}
        />
      </Box>
    </Box>
  );
}
