/***************************************
 *                                      *
 *                 INPUT                *
 *                                      *
 ***************************************/

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_TYPE_OPTIONS = [
  { value: 'VIDEO', label: 'Video' },
  { value: 'AUDIO', label: 'Audio' },
  { value: 'SENSOR', label: 'Sensor' },
  { value: 'MODEL', label: 'Model' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_MODEL_NAMES = [
  { value: 'Bounding Boxes', label: 'Bounding Boxes' },
  { value: 'Label', label: 'Label' },
  { value: 'Precision', label: 'Precision' },
  { value: 'Image', label: 'Image' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_RESOLUTIONS = [
  { value: 'WH_426x240', label: '426x240 (240p)' },
  { value: '360p', label: '360p' },
  { value: 'WH_640x360', label: '640x360 (360p)' },
  { value: '480p', label: '480p' },
  { value: 'WH_640x480', label: '640x480 (480p)' },
  { value: 'WH_854x480', label: '854x480 (480p)' },
  { value: 'WH_960x540', label: '960x540 (540p)' },
  { value: '720p', label: '720p' },
  { value: 'WH_1280x720', label: '1280x720 (720p)' },
  { value: 'WH_1920x1080', label: '1920x1080 (1080p)' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_COLOR_TYPES = [
  { value: 'grayscale', label: 'Grayscale' },
  { value: 'rgb', label: 'RGB' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_BIT_DEPTH = [
  { value: '8_bit', label: '8-bit' },
  { value: '16_bit', label: '16-bit' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_SAMPLE_RATES = [
  { value: 'Hz_8K', label: '8000 Hz' },
  { value: 'Hz_12K', label: '12000 Hz' },
  { value: 'Hz_16K', label: '16000 Hz' },
  { value: 'Hz_22.05K', label: '22050 Hz' },
  { value: 'Hz_32K', label: '32000 Hz' },
  { value: 'Hz_44.1K', label: '44100 Hz' },
  { value: 'Hz_48K', label: '48000 Hz' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_QUANTIZATION = [
  { value: 'Hz_8K', label: '8000 Hz' },
  { value: 'Hz_12K', label: '12000 Hz' },
  { value: 'Hz_16K', label: '16000 Hz' },
  { value: 'Hz_22.05K', label: '22050 Hz' },
  { value: 'Hz_32K', label: '32000 Hz' },
  { value: 'Hz_44.1K', label: '44100 Hz' },
  { value: 'Hz_48K', label: '48000 Hz' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_CHANNELS = [
  { value: 'stereo', label: 'Stereo' },
  { value: 'mono', label: 'Mono' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_SENSOR_TYPES = [
  { value: 'accelerometer', label: 'Accelerometer' },
  { value: 'gps', label: 'GPS' },
  { value: 'gyrometer', label: 'Gyrometer' },
  { value: 'humidity', label: 'Humidity' },
  { value: 'magnetometer', label: 'Magnetometer' },
  { value: 'pressure', label: 'Pressure' },
  { value: 'temperature', label: 'Temperature' },
  { value: 'thermal_radiation', label: 'Thermal Radiation' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_CODEC_LIST = [
  { value: 'H264', label: 'H264' },
  { value: 'RAW', label: 'RAW' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_AUDIO_TYPES = [
  { value: 'FLOAT', label: 'Float' },
  { value: 'I16', label: 'I16' },
  { value: 'I24', label: 'I24' },
  { value: 'I32', label: 'I32' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_AUDIO_CODEC_LIST = [
  { value: 'PCM', label: 'PCM' },
  { value: 'AAC', label: 'AAC' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_INPUT_SOURCE_SENSOR_UNITS = [
  {
    value: 'celsius',
    label: 'Celsius',
  },
  {
    value: 'RH',
    label: 'Relative Humidity %',
  },
  {
    value: 'hPa',
    label: 'Millibar',
  },
  {
    value: 'm/s2',
    label: 'Meters/sec^2',
  },
  {
    value: 'g',
    label: 'Gravitational force',
  },

  {
    value: 'tesla',
    label: 'Tesla',
  },

  {
    value: 'μm',
    label: 'Micro meter (μm)',
  },
];

/***************************************
 *                                      *
 *                OUTPUT                *
 *                                      *
 ***************************************/

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_OUTPUT_TYPE = [
  { value: 'VIDEO', label: 'Video' },
  { value: 'AUDIO', label: 'Audio' },
  { value: 'SENSOR', label: 'Sensor' },
  { value: 'MODEL', label: 'Model' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_OUTPUT_NAME = [
  { value: 'Bounding Boxes', label: 'Bounding Boxes', field: 'bounding_boxes' },
  { value: 'Label', label: 'Label', field: 'label' },
  { value: 'Precision', label: 'Precision', field: 'precision' },
  { value: 'Image', label: 'Image', field: 'image' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_OUTPUT_CODE = [
  { value: 'bounding_boxes', label: 'bounding_boxes' },
  { value: 'label', label: 'label' },
  { value: 'precision', label: 'precision' },
  { value: 'image', label: 'image' },
];

/***************************************
 *                                      *
 *             MODEL PARAMS             *
 *                                      *
 ***************************************/
/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_PARAMS_TYPES = [
  { value: 'BOOLEAN', label: 'Boolean' },
  { value: 'NUMBER', label: 'Number' },
  { value: 'STRING', label: 'String' },
];

/** @type {Array<AIModelFormOption>} */
export const AI_MODEL_PARAMS_THRESHOLD_BOOLEAN_VALUES = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
];
