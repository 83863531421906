import { trimString } from '@/utils/formatting';
import { CustomInput } from '@/web/@components/CustomForm';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { AiContainerProductSelect } from './AiContainerProductSelect';
import { useCallback, useEffect, useState } from 'react';
import api from '@/api';
import { useSelector } from 'react-redux';
import { selectSecretToken, selectTenantId } from '@/store/auth';

/**
 * @typedef {object} AiContainerFormField
 * @property {string} name
 * @property {import('@/types').ProductProperties} product
 * @property {string} versionNumber
 */

/**
 * @typedef {object} AiContainerBasicFormProps
 * @property {boolean} [disabled]
 * @property {AIContainerV2} [container]
 * @property {import('@/types').ProductProperties} [product]
 * @property {import('react-hook-form').SubmitHandler<AiContainerFormField>} onSubmit
 */

/**
 * @param {AiContainerBasicFormProps} props
 */
export function AiContainerBasicForm(props) {
  const { container, product = null, onSubmit, disabled } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [containerPrevVersion, setContainerPrevVersion] = useState('');

  /** @type {import('react-hook-form').UseFormReturn<AiContainerFormField, any>} */
  const form = useForm({
    mode: 'all',
    defaultValues: {
      product,
      name: container?.name || '',
      versionNumber: container?.versionNumber || '',
    },
    shouldUnregister: true,
  });

  const getLastContainer = useCallback(async () => {
    const request = await api.ac.v5.aicontainer.filter.$get({
      headers: {
        Authorization: secretToken,
      },
      params: {
        limit: 1,
        offset: 0,
        tenantId,
      },
    });
    await request.process();
    const result = request.result.aiContainers?.at(0);
    setContainerPrevVersion(result?.versionNumber);
  }, [secretToken, tenantId]);

  useEffect(() => {
    if (Boolean(container)) return;
    getLastContainer();
  }, [getLastContainer, container]);

  /** @param {AiContainerFormField} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmit) return;
    data.name = trimString(data.name);
    onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={1} mb={'10px'}>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="name"
              label={'Name *'}
              placeholder={'Enter AI Container name'}
              rules={{ required: 'Name is required' }}
            />
          </Grid>

          {!Boolean(container) && (
            <Grid item xs={12} md={6}>
              <CustomInput
                name="versionNumber"
                label={'Version *'}
                placeholder={containerPrevVersion || 'Enter AI Container version'}
                rules={{ required: 'Version is required' }}
                disabled={Boolean(container)}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <AiContainerProductSelect disabled={Boolean(container)} />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent={'flex-end'} gap="5px">
          <Button type="button" variant="text" onClick={() => onSubmit(null)}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ gap: '5px' }}
            disabled={disabled || !form.formState.isDirty}
          >
            {disabled && <CircularProgress color="inherit" size={16} />}
            <span>{container ? 'Save' : 'Submit'}</span>
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
