import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Fetch list of all Annotations
 */
export function useAnnotationCategories() {
  const fetcher = async ({ signal, secretToken, tenantId }) => {
    const pageSize = 20;
    let afterName = null;
    /** @type {Array<AnnotationCategory>} */
    let results = [];
    while (!signal.aborted) {
      const request = api.ac.v5['annotation-category'].$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          pageSize,
          tenantId,
          afterName,
        },
      });
      const result = await request.process();
      results.push(...result.annotationCategories);
      if (result.annotationCategories.length < pageSize) break;
      afterName = results[results?.length - 1]?.name;
    }
    return results;
  };
  return useFutureLoader(fetcher, [], { cache: true });
}
