import { T } from '@/assets/locales';

/** @type {Array<TableHeaderAction<DeviceGroupInfo>>} */
export const ACTION_ITEMS = [
  {
    label: 'Search',
    placeholder: T['ai.scheme.table.search.placeholder'],
    component: 'SEARCH',
  },
];
