import { handleKeyPress, handleOnPaste } from '@/utils/input';
import { CustomTooltip } from '@/web/@components/CustomTooltip';
import { Box, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { get, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} CustomInputProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {CustomInputProps} props */
export function GroupSettingsInputForm(props) {
  const { item } = props;
  const { key: name, label: labelName, rules, ...rest } = item;
  const { register, formState, watch, setValue, getValues } = useFormContext();
  const error = get(formState.errors, name);

  const handleOnChange = (e) => {
    e.persist();
    const maxVal = rest?.max;
    if (maxVal || rest?.inputType === 'number') {
      let value = e.target.value ? Number(e.target.value) : '';
      //const minVal = rest?.min || 0;
      if (maxVal) {
        if (value > maxVal) value = maxVal;
      }
      //if (value < minVal) value = minVal;
      setValue(name, value);
    } else {
      setValue(name, e.target.value);
    }

    setTimeout(() => {
      const value = getValues(name);
      const minVal = rest?.min || 0;
      if (value < minVal) {
        setValue(name, minVal);
      }
    }, 2000);
  };

  const handleOnBlur = (e) => {
    if (rest?.inputType !== 'number') return;
    const value = getValues(name);
    const minVal = rest?.min || 0;
    if (value < minVal) {
      setValue(name, minVal);
    }
  };

  return (
    <FormControl variant="standard" fullWidth>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
        }}
      >
        <Box display="inline-flex" width="70%" alignItems="center">
          <Typography color="#0B2547" fontSize="14px">
            {labelName}
          </Typography>
          <CustomTooltip text={item?.infoText} />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
          <TextField
            {...register(name)}
            disabled={Boolean(rest.hasOwnProperty('isDisabled') ? rest.isDisabled(watch) : false)}
            fullWidth
            size="small"
            variant="outlined"
            type={rest?.inputType ?? 'number'}
            error={Boolean(error)}
            helperText={error?.message || ''}
            multiline={!!rest?.multiline}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            {...(!['input', 'textarea'].includes(rest?.inputType) && {
              onPaste: handleOnPaste,
              onKeyPress: handleKeyPress,
            })}
          />
          {rest?.postText && (
            <Typography color="#0B2547" fontSize="14px">
              {rest?.postText}
            </Typography>
          )}
        </Box>
      </Box>
    </FormControl>
  );
}
