import { Box } from '@mui/material';

/** @param {import('@mui/material').BoxProps} props */
export function LiveIcon(props) {
  return (
    <Box
      p="1px 5px"
      color="#fff"
      fontWeight="600"
      borderRadius="2px"
      fontSize="0.675rem"
      bgcolor="#E32929"
      display={'inline-block'}
      {...props}
      sx={{
        'animation': 'pulse-animation 2s infinite',
        '@keyframes pulse-animation': {
          '0%': {
            boxShadow: '0 0 0 0px rgba(0, 0, 0, 0.2)',
          },
          '100%': {
            boxShadow: `0 0 0 10px rgba(0, 0, 0, 0)`,
          },
        },
        ...props?.sx,
      }}
    >
      LIVE
    </Box>
  );
}
