import { AI_CONTAINER_ADD_PLUS_ICON, AI_CONTAINER_EDIT_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { Box, Button, Typography } from '@mui/material';
import { startCase } from 'lodash';
import { getIconBySourceType } from './utils';

/**
 * @typedef {object} InputSourceListItemProps
 * @property {ComponentOutputSource} selected
 * @property {ComponentInputSource} inputSource
 * @property {import('react').MouseEventHandler<HTMLElement>} onClick
 */

/** @param {InputSourceListItemProps} props */
export function InputSourceListItem(props) {
  const { inputSource, selected, onClick } = props;
  return (
    <Box mt={1.5} display="flex" gap="5px">
      <Button onClick={onClick} sx={{ p: 0, borderRadius: 0 }}>
        <Box
          height="100%"
          position="relative"
          textAlign="center"
          border="1px dashed #C5D9F0"
          width={{ xs: 100, sm: 120, lg: 130 }}
          p={0.5}
          sx={{
            '&:hover .edit-button': {
              opacity: 1,
            },
          }}
        >
          {selected ? (
            <CenterBox bgcolor="#FFF" p={1}>
              <BoxImage src={getIconBySourceType(selected.type)} size="32px" />
              <Typography noWrap fontSize="0.75rem" width="100%" textOverflow="ellipsis">
                {selected.name}
              </Typography>
              <CenterBox
                className="edit-button"
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                right={0}
                sx={{
                  opacity: 0,
                  background: '#586A813B',
                  transition: '0.3s opacity',
                }}
              >
                <BoxImage src={AI_CONTAINER_EDIT_ICON} size="24px" />
              </CenterBox>
            </CenterBox>
          ) : (
            <CenterBox>
              <BoxImage src={AI_CONTAINER_ADD_PLUS_ICON} size="24px" />
            </CenterBox>
          )}
        </Box>
      </Button>
      <Box flex={1} p={2} bgcolor="#F5F9FF">
        <Typography variant="subtitle1" fontWeight={500}>
          {inputSource.name || `Input ${inputSource.id}`}
        </Typography>
        <Typography variant="subtitle1" fontWeight={300}>
          {inputSource.description || startCase(inputSource.type.toLowerCase())}
        </Typography>
      </Box>
    </Box>
  );
}
