import api from '@/api';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { selectSecretToken } from '@/store/auth';
import { flatten, isArray, uniq } from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertUnit, convertValueByUnit } from '../../utils';

/**
 * @typedef {object} TargetSelectionItem
 * @property {"ENDPOINT"|"GROUP"|"TENANT"|"TRIGGER"|"TRIGGER_CATEGORY"|"DEFAULT"} type - The type of the state variable.
 * @property {string} value - The value of the state variable.
 * @property {string} name - The name of the state variable.
 */

/**
 * @template T
 * @typedef {object} TriggerConfigurationContextData
 * @property {Array<T>} triggers
 * @property {string} error
 * @property {Array<VariableDefinitionDto & {triggerCategoryId: number}>} variablesDefinition
 * @property {TargetSelectionItem} selectionType
 * @property {Array<TriggerCategoryDetails>} triggerCategories
 * @property {boolean} parentLoading
 * @property {StateAction<T>} setSelectionType
 * @property {StateAction<Array<T>>} setTriggers
 */

/**
 * @template T
 * @type {import('react').Context<TriggerConfigurationContextData<any>>}
 */
export const TriggerConfigurationContext = createContext(null);

export const TriggerConfigurationProvider = ({ children }) => {
  const secretToken = useSelector(selectSecretToken);

  const { result: triggerCategories, loading: categoryLoading } = useFetchTriggerCategories();

  /** @type {StateVariable<Array<VariableDefinitionDto & {triggerCategoryId: number}>>} */
  const [variablesDefinition, setVariableDefinition] = useState([]);
  /** @type {StateVariable<TargetSelectionItem>} */
  const [selectionType, setSelectionType] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [parentLoading, setParentLoading] = useState(true);
  /** @type {StateVariable<Array<CompositeTriggerDto>>} */
  const [triggers, setTriggers] = useState(null);
  /** @type {StateVariable<string>} */
  const [error, setError] = useState(null);

  const setLoadingAndClearError = (loading) => {
    setParentLoading(loading);
    setError(null);
  };

  /***********************************************************
   *                                                          *
   *                  Trigger Category Ids                  *
   *                                                          *
   ***********************************************************/
  const triggerCategoryIds = useMemo(
    () => uniq(triggerCategories?.map((i) => i?.id)),
    [triggerCategories]
  );

  /***********************************************************
   *                                                          *
   *                      Transform Data                      *
   *                                                          *
   ***********************************************************/
  const transFormData = useCallback(
    async (triggers) => {
      if (!triggerCategories?.length || !variablesDefinition?.length) return;

      const getTriggerCategory = (trigger) =>
        triggerCategories.find((cat) => cat.id === trigger?.triggerCategoryId);

      // Skip the current item if trigger category is not found
      // Or it a Shadow Category , id = 36
      // Or it a Default Category , id = 1
      const shouldSkipTrigger = (triggerCategory) =>
        !triggerCategory || [1, 36].includes(triggerCategory.id);

      const getConvertedValue = (value, originalUnit, convertedUnitName) =>
        convertValueByUnit(value, originalUnit, convertedUnitName);

      const transformVariable = (variable, variablesData) => {
        const originalUnit = variable.unit;
        const convertedUnitName = convertUnit(originalUnit);
        const triggerVariableData =
          variablesData?.find((data) => data.variableName === variable.name) || {};

        const {
          defaultValue,
          constraint: { minValue, maxValue, stepSize },
          variableSensitivity: { low, high, medium },
        } = variable;

        return {
          ...triggerVariableData,
          key: variable.name,
          description: variable.description,
          unit: convertedUnitName,
          originalUnit,
          minValue: getConvertedValue(minValue, originalUnit, convertedUnitName),
          maxValue: getConvertedValue(maxValue, originalUnit, convertedUnitName),
          stepSize: getConvertedValue(stepSize, originalUnit, convertedUnitName),
          default: getConvertedValue(defaultValue, originalUnit, convertedUnitName),
          value: getConvertedValue(
            triggerVariableData?.value ?? defaultValue,
            originalUnit,
            convertedUnitName
          ),
          sensitivity: {
            low: getConvertedValue(low, originalUnit, convertedUnitName),
            medium: getConvertedValue(medium, originalUnit, convertedUnitName),
            high: getConvertedValue(high, originalUnit, convertedUnitName),
          },
        };
      };

      const transformTrigger = (trigger) => {
        const triggerCategory = getTriggerCategory(trigger);
        if (shouldSkipTrigger(triggerCategory) || trigger?.isSystemTrigger) return null;

        const variablesData = trigger?.body?.variableOverrides?.variables || [];
        const triggerVariables = variablesDefinition
          ?.filter((variable) => variable.triggerCategoryId === trigger.triggerCategoryId)
          ?.map((variable) => transformVariable(variable, variablesData))
          ?.sort((a, b) => a.key.localeCompare(b.key));

        return {
          ...trigger,
          triggerCategoryName: triggerCategory.name,
          triggerCategoryDescription: triggerCategory.description,
          variables: variablesData.length === 0 ? variablesData : triggerVariables,
        };
      };

      const transformedResult = (triggers || [])
        .map(transformTrigger)
        .filter(Boolean)
        .sort((a, b) => a.triggerCategoryName.localeCompare(b.triggerCategoryName));

      return transformedResult;
    },
    [triggerCategories, variablesDefinition]
  );

  /***********************************************************
   *                                                          *
   *            Fetching Variable constants details           *
   *                                                          *
   ***********************************************************/
  const getVariableConstantsDetails = useCallback(
    async (signal) => {
      try {
        if (!triggerCategoryIds?.length || categoryLoading) return;
        const variableDefinitionRequests = api.ac.v5.trigger.variable.definition.$post({
          signal,
          headers: {
            Authorization: secretToken,
          },
          data: triggerCategoryIds,
        });
        const variableDefinitionResponse = await variableDefinitionRequests.process();

        const customerLabelVariables = flatten(
          variableDefinitionResponse.values?.map((i) =>
            i.variableDefinitions
              .filter((y) => y.customerFacing)
              .map((y) => ({ ...y, triggerCategoryId: i.triggerCategoryId }))
          )
        );
        setVariableDefinition(customerLabelVariables);
      } catch (ex) {}
    },
    [secretToken, triggerCategoryIds, categoryLoading]
  );

  /***********************************************************
   *                                                          *
   *                 Fetch Tenant Trigger Data                *
   *                                                          *
   ***********************************************************/
  const fetchTenantTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.tenant
          .$tenantId(selectionType?.value)
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
        console.error(ex);
      }
    },
    [secretToken, selectionType?.value]
  );

  /***********************************************************
   *                                                          *
   *                 Fetch Group Trigger Data                 *
   *                                                          *
   ***********************************************************/
  const fetchGroupTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.group
          .$groupId(Number(selectionType?.value))
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
      }
    },
    [secretToken, selectionType?.value]
  );

  /***********************************************************
   *                                                          *
   *                Fetch Endpoint Trigger Data               *
   *                                                          *
   ***********************************************************/
  const fetchEndpointTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.trigger.composite.endpoint
          .$endpointId(Number(selectionType?.value))
          .detail.$get({
            signal,
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
        const result = request.result?.compositeTriggers;
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
      }
    },
    [secretToken, selectionType?.value]
  );

  /***********************************************************
   *                                                          *
   *                Fetch Specific Trigger Data               *
   *                                                          *
   ***********************************************************/
  const fetchTriggerData = useCallback(
    async (id) => {
      const request = api.ac.v5.trigger.composite.$triggerId(id).$get({
        headers: {
          Authorization: secretToken,
        },
        params: {},
      });
      const result = await request?.process();
      const transformedResult = await transFormData([result]);
      return transformedResult?.at(0);
    },
    [secretToken, transFormData]
  );

  /***********************************************************
   *                                                          *
   *                      Fetch All Data                      *
   *                                                          *
   ***********************************************************/
  const fetchData = useCallback(
    async (abortController) => {
      setLoadingAndClearError(true);
      try {
        let result;
        if (selectionType.type === 'TENANT') {
          result = await fetchTenantTriggerData(abortController.signal);
        } else if (selectionType.type === 'ENDPOINT') {
          result = await fetchEndpointTriggerData(abortController.signal);
        } else if (selectionType.type === 'GROUP') {
          result = await fetchGroupTriggerData(abortController.signal);
        }
        const transformedTriggerData = await transFormData(result);

        await Promise.all(
          transformedTriggerData?.map(async (trigger) => {
            const defaultValues = await fetchTriggerData(trigger?.id);
            trigger.defaultValues = defaultValues;
          })
        );
        await setTriggers(transformedTriggerData);
      } catch (ex) {
        console.log('ex', ex);
        setError(ex?.response?.data?.message);
      }
    },
    [
      fetchEndpointTriggerData,
      fetchGroupTriggerData,
      fetchTriggerData,
      transFormData,
      fetchTenantTriggerData,
      selectionType?.type,
    ]
  );

  useEffect(() => {
    const abortController = new AbortController();
    getVariableConstantsDetails(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, [getVariableConstantsDetails]);

  useEffect(() => {
    if (!selectionType?.type || !selectionType?.value) {
      setLoadingAndClearError(false);
      return;
    }
    const abortController = new AbortController();
    fetchData(abortController);
    return () => {
      abortController.abort();
      setLoadingAndClearError(false);
    };
  }, [selectionType?.type, selectionType?.value, fetchData]);

  useEffect(() => {
    if (isArray(triggers)) {
      setParentLoading(false);
    }
  }, [triggers]);

  return (
    <TriggerConfigurationContext.Provider
      value={{
        setSelectionType,
        selectionType,
        variablesDefinition,
        parentLoading: parentLoading || categoryLoading,
        triggers,
        error,
        triggerCategories,
        setTriggers,
      }}
    >
      {children}
    </TriggerConfigurationContext.Provider>
  );
};
