import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { ACTION_ITEMS } from './actions';
import { FEEDBACK_TABLE_COLUMNS } from './columns';

export function TriggerVariableListView() {
  const secretToken = useSelector(selectSecretToken);

  /** @param {Array<DeviceGroupInfo>} selectedItems */
  const deleteFeedbacks = async (selectedItems) => {
    const success = [];
    const failed = [];
    await Promise.allSettled(
      selectedItems.map(async (feedback) => {
        try {
          const request = api.ac.v5.feedbacks.$delete({
            headers: {
              Authorization: secretToken,
            },
            params: {
              feedbackId: feedback.id,
            },
          });
          await request.process();
          success.push(feedback.id);
        } catch (e) {
          failed.push(feedback.id);
        }
      })
    );
    if (success?.length) {
      toastSuccess('Feedbacks Discontinued successfully.');
    }
    if (failed?.length) {
      toastWarning('Something went wrong.');
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<DeviceGroupInfo>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteFeedbacks(selectedItems);
    }
    setTableReload(true);
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader title="Feedbacks" actions={ACTION_ITEMS} onAction={onActionHandle} />
        <OffsetPagination
          key={secretToken}
          fetcher={async ({ signal, limit, offset }) => {
            const request = api.ac.v5.feedbacks.$get({
              signal,
              headers: {
                Authorization: secretToken,
              },
              params: {
                limit,
                offset,
              },
            });
            await request.process();
            return {
              data: request.result?.feedbackDtoList,
              count: request.result?.totalElements,
            };
          }}
          renderList={(/** @type {Array<FeedbackDto>} */ results) => (
            <ItemsResponsiveView results={results} columns={FEEDBACK_TABLE_COLUMNS} />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
