/* eslint-disable no-unused-vars */
import api from '@/api';
import { TRIGGER_VOICE_BLUE_ICON } from '@/assets/constants/images';
import { selectSecretToken } from '@/store/auth';
import { BoxImage } from '@/web/@components/BoxImage';
import { Pause, PlayArrow } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

export function TtsPreviewButton({ trigger }) {
  const { ttsGender, ttsLanguageLocale, ttsText } = trigger;

  const secretToken = useSelector(selectSecretToken);

  const { control } = useFormContext();

  const audioRef = useRef(null);

  const { getValues } = useFormContext();

  /** @type {StateVariable<boolean>} */
  const [isPlaying, setIsPlaying] = useState(false);
  /** @type {StateVariable<{loading: boolean, url: string}>} */
  const [preview, setPreview] = useState({
    loading: false,
    url: null,
  });

  const ttsValue = useWatch({ control, name: 'tts' });

  const isDisabledTts = useMemo(() => ttsValue === '', [ttsValue]);

  const convertLocale = (locale) => {
    const [language, region] = locale.split('-');
    const convertedLocale = `${language.toUpperCase()}_${region.toUpperCase()}`;
    return convertedLocale;
  };

  const handleGeneratePreviewUrl = async () => {
    setPreview({ loading: true, url: null });
    try {
      const data = {
        // ttsGender: ttsGender?.value,
        // ttsLanguageLocale: convertLocale(ttsLanguageLocale?.value),
        ttsGender: null,
        ttsLanguageLocale: null,
        text: getValues('tts') || ttsText?.value,
      };

      const request = api.ac.v5.text.preview.$post({
        headers: {
          Authorization: secretToken,
        },
        // @ts-ignore
        data,
      });

      const result = await request?.process();
      setPreview((prev) => ({ ...prev, url: result?.audioUrl }));
      setTimeout(() => {
        setPreview((prev) => ({ ...prev, loading: false }));
        togglePlayPause();
      }, 1400);
    } catch (ex) {
      setPreview({ loading: false, url: null });
    }
  };

  const togglePlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      // Pause the audio if it is currently playing
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      // Check if the audio is ready to play
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error('Error playing audio:', error);
          setIsPlaying(false);
        });
    }
  };

  useEffect(() => {
    const handleAudioEnd = () => setIsPlaying(false);
    const cleanupAudio = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
        audioRef.current.removeEventListener('ended', handleAudioEnd);
      }
    };

    if (preview?.url) {
      // Clean up previous audio if it exists
      cleanupAudio();
      // Create new Audio object
      audioRef.current = new Audio(preview?.url);
      audioRef.current.addEventListener('ended', handleAudioEnd);
      // Resume playback if it was previously playing
      if (isPlaying) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
          setIsPlaying(false);
        });
      }
    } else {
      cleanupAudio();
    }
    // Cleanup on component unmount or audioSrc change
    return () => cleanupAudio();
  }, [preview?.url, isPlaying]);

  useEffect(() => {
    setPreview({ loading: false, url: null });
  }, [ttsValue]);

  return (
    <Box display="flex" flexDirection="row" gap={0.5} alignItems={'center'}>
      <IconButton
        disabled={isDisabledTts}
        size="small"
        sx={{
          background: '#F5FAFF',
          border: '1px solid #C5D9F0',
          p: '1px',
        }}
        onClick={() => (preview?.url ? togglePlayPause() : handleGeneratePreviewUrl())}
        color="primary"
      >
        {preview?.loading ? (
          <CircularProgress size={20} />
        ) : isPlaying ? (
          <Pause htmlColor="#608AC3" fontSize="small" />
        ) : (
          <PlayArrow htmlColor="#608AC3" fontSize="small" />
        )}
      </IconButton>
      <Typography variant="body2" fontWeight={'medium'} fontSize={'12px'}>
        Preview
      </Typography>
    </Box>
  );
}
