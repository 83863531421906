import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { DevicePoolDetailsContext } from '../DevicePoolDetailsLayout';
import { PoolFirmwareDeployDialog } from '../PoolFirmwareDeployDialog';
import { ACTION_ITEMS } from './actions';

export function PoolFirmwareListActions() {
  const secretToken = useSelector(selectSecretToken);
  const { setTableReload } = useContext(PaginatedTableContext);
  const { poolId } = useContext(DevicePoolDetailsContext);

  const [deploymentDialog, setDeploymentDialog] = useState(false);

  /** @type {import('../PoolFirmwareDeployDialog').PoolFirmwareDeployDialogProps['onSubmit']} */
  const handleDeploy = async (firmware) => {
    try {
      const { firmwareId, type, version } = firmware;
      const request = api.ac.v2.endpoint.devicepools.$poolId(poolId).firmwares.$post({
        headers: {
          Authorization: secretToken,
        },
        params: {
          firmwareId,
          firmwareType: type,
          firmwareVersion: version,
        },
      });
      await request.process();
      setTableReload(true);
      setDeploymentDialog(null);
    } catch (err) {
      console.error('Failed to deploy firmware', err);
      toastWarning('Sorry!', `Could not deploy the firmware`);
    }
  };

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<Device>['onAction']} */
  const onActionHandle = (type, selectedItems) => {
    switch (type) {
      case 'DEPLOY_FIRMWARE':
        setDeploymentDialog(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ItemsListHeader title="Firmwares" actions={ACTION_ITEMS} onAction={onActionHandle} />
      {deploymentDialog && (
        <PoolFirmwareDeployDialog
          open
          onSubmit={handleDeploy}
          onCancel={() => setDeploymentDialog(false)}
        />
      )}
    </>
  );
}
