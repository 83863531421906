import { orderBy } from 'lodash';

export const THRESHOLD_INPUT_TYPE = [
  { value: 'INPUT', label: 'Input' },
  { value: 'SLIDER', label: 'Slider' },
];

export const STATIC_SOURCE_LIST = [
  { label: 'Computer Vision', value: 'Classification' },
  // { label: "Vehicle Dynamics", value: "VEHICLE_DYNAMICS" },
  { label: 'Vehicle Dynamics', value: 'Sensor' },
  { label: 'Alerts & IOs', value: 'ALERTS_AND_IOS' },
  // { label: "OBDII", value: "OBDII" },
  { label: 'OBD-II', value: 'Canbus' },
];

export const unitList = [
  { value: 'years', label: 'years' },
  { value: '%', label: '%' },
  { value: 'seconds', label: 'seconds' },
  { value: '°C', label: '°C' },
  { value: 'rpm', label: 'rpm' },
  { value: 'kPa', label: 'kPa' },
  { value: 'degrees', label: 'degrees' },
  { value: 'angle', label: 'angle' },
  { value: 'grams/sec', label: 'grams/sec' },
  { value: 'm/s', label: 'm/s' },
  { value: 'km', label: 'km' },
  { value: '°before TDC', label: '°before TDC' },
  { value: 'Volt', label: 'Volt' },
  { value: 'Boolean (ON/OFF)', label: 'Boolean (ON/OFF)' },
  { value: 'm/s^2', label: 'm/s²' },
  { value: 'Meter', label: 'Meter' },
  { value: 'Milliseconds', label: 'Milliseconds' },
  { value: 'km/h', label: 'km/h' },
  { value: 'LITER', label: 'Liter' },
  { value: '', label: 'Undefined' },
];

export const SORTED_LABEL_UNITS = orderBy(unitList, ['label'], ['asc']);
