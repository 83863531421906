import { T } from '@/assets/locales';
import { SafetyScoring } from '.';
import { DriverDetails } from './DriverDetails';
import { DriverList } from './DriverList';
import { EndpointList } from './EndpointList';
import { EndpointDetails } from './EndpointDetails';
import { PersonaList } from './@components/PersonaList';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <SafetyScoring />,
    breadcrumb: T['scoring.page.title'],
  },
  {
    path: 'drivers',
    // breadcrumb: 'Drivers',
    children: [
      {
        path: '',
        element: <DriverList />,
        breadcrumb: 'Loading...',
      },
      {
        path: ':id',
        element: <DriverDetails />,
        breadcrumb: 'Loading...',
      },
    ],
  },
  {
    path: 'cameras',
    breadcrumb: 'Cameras',
    children: [
      {
        path: '',
        element: <EndpointList />,
        breadcrumb: 'Loading...',
      },
      {
        path: ':id',
        element: <EndpointDetails />,
        breadcrumb: 'Loading...',
      },
    ],
  },
  {
    path: 'persona',
    breadcrumb: 'Persona',
    children: [
      {
        path: '',
        element: <PersonaList />,
        breadcrumb: 'Loading...',
      },
    ],
  },
];

export default routes;
