import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastWarning } from '../toaster';

/**
 * Send Event to Endpoints
 * @param {Array<any>} endpointIds
 * @param {string} type
 * @param {string} [text]
 * @returns
 */

export const sendEventsToDevices = (endpointIds, type, text) => {
  const state = store.getState();
  const tenantId = selectTenantId(state);
  const secretToken = selectSecretToken(state);

  if (!tenantId || !secretToken || !endpointIds || endpointIds?.length === 0 || !type) {
    return;
  }

  const request = api.ac.v1['event-messaging']['send-event'].$post({
    params: {
      secretToken,
    },
    data: {
      connectionId: '',
      endpointIds,
      endpointType: 'DEVICE',
      occurrenceTime: Date.now(),
      private: true,
      recipient: 0,
      sender: 0,
      sentTime: 0,
      tenantId,
      text: text || '',
      userId: 0,
      type,
    },
  });
  return request.process();
};

/**
 * Send Event to Device Group
 * @param {number} groupId
 * @param {string} type
 * @returns
 */

export const sendEventsToGroup = (groupId, type) => {
  const state = store.getState();
  const tenantId = selectTenantId(state);
  const secretToken = selectSecretToken(state);
  if (!tenantId || !secretToken) {
    return;
  }
  const req = api.ac.v1.endpoint.group.list.resources.$get({
    params: {
      groupId,
      secretToken,
      tenantID: tenantId,
      groupResourceType: 'DEVICE',
      limit: 1000,
      offset: 0,
    },
  });
  req.process().then((result) => {
    const endpointIds = result.endpoints.map((item) => item.id) || [];
    if (endpointIds?.length === 0) return;
    const request = api.ac.v1['event-messaging']['send-event'].$post({
      params: {
        secretToken,
      },
      data: {
        connectionId: '',
        endpointIds,
        endpointType: 'DEVICE',
        occurrenceTime: Date.now(),
        private: true,
        recipient: 0,
        sender: 0,
        sentTime: 0,
        tenantId,
        text: '',
        userId: 0,
        type,
      },
    });
    request.process().catch((err) => {
      toastWarning('Failed to send event to group devices.');
    });
  });
};

/**
 * Send OTA message to Device
 * @param {string} deviceSerial
 * @param {string} type
 * @returns
 */

export const sendOtaMessagesToDevice = (deviceSerial, type) => {
  const state = store.getState();
  const secretToken = selectSecretToken(state);
  if (!secretToken) return;
  const request = api.ac.v2.endpoint.devices.$deviceSerialNumber(deviceSerial).otamessages.$post({
    params: {
      type,
    },
    headers: {
      'Authorization': secretToken,
      'Content-Type': 'application/json',
    },
    data: {},
  });
  return request.process();
};

/**
 * Send OTA message to Group
 * @param {number} groupId
 * @param {string} type
 * @returns
 */

export const sendOtaGroupMessages = (groupId, type) => {
  const state = store.getState();
  const secretToken = selectSecretToken(state);
  if (!secretToken) return;
  const request = api.ac.v2.endpoint.devicegroups.$groupId(groupId).otamessages.$post({
    params: {
      type,
    },
    headers: {
      'Authorization': secretToken,
      'Content-Type': 'application/json',
    },
    data: {},
  });
  return request.process();
};

/**
 * Send Event to Endpoints
 * @param {number} endpointId
 * @param {string} type
 * @param {string} [text]
 * @param {string} [connectionId]
 * @param {number} [sender]
 * @returns
 */

export const sendMessageToDevice = (endpointId, type, text, connectionId = '', sender = 0) => {
  const state = store.getState();
  const tenantId = selectTenantId(state);
  const secretToken = selectSecretToken(state);

  if (!tenantId || !secretToken || !endpointId || !type) {
    return;
  }

  const request = api.ac.v1['event-messaging']['send-message'].$post({
    params: {
      secretToken,
      remoteEndpointId: endpointId,
    },
    data: {
      connectionId: connectionId || '',
      endpointType: 'DEVICE',
      occurrenceTime: Date.now(),
      private: true,
      recipient: endpointId,
      sender: sender || 0,
      sentTime: 0,
      tenantId,
      text: text || '',
      userId: 0,
      type,
    },
  });
  return request.process();
};
