import { BOX_DISCONTINUE, BOX_MODIFY_ICON } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<TenantInfo>>} */
export const ACTION_ITEMS = [
  {
    label: 'Deactivate',
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) =>
      !(selected.length === 1 && selected?.at(0)?.tenantStatus !== 'NOT_APPROVED'),

    confirmationMessage: 'Are you sure you want to deactivate the selected Tenant?',
    accessLevel: 'SUPER_ADMIN',
  },
  {
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    link: (selectedItem) => `/administration/tenants/${selectedItem.tenantId}/edit`,
    hidden: (selected) => selected.length !== 1,
    accessLevel: 'SUPER_ADMIN',
  },
  {
    label: 'Search',
    placeholder: 'Search Tenant',
    component: 'SEARCH',
  },
  {
    label: 'Tenant',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/tenants/create',
    accessLevel: 'SUPER_ADMIN',
  },
];
