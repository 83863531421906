import { useDashcamPlayer } from '@/hooks/useDashcamPlayer';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { selectMainAccessToken, selectMainRefreshToken, selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export function FaceRegistrationPage() {
  const { query } = useRouteQuery();

  /** @type {import('react').Ref<HTMLDivElement>} */
  const parentRef = useRef();

  const tenantId = useSelector(selectTenantId);
  const accessToken = useSelector(selectMainAccessToken);
  const refreshToken = useSelector(selectMainRefreshToken);
  const player = useDashcamPlayer('face-registration');

  useEffect(() => {
    if (!player) return;
    player.setAttribute('tenant-id', tenantId);
  }, [player, tenantId]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('refresh-token', refreshToken);
  }, [player, refreshToken]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('access-token', accessToken);
  }, [player, accessToken]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('endpoint-id', trimString(Number(query.id)));
  }, [player, query.id]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('driver-id', trimString(query.driverId));
  }, [player, query.driverId]);

  useEffect(() => {
    if (!player) return;
    player.setAttribute('nick-name', trimString(query.nickName));
  }, [player, query.nickName]);

  // display on view
  useEffect(() => {
    if (!player) return;
    parentRef?.current?.replaceChildren(player);
  }, [player]);

  return (
    <Box
      ref={parentRef}
      width="100%"
      height="98%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
}
