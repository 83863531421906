import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

const ENABLED_LANGUAGES = ['EN', 'FR', 'DE', 'ES'];
const ENABLED_REGIONS = ['AU', 'US', 'GB', 'DE', 'CA', 'FR', 'ES'];

export function useLanguageRegion(endpointId) {
  return useFutureLoader(
    async ({ secretToken, signal }) => {
      const request = api.ac.v5.tenant.settings.trigger.map.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      if (!result) throw new Error('No Language Data');

      const languages = Object.entries(result.language)
        .map(([key, value]) => ({
          category: value,
          value: key,
        }))
        ?.filter((i) => ENABLED_LANGUAGES?.includes(i?.value))
        ?.sort((a, b) => a.category.localeCompare(b.category));

      const regions = Object.entries(result.region)
        .map(([key, value]) => ({
          label: value,
          value: key,
        }))
        ?.filter((i) => ENABLED_REGIONS?.includes(i?.value));

      const mappedData = languages?.map((item) => {
        const regionKeys = result?.languageRegionMap[item?.value];
        const filteredRegions = regions
          ?.filter((i) => regionKeys?.includes(i?.value))
          ?.filter((i) => !(item?.value === 'EN' && i?.value === 'CA'))
          ?.map((y) => {
            return {
              label: item?.value === 'ES' && y?.value === 'US' ? 'Mexico' : y?.label,
              value: `${item?.value}_${y?.value}`,
            };
          })
          ?.sort((a, b) => a.label.localeCompare(b.label));

        return {
          ...item,
          items: filteredRegions,
        };
      });

      const flatOptions = mappedData
        .map((group) =>
          group.items.map((item) => ({
            ...item,
            languageName: group.category,
          }))
        )
        .flat();
      return flatOptions;
    },
    [endpointId],
    { cache: true }
  );
}
