import { isProduction } from '@/config';
import { SentryEvents, reportEvent } from './sentry';

// Clones the console for later use
const _console = { ...console };

const _hideInProduction = ['trace', 'log', 'info', 'warn', 'debug'];

export class CustomLogger {
  #name = null;

  /**
   * @param {string} [name] The name of the logger, default: root.
   */
  constructor(name = 'root') {
    this.#name = name;
  }

  /**
   * @param {keyof Console} level
   * @param {any} message
   * @param {...any} args
   */
  _out = (level, message, ...args) => {
    if (!level || !_console[level + '']) return;
    if (isProduction && _hideInProduction.includes(level)) return;
    const time = new Date().toISOString();
    const tag = (level + '').toUpperCase().substring(0, 5).padStart(5);
    _console[level + ''](
      '%c %s %c %s %c %s %c',
      'color:black;background:orange',
      tag,
      'color:teal',
      time,
      'color:orange;background:teal',
      this.#name || 'root',
      'color:inherit',
      message,
      ...args
    );
  };

  /**
   * @param {any} message
   * @param {...any} args
   */
  trace = (message, ...args) => {
    this._out('trace', message, ...(args || []));
  };

  /**
   * @param {any} message
   * @param {...any} args
   */
  log = (message, ...args) => {
    this._out('log', message, ...(args || []));
  };

  /**
   * @param {any} message
   * @param {...any} args
   */
  info = (message, ...args) => {
    this._out('info', message, ...(args || []));
  };

  /**
   * @param {any} message
   * @param {...any} args
   */
  warn = (message, ...args) => {
    this._out('warn', message, ...(args || []));
  };

  /**
   * @param {any} message
   * @param {...any} args
   */
  debug = (message, ...args) => {
    this._out('debug', message, ...(args || []));
  };

  /**
   * @param {any} message
   * @param {...any} args
   */
  error = (message, ...args) => {
    this._out('error', message, ...(args || []));
    const name = message instanceof Error ? message.name : message + '';
    reportEvent(SentryEvents.CONSOLE_ERROR_LOG, name, {
      logger: this.#name,
      production: isProduction,
      message,
      args,
    });
  };
}

export function setupConsole() {
  const root = new CustomLogger('root');
  Object.assign(console, root);
}

export function resetConsole() {
  Object.assign(console, _console);
}
