import { BOX_DISCONTINUE } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<AIContainerV2>>} */
export const ACTION_ITEMS = [
  {
    label: 'Remove',
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) => !selected.length,
    confirmationMessage: 'Are you sure you want to remove the selected AI Containers?',
  },
  {
    label: 'Search',
    placeholder: 'Search Trigger',
    component: 'SEARCH',
  },
  {
    label: 'Assign',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    type: 'ASSIGN',
    useCustomDialog: true,
  },
];
