import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { Checkbox } from '@mui/material';
import { useContext, useMemo } from 'react';

/**
 * @typedef {object} TableCheckboxItemProps
 * @property {string} [value]
 * @property {boolean} [disabled]
 * @property {object} [row] TODO: missing type
 */

/** @param {Partial<TableCheckboxItemProps>} props */
export function TableCheckboxItem(props) {
  const { disabled = false, row } = props;
  const { disabledItems, selectedItems, setSelectedItems } = useContext(PaginatedTableContext);

  const isChecked = useMemo(
    () => selectedItems?.some((item) => item === row) || false,
    [selectedItems, row]
  );

  const isDisabled = useMemo(
    () => disabled || disabledItems.some((item) => item === row),
    [disabledItems, disabled, row]
  );

  return (
    <Checkbox
      sx={{ p: 0 }}
      disabled={isDisabled}
      color={isDisabled ? 'secondary' : 'primary'}
      checked={isDisabled ? null : isChecked}
      onChange={(e) => {
        e.persist();
        if (e.target.checked) {
          setSelectedItems((list) => [...list, row]);
        } else {
          setSelectedItems((list) => list.filter((item) => item !== row));
        }
      }}
    />
  );
}
