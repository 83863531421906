import { AI_MODEL_OUTPUT_SOURCE } from '@/utils/ai-models/outputSources';
import { ModifySourceItemContainer } from '@/web/@components/ModifySourceItemContainer';
import { Grid } from '@mui/material';
import { AIModelOutputSourceFormControl } from './AIModelOutputSourceFormControl';

/** @param {{fieldIndex: number, remove: import('react-hook-form').UseFieldArrayRemove}} props */
export function AiModelOutputSourceFormItem(props) {
  const { fieldIndex, remove } = props;
  return (
    <ModifySourceItemContainer onRemove={() => remove(fieldIndex)}>
      <Grid container spacing="10px" px="10px" mb="10px">
        {AI_MODEL_OUTPUT_SOURCE.map((item, index) => (
          <AIModelOutputSourceFormControl key={index} item={item} fieldIndex={fieldIndex} />
        ))}
      </Grid>
    </ModifySourceItemContainer>
  );
}
