import api from '@/api';
import { T } from '@/assets/locales';
import {
  MEDIA_SOURCE_OPTIONS,
  TRIGGERS_MEDIA_TYPES,
  TRIGGER_CHIME_OPTIONS,
} from '@/assets/triggers';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { useLabelListFetch } from '@/hooks/useLabelListFetch';
import { selectSecretToken, selectTeamList, selectTenantId } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { findExpressionLabels } from '@/utils/triggers';
import { CustomInput, CustomSelect, CustomSwitch } from '@/web/@components/CustomForm';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS } from '@/web/administration/triggers-configuration/assets';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { isObject, isUndefined } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpressionGenerator } from '../ExpressionGenerator';

/**
 * @typedef {object} ExpressionTriggerFormPropsBase
 * @property {(data: CompositeTriggerRequest & {description: string}, triggerId: any, categoryId: number) => any} onSubmitData
 * @property {boolean} isLoading
 */

const defaultValues = {
  cooldownTimer: 30,
  mediaType: TRIGGERS_MEDIA_TYPES[3],
  chime: TRIGGER_CHIME_OPTIONS[0],
  tts: '',
  critical: false,
  triggerCategory: null,
  preBuffer: 5,
  postBuffer: 5,
  enableTts: true,
  uploadStrategy: TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS[0],
  expression: '',
  displayFormula: '',
  magnitudeFormula: '',
  snapshotFormula: '',
  classifications: [],
  magnitudeClassifications: [],
  snapshotClassifications: [],
  systemTrigger: false,
  mediaSource: MEDIA_SOURCE_OPTIONS[0],
  primarySnapshotImager: MEDIA_SOURCE_OPTIONS[0],
  description: '',
};

/**
 * @typedef {ExpressionTriggerFormPropsBase & import('@mui/material').BoxProps} ExpressionTriggerFormProps
 */

/**
 * @param {ExpressionTriggerFormProps} props
 */
export function ExpressionTriggerForm(props) {
  const { onSubmitData, isLoading, ...extra } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const teamList = useSelector(selectTeamList);

  const { setBreadcrumbTitle } = useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { result: labels } = useLabelListFetch();
  const { result: triggerCategories, loading: triggerCategoryLoading } =
    useFetchTriggerCategories();

  const triggerId = useMemo(() => params.id, [params]);

  const form = useForm({
    mode: 'all',
    defaultValues,
    shouldUnregister: false,
  });

  const { setValue, control } = form;

  const [loading, setLoading] = useState(true);

  const displayFormulaField = useWatch({ control, name: `displayFormula` });
  const magnitudeFormulaField = useWatch({ control, name: `magnitudeFormula` });
  const snapshotFormulaField = useWatch({ control, name: `snapshotFormula` });
  const classificationsField = useWatch({ control, name: `classifications` });
  const magnitudeClassificationsField = useWatch({ control, name: `magnitudeClassifications` });
  const snapshotClassificationsField = useWatch({ control, name: `snapshotClassifications` });
  const triggerNameField = useWatch({ control, name: `triggerCategory` });

  const hasDescriptionEditAccess = useMemo(
    () => teamList.some((x) => ['AI_TEAM', 'BACK_END_USER']?.includes(x)),
    [teamList]
  );

  const handleCancel = () => {
    navigate('/smarter-ai/triggers');
  };

  /** @param {typeof defaultValues} data */
  const prepareAndSubmit = (data) => {
    if (!onSubmitData) return;

    data.expression = trimString(data.expression);
    data.displayFormula = trimString(data.displayFormula);

    const {
      cooldownTimer,
      mediaType,
      chime,
      tts,
      critical,
      displayFormula,
      triggerCategory,
      classifications,
      postBuffer,
      preBuffer,
      enableTts,
      magnitudeFormula,
      snapshotFormula,
      uploadStrategy,
      magnitudeClassifications,
      snapshotClassifications,
      systemTrigger,
      mediaSource,
      primarySnapshotImager,
      description,
    } = data;

    // @ts-ignore
    const name = isObject(triggerCategory) ? triggerCategory?.name : triggerCategory || 'Default';

    const bodyObj = {
      expression: displayFormula,
      displayFormula: displayFormula,
      classifications: classifications || [],
      type: 'EXPRESSION',
    };

    const magnitudeFormulaObj = {
      expression: trimString(magnitudeFormula) || null,
      displayFormula: trimString(magnitudeFormula) || null,
      classifications: magnitudeClassifications || null,
      type: trimString(magnitudeFormula) ? 'EXPRESSION' : null,
    };

    const snapshotFormulaObj = {
      expression: trimString(snapshotFormula) || null,
      displayFormula: trimString(snapshotFormula) || null,
      classifications: snapshotClassifications || null,
      type: trimString(snapshotFormula) ? 'EXPRESSION' : null,
    };

    /** @type {CompositeTriggerRequest & {description: string}} */
    const formData = {
      name,
      cooldown: true,
      cooldownTimer: cooldownTimer ? cooldownTimer * 1000 : 0,
      mediaType: mediaType?.value,
      critical,
      tts,
      triggerCategoryId: triggerCategory?.id || 1,
      chime: chime?.value,
      uploadStrategy: uploadStrategy?.value,
      postBuffer,
      preBuffer,
      enableTts,
      body: JSON.stringify(bodyObj),
      magnitudeFormula: JSON.stringify(magnitudeFormulaObj),
      snapshotFormula: JSON.stringify(snapshotFormulaObj),
      systemTrigger,
      mediaSource: mediaSource?.value,
      primarySnapshotImager: primarySnapshotImager?.value,
      description,
    };

    onSubmitData(formData, triggerId, triggerCategory?.id);
  };

  const setTriggerCategoryAndBreadcrumb = useCallback(
    (triggerCategoryId, name) => {
      const triggerCategory = triggerCategories?.find(
        (item) => item.id === (triggerCategoryId || 1)
      );
      if (!triggerId) {
        setValue('triggerCategory', triggerCategory);
        setValue('description', triggerCategory?.description || '');
        setBreadcrumbTitle('Create Trigger');
      } else {
        setValue('description', triggerCategory?.description);

        const triggerName =
          (name === 'Default' && name !== '') || name !== triggerCategory?.name
            ? name
            : triggerCategory;

        setValue('triggerCategory', triggerName);
        setBreadcrumbTitle(triggerName?.name || triggerName);
      }
    },
    [setValue, setBreadcrumbTitle, triggerId, triggerCategories]
  );

  const setBasicFormValues = useCallback(
    ({ cooldownTimer, tts, critical, isSystemTrigger, preBuffer, postBuffer, enableTts }) => {
      cooldownTimer && setValue('cooldownTimer', cooldownTimer?.value / 1000);
      setValue('tts', tts);
      setValue('critical', critical);
      setValue('systemTrigger', isSystemTrigger || false);
      setValue('preBuffer', preBuffer?.value || '');
      setValue('postBuffer', postBuffer?.value || '');
      setValue('enableTts', enableTts?.value || true);
    },
    [setValue]
  );

  const setOptionalFormValues = useCallback(
    ({ uploadStrategy, mediaType, mediaSource, primarySnapshotImager, chime }) => {
      const uploadItem = TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS.find(
        (item) => item.value === uploadStrategy?.value
      );
      if (uploadItem) setValue('uploadStrategy', uploadItem);

      const selectedMediaType = TRIGGERS_MEDIA_TYPES.find(
        (item) => item.value === mediaType?.value
      );
      if (selectedMediaType) setValue('mediaType', selectedMediaType);

      const selectedMediaSource = MEDIA_SOURCE_OPTIONS.find((item) => item.value === mediaSource);
      if (selectedMediaSource) setValue('mediaSource', selectedMediaSource);

      const primarySnapshotImagerSrc = MEDIA_SOURCE_OPTIONS.find(
        (item) => item.value === primarySnapshotImager
      );
      if (primarySnapshotImagerSrc) setValue('primarySnapshotImager', primarySnapshotImagerSrc);

      const selectedChime = TRIGGER_CHIME_OPTIONS.find((item) => item.value === chime?.value);
      if (selectedChime) setValue('chime', selectedChime);
    },
    [setValue]
  );

  const setFormulaFormValues = useCallback(
    ({ body, magnitudeFormula, snapshotFormula }) => {
      const expression = body?.displayFormula || body?.expression;
      setValue('expression', expression);
      setValue('displayFormula', expression);
      setValue('classifications', body?.classifications || []);

      if (magnitudeFormula?.expression) {
        setValue('magnitudeFormula', magnitudeFormula.expression);
        setValue('magnitudeClassifications', magnitudeFormula.magnitudeClassifications);
      }

      if (snapshotFormula?.expression) {
        setValue('snapshotFormula', snapshotFormula.expression);
        setValue('snapshotClassifications', snapshotFormula.snapshotClassifications);
      }
    },
    [setValue]
  );

  const processTriggerDetails = useCallback(
    (result) => {
      const {
        name,
        triggerCategoryId,
        cooldownTimer,
        tts,
        critical,
        isSystemTrigger,
        preBuffer,
        postBuffer,
        enableTts,
        uploadStrategy,
        mediaType,
        mediaSource,
        primarySnapshotImager,
        chime,
        body,
        magnitudeFormula,
        snapshotFormula,
      } = result;

      setBasicFormValues({
        cooldownTimer,
        tts,
        critical,
        isSystemTrigger,
        preBuffer,
        postBuffer,
        enableTts,
      });
      setOptionalFormValues({
        uploadStrategy,
        mediaType,
        mediaSource,
        primarySnapshotImager,
        chime,
      });
      setFormulaFormValues({ body, magnitudeFormula, snapshotFormula });
      setTriggerCategoryAndBreadcrumb(triggerCategoryId, name);
    },
    [
      setBasicFormValues,
      setFormulaFormValues,
      setOptionalFormValues,
      setTriggerCategoryAndBreadcrumb,
    ]
  );

  const fetchAndSetTriggerDetails = useCallback(
    async (signal) => {
      try {
        if (!triggerId) return;
        const result = await api.ac.v5.trigger.composite
          .$triggerId(triggerId)
          .$get({
            signal,
            headers: { Authorization: secretToken },
            params: { tenantId },
          })
          .process();

        if (result) {
          processTriggerDetails(result);
        }
      } catch (error) {
        console.error('Failed to fetch trigger details', error);
      } finally {
        setLoading(false);
      }
    },
    [tenantId, secretToken, processTriggerDetails, triggerId]
  );

  const updateClassifications = useCallback(
    (formulaField, classificationsField, classificationsName) => {
      const exp = findExpressionLabels(labels, formulaField);
      const value = classificationsField || [];
      const extra = exp?.filter((x) => !value.some((y) => y.label === x.label));
      if (!extra?.length) return;
      setValue(classificationsName, [...value, ...extra]);
    },
    [labels, setValue]
  );

  useEffect(() => {
    updateClassifications(displayFormulaField, classificationsField, 'classifications');
  }, [classificationsField, displayFormulaField, updateClassifications]);

  useEffect(() => {
    updateClassifications(
      snapshotFormulaField,
      snapshotClassificationsField,
      'snapshotClassifications'
    );
  }, [snapshotClassificationsField, snapshotFormulaField, updateClassifications]);

  useEffect(() => {
    updateClassifications(
      magnitudeFormulaField,
      magnitudeClassificationsField,
      'magnitudeClassifications'
    );
  }, [magnitudeClassificationsField, magnitudeFormulaField, updateClassifications]);

  useEffect(() => {
    if (isUndefined(triggerNameField)) return;
    const triggerCategory = triggerCategories?.find((item) => item.id === triggerNameField?.id);
    setValue('description', triggerCategory?.description);
  }, [triggerNameField, setValue, triggerCategories]);

  useEffect(() => {
    if (triggerCategoryLoading || !triggerCategories?.length) return;
    const controller = new AbortController();
    setLoading(true);
    if (!triggerId) setTriggerCategoryAndBreadcrumb(1);
    fetchAndSetTriggerDetails(controller.signal);
    return () => controller.abort();
  }, [
    triggerId,
    triggerCategories,
    triggerCategoryLoading,
    fetchAndSetTriggerDetails,
    setTriggerCategoryAndBreadcrumb,
  ]);

  return (
    <Box {...extra}>
      <Typography
        variant="body2"
        fontSize="1rem"
        mt={'20px'}
        mb={'10px'}
        sx={{ color: ' #0B2547', opacity: 0.68, fontSize: '16px' }}
      >
        Trigger Details
      </Typography>
      <Divider />
      <FormProvider {...form}>
        <form autoComplete="off" noValidate>
          <Box
            display="grid"
            gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
            gap="10px"
            paddingTop="10px"
            paddingX="10px"
          >
            <CustomSelect
              disabled={!!triggerId}
              name="triggerCategory"
              label="Trigger Name *"
              options={triggerCategories || []}
              loading={triggerCategoryLoading}
              getKey="id"
              getLabel="name"
              freeSolo={true}
              placeholder="Select Trigger Name"
              rules={{ required: 'Trigger name is required' }}
            />

            <CustomInput
              name="cooldownTimer"
              label="Cooldown Timer *"
              placeholder="Enter cooldown timer"
              type="number"
              InputProps={{
                endAdornment: (
                  <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                ),
              }}
              rules={{
                required: 'Cooldown Timer is required',
                validate: {
                  cooldownTimer: (value) =>
                    value < 1 || value > 3600
                      ? 'Cooldown Timer should be between 1 to 3600'
                      : undefined,
                },
              }}
            />

            <CustomSelect
              name="mediaType"
              label="Media Type *"
              options={TRIGGERS_MEDIA_TYPES}
              placeholder="Select media type"
              rules={{ required: 'Media type is required' }}
            />

            <CustomSelect
              name="uploadStrategy"
              label="Upload Strategy *"
              options={TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS}
              placeholder="Select upload strategy"
              rules={{ required: 'Upload strategy is required' }}
            />

            <CustomSelect
              name="chime"
              label="Chime"
              options={TRIGGER_CHIME_OPTIONS}
              placeholder="Select chime"
            />

            <CustomInput
              name="preBuffer"
              label="Pre Buffer *"
              placeholder="Pre Buffer"
              type="number"
              InputProps={{
                endAdornment: (
                  <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                ),
              }}
              rules={{
                required: 'Pre Buffer is required',
                validate: {
                  cooldownTimer: (value) =>
                    value < 1 || value > 50 ? 'Pre Buffer should be between 1 to 50' : undefined,
                },
              }}
            />

            <CustomInput
              name="postBuffer"
              label="Post Buffer *"
              placeholder="Post Buffer"
              type="number"
              InputProps={{
                endAdornment: (
                  <Typography sx={{ fontSize: '12px', color: '#8492a3' }}>seconds</Typography>
                ),
              }}
              rules={{
                required: 'Post Buffer is required',
                validate: {
                  cooldownTimer: (value) =>
                    value < 1 || value > 50 ? 'Post Buffer should be between 1 to 50' : undefined,
                },
              }}
            />

            <CustomSelect
              name="mediaSource"
              label="Uses cameras"
              options={MEDIA_SOURCE_OPTIONS}
              placeholder="Select media source"
            />

            <CustomSelect
              name="primarySnapshotImager"
              label="Default Snapshot Imager"
              options={MEDIA_SOURCE_OPTIONS}
              placeholder="Select Snapshot Imager"
            />

            <CustomInput
              name="tts"
              label="Voice Alert"
              placeholder="Voice alert for your Driver"
              TextFieldProps={{
                multiline: false,
                inputProps: {
                  maxLength: 30,
                },
              }}
              rules={{
                validate: {
                  tts: (value) => (value?.length > 30 ? 'Maximum length is 30' : undefined),
                },
              }}
            />
          </Box>

          {hasDescriptionEditAccess && isObject(triggerNameField) && (
            <Box p={1.5}>
              <CustomInput
                name="description"
                label="Description"
                placeholder="Enter the trigger description"
                TextFieldProps={{
                  multiline: true,
                  minRows: 3,
                }}
              />
            </Box>
          )}

          <Box pt={2}>
            <CustomSwitch name="systemTrigger" label="Is System Trigger?" />
          </Box>

          <Box
            pt={2.5}
            px={1.5}
            mt="20px"
            borderRadius="11px"
            minHeight="137px"
            py={2}
            rowGap={2}
            bgcolor="#FAFAFA"
          >
            <ExpressionGenerator
              labels={labels}
              itemLabel={'Trigger Formula *'}
              isRequired={true}
              keyName={'displayFormula'}
            />
            <CustomSelect
              name="classifications"
              label="Labels"
              getKey="label"
              getLabel="name"
              loading={labels?.length <= 0}
              options={labels?.length > 0 ? labels : []}
              placeholder="Select Labels"
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Box>

          <Box
            pt={2.5}
            px={1.5}
            mt="20px"
            borderRadius="11px"
            minHeight="137px"
            py={2}
            rowGap={2}
            bgcolor="#FAFAFA"
          >
            <ExpressionGenerator
              labels={labels}
              itemLabel={'Magnitude Formula'}
              keyName={'magnitudeFormula'}
            />

            <CustomSelect
              name="magnitudeClassifications"
              label="Labels"
              getKey="label"
              getLabel="name"
              loading={labels?.length <= 0}
              options={labels?.length > 0 ? labels : []}
              placeholder="Select Labels"
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Box>

          <Box
            pt={2.5}
            px={1.5}
            mt="20px"
            borderRadius="11px"
            minHeight="137px"
            py={2}
            rowGap={2}
            bgcolor="#FAFAFA"
          >
            <ExpressionGenerator
              labels={labels}
              itemLabel={'Snapshot Formula'}
              keyName={'snapshotFormula'}
            />
            <CustomSelect
              name="snapshotClassifications"
              label="Labels"
              getKey="label"
              getLabel="name"
              loading={labels?.length <= 0}
              options={labels?.length > 0 ? labels : []}
              placeholder="Select Labels"
              AutocompleteProps={{
                multiple: true,
              }}
            />
          </Box>

          <Box display={'flex'} justifyContent={'flex-end'} gap={'5px'} mb={15} mt={3}>
            <Button variant="text" onClick={handleCancel}>
              {t(T['button.cancel'])}
            </Button>
            <Button
              disabled={isLoading || loading}
              variant="contained"
              onClick={form.handleSubmit(prepareAndSubmit)}
            >
              {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
              {triggerId ? t(T['button.update']) : t(T['button.save'])}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
