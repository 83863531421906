export class NiceBBoxRenderer {
  /**
   * @param {import('./_nice').NiceBoundingBoxRenderer} parent
   */
  constructor(parent) {
    this.parent = parent;
    this.canvas = parent.canvas;
    this.ctx = parent.ctx;
    this.options = parent.options;
  }

  /**
   * Draw Legacy Bounding box
   * @param {Array<RenderBoxData>} boxes
   */
  render(boxes) {
    // sort boxes by area (triggered boxes are placed last)
    boxes.sort((a, b) => {
      const d = a.area - b.area;
      if (a.triggered !== b.triggered) {
        return a.triggered ? 1 : -1;
      } else {
        return d;
      }
    });

    // render boxes from small to big
    for (const box of boxes) {
      this._renderBox(box);
    }
  }

  /**
   * Draw Nice Bounding box
   * @param {RenderBoxData} box
   */
  _renderBox(box) {
    const { scale } = this.options;
    const { x, y, width, height, ratio, color } = box;

    // scale based canvas size
    const size = (1 - Math.log(ratio)) / Math.log(4);
    let r, gap, lineWidth;
    r = Math.min(width, (18 * scale) / size);
    gap = (6 * scale) / size;
    lineWidth = (8 * scale) / size;

    this.ctx.save();
    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = color;
    this.ctx.shadowBlur = 4 * scale;
    this.ctx.shadowColor = '#000';
    this.ctx.lineWidth = lineWidth;

    // Note: Draws a blur
    // this.ctx.save();
    // this.ctx.fillStyle = color + '15';
    // this.ctx.roundRect(x - r, y - r, width + 2 * r, height + 2 * r, r);
    // this.ctx.fill();
    // this.ctx.restore();

    // bottom-left
    this.ctx.beginPath();
    if (r < width) {
      this.ctx.lineTo(x + r, y + height + r); // right
    }
    this.ctx.arc(x, y + height, r, 0.5 * Math.PI, Math.PI);
    this.ctx.lineTo(x - r, y + height - r); // top
    if (2.5 * r + gap < height) {
      this.ctx.moveTo(x - r, y + height - r - gap); // top gap
      this.ctx.lineTo(x - r, y + height - 2.5 * r - gap); // top line
    }
    this.ctx.stroke();
    this.ctx.closePath();

    // bottom-right
    this.ctx.beginPath();
    this.ctx.lineTo(x + width + r, y + height - r); // top
    this.ctx.arc(x + width, y + height, r, 0, 0.5 * Math.PI);
    if (r < width) {
      this.ctx.lineTo(x + width - r, y + height + r); // left
    }
    if (2.5 * r + gap < height) {
      this.ctx.moveTo(x + width + r, y + height - r - gap); // top gap
      this.ctx.lineTo(x + width + r, y + height - 2.5 * r - gap); // top line
    }
    this.ctx.stroke();
    this.ctx.closePath();

    if (!box.hasCar || box.triggered) {
      // top-left
      this.ctx.beginPath();
      this.ctx.lineTo(x - r, y + r); // bottom
      this.ctx.arc(x, y, r, Math.PI, 1.5 * Math.PI);
      if (r < width) {
        this.ctx.lineTo(x + r, y - r); // right
      }
      if (2.5 * r + gap < height) {
        this.ctx.moveTo(x - r, y + r + gap); // bottom gap
        this.ctx.lineTo(x - r, y + 2.5 * r + gap); // bottom line
      }
      this.ctx.stroke();
      this.ctx.closePath();

      // top-right
      this.ctx.beginPath();
      if (r < width) {
        this.ctx.lineTo(x + width - r, y - r); // left
      }
      this.ctx.arc(x + width, y, r, 1.5 * Math.PI, 0);
      this.ctx.lineTo(x + width + r, y + r); // bottom
      if (2.5 * r + gap < height) {
        this.ctx.moveTo(x + width + r, y + r + gap); // bottom gap
        this.ctx.lineTo(x + width + r, y + 2.5 * r + gap); // bottom line
      }
      this.ctx.stroke();
      this.ctx.closePath();
    }
    this.ctx.restore();
  }
}
