/**
 * Parse the access token and retrieve the data
 * @param {string} token
 */
export function parseJwtToken(token) {
  try {
    const parts = token.split('.');
    const json = window.atob(parts[1]);
    return JSON.parse(json);
  } catch {
    return null;
  }
}
