export const TRIGGER_COLOR = {
  RED: '#e32a28',
  BLUE: '#608ac3',
  // SHALLOW_RED: '#FF8A8A',//shallow red
  SHALLOW_RED: '#ECF0F4',
};

export const LANGUAGE_MENU_ITEMS = [
  { label: 'Turn On', value: 'EN' },
  // { label: 'French', value: 'FR' },
  // { label: 'Spanish', value: 'SP' },
  { label: 'Turn Off', value: 'TURN_OFF' },
];

export const BUFFER_MENU_ITEMS = [
  { label: 'Seconds', value: 'SECONDS' },
  // { label: 'Minutes', value: 'MINUTES' },
];

export const COOLDOWN_MENU_ITEMS = [
  { label: 'Seconds', value: 'SECONDS' },
  // { label: 'Minutes', value: 'MINUTES' },
  { label: 'Turn Off', value: 'TURN_OFF' },
];

export const TRIGGER_SENSITIVITY_CHIME_OPTIONS = [
  { label: 'Chime 1', value: 'Chime1' },
  { label: 'Chime 2', value: 'Chime2' },
];

export const TRIGGER_SENSITIVITY_UPLOAD_TYPE_OPTIONS = [
  { label: 'Camera', value: 'SD_CARD' },
  { label: 'Cloud', value: 'ON_CLOUD' },
];

export const TRIGGER_SENSITIVITY_MEDIA_TYPES = [
  { value: 'VIDEO', label: 'Video' },
  { value: 'SNAPSHOT', label: 'Snapshot' },
];

export const SENSITIVITY_MEDIA_MAPPING = {
  NONE: ['NONE'],
  SNAPSHOT: ['SNAPSHOT'],
  VIDEO: ['VIDEO'],
  SNAPSHOT_AND_VIDEO: ['SNAPSHOT', 'VIDEO'],
};
