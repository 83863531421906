import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { formatRegionName, getCountryFlagUrlByCode } from '@/utils/country-flags';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CustomSelect } from '@/web/@components/CustomForm';
import { Box, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ChildTenantDataResidency } from '../@components/ChildTenantDataResidency';
import { sortBy } from 'lodash';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { SMARTER_AI_ICON } from '@/assets/constants/images';

export function DataResidencyPage() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [allowedRegions, setAllowedRegions] = useState([]);
  const [childTenants, setChildTenants] = useState([]);

  const form = useForm({
    mode: 'all',
    defaultValues: {
      region: null,
      childTenantData: [],
    },
  });

  const { reset, formState, watch, getValues, setValue } = form;
  const { isDirty, dirtyFields } = formState;
  const region = watch('region');

  const updateDataResidencyRegion = useCallback(async () => {
    try {
      const region = getValues('region');
      const request = api.ac.v5.tenant.$tenantId(tenantId)['data-residency-region'].$patch({
        headers: {
          Authorization: secretToken,
        },
        data: {
          region: region.code,
        },
      });
      await request.process();
      toastSuccess('Data Residency Region changed successfully');
    } catch (error) {
      toastSuccess('Failed to change Data Residency Region');
    }
  }, [secretToken, tenantId, getValues]);

  const updateChildDataResidencySettings = useCallback(
    async (index) => {
      try {
        const childTenant = childTenants[index];
        if (!childTenant) return;

        // @ts-ignore
        const dataResidencyEnabled = getValues(`childTenantData[${index}].dataResidencyEnabled`);
        const request = api.ac.v5.tenant
          .$tenantId(tenantId)
          .child['change-data-residency-setting'].$patch({
            headers: {
              Authorization: secretToken,
            },
            data: {
              childTenantId: childTenant?.tenantId,
              enabledDataResidency: dataResidencyEnabled,
            },
          });
        await request.process();
        toastSuccess(`Data Residency settings for ${childTenant.tenantName} changed successfully`);
      } catch (error) {
        toastSuccess('Failed to change Data Residency settings');
      }
    },
    [secretToken, tenantId, getValues, childTenants]
  );

  const updateChildDataResidencyRegion = useCallback(
    async (tenantIndex, regionIndex, changes) => {
      try {
        const childTenant = childTenants[tenantIndex];
        const region = allowedRegions[regionIndex];

        if (!childTenant || !region) return;

        const regionVal = getValues(
          // @ts-ignore
          `childTenantData[${tenantIndex}].residency[${regionIndex}].status`
        );
        const defaultVal = getValues(
          // @ts-ignore
          `childTenantData[${tenantIndex}].residency[${regionIndex}].default`
        );

        const request = api.ac.v5.tenant
          .$tenantId(tenantId)
          .child['update-data-residency-region'].$patch({
            headers: {
              Authorization: secretToken,
            },
            data: {
              childTenantId: childTenant?.tenantId,
              region: region.code,
              allowRegion: regionVal,
              setDefault: defaultVal,
            },
          });

        request
          .process()
          .then((data) => {
            // remove the other item selected default value
            if (changes?.default && defaultVal) {
              allowedRegions.forEach((item, index) => {
                if (index !== regionIndex) {
                  // @ts-ignore
                  setValue(`childTenantData[${tenantIndex}].residency[${index}].default`, false);
                }
              });
            }
            toastSuccess(
              `Data Residency Region for ${childTenant.tenantName} changed successfully`
            );
          })
          .catch((ex) => {
            toastWarning(
              ex?.response?.data?.message ||
                `Failed to changed Data Residency Region for ${childTenant.tenantName}`
            );
          });
      } catch (error) {
        toastSuccess('Failed to change Data Residency Region');
      }
    },
    [secretToken, tenantId, getValues, childTenants, allowedRegions, setValue]
  );

  /***********************************************************
   *                                                          *
   *       Watch Form Fields and Update Accordingly           *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!isDirty) return;
    if (dirtyFields?.region) updateDataResidencyRegion();

    dirtyFields?.childTenantData?.forEach((item, index) => {
      //child tenant parent residency
      if (item?.dataResidencyEnabled) {
        updateChildDataResidencySettings(index);
      }
      // Handle Allow deny and default value
      if (item?.residency) {
        item.residency?.forEach((resItem, resIndex) => {
          updateChildDataResidencyRegion(index, resIndex, resItem);
        });
      }
    });

    reset({}, { keepValues: true });
  }, [
    isDirty,
    dirtyFields,
    reset,
    updateDataResidencyRegion,
    updateChildDataResidencySettings,
    updateChildDataResidencyRegion,
  ]);

  /***********************************************************
   *                                                          *
   *     Fetch Allowed Residency Region For Parent Tenant     *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    const request = api.ac.v5.tenant.$tenantId(tenantId)['data-residency']['allowed-region'].$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request.process().then((data) => {
      const regions = data.regionList.map((region) => {
        return {
          code: region,
          label: formatRegionName(region),
        };
      });
      setAllowedRegions(regions || []);
    });
  }, [secretToken, tenantId]);

  /***********************************************************
   *                                                          *
   *                 Fetch Tenant Information                 *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    const request = api.ac.v5.tenant.$tenantId(tenantId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request.process().then((data) => {
      const region = {
        code: data?.tenantRegion,
        label: formatRegionName(data?.tenantRegion),
      };
      form.setValue('region', region);
    });
  }, [secretToken, tenantId, form]);

  /***********************************************************
   *                                                          *
   *                  Fetch Child Tenant List                 *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!allowedRegions?.length) return;
    const request = api.ac.v5.tenant.$tenantId(tenantId).subtenants.$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request.process().then((data) => {
      const sortedData = sortBy(request.result, [
        'tenantStatus',
        (item) => item.tenantName.toLowerCase(),
      ]);
      const childTenantData = sortedData.map((item) => {
        return { residency: allowedRegions, ...item };
      });
      setChildTenants(childTenantData);
      form.setValue('childTenantData', childTenantData);
    });
  }, [secretToken, tenantId, form, allowedRegions]);

  return (
    <Box p={2.5}>
      <FormProvider {...form}>
        <form autoComplete="off" noValidate>
          <Typography variant="subtitle2" fontSize="1rem" fontWeight="500">
            Data Residency
          </Typography>

          <Typography variant="subtitle2" mt={3}>
            Select Tenant Data Residency
          </Typography>
          <Box p={2.5} borderRadius="9px" bgcolor="#F2F7FC">
            <CustomSelect
              name="region"
              label=""
              disabled={allowedRegions.length === 0}
              options={allowedRegions}
              getLabel="label"
              sx={{
                '& .MuiInputBase-root': {
                  background: 'white',
                },
                'width': { xs: '100%', md: '30%' },
              }}
              AutocompleteProps={{
                renderOption: (props, option) => {
                  const icon =
                    // @ts-ignore
                    option?.code !== 'LEGACY'
                      ? // @ts-ignore
                        getCountryFlagUrlByCode(option?.code)
                      : SMARTER_AI_ICON;
                  return (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <IconLabelItem
                        src={icon}
                        title={option.label === 'Eu' ? 'European Union' : option.label}
                        sx={{
                          alignItems: 'center',
                        }}
                        iconSize={{ xs: '17px', md: '25px' }}
                      />
                    </Box>
                  );
                },
                renderInput: (params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: region?.code ? (
                        <img
                          src={
                            region?.code !== 'LEGACY'
                              ? getCountryFlagUrlByCode(region?.code)
                              : '/images/layouts/icon.svg'
                          }
                          height="20"
                          width="34"
                          alt="Legacy"
                        />
                      ) : (
                        ''
                      ),
                    }}
                  />
                ),
              }}
            />
          </Box>

          {childTenants.length > 0 && (
            <>
              <Box pt={3}>
                <Typography variant="subtitle2" fontSize="1rem" fontWeight="500">
                  Child Tenant Data Residency Policies
                </Typography>
              </Box>

              <Box pt={3} display="flex" justifyContent="space-between">
                <Typography variant="subtitle2">Child Tenants</Typography>
                <Typography variant="subtitle2">
                  Set Child Tenant Data Residency Policies
                </Typography>
              </Box>
              <Box
                py="5px"
                pb="100px"
                height="100%"
                overflow="auto"
                sx={{
                  '& .MuiPaper-elevation': {
                    border: '1px solid #c5d9f0',
                    borderRadius: '5px',
                  },
                }}
              >
                <ChildTenantDataResidency />
              </Box>
            </>
          )}
        </form>
      </FormProvider>
    </Box>
  );
}
