import { useFullProductList } from '@/hooks/useFullProductList';
import { formatTimestamp } from '@/utils/datetime';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useMemo } from 'react';
import { DeviceGroupDetailsContext } from '../DeviceGroupDetailsLayout';

export function DeviceGroupInfo() {
  const { result: products } = useFullProductList();
  const { group, loading } = useContext(DeviceGroupDetailsContext);

  const groupProductName = useMemo(
    () => products?.find((item) => item?.productId === group?.associatedTypeId)?.productName || '',
    [products, group]
  );

  return (
    <Box borderRadius="20px">
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        minHeight="73px"
        rowGap={2}
      >
        <Box>
          <Typography variant="body2">Camera Group Name</Typography>
          <Typography variant="body1">
            {loading ? <CircularProgress size={20} /> : group?.name}
          </Typography>
        </Box>
        <Box mb="10px">
          <Typography variant="body2">Product</Typography>
          <Typography variant="body1">
            {loading ? <CircularProgress size={20} /> : groupProductName || ''}
          </Typography>
        </Box>
        <Box mb="10px">
          <Typography variant="body2">Created Date</Typography>
          <Typography variant="body1">
            {loading ? <CircularProgress size={20} /> : formatTimestamp(group?.createdTime)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
