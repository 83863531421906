import api from '@/api';
import {
  PERSONA_AGGRESSIVE_ICON,
  PERSONA_COMPLIANT_ICON,
  PERSONA_DISTRACTED_ICON,
  PERSONA_EFFICIENT_ICON,
  PERSONA_ICON,
  PERSONA_NEAR_COLLISION_ICON,
  PERSONA_OVERALL_SAFETY_ICON,
  PERSONA_SPEEDY_ICON,
} from '@/assets/constants/images';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { SearchField } from '@/web/@components/SearchField';
import { Add, ArrowBack } from '@mui/icons-material';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PersonaCreateModal } from '../PersonaCreateModal';

export function PersonaList() {
  const navigate = useNavigate();

  const [filter, setFilter] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleBackToScoring = () => {
    navigate('/safety/scoring');
  };

  const getIconByName = (personaName) => {
    if (personaName === 'Speedy') return PERSONA_SPEEDY_ICON;
    else if (personaName === 'Near Collision') return PERSONA_NEAR_COLLISION_ICON;
    else if (personaName === 'Efficient') return PERSONA_EFFICIENT_ICON;
    else if (personaName === 'Distracted') return PERSONA_DISTRACTED_ICON;
    else if (personaName === 'Compliant') return PERSONA_COMPLIANT_ICON;
    else if (personaName === 'Aggressive') return PERSONA_AGGRESSIVE_ICON;
    else if (personaName === 'Overall Safety') return PERSONA_OVERALL_SAFETY_ICON;
    else return PERSONA_ICON;
  };

  const {
    result: personaList,
    loading: personaLoading,
    retry: fetchPersona,
  } = useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.v5.persona.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      return result.list?.map((item) => {
        if (item?.id === 1) {
          item['name'] = 'Overall Safety';
        }
        return {
          ...item,
          icon: getIconByName(item?.name),
        };
      });
    },
    [],
    { cache: false }
  );

  const filteredResults = useMemo(() => {
    const searchBy = filter.trim().toLowerCase();
    if (!searchBy || !personaList.length) return personaList || [];
    return personaList.filter((item) => item.name.toLowerCase().includes(searchBy));
  }, [filter, personaList]);

  return (
    <Box px={2.5}>
      {showCreateModal && (
        <PersonaCreateModal
          onHide={(isRefresh) => {
            setShowCreateModal(false);
            isRefresh && fetchPersona();
          }}
        />
      )}

      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: '0.875rem', color: '#4177BF' }}
            startIcon={<ArrowBack />}
            onClick={handleBackToScoring}
          >
            Back to scoring
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-around" gap={1}>
          <SearchField
            clearable
            searchOnEnter
            value={filter}
            onSearch={setFilter}
            placeholder={'Search'}
            InputProps={{
              // @ts-ignore
              onInput: (event) => setFilter(event.target.value),
            }}
            sx={{
              borderColor: 'transparent',
              borderRadius: '6px',
              background: '#f7f7f7',
              width: { xs: '100%', sm: '50%', md: 280 },
            }}
          />
          <Button
            variant="contained"
            startIcon={<Add sx={{ fontSize: '14px' }} />}
            onClick={() => setShowCreateModal(true)}
          >
            Persona
          </Button>
        </Box>
      </Box>

      <Box>
        {personaLoading ? (
          <CenterBox>
            <CircularProgress />
          </CenterBox>
        ) : (
          filteredResults?.map((item, index) => (
            <Paper
              key={index}
              elevation={0}
              sx={{
                p: 1,
                my: 1,
                height: {
                  xs: 'fit-content',
                  md: '48px',
                },
                border: '1px solid #E0ECFC',
                borderRadius: '6px',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                }}
                alignItems="center"
                px={2}
              >
                <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '500px' }}>
                  <IconLabelItem src={item?.icon} title={item.name} sx={{ alignItems: 'center' }} />
                </Typography>
              </Box>
            </Paper>
          ))
        )}
      </Box>
    </Box>
  );
}
