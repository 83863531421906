import { T } from '@/assets/locales';
import { SafetyPage } from '.';
import { SafetyScoreLayout } from './scoring/@layouts/SafetyScoreLayout';
import { CoachingLayout } from './scoring/@layouts/CoachingLayout';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <SafetyPage />,
  },
  {
    path: 'scoring',
    element: <SafetyScoreLayout />,
    children: require('./scoring/routes').default,
    breadcrumb: T['navigation.scoring'],
  },
  {
    path: 'reviews',
    children: require('./review/routes').default,
    breadcrumb: T['navigation.review'],
  },
  {
    path: 'coaching',
    element: <CoachingLayout />,
    children: require('./coaching/routes').default,
    breadcrumb: T['navigation.coaching'],
    serviceAccessKey: 'COACHING',
  },
];

export default routes;
