import { T } from '@/assets/locales';
import { AuthLayout } from '@/web/@layouts/AuthLayout';
import { MainLayout } from '@/web/@layouts/MainLayout';
import { Navigate } from 'react-router-dom';
import { EmptyLayout } from './@layouts/EmptyLayout';

/** @type {Array<import('@/types').ExtendedRoute>} */
export const routes = [
  {
    path: '/auth',
    element: <AuthLayout />,
    children: require('./auth/routes').default,
  },
  {
    path: '/cameras',
    element: <MainLayout />,
    breadcrumb: T['navigation.cameras'],
    children: require('./cameras/routes').default,
  },
  {
    path: '/events',
    element: <MainLayout />,
    breadcrumb: T['navigation.events'],
    children: require('./events/routes').default,
  },
  {
    path: '/trips',
    element: <MainLayout />,
    breadcrumb: T['navigation.trips'],
    children: require('./trips/routes').default,
  },
  {
    path: '/fleets',
    element: <MainLayout />,
    children: require('./fleets/routes').default,
    breadcrumb: T['navigation.fleets'],
  },
  {
    path: '/safety',
    element: <MainLayout />,
    children: require('./safety/routes').default,
    breadcrumb: T['navigation.safety'],
  },
  {
    path: '/operations',
    element: <MainLayout />,
    children: require('./operations/routes').default,
    breadcrumb: T['navigation.operations'],
    accessLevel: 'ADMIN',
  },
  {
    path: '/administration',
    element: <MainLayout />,
    children: require('./administration/routes').default,
    breadcrumb: T['navigation.administration'],
    accessLevel: 'ADMIN',
  },
  {
    path: '/smarter-ai',
    element: <MainLayout />,
    children: require('./smarter-ai/routes').default,
    breadcrumb: T['navigation.smarter.ai'],
    accessLevel: 'SMARTER_AI_ADMIN',
  },
  // {
  //   path: '/winter-operations',
  //   element: <MainLayout />,
  //   children: require('./winter-operations/routes').default,
  //   breadcrumb: T['navigation.winter.operations'],
  //   accessLevel: 'SUPER_ADMIN',
  // },
  // {
  //   path: '/waste-management',
  //   element: <MainLayout />,
  //   children: require('./waste-management/routes').default,
  //   breadcrumb: T['navigation.waste.management'],
  //   accessLevel: 'SUPER_ADMIN',
  // },
  {
    path: '/support',
    element: <MainLayout />,
    children: require('./support/routes').default,
    breadcrumb: T['navigation.support'],
  },
  {
    path: '/data-privacy',
    element: <MainLayout />,
    children: require('./data-privacy/routes').default,
    breadcrumb: T['navigation.privacy.and.compliance'],
    accessLevel: 'SUPER_ADMIN',
  },
  {
    path: '/stream/live',
    element: <Navigate to={`/cameras/live${window.location.search}`} replace />,
  },
  {
    path: '/stream/event',
    element: <Navigate to={`/events/play${window.location.search}`} replace />,
  },
  {
    path: '/stream/registration',
    element: <Navigate to={`/fleets/drivers/registration${window.location.search}`} replace />,
  },
  {
    path: '/install',
    element: <EmptyLayout />,
    children: require('./install/routes').default,
  },
];
