import { isExactMatch } from '@/utils/routing';
import { createContext, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @typedef {object} MainContextParams
 * @property {Array<BreadcrumbItem>} breadcrumbs Current breadcrumbs
 * @property {StateAction<Array<BreadcrumbItem>>} setBreadcrumbs Set the current breadcrumbs
 * @property {(title: string, matcher?: (path: string) => boolean) => any} setBreadcrumbTitle Set the breadcrumb title
 */

/** @type {import('react').Context<MainContextParams>} */
export const MainContext = createContext(null);

/** @param {{children: import('react').ReactNode}} props */
export function BreadcrumbContextProvider(props) {
  const location = useLocation();

  /** @type {StateVariable<Array<BreadcrumbItem>>} */
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const setBreadcrumbTitle = useCallback(
    /** @type {MainContextParams['setBreadcrumbTitle']} */
    (title, matcher) => {
      matcher ||= (path) => isExactMatch(location.pathname, path);
      setBreadcrumbs((list) =>
        list.map((item) => ({
          ...item,
          name: matcher(item.pathname) ? title : item.name,
        }))
      );
    },
    [location.pathname]
  );

  return (
    <MainContext.Provider
      {...props}
      value={{
        breadcrumbs,
        setBreadcrumbs,
        setBreadcrumbTitle,
      }}
    />
  );
}
