import { useRouteQuery } from '@/hooks/useRouteQuery';
import { Box } from '@mui/material';
import { useState } from 'react';
import { CameraCountHeader } from './@components/CameraCountHeader';
import { CameraListPage } from './@components/CameraListPage';

export function CamerasPage() {
  const { query } = useRouteQuery();
  const [count, setCount] = useState(null);

  if (query.layout === 'empty') {
    return <CameraListPage fullHeight />;
  }

  return (
    <>
      <Box px={2.5} mt={1} display="flex" gap={1} alignItems="center">
        <CameraCountHeader count={count} />
        <Box flex={1} />
      </Box>
      <Box id="camera-list">
        <CameraListPage onCountUpdate={setCount} />
      </Box>
    </>
  );
}
