import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/**
 * Fetch list of All User
 */
export function useFetchDrivers() {
  return useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      const limit = 20;
      let offset = 0;
      /** @type {Array<Driver>} */
      let results = [];
      while (!signal.aborted) {
        const request = api.ac.v5.driver.list.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            limit,
            offset,
            tenantId,
          },
        });
        await request.process();
        if (!request.result) break;
        offset = offset + limit;
        const drivers = request.result.drivers;
        results.push(...drivers);
        if (drivers.length < limit) break;
      }
      return results;
    },
    [],
    { cache: true }
  );
}
