import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { SchemeEffectivePropertiesForm } from './@components/EffectiveView';
import { transformAndFormatEffectiveSchemeData } from './utils';

export function EffectiveSchemes() {
  const { cameraId, camera } = useContext(CameraDetailsContext);

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [loading, setLoading] = useState(true);
  const [triggers, setTriggers] = useState([]);
  const [settings, setSettings] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [aiContainers, setAiContainers] = useState([]);
  const [productInputs, setProductInputs] = useState([]);
  /** @type {StateVariable<Array<DbcFileDeployedDto>>} */
  // eslint-disable-next-line no-unused-vars
  const [dbcFiles, setDBCFiles] = useState([]);

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      properties: [],
    },
  });

  const fetchSchemeTriggers = async () => {
    try {
      const request = api.ac.v5.trigger.composite.endpoint.$endpointId(cameraId).info.$get({
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      setTriggers(result.triggers);
      return result.triggers || [];
    } catch (error) {
      return [];
    }
  };

  const fetchSchemeFirmwares = async () => {
    try {
      const request = api.ac.v5.firmware.endpoint.$endpointId(cameraId).$get({
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      setSettings(result.list);
      return result.list || [];
    } catch (error) {
      return [];
    }
  };

  const fetchSchemeSettings = async () => {
    try {
      const request = api.ac.v5.endpoint.$endpointId(cameraId)['effective-settings'].$get({
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      setSettings(result.properties);
      return result.properties || [];
    } catch (error) {
      return [];
    }
  };

  const fetchSchemeAiContainers = async () => {
    try {
      const request = api.ac.v5.aicontainer.endpoint.$endpointId(cameraId).$get({
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request.process();
      setAiContainers(result.list);
      return result.list || [];
    } catch (error) {
      return [];
    }
  };

  const fetchSchemeDBCFiles = async () => {
    try {
      const request = api.ac.v5.dbc
        .$targetLevel('ENDPOINT')
        .$targetId(cameraId + '')
        .$get({
          headers: {
            Authorization: secretToken,
          },
        });
      const result = await request.process();
      setDBCFiles([result]);
      return [result] || [];
    } catch (error) {
      return [];
    }
  };

  const fetchProductInfo = async () => {
    try {
      const request = api.ac.v1.tenant.product.$get({
        params: {
          productId: camera.productId,
          secretToken,
          tenantId,
        },
      });
      const result = await request.process();
      const audioVideoInputCodes = result?.inputs?.filter((item) =>
        ['AUDIO', 'VIDEO'].includes(item.inputType)
      );
      setProductInputs(audioVideoInputCodes);
      return audioVideoInputCodes || [];
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      const schemeTriggers = await fetchSchemeTriggers();
      const schemeSettings = await fetchSchemeSettings();
      const schemeFirmwares = await fetchSchemeFirmwares();
      const schemeProductInputs = await fetchProductInfo();
      const schemeAiContainers = await fetchSchemeAiContainers();
      const schemeDBCFiles = await fetchSchemeDBCFiles();
      const formattedData = await transformAndFormatEffectiveSchemeData(
        schemeTriggers,
        schemeFirmwares,
        schemeSettings,
        schemeAiContainers,
        schemeDBCFiles,
        schemeProductInputs
      );
      form.setValue('properties', formattedData);
      setLoading(false);
    };
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={2.5} pb={14}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Effective Scheme
      </Typography>
      {loading ? (
        <CenterBox fullView>
          <CircularProgress />
        </CenterBox>
      ) : (
        <FormProvider {...form}>
          <SchemeEffectivePropertiesForm
            triggers={triggers}
            settings={settings}
            productInputs={productInputs}
          />
        </FormProvider>
      )}
    </Box>
  );
}
