import api from '@/api';
import {
  TRIGGER_FEMALE_ICON,
  TRIGGER_MALE_ICON,
  TRIGGER_NEUTRAL_ICON,
} from '@/assets/constants/images';
import { useLanguageRegion } from '@/hooks/useLanguageRegion';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { getCountryFlagIconByCountryName } from '@/utils/country-flags';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { Pause, PlayArrow } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GENDER_ITEMS = [
  {
    key: 'MALE',
    label: 'Male',
    tts: 'Assertive',
    icon: TRIGGER_MALE_ICON,
  },
  {
    key: 'NEUTRAL',
    label: 'Neutral',
    tts: 'Soft',
    icon: TRIGGER_NEUTRAL_ICON,
  },
  {
    key: 'FEMALE',
    label: 'Female',
    tts: 'Soft',
    icon: TRIGGER_FEMALE_ICON,
  },
];

const DEFAULT_TEXT = 'put your phone down';

const GroupHeader = styled('div')(({ theme }) => ({
  // position: 'sticky',
  top: '-18px',
  paddingLeft: '16px',
  color: theme.palette.primary.main,
}));

const GroupItems = styled('ul')({ padding: 0 });

export function TriggerParentTtsForm() {
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const { result: languages, loading: languageLoading } = useLanguageRegion();
  const { setBreadcrumbTitle } = useContext(MainContext);

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [preview, setPreview] = useState({});
  const [currentPlayingKey, setCurrentPlayingKey] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAccent, setSelectedAccent] = useState(null);

  const handleOnCancel = () => {
    navigate('/administration/trigger-configuration');
  };

  const handleApply = async () => {
    try {
      setLoading(true);
      const data = {
        triggerTtsGender: selectedGender,
        triggerTtsLanguageLocale: selectedAccent?.value,
      };

      const request = api.ac.v5.tenant.settings.$post({
        headers: {
          Authorization: secretToken,
        },
        data,
      });

      await request?.process();
      toastSuccess('Success', 'Incab coach settings updated successfully!');
      navigate('/administration/trigger-configuration');
    } catch (error) {
      toastWarning('Error', 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const handleGeneratePreviewUrl = async (gender) => {
    setPreview((prevState) => ({
      ...prevState,
      [gender]: { loading: true, url: null },
    }));

    try {
      const data = {
        ttsGender: gender,
        ttsLanguageLocale: selectedAccent?.value,
        text: DEFAULT_TEXT,
      };

      const request = api.ac.v5.text.preview.$post({
        headers: {
          Authorization: secretToken,
        },
        // @ts-ignore
        data,
      });

      const result = await request?.process();

      setPreview((prevState) => ({
        ...prevState,
        [gender]: { url: result?.audioUrl, loading: false },
      }));
      setIsPlaying(true);
      setCurrentPlayingKey(gender);
    } catch (ex) {
      setPreview((prevState) => ({
        ...prevState,
        [gender]: { loading: false, url: null },
      }));
    }
  };

  const handleAccentChange = (event, newData) => {
    setSelectedAccent(newData);
  };

  useEffect(() => {
    const handleAudioEnd = () => {
      setIsPlaying(false);
      setCurrentPlayingKey(null);
    };
    const cleanupAudio = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
        audioRef.current.removeEventListener('ended', handleAudioEnd);
      }
    };

    if (preview[currentPlayingKey]?.url) {
      cleanupAudio();
      audioRef.current = new Audio(preview[currentPlayingKey]?.url);
      audioRef.current.addEventListener('ended', handleAudioEnd);
      if (isPlaying) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
          setIsPlaying(false);
        });
      }
    } else {
      cleanupAudio();
    }

    return () => cleanupAudio();
  }, [preview, currentPlayingKey, isPlaying]);

  useEffect(() => {
    setPreview({
      MALE: { loading: false, url: null },
      FEMALE: { loading: false, url: null },
      NEUTRAL: { loading: false, url: null },
    });
  }, [selectedAccent, selectedGender, tenantId]);

  const getTenantSettings = useCallback(async () => {
    try {
      if (!languages?.length) return;
      setInitLoading(true);
      const request = api.ac.v5.tenant.settings.$get({
        headers: {
          Authorization: secretToken,
        },
      });
      const result = await request?.process();
      const item = languages?.find((i) => i?.value === result?.triggerTtsLanguageLocale);
      item && setSelectedAccent(item);
      setSelectedGender(result?.triggerTtsGender);
      setInitLoading(false);
    } catch (ex) {
      console.error(ex);
      setInitLoading(false);
    }
  }, [secretToken, languages]);

  useEffect(() => {
    getTenantSettings();
  }, [getTenantSettings]);

  useEffect(() => {
    setBreadcrumbTitle('Incab Coach Settings');
  }, [setBreadcrumbTitle]);

  return (
    <Box width={{ xs: '100%', md: '750px' }} p={2.5}>
      <Typography variant="subtitle2" fontSize={'1rem'} fontWeight={600}>
        Incab Coach Settings
      </Typography>
      <Typography pt={2} fontSize="0.82rem" variant="subtitle2">
        Customize your incab coach's voice by adjusting language, accent, and gender settings.
        Preview the voice by clicking the play button.
      </Typography>

      {initLoading || languageLoading ? (
        <CenterBox style={{ height: '50vh' }}>
          <CircularProgress />
        </CenterBox>
      ) : (
        <Box>
          <Box gap={1} pt={2}>
            <FormControl sx={{ width: '100%' }} size="medium">
              <Typography variant="subtitle2" pb={1}>
                Language
              </Typography>

              <Autocomplete
                id="grouped-demo"
                forcePopupIcon={true}
                loading={languageLoading}
                disableClearable
                options={languages?.sort((a, b) => -b.languageName.localeCompare(a.languageName))}
                groupBy={(option) => option.languageName}
                getOptionLabel={(option) => option.label}
                sx={{ width: '100%' }}
                value={selectedAccent}
                onChange={handleAccentChange}
                renderGroup={(params) => (
                  <li key={params.key} style={{ paddingBottom: '15px' }}>
                    <GroupHeader>
                      <Typography color="#768498">{params.group}</Typography>
                    </GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props} height="32px" gap={1}>
                      <img
                        src={getCountryFlagIconByCountryName(option.label)}
                        height="16px"
                        width="28px"
                        alt={option.label}
                      />
                      <Typography>{option.label}</Typography>
                    </Box>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      placeholder="Select a language"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: selectedAccent?.languageName ? (
                          <Box gap={1} display="inline-flex" alignItems="center">
                            <img
                              src={getCountryFlagIconByCountryName(selectedAccent?.label)}
                              height="16px"
                              width="28px"
                              alt={selectedAccent?.label}
                            />
                            <Typography {...params} fontSize={'.875rem'} sx={{ opacity: '0.6' }}>
                              {selectedAccent?.languageName}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        ),
                      }}
                    />
                  );
                }}
              />
            </FormControl>

            <Box pt={3}>
              <Typography variant="subtitle2" pb={1}>
                Gender
              </Typography>
              <Grid container spacing={2}>
                {GENDER_ITEMS?.map((item) => (
                  <Grid item xs={12} sm={4} key={item?.key}>
                    <Box
                      onClick={() => setSelectedGender(item?.key)}
                      display="flex"
                      flexDirection="column"
                      alignContent="center"
                      height="250px"
                      alignItems="center"
                      gap={4.5}
                      borderRadius="18px"
                      pt={'20px'}
                      sx={{ backgroundColor: selectedGender !== item?.key ? 'white' : '#F8FCFF' }}
                      border={
                        selectedGender !== item?.key ? '1px solid #C5D9F0' : '2px solid #608AC3'
                      }
                    >
                      <Typography fontWeight={500}>{item?.label}</Typography>
                      <BoxImage src={item?.icon}></BoxImage>
                    </Box>

                    <Box mb={{ xs: '50px', md: '10px' }}>
                      <IconButton
                        sx={{
                          background: '#F5FAFF',
                          border: '1px solid #C5D9F0',
                          mt: '-70px !important',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '0 auto',
                        }}
                        onClick={() => handleGeneratePreviewUrl(item.key)}
                        color="primary"
                        aria-label={`Play ${item.label} audio`}
                      >
                        {preview[item.key]?.loading ? (
                          <CircularProgress size={24} />
                        ) : isPlaying && currentPlayingKey === item.key ? (
                          <Pause htmlColor="#608AC3" />
                        ) : (
                          <PlayArrow htmlColor="#608AC3" />
                        )}
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" pt={'60px'} gap={0.5} pb={10}>
            <Button onClick={handleOnCancel} size="small" disabled={loading}>
              Cancel
            </Button>

            <Button
              onClick={handleApply}
              variant="contained"
              size="small"
              type="submit"
              disabled={loading || !selectedGender || !selectedAccent}
              startIcon={loading && <CircularProgress size={20} sx={{ color: 'white' }} />}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
