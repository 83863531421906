import { T } from '@/assets/locales';
import { ApkFileLandingPage } from '.';
import { ApkFileDetailsPage } from './_id/details';
import { ApkFileEditPage } from './_id/edit';
import { APKFileCreatePage } from './create';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <ApkFileLandingPage />,
    breadcrumb: 'APK Files',
  },
  {
    path: 'create',
    element: <APKFileCreatePage />,
    breadcrumb: 'Create APK File',
  },
  {
    path: ':id',
    children: [
      {
        path: 'details',
        element: <ApkFileDetailsPage />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit',
        element: <ApkFileEditPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
