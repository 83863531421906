import { ANNOTATION_EDIT_ICON, DELETE_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { ConfirmationDialog } from '@/web/@components/ConfirmationDialog';
import { PaginatedTableContext } from '@/web/@components/PaginatedTableContext';
import { DriverItem } from '@/web/fleets/@components/DriverItem';
import { Box, IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * @template T
 * @typedef {object} DriverItemViewProps
 * @property {Driver} [driver]
 * @property {(type: TableHeaderAction<T>['type'], selected: Array<T>, reload: function) => any} [onAction]
 */

/**
 * @template T
 * @param {DriverItemViewProps<T>} props
 */

export function DriverItemView(props) {
  const { driver, onAction } = props;
  const navigate = useNavigate();

  const [display, setDisplay] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openActionType, setOpenActionType] = useState(null);

  const { setSelectedItems, selectedItems, setTableReload } = useContext(PaginatedTableContext);

  const showButton = (e) => {
    e.preventDefault();
    setDisplay(true);
  };

  const hideButton = (e) => {
    e.preventDefault();
    setDisplay(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setOpenActionType(null);
  };

  const handleDialogConfirmation = () => {
    setDialogOpen(false);
    onAction && onAction(openActionType, selectedItems, () => setTableReload(true));
  };

  return (
    <>
      <ConfirmationDialog
        open={dialogOpen}
        message={'Are you sure you want to delete the selected Driver?'}
        onCancel={handleDialogClose}
        onApply={handleDialogConfirmation}
      />
      <Box
        display="grid"
        position="relative"
        gap="5px"
        onMouseEnter={(e) => showButton(e)}
        onMouseLeave={(e) => hideButton(e)}
      >
        <DriverItem driver={driver} />
        <Box
          display={display ? 'grid' : 'none'}
          gap={1}
          sx={{
            'position': 'absolute',
            'top': '5px',
            'right': '5px',
            'zIndex': 100,
            '& .MuiIconButton-root': {
              border: '1px solid white',
              bgcolor: 'white',
            },
          }}
        >
          <IconButton
            onClick={() => {
              setSelectedItems([driver]);
              setOpenActionType('DISCONTINUE');
              setDialogOpen(true);
            }}
          >
            <BoxImage src={DELETE_ICON} size="16px" />
          </IconButton>
          <IconButton onClick={() => navigate(`${driver.driverId}/edit`)}>
            <BoxImage src={ANNOTATION_EDIT_ICON} size="16px" />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
