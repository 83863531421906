import { AI_LABEL_RAW_DATA } from '@/utils/ai-labels';
import { CenterBox } from '@/web/@components/CenterBox';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * @typedef {object} AiLabelSelectionProps
 * @property {GroupSettingsChildItems} item
 */

/** @param {AiLabelSelectionProps} props */
export function AiLabelSelection(props) {
  const { item } = props;
  const { key: name } = item;
  const { getValues, setValue } = useFormContext();

  const [checked, setChecked] = useState([]);
  const initValues = useMemo(() => getValues(name), [name, getValues]);

  const aiLabel = useMemo(() => {
    const labels = AI_LABEL_RAW_DATA.filter(
      (item) => item?.publicationStatus === 'RELEASED' && item?.type === 'Classification'
    );
    return labels?.sort((a, b) => a?.displayName?.localeCompare(b?.displayName));
  }, []);

  const onLabelValueChange = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if (!aiLabel?.length) return;
    const arrayList = initValues ? initValues?.split(',') : [];
    const filteredArray = aiLabel.filter((item, index) => {
      return arrayList.includes(item.name);
    });
    setChecked(filteredArray);
  }, [initValues, aiLabel]);

  useEffect(() => {
    let mappedItems = (checked || []).map((item) => item.name);
    setValue(name, mappedItems?.join());
  }, [checked, setValue, name]);

  return (
    <>
      {aiLabel?.length > 0 ? (
        <Box>
          <Typography variant="body1" fontWeight={500} fontSize="16px">
            AI Labels
          </Typography>
          <Divider />
          <Grid container px="20px" mt="10px">
            {(aiLabel || []).map((label, index) => (
              <Grid item md={4} key={label.type + index}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      key={label.type + index}
                      checked={checked.includes(label)}
                      onChange={() => onLabelValueChange(label)}
                      name={label.name}
                    />
                  }
                  label={label.displayName}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <CenterBox>
          <CircularProgress role="status" /> {'Loading AI labels'}
        </CenterBox>
      )}
    </>
  );
}
