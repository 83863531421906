import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { endOfTheDay, minusOneMonth } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { PageLink } from '@/web/@components/PageLink';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { EventGridListItem } from '@/web/events/@components/EventGridListItem';
import { Box, CircularProgress, Grid, Hidden, Typography } from '@mui/material';
import qs from 'qs';
import { useContext } from 'react';

/** @param {import('@mui/material').BoxProps} props */
export function RecentEventArea(props) {
  const { cameraId } = useContext(CameraDetailsContext);

  const {
    result: events,
    error,
    loading,
  } = useFutureLoader(
    async ({ signal, secretToken, tenantId }) => {
      if (!cameraId) return null;
      const request = api.ac.v5.events.filter.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          pageSize: 100,
          endpointId: cameraId,
          startTimestamp: minusOneMonth(),
          endTimestamp: endOfTheDay(Date.now()),
        },
      });
      await request.process();
      return request.result.events;
    },
    [cameraId]
  );

  if (error) {
    return null;
  }

  return (
    <Box {...props}>
      <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} color="#0B2547" sx={{ opacity: 0.68 }}>
          Recent Events
        </Typography>

        {events?.length > 0 && (
          <PageLink
            fontSize={'0.875rem'}
            to={`./events?${qs.stringify({
              from: minusOneMonth(),
              to: endOfTheDay(Date.now()),
              type: 'triggerName',
            })}`}
          >
            View All Events
          </PageLink>
        )}
      </Box>
      <Grid container columns={60}>
        {loading ? (
          <CenterBox style={{ border: '1px solid #f8f8f8', height: '200px' }}>
            <CircularProgress></CircularProgress>
          </CenterBox>
        ) : !events?.length ? (
          <CenterBox style={{ border: '1px solid #f8f8f8' }}>
            <IconMessageBox size="150px" src="/images/empty/no-events.svg" message="No Events" />
          </CenterBox>
        ) : (
          <>
            {events.length > 0 && (
              <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                <EventGridListItem px={0} mb={0} mx={'2px'} item={events[0]} />
              </Grid>
            )}
            {events.length > 1 && (
              <Hidden smDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[1]} />
                </Grid>
              </Hidden>
            )}
            {events.length > 2 && (
              <Hidden mdDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[2]} />
                </Grid>
              </Hidden>
            )}
            {events.length > 3 && (
              <Hidden lgDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[3]} />
                </Grid>
              </Hidden>
            )}
            {events.length > 4 && (
              <Hidden xlDown>
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <EventGridListItem px={0} mb={0} mx={'2px'} item={events[4]} />
                </Grid>
              </Hidden>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}
