import { USER_FALLBACK_ICON } from '@/assets/constants/images';
import { selectUserAvatar } from '@/store/auth';
import { BoxImage } from '@/web/@components/BoxImage';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {object} UserNoteBoxProps
 * @property {(string) => any} onAdd
 * @property {() => any} onClose
 * @property {boolean} [loading]
 * @property {string} [userName]
 */

/** @param {UserNoteBoxProps} props */
export function UserNoteCreateBox(props) {
  const { onAdd, onClose, loading, userName } = props;

  const userAvatar = useSelector(selectUserAvatar);

  const wrapperRef = useRef();

  /** @type {StateVariable<string>} */
  const [note, setNote] = useState('');

  const handleMenuCloseWhileClickOutside = useCallback(
    (e) => {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current?.contains(e.target)) {
        setTimeout(() => {
          onClose();
        }, 100);
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMenuCloseWhileClickOutside);
    return () => {
      document.removeEventListener('visibilitychange', handleMenuCloseWhileClickOutside);
    };
  }, [handleMenuCloseWhileClickOutside]);

  return (
    <Box
      ref={wrapperRef}
      p={2}
      borderRadius={2}
      border="1px solid #C5D9F0"
      display="flex"
      alignItems="center"
      position="relative"
      gap={1.5}
    >
      <Box>
        <BoxImage
          size="48px"
          height="100%"
          borderRadius="50%"
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
          src={userAvatar}
          fallbackSrc={USER_FALLBACK_ICON}
        />
      </Box>
      <form style={{ width: '100%' }}>
        <Typography variant="body1" fontWeight={500}>
          {userName}
        </Typography>
        <Box display="flex" alignItems="center" gap={1.5}>
          <TextField
            required
            fullWidth
            placeholder="Enter note"
            style={{ flex: 1 }}
            onInput={(e) => {
              // @ts-ignore
              setNote(e?.target?.value);
            }}
          />
          <Button
            disabled={loading || !note}
            variant="contained"
            onClick={() => {
              onAdd(note);
            }}
            startIcon={loading ? <CircularProgress size={18} sx={{ color: 'white' }} /> : null}
          >
            Add
          </Button>
        </Box>
      </form>
    </Box>
  );
}
