import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { PageLink } from '@/web/@components/PageLink';
import { CameraThumbnailProvider } from '@/web/cameras/@components/CameraThumbnailProvider';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import qs from 'qs';
import { FleetCameraGridListItem } from './camera';

/** @param {import('@mui/material').BoxProps} props */
export function FleetRecentCameras(props) {
  const {
    result: cameras = [],
    loading,
    error,
  } = useFutureLoader(
    async ({ signal, tenantId, secretToken }) => {
      let offset = 0;
      const limit = 500;
      /** @type {Array<EndpointInfoAggregated>} */
      const results = [];
      while (true) {
        const request = api.ac.v5.endpoint.list.$get({
          signal: signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            type: 'DEVICE',
            status: 'ACTIVE',
            limit,
            offset,
            tenantId,
          },
        });
        await request.process();
        if (!request.result) break;
        const result = request.result.endpointInfoList;
        results.push(...result);
        if (result.length < limit) break;
        offset += limit;
      }
      return results;
    },
    [],
    { cache: true }
  );

  if (error) {
    return null;
  }

  return (
    <Box {...props}>
      <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} color="#0B2547" sx={{ opacity: 0.68 }}>
          Recent Cameras
        </Typography>

        {cameras?.length > 0 && (
          <PageLink
            fontWeight={600}
            fontSize={'0.875rem'}
            to={`/cameras?${qs.stringify({
              from: startOfTheDay(cameras[0]?.recordingStartTimestamp),
              to: endOfTheDay(Date.now()),
            })}`}
          >
            View All Cameras
          </PageLink>
        )}
      </Box>

      <Grid container columns={60}>
        {loading ? (
          <CenterBox style={{ border: '1px solid #f8f8f8', height: '200px' }}>
            <CircularProgress></CircularProgress>
          </CenterBox>
        ) : !cameras?.length ? (
          <CenterBox style={{ border: '1px solid #f8f8f8' }}>
            <IconMessageBox size="150px" src="/images/empty/no-events.svg" message="No Cameras" />
          </CenterBox>
        ) : (
          <>
            <CameraThumbnailProvider cameras={cameras}>
              {cameras?.map((item, index) => (
                <Grid item xs={60} sm={30} md={20} lg={15} xl={12}>
                  <FleetCameraGridListItem
                    item={item}
                    index={index}
                    style={{ width: '280px' }}
                    p="5px"
                  />
                </Grid>
              ))}
            </CameraThumbnailProvider>
          </>
        )}
      </Grid>
    </Box>
  );
}
