import api from '@/api';
import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { selectSessionId } from '@/store/hub-connection';
import { TimestampStore } from '@/utils/collections/timestamp-store';
import { SentryEvents, reportEvent } from '@/utils/sentry';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { processRecordingThumbnails } from '@/web/@components/LiveStreaming/recordings';
import { CircularProgress } from '@mui/material';
import { isEqual } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TripDetailsCache } from '../TripContext';
import { TripDetailsView } from '../TripDetailsView';
import { TripFilterContext } from '../TripFilterContext';

export function TripDetailsBox() {
  const { tripId } = useContext(TripFilterContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<TripDetailsResponse>} */
  const [selectedTrip, setSelectedTrip] = useState(null);
  /** @type {StateVariable<Array<{ts: number, value: {[key: string]: VideoThumbnailData}}>>} */
  const [thumbnails, setThumbnails] = useState(null);

  // ----------------------------------------------------------------
  // Fetch Trip Details
  // ----------------------------------------------------------------
  useEffect(() => {
    setLoading(true);
    setSelectedTrip(null);
    if (!tripId) return;
    let live = true;
    const process = async () => {
      if (!live) return;
      try {
        const cache = await TripDetailsCache.getItem(tripId);
        setSelectedTrip(cache);
        if (cache) setLoading(false);
        const state = store.getState();
        const request = api.ac.v5.trips.$tripId(tripId).$get({
          headers: {
            Authorization: selectSecretToken(state),
          },
          params: {
            tenantId: selectTenantId(state),
          },
        });
        const result = await request.process();
        if (!result) throw new Error('Empty response');
        if (!isEqual(cache?.geoPoints?.length, result?.geoPoints?.length)) {
          setSelectedTrip(result);
          TripDetailsCache.setItem(tripId, result);
        }
        live = result.tripStatus !== 'ENDED';
      } catch (err) {
        console.error('Failed to load trip details', err);
        reportEvent(SentryEvents.TRIP_LIST_DETAIL_LOAD_FAILED, '', {
          err,
          tags: { tripId },
        });
      } finally {
        setLoading(false);
      }
    };
    process();
    const iid = setInterval(process, 30000);
    return () => {
      setSelectedTrip(null);
      clearInterval(iid);
    };
  }, [tripId]);

  // ----------------------------------------------------------------
  // Fetch Trip Thumbnails
  // ----------------------------------------------------------------

  const startTimestamp = useMemo(() => {
    if (!selectedTrip?.geoPoints?.length) return undefined;
    const points = selectedTrip.geoPoints[selectedTrip.geoPoints.length - 1];
    if (!points?.length) return undefined;
    return Math.floor(points[points.length - 1].timestamp) || 0;
  }, [selectedTrip?.geoPoints]);

  const endTimestamp = useMemo(() => {
    if (!selectedTrip?.geoPoints?.length) return undefined;
    const points = selectedTrip.geoPoints[0];
    if (!points?.length) return undefined;
    return Math.floor(points[0].timestamp) || 0;
  }, [selectedTrip?.geoPoints]);

  const eventThumbs = useMemo(() => {
    if (!selectedTrip?.triggeredEvents?.length) return null;
    return JSON.stringify(selectedTrip?.triggeredEvents);
  }, [selectedTrip?.triggeredEvents]);

  useEffect(() => {
    if (!selectedTrip?.endpointId || !startTimestamp) return;

    const aborter = new AbortController();
    const process = async () => {
      const state = store.getState();
      const sessionId = selectSessionId(state);

      /** @type {TimestampStore<{ts: number, value: {[key: string]: VideoThumbnailData}}>} */
      const thumbs = new TimestampStore();
      function addThumbnail(/** @type {VideoThumbnailData} */ thumb) {
        if (!thumb) return;
        const near = thumbs.nearestValue(thumb.ts);
        if (near && Math.abs(thumb.ts - near.ts) < 1000) {
          near.value[thumb.source] = thumb;
        } else {
          thumbs.insert(thumb.ts, {
            ts: thumb.ts,
            value: { [thumb.source]: thumb },
          });
        }
      }

      if (eventThumbs) {
        JSON.parse(eventThumbs).forEach((event) => {
          event.snapshots?.forEach((item) => {
            if (item.source === 'sensor') return;
            addThumbnail({
              source: item.source,
              src: item.downloadUrl,
              ts: event.eventTimestamp,
            });
          });
        });
      }

      const request = api.ac.v3.media.endpoint
        .$endpointId(selectedTrip.endpointId)
        .thumbnails.$get({
          signal: aborter.signal,
          headers: {
            Authorization: selectSecretToken(state),
          },
          params: {
            senderEndpointId: sessionId,
            fromTime: startTimestamp - 60000,
            toTime: endTimestamp,
          },
        });
      try {
        const result = await request.process();
        if (!result?.thumbnailPacks?.length) return;
        const pack = result.thumbnailPacks.reverse();
        /** @type {TimestampStore<{[key: string]: string}>} */
        for await (const thumb of processRecordingThumbnails(pack, aborter.signal)) {
          addThumbnail(thumb);
        }
      } catch (err) {
        console.error('Failed to fetch thumbnails', err);
      } finally {
        setThumbnails(thumbs.values);
      }
    };
    const tid = setTimeout(process, 50);
    return () => {
      aborter.abort();
      clearTimeout(tid);
    };
  }, [eventThumbs, selectedTrip?.endpointId, startTimestamp, endTimestamp]);

  // ----------------------------------------------------------------
  // Fetch Camera Details
  // ----------------------------------------------------------------

  /** @type {StateVariable<EndpointDetailDto>} */
  const [endpoint, setEndpoint] = useState();

  useEffect(() => {
    if (!selectedTrip?.endpointId) return;
    const request = api.ac.v5.endpoint.$endpointId(selectedTrip.endpointId).details.$get({
      headers: {
        Authorization: selectSecretToken(store.getState()),
      },
    });
    request.process().then(setEndpoint).catch(console.error);
  }, [selectedTrip?.endpointId]);

  // ----------------------------------------------------------------
  // Render the details view
  // ----------------------------------------------------------------

  if (loading) {
    return (
      <CenterBox>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (!selectedTrip) {
    return (
      <CenterBox>
        <IconMessageBox
          size={'96px'}
          src={NO_SEARCH_RESULT_IMG}
          message="Failed to load Trip details"
        />
      </CenterBox>
    );
  }

  return <TripDetailsView details={selectedTrip} thumbnails={thumbnails} camera={endpoint} />;
}
