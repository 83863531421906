import { DRIVER_CONTAINED_ICON } from '@/assets/constants/images';
import { BoxImage } from '@/web/@components/BoxImage';
import { DriverScoreCircleSvg } from '@/web/@components/DriverScoreCircleSvg';
import { IconLabelItem } from '@/web/@components/IconLabelItem';
import { PageLink } from '@/web/@components/PageLink';
import { Box, Typography } from '@mui/material';
import { DriverImageNotAvailable } from '../../drivers/@components/DriverImageNotAvailable';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {object} DriverItemProps
 * @property {Driver} [driver]
 */

/**
 * @param {DriverItemProps} props
 */
export function DriverItem(props) {
  const { driver } = props;
  const navigate = useNavigate();

  const driverScore = useMemo(
    () => (driver?.globalScore ? Math.floor(driver?.globalScore) : null),
    [driver]
  );

  return (
    <>
      <BoxImage
        width="100%"
        loading="eager"
        alt={'driver photo'}
        src={driver?.pictureUrl}
        objectFit="cover"
        objectPosition="center"
        sx={{ height: { xs: '300px', md: '250px' }, cursor: 'pointer' }}
        onClick={() => navigate(`/fleets/drivers/${driver?.driverId}/details`)}
        fallback={
          <Box
            width="100%"
            height="250px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bgcolor="#F5F5F5"
            gap="5px"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`/fleets/drivers/${driver?.driverId}/details`)}
          >
            <DriverImageNotAvailable />
          </Box>
        }
      />
      <Box display="inline-flex" justifyContent="space-between" alignItems="center">
        <IconLabelItem
          mt="5px"
          src={DRIVER_CONTAINED_ICON}
          fontWeight={600}
          fontSize={'14px'}
          title={
            <PageLink to={`/fleets/drivers/${driver?.driverId}/details`}>
              <Typography title={driver?.firstName + ' ' + driver?.lastName}>
                {driver?.firstName + ' ' + driver?.lastName}
              </Typography>
            </PageLink>
          }
        />
        <Box width="28px" height="28px" position="relative">
          <DriverScoreCircleSvg value={driver?.globalScore / 100} />
          <Typography
            position="absolute"
            left={5}
            right={5}
            top={1}
            bottom={0}
            textAlign={'center'}
            borderRadius="50%"
            lineHeight={driverScore ? '28px' : '20px'}
            fontWeight={600}
            fontSize={driverScore > 99 ? '10px' : '12px'}
          >
            {driverScore || '...'}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
