import { FIRMWARE_FILE_UPLOAD_LIMIT } from '@/assets/file';
import { toastWarning } from '@/utils/toaster';
import { FileUpload } from '@mui/icons-material';
import { Box, Button, FormLabel, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { get, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} ApkFileUploadProps
 * @property {string} apkId
 * @property {string} label
 * @property {boolean} isReadOnly
 * @property {'apkFile'} name
 * @property {(file: File) => any} onDropFile
 * @property {import('react-hook-form').ControllerProps['rules']} [rules]
 */

/** @param {ApkFileUploadProps} props */
export function ApkFileUpload(props) {
  const { name, label, onDropFile, apkId, isReadOnly = false } = props;
  const theme = useTheme();
  const { watch, formState } = useFormContext();

  const error = get(formState.errors, name);

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      onDropFile && onDropFile(acceptedFiles[0]);
    },
    [onDropFile]
  );

  const onDropRejected = () => {
    toastWarning('Maximum file upload size is 5000MB');
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    maxFiles: 1,
    minSize: 0,
    maxSize: FIRMWARE_FILE_UPLOAD_LIMIT, //bytes
    accept: {
      'application/octet-stream': ['.gz'],
    },
  });

  const formattedName = watch(name)?.name || (apkId ? watch('storedFileNamePrev') : '');

  return (
    <Box my={2} display={'flex'} flexDirection={'column'} gap={0.6}>
      <FormLabel color="secondary" sx={{ fontWeight: 500, fontSize: '0.875rem' }}>
        {label}
      </FormLabel>
      <Button
        {...getRootProps()}
        fullWidth
        disabled={isReadOnly}
        variant="text"
        style={{
          padding: '20px',
          textAlign: 'center',
          borderRadius: '4px',
          border: '1px dashed #C5D9F0',
          fontWeight: 'normal',
          fontSize: '0.875rem',
          color: error
            ? theme.palette.error.main
            : formattedName
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
        }}
      >
        <input {...getInputProps()} />
        <Box>{formattedName || "Drag 'n' drop the file here, or click to select file"}</Box>
        <Box flex={1} />
        {!isReadOnly && <FileUpload htmlColor="#596A82" />}
      </Button>
    </Box>
  );
}
