/**
 * Get AI Container Deployment status
 * @param {string} status
 * @returns
 */

export function getAiContainerDeploymentStatus(status) {
  switch (status) {
    case 'LOADING_MODEL':
      return 'Loading Model';
    case 'NCS_INFERENCING':
      return 'NCS Inferencing';
    case 'NCS_UNRESPONSIVE':
      return 'NCS Unresponsive';
    case 'NCS_FAILED_TO_LOAD_MODEL':
      return 'NCS Failed to load Model';
    case 'NCS_MOVIDIUS_OPENCV_DECODER_SOCK_FAILED':
      return 'NCS Movidius openCV decoder sock failed';
    case 'NCS_TPU_FAILED_TO_READ':
      return 'NCS TPU failed to read';
    case 'NCS_MOVIDIUS_NETWORK_LAYER_NOT_FOUND':
      return 'NCS Movidius network layer not found';
    case 'NCS_NOT_FOUND':
      return 'NCS not found';
    case 'DOWNLOADING_MODEL':
      return 'Downloading Model';
    case 'MODEL_DOWNLOAD_FAILED':
      return 'Model download failed';
    case 'MODEL_CHECKSUM_INVALID':
      return 'Model checksum invalid';
    case 'MODEL_EXTRACTION_FAILED':
      return 'Model extraction failed';
    case 'MODEL_EXTRACTION_SUCCEED':
      return 'Model extraction success';
    case 'MODEL_FORMAT_MISMATCHED':
      return 'Model format mismatched';
    case 'DEPLOYING_MODEL':
      return 'Deploying Model';
    case 'NO_AI_CONTAINER':
      return 'Not Found';
    case 'MODEL_NOT_FOUND':
      return 'Model not found';
    case 'NCS_LOADING_MODEL':
      return 'NCS Loading Model';
    case 'NCS_Calibrating ...':
      return 'NCS Calibrating...';
    case 'NCS_Calibration in progress...':
      return 'NCS Calibration in progress...';
    case 'NCS_Calibration completed!':
      return 'NCS Calibration completed!';
    default:
      return status;
  }
}
