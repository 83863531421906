import { NO_SEARCH_RESULT_IMG } from '@/assets/constants/images';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollLoadNext } from '@/web/@components/InfiniteScrollView/InfiniteScrollLoadNext';
import { CircularProgress, Grid } from '@mui/material';

/**
 * @template T
 * @param {InfiniteScrollListViewProps<T> & import('@mui/material').ListProps} props
 */
export function InfiniteDriverScrollView(props) {
  const {
    state,
    renderItem,
    renderFailure = (error, retry) => (
      <IconMessageBox
        size="128px"
        src={NO_SEARCH_RESULT_IMG}
        message="Sorry! Could not fetch data"
        onRetry={retry}
      />
    ),
  } = props;
  const { results, loading, error, finished, loadNext } = state;

  return (
    <Grid container spacing={3} pb={2}>
      {results.map((data, index) => (
        <Grid key={index} item xs={12} sm={6} md={3} xl={2.4}>
          {renderItem(data, index, results)}
        </Grid>
      ))}
      {!finished && (
        <CenterBox>
          <InfiniteScrollLoadNext>
            {loading ? (
              <CircularProgress size="24px" />
            ) : error ? (
              renderFailure(error, loadNext)
            ) : null}
          </InfiniteScrollLoadNext>
        </CenterBox>
      )}
    </Grid>
  );
}
