// @ts-nocheck
import api from '@/api';
import { isProduction } from '@/config';
import { selectSecretToken, selectUserId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { CustomSwitch } from '@/web/@components/CustomForm';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TriggerNameWithThreshold } from '../TriggerNameWithThreshold';

const columns = [
  {
    key: 'triggerName',
    label: 'Name',
    textAlign: 'left',
    width: '70%',
    component: (trigger, hoveredRowId) => (
      <Box pl={1} mr={{ xs: 0, sm: 0, md: 0, xl: -15 }}>
        <TriggerNameWithThreshold
          trigger={trigger}
          formName={`threshold-${trigger.id}`}
          hoveredRowId={hoveredRowId}
        />
      </Box>
    ),
  },
  {
    key: 'pushEnabled',
    label: 'App',
    textAlign: 'center',
    width: '40px',
    disabled: isProduction,
    component: (trigger) => <CustomSwitch name={`pushEnabled-${trigger.id}`} label="" />,
  },
  {
    key: 'smsEnabled',
    label: 'SMS',
    textAlign: 'center',
    width: '40px',
    disabled: isProduction,
    component: (trigger) => {
      return <CustomSwitch name={`smsEnabled-${trigger.id}`} label="" />;
    },
  },
  {
    key: 'emailEnabled',
    label: 'Email',
    textAlign: 'center',
    width: '40px',
    component: (trigger) => (
      <CustomSwitch
        name={`emailEnabled-${trigger.id}`}
        label=""
        BoxProps={{ sx: { justifyContent: 'center' } }}
      />
    ),
  },
];

export function EntityWiseTriggersNotification() {
  const secretToken = useSelector(selectSecretToken);
  const userId = useSelector(selectUserId);

  const { searchText } = useContext(FilterContext);

  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<any>} */
  const [error, setError] = useState(null);
  const [triggers, setTriggers] = useState([]);
  const [filteredTriggers, setFilteredTriggers] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));

  const form = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const { formState, setValue } = form;

  // eslint-disable-next-line no-unused-vars
  const { isDirty } = formState;

  const handleOnCancel = () => {
    navigate('/administration');
  };

  const prepareAndSubmit = (data) => {
    setSubmitLoader(true);
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined)
    );

    // Group the data by the part after the hyphen
    const groupedData = Object.entries(filteredData).reduce((acc, [key, value]) => {
      const [prefix, id] = key?.split('-'); // Split the key into prefix and ID
      const parsedId = parseInt(id);
      if (!acc[parsedId]) {
        acc[parsedId] = { id: parsedId };
      }
      acc[parsedId][prefix] = value;
      return acc;
    }, {});

    // Convert the grouped data object into an array
    const groupedArray = Object.values(groupedData);

    const finalizedData = groupedArray?.map((item) => {
      return {
        emailEnabled: item?.emailEnabled || false,
        pushEnabled: item?.pushEnabled || false,
        smsEnabled: item?.smsEnabled || false,
        threshold: item?.threshold || undefined,
        triggerCategoryId: item?.id,
        userId: userId,
      };
    });

    updateUserNotification(finalizedData);
  };

  const updateUserNotification = (finalizedData) => {
    try {
      const request = api.ac.v5.notification.$post({
        // @ts-ignore
        headers: {
          Authorization: secretToken,
        },
        data: finalizedData,
      });
      request
        ?.process()
        .then((data) => {
          setSubmitLoader(false);
          toastSuccess('Triggers notification settings updated successfully.');
        })
        .catch((ex) => {
          setSubmitLoader(false);
          toastWarning('Failed to update trigger notification settings!.');
        });
    } catch (ex) {
      setSubmitLoader(false);
      toastWarning('Failed to update trigger notification settings!.');
    }
  };

  const fetchTenantTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal, userTriggerData) => {
      try {
        const request = api.ac.v5.trigger.category.all.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
        });
        await request.process();
        // Hide Shadow Category , id = 36 &  Default Category , id = 1
        const result = request?.result?.filter((i) => ![1, 36]?.includes(i?.id));
        const data = result?.map((item) => ({
          ...item,
          emailEnabled: undefined,
          pushEnabled: undefined,
          smsEnabled: undefined,
          threshold: undefined,
        }));

        const mappedData = data?.map((triggerItem) => {
          const userTriggerItem = userTriggerData.find(
            (item) => item.triggerCategoryId === triggerItem.id
          );

          if (userTriggerItem) {
            setValue(`emailEnabled-${triggerItem.id}`, userTriggerItem?.emailEnabled);
            setValue(`pushEnabled-${triggerItem.id}`, userTriggerItem?.pushEnabled);
            setValue(`smsEnabled-${triggerItem.id}`, userTriggerItem?.smsEnabled);
            setValue(`threshold-${triggerItem.id}`, userTriggerItem?.threshold);

            return {
              ...triggerItem,
              emailEnabled: userTriggerItem?.emailEnabled,
              pushEnabled: userTriggerItem?.pushEnabled,
              smsEnabled: userTriggerItem?.smsEnabled,
              threshold: userTriggerItem?.threshold,
            };
          }

          return triggerItem;
        });

        setTriggers(mappedData);
        return result;
      } catch (ex) {
        setError(ex?.response?.data?.message);
        console.log('ex', ex);
        setLoading(false);
      }
    },
    [secretToken, setValue]
  );

  const fetchTenantUserTriggerData = useCallback(
    async (/** @type {AbortSignal} */ signal) => {
      try {
        const request = api.ac.v5.notification.$userId(userId).$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
        });
        await request.process();
        const result = request.result;
        return result;
      } catch (ex) {
        console.log('ex', ex);
        return [];
      }
    },
    [secretToken, userId]
  );

  useEffect(() => {
    setLoading(true);
    setError(null);
    const aborter = new AbortController();

    const fetchData = async () => {
      const userTriggerData = await fetchTenantUserTriggerData(aborter.signal);
      await fetchTenantTriggerData(aborter.signal, userTriggerData);
      setLoading(false);
    };

    fetchData();

    return () => {
      aborter.abort();
      setLoading(false);
    };
  }, [fetchTenantTriggerData, fetchTenantUserTriggerData]);

  useEffect(() => {
    const data = triggers
      ?.filter((item) => item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()))
      ?.sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()));

    setFilteredTriggers(data);
  }, [triggers, searchText]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error || !filteredTriggers?.length) {
    return (
      <CenterBox fullView>
        <IconMessageBox
          size="128px"
          src="/images/empty/no-search-results.svg"
          message="No Data Found!"
        />
      </CenterBox>
    );
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(prepareAndSubmit)} autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer
              pb={5}
              component={Box}
              sx={{
                'maxHeight': xlAndUp ? '80vh !important' : '70vh !important',
                '& .MuiTableCell-stickyHeader': {
                  opacity: 1,
                  background: '#f2f6fc',
                },
              }}
            >
              <Table
                stickyHeader
                sx={{
                  '& .MuiTableCell-head': {
                    padding: '0px 16px !important',
                    borderBottom: 'none',
                  },
                  '& .MuiTableHead-root': {
                    border: '1px solid #d6e4f5 !important',
                    borderRadius: '100px',
                    background: '#d6e4f5',
                  },
                  '& .MuiTableCell-body': {
                    padding: '0px 4px !important',
                  },
                  '& .MuiTableRow-root': {
                    height: '50px !important',
                  },
                }}
              >
                <TableHead
                  sx={{
                    '& .MuiTableRow-root': {
                      height: '35px !important',
                    },
                  }}
                >
                  <TableRow>
                    {columns
                      ?.filter((i) => !i?.disabled)
                      ?.map((column, index) => (
                        <TableCell
                          sx={{
                            color: '#0B2547',
                            opacity: 0.68,
                            fontSize: '0.875rem',
                            textAlign: column?.textAlign,
                          }}
                          key={index}
                        >
                          {column?.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredTriggers?.map((trigger) => (
                    <TableRow
                      key={trigger?.id}
                      onMouseEnter={() => setHoveredRowId(trigger?.id)}
                      onMouseLeave={() => setHoveredRowId(null)}
                      sx={{
                        'px': 2,
                        'height': '80px',
                        '&:hover': {
                          backgroundColor: '#E3EDFD',
                        },
                        '&:hover .edit-button': {
                          visibility: 'visible',
                        },
                      }}
                    >
                      {columns
                        ?.filter((i) => !i?.disabled)
                        ?.map((item) => (
                          <TableCell
                            sx={{
                              textAlign: item?.textAlign,
                              width: item?.width,
                            }}
                            key={item?.key}
                          >
                            {item?.component(trigger, hoveredRowId)}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        {filteredTriggers?.length > 0 ? (
          <Box mb={2} mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleOnCancel} size="small" disabled={submitLoader}>
              Cancel
            </Button>
            <Button size="small" type="submit" variant="contained" disabled={submitLoader}>
              Save
            </Button>
          </Box>
        ) : null}
      </form>
    </FormProvider>
  );
}
