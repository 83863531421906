import { isLoggedIn, isTenantLoggedIn } from '@/store/auth';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';

export function NotFoundPage() {
  const location = useLocation();
  const loggedIn = useSelector(isLoggedIn);
  const tenantLoggedIn = useSelector(isTenantLoggedIn);
  const referrer = location.state?.referrer || '/cameras';

  if (!loggedIn) {
    return <Navigate replace to="/auth/login" state={{ referrer }} />;
  }

  if (!tenantLoggedIn) {
    return <Navigate replace to="/auth/tenants" state={{ referrer }} />;
  }

  return <Navigate replace to={referrer} />;
}
