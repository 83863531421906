import api from '@/api';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { selectDeviceId, selectSessionId } from '@/store/hub-connection/selectors';
import { CustomLogger } from '@/utils/logger';

const logger = new CustomLogger('HubMessageSender');

/**
 * @typedef {object} SendHubMessageParams
 * @property {number} endpointId
 * @property {string} connectionId
 * @property {string} type
 * @property {object} [message]
 * @property {AbortSignal} [signal]
 */

/**
 * Send a new message to an endpoint connected via Hub
 * @param {SendHubMessageParams} data
 * @returns {Promise<SendMessageResponse>}
 */
export function sendHubObject(data) {
  const state = store.getState();
  const deviceId = selectDeviceId(state);
  return sendHubMessage({
    ...data,
    text: JSON.stringify({
      ...data.message,
      deviceId,
      useragent: window.navigator.userAgent,
    }),
  });
}

/**
 * @typedef {object} SendHubEventParams
 * @property {number} endpointId
 * @property {string} connectionId
 * @property {string} type
 * @property {string} text
 * @property {AbortSignal} [signal]
 */

/**
 * Send a new event to an endpoint connected via Hub
 * @param {SendHubEventParams} data
 * @returns {Promise<SendMessageResponse>}
 */
export async function sendHubMessage(data) {
  const { endpointId, connectionId, type, signal, text = '' } = data;

  const state = store.getState();
  const tenantId = selectTenantId(state);
  const secretToken = selectSecretToken(state);
  const sessionId = selectSessionId(state);

  if (!tenantId || !secretToken || !sessionId || !connectionId) {
    return null;
  }

  if (!endpointId || Object.values(MESSAGE_TYPE).indexOf(type) < 0) {
    console.warn('Invalid parameters');
    return null;
  }

  const request = api.ac.v1['event-messaging']['send-message'].$post({
    signal,
    params: {
      secretToken: secretToken,
      remoteEndpointId: endpointId,
    },
    data: {
      type,
      text,
      tenantId,
      connectionId,
      occurrenceTime: Date.now(),
      sender: sessionId,
      recipient: endpointId,
    },
  });

  try {
    const result = await request.process();
    return result;
  } catch (err) {
    logger.error('Failed to send Event Message', err);
  }
}
