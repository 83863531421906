import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

/**
 * @typedef {object} BoxImagePropsBase
 * @property {any} [fallback]
 * @property {string} [fallbackSrc]
 * @property {import('@mui/system').ResponsiveStyleValue<import('csstype').DataType.ViewportLength<number>>} [size]
 * @property {import('@mui/system').ResponsiveStyleValue<import('csstype').Property.ObjectFit>} [objectFit]
 * @property {import('@mui/system').ResponsiveStyleValue<import('csstype').Property.ObjectPosition>} [objectPosition]
 */

/**
 * @typedef {BoxImagePropsBase & import('@mui/material').BoxProps & import('react').ImgHTMLAttributes} BoxImageProps
 */

/**
 * @param {BoxImageProps} props
 */
export const BoxImage = (props) => {
  const {
    sx,
    src,
    size,
    width,
    height,
    fallback,
    fallbackSrc,
    objectFit = 'contain',
    objectPosition = 'center',
    onLoad,
    onError,
    ...others
  } = props;

  const [error, setError] = useState(false);

  useEffect(() => {
    if (src || fallbackSrc) {
      setError(false);
    }
  }, [src, fallbackSrc]);

  /** @type {import('react').ReactEventHandler<HTMLImageElement>} */
  const handleError = (e) => {
    // @ts-ignore
    e.error = true;
    setError(true);
    if (onError) onError(e);
    if (onLoad) onLoad(e);
  };

  /** @type {import('react').ReactEventHandler<HTMLImageElement>} */
  const handleLoad = (e) => {
    if (!(src || fallbackSrc)) {
      // @ts-ignore
      e.error = true;
      setError(true);
      if (onError) onError(e);
    }
    if (onLoad) onLoad(e);
  };

  if (error && fallback) {
    return fallback;
  }

  return (
    <Box
      className="box-image"
      alt="image"
      loading="eager"
      {...others}
      draggable={false}
      component={'img'}
      onLoad={handleLoad}
      onError={handleError}
      onAbort={handleError}
      src={src || fallbackSrc || ''}
      sx={{
        objectFit,
        objectPosition,
        width: size || width,
        height: size || height,
        ...sx,
      }}
    />
  );
};
