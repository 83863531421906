import { TriggerConfiguration } from '.';
import { TriggerParentTtsForm } from './@components/TriggerParentTtsForm';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <TriggerConfiguration />,
  },
  {
    path: 'incab-coach-settings',
    element: <TriggerParentTtsForm />,
  },
];

export default routes;
