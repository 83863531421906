import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<DbcFileDto>>} */
export const ACTION_ITEMS = [
  {
    label: 'Modify',
    iconImage: BOX_MODIFY_ICON,
    component: 'BUTTON',
    variant: 'outlined',
    hidden: (selected) => selected.length !== 1,
    link: (row) => `/administration/dbc/${row?.id}/edit`,
  },
  {
    label: 'DBC File',
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/dbc/create',
  },
];
