import { selectSecretToken, selectTenantId } from '@/store/auth';
import { Add as AddIcon, CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchTagList } from './fetchers';

/**
 * @typedef {object} AddHealthTagButtonProps
 * @property {Array<AssignedHealthTagResponse>} cameraTags
 * @property {(tagId: number) => Promise<any>} onRemove
 * @property {(tagId: number) => Promise<any>} onAdd
 */

/** @param {AddHealthTagButtonProps} props */
export function AddHealthTagButton(props) {
  const { cameraTags, onRemove, onAdd } = props;

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  /** @type {StateVariable<HTMLElement>} */
  const [anchorEl, setAnchorEl] = useState(null);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(true);
  /** @type {StateVariable<Array<HealthTagResponse>>} */
  const [tags, setTags] = useState(null);
  /** @type {StateVariable<number>} */
  const [updating, setUpdating] = useState(null);

  const selected = useMemo(() => {
    return new Set((cameraTags || []).map((x) => x.tagId));
  }, [cameraTags]);

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);
    fetchTagList(tenantId, secretToken, aborter.signal)
      .then(setTags)
      .catch(console.error)
      .finally(() => setLoading(false));
    return () => aborter.abort();
  }, [secretToken, tenantId]);

  /** @type {import('react').MouseEventHandler<HTMLElement>} */
  const showAddPopup = (e) => {
    if (!tags?.length || loading) return;
    setAnchorEl(e.currentTarget);
  };

  const handleDone = () => {
    setAnchorEl(null);
  };

  /** @param {number} tagId */
  const handleToggle = async (tagId) => {
    if (updating) return;
    try {
      setUpdating(tagId);
      if (selected.has(tagId)) {
        await onRemove(tagId);
      } else {
        await onAdd(tagId);
      }
    } finally {
      setUpdating(null);
    }
  };

  return (
    <>
      <IconButton
        disableRipple
        onClick={showAddPopup}
        title="Assign Health Tags"
        sx={{ width: '40px', height: '40px' }}
      >
        {loading ? <CircularProgress size="24px" /> : <AddIcon />}
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleDone}
        style={{ maxHeight: 300 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: '3px',
            ml: '5px',
          },
        }}
      >
        {tags?.map((tag) => (
          <MenuItem key={tag.id} disabled={Boolean(updating)} onClick={() => handleToggle(tag.id)}>
            {updating === tag.id ? (
              <CircularProgress size="24px" sx={{ color: '#4EA5F1' }} />
            ) : selected.has(tag.id) ? (
              <CheckBoxIcon htmlColor="#4EA5F1" />
            ) : (
              <CheckBoxIcon htmlColor="#EBEBEB" />
            )}
            <Typography fontSize="0.875rem" pl={1}>
              {tag.tagName}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
