module.exports = {
  'ai.containers.create.button': 'Створіть контейнер AI',
  'ai.containers.page.card.browse': 'Переглянути контейнер AI',
  'ai.containers.page.card.create': 'Створіть новий контейнер AI',
  'ai.containers.page.card.title': 'Контейнери AI',
  'ai.containers.page.title': 'Контейнери AI',
  'ai.containers.table.button.add': 'Контейнер AI',
  'ai.containers.table.columns.name': 'Назва',
  'ai.containers.table.columns.publication.status': 'Статус',
  'ai.containers.table.columns.release.version': 'Версія випуску',
  'ai.containers.table.columns.version': 'Версія',
  'ai.containers.table.search.placeholder': 'Шукайте контейнер AI',
  'ai.models.create.button': 'Створіть модель AI',
  'ai.models.name': 'Моделі AI',
  'ai.models.page.card.browseItems': 'Переглянути моделі AI',
  'ai.models.page.card.createNew': 'Створіть нову модель AI',
  'ai.models.page.card.name': 'Моделі AI',
  'ai.models.table.button.add': 'У вас є модель',
  'ai.models.table.columns.name': 'Назва',
  'ai.models.table.columns.publication.status': 'Статус',
  'ai.models.table.columns.release.version': 'Версія випуску',
  'ai.models.table.columns.vendor': 'Продавець',
  'ai.models.table.search.placeholder': 'Шукайте моделі AI',
  'annotations.fetching.data.message': 'Категорії анотації',
  'annotations.no.categories.message': 'Ніяких категорій анотації.',
  'annotations.page.card.browseItems': 'Перегляньте анотацію',
  'annotations.page.card.createNew': 'Створіть анотацію',
  'annotations.page.card.name': 'Категорії анотації',
  'annotations.page.title': 'Анотації',
  'annotations.search.placeholder': 'Шукайте анотацію для редагування та видалення',
  'button.agree': 'Погодитися',
  'button.cancel': 'Скасувати',
  'button.details': 'Деталі',
  'button.disagree': 'Не погодитися',
  'button.discontinue': 'Припиняти',
  'button.edit': 'Редагувати',
  'button.modify': 'Змінювати',
  'button.release': 'Звільнення',
  'button.retry': 'Повторити',
  'button.save': 'Збагачувати',
  'button.update': 'Оновити',
  'cameras.no.data.message': 'Ніяких камер не знайдено!',
  'cameras.page.title': 'Камери',
  'cameras.search.placeholder':
    'Шукайте за назвою камери, ідентифікатором камери, місцем розташування та VIN',
  'change.password.title': 'Змінити пароль',
  'confirmations.discontinue': 'Ви впевнені, що хочете припинити ці предмети?',
  'confirmations.release': 'Ви впевнені, що хочете випустити ці предмети?',
  'data-residency.page.card.configurations': 'Конфігурації',
  'data-residency.page.card.name': 'Проживання даних',
  'data-retention.page.card.configurations': 'Конфігурації',
  'data-retention.page.card.name': 'Зберігання даних',
  'device-batches.page.card.browseItems': 'Перегляньте партії пристроїв',
  'device-batches.page.card.createNew': 'Створіть партію пристрою',
  'device-batches.page.card.name': 'Партії пристрою',
  'device-group.columns.description': 'Опис',
  'device-group.columns.name': 'Назва',
  'device-group.columns.product': 'Продукт',
  'device-group.create.button': 'Група пристроїв',
  'device-group.create.failed': 'Не вдалося оновити групу пристроїв',
  'device-group.create.page.title': 'Створіть групу камери',
  'device-group.create.retry': 'Не вдалося додати групу.Будь ласка, спробуйте ще раз після колись',
  'device-group.create.success': 'Група {{groupName}} успішно додана.',
  'device-group.delete.failed': 'Не вдалося припинити {{groupName}}.',
  'device-group.delete.retry': 'Не міг припинити {{groupName}}.Будь-ласка спробуйте пізніше.',
  'device-group.delete.success': 'Група пристроїв {{groupName}} успішно припинена.',
  'device-group.edit.failed': 'Не вдалося оновити групу пристроїв',
  'device-group.edit.success': 'Група {{groupName}} успішно оновлюється.',
  'device-group.edit.title': 'Оновіть групу камери',
  'device-group.form.description.placeholder': 'Введіть опис групи',
  'device-group.form.group.name.hint': 'Введіть назву групи',
  'device-group.form.group.name.label': 'Назва групи',
  'device-group.form.group.name.placeholder': 'Введіть назву групи',
  'device-group.form.select.product.hint': 'Виберіть товар',
  'device-group.form.select.product.label': 'Продукт',
  'device-group.form.select.product.placeholder': 'Виберіть товар',
  'device-group.placeholder.search': 'Група пошукових пристроїв',
  'device-group.retry': 'Не вдалося оновити групу.Будь ласка, спробуйте ще раз після колись',
  'device-group.title': 'Групи пристроїв',
  'device-group.form.text.secondary': 'Деталі групи',
  'device.groups.page.card.browseItems': 'Переглянути групи пристроїв',
  'device.groups.page.card.createNew': 'Створити групу пристроїв',
  'device.groups.page.card.name': 'Групи пристроїв',
  'device.pools.page.card.browseItems': 'Перегляньте пули пристроїв',
  'device.pools.page.card.createNew': 'Створіть пул пристроїв',
  'device.pools.page.card.name': 'Пули пристроїв',
  'events.no.data.message': 'Подів не знайдено!',
  'events.page.title': 'Події',
  'events.search.placeholder': 'Обшук',
  'firmwares.page.card.browseItems': 'Переглянути проміжні товари',
  'firmwares.page.card.name': 'Провідна ворота',
  'firmwares.page.card.upload': 'Завантажити програмне забезпечення',
  'geofences.page.card.browseItems': 'Перегляньте геофенції',
  'geofences.page.card.createNew': 'Створіть геофенція',
  'geofences.page.card.name': 'Геофенції',
  'login.alternate.text': 'Або ви можете з',
  'login.banner': 'Точність AI для надійних даних та рішень',
  'login.button.google': 'google',
  'login.button.microsoft': 'microsoft',
  'login.button': 'Вхід',
  'login.forget.password': 'Забули пароль?',
  'login.label.email': 'Електронна пошта',
  'login.label.password': 'Пароль',
  'login.label.remember.me': "Пам'ятай мене",
  'login.title': 'Увійдіть до свого облікового запису',
  'navigation.ai.container': 'Контейнери AI',
  'navigation.ai.models': 'Моделі AI',
  'navigation.ai': 'Ai',
  'navigation.annotations': 'Анотації',
  'navigation.cameras': 'Камери',
  'navigation.configurations': 'Конфігурації',
  'navigation.data.residency': 'Проживання даних',
  'navigation.data.retention': 'Зберігання даних',
  'navigation.device.batches': 'Партії пристрою',
  'navigation.device.groups': 'Групи пристроїв',
  'navigation.device.pools': 'Пули пристроїв',
  'navigation.events': 'Події',
  'navigation.firmwares': 'Провідна ворота',
  'navigation.gallery': 'Галерея',
  'navigation.operations.geofences': 'Геофенції',
  'navigation.operations': 'Операції',
  'navigation.administration': 'Оркестрація',
  'navigation.privacy.and.compliance': 'Конфіденційність та дотримання',
  'navigation.privacy.policies': 'Політика конфіденційності',
  'navigation.products': 'Продукція',
  'navigation.reports': 'Звіти',
  'navigation.smarter.ai': 'Розумніший AI',
  'navigation.tenants': 'Орендарі',
  'navigation.triggers': 'Спрацьовує',
  'navigation.users': 'Користувачі',
  'navigation.vin.groups': 'VIN -групи',
  'navigation.waste.management': 'Поводження з відходами',
  'navigation.winter.operations': 'Зимові операції',
  'navigation.support': 'Підтримка',
  'navigation.trips': 'Поїздок',
  'no.data.found': 'Даних не знайдено!',
  'pages.administration.deviceGroups.form.description.name': 'Опис',
  'privacy-policies.page.card.biometric': 'Біометричний',
  'privacy-policies.page.card.consent': 'Згода',
  'privacy-policies.page.card.name': 'Політика конфіденційності',
  'privacy-policies.page.card.tools': 'Інструменти',
  'products.page.card.manage-accelerator': 'Керуйте прискорювачем AI',
  'products.page.card.manageHardwareProducts': 'Керуйте апаратними продуктами',
  'products.page.card.name': 'Продукція',
  'reset.password.title': 'Скинути пароль',
  'table.per.page': '{{item}} на сторінці',
  'tenants.label.select': 'Виберіть орендаря',
  'tenants.page.card.browseItems': 'Перегляньте орендарів',
  'tenants.page.card.createNew': 'Створіть орендаря',
  'tenants.page.card.name': 'Орендарі',
  'tenants.placeholder.search': 'Пошук орендаря',
  'tenants.title': 'Орендарі',
  'triggers.create.button': 'Створити тригер',
  'triggers.page.card.browseItems': 'Переглянути тригери',
  'triggers.page.card.createNew': 'Створити тригер',
  'triggers.page.card.manageLabels': 'Керуйте етикетками',
  'triggers.page.card.name': 'Спрацьовує',
  'triggers.page.title': 'Спрацьовує',
  'triggers.table.button.add': 'Тригер',
  'triggers.table.columns.name': 'Назва',
  'triggers.table.columns.publication.status': 'Статус',
  'triggers.table.columns.type': 'Тип',
  'triggers.table.search.placeholder': 'Шукальний тригер',
  'users.page.card.browseItems': 'Перегляньте користувачів',
  'users.page.card.createNew': 'Створити користувача',
  'users.page.card.name': 'Користувачі',
  'vin.groups.page.card.browseItems': 'Переглянути групи VIN',
  'vin.groups.page.card.createNew': 'Створіть групу VIN',
  'vin.groups.page.card.name': 'VIN -групи',
  'waste-management.configurations.page.card.customer': 'Клієнти',
  'waste-management.configurations.page.card.name': 'Конфігурації',
  'waste-management.configurations.page.card.softwareConf': 'Конфігурація програмного забезпечення',
  'waste-management.operations.page.card.name': 'Операції',
  'waste-management.reports.page.card.browseItems': 'Перегляньте звіти',
  'waste-management.reports.page.card.createNew': 'Створити звіт',
  'waste-management.reports.page.card.name': 'Звіти',
  'winter-operations.configurations.page.card.customer': 'Клієнти',
  'winter-operations.configurations.page.card.name': 'Конфігурації',
  'winter-operations.configurations.page.card.softwareConf':
    'Конфігурація програмного забезпечення',
  'winter-operations.gallery.page.card.name': 'Галерея',
  'winter-operations.reports.page.card.browseItems': 'Перегляньте звіти',
  'winter-operations.reports.page.card.createNew': 'Створити звіт',
  'winter-operations.reports.page.card.name': 'Звіти',
  'user.create.title': 'Створити нового користувача',
  'user.update.title': 'Оновіть дані користувача',
  'user.form.email.label': 'Електронна пошта',
  'user.form.email.placeholder': 'Введіть адресу електронної пошти',
  'user.form.email.required': 'Введіть електронну пошту користувача',
  'user.form.email.invalid': 'невірна адреса електронної пошти',
  'user.form.name.label': 'Назва',
  'user.form.name.placeholder': 'Введіть повне ім’я користувача',
  'user.form.name.required': "Введіть ім'я користувача",
  'user.form.password.label': 'Пароль',
  'user.form.password.placeholder': 'Введіть пароль',
  'user.form.password.required': 'Введіть пароль',
  'user.form.password.minLength': 'Пароль Мінина довжина - 8',
  'user.form.password.regex':
    'Пароль повинен містити щонайменше одну букву та один спеціальний символ',
  'user.form.confirm_password.label': 'Підтвердьте пароль',
  'user.form.confirm_password.placeholder': 'Введіть пароль',
  'user.form.confirm_password.required': 'Введіть пароль підтвердження',
  'user.form.confirm_password.match': 'Ваші паролі не відповідають',
  'user.form.role.label': 'Роль',
  'user.form.role.placeholder': 'Виберіть роль користувача',
  'user.form.tenantId.label': 'Орендар',
  'user.form.tenantId.placeholder': 'Виберіть орендаря',
  'user.form.tenantId.required': 'Виберіть орендаря',
  'user.create.success': 'Користувач успішно створений',
  'user.create.retry': 'Не вдалося створити користувача, спробуйте пізніше',
  'user.update.success': 'Користувач успішно оновлюється',
  'user.update.retry': 'Не вдалося оновити користувача, спробуйте пізніше',
  'device-batch.actions.modify': 'Змінювати',
  'device-batch.actions.create': 'Партія пристрою',
  'device-batch.actions.search.placeholder': 'Пакет пошуку пристрою',
  'device-batch.columns.name': 'Назва',
  'device-batch.columns.package': 'Пакет',
  'device-batch.title': 'Партії пристрою',
  'device-batch.create': 'Створіть партію пристрою',
  'device-batch.form.details': 'Деталі партії пристрою',
  'device-batch.form.create.title': 'Створіть партію пристрою',
  'device-batch.form.update.title': 'Оновити партію пристрою',
  'device-batch.create.form.response.success': 'Партія пристрою створила успішно',
  'device-batch.create.form.response.error': 'Не вдалося створити, будь ласка, спробуйте пізніше.',
  'device-batch.update.form.response.success': 'Партія пристрою успішно оновлюється',
  'device-batch.update.form.response.error': 'Не вдалося оновити, спробуйте пізніше.',
  'device-batch.form.name.title': 'Назва партії',
  'device-batch.form.name.placeholder': 'Введіть ім’я пакетів',
  'device-batch.form.name.required': 'Назва пакетів потрібно',
  'device-batch.form.packageId.title': 'Ідентифікатор пакету',
  'device-batch.form.packageId.placeholder': 'Введіть ідентифікатор пакету',
  'device-batch.form.packageId.required': 'Потрібен ідентифікатор пакету',
  'device-batch.form.secret.title': 'Таємний',
  'device-batch.form.secret.placeholder': 'Увійти в таємницю',
  'device-batch.form.secret.required': 'Потрібна таємниця',
  'device-pool.route.create': 'Створіть пул пристроїв',
  'device-pool.form.name.label': 'Назва пулу пристроїв',
  'device-pool.form.name.placeholder': 'Введіть назву пулу пристроїв',
  'device-pool.form.name.required': 'Назва пулу пристроїв необхідна',
  'device-pool.form.text.secondary': 'Деталі басейну',
  'device-pool.create.success': 'Пул пристроїв {{name}} успішно додано.',
  'device-pool.create.retry': 'Не вдалося створити пул пристроїв, спробуйте пізніше.',
  'device-pool.create.page.title': 'Створіть пул пристроїв',
  'device-pool.update.page.title': 'Оновіть пул пристроїв',
  'device-pool.update.success': 'Пул пристроїв {{name}} успішно оновлено.',
  'device-pool.update.retry': 'Не вдалося оновити, спробуйте пізніше.',
  'device-pool.delete.success': 'Пул пристроїв {{name}} успішно припинено.',
  'device-pool.delete.failed': 'Не вдалося припинити пул пристроїв',
  'device-pool.table.title': 'Пули пристроїв',
  'device-pool.actions.create': 'Пул пристроїв',
  'device-pool.actions.search': 'Пошук пулу пристроїв',
  'tenant.route.list.title': 'Орендарі',
  'tenant.route.create.title': 'Створіть орендаря',
  'tenant.form.text.secondary': 'Деталі орендаря',
  'tenant.form.name.label': 'Назва орендаря',
  'tenant.form.name.placeholder': 'Введіть ім’я орендаря',
  'tenant.form.name.required': "Ім'я орендаря потрібно",
  'tenant.form.code.label': 'Код орендаря',
  'tenant.form.code.placeholder': 'Введіть код орендаря',
  'tenant.form.code.required': 'Потрібен код орендаря',
  'tenant.create.success': 'Орендар {{name}} створений успішно.',
  'tenant.create.retry': 'Не вдалося створити, будь ласка, спробуйте пізніше.',
  'tenant.create.page.title': 'Створіть нового орендаря',
  'tenant.update.success': 'Орендар {{name}} успішно оновлено.',
  'tenant.update.retry': 'Не вдалося оновити, спробуйте пізніше.',
  'tenant.update.page.title': 'Оновлення орендаря',
  'tenant.table.title': 'Орендарі',
  'tenant.delete.success': 'Орендар {{name}} успішно припинено.',
  'tenant.delete.failed': 'Не вдалося припинити орендаря',
  'tenant.form.user.label': 'Користувач',
  'tenant.form.user.placeholder': 'Виберіть користувача',
  'tenant.form.user.required': 'Потрібен користувач',
  'tenant.details.page.title': 'Деталі орендаря',
};
