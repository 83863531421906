import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/administration/device-pools/actions';
import { DEVICE_POOL_TABLE_COLUMNS } from '@/web/administration/device-pools/columns';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function DevicePoolListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { t } = useTranslation();

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<DevicePoolInfoForTenant>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await deleteDevicePools(selectedItems);
    }
    setTableReload(true);
  };

  const deleteDevicePool = async (pool) => {
    if (!pool) return;
    try {
      const request = api.ac.v5['device-pool'].$poolId(pool.id).$delete({
        headers: {
          Authorization: `Bearer ${secretToken}`,
        },
      });
      await request.process();
      toastSuccess(
        'Success',
        t(T['device-pool.delete.success'], {
          name: pool.name,
        })
      );
    } catch (e) {
      toastWarning(
        'Error',
        t(T['device-pool.delete.failed'], {
          name: pool.name,
        })
      );
    }
  };

  const deleteDevicePools = async (selectedItems) => {
    await Promise.all(selectedItems.map(deleteDevicePool));
  };

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={t(T['device-pool.table.title'])}
          actions={ACTION_ITEMS}
          onAction={onActionHandle}
        />
        <OffsetPagination
          key={generateUniqueKey([secretToken, 'device_pools'])}
          fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
            if (searchType === 'deviceSerial' && searchText !== '') {
              const deviceId = searchText;
              const request = api.ac.v5.device.$deviceId(deviceId)['device-pool'].$get({
                signal,
                headers: {
                  Authorization: secretToken,
                },
                params: {
                  deviceId: deviceId,
                },
              });
              await request.process();
              if (!request.result) {
                return { data: [], count: 0 };
              } else {
                return { data: [request.result], count: 1 };
              }
            } else {
              const request = api.ac.v5['device-pool'].list.$get({
                signal,
                headers: {
                  Authorization: secretToken,
                },
                params: {
                  limit: limit,
                  offset,
                  tenantId,
                  ...(searchText !== '' && searchType && { [searchType]: searchText }),
                },
              });
              await request.process();
              return {
                data: request.result,
                count: Number(request.response.headers['x-total-count']),
              };
            }
          }}
          renderList={(/** @type {Array<DevicePoolInfoForTenant>} */ results) => (
            <ItemsResponsiveView results={results} columns={DEVICE_POOL_TABLE_COLUMNS} />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
