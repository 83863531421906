import { useTriggerVariables } from '@/hooks/useTriggerVariables';
import { KnownUserError } from '@/utils/errors';
import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { ACTION_ITEMS } from '@/web/smarter-ai/device-batches/actions';
import { Box } from '@mui/material';
import { TRIGGER_VARIABLES_TABLE_COLUMNS } from './columns';

export function TriggerVariableListView() {
  const { result, loading, error } = useTriggerVariables();

  if (error) {
    throw new KnownUserError('Sorry! Could not fetch Trigger Variables');
  }

  return (
    <Box mx={2.5} mt={2}>
      <PaginatedTableContextProvider>
        <ItemsListHeader
          title={'Trigger Variables'}
          actions={ACTION_ITEMS}
          //onAction={onActionHandle}
        />
        <ContinuationTokenTable
          loading={loading}
          results={result}
          columns={TRIGGER_VARIABLES_TABLE_COLUMNS}
          dataSortKey={'name'}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
