import { Grid, Typography } from '@mui/material';

/**
 * @typedef {object} CameraSensorInfoProps
 * @property {Array} sensorInputs
 * @property {String} [adrStatus]
 */

/**
 * @param {CameraSensorInfoProps} props
 */
export function CameraSensorInfo(props) {
  const { sensorInputs, adrStatus = 'Unknown' } = props;

  return (
    <Grid container spacing={2} px={2}>
      {sensorInputs.map((item, index) => (
        <Grid item xs={6} md={3} key={index}>
          <Typography variant="body1" fontSize="1rem" fontWeight="600">
            {item?.inputName}
          </Typography>
          <Typography variant="body2" fontSize="0.875rem">
            Available
          </Typography>
        </Grid>
      ))}
      <Grid item xs={6} md={3}>
        <Typography variant="body1" fontSize="1rem" fontWeight="600">
          GPS ADR
        </Typography>
        <Typography variant="body2" fontSize="0.875rem">
          {adrStatus}
        </Typography>
      </Grid>
    </Grid>
  );
}
