import api from '@/api';
import { useFutureLoader } from './useFutureLoader';

/** @param {CompositeTriggerDto} item */
export function filterTriggers(item) {
  return (
    // keep only these triggers
    (item.publicationStatus === 'DRAFT' || item.publicationStatus === 'RELEASED') &&
    // remove shadow triggers
    item.triggerCategoryId !== 36
  );
}

/**
 * Fetch list of all Triggers
 */
export function useFetchTriggers() {
  const fetcher = async ({ signal, secretToken, tenantId }) => {
    let offset = 0;
    const limit = 100;
    /** @type {Array<CompositeTriggerDto>} */
    let results = [];
    while (!signal.aborted) {
      const request = api.ac.v5.trigger.composite.tenant.$tenantId(tenantId).filter.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
        params: {
          limit,
          offset,
        },
      });
      const result = await request.process();
      results.push(...result.compositeTriggers);
      if (result.compositeTriggers.length < limit) break;
      offset += limit;
    }
    return results?.filter(filterTriggers);
  };
  return useFutureLoader(fetcher, [], { cache: true });
}

/**
 * Fetch list of all effective Triggers
 */
export function useAffectiveTriggers() {
  const fetcher = async ({ signal, secretToken, tenantId }) => {
    const request = api.ac.v5.trigger.composite.tenant.$tenantId(tenantId).detail.$get({
      signal,
      headers: {
        Authorization: secretToken,
      },
    });
    const result = await request.process();
    return (result.compositeTriggers || []).filter(filterTriggers);
  };
  return useFutureLoader(fetcher, [], { cache: true });
}
