import React, { useContext } from 'react';
import { Android12Switch } from '@/web/@components/CustomForm/CustomSwitch';
import { useSelector } from 'react-redux';
import { selectSecretToken } from '@/store/auth';
import api from '@/api';
import { TriggerConfigurationContext } from '../../@context/TriggerConfigurationContext';
import { toastSuccess, toastWarning } from '@/utils/toaster';

export function TriggerActivateSwitch({ trigger }) {
  const secretToken = useSelector(selectSecretToken);

  const { setTriggers, selectionType } = useContext(TriggerConfigurationContext);

  function createNewValue(newValue, item) {
    const existingValue = item?.value;
    const prevTargetId = item?.targetId;
    const prevTargetLevel = item?.targetLevel;
    const prevTargetName = item?.targetName;
    return {
      value: newValue,
      targetLevel: newValue === existingValue ? prevTargetId : selectionType?.type,
      targetId: newValue === existingValue ? prevTargetLevel : selectionType?.value,
      targetName: newValue === existingValue ? prevTargetName : null,
    };
  }

  const handleOnChange = async (e) => {
    const newValue = e.target.checked;
    try {
      const request = await api.ac.v5.trigger.composite
        .$triggerCategoryId(trigger.triggerCategoryId)
        .config.$put({
          headers: {
            Authorization: secretToken,
          },
          data: {
            enableTrigger: newValue,
            targetId: selectionType?.value,
            targetLevel: selectionType?.type,
          },
        });

      await request
        ?.process()
        .then(() => {
          toastSuccess(
            'Success',
            `${trigger?.triggerCategoryName} ${newValue ? 'enabled' : 'disabled'}  successfully`
          );

          setTriggers((prevTriggers) => {
            return prevTriggers.map((item) => {
              if (item.id === trigger?.id) {
                const newValues = {
                  enableTrigger: createNewValue(newValue, item?.enableTrigger?.value),
                };
                return { ...trigger, ...newValues };
              }
              return item;
            });
          });
        })
        .catch((exp) => {
          toastWarning('Error', `Failed to update ${trigger?.triggerCategoryName} enabled status`);
        });
    } catch (ex) {
      console.error('Error:', ex);
    }
  };

  return (
    <Android12Switch defaultChecked={trigger.enableTrigger?.value} onChange={handleOnChange} />
  );
}
