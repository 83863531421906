import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

/** @param {{item: APNAdditionalSettingsForm, fieldIndex: number}} props */
export function ApnAdditionalSettingsFormControl(props) {
  const { item, fieldIndex } = props;

  const { watch } = useFormContext();
  const inputType = watch(`apnAdditionalInfoItemList[${fieldIndex}].type`);

  if (item.visibility && !item.visibility(inputType)) {
    return null;
  }

  if (item.type === 'select') {
    return (
      <Grid item xs={12} md={4}>
        <CustomSelect
          name={`apnAdditionalInfoItemList[${fieldIndex}].${item.name}`}
          label={item.label}
          placeholder={item.placeholder}
          options={(item.options || []).map((x) => x.value)}
          getLabel={(value) => item.options?.find((x) => x.value === value)?.label || ''}
          rules={{ required: item?.mandatory ? 'This is required' : false }}
        />
      </Grid>
    );
  }

  if (item.type === 'input') {
    return (
      <Grid item xs={12} md={4}>
        <CustomInput
          label={item.label}
          placeholder={item.placeholder}
          name={`apnAdditionalInfoItemList[${fieldIndex}].${item.name}`}
          rules={{ required: item?.mandatory ? 'This is required' : false }}
          type={inputType === 'NUMBER' && item.name === 'value' ? 'number' : 'text'}
        />
      </Grid>
    );
  }

  return null;
}
