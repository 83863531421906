import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { random, snakeCase } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserForm } from '../@components/UserForm';

export function CreateUserPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/UserForm').UserFormProps['onSubmitData']} */
  const createUser = async (props) => {
    const { email, name, role } = props;
    try {
      setLoading(true);
      const username = snakeCase(email.substring(0, email.indexOf('@')) + random(10, 100));
      const req = api.ac.v5.auth.account.$post({
        headers: {
          Authorization: secretToken,
        },
        data: {
          role: role?.name || 'ADMIN',
          email,
          name,
          tenantId,
          username,
        },
      });
      const data = await req.process();
      await api.ac.v5['role-manager'].users
        .$userId(data.users[0].userId)
        .$put({
          headers: {
            Authorization: secretToken,
          },
          params: {
            newRoleId: role?.id,
            tenantId,
          },
        })
        .process();
      toastSuccess('Success', t(T['user.create.success']));
      navigate('/administration/users');
    } catch (err) {
      setLoading(false);
      toastWarning('Error', err?.response?.data?.message || t(T['user.create.retry']));
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['user.create.title'])}
      </Typography>
      <UserForm onSubmitData={createUser} loading={loading} />
    </Box>
  );
}
