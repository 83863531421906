import api from '@/api';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { CameraThumbnailProvider } from '@/web/cameras/@components/CameraThumbnailProvider';
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CameraSafetyGridListItem } from '../CameraSafetyGridListItem';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { endOfTheDay, startOfTheDay } from '@/utils/datetime';
import { ScoringFilterContext } from '../../@context/ScoringFilterContext';
import { debounce } from 'lodash';
import { SafetyScoreLayoutContext } from '../../@layouts/SafetyScoreLayout';

export function EndpointByPersona(props) {
  const { persona } = props;
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { filteredData } = useContext(ScoringFilterContext);
  const { cameras } = useContext(SafetyScoreLayoutContext);

  const navigate = useNavigate();
  const theme = useTheme();

  /** @type {StateVariable<boolean>} */
  const [isHovered, setIsHovered] = useState(false);
  /** @type {StateVariable<Array<EndpointScoreWithEndpointDetailDto>>} */
  const [endpoints, setEndpoints] = useState([]);
  /** @type {StateVariable<boolean>} */
  const [loading, setLoading] = useState(false);

  const mdAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const gridItems = useMemo(() => {
    if (smAndDown) return 1;
    else if (mdAndDown) return 3;
    else return 4;
  }, [mdAndDown, smAndDown]);

  /** @param {string} id */
  const handleViewAll = (id) => {
    navigate(`/safety/scoring/cameras?personaId=${id}`);
  };

  const endpointsWithScores = useMemo(() => {
    const filteredCameras = filteredData?.cameras || [];
    const mappedEndpoints = cameras?.map((endpoint) => ({
      ...endpoint,
      score:
        endpoints?.find((item) => Number(item?.endpointId) === Number(endpoint?.endpointId))
          ?.score || 0,
    }));

    const filteredEndpoints =
      filteredCameras.length === 0
        ? mappedEndpoints
        : mappedEndpoints.filter((endpoint) =>
            filteredCameras.some((camera) => camera?.endpointId === endpoint?.endpointId)
          );

    return filteredEndpoints.sort((a, b) => b?.score - a?.score);
  }, [cameras, endpoints, filteredData?.cameras]);

  const fetchEndpointByPersona = useCallback(
    async (signal) => {
      const request = api.ac.v5.score.persona
        .$personaId(persona?.id)
        .tenant?.$tenantId(tenantId)
        .endpoint.details.$get({
          signal,
          headers: {
            Authorization: secretToken,
          },
          params: {
            endpointIds: filteredData?.cameras?.map((camera) => camera?.endpointId),
            fromTimestamp: startOfTheDay(filteredData?.dateRange?.from),
            toTimestamp: endOfTheDay(filteredData?.dateRange?.to),
            limit: gridItems,
            offset: 0,
            sort: 'DESC',
          },
        });
      const result = await request?.process();
      setEndpoints(result?.list);
      setTimeout(() => {
        setLoading(false);
      }, 600); //Need to fix
    },
    [
      persona?.id,
      tenantId,
      secretToken,
      gridItems,
      filteredData?.dateRange?.from,
      filteredData?.dateRange?.to,
      filteredData?.cameras,
    ]
  );

  useEffect(() => {
    const aborter = new AbortController();
    setLoading(true);

    const debouncedFetchEndpoints = debounce(() => {
      fetchEndpointByPersona(aborter?.signal);
    }, 500);

    debouncedFetchEndpoints();

    return () => {
      setLoading(false);
      aborter.abort();
    };
  }, [fetchEndpointByPersona]);

  return (
    <Box pb={4}>
      <Box
        display="flex"
        alignItems="center"
        mb={1.5}
        gap={1}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Typography sx={{ color: '#0B2547', opacity: '0.68', fontWeight: '600' }}>
          {persona?.name || 'Aggressive'}
        </Typography>
        <Link
          className="view-hover"
          onClick={() => handleViewAll(persona?.id)}
          display={endpoints?.length > 0 ? 'block' : 'none'}
          style={{
            fontSize: '0.825rem',
            fontWeight: 600,
            visibility: isHovered ? 'visible' : 'hidden',
            textDecoration: 'none',
            color: '#1E59A8',
          }}
        >
          View All
        </Link>
      </Box>

      <Grid container spacing={1}>
        {loading ? (
          <CenterBox style={{ height: '280px', border: '1px solid #f8f8f8' }}>
            <CircularProgress />
          </CenterBox>
        ) : endpoints?.length > 0 ? (
          <CameraThumbnailProvider cameras={endpointsWithScores?.slice(0, gridItems)}>
            {endpointsWithScores?.slice(0, gridItems)?.map((endpoint, index) => {
              return (
                <Grid item xs={12} md={6} lg={3} key={index}>
                  <CameraSafetyGridListItem endpoint={endpoint} score={endpoint?.score} />
                </Grid>
              );
            })}
          </CameraThumbnailProvider>
        ) : (
          <CenterBox style={{ border: '1px solid #f8f8f8' }}>
            <IconMessageBox
              size="150px"
              src="/images/empty/no-vehicles.svg"
              message="No Cameras!"
            />
          </CenterBox>
        )}
      </Grid>
    </Box>
  );
}
