import { EMAIL_VALIDATION_REGEX } from '@/assets/regex';

/**
 * Input Field double [-, e, E, +] validation
 * @param {any} event
 */
export function handleKeyPress(event) {
  if (event?.key === 'e' || event?.key === '+') {
    event.preventDefault();
  }
  if (event.target.value.length === 0 && event?.key === '-') {
    event.target.value = '';
  }
  if (event.target.value.length > 0 && event?.key === '-') {
    event.preventDefault();
  }
}

/**
 * Input Field double [-, e, E, +] validation while pasting
 * @param {any} event
 */

export function handleOnPaste(event) {
  const reg = '^[-]?[0-9]*\\.?[0-9]*$';
  const current = event.target.value;
  if (!current) {
    event.target.value = '';
  }
  if (
    !(current + event.clipboardData.getData('Text')).match(reg) &&
    event.clipboardData.getData('Text').match(reg)
  ) {
    event.target.value = '';
  }
  if (!event.clipboardData.getData('Text').match(reg)) {
    event.preventDefault();
  }
}

/**
 * Email Validation
 * @param {string} email
 * @returns
 */

export function isValidEmail(email) {
  return EMAIL_VALIDATION_REGEX.test(email);
}
