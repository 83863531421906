import {
  BASIC_MODEL_INPUT_SOURCE,
  SECONDARY_MODEL_INPUT_SOURCE,
} from '@/utils/ai-models/inputSources';
import { ModifySourceItemContainer } from '@/web/@components/ModifySourceItemContainer';
import { Grid } from '@mui/material';
import { BasicInputSourceFormControl } from './BasicInputSourceFormControl';
import { SecondaryInputSourceFormControl } from './SecondaryInputSourceFormControl';

/** @param {{fieldIndex: number, remove: import('react-hook-form').UseFieldArrayRemove}} props */
export function AIModelInputSourceFormItem(props) {
  const { fieldIndex, remove } = props;

  return (
    <ModifySourceItemContainer onRemove={() => remove(fieldIndex)}>
      <Grid container spacing="10px" p="10px">
        {BASIC_MODEL_INPUT_SOURCE.map((item, index) => (
          <BasicInputSourceFormControl key={index} item={item} fieldIndex={fieldIndex} />
        ))}
      </Grid>

      <Grid container spacing="10px" p="10px">
        {SECONDARY_MODEL_INPUT_SOURCE.map((item, index) => (
          <SecondaryInputSourceFormControl key={index} item={item} fieldIndex={fieldIndex} />
        ))}
      </Grid>
    </ModifySourceItemContainer>
  );
}
