import { ContinuationTokenTable } from '@/web/@components/ContinuationTokenTable';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box } from '@mui/material';
import { APK_VERSION_TABLE_COLUMNS } from './columns';

export function ApkVersionHistory({ versions }) {
  return (
    <Box mt={2} pb={{ xs: 10, md: 5 }}>
      <PaginatedTableContextProvider>
        <ContinuationTokenTable
          results={versions}
          columns={APK_VERSION_TABLE_COLUMNS}
          height="700px"
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
