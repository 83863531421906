import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { generateUniqueKey } from '@/utils/table';
import { FilterContext } from '@/web/@components/FilterContext';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { InfiniteScrollView } from '@/web/@components/InfiniteScrollView';
import { InfiniteScrollTableView } from '@/web/@components/InfiniteScrollView/InfiniteScrollTableView';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CAMERA_OTA_LOG_TABLE_COLUMNS } from './columns';

export function OtaLogsTable() {
  const { cameraId, camera } = useContext(CameraDetailsContext);
  const tenantId = useSelector(selectTenantId);

  const { searchText, startTime, endTime } = useContext(FilterContext);

  // eslint-disable-next-line no-unused-vars
  const timestamps = {
    fromTimestamp: startTime,
    toTimestamp: endTime,
  };

  return (
    <Box mt={2} height="90%">
      <PaginatedTableContextProvider>
        <InfiniteScrollView
          key={generateUniqueKey([tenantId, cameraId, searchText])}
          initialToken={''}
          fullView={false}
          itemsPerPage={25}
          fetcher={async ({ signal, limit, token: item }) => {
            const secretToken = selectSecretToken(store.getState());
            const request = api.ac.v3.report.devices
              .$deviceSerialNumber(camera.deviceSerialNumber)
              .logs.$get({
                signal,
                headers: {
                  Authorization: secretToken,
                },
                params: {
                  pageSize: limit,
                  lastItemTieBreak: item?.lastItemTieBreak || '',
                  lastTimestamp: item?.lastTimestamp || '',
                  searchString: searchText || '',
                  // ...(startTime && timestamps),
                },
              });

            await request.process();

            const result = request.result.deviceLogs;

            let token = null;
            if (result.length >= limit) {
              token = {
                lastItemTieBreak: result[result.length - 1]?.sortTieBreak,
                lastTimestamp: result[result.length - 1]?.timestamp,
              };
            }
            return { result, token };
          }}
          renderEmpty={() => (
            <IconMessageBox
              size="256px"
              src="/images/player/no-data-icon.svg"
              message="No Data Found"
            />
          )}
          renderList={(state) => (
            <InfiniteScrollTableView
              state={state}
              columns={CAMERA_OTA_LOG_TABLE_COLUMNS}
              sx={{ height: 'calc(100vh - 200px)' }}
            />
          )}
        />
      </PaginatedTableContextProvider>
    </Box>
  );
}
