import { TextField } from '@mui/material';

/**
 * @typedef {object} DateTimeSelectionProps
 * @property {string} date
 * @property {function} setDate
 * @property {boolean} [isDisabled]
 */

/**
 * @param {DateTimeSelectionProps} props
 */

export function GroupSettingsDateTimeSelection({ date, setDate, isDisabled = false }) {
  return (
    <TextField
      disabled={isDisabled}
      fullWidth
      value={date}
      id="outlined-basic"
      variant="outlined"
      type="datetime-local"
      size="medium"
      onChange={(e) => {
        setDate(e.target.value);
      }}
    />
  );
}
