import api from '@/api';
import { STATUS_LIST } from '@/assets/constants/table';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { CenterBox } from '@/web/@components/CenterBox';
import { IconMessageBox } from '@/web/@components/IconMessageBox';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AiContainerDetailsContext } from '../AiContainerDetailsLayout';
import { ComponentNodeTree } from './ComponentNodeTree';
import { InputSourceMapping } from './InputSourceMapping';
import { SelectAiModelControl } from './SelectAiModelControl';

export function AiContainerComponents() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const { product, models: components } = useContext(AiContainerDetailsContext);

  const [loading, setLoading] = useState(false);
  /** @type {StateVariable<AIModelV2WithAccelerator[]>} */
  const [supportedModels, setSupportedModels] = useState([]);
  /** @type {StateVariable<AIModelV2WithAccelerator>} */
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedComponentId, setSelectedComponentId] = useState(null);
  /** @type {StateVariable<import('./InputSourceMapping').InputSourceMappingProps['mode']>} */
  const [selectionMode, setSelectionMode] = useState(null);

  useEffect(() => {
    if (!product?.productId) return null;
    setLoading(true);
    const request = api.ac.v1.tenant.product.supported.aimodel.$get({
      params: {
        productId: product.productId,
        secretToken,
        tenantId,
      },
    });
    request
      .process()
      .then((result) => {
        const models = (result.data || [])
          .filter((x) => [STATUS_LIST.RELEASED, STATUS_LIST.DRAFT].includes(x.publicationStatus))
          .sort((a, b) => {
            return (
              -a.publicationStatus.localeCompare(b.publicationStatus) ||
              a.name.localeCompare(b.name) ||
              -a.version.localeCompare(b.version)
            );
          });
        setSupportedModels(models);
        setSelectedModel(null);
      })
      .finally(() => setLoading(false));
  }, [tenantId, secretToken, product?.productId]);

  /** @type {import('./ComponentNodeTree').ComponentNodeTreeProps['onSelect']} */
  const handleComponentSelect = debounce((modelId, add) => {
    if (add) {
      setSelectionMode('add');
      setSelectedModel(null);
      setSelectedComponentId(modelId || '');
    } else {
      setSelectionMode('edit');
      const model = supportedModels.find((x) => x.modelId === modelId);
      setSelectedModel(model || null);
      const component = components.find((x) => x.modelId === modelId);
      setSelectedComponentId(component.inputModelId || '');
    }
    setTimeout(() => document.getElementById('select-ai-model')?.focus(), 100);
  }, 100);

  if (!product) {
    return <IconMessageBox message="Please select a component to link with AI Model" />;
  }

  if (loading) {
    return (
      <CenterBox py={3}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={5}
        p={2.5}
        bgcolor="#F5F9FF"
        overflow="auto hidden"
        // height={{ xs: '50vh', md: 'calc(100vh - 350px)' }}
      >
        <Typography fontWeight="600" fontSize="0.875rem" mb={1}>
          Input Sources
        </Typography>
        <ComponentNodeTree
          onSelect={handleComponentSelect}
          adding={selectionMode === 'add' && selectedComponentId}
          selected={selectionMode === 'edit' && selectedModel?.modelId}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        p={2.5}
        overflow="auto hidden"
        // bgcolor="#FAFAFA"
        // border="1px dashed #99c0f6"
        // height={{ md: 'calc(100vh - 350px)' }}
        position="relative"
      >
        <Typography fontWeight="600" fontSize="0.875rem" mb={0.5}>
          Select AI Model
        </Typography>
        <SelectAiModelControl
          loading={loading}
          options={supportedModels}
          value={selectedModel}
          onChange={setSelectedModel}
          componentId={selectedComponentId}
          disabled={selectionMode === 'edit' || selectedComponentId === null}
        />
        {selectedModel && (
          <InputSourceMapping
            mt={2}
            mode={selectionMode}
            model={selectedModel}
            componentId={selectedComponentId}
            supportedModels={supportedModels}
            onReset={() => {
              setSelectedModel(null);
              setSelectedComponentId(null);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
