import api from '@/api';
import { selectSecretToken, selectTeamList } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ExpressionTriggerForm } from '../@components/ExpressionTriggerForm';
import { isUndefined } from 'lodash';

export function ExpressionTriggerCreate() {
  const secretToken = useSelector(selectSecretToken);
  const teamList = useSelector(selectTeamList);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  /** @type {import('../@components/ExpressionTriggerForm').ExpressionTriggerFormProps['onSubmitData']} */
  const createTrigger = async (data, triggerId, categoryId) => {
    try {
      setLoading(true);

      const hasDescriptionEditAccess = teamList.some((x) =>
        ['AI_TEAM', 'BACK_END_USER']?.includes(x)
      );

      const req = await api.ac.v5.trigger.composite.$post({
        headers: {
          Authorization: secretToken,
        },
        data,
      });
      await req.process().then(() => {
        if (!hasDescriptionEditAccess || isUndefined(categoryId)) return;
        const categoryReq = api.ac.v5.trigger.category.$categoryId(data?.triggerCategoryId).$put({
          headers: {
            Authorization: secretToken,
          },
          data: { description: data?.description },
        });
        categoryReq?.process();
      });
      toastSuccess('Success', 'Trigger created successfully');
      navigate('..');
    } catch (err) {
      toastWarning('Error', 'Failed to create, please try later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={3}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        Create Trigger
      </Typography>
      <ExpressionTriggerForm onSubmitData={createTrigger} isLoading={loading} />
    </Box>
  );
}
