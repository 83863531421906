import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { SettingsSuggest, ViewInAr as ViewInArIcon } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export function CameraDetailsToSettingsButton() {
  const location = useLocation();
  const { cameraId, camera } = useContext(CameraDetailsContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const isManageCameraPage = useMemo(
    () => location.pathname.includes('device-groups'),
    [location.pathname]
  );

  return (
    <>
      <IconButton
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          backgroundColor: '#F8FAFF',
          height: '40px',
          width: '40px',
        }}
        size="large"
        {...{
          LinkComponent: RouterLink,
          to: isManageCameraPage
            ? `/cameras/${cameraId}`
            : `/administration/device-groups/${camera?.groupId}/cameras/${cameraId}`,
        }}
      >
        {isManageCameraPage ? (
          <ViewInArIcon htmlColor="#abb4c0" />
        ) : (
          <SettingsSuggest htmlColor="#abb4c0" />
        )}
      </IconButton>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(open)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption" fontSize={'12px'} fontWeight={600}>
            {isManageCameraPage ? 'Camera Details' : 'Manage Camera'}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
