import { T } from '@/assets/locales';
import { UnderDevelop } from '@/web/@components/UnderDevelop';
import { DBCListView } from '@/web/administration/dbc';
import { DbcFileEditPage } from './_id/edit';
import { DBCFileCreatePage } from './create';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DBCListView />,
    breadcrumb: 'DBC Files',
  },
  {
    path: 'create',
    element: <DBCFileCreatePage />,
    breadcrumb: 'Create DBC File',
  },
  {
    path: ':id',
    children: [
      {
        path: 'details',
        element: <UnderDevelop />,
        breadcrumb: T['button.details'],
      },
      {
        path: 'edit',
        element: <DbcFileEditPage />,
        breadcrumb: T['button.edit'],
      },
    ],
  },
];

export default routes;
