export class NiceLaneRenderer {
  /**
   * @param {import('./_nice').NiceBoundingBoxRenderer} parent
   */
  constructor(parent) {
    this.parent = parent;
    this.canvas = parent.canvas;
    this.ctx = parent.ctx;
    this.options = parent.options;
  }

  /**
   * @param {{[key: string]: Array<LanePoint>}} lanes
   */
  render(lanes) {
    const { scale, laneColor } = this.options;
    this.ctx.save();
    this.ctx.lineCap = 'round';
    this.ctx.lineJoin = 'round';
    this.ctx.strokeStyle = laneColor;
    this.laneScale = Math.min(scale, 0.001 * this.canvas.width);
    for (const lane of Object.values(lanes)) {
      if (lane.length < 2) continue;
      lane.sort((a, b) => b.i - a.i);

      const points = smoothLane(lane);
      const grad = this.ctx.createLinearGradient(
        points[0].x,
        points[0].y,
        points[points.length - 1].x,
        points[points.length - 1].y
      );
      grad.addColorStop(0, laneColor);
      grad.addColorStop(0.75, laneColor + 'af');
      grad.addColorStop(1, '#ffffff10');
      this.ctx.strokeStyle = grad;
      this.ctx.fillStyle = grad;

      switch (lane[0].type) {
        case '0':
          this._drawDashedLine(points);
          break;
        case '1':
          this._drawSolidLine(points);
          break;
        case '2':
          this._drawDoubleSolidLine(points);
          break;
        case '3':
          this._drawSolidDashedLine(points);
          break;
        case '4':
          this._drawDashedSolidLine(points);
          break;
        case '5':
          this._drawSolidDashedSolidLine(points);
          break;
        case '6':
          this._drawTripleSolidLine(points);
          break;
        default:
          this._drawPointOnly(points);
          break;
      }
    }
    this.ctx.restore();
  }

  /**
   * Draw Just Point
   * @param {LanePoint[]} points
   */
  _drawPointOnly(points) {
    this.ctx.save();
    const width = 4 * this.laneScale;
    for (const p of points) {
      this.ctx.beginPath();
      this.ctx.arc(p.x, p.y, width, 0, 2 * Math.PI);
      this.ctx.fill();
      this.ctx.closePath();
    }
    this.ctx.restore();
  }

  /**
   * Draw Dashed Lane
   * @param {LanePoint[]} points
   */
  _drawDashedLine(points) {
    this.ctx.save();
    const width = 6 * this.laneScale;
    this.ctx.lineWidth = width;
    this.ctx.setLineDash([6 * width, 6 * width]);
    this.ctx.beginPath();
    let first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();
  }

  /**
   * Draw Solid Lane
   * @param {LanePoint[]} points
   */
  _drawSolidLine(points) {
    this.ctx.save();
    const width = 6 * this.laneScale;
    this.ctx.lineWidth = width;
    this.ctx.beginPath();
    let first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();
  }

  /**
   * Draw Double Solid Lane
   * @param {LanePoint[]} points
   */
  _drawDoubleSolidLine(points) {
    this.ctx.save();
    const width = 4 * this.laneScale;
    this.ctx.lineWidth = width;
    const gap = width;

    this.ctx.beginPath();
    let first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x - gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x - gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.beginPath();
    first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x + gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x + gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.restore();
  }

  /**
   * Draw Solid Dashed Lane
   * @param {LanePoint[]} points
   */
  _drawSolidDashedLine(points) {
    this.ctx.save();
    const width = 4 * this.laneScale;
    this.ctx.lineWidth = width;
    const gap = width;

    this.ctx.beginPath();
    let first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x - gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x - gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.save();
    this.ctx.setLineDash([6 * width, 6 * width]);
    this.ctx.beginPath();
    first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x + gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x + gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();

    this.ctx.restore();
  }

  /**
   * Draw Dashed Solid Lane
   * @param {LanePoint[]} points
   */
  _drawDashedSolidLine(points) {
    this.ctx.save();
    const width = 4 * this.laneScale;
    this.ctx.lineWidth = width;
    const gap = width;

    this.ctx.save();
    this.ctx.setLineDash([6 * width, 6 * width]);
    this.ctx.beginPath();
    let first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x - gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x - gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();

    this.ctx.beginPath();
    first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x + gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x + gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.restore();
  }

  /**
   * Draw Solid Dashed Solid Lane
   * @param {LanePoint[]} points
   */
  _drawSolidDashedSolidLine(points) {
    this.ctx.save();
    const width = 3 * this.laneScale;
    this.ctx.lineWidth = width;
    const gap = 1.5 * width;

    this.ctx.beginPath();
    let first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x - gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x - gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.save();
    this.ctx.setLineDash([6 * width, 6 * width]);
    this.ctx.beginPath();
    first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();

    this.ctx.beginPath();
    first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x + gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x + gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.restore();
  }

  /**
   * Draw Triple Solid Lane
   * @param {LanePoint[]} points
   */
  _drawTripleSolidLine(points) {
    this.ctx.save();
    const width = 3 * this.laneScale;
    this.ctx.lineWidth = width;
    const gap = 2 * width;

    this.ctx.beginPath();
    let first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x - gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x - gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.beginPath();
    first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.beginPath();
    first = true;
    for (const p of points) {
      if (first) {
        this.ctx.moveTo(p.x + gap, p.y);
        first = false;
      } else {
        this.ctx.lineTo(p.x + gap, p.y);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();

    this.ctx.restore();
  }
}

// /**
//  * @param {LanePoint} a
//  * @param {LanePoint} b
//  * @param {LanePoint} p
//  * @returns {LanePoint}
//  */
// function interpolate(a, b, p) {
//   if (!a || !b) return p;
//   let dx = b.x - a.x;
//   let dy = b.y - a.y;
//   const m = Math.sqrt(dx * dx + dy * dy);
//   dx /= m;
//   dy /= m;
//   const l = dx * (p.x - a.x) + dy * (p.y - a.y);
//   return {
//     ...p,
//     x: dx * l + a.x,
//     y: p.y,
//   };
// }

/**
 * @param {LanePoint} a
 * @param {LanePoint} b
 * @param {LanePoint} p
 * @returns {LanePoint}
 */
function interpolate(a, b, p) {
  if (!a || !b) return p;
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  const vy = p.y - a.y;
  const x = (vy * dx) / dy + a.x;
  return {
    ...p,
    x,
    y: p.y,
  };
}

/**
 * @param {Array<LanePoint>} points
 * @param {number} [windowSize]
 * @returns {Array<LanePoint>}
 */
function smoothLane(points, windowSize = 8) {
  /** @type  {Array<LanePoint>} */
  const output = [];
  // middle points
  let n = 0;
  let sumX = 0;
  let sumY = 0;
  let half = windowSize >>> 1;
  for (let i = 0; i < half && i < points.length; ++i) {
    sumX += points[i].x;
    sumY += points[i].y;
    n++;
  }
  for (let i = 0; i < points.length; ++i) {
    if (i + half < points.length) {
      const p = i + half;
      sumX += points[p].x;
      sumY += points[p].y;
      n++;
    }
    if (i - half > 0) {
      const p = i - half - 1;
      sumX -= points[p].x;
      sumY -= points[p].y;
      n--;
    }
    output.push({
      ...points[i],
      x: sumX / n,
      y: sumY / n,
    });
  }
  if (output.length >= 2) {
    n = output.length;
    // take first point
    output.unshift(interpolate(output[0], output[1], points[0]));
    // interpolate last point
    output.push(interpolate(output[n - 1], output[n - 2], points[n - 1]));
  }
  return output;
}
