import { AI_LABEL_DATA } from '../ai-labels';
import { makeDisplayText } from '../sensors/extractor';
import { NiceBBoxRenderer } from './_nice_bbox';
import { NiceLabelRenderer } from './_nice_labels';
import { NiceLaneRenderer } from './_nice_lanes';

export class NiceBoundingBoxRenderer {
  /**
   * @param {import('.').BoundingBoxRenderer} parent
   */
  constructor(parent) {
    this.parent = parent;
    this.canvas = parent.canvas;
    this.ctx = parent.ctx;
    this.options = parent.options;
    this.bboxRenderer = new NiceBBoxRenderer(this);
    this.labelRenderer = new NiceLabelRenderer(this);
    this.laneRenderer = new NiceLaneRenderer(this);
  }

  /**
   * Draw Legacy Bounding box
   * @param {Array<VideoMetaData>} items
   */
  render(items) {
    const { imagerWidth, imagerHeight } = this.options;

    /** @type {{[key: string]: Array<LanePoint>}} */
    const lanes = {};
    /** @type {Array<RenderBoxData>} */
    const boxes = [];
    for (const item of items) {
      const { ts, dimen, bbox, detections } = item;
      const width = dimen[1] || imagerWidth;
      const height = dimen[0] || imagerHeight;
      const totalArea = width * height;
      const scaleX = this.canvas.width / width;
      const scaleY = this.canvas.height / height;
      let boxWidth = Math.abs(bbox[2] - bbox[0]);
      let boxHeight = Math.abs(bbox[3] - bbox[1]);
      const boxArea = boxWidth * boxHeight;

      // filter out lanes
      if (detections.length === 1) {
        const label = detections[0][0];
        if (/\d+ - \d+(-\d+)?/.test(label)) {
          const [laneId, trackId] = label.split(' - ');
          const [laneType, pointId] = trackId.split('-');
          const x = (bbox[0] + bbox[2]) / 2;
          const y = (bbox[1] + bbox[3]) / 2;
          lanes[laneId] ||= [];
          lanes[laneId].push({
            lane: laneId,
            type: laneType,
            i: Number(pointId) || 0,
            x: scaleX * x,
            y: scaleY * y,
          });
          continue;
        }
      }

      // hide small bounding boxes at top-left
      if (bbox[0] < 5 || bbox[1] < 5 || boxArea <= 16) {
        continue;
      }

      // take box by area
      boxes.push({
        area: boxArea,
        x: bbox[0] * scaleX,
        y: bbox[1] * scaleY,
        width: boxWidth * scaleX,
        height: boxHeight * scaleY,
        ratio: boxArea / totalArea,
        ...this.buildLabels(ts, detections),
      });
    }

    // Render lanes
    this.laneRenderer.render(lanes);

    // Render bounding boxes
    this.bboxRenderer.render(boxes);

    // Render labels
    this.labelRenderer.render(boxes);
  }

  /**
   * @param {number} ts
   * @param {DetectionData[]} detections
   * @returns {RenderBoxLabels}
   */
  buildLabels(ts, detections) {
    const { imperial, startTime, endTime, decimalPlaces } = this.options;
    const triggerLabels = new Set(this.options.triggerLabels);

    let hasCar = false;
    let triggered = false;
    let boxColor = this.options.boxColor;

    /** @type {{[key: string]: Array<string>}} */
    const items = {};
    for (const det of detections) {
      const [name, value] = det;
      if (ts >= startTime && ts <= endTime && triggerLabels.has(name)) {
        triggered = true;
        boxColor = '#FF0000';
      }
      const ai = AI_LABEL_DATA[name];
      if (!ai) continue;
      if (ai.displayName === 'Car') {
        hasCar = true;
      }
      /** @type {TelematicsTemplate} */
      const template = {
        name,
        value,
        type: 'number',
        source: 'bbox',
        min: ai.min,
        max: ai.max,
        unit: ai.unit,
      };
      const text = makeDisplayText(template, decimalPlaces, imperial);
      items[ai.displayName] ||= [];
      items[ai.displayName].push(text);
    }
    const labels = Object.keys(items)
      .sort((a, b) => {
        let c = a.length - b.length;
        if (!c) c = a.localeCompare(b);
        const at = triggerLabels.has(a);
        const bt = triggerLabels.has(b);
        if (at && !bt) {
          return -1;
        } else if (!at && bt) {
          return 1;
        }
        return c;
      })
      .map((x) => ({
        name: x + ':',
        value: items[x].join(', '),
      }));
    return {
      labels,
      hasCar,
      triggered,
      color: boxColor,
    };
  }
}
