import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiModelAdditionalParamFormItem } from './AiModelAdditionalParamFormItem';

export function AiModelAdditionalParamForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, remove, prepend } = useFieldArray({ control, name: 'modelParams' });

  return (
    <Box mb="15px">
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb="5px">
        <Typography variant="body2" fontWeight="500">
          {t(T['ai.model.form.text.additional.param'])}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => prepend({ type: 'BOOLEAN' })}
          startIcon={<AddOutlined />}
        >
          {t(T['ai.model.form.text.additional.param.add'])}
        </Button>
      </Box>

      <Divider sx={{ mt: '5px', mb: '10px' }} />

      {fields.map((itemField, fieldIndex) => (
        <AiModelAdditionalParamFormItem
          key={itemField.id}
          remove={remove}
          fieldIndex={fieldIndex}
        />
      ))}
    </Box>
  );
}
