import { formatTimestamp } from '@/utils/datetime';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<VinDetails>>} */
export const VIN_TABLE_COLUMNS = [
  {
    id: 'vin',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'vin'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'vin',
    label: 'VIN',
    align: 'left',
    isPrimary: true,
  },
  {
    id: 'deviceId',
    label: 'Camera Serial',
    align: 'left',
    fallback: '...',
  },
  {
    id: 'onboardTime',
    label: 'Onboarded At',
    align: 'left',
    fallback: 'Not Onboarded',
    format: (value) => (value ? formatTimestamp(value) : null),
  },
];
