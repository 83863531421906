import { AI_MODEL_OUTPUT_NAME } from '@/assets/ai/ai-model';
import { CustomInput, CustomSelect } from '@/web/@components/CustomForm';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

/** @param {{item: AIModelForm, fieldIndex: number}} props */
export function AIModelOutputSourceFormControl(props) {
  const { item, fieldIndex } = props;

  const { watch, setValue } = useFormContext();
  const outputType = watch(`outputSources[${fieldIndex}].outputType`);

  if (item.visibility && !item.visibility(outputType)) {
    return null;
  }

  if (item.type === 'select') {
    return (
      <Grid item xs={12} md={4}>
        <CustomSelect
          disabled={item.disabled}
          name={`outputSources[${fieldIndex}].${item.name}`}
          label={item.label}
          placeholder={item.placeholder}
          options={(item.options || []).map((x) => x.value)}
          getLabel={(value) => item.options?.find((x) => x.value === value)?.label || ''}
          rules={{ required: item?.mandatory ? 'This is required' : false }}
          AutocompleteProps={{
            onChange: (e, value) => {
              setValue(`outputSources[${fieldIndex}].${item.name}`, value);
              if (item.name === 'outputName') {
                setValue(
                  `outputSources[${fieldIndex}].outputCode`,
                  AI_MODEL_OUTPUT_NAME.find((item) => item.value === value)?.field
                );
              }
            },
          }}
        />
      </Grid>
    );
  }

  if (item.type === 'input') {
    return (
      <Grid item xs={12} md={4}>
        <CustomInput
          disabled={item.disabled}
          name={`outputSources[${fieldIndex}].${item.name}`}
          label={item.label}
          placeholder={item.placeholder}
          rules={{ required: item?.mandatory ? 'This is required' : false }}
        />
      </Grid>
    );
  }

  return null;
}
