export const CAR_NAMES = [
  'acura_ilx_2016_can_generated',
  'acura_ilx_2016_nidec',
  'acura_rdx_2018_can_generated',
  'acura_rdx_2020_can_generated',
  'cadillac_ct6_chassis',
  'cadillac_ct6_object',
  'cadillac_ct6_powertrain',
  'chrysler_pacifica_2017_hybrid',
  'chrysler_pacifica_2017_hybrid_private_fusion',
  'esr',
  'ford_cads',
  'ford_cgea1_2_bodycan_2011',
  'ford_cgea1_2_ptcan_2011',
  'ford_fusion_2018_adas',
  'ford_fusion_2018_pt',
  'ford_lincoln_base_pt',
  'gm_global_a_chassis',
  'gm_global_a_high_voltage_management',
  'gm_global_a_lowspeed',
  'gm_global_a_lowspeed_1818125',
  'gm_global_a_object',
  'gm_global_a_powertrain',
  'gm_global_a_powertrain_expansion',
  'honda_accord_2018_can_generated',
  'honda_civic_hatchback_ex_2017_can_generated',
  'honda_civic_sedan_16_diesel_2019_can_generated',
  'honda_civic_touring_2016_can_generated',
  'honda_clarity_hybrid_2018_can_generated',
  'honda_crv_ex_2017_body_generated',
  'honda_crv_ex_2017_can_generated',
  'honda_crv_executive_2016_can_generated',
  'honda_crv_hybrid_2019_can_generated',
  'honda_crv_touring_2016_can_generated',
  'honda_fit_ex_2018_can_generated',
  'honda_fit_hybrid_2018_can_generated',
  'honda_insight_ex_2019_can_generated',
  'honda_odyssey_exl_2018_generated',
  'honda_odyssey_extreme_edition_2018_china_can_generated',
  'honda_pilot_touring_2017_can_generated',
  'honda_ridgeline_black_edition_2017_can_generated',
  'hyundai_2015_ccan',
  'hyundai_2015_mcan',
  'hyundai_i30_2014',
  'hyundai_kia_generic',
  'hyundai_kia_mando_front_radar',
  'j1939',
  'lexus_ct200h_2018_pt_generated',
  'lexus_gs300h_2017_pt_generated',
  'lexus_is_2018_pt_generated',
  'lexus_nx300_2018_pt_generated',
  'lexus_nx300h_2018_pt_generated',
  'lexus_rx_350_2016_pt_generated',
  'lexus_rx_hybrid_2017_pt_generated',
  'luxgen_s5_2015',
  'mazda_2017',
  'mazda_3_2019',
  'mercedes_benz_e350_2010',
  'nissan_leaf_2018',
  'nissan_x_trail_2017',
  'obd2',
  'obd2_1',
  'obd2_2',
  'obd2_3',
  'obd2_4',
  'obd2_5',
  'packer',
  'parser',
  'stellantis_dasm',
  'subaru_forester_2017_generated',
  'subaru_global_2017_generated',
  'subaru_global_2020_hybrid_generated',
  'subaru_outback_2015_generated',
  'subaru_outback_2019_generated',
  'tesla_can',
  'tesla_powertrain',
  'tesla_radar',
  'toyota_2017_ref_pt',
  'toyota_adas',
  'toyota_avalon_2017_pt_generated',
  'toyota_camry_hybrid_2018_pt_generated',
  'toyota_corolla_2017_pt_generated',
  'toyota_highlander_2017_pt_generated',
  'toyota_highlander_hybrid_2018_pt_generated',
  'toyota_iQ_2009_can',
  'toyota_nodsu_hybrid_pt_generated',
  'toyota_nodsu_pt_generated',
  'toyota_prius_2010_pt',
  'toyota_prius_2017_pt_generated',
  'toyota_rav4_2017_pt_generated',
  'toyota_rav4_hybrid_2017_pt_generated',
  'toyota_sienna_xle_2018_pt_generated',
  'toyota_tss2_adas',
  'volvo_v40_2017_pt',
  'volvo_v60_2015_pt',
  'vw_golf_mk4',
  'vw_mqb_2010',
];
