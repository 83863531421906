import { BoxImage } from '@/web/@components/BoxImage';
import { SetFilterContext } from '@/web/@components/FilterContext';
import { SearchField } from '@/web/@components/SearchField';
import { Box, IconButton, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

export function CollapsibleFilterBox(props) {
  const { expandWidth } = props;

  const wrapperRef = useRef();
  const filterRef = useRef('');
  const setFilter = useContext(SetFilterContext);

  /** @type {StateVariable<string>} */
  const [searchFilter, setSearchFilter] = useState('');

  /** @type {StateVariable<boolean>} */
  const [searchEnable, setSearchEnable] = useState(false);
  // Handle Click outside for bigger display
  const handleMenuCloseWhileClickOutside = useCallback(
    (e) => {
      if (filterRef?.current?.trim()?.length) return;
      if (
        wrapperRef.current &&
        searchEnable &&
        // @ts-ignore
        !wrapperRef.current?.contains(e.target)
      ) {
        setTimeout(() => {
          setSearchEnable(false);
        }, 100);
      }
    },
    [searchEnable]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleMenuCloseWhileClickOutside);
    return () => {
      document.removeEventListener('visibilitychange', handleMenuCloseWhileClickOutside);
    };
  }, [handleMenuCloseWhileClickOutside]);

  useEffect(() => {
    filterRef.current = searchFilter;
    setFilter({ searchText: searchFilter });
  }, [searchFilter, setFilter]);

  return searchEnable ? (
    <Box
      ref={wrapperRef}
      sx={{
        'width': expandWidth,
        'display': 'flex',
        '& .MuiInputBase-root': {
          'padding': '5px !important',
          '& input::placeholder': {
            fontSize: '16px',
          },
          '& fieldset': {
            display: 'none',
          },
        },
      }}
    >
      <SearchField
        clearable
        ignoreCase
        autoFocus
        value={searchFilter}
        placeholder="Search Triggers"
        onSearch={setSearchFilter}
        sx={{ width: '100%', height: '34px', borderRadius: '5px', mb: '10px' }}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        textAlign: 'end',
        justifyContent: 'flex-end',
        alignItems: 'center',
        cursor: 'pointer',
        height: '44px',
      }}
      onClick={() => setSearchEnable(true)}
    >
      <IconButton sx={{ mr: '-5px' }}>
        <BoxImage src="/images/commons/search-icon.svg" size="16px" />
      </IconButton>
      <Typography
        variant="subtitle2"
        color="#99a9bd"
        onClick={() => setSearchEnable(true)}
        sx={{ cursor: 'pointer' }}
      >
        Search Triggers
      </Typography>
    </Box>
  );
}
