import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiModelOutputSourceFormItem } from './AiModelOutputSourceFormItem';

export function AIModelOutputSourceForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, remove, prepend } = useFieldArray({ control, name: 'outputSources' });

  return (
    <Box mb="15px">
      <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb="5px">
        <Typography variant="body2" fontWeight="500">
          {t(T['ai.model.form.text.output.source'])}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => prepend({ outputType: 'VIDEO' })}
          startIcon={<AddOutlined />}
        >
          {t(T['ai.model.form.text.output.source.add'])}
        </Button>
      </Box>

      <Divider sx={{ mt: '5px', mb: '10px' }} />

      {fields.map((itemField, fieldIndex) => (
        <AiModelOutputSourceFormItem key={itemField.id} fieldIndex={fieldIndex} remove={remove} />
      ))}
    </Box>
  );
}
