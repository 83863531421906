import api from '@/api';
import { useFutureLoader } from '@/hooks/useFutureLoader';

/**
 * Fetch Trigger Categories
 * @returns {import('./useFutureLoader').FutureLoaderResponse<Array<TriggerCategoryDetails>>}
 */
export function useFetchTriggerCategories() {
  return useFutureLoader(
    async ({ signal, secretToken }) => {
      const request = api.ac.v5.trigger.category.all.$get({
        signal,
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
      return request.result || [];
    },
    [],
    { cache: false }
  );
}
