import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { DevicePoolDetailsContext } from '../DevicePoolDetailsLayout';

export function DevicePoolDetails() {
  const { devicePool } = useContext(DevicePoolDetailsContext);

  return (
    <Box borderRadius="20px">
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        flexWrap="wrap"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        minHeight="73px"
        rowGap={2}
      >
        <Box>
          <Typography variant="body2">Device Pool Name</Typography>
          <Typography variant="body1">{devicePool.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
