import { billingCycleOptions, frequencyOptions, periodicReportOptions } from '../options';
import { FROM_TYPES, GROUP_SETTINGS_CATEGORIES } from '../constants';

/** @type {Array<GroupSettingsItems>} */
export const profilingSettings = [
  {
    title: 'Profiling',
    key: GROUP_SETTINGS_CATEGORIES.PROFILING,
    children: [
      {
        label: 'CPU Profiling Frequency',
        key: 'cpuProfilingFrequency',
        type: FROM_TYPES.SLIDER,
        postText: 'sec',
        min: 30,
        max: 600,
      },
      {
        label: 'GPU Profiling Frequency',
        key: 'gpuProfilingFrequency',
        type: FROM_TYPES.SLIDER,
        postText: 'sec',
        min: 30,
        max: 600,
      },
      {
        label: 'Memory Profiling Frequency',
        key: 'memoryProfilingFrequency',
        type: FROM_TYPES.SLIDER,
        postText: 'sec',
        min: 30,
        max: 600,
      },
      {
        label: 'Network Profiling Frequency',
        key: 'networkProfilingFrequency',
        type: FROM_TYPES.SLIDER,
        postText: 'min',
        min: 30,
        max: 360,
      },
      {
        label: 'Disk Profiling Frequency',
        key: 'diskProfilingFrequency',
        type: FROM_TYPES.SLIDER,
        postText: 'min',
        min: 1,
        max: 60,
      },
      {
        label: 'Profile Network on Active Adapter Change',
        key: 'profileNetworkOnActiveAdapterChange',
        type: FROM_TYPES.SWITCH,
      },
      {
        label: 'Canbus Profiling Frequency',
        key: 'canBusProfilingFrequency',
        type: FROM_TYPES.SLIDER,
        postText: 'sec',
        min: 30,
        max: 600,
        isHidden: () => true,
      },
      {
        label: 'Sensor Profiling Frequency',
        key: 'sensorProfilingFrequency',
        type: FROM_TYPES.SELECT,
        options: frequencyOptions,
      },
      {},
      {
        label: 'Data Consumption',
        key: 'data-consumption',
        secondaryUnderline: true,
        children: [
          {
            label: 'Enable Data Consumption Reporting',
            key: 'enableDataConsumptionReporting',
            type: FROM_TYPES.SWITCH,
          },
          {},
          {
            label: 'Reporting Threshold',
            key: 'reportingThreshold',
            type: FROM_TYPES.NUMBER,
            postText: 'MB',
            min: 0,
            max: 30,
          },
          {
            label: 'Low Bandwidth Threshold',
            key: 'lowBandwidthThreshold',
            type: FROM_TYPES.NUMBER,
            postText: 'GB',
            min: 0,
            max: 1000,
          },
          {
            label: 'Blackout Threshold',
            key: 'blackoutThreshold',
            type: FROM_TYPES.NUMBER,
            postText: 'GB',
            min: 0,
            max: 1000,
          },
          {},
          {},
          {},
          {
            label: 'Start Date of Bill Cycle',
            key: 'billingCycleDate',
            type: FROM_TYPES.SELECT,
            options: billingCycleOptions,
          },
          {
            label: 'Period Report in Every',
            key: 'periodReportCycle',
            postText: 'Day',
            type: FROM_TYPES.SELECT,
            options: periodicReportOptions,
          },
          {},
          {
            label: 'Reset Periodic Counting at Time',
            key: 'resetPeriodicCountingAt',
            type: FROM_TYPES.DATE_TIME,
          },
        ],
      },
    ],
  },
];
