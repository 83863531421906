/** @param {{value: number}} props */
export function DriverScoreCircleSvg(props) {
  const { value } = props;

  const innerRadius = 82;
  const innerPerimeter = 2 * innerRadius * Math.PI;
  const innerValues = [0.84 * 1, 0.84 * 0.9, 0.84 * 0.8];
  //const innerColors = ['#bb2327', '#ffb300', '#70aa1f'];
  const innerColors = ['#2680EB', '#789BCB', '#A9B5C6'];

  const angle = (330 - 30) * value + 30;
  const valueColor = innerColors[value > 0.9 ? 0 : value > 0.8 ? 1 : 2];

  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <g>
        {innerValues.map((x, i) => (
          <ellipse
            key={i}
            cx="100"
            cy="100"
            rx={innerRadius}
            ry={innerRadius}
            fill="none"
            strokeWidth="16"
            strokeLinecap="round"
            stroke={innerColors[i]}
            strokeDasharray={`${x * innerPerimeter} ${(1 - x) * innerPerimeter}`}
            transform="translate(236 62) rotate(119)"
          />
        ))}
        <g transform={`translate(100 100) rotate(${90 + angle})`}>
          <ellipse cx="80" cy="0" rx="16" ry="16" fill="#ffffff" />
          <ellipse cx="80" cy="0" rx="13" ry="13" fill={valueColor} />
        </g>
      </g>
    </svg>
  );
}
