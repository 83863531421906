import { BOX_DISCONTINUE } from '@/assets/constants/images';
import { T } from '@/assets/locales';
import { AddOutlined } from '@mui/icons-material';

/** @type {Array<TableHeaderAction<DevicePoolInfoForTenant>>} */
export const ACTION_ITEMS = [
  {
    label: T['button.discontinue'],
    iconImage: BOX_DISCONTINUE,
    component: 'BUTTON',
    type: 'DISCONTINUE',
    variant: 'outlined',
    hidden: (selected) => true,
    confirmationMessage: T['device-pool.confirmations.discontinue'],
  },
  // {
  //   label: T['button.modify'],
  //   iconImage: BOX_MODIFY_ICON,
  //   component: 'BUTTON',
  //   variant: 'outlined',
  //   link: (selectedItem) => `/administration/device-pools/${selectedItem.id}/edit`,
  //   hidden: (selected) => selected.length !== 1,
  // },
  {
    component: 'SEARCH',
    label: 'Search',
    placeholder: T['device-pool.actions.search'],
    options: [
      {
        value: 'poolName',
        label: 'Pool Name',
      },
      {
        value: 'deviceSerial',
        label: 'Device Serial',
      },
    ],
  },
  {
    label: T['device-pool.actions.create'],
    icon: AddOutlined,
    component: 'BUTTON',
    variant: 'contained',
    link: '/administration/device-pools/create',
  },
];
