import api from '@/api';
import { T } from '@/assets/locales';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { sendEventsToGroup, sendOtaGroupMessages } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { SchemeForm } from '@/web/smarter-ai/scheme-management/schemes/@components/SchemeForm';
import { Box, Typography } from '@mui/material';
import { getTime } from 'date-fns';
import { isEmpty, unionBy } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SCHEME_PARENT_ITEMS } from '../assets';
import { formatSettingsBeforeSubmit } from '../utils/settings';

export function CreateScheme() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const deploySchemeAIContainer = async (containers, schemeId) => {
    try {
      let totalItems = 0;
      (containers || []).forEach(async (container) => {
        totalItems++;
        const { id } = container;
        const request = api.ac.v5.aicontainer
          .$aiContainerId(id)
          .scheme.$schemeId(schemeId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
      });

      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.AI_CONTAINER_DEPLOYED);
        //sendOtaGroupMessages(groupId, MESSAGE_TYPE.AI_EVENT_UPDATED);
      }
    } catch (error) {
      toastWarning(`Failed to deploy AI Container.`);
    }
  };

  const deploySchemeFirmware = async (firmwares, schemeId) => {
    try {
      let totalItems = 0;
      (firmwares || []).forEach(async (firmware) => {
        totalItems++;
        const request = api.ac.v5.firmware
          .$firmwareId(firmware?.firmwareId)
          .scheme.$schemeId(schemeId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
      });

      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.FIRMWARE_DEPLOYED);
        sendOtaGroupMessages(schemeId, MESSAGE_TYPE.FIRMWARE_DEPLOYED);
      }
    } catch (error) {
      toastWarning(`Failed to deploy firmware.`);
    }
  };

  const deployDBCFiles = async (dbcFiles, schemeId) => {
    try {
      let totalItems = 0;
      (dbcFiles || []).forEach(async (dbcFile) => {
        totalItems++;
        const request = api.ac.v5.dbc
          .$id(dbcFile?.id)
          .scheme.$schemeId(schemeId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
      });

      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.DBC_FILE_UPDATE);
        sendOtaGroupMessages(schemeId, MESSAGE_TYPE.DBC_FILE_UPDATE);
      }
    } catch (error) {
      toastWarning(`Failed to deploy dbc files.`);
    }
  };

  const deploySchemeTriggers = async (triggers, schemeId) => {
    try {
      let totalItems = 0;
      (triggers || []).forEach(async (item) => {
        totalItems++;
        const request = api.ac.v5.trigger.composite
          .$triggerId(item?.id)
          .scheme.$schemeId(schemeId)
          .$post({
            headers: {
              Authorization: secretToken,
            },
          });
        await request.process();
      });
      if (totalItems > 0) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.AI_EVENT_UPDATED);
        //sendOtaGroupMessages(groupId, MESSAGE_TYPE.AI_EVENT_UPDATED)
      }
    } catch (error) {
      toastWarning(`Failed to deploy triggers`);
    }
  };

  const updateSchemeSettings = async (settings, schemeId) => {
    try {
      const groupSettings = {};
      groupSettings.groupId = schemeId;
      groupSettings.properties = Object.keys(settings).map((item) => ({
        key: item,
        value: settings[item],
      }));
      groupSettings.properties.push({ key: 'lastUpdatedTime', value: getTime(new Date()) });

      const postRequest = api.ac.endpoint.group.settings.$post({
        params: {
          secretToken,
          groupId: schemeId,
        },
        data: groupSettings,
      });
      await postRequest.process();

      if (postRequest.response.status === 200) {
        sendEventsToGroup(schemeId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
        sendOtaGroupMessages(schemeId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
      }
    } catch (e) {
      toastWarning('Could not update settings');
    }
  };

  const createScheme = async (form) => {
    const firmwares = [];
    const aiContiners = [];
    const triggers = [];
    const dbcFiles = [];

    await form.properties.forEach((obj) => {
      const itemKey = obj.item[0].value;
      if (itemKey === SCHEME_PARENT_ITEMS.FIRMWARES) {
        const mappedValue = obj.value;
        firmwares.push(mappedValue);
      }
      if (itemKey === SCHEME_PARENT_ITEMS.AI_CONTAINERS) {
        const mappedValue = obj.value;
        aiContiners.push(mappedValue);
      }
      if (itemKey === SCHEME_PARENT_ITEMS.TRIGGERS) {
        const mappedValue = obj.value;
        triggers.push(mappedValue);
      }
      if (itemKey === SCHEME_PARENT_ITEMS.DBC) {
        const mappedValue = obj.value;
        dbcFiles.push(mappedValue);
      }
    });

    const settings = await formatSettingsBeforeSubmit(form.properties);

    const { schemeName, schemeDescription, product } = form;

    try {
      setLoading(true);
      const req = api.ac.v5.scheme.$post({
        headers: {
          Authorization: secretToken,
        },
        params: {
          tenantId,
          schemeName: schemeName,
          schemeDescription: schemeDescription,
          productId: product?.productId,
        },
      });
      const res = await req.process();
      await deploySchemeAIContainer(unionBy(aiContiners, 'id'), res.id);
      await deploySchemeFirmware(unionBy(firmwares, 'firmwareId'), res.id);
      await deployDBCFiles(unionBy(dbcFiles, 'id'), res.id);
      await deploySchemeTriggers(unionBy(triggers, 'id'), res.id);
      if (!isEmpty(settings)) {
        await updateSchemeSettings(settings, res.id);
      }
      toastSuccess('Success', t(T['scheme.create.success'], { schemeName }));
      navigate('/smarter-ai/scheme-management/schemes');
    } catch (err) {
      toastWarning('Error', t(T['scheme.create.retry']));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={2.5} pb={14}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['scheme.create.page.title'])}
      </Typography>
      <SchemeForm onSubmitData={createScheme} loading={loading} />
    </Box>
  );
}
