import api from '@/api';
import { MESSAGE_TYPE } from '@/assets/signalr/config';
import { buildProductProperties } from '@/hooks/useFullProductList';
import { selectSecretToken } from '@/store/auth';
import { sendEventsToGroup, sendOtaGroupMessages } from '@/utils/event-messaging';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { BackdropProgress } from '@/web/@components/BackdropProgress';
import { CenterBox } from '@/web/@components/CenterBox';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectTenantId } from '../../../../../store/auth/selectors';
import { DeviceGroupDetailsContext } from '../@components/DeviceGroupDetailsLayout';
import { GroupSettingsForms } from './@components/GroupSettingsForm';
import { SettingsActionButtons } from './@components/SettingsActionButtons';
import { DEFAULT_GROUP_SETTINGS } from '@/assets/schemes/defaultValues';
import {
  imagerBasedPipelineSettingsInitialization,
  validateGroupSettingsData,
} from '@/utils/schemes';
import { DASHCAM_ASSOCIATED_TYPE_ID } from '@/assets/schemes/constants';
import { DEVICE_GROUP_SETTINGS } from '@/assets/schemes/settings';

/**
 * @typedef {object} GroupSettingsContextParam
 * @property {Array<Input>} [inputs]
 */

/** @type {import("react").Context<GroupSettingsContextParam>} */
export const GroupSettingsContext = createContext(null);

export function GroupSettings() {
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);
  const { groupId, group } = useContext(DeviceGroupDetailsContext);

  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState('');
  const [product, setProduct] = useState(null);
  const [groupSettings, setGroupSettings] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [lastUpdateTime, setLastUpdatedTime] = useState(null);

  const methods = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: DEFAULT_GROUP_SETTINGS,
  });

  const { setValue, handleSubmit } = methods;

  const onSubmit = async (data) => {
    setIsEditing(false);
    setIsUpdating(true);
    try {
      let { form, errorArray } = validateGroupSettingsData(data, product);

      if (errorArray.length > 0) return;

      const request = api.ac.endpoint.group.settings.$get({
        params: {
          groupId,
          secretToken,
        },
      });
      await request.process();

      if (request.response.status === 200) {
        const mapped = request.result?.groupSettings?.properties?.map((item) => ({
          [item.key]: item.value,
        }));
        const settings = Object.assign({}, ...mapped);
        form = { ...settings, ...form };
      }

      const groupSettings = {};
      groupSettings.groupId = groupId;
      groupSettings.properties = Object.keys(form).map((item) => ({
        key: item,
        value: form[item],
      }));
      groupSettings.properties.push({ key: 'lastUpdatedTime', value: lastUpdateTime });

      const postRequest = api.ac.endpoint.group.settings.$post({
        params: {
          secretToken,
          groupId,
        },
        data: groupSettings,
      });
      await postRequest.process();

      if (postRequest.response.status === 200) {
        toastSuccess('Settings updated successfully!');
        sendEventsToGroup(groupId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
        sendOtaGroupMessages(groupId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
        fetchGroupSettings();
        setExpanded('');
      } else {
        toastWarning('Could not update settings.');
      }
    } catch (e) {
      toastWarning('Could not update settings');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const formatGroupSettingsData = useCallback(
    (groupSettings, product, isReset = false) => {
      Object.entries(groupSettings).forEach(([key, value]) => {
        imagerBasedPipelineSettingsInitialization(key, value, product, setValue);

        if (key === 'parkingMode') {
          setValue(key, value === 'ON' || value?.toString() === 'true');
          return;
        }

        if (key === 'usbFileTransfer') {
          if (groupSettings['usbMode'] === 'FILE_TRANSFER') {
            setValue(key, value === 'ON' || value?.toString() === 'true');
          } else {
            setValue(key, false);
          }
          return;
        }

        if (key === 'wifiNetworks') {
          setValue('wifiNetworks', value ? JSON?.parse(value) : []);
          return;
        }

        if (value === 'true' || value === 'false') {
          // @ts-ignore
          setValue(key, value === 'true');
          return;
        }

        if (!isReset) {
          if (key === 'periodicCheckInterval') {
            setValue('periodicCheckInterval', value / 60);
            return;
          }

          if (key === 'reportingThreshold') {
            setValue(key, value / 1024);
            return;
          }

          if (key === 'lowBandwidthThreshold') {
            setValue(key, value / 1024 / 1024);
            return;
          }

          if (key === 'blackoutThreshold') {
            setValue(key, value / 1024 / 1024);
            return;
          }
        }

        // @ts-ignore
        setValue(key, value);
      });
    },
    [setValue]
  );

  const onCancelEdit = () => {
    setIsEditing(false);
    formatGroupSettingsData(groupSettings, product);
  };

  const onHandleReset = async () => {
    formatGroupSettingsData(DEFAULT_GROUP_SETTINGS, product, true);
    sendEventsToGroup(groupId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
    sendOtaGroupMessages(groupId, MESSAGE_TYPE.GROUP_SETTINGS_UPDATE);
    toastSuccess('Settings updated successfully!');
  };

  const fetchGroupSettings = useCallback(() => {
    if (!groupId || !secretToken || !tenantId || !group) return;
    const request = api.ac.endpoint.group.settings.$get({
      params: {
        groupId,
        secretToken,
      },
    });
    request.process().then((result) => {
      const mapped = result?.groupSettings?.properties?.map((item) => ({
        [item.key]: item.value,
      }));

      const videoPipeline = mapped?.some((item) => item.hasOwnProperty('videoPipeline'));
      if (!videoPipeline) {
        mapped?.push({ videoPipeline: '' });
      }
      const audioPipeline = mapped?.some((item) => item.hasOwnProperty('audioPipeline'));
      if (!audioPipeline) {
        mapped?.push({ audioPipeline: '' });
      }
      const deviceImagerState = mapped?.some((item) => item.hasOwnProperty('imagerState'));
      if (!deviceImagerState) {
        mapped?.push({ imagerState: '' });
      }

      const groupSettings = Object.assign({}, ...(mapped || []));
      setLastUpdatedTime(result?.groupSettings.lastUpdatedTime);
      formatGroupSettingsData(groupSettings, product);
      setGroupSettings(groupSettings);
      setLoading(false);
    });
    return request;
  }, [groupId, secretToken, formatGroupSettingsData, group, tenantId, product]);

  /***********************************************************
   *                                                          *
   *             Fetching Group Product information           *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!group || !secretToken || !tenantId) return;
    const request = api.ac.v1.tenant.product.$get({
      params: {
        secretToken,
        productId: group?.associatedTypeId,
        tenantId,
      },
    });
    request.process().then((data) => {
      const parsedData = buildProductProperties(data);

      setProduct(parsedData);
    });
    return () => request && request.abort();
  }, [tenantId, group, secretToken]);

  /***********************************************************
   *                                                          *
   *            Fetching Group Settings and Parsing           *
   *                                                          *
   ***********************************************************/
  useEffect(() => {
    if (!product) return;
    const request = fetchGroupSettings();
    return () => request && request.abort();
  }, [fetchGroupSettings, product]);

  const hasDefaultProduct = useMemo(
    () => group?.associatedTypeId !== DASHCAM_ASSOCIATED_TYPE_ID,
    [group?.associatedTypeId]
  );

  if (loading || !product) {
    return (
      <CenterBox sx={{ mt: '100px' }}>
        <CircularProgress />
      </CenterBox>
    );
  }

  return (
    <>
      <GroupSettingsContext.Provider value={{ inputs: product?.inputs }}>
        <BackdropProgress isOpen={isUpdating} />
        <FormProvider {...methods}>
          <form autoComplete="off" noValidate>
            <SettingsActionButtons
              isEditing={isEditing}
              lastUpdateTime={lastUpdateTime}
              handleSettingsSubmit={handleSubmit(onSubmit)}
              setIsEditing={() => {
                setIsEditing(true);
                !expanded && setExpanded('recording');
              }}
              onCancelEdit={onCancelEdit}
              onHandleReset={onHandleReset}
            />

            <Box
              px={3}
              py="5px"
              sx={{
                '& .MuiPaper-elevation': {
                  border: '1px solid #c5d9f0',
                  borderRadius: '5px',
                },
                'overflowY': 'auto',
              }}
              height="100%"
            >
              {DEVICE_GROUP_SETTINGS.filter(
                (tab) => !(tab.key === 'parking' && hasDefaultProduct)
              ).map((settings, index) => {
                return (
                  <Accordion
                    key={settings.key + index}
                    disableGutters
                    elevation={0}
                    square
                    disabled={loading}
                    TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    expanded={expanded === settings.key}
                    onChange={handleChange(settings.key)}
                  >
                    <AccordionSummary expandIcon={<ArrowDropDown fontSize="large" />}>
                      <Typography>{settings.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      key={settings.key + index}
                      sx={{
                        ...(!isEditing && { pointerEvents: 'none', opacity: '.8' }),
                      }}
                    >
                      <GroupSettingsForms settings={settings.children} product={product} />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </form>
        </FormProvider>
      </GroupSettingsContext.Provider>
    </>
  );
}
