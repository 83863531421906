import { FilterContextProvider } from '@/web/@components/FilterContext';
import { TripFilterContextProvider } from '@/web/trips/@components/TripFilterContext';
import { TripContextProvider } from '@/web/trips/@components/TripContext';
import { Box } from '@mui/material';
import { CameraDetailsArea } from './@components/CameraDetailsArea';
import { CameraVinArea } from './@components/CameraVinArea';
import { RecentEventArea } from './@components/RecentEventArea';
import { RecentTripsArea } from './@components/RecentTripsArea';
import { HubConnectionProvider } from '@/web/@components/HubConnectionContext';

export function CameraDetailsPage() {
  return (
    <HubConnectionProvider>
      <Box p={2} pb={15}>
        <CameraDetailsArea />

        <RecentEventArea sx={{ mt: 2 }} />

        {/* Need to refactor */}
        <FilterContextProvider>
          <TripFilterContextProvider>
            <TripContextProvider>
              <RecentTripsArea sx={{ mt: 3 }} />
            </TripContextProvider>
          </TripFilterContextProvider>
        </FilterContextProvider>

        <CameraVinArea sx={{ mt: 3 }} />
      </Box>
    </HubConnectionProvider>
  );
}
