import { Box } from '@mui/material';
import { DevicePoolDetails } from './@components/DevicePoolDetails';
import { DevicePoolCardItems } from './@components/DeviePoolCardItems';

export function DevicePoolDetailsPage() {
  return (
    <Box display="flex" flexDirection="column" rowGap="15px" mx={2.5}>
      <DevicePoolDetails />
      <DevicePoolCardItems />
    </Box>
  );
}
