import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<DevicePoolInfoForTenant>>} */
export const DEVICE_POOL_TABLE_COLUMNS = [
  {
    id: 'id',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} disabled={true} />,
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    isPrimary: true,
    link: (row) => `/administration/device-pools/${row.id}`,
  },
];
