import api from '@/api';
import { BOX_MODIFY_ICON } from '@/assets/constants/images';
import { isDev } from '@/config';
import { selectSecretToken, selectUserRole } from '@/store/auth';
import { trimString } from '@/utils/formatting';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { BoxImage } from '@/web/@components/BoxImage';
import { CustomInput } from '@/web/@components/CustomForm';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

/** @param {import('@mui/material').IconButtonProps} props */
export function EditCameraName(props) {
  const secretToken = useSelector(selectSecretToken);
  const useRole = useSelector(selectUserRole);

  const { cameraId, camera, refreshCamera } = useContext(CameraDetailsContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      endpointName: camera?.label || '',
    },
  });

  useEffect(() => {
    form.setValue('endpointName', camera?.label || '');
  }, [form, camera?.label]);

  const submitEndpointName = async ({ endpointName }) => {
    setLoading(true);
    const request = api.ac.endpoint.info_fields.$put({
      params: {
        secretToken,
        endpointID: cameraId,
      },
      data: [
        {
          fieldName: 'LABEL',
          valueStr: trimString(endpointName),
        },
      ],
    });
    try {
      await request.process();
      toastSuccess(null, endpointName ? 'Changed the camera name' : 'Removed the camera name');
      setLoading(false);
      setShow(false);
      refreshCamera();
    } catch (err) {
      setLoading(false);
      console.error(err);
      toastWarning('Failed to change the camera name');
    }
  };

  if (!isDev && useRole === 'USER') return null;

  return (
    <>
      <IconButton onClick={() => setShow(true)} {...props}>
        <BoxImage src={BOX_MODIFY_ICON} size="16px" />
      </IconButton>

      <Dialog open={show} onClose={() => setShow(false)} fullWidth maxWidth="xs">
        <DialogTitle>Change Endpoint Name</DialogTitle>
        <Divider />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitEndpointName)} autoComplete="off" noValidate>
            <DialogContent>
              <CustomInput
                name="endpointName"
                label="Camera Name"
                placeholder="Enter the camera name"
                rules={{ required: 'The camera name is required' }}
              />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button type="reset" onClick={() => setShow(false)}>
                Cancel
              </Button>
              <Button type="submit" autoFocus variant="contained" disabled={loading}>
                {loading && <CircularProgress color="inherit" size={16} />}
                <span>Apply</span>
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
}
