import { Box, FormControl, FormLabel, Slider } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * @typedef {object} CustomSliderProps
 * @property {string} name
 * @property {string} label
 * @property {boolean} [isDisabled]
 * @property {number} [step]
 * @property {number} [defaultVal]
 * @property {string} [postText]
 * @property {number} [min]
 * @property {number} [max]
 * @property {number} [height]
 * @property {import('@mui/material').SliderProps} [SliderProps]
 * @property {import('react-hook-form').ControllerProps['rules']} [rules]
 */

/**
 * @param {CustomSliderProps} props
 */
export function CustomSlider(props) {
  const {
    name,
    label,
    isDisabled = false,
    step = 1,
    defaultVal = 0,
    postText = '',
    rules,
    min = 0,
    max = 100,
    height = 4,
    SliderProps,
  } = props;

  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultVal);
  }, [defaultVal, setValue, name]);

  return (
    <FormControl variant="standard" fullWidth>
      <Box display="flex" alignItems="center" gap="4px">
        <Controller
          name={name}
          rules={rules}
          control={control}
          defaultValue={Number(defaultVal)}
          render={({ field }) => (
            <Slider
              sx={{
                'color': '#608AC3',
                'height': height,
                'padding': '15px 0',
                '& .MuiSlider-thumb': {
                  backgroundColor: '#fff',
                  border: '4px solid #608AC3',
                },
                '& .MuiSlider-valueLabel': {
                  fontSize: 12,
                  fontWeight: 'normal',
                  background: '#596A82',
                  color: 'white',
                },
                '& .MuiSlider-markLabel': {
                  fontSize: '0.75rem',
                },
                ...(SliderProps?.marks && {
                  '& .MuiSlider-markLabel[data-index="0"]': {
                    transform: 'translateX(0) !important',
                  },
                  // @ts-ignore
                  [`& .MuiSlider-markLabel[data-index="${SliderProps?.marks?.length - 1}"]`]: {
                    transform: 'translateX(-100%) !important',
                  },
                }),
              }}
              disabled={Boolean(isDisabled) || false}
              defaultValue={Number(defaultVal)}
              value={field?.value || 0}
              aria-label="Slider"
              marks={SliderProps?.marks || false}
              valueLabelDisplay="auto"
              valueLabelFormat={(x) => (postText ? `${x} ${postText}` : '')}
              step={Number(step) || 1}
              min={Number(min) || 0}
              max={Number(max) || 100}
              onChange={(_, value) => {
                field.onChange(value);
                setValue(name, value);
              }}
              {...SliderProps}
            />
          )}
        />
        <FormLabel sx={{ fontWeight: 500, fontSize: '0.875rem' }}>{label}</FormLabel>
      </Box>
    </FormControl>
  );
}
