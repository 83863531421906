import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { TenantForm } from '@/web/administration/tenants/@components/TenantForm';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function TenantCreatePage() {
  const secretToken = useSelector(selectSecretToken);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createTenant = async (form) => {
    const { adminEmail, adminName, tenantName, products } = form;
    const productIds = products?.map((item) => item.productId);
    try {
      setLoading(true);
      const req = api.ac.v5.tenant.subtenants.$post({
        headers: { Authorization: secretToken },
        data: {
          adminEmail,
          adminName,
          tenantName,
          products: productIds,
        },
      });
      await req.process();
      toastSuccess('Success', t(T['tenant.create.success'], { tenantName }));
      navigate('/administration/tenants');
    } catch (err) {
      toastWarning('Error', t(T['tenant.create.retry']));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['tenant.create.page.title'])}
      </Typography>
      <TenantForm onSubmitData={createTenant} isLoading={loading} />
    </Box>
  );
}
