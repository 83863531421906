import { T } from '@/assets/locales';
import { ItemStatus } from '@/web/@components/ItemStatus';
import { TableCheckboxHeader } from '@/web/@components/TableCheckboxHeader';
import { TableCheckboxItem } from '@/web/@components/TableItemCheckbox';

/** @type {Array<TableColumn<AiContainerResponse>>} */
export const AI_CONTAINER_TABLE_COLUMNS = [
  {
    id: 'id',
    label: '',
    align: 'left',
    width: 50,
    isPrimary: true,
    headerFormat: (value, rows) => <TableCheckboxHeader rows={rows} itemKey={'id'} />,
    format: (value, row) => <TableCheckboxItem row={row} />,
  },
  {
    id: 'name',
    label: T['ai.containers.table.columns.name'],
    align: 'left',
    isPrimary: true,
    sort: true,
    searchable: true,
  },
  {
    id: 'versionNumber',
    label: T['ai.containers.table.columns.version'],
    align: 'center',
    sort: true,
    searchable: true,
    format: (value) => value || '...',
  },
  {
    id: 'publicationStatus',
    label: T['ai.containers.table.columns.publication.status'],
    align: 'left',
    sort: true,
    searchable: true,
    format: (value) => <ItemStatus status={value} />,
  },
];
