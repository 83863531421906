import api from '@/api';
import { STATUS_LIST } from '@/assets/constants/table';
import { T } from '@/assets/locales';
import { useFetchTriggerCategories } from '@/hooks/useFetchTriggerCategories';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { CenterBox } from '@/web/@components/CenterBox';
import { ItemsListHeader } from '@/web/@components/ItemsListHeader';
import { ItemsResponsiveView } from '@/web/@components/ItemsResponsiveView';
import { OffsetPagination } from '@/web/@components/OffsetPagination';
import { PaginatedTableContextProvider } from '@/web/@components/PaginatedTableContext';
import { Box, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TriggerCloneDialog } from './@components/TriggerCloneDialog';
import { ACTIONS_ITEMS } from './actions';
import { TRIGGER_TABLE_COLUMNS } from './columns';

export function TriggerListView() {
  const tenantId = useSelector(selectTenantId);
  const secretToken = useSelector(selectSecretToken);

  const { result: triggerCategories, loading: triggerCategoryLoading } =
    useFetchTriggerCategories();

  const { t } = useTranslation();

  const [refreshCount, setRefreshCount] = useState(0);
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [isCloning, setIsCloning] = useState(false);

  /** @type {import('@/web/@components/ItemsListHeader').ItemsListHeaderProps<CompositeTriggerDto>['onAction']} */
  const onActionHandle = async (type, selectedItems, setTableReload) => {
    if (type === 'DISCONTINUE') {
      await discontinueTriggers(selectedItems);
      setTableReload(true);
    } else if (type === 'RELEASE') {
      await releaseTriggers(selectedItems);
      setTableReload(true);
    } else if (type === 'CLONE') {
      //cloneTriggers(selectedItems[0]);
      setTimeout(() => {
        setSelectedTrigger(selectedItems[0]);
      }, 200);
    }
  };

  const cloneTriggers = async (triggerName) => {
    try {
      const request = api.ac.v5.trigger.composite.$triggerId(selectedTrigger.id).$get({
        headers: {
          Authorization: secretToken,
        },
        params: {},
      });

      request.process().then((trigger) => {
        const {
          cooldown,
          cooldownTimer,
          mediaType,
          tts,
          body,
          chime,
          critical,
          triggerCategoryId,
          type,
          uploadStrategy,
          ttsLanguageLocale,
          ttsGender,
          snapshotFormula,
          primarySnapshotImager,
          preBuffer,
          postBuffer,
          enableTrigger,
          enableTts,
          magnitudeFormula,
          mediaSource,
          isSystemTrigger,
        } = trigger;

        const expression = body?.displayFormula || body?.expression;
        const bodyObj = {
          expression: expression,
          displayFormula: expression,
          classifications: body?.classifications || [],
          type: type,
        };

        /** @type {CompositeTriggerRequest} */
        const data = {
          name: triggerName,
          triggerCategoryId,
          cooldown: cooldown?.value,
          cooldownTimer: cooldownTimer?.value,
          mediaType: mediaType?.value,
          critical,
          chime: chime?.value,
          body: JSON.stringify(bodyObj),
          tts,
          uploadStrategy: uploadStrategy?.value,
          ttsLanguageLocale: ttsLanguageLocale?.value,
          ttsGender: ttsGender?.value,
          snapshotFormula: JSON.stringify(snapshotFormula),
          primarySnapshotImager,
          preBuffer: preBuffer?.value,
          postBuffer: postBuffer?.value,
          enableTrigger: enableTrigger?.value,
          enableTts: enableTts?.value,
          magnitudeFormula: JSON.stringify(magnitudeFormula),
          mediaSource,
          systemTrigger: isSystemTrigger,
        };

        const req = api.ac.v5.trigger.composite.$post({
          headers: {
            Authorization: secretToken,
          },
          data,
        });

        req
          .process()
          .then((item) => {
            setIsCloning(false);
            setSelectedTrigger(null);
            setRefreshCount((value) => value + 1);
            toastSuccess('Success', 'Trigger cloned successfully');
          })
          .catch((ex) => {
            setIsCloning(false);
            setSelectedTrigger(null);
            toastWarning('Error', 'Failed to cloned trigger');
          });
      });
    } catch (e) {
      setIsCloning(false);
      setSelectedTrigger(null);
      toastWarning('Error', `Failed to clone ${selectedTrigger.name}`);
    }
  };

  const discontinueTrigger = async (trigger) => {
    try {
      const request = api.ac.v5.trigger.composite.$triggerId(trigger.id).discontinue.$patch({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
    } catch (e) {
      toastWarning('Error', `Failed to discontinue ${trigger.name}`);
    }
  };

  const discontinueTriggers = async (selectedItems) => {
    await Promise.all(selectedItems.map(discontinueTrigger));
  };

  const releaseTrigger = async (trigger) => {
    try {
      const request = api.ac.v5.trigger.composite.$triggerId(trigger.id).release.$patch({
        headers: {
          Authorization: secretToken,
        },
      });
      await request.process();
    } catch (e) {
      toastWarning('Error', `Failed to release ${trigger.name}`);
    }
  };

  const releaseTriggers = async (selectedItems) => {
    await Promise.all(selectedItems.map(releaseTrigger));
  };

  if (triggerCategoryLoading) {
    return <CenterBox style={{ height: '50vh' }} children={<CircularProgress />} />;
  }

  return (
    <>
      {selectedTrigger && (
        <TriggerCloneDialog
          onClose={() => {
            setSelectedTrigger(null);
            setIsCloning(false);
          }}
          onSubmit={(name) => {
            setIsCloning(true);
            cloneTriggers(name);
          }}
          name={selectedTrigger.name}
          isCloning={isCloning}
        />
      )}
      <Box mx={2.5} mt={2}>
        <PaginatedTableContextProvider>
          <ItemsListHeader
            title={t(T['triggers.page.title'])}
            actions={ACTIONS_ITEMS}
            onAction={onActionHandle}
          />
          <OffsetPagination
            key={secretToken + refreshCount}
            fetcher={async ({ signal, limit, offset, searchText, searchType }) => {
              const request = api.ac.v5.trigger.composite.tenant.$tenantId(tenantId).filter.$get({
                signal,
                headers: {
                  Authorization: secretToken,
                },
                params: {
                  limit: limit,
                  offset: offset,
                  ...(searchText && searchType && { [searchType]: searchText.toUpperCase() }),
                },
              });
              await request.process();
              return {
                data: request.result.compositeTriggers
                  .filter((x) =>
                    [STATUS_LIST.DRAFT, STATUS_LIST.RELEASED]?.includes(x.publicationStatus)
                  )
                  .map((item) => ({
                    ...item,
                    category: triggerCategories?.find((cat) => cat.id === item.triggerCategoryId),
                  })),
                count: Number(request.response.headers['x-total-count']),
              };
            }}
            renderList={(/** @type {Array<CompositeTriggerDto>} */ results) => (
              <ItemsResponsiveView results={results} columns={TRIGGER_TABLE_COLUMNS} />
            )}
          />
        </PaginatedTableContextProvider>
      </Box>
    </>
  );
}
