import { TRIGGER_NAMES } from '../triggers/categories';

/**
 * Format Event Trigger Name
 * @param  {string} triggerName
 * @returns {string}
 */
export const formatTriggerName = (triggerName) => {
  if (!triggerName) return 'Trigger not available';
  if (triggerName === 'REST_API') return 'System-generated';
  if (triggerName === 'EMERGENCY_RECORD') return 'Driver-generated';
  return triggerName;
};

const vid_2_keywords = new Set([
  'Driver', //
  'Cabin',
]);

/**
 * Select which video imager to display based on trigger category id
 * @param {number} triggerCategoryId
 * @param {string} [triggerName]
 * @returns {'vid_1'|'vid_2'}
 */
export const selectDisplaySourceId = (triggerCategoryId, triggerName) => {
  const name = TRIGGER_NAMES[triggerCategoryId] + '' + triggerName;
  //name.split('.').some((part) => vid_2_keywords.has(part))
  const containsKeyword = [...vid_2_keywords].some((keyword) =>
    name?.toLowerCase()?.includes(keyword?.toLowerCase())
  );
  if (containsKeyword) {
    return 'vid_2';
  }
  return 'vid_1';
};
