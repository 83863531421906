import { formatTimestamp } from '@/utils/datetime';

/** @type {Array<TableColumn<CameraTypeRecord>>} */
export const GROUP_CAMERA_CPU_USAGE_TABLE_COLUMNS = [
  {
    id: 'reportingTime',
    label: 'Time',
    align: 'left',
    searchable: true,
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'avgCpuUse',
    label: 'Average Use',
    align: 'center',
    searchable: true,
    format: (value) => `${parseInt(value)} %`,
  },
];
