import api from '@/api';
import { store } from '@/store';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { SmartCache } from '../caching/smart-cache';
import { isDev, isLocalNetwork } from '@/config';

/**
 * @typedef {object} SignalRDelayProperties
 * @property {string} [Type]
 * @property {string} [Sender]
 * @property {number} [Recipient]
 * @property {string} [ConnectionId]
 * @property {number} [SenderTimestamp]
 * @property {number} [ServiceTimestamp]
 * @property {number} [RecipientTimestamp]
 * @property {number} [EndToEndDelay]
 * @property {number} [SenderToServiceDelay]
 * @property {number} [ServiceToRecipientDelay]
 * @property {'BROWSER'} [Platform]
 */

const signalRDelayCache = new SmartCache(`signalR-Delays`, 2 * 24 * 3600 * 1000);

export const prepareSignalRDelayData = async (data) => {
  /** @type {Array<SignalRDelayProperties>} */
  const cached = (await signalRDelayCache.getItem(data?.sender)) || [];

  const now = Date.now();

  /** @type {SignalRDelayProperties} */
  const parsedObj = {
    Type: data?.type || 0,
    Sender: data?.sender || 0,
    Recipient: data?.sender || data?.endpointId || 0,
    ConnectionId: data?.connectionId || '',
    SenderTimestamp: data?.occurrenceTime,
    ServiceTimestamp: data?.sentTime,
    RecipientTimestamp: now,
    EndToEndDelay: Math.max(now - data?.occurrenceTime, 0),
    SenderToServiceDelay: Math.max(data?.sentTime - data?.occurrenceTime, 0),
    ServiceToRecipientDelay: Math.max(now - data?.sentTime, 0),
    Platform: 'BROWSER',
  };

  const newItem = {
    reportingTime: Date.now(),
    subType: 'signalr',
    type: 'camera.smarterai.health',
    properties: JSON.stringify(parsedObj),
    endpointId: data?.sender,
  };

  const mergedArray = [...cached, newItem];
  // Update the cache with the new array
  await signalRDelayCache.setItem(data?.sender, mergedArray);
};

const sendDelayReportRequest = async (endpointId, cachedProperties) => {
  if (endpointId === 0 || !endpointId) return;
  const state = store.getState();
  const tenantId = selectTenantId(state);
  const secretToken = selectSecretToken(state);

  if (!tenantId || !secretToken || !endpointId) return;
  const request = api.ac.v3.report.device.records.$post({
    params: {
      secretToken,
      endpointId,
      tenantId,
    },
    data: cachedProperties,
  });

  return request
    .process()
    ?.then(() => {
      signalRDelayCache?.removeItem(endpointId);
    })
    .catch((ex) => {});
};

export const sendSignalRDelayReports = async () => {
  try {
    if (isDev && isLocalNetwork) return;
    for await (const report of signalRDelayCache.getAllValues()) {
      if (!report?.length || !report?.at(0)?.endpointId) continue;
      sendDelayReportRequest(report?.at(0)?.endpointId, report);
    }
  } catch (error) {
    console.error('Error fetching cache data:', error);
  }

  return;
};
