import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { deployedSchemesArraysDifferent } from '@/web/smarter-ai/scheme-management/schemes/utils';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SELECTION_TYPES } from '../assets';
import { ApplicableTypeSelection } from './@components/ApplicableTypeSelection';
import { CameraGroupSelection } from './@components/CameraGroupSelection';
import { CameraSelection } from './@components/CameraSelection';
import { SchemeSelection } from './@components/SchemeSelection';
import { TenantSelection } from './@components/TenantSelection';

export function ApplySchemes() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues: {
      selectionType: SELECTION_TYPES[0],
      camera: null,
      includeChildTenant: false,
      tenant: null,
      group: null,
      selectedSchemes: [],
      availableSchemes: [],
      deployedItems: [],
      isDirty: false,
      submitCount: 0,
    },
  });

  const { control, setValue } = form;

  const inputType = useWatch({ control, name: `selectionType` });
  const selectedSchemes = useWatch({ control, name: `selectedSchemes` });
  const deployedSchemes = useWatch({ control, name: `deployedItems` });
  const availableSchemes = useWatch({ control, name: `availableSchemes` });
  const submitCount = useWatch({ control, name: `submitCount` });
  const camera = useWatch({ control, name: `camera` });
  const tenant = useWatch({ control, name: `tenant` });
  const group = useWatch({ control, name: `group` });

  const areArraysDifferent = useMemo(() => {
    return deployedSchemesArraysDifferent(deployedSchemes, selectedSchemes, availableSchemes);
  }, [selectedSchemes, deployedSchemes, availableSchemes]);

  useEffect(() => {
    setValue('camera', null);
    setValue('tenant', null);
    setValue('group', null);
    setValue('selectedSchemes', []);
  }, [inputType, setValue, tenantId]);

  const handleCancel = () => {
    navigate('/administration/schemes');
  };

  const undeploySchemes = async (selectedItems, camera, group, tenant) => {
    if (!selectedItems?.length) return;
    let errorCount = 0;
    await Promise.allSettled(
      selectedItems.map(async (scheme) => {
        try {
          const request = api.ac.v5.scheme.undeploy.$delete({
            headers: {
              Authorization: secretToken,
            },
            params: {
              ...(camera ? { endpointID: camera?.endpointId } : {}),
              ...(group ? { groupId: group?.id } : {}),
              ...(tenant ? { tenantId: tenant?.tenantId } : {}),
              schemeId: scheme.schemeId,
            },
          });
          await request.process();
        } catch (e) {
          errorCount = errorCount + 1;
          // toastWarning(`Failed to Undeploy ${scheme.schemeName}`);
        }
      })
    );
    return errorCount;
  };

  const deploySchemes = async (selectedItems, camera, group, tenant) => {
    if (!selectedItems?.length) return;
    let errorCount = 0;
    await Promise.allSettled(
      selectedItems.map(async (scheme) => {
        try {
          const request = api.ac.v5.scheme.deploy.$post({
            headers: {
              Authorization: secretToken,
            },
            params: {
              ...(camera ? { endpointID: camera?.endpointId } : {}),
              ...(group ? { groupId: group?.id } : {}),
              ...(tenant ? { tenantId: tenant?.tenantId } : {}),
              schemeId: scheme.id,
            },
          });
          await request.process();
        } catch (e) {
          errorCount = errorCount + 1;
          // toastWarning(`Failed to Deploy Scheme ${scheme.name}`);
        }
      })
    );
    return errorCount;
  };

  const prepareAndSubmit = async (data) => {
    try {
      const { availableSchemes, selectedSchemes, deployedItems, camera, group, tenant } = data;

      // Find newly added items based on id
      const newlyAddedItems = selectedSchemes?.filter(
        (newItem) =>
          !deployedItems?.some((deployedItem) => Number(deployedItem.schemeId) === newItem.id)
      );

      //Find the unselected Items
      const unselectedItems = availableSchemes?.filter(
        (item) => !selectedSchemes?.some((selected) => Number(selected.id) === item.id)
      );
      // Find deleted items based on id
      const deletedItems = deployedItems?.filter((deployedItem) =>
        unselectedItems?.some((newItem) => newItem.id === Number(deployedItem.schemeId))
      );

      const undeployErrorCount = await undeploySchemes(deletedItems, camera, group, tenant);
      const deployErrorCount = await deploySchemes(newlyAddedItems, camera, group, tenant);

      const totalChangedItems = deletedItems + newlyAddedItems;
      const totalErrorCount = undeployErrorCount + deployErrorCount;
      const successMessage =
        totalErrorCount > 0
          ? `${
              totalChangedItems - totalErrorCount
            } out of ${totalChangedItems} schemes applied successfully.`
          : 'Schemes Applied Successfully';

      if (totalErrorCount === totalChangedItems) {
        toastWarning('Failed to Apply Scheme');
      } else {
        toastSuccess(successMessage);
      }

      setValue('submitCount', submitCount + 1);
      setIsLoading(false);

      navigate('/administration/schemes/apply');
    } catch (error) {
      toastWarning('Failed to Apply Scheme');
      setIsLoading(false);
    }
  };

  return (
    <Box p={2.5} pb={14}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'} mb={2}>
        Apply Scheme
      </Typography>

      <FormProvider {...form}>
        <Grid
          container
          spacing={{ xs: 2, md: 10 }}
          sx={{
            'alignItems': 'center',
            '& input': {
              fontSize: '15px',
              fontWeight: 500,
            },
          }}
        >
          <Grid item xs={12} md={6}>
            <ApplicableTypeSelection
              property="selectionType"
              inputType={inputType}
              setValue={setValue}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {inputType?.value === 'ENDPOINT' ? (
              <CameraSelection property="camera" />
            ) : inputType?.value === 'GROUP' ? (
              <CameraGroupSelection property="group" />
            ) : inputType?.value === 'TENANT' ? (
              <TenantSelection property="tenant" />
            ) : null}
          </Grid>
        </Grid>

        <SchemeSelection
          property={'selectedSchemes'}
          submitCount={submitCount}
          camera={camera}
          group={group}
          tenant={tenant}
        />

        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          gap={'5px'}
          mt={4}
          sx={{
            '& .Mui-disabled': {
              backgroundColor: '#acb4c0 !important',
              color: 'white !important',
            },
          }}
        >
          <Button variant="text" onClick={handleCancel} size="medium">
            {t(T['button.cancel'])}
          </Button>
          <Button
            size="medium"
            disabled={isLoading || !areArraysDifferent}
            variant="contained"
            onClick={form.handleSubmit(prepareAndSubmit)}
          >
            {isLoading && <CircularProgress color="inherit" size={16} sx={{ mr: '5px' }} />}
            {t(T['button.apply'])}
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
