import { byteToPercentage, formatTimestamp } from '@/utils/datetime';
import { prettyBytes } from '@/utils/pretty-bytes';

/** @type {Array<TableColumn<CameraTypeRecord>>} */
export const GROUP_CAMERA_DISK_USAGE_TABLE_COLUMNS = [
  {
    id: 'reportingTime',
    label: 'Time',
    align: 'left',
    searchable: true,
    isPrimary: true,
    format: (value) => formatTimestamp(value),
  },
  {
    id: 'internal',
    label: 'Internal Disk Use',
    align: 'center',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'internal',
    label: 'Internal Disk Use (%)',
    align: 'center',
    searchable: true,
    format: (value, row) => value && byteToPercentage(value, row?.totalInternalDisk),
  },
  {
    id: 'external',
    label: 'External Disk Use',
    align: 'center',
    searchable: true,
    format: (value) => value && prettyBytes(value),
  },
  {
    id: 'external',
    label: 'External Disk Use (%)',
    align: 'center',
    searchable: true,
    format: (value, row) => value && byteToPercentage(value, row?.totalExternalDisk),
  },
];
