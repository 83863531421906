import api from '@/api';
import { selectSecretToken } from '@/store/auth';
import { KnownUserError } from '@/utils/errors';
import { CenterBox } from '@/web/@components/CenterBox';
import { MainContext } from '@/web/@components/PageBreadcrumb/BreadcrumbContext';
import { CircularProgress } from '@mui/material';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

/**
 * @typedef {object} VinGroupDetailsContextParams
 * @property {string} groupId
 * @property {VinGroup} vinGroup
 */

/** @type {import('react').Context<VinGroupDetailsContextParams>} */
export const VinGroupDetailsContext = createContext(null);

export function VinGroupDetailsLayout() {
  const params = useParams();
  const secretToken = useSelector(selectSecretToken);
  const { setBreadcrumbTitle } = useContext(MainContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vinGroup, setVinGroup] = useState(null);

  const groupId = useMemo(() => '' + params.id, [params]);

  useEffect(() => {
    setError(null);
    setLoading(true);
    const request = api.ac.v5['vin-groups'].$id(groupId).$get({
      headers: {
        Authorization: secretToken,
      },
    });
    request
      .process()
      .then(setVinGroup)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [groupId, secretToken]);

  useEffect(() => {
    if (!vinGroup) return;
    setBreadcrumbTitle(vinGroup.name, (path) => path.endsWith(groupId));
  }, [setBreadcrumbTitle, groupId, vinGroup]);

  if (loading) {
    return (
      <CenterBox fullView>
        <CircularProgress />
      </CenterBox>
    );
  }

  if (error || !vinGroup) {
    throw new KnownUserError('Failed to get VIN Group details');
  }

  return (
    <VinGroupDetailsContext.Provider value={{ groupId, vinGroup }}>
      <Outlet />
    </VinGroupDetailsContext.Provider>
  );
}
