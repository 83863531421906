import { Box } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

/**
 * @typedef {object} EmptyImagePlaceholderProps
 * @property {import('@mui/material').SxProps} [containerSx]
 * @property {import('@mui/material').SxProps} [iconSx]
 */

/** @param {EmptyImagePlaceholderProps} props */
export function EmptyImagePlaceholder(props) {
  const { containerSx, iconSx } = props;

  return (
    <Box
      sx={{
        width: '50%',
        height: '80px',
        border: '1px dashed #C4D0E1',
        background: '#F2F7FC',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        ...containerSx,
      }}
    >
      <ImageIcon
        htmlColor="#C4D0E1"
        fontSize="large"
        sx={{ height: '50px', width: '50px', ...iconSx }}
      />
    </Box>
  );
}
