import { Box, Popover, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { TRIGGER_COLOR } from '../../assets';

const lineStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '4.6px',
  backgroundColor: TRIGGER_COLOR.BLUE,
  position: 'relative',
  borderRadius: '13px',
};

const circleStyle = {
  width: '10px',
  height: '10px',
  borderRadius: '70%',
  border: '2px solid white',
  position: 'absolute',
  padding: '4px',
};

const redLineStyle = {
  flexGrow: 1,
  height: '100%',
  backgroundColor: TRIGGER_COLOR.RED,
  margin: '0 33px',
};

export function BufferInfo(props) {
  const { preBuffer = 0, postBuffer = 0, defaultPreBuffer = 0, defaultPostBuffer = 0 } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const preBufferCircleColor = useMemo(
    () => (preBuffer === defaultPreBuffer ? TRIGGER_COLOR.BLUE : TRIGGER_COLOR.RED),
    [preBuffer, defaultPreBuffer]
  );

  const postBufferCircleColor = useMemo(
    () => (postBuffer === defaultPostBuffer ? TRIGGER_COLOR.BLUE : TRIGGER_COLOR.RED),
    [postBuffer, defaultPostBuffer]
  );

  // const bufferLineColor = useMemo(
  //   () =>
  //     [preBufferCircleColor, postBufferCircleColor]?.includes(TRIGGER_COLOR.RED)
  //       ? TRIGGER_COLOR.SHALLOW_RED
  //       : TRIGGER_COLOR.BLUE,
  //   [preBufferCircleColor, postBufferCircleColor]
  // );

  return (
    <>
      <Box
        height="15px"
        minWidth="100px"
        width="100px"
        textAlign="center"
        margin="auto"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ '&:hover': { cursor: 'pointer' } }}
      >
        {/* <Box sx={{ ...lineStyle, backgroundColor: bufferLineColor }}> */}
        <Box sx={{ ...lineStyle }}>
          <Box
            sx={{
              ...circleStyle,
              left: `${21 - Math.min(preBuffer * 1.5, 20)}px`,
              backgroundColor: preBufferCircleColor,
            }}
          >
            <Typography
              variant="caption"
              sx={{ whiteSpace: 'nowrap', ml: preBuffer < 0.01 ? '-25px' : '-15px' }}
            >
              {preBuffer} sec
            </Typography>
          </Box>
          <Box sx={redLineStyle}></Box>
          <Box
            sx={{
              ...circleStyle,
              right: `${21 - Math.min(postBuffer * 1.5, 20)}px`,
              backgroundColor: postBufferCircleColor,
            }}
          >
            <Typography
              variant="caption"
              sx={{ whiteSpace: 'nowrap', ml: postBuffer < 0.01 ? '-9px' : '-13px' }}
            >
              {postBuffer} sec
            </Typography>
          </Box>
        </Box>
      </Box>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box m={1} display="grid">
          <Typography variant="caption" fontSize="12px" fontWeight={600}>
            Default
          </Typography>
          <Typography variant="caption">Pre Buffer: {defaultPreBuffer || 0} sec</Typography>
          <Typography variant="caption">Post Buffer: {defaultPostBuffer || 0} sec</Typography>
        </Box>
      </Popover>
    </>
  );
}
