import { useRouteQuery } from '@/hooks/useRouteQuery';
import { CameraDetailsContext } from '@/web/@layouts/CameraDetailsLayout';
import { TripContextProvider } from '@/web/trips/@components/TripContext';
import { TripDateRangePicker } from '@/web/trips/@components/TripDateRangePicker';
import { TripFilterContextProvider } from '@/web/trips/@components/TripFilterContext';
import { TripListContainer } from '@/web/trips/@components/TripListContainer';
import { TripsCountHeader } from '@/web/trips/@components/TripsCountHeader';
import { TripsItemSearch } from '@/web/trips/@components/TripsItemSearch';
import { Box, Hidden } from '@mui/material';
import { useContext } from 'react';

export function CameraTripsPage() {
  const { query } = useRouteQuery();
  const { cameraId } = useContext(CameraDetailsContext);

  if (query.layout === 'empty') {
    return (
      <TripFilterContextProvider>
        <TripContextProvider cameraId={cameraId}>
          <TripListContainer />
        </TripContextProvider>
      </TripFilterContextProvider>
    );
  }

  return (
    <TripFilterContextProvider>
      <TripContextProvider cameraId={cameraId}>
        <Box px={2.5} mt={1} display="flex" gap={1} alignItems="center">
          <TripsCountHeader />
          <Box flex={1} />
          <Hidden mdDown>
            <TripsItemSearch cameraId={cameraId} />
            <TripDateRangePicker />
          </Hidden>
        </Box>

        <Hidden mdUp>
          <Box px={2} mt={0.5}>
            <Box pb={1}>
              <TripsItemSearch cameraId={cameraId} />
            </Box>
            <TripDateRangePicker />
          </Box>
        </Hidden>

        <TripListContainer />
      </TripContextProvider>
    </TripFilterContextProvider>
  );
}
