import {
  ANNOTATION_ICON,
  BOX_CREATE_ICON,
  BOX_LIST_ICON,
  GEO_FENCE_ICON,
} from '@/assets/constants/images';
import { T } from '@/assets/locales';

/** @type {Array<PageCardItem>} */
export const OPERATIONS_ITEMS = [
  {
    name: T['annotations.page.card.name'],
    image: ANNOTATION_ICON,
    color: '#F0EDF2',
    type: 'ANNOTATION_CATEGORY',
    link: '/operations/annotations',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['annotations.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/operations/annotations',
      },
      {
        name: T['annotations.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/operations/annotations',
      },
    ],
  },
  {
    name: T['geofences.page.card.name'],
    image: GEO_FENCE_ICON,
    color: '#EDF2F2',
    type: 'GEO_FENCE',
    link: '/operations/geofences',
    accessLevel: 'ADMIN',
    components: [
      {
        name: T['geofences.page.card.browseItems'],
        image: BOX_LIST_ICON,
        link: '/operations/geofences',
      },
      {
        name: T['geofences.page.card.createNew'],
        image: BOX_CREATE_ICON,
        link: '/operations/geofences/create',
      },
    ],
  },
];
