import api from '@/api';
import { T } from '@/assets/locales';
import { selectSecretToken, selectTenantId } from '@/store/auth';
import { toastSuccess, toastWarning } from '@/utils/toaster';
import { UserDetailsContext } from '@/web/@layouts/UserDetailsLayout';
import { UserForm } from '@/web/administration/users/@components/UserForm';
import { Box, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export function UpdateUserPage() {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(UserDetailsContext);
  const secretToken = useSelector(selectSecretToken);
  const tenantId = useSelector(selectTenantId);

  const [loading, setLoading] = useState(false);
  const accountId = useMemo(() => params.accountId, [params]);

  /** @type {import('@/web/administration/users/@components/UserForm').UserFormProps['onSubmitData']} */
  const updateUser = async (props) => {
    const { name, role } = props;
    try {
      setLoading(true);
      await Promise.all([
        api.ac.v5.auth.account
          .$id(accountId)
          .$patch({
            headers: {
              Authorization: secretToken,
            },
            data: {
              name,
            },
          })
          .process(),
        api.ac.v5['role-manager'].users
          .$userId(user.userId)
          .$put({
            headers: {
              Authorization: secretToken,
            },
            params: {
              newRoleId: role?.id,
              tenantId,
            },
          })
          .process(),
      ]);
      toastSuccess('Success', t(T['user.update.success']));
      navigate('/administration/users');
    } catch (err) {
      toastWarning('Error', t(T['user.update.retry']));
    }
  };

  return (
    <Box m={2.5}>
      <Typography variant="body2" fontSize="1.12rem" fontWeight={'medium'}>
        {t(T['user.update.title'])}
      </Typography>
      <UserForm onSubmitData={updateUser} loading={loading} user={user} />
    </Box>
  );
}
