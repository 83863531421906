import { Box, useTheme } from '@mui/material';

/**
 * @typedef {object} CenterBoxPropsBase
 * @property {keyof import('@mui/material').TypeText} [text]
 * @property {boolean} [fullView] Set height to [100vh - 250px]
 * @property {boolean} [absolute] Use position=absolute
 */

/**
 * @typedef {CenterBoxPropsBase & import('@mui/material').BoxProps} CenterBoxProps
 */

/**
 * @param {CenterBoxProps} props
 */
export function CenterBox(props) {
  const { fullView, absolute, text = 'primary', ...boxProps } = props;
  const theme = useTheme();
  return (
    <Box
      {...boxProps}
      sx={{
        height: fullView ? 'calc(100vh - 250px)' : '100%',
        width: '100%',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        color: theme.palette.text[text] || theme.palette.text.primary,
        position: absolute ? 'absolute' : 'relative',
        top: absolute ? 0 : null,
        left: absolute ? 0 : null,
        ...boxProps?.sx,
      }}
    />
  );
}
