import { PARKING_MODES } from '@/assets/cameras';

/**
 * Checks if the camera is in parking mode or not.
 * @param {string} status
 * @returns {boolean}
 */
export function isCameraInParkingMode(status) {
  return PARKING_MODES.includes(status);
}

/**
 * Properly format the camera name
 * @param {string} label The device label
 * @param {string} [serial] The device serial name
 */
export function formatCameraName(label, serial) {
  if (!label) {
    return 'Smarter AI Dashcam' + (serial ? ' - ' + serial : '');
  }
  return label;
}

/**
 * Video Resolution Name format
 * @param {string} resolution THe video resolution
 */
export function formatResolution(resolution) {
  switch (resolution) {
    case 'WH_640x480':
      return '480p';
    case 'WH_1280x720':
      return '720p';
    case 'WH_1920x1080':
      return '1080p';
    default:
      return resolution;
  }
}

/**
 * Sorts an array of devices based on custom criteria.
 * @param {Array<EndpointInfoAggregated>} devices - An array of device objects to be sorted.
 * @returns {Array<EndpointInfoAggregated>} - The sorted array of devices.
 */
export function customSortDevices(devices) {
  const getCategoryOrder = (category) => {
    const order = ['live', 'parkingOnline', 'parkingOffline', 'offline', 'others'];
    return order.indexOf(category);
  };

  const getCategory = (device) => {
    if (device.deviceOnlineStatus && !isCameraInParkingMode(device.parkingStatus)) {
      return 'live';
    } else if (device.deviceOnlineStatus && isCameraInParkingMode(device.parkingStatus)) {
      return 'parkingOnline';
    } else if (!device.deviceOnlineStatus && isCameraInParkingMode(device.parkingStatus)) {
      return 'parkingOffline';
    } else if (!device.deviceOnlineStatus && device.parkingStatus === 'NotInParkingMode') {
      return 'offline';
    } else {
      return 'others';
    }
  };

  return devices.sort((a, b) => {
    const categoryA = getCategory(a);
    const categoryB = getCategory(b);

    const orderA = getCategoryOrder(categoryA);
    const orderB = getCategoryOrder(categoryB);

    if (orderA !== orderB) {
      return orderA - orderB;
    }

    return a.deviceLabel.localeCompare(b.deviceLabel);
  });
}
