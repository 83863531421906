/**
 * Download image ignoring cross-origin issues.
 * @param {CanvasImageSource} src
 * @param {number} width
 * @param {number} height
 * @param {string} [type]
 * @returns {string}
 */
export function takeElementSnapshot(src, width, height, type = 'image/jpeg') {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.drawImage(src, 0, 0, width, height);
    return canvas.toDataURL(type);
  } catch (err) {
    console.warn(err);
    return null;
  }
}

/**
 * Rotates an image and returns a new URL
 * @param {string|HTMLImageElement} img Image to rotate
 * @param {number} [angle] Rotation angle in degrees
 * @returns {Promise<string>}
 */
export async function rotateImage(img, angle = 0) {
  if (typeof img === 'string') {
    const elem = document.createElement('img');
    elem.src = img;
    img = elem;
  }
  const loader = new Promise((resolve, reject) => {
    if (typeof img === 'string') return reject();
    img.addEventListener('load', resolve);
    img.addEventListener('error', reject);
  });
  if (img.loading) {
    await loader;
  }
  const w = 4 * img.naturalWidth;
  const h = 4 * img.naturalHeight;
  const canvas = document.createElement('canvas');
  canvas.width = 2 * w;
  canvas.height = 2 * h;
  const ctx = canvas.getContext('2d');
  ctx.save();
  ctx.translate(w, h);
  ctx.rotate((angle * Math.PI) / 180);
  ctx.translate(-w / 2, -h / 2);
  ctx.drawImage(img, 0, 0, w, h);
  ctx.restore();
  return canvas.toDataURL('image/svg');
}

/**
 * Make avatar image
 * @param {string} url Image URL
 * @returns {Promise<string>}
 */
export async function makeAvatarImage(url) {
  const radius = 48;
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url || '';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 2 * radius;
      canvas.height = 2 * radius;
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = '#fff';
      ctx.lineWidth = radius * 0.2;
      ctx.save();
      ctx.beginPath();
      ctx.arc(radius, radius, radius, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, 2 * radius, 2 * radius);
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(0, 0, radius, 0, Math.PI * 2, true);
      ctx.clip();
      ctx.closePath();
      ctx.restore();
      resolve(canvas.toDataURL('image/png'));
    };
  });
}
