import { Alert, Typography } from '@mui/material';

export function TriggerDescription({ description }) {
  return (
    <Alert severity="info" icon={false} sx={{ borderRadius: '7px', backgroundColor: '#eff4fa' }}>
      <Typography variant="subtitle2" lineHeight="18px" pb={1}>
        {'Description'}
      </Typography>
      <Typography
        sx={{
          fontSize: '0.85rem',
          fontWeight: 400,
          lineHeight: '18px',
          className: 'word-break',
        }}
      >
        {description}
      </Typography>
    </Alert>
  );
}
