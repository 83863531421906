import { DriverFleets } from '.';

/** @type {Array<import('@/types').ExtendedRoute>} */
const routes = [
  {
    path: '',
    element: <DriverFleets />,
    breadcrumb: 'My Fleet',
  },
];

export default routes;
