/**
 * Parses CSV data into an array of objects.
 * @param {any} csvData - The CSV data to parse.
 * @returns {Array<Object>} An array of objects representing the parsed CSV data.
 */
export const parseCSV = (csvData) => {
  // Split the CSV data into lines
  const lines = csvData.split('\n');

  // Extract headers from the first line and trim whitespace
  const headers = lines[0].split(',').map((header) => header.trim());

  // Initialize an array to store parsed data
  const data = [];

  // Loop through each line starting from the second line
  for (let i = 1; i < lines.length; i++) {
    // Split the line into values
    const values = lines[i].split(',');

    // Check if the number of values matches the number of headers
    if (values.length === headers.length) {
      // Create an object for the row and map values to headers
      const row = {};
      values.forEach((value, index) => {
        row[headers[index]] = value.trim();
      });

      data.push(row);
    }
  }

  return data;
};
