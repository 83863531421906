export function TermsAndConditionsPage() {
  return (
    <div
      style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto', paddingBottom: '25px' }}
    >
      <h1 style={{ textAlign: 'center' }}>
        Terms &amp; Conditions
        <br />
        <div style={{ fontWeight: 500, textAlign: 'center', color: 'grey', fontSize: '0.75rem' }}>
          Effective Date: January 1, 2019.
        </div>
      </h1>
      <p>
        In these terms of sale, the terms &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to the
        entity that has submitted an Order Form as a Customer to purchase Smarter AI Cameras or
        Gateways (together, &ldquo;Devices&rdquo;). Capitalized terms in these terms, where not
        defined in these terms, have the meanings set out in the Smarter AI Master Services
        Agreement posted on the Smarter AI website.
      </p>
      <p>
        Your Order Form specifies the model(s) and number of Devices that you agree to purchase from
        Smarter AI. Smarter AI agrees to sell and ship the accepted model(s) and number of Devices
        to you.
      </p>
      <ol>
        <li>
          <strong>Shipping</strong>
          <br />
          Your Order Form specifies a delivery date for your Devices and a shipping method. Smarter
          AI will use its best efforts to ship the Devices by the designated shipping method so that
          they arrive at your shipping address by the stated delivery date. Smarter AI is not
          responsible if shipment takes longer than normally anticipated shipping times, so that
          shipment is delayed beyond the stated delivery date.
          <br />
          Prices stated in your Order Form do not include shipping costs, which will be added to
          your invoice as a separate item.
        </li>
        <li>
          <strong>Taxes</strong>
          <br />
          All prices stated in your Order Form are exclusive of any applicable taxes. You are
          responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and
          charges of any kind imposed by any governmental entity, whether national, federal,
          provincial, or local on any products sold or shipped to you
          <br />
          Any taxes that Smarter AI is required to collect or remit will be added to your invoice as
          a separate item.
        </li>
        <li>
          <strong>
            Payment
            <br />
          </strong>
          Payment is due within 7 days following delivery of any invoice from Smarter AI. You agree
          that any invoice from Smarter AI can be delivered to an email address shown on your Order
          Form. Late payments are subject to an interest charge of 2% per month. Unless otherwise
          stated in an Order Form, all amounts are stated and payable in United States dollars.
          Smarter AI reserves the right to cancel, suspend, or stop any shipment to Customer if
          Customer fails to comply with the applicable payment terms.
        </li>
        <li>
          <strong>
            Limited Warranties
            <br />
          </strong>
          Limited Warranty to Customer. Smarter AI warrants to Customer (the &ldquo;Smarter AI
          Limited Warranty&rdquo;) that Devices sold pursuant to an accepted Smarter AI Order Form,
          if properly installed and used thereafter in strict accordance with the applicable user
          documentation available on the Smarter AI website, shall be free from manufacturing
          defects in material and workmanship which renders a Device unsuitable for use in
          connection with the Smarter AI Services. Except as set out below, the Smarter AI Limited
          Warranty extends only to the Customer purchasing the Devices directly from Smarter AI, and
          not to any subsequent owner.
        </li>
      </ol>
      <p>
        Extension to other Customers and Customer Clients. The Smarter AI Limited Warranty
        applicable to a Device, as stated above, extends automatically to any other Customer or any
        Customer Client who acquires ownership from you and who connects the Device to the Smarter
        AI Services.
      </p>
      <p>
        Term of Smarter AI Limited Warranty. The Smarter AI Limited Warranty covers a Device, for 1
        year from the date of the invoice issued by Smarter AI for the sale of the Device.
      </p>
      <p>
        Smarter AI&rsquo;s Obligations. Smarter AI&rsquo;s obligations under the Smarter AI Limited
        Warranty are limited, at its sole option, either to:
      </p>
      <ul>
        <li>Repair the Device found by Smarter AI to be defective; or</li>
        <li>
          Replace parts or, at Smarter AI&rsquo;s sole discretion, the Device, if found by Smarter
          AI to be defective.
          <br />
          The Device may be repaired with new or refurbished parts and may be replaced with new or
          refurbished products, of the same or similar model as the replaced Device, or of
          materially equivalent or superior quality, at the sole discretion of Smarter AI. Repaired
          and replacement Devices will be warranted to the Customer under the terms of this Limited
          Warranty for the remainder of the original warranty period or 90 days from the date of
          shipment to Customer, whichever is longer. Any replaced Device or part becomes the
          property of Smarter AI.
        </li>
      </ul>
      <p>
        Warranty Limitations, Exclusions &amp; Disclaimers. The Smarter AI Limited Warranty is
        invalidated if non-Smarter AI accessories are or have been used in connection with the
        Device, or if any modification or repair is made to the Device by any service depot or other
        person not authorized by Smarter AI. This warranty does not apply to defects in or damages
        to the Device caused by (i) negligent use of the Device, (ii) misuse, abuse, neglect,
        alteration, repair or improper installation of the Device, (iii) electrical short circuits
        or transients, (iv) heat, humidity, moisture or temperatures beyond product specifications
        (v) use of replacement parts not supplied by Smarter AI (vi) improper Device maintenance,
        (vii) any errors or defects in non-Smarter AI software added to the Device following
        shipment; or (vii) accident, fire, flood or other Acts of God.
      </p>
      <p>
        The Smarter AI Limited Warranty does not cover data loss, or costs related to data recovery.
        Smarter AI makes no warranty that the software provided with the Device will function
        without interruption or otherwise be free of anomalies, errors or viruses. This warranty
        does not cover any costs relating to removal, replacement, or installation of any Device,
        regardless of whether the Device is found by Smarter AI to be defective, or software
        installed on the user&rsquo;s computer.
      </p>
      <p>
        Smarter AI reserves the right to make changes to its products without incurring any
        obligation to modify any product that has already been manufactured.
      </p>
      <p>
        REPAIR OR REPLACEMENT AS PROVIDED IN THE SMARTER AI WARRANTY IS THE EXCLUSIVE REMEDY OF THE
        PURCHASER. THE SMARTER AI WARRANTY IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESSED OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A
        PARTICULAR USE OR PURPOSE, AND SMARTER AI NEITHER ASSUMES NOR AUTHORIZES ANY PERSON TO
        ASSUME FOR IT ANY OTHER OBLIGATION OR LIABILITY IN CONNECTION WITH THE SALE OR SERVICE OF
        THE DEVICE. IN NO EVENT SHALL SMARTER AI BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE,
        SPECIAL OR CONSEQUENTIAL DAMAGES ARISING FROM THE USE OF THE DEVICE OR ARISING FROM THE
        MALFUNCTIONING OR NON-FUNCTIONING OF THE DEVICE, INCLUDING, WITHOUT LIMITATION, LOSS OF
        REVENUE OR PROFITS, FAILURE TO REALIZE SAVINGS OR OTHER BENEFITS, DAMAGE TO OR LOSS OF
        PROPERTY, INJURIES TO OR DEATH OF ANY PERSON, AND CLAIMS AGAINST THE CUSTOMER BY ANY THIRD
        PERSON, EVEN IF SMARTER AI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SMARTER AI
        DOES NOT WARRANT THAT THE PRODUCT OR ITS SOFTWARE WILL OPERATE WITH ANY OTHER PRODUCT OR
        SOFTWARE EXCEPT THAT WHICH IS INDICATED BY SMARTER AI. SMARTER AI IS NOT RESPONSIBLE FOR
        CHARACTERISTICS OF THIRD PARTY HARDWARE OR SOFTWARE THAT MAY AFFECT THE OPERATION OF THE
        SMARTER AI SOFTWARE INCLUDED.
      </p>
      <p>
        WITHOUT LIMITING THE FOREGOING, SMARTER AI DOES NOT MAKE ANY CLAIMS OR WARRANTIES OF ANY
        KIND WHATSOEVER REGARDING THE PRODUCT&rsquo;S POTENTIAL, ABILITY OR EFFECTIVENESS TO
        PREVENT, MINIMIZE, OR IN ANY WAY AFFECT PERSONAL OR PROPERTY DAMAGE OR INJURY. SMARTER AI IS
        NOT RESPONSIBLE FOR ANY DAMAGE, LOSS OR THEFT RELATED TO THE PRODUCT OR TO ITS USE FOR ANY
        HARM, WHETHER PHYSICAL OR MENTAL RELATED THERETO. ANY AND ALL CLAIMS OR STATEMENTS, WHETHER
        WRITTEN OR VERBAL, BY SALESPEOPLE OR ANY OTHER PERSONS TO THE CONTRARY ARE NOT AUTHORIZED BY
        SMARTER AI, AND DO NOT AFFECT THIS PROVISION OF THIS WARRANTY.
      </p>
      <p>
        State/Provincial Law. The Customer may have other rights under state, provincial, or federal
        laws and where the whole or part of any item of this Smarter AI Limited Warranty is
        prohibited by such laws, it shall be deemed null and void. The remainder of the Smarter AI
        Limited Warranty shall remain in effect.
      </p>
      <p>
        The Devices covered by this Smarter AI Limited Warranty must be used as intended for the
        Smarter AI Limited Warranty to apply. Without limit to the foregoing, some states do not
        allow the exclusion or limitation of implied warranties or the limitation of incidental or
        consequential damages for certain products supplied to consumers or the limitation of
        liability for personal injury, so the above limitations, exclusions and disclaimers may be
        limited in their application to you. If implied warranties are not allowed to be excluded in
        their entirety for any reason, they will be limited to the duration of the applicable
        written warranty in this document. This Smarter AI Limited Warranty gives you specific legal
        rights that may vary depending on local law. By keeping this Device you agree to the terms
        of this Smarter AI Limited Warranty without objection or addition, and agree that binding
        arbitration shall be your sole remedy for any disputes over any elements of this Smarter AI
        Limited Warranty and waive any right to pursue joint, collective, or class claims in any
        form with respect to the Device, your purchase of the Device, this Smarter AI Limited
        Warranty and Smarter AI&rsquo;s performance hereunder.
      </p>
      <p>
        Obtaining Service. Should the Device require service under this warranty, you must return
        the Device to Smarter AI, and you must assume the risk of damage or loss during shipment.
        Smarter AI will cover the cost of shipping the replacement product back to you.
      </p>
      <p>
        Limited Technical Support. The Smarter AI Limited Warranty period covers 1 year of limited
        technical support. After the warranty period has expired, technical support services will
        continue to be available for a fee.
      </p>
    </div>
  );
}
